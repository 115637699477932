import React from "react";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import { TemplateForm } from "../../components/Templates/TemplateForm/TemplateForm";
import { useQuery } from "../../hooks/useQuery";

const TemplateBuilderPage = () => {
  const query = useQuery();
  const type = query.get("type");

  return (
    <div>
      <AdminNavbar
        link={`/admin/templates`}
        title={`${type} Template Builder`}
        backLink={"Go Back to Templates Dashboard"}
      />

      <div className="5xl:px-[96px] px-12 w-full md:pt-[94px] mt-9">
        <div className="w-[253px]">
          <TemplateForm type={type} />

          <hr className="mt-8 bg-Neutral400" />

          <button className="bg-AccentRegular text-Neutral000 flex justify-center items-center uppercase py-4 text-xs font-bold leading-4 text-center w-full rounded mt-6">
            VIEW PREVIEW
          </button>
        </div>
      </div>
    </div>
  );
};

export default TemplateBuilderPage;
