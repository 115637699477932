import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { UpdateIsNotificationReceiveAction } from "../../store/actions/authAction";

const EmailNotification = ({ logedinUser }) => {
  const dispatch = useDispatch();
  const [isNotificationReceive, setIsNotificationReceive] = useState(
    logedinUser?.is_notification_receive
  );

  return (
    <div className="w-full 5xl:max-w-[500px] md:max-w-[408px] rounded border border-Neutral300 py-8 px-5">
      <div className="flex gap-5">
        <div className="space-y-1">
          <h5 className="text-sm font-semibold tracking-wide uppercase text-Neutral700">
            EMAIL Notifications
          </h5>
          <p className="text-xs font-medium text-Neutral600">
            Enable email notifications for task mentions, client updates and
            document changes.
          </p>
        </div>
        <label className="flex items-center cursor-pointer select-none toggle-switch">
          <div className="relative">
            <input
              type="checkbox"
              className="sr-only"
              checked={isNotificationReceive}
              onChange={() => {
                setIsNotificationReceive(!isNotificationReceive);
                dispatch(UpdateIsNotificationReceiveAction());
              }}
            />
            <div className="block h-[24px] switch w-[44px] rounded-full border-Neutral400 border-2 bg-Neutral100"></div>
            <div className="dot absolute left-0 top-0 h-6 w-6 rounded-full bg-Neutral800 transition shadow-[0px_1.5px_3px_1px_rgba(0,0,0,0.2)]"></div>
          </div>
        </label>
      </div>
    </div>
  );
};

export default EmailNotification;
