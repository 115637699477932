import React from "react";

const PenIcon = ({ width = 18, color = "#FFF", className = "" }) => {
  return (
    <svg
      className={className}
      width={width}
      height={width}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.75 14.475L14.475 9.75L16.5 11.775L11.775 16.5L9.75 14.475Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8 10.425L12.7875 5.3625L3 3L5.3625 12.7875L10.425 13.8L13.8 10.425Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 3L8.12055 8.12055"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.0751 10.4251C9.82068 10.4251 10.4251 9.82068 10.4251 9.0751C10.4251 8.32951 9.82068 7.7251 9.0751 7.7251C8.32951 7.7251 7.7251 8.32951 7.7251 9.0751C7.7251 9.82068 8.32951 10.4251 9.0751 10.4251Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PenIcon;
