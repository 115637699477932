import Modal from "react-modal";

import CloseBtn from "../../../assets/Images/close-btn.svg";

/** @type {import("react-modal").Styles} */
const customModalStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "50px",
    background: "none",
    borderRadius: "10px",
  },
};

function ClientDeactivateConfirmationModal({ isOpen, setOpen, deactivate }) {
  const handleClose = () => {
    setOpen({ isOpen: false });
  };

  const onDeactivate = () => {
    deactivate();
    handleClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={customModalStyles}
      closeTimeoutMS={300}
      contentLabel="Client Deactivate Confirmation Modal"
    >
      <div className="relative rounded-lg text-left transform transition-all w-[80vw] sm:my-6 shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp sm:max-w-md">
        <div className="w-full px-8 py-8 rounded-lg bg-Neutral200">
          <h4 className="text-base font-bold text-center text-Neutral800">
            Are your sure? You'll loose all client portal information and it can't be recovered!
          </h4>
          <div className="flex gap-5 mt-8">
            <button
              className="w-full py-3 text-sm font-semibold rounded-md px-7 hover:bg-Red500 bg-Red400 text-Neutral000"
              onClick={onDeactivate}
            >
              Yes, Deactivate
            </button>
            <button
              className="w-full py-3 text-sm font-semibold rounded-md px-7 hover:bg-AccentMediumDark bg-AccentRegular text-Neutral000"
              onClick={handleClose}
            >
              No, Keep Portal
            </button>
          </div>
        </div>
        {/* ----- Close Modal Button ----- */}
        {/* Close modal button */}
        <button className="absolute -right-1 top-[-50px] z-30" onClick={() => handleClose()}>
          <img src={CloseBtn} alt="Close modal button" />
        </button>
      </div>
    </Modal>
  );
}

export default ClientDeactivateConfirmationModal;
