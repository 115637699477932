import React from 'react'

const UploadFiles = ({label, id, name, icon}) => {
  return (
    <>
      <div className='relative'>
        <label htmlFor={id} className='placeholder:text-Neutral500 p-2 px-3 text-sm leading-5 w-full focus-visible:outline-none border-[1px] rounded border-Neutral400 hover:border-Neutral800 bg-Neutral100 transition duration-300 border-dashed inline-block cursor-pointer'>
          <span className='flex items-center'>
            <img src={icon} alt="" />
            <p className='text-Neutral600 ml-2 text-xs font-medium'>{label}</p>
          </span>
        </label>
        <input id={id} className='hidden' name={name} type="file" placeholder='Eg. very_good_password' />
      </div>
    </>
  )
}

export default UploadFiles