import React from "react";
import clx from "classnames";

function NotificationBadge({ number = null, className = "" }) {
  if (!number) {
    return null;
  }
  return (
    <span
      className={clx(
        "flex items-center justify-center w-[16px] h-[16px] rounded-[50%] bg-AccentRegular text-white text-[10px] font-bold leading-[100%] tracking-[0em]",
        className
      )}
      aria-label="Notifications"
      title="Notifications"
    >
      {number}
    </span>
  );
}

export default NotificationBadge;
