import React from "react";
import clx from "classnames";

import TagTrackingBadge from "../badges/TagTrackingBadge";

const TAGS_SHOW_LIMIT = 3;

function Counter({ number = null }) {
  if (!number) {
    return null;
  }

  return (
    <li className="flex justify-center items-center text-[12px] leading-[120%] tracking-[0.04em] source-code-pro text-Neutral700 uppercase font-medium ml-1">
      +{number}
    </li>
  );
}

function TagsTrackingList({
  tags = [],
  className = "",
  limit = TAGS_SHOW_LIMIT,
}) {
  if (!tags.length || !Array.isArray(tags)) {
    return null;
  }

  const tagsElements =
    tags.length <= limit
      ? tags.map((tag, idx, arr) => (
          <TagTrackingBadge tag={tag} key={tag?.id ?? idx} />
        ))
      : tags
          .slice(0, limit)
          .map((tag, idx, arr) => (
            <TagTrackingBadge tag={tag} key={tag?.id ?? idx} />
          ))
          .concat([<Counter key="counter" number={tags.length - limit} />]);

  return (
    <ul className={clx("flex list-none gap-1", className)}>{tagsElements}</ul>
  );
}

export default TagsTrackingList;
