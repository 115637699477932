const addPositionIdx = (arr = []) => {
  if (!Array.isArray(arr)) {
    return null;
  }

  return arr.map((item, idx) => {
    item.position = idx;
    return item;
  });
};

export default addPositionIdx;
