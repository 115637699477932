import { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import Clipboard from "../../assets/Images/Clipboard-gray.svg";
import Close from "../../assets/Images/Close-gray.svg";
import Logo from "../../assets/Images/Logo.svg";
import Mail from "../../assets/Images/Mail-gray.svg";
import Notification from "../../assets/Images/Notification-gray.svg";
import CloseBtn from "../../assets/Images/close-btn.svg";
import {
  GetQuestionnaireByKey,
  SaveAnswers,
} from "../../store/actions/questionnaireAction";
import ContentLoader from "../ContentLoaders/RequestInfoQuestionnaireLoader";
import BgShape1 from "../Icons/BgShape1";
import BgShape2 from "../Icons/BgShape2";
import BgShape3 from "../Icons/BgShape3";
import CloudLoaderIcon from "../Icons/CloudLoaderIcon";
import CloudSuccessIcon from "../Icons/newicons/CloudSuccessIcon";
import QuestionCard from "./components/QuestionCard";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

function ClientRequestInfo() {
  const { key } = useParams();
  const dispatch = useDispatch();

  const questionnaireList = useSelector(
    (state) => state.questionnaire.open_questionnaire
  );

  const companyName = questionnaireList?.brandIdentity?.companyName;
  const isWhiteLabelUser =
    questionnaireList?.brandIdentity?.status === "active";
  const isCustom = !!questionnaireList?.brandIdentity?.logo;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [closeTab, setCloseTab] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [isRequiredList, setIsRequiredList] = useState([]);
  const [questionnaire, setQuestionnaire] = useState({
    isLoading: true,
    data: {},
    error: false,
    error_message: "",
  });

  useEffect(() => {
    if (questionnaireList?.data?._id) {
      setQuestionnaire({
        isLoading: questionnaireList.isLoading,
        data: questionnaireList.data,
        error: questionnaireList.error,
        error_message: questionnaireList.error_message,
      });
    }

    if (questionnaireList?.brandIdentity) {
      document.documentElement.style.setProperty(
        "--bg-color",
        questionnaireList?.brandIdentity?.bg_color
      );
      document.documentElement.style.setProperty(
        "--accent-color",
        questionnaireList?.brandIdentity?.accent_color
      );
    }
  }, [questionnaireList]);

  useEffect(() => {
    dispatch(GetQuestionnaireByKey(key));
  }, [dispatch, key]);

  const [selectedBox, setSelectedBox] = useState("box-1");
  const [colorBox, setColorBox] = useState({
    id: 4,
    color: "bg-AccentRegular",
    box: "color-4",
    text: "text-color-4",
  });

  useEffect(() => {
    if (questionnaire?.data?.config) {
      const parsedConfig = questionnaire?.data?.config;
      if (parsedConfig && parsedConfig?.colorBox) {
        setColorBox(parsedConfig?.colorBox);
      }
      if (parsedConfig && parsedConfig?.selctedBox) {
        setSelectedBox(parsedConfig?.selctedBox);
      }
    }
  }, [questionnaire]);

  const answeredQuestions = questionnaire?.data?.questions?.filter(
    (item) =>
      item?.answer !== null && item?.answer !== undefined && item?.answer !== ""
  ).length;

  return (
    <>
      <div className="py-8 lg:py-[80px] bg-Neutral200 px-3">
        <div className="w-full max-w-[1124px] mx-auto flex justify-between flex-col md:flex-row gap-5">
          <div className="w-full lg:max-w-[340px]">
            <div className="bg-Neutral000 rounded border border-Neutral400 py-[28px] pl-[56px] pr-5 mb-6">
              {!questionnaire.isLoading && questionnaire?.data?._id ? (
                <>
                  <h2 className="text-AccentRegular font-black text-[28px] mb-1 source-code-pro leading-tight">
                    {answeredQuestions ? answeredQuestions : "0"}/
                    {questionnaire?.data?.total_questoins
                      ? questionnaire?.data?.total_questoins
                      : "0"}
                  </h2>
                  <p className="text-sm font-medium uppercase text-AccentRegular">
                    Questions answered
                  </p>
                </>
              ) : (
                <>
                  <div className="text-Neutral900 font-black text-[28px] mb-1 source-code-pro leading-tight flex items-center">
                    <div className="rounded w-[15px] bg-[#f6f7f8] block h-6 bg-no-repeat skeleton-animation relative mr-1" />
                    /
                    <div className="rounded w-[15px] bg-[#f6f7f8] block h-6 bg-no-repeat skeleton-animation relative ml-1" />
                  </div>
                  <div className="rounded w-[150px] bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative" />
                </>
              )}
            </div>
            <div className="bg-Neutral000 rounded border border-Neutral400 pt-[28px] pb-4 pl-6 pr-10 mb-6">
              <div className="flex items-center justify-between mb-5">
                <h3 className="ml-8 text-sm font-medium uppercase text-Neutral800">
                  REMINDERS
                </h3>
                <div>
                  {isSubmitting ? (
                    <span className="text-xs font-semibold leading-4 ml-2.5 flex items-center gap-1.5">
                      <CloudLoaderIcon />
                      Saving...
                    </span>
                  ) : (
                    <span className="text-Green500 text-xs font-semibold leading-4 ml-2.5 flex items-center gap-1.5">
                      <CloudSuccessIcon />
                      Saved
                    </span>
                  )}
                </div>
              </div>
              <ul>
                <li className="flex items-start mb-8">
                  <img src={Clipboard} alt="" />
                  <p className="ml-3 text-Neutral800 text-xs leading-[1.5]">
                    Fill out the answers to the best of your knowledge. Answers
                    can be modified later.
                  </p>
                </li>
                <li className="flex items-start mb-8">
                  <img src={Notification} alt="" />
                  <p className="ml-3 text-Neutral800 text-xs leading-[1.5]">
                    We will be notified when you complete this form.
                  </p>{" "}
                </li>
                <li className="flex items-start mb-8">
                  <img src={Close} alt="" />
                  <p className="ml-3 text-Neutral800 text-xs leading-[1.5]">
                    Please{" "}
                    <span className="font-bold underline text-Red600 decoration-solid">
                      DO NOT
                    </span>{" "}
                    share this link as answers can be changed by anyone with the
                    URL.
                  </p>
                </li>
                <li className="flex items-start mb-8">
                  <img src={Mail} alt="" />
                  <p className="ml-3 text-Neutral800 text-xs leading-[1.5]">
                    Send an email to{" "}
                    <span className="font-bold text-AccentRegular">
                      {questionnaire?.data?.created_by?.email}
                    </span>{" "}
                    if you have any questions.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="view-form-prev relative bg-white text-left transform rounded transition-all max-w-[720px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)]">
            <div className="bg-white px-[60px] pt-[60px] rounded-[8px] relative overflow-hidden">
              <div className="w-full max-w-[476px] mx-auto">
                <div
                  className={`absolute top-0 left-0 h-full bg-shap-main w-[80px] ${selectedBox} ${colorBox.box}`}
                >
                  <div className="absolute top-0 left-0 transition duration-300 opacity-0 bg-shape-1">
                    <BgShape1 />
                  </div>
                  <div className="absolute top-0 left-0 transition duration-300 opacity-0 bg-shape-2">
                    <BgShape2 />
                  </div>
                  <div className="absolute top-0 left-0 transition duration-300 opacity-0 bg-shape-3">
                    <BgShape3 />
                  </div>
                </div>
                {questionnaire?.isLoading ? <ContentLoader /> : null}
                {!questionnaire?.isLoading && questionnaire?.data?._id ? (
                  <div>
                    <div className="border-b border-Neutral400">
                      <h2 className="text-black font-bold text-[20px] 5xl:text-[24px] mb-5 flex items-center">
                        {questionnaire?.data?.title
                          ? questionnaire?.data?.title
                          : ""}{" "}
                        Information Request
                      </h2>
                      <p
                        className="pb-10 text-xs text-AccentRegular"
                        dangerouslySetInnerHTML={{
                          __html:
                            questionnaire?.data?.created_by?.text_for_questionnaire?.replace(
                              questionnaire?.data?.created_by?.email,
                              `<strong class="text-AccentRegular transition duration-300 ${colorBox.text}">${questionnaire?.data?.created_by?.email}</strong>`
                            ),
                        }}
                      ></p>
                    </div>
                    {questionnaire?.data?.questions?.map((item, index) => (
                      <QuestionCard
                        key={item?._id}
                        item={item}
                        index={index}
                        colorBox={colorBox}
                        setAnswers={setAnswers}
                        setIsRequiredList={setIsRequiredList}
                        isRequiredList={isRequiredList}
                      />
                    ))}
                  </div>
                ) : null}
                {questionnaire?.error && questionnaire?.error_message ? (
                  <p className="mt-2 text-lg font-medium text-center text-Red400 focus-visible:outline-none">
                    {questionnaire?.error_message}
                  </p>
                ) : null}
              </div>

              <div className="flex justify-between items-center px-[60px] py-[40px]">
                <button
                  className="button-hover w-full bg-AccentRegular flex items-center justify-center h-[48px] rounded gap-1 text-sm font-medium text-Neutral000"
                  type="button"
                  onClick={() => {
                    const isRequiredAnsweredAvailable =
                      questionnaire?.data?.questions?.filter(
                        (item) => item?.is_required && !item?.answer
                      );

                    if (isRequiredAnsweredAvailable?.length > 0) {
                      return setIsRequiredList(isRequiredAnsweredAvailable);
                    }

                    setIsSubmitting(true);
                    const values = {
                      _id: questionnaire?.data?._id,
                      answers: Object.values(answers),
                    };

                    dispatch(
                      SaveAnswers(
                        values,
                        setIsSubmitting,
                        setAnswers,
                        setCloseTab
                      )
                    );
                  }}
                >
                  Save Answers
                </button>
              </div>

              <div className="flex items-center w-full mx-auto mb-5 bg-white">
                {(() => {
                  if (isCustom && companyName) {
                    // Case 1: Custom logo and company name
                    return (
                      <div className="flex items-center justify-center flex-grow">
                        <a
                          href="#"
                          target="_blank"
                          rel="noreferrer"
                          id="logo_div"
                          className="text-Neutral700 text-[10px] font-normal leading-[7px] flex items-center"
                        >
                          <img
                            src={questionnaireList?.brandIdentity?.logo}
                            alt={`${companyName} Logo`}
                            id="cm_logo"
                            className="mr-[6px] h-5 w-5"
                          />
                          Made with
                          <span className="text-gray-500 font-bold ml-[2px]">
                            {companyName}
                          </span>
                        </a>
                      </div>
                    );
                  } else if (isCustom && !companyName) {
                    // Case 2: Custom logo only
                    return (
                      <div className="flex items-center justify-center flex-grow">
                        <img
                          src={questionnaireList?.brandIdentity?.logo}
                          alt="Company Logo"
                          id="cm_logo"
                          className="w-5 h-5"
                        />
                      </div>
                    );
                  } else if (!isCustom && !isWhiteLabelUser) {
                    // Case 3: Default branding for normal user
                    return (
                      <div className="flex items-center justify-center flex-grow gap-2">
                        <a
                          href="https://www.clientmanager.io/"
                          target="_blank"
                          rel="noreferrer"
                          id="logo_div"
                          className="text-Neutral700 text-[10px] font-normal leading-[7px] flex items-center"
                        >
                          <img
                            src={Logo}
                            alt="Company Logo"
                            id="cm_logo"
                            className="w-5 h-5"
                          />
                          Made with
                          <span className="text-gray-500 font-bold ml-[2px]">
                            ClientManager
                          </span>
                        </a>
                      </div>
                    );
                  } else if (!isCustom && isWhiteLabelUser) {
                    // Case 4: No branding (blank)
                    return null;
                  } else {
                    // Optional: Handle any unexpected cases
                    return null;
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={closeTab}
        onRequestClose={() => setCloseTab(false)}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="Close Questionnaire Window Modal"
      >
        <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="bg-white px-12 py-8 rounded-[8px]">
            <div>
              <div className="text-left">
                <h2
                  className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full"
                  id="modal-title"
                >
                  Thanks for your answers - you can close this window/tab now.
                </h2>
              </div>
            </div>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => setCloseTab(false)}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ClientRequestInfo;
