import React from "react";
import TagList from "./components/TagList";

const TemplateInfoModal = ({
  title,
  id,
  tags,
  color,
  handleSelect,
  selectedTemplate,
}) => {
  return (
    <div
      className={`bg-Neutral00 cursor-pointer ${selectedTemplate?._id === id
        ? `border-[4px] border-${color}400`
        : "border-[1px] border-Neutral300"
        }  p-5 rounded-lg md:w-[310px] mb-4 bg-Neutral000`}
      onClick={() => {
        handleSelect();
      }}
    >
      <div className="flex items-center justify-between">
        <h6 className="uppercase text-sm truncate font-medium tracking-[0.04em]">
          {title}
        </h6>
      </div>

      <TagList {...{ color, tags }} />
    </div>
  );
};

export default TemplateInfoModal;
