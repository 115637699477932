const initialState = {
    isLogin: false,
    authToken: localStorage.getItem("jwt_access_token"),
    userLoading: true,
    user: null,
    accountDetail: {
        response: null,
        isLoading: true,
    },
    basicInfo: null,
    refrence: [],
};
const collabAuthReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case "COLLABLOGIN":
            return {
                ...state,
                isLogin: payload.isLogin,
                authToken: payload.token,
            };
        case "COLLAB_LOGOUT":
            return {
                ...state,
                isLogin: false,
                authToken: null,
                user: null,
            };
        case "COLLAB_SET_USER_DATA": {
            return {
                ...state,
                user: payload.data,
                userLoading: false,
            };
        }
        case "ACCOUNT_DETAILS":
            return {
                ...state,
                accountDetail: payload,
            };
        case "GET_BASIC_USER_INFO":
            return {
                ...state,
                basicInfo: payload.data,
            };
        default:
            return state;
    }
};

export default collabAuthReducer;
