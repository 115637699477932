import { Menu, Transition } from "@headlessui/react";
import { Fragment, useMemo } from "react";
import Modal from "react-modal";

import CloseBtn from "../../../assets/Images/close-btn.svg";
import ChevronDown from "../icons/ChevronDown";
import TimeTrackingRow from "../TimeTrackingRow";
import Spinner from "../../Spinner";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
    overflow: "visible",
  },
};

const filterOptionsMap = {
  all: "All Time",
  week: "This Week",
  month: "This Month",
};

function DetailedTrackedTimeModal({
  isOpen,
  setOpen,
  data,
  filter,
  setFilter,
  loading = false,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const transformedData = useMemo(
    () =>
      data?.map((item) => ({
        id: item?.member,
        name: item?.user?.member?.user_name,
        profilePicture: item?.user?.member?.profile_path,
        hours: item?.total_hours,
        clients: item?.clients,
        projects: item?.projects,
        cost: item?.total_amount,
      })),
    [data]
  );

  const hasNonZeroValues = (item) => {
    return (
      item.hours !== 0 ||
      item.clients !== 0 ||
      item.projects !== 0 ||
      item.cost !== 0
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="Detailed Tracked Time Modal"
    >
      <div className="relative transition-all sm:w-full my-4 shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp md:w-[55dvw]">
        <div className="w-full space-y-5">
          <div className="flex items-center justify-between px-4 py-2 rounded-md bg-Neutral000">
            <h4 className="text-base text-Neutral800">Tracked Time</h4>
            <Menu as="div" className="relative">
              <Menu.Button className="inline-flex items-center gap-1 py-1.5 pl-3 pr-2 text-xs font-medium rounded-[30px] text-Neutral000 bg-Neutral900">
                {filterOptionsMap[filter]}
                {loading ? (
                  <Spinner
                    size={16}
                    className="ml-1.5"
                    spinnerBg="#9C9CA6"
                    thumbColor="#FFFFFF"
                  />
                ) : (
                  <ChevronDown className="text-Neutral000 size-4" />
                )}
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  as="div"
                  className="absolute right-0 z-20 w-full p-1 mt-1 border divide-y rounded-md shadow-xl bg-Neutral000 border-Neutral300 divide-Blue100 min-w-[100px]"
                >
                  <Menu.Item
                    as="button"
                    type="button"
                    className="w-full p-1 text-sm text-left rounded text-Neutral900 hover:bg-Blue100"
                    onClick={() => setFilter("all")}
                  >
                    All Time
                  </Menu.Item>
                  <Menu.Item
                    as="button"
                    type="button"
                    className="w-full p-1 text-sm text-left rounded text-Neutral900 hover:bg-Blue100"
                    onClick={() => setFilter("week")}
                  >
                    This Week
                  </Menu.Item>
                  <Menu.Item
                    as="button"
                    type="button"
                    className="w-full p-1 text-sm text-left rounded text-Neutral900 hover:bg-Blue100"
                    onClick={() => setFilter("month")}
                  >
                    This Month
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>

          <div className="px-5 py-6 overflow-y-auto rounded-md bg-Neutral000">
            {/* Table Header */}
            <div className="flex px-6 text-xs uppercase text-Neutral600">
              <span className="w-[40%]">Name</span>
              <div className="flex items-center w-[60%]">
                <span className="flex-1 text-center">Hours</span>
                <span className="flex-1 text-center">Clients</span>
                <span className="flex-1 text-center">Projects</span>
                <span className="flex-1 text-center">Cost</span>
              </div>
            </div>

            {/* Table Rows */}
            <div className="flex flex-col gap-4 mt-5 overflow-y-auto md:max-h-[345px] pr-2">
              {!!transformedData.length &&
                transformedData
                  .filter(hasNonZeroValues)
                  .map((item) => (
                    <TimeTrackingRow
                      key={item.id}
                      userName={item.name}
                      userImage={item.profilePicture}
                      hours={item.hours}
                      clients={item.clients}
                      projects={item.projects}
                      cost={item.cost}
                    />
                  ))}
            </div>
          </div>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={handleClose}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
}

export default DetailedTrackedTimeModal;
