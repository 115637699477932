import React, { Fragment, useState } from "react";
import Modal from "react-modal";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { Menu, Transition } from "@headlessui/react";
import { useRef } from "react";
import SelectDownArrow from "../Icons/SelectDownArrow";
import { postRefrenceData } from "../../store/actions/preWelcomeAction";
import { useDispatch } from "react-redux";

const roles = [
  "Founder/Owner/CEO",
  "Operations",
  "Marketing",
  "Designer",
  "Developer",
  "Project Manager",
  "Other",
];
const peoplesInTeam = ["Just me", "2-10", "10-20", "20-50", "50+"];
const sources = [
  "Twitter",
  "Facebook",
  "YouTube",
  "Blog Article",
  "Other (please let us know)",
];

const customModalStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
    background: "white",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "50px",
    background: "none",
    borderRadius: "10px",
    overflow: "visible",
  },
};

const ValidationSchema = Yup.object().shape({
  role: Yup.string().required("Role is Required"),
  team: Yup.string().required("Please select team member"),
  source: Yup.string().required("Source is Required"),
  source_value: Yup.string().when("source", {
    is: "Other (please let us know)",
    then: Yup.string().required("Please enter sources value").nullable(),
  }),
});

function PreWelcomeModal() {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);

  const [newRole, setNewRole] = useState("");
  const [newTeam, setNewTeam] = useState("");
  const [newSource, setNewSource] = useState("");
  const roleDropDownref = useRef(null);
  const teamDropDownref = useRef(null);
  const sourceDropDownref = useRef(null);

  const [numberOfLetters, setNumberOfLetters] = useState(120);
  const handleTextChange = (event) => {
    const text = event.target.value;
    if (text.length > 120) {
      return;
    }
    setNumberOfLetters(120 - text.length);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        // onRequestClose={() => setIsOpen(false)}
        style={customModalStyles}
        closeTimeoutMS={300}
        contentLabel="Pre-welcome Modal"
      >
        <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full  animate__fadeInUp">
          <div className="bg-white px-6 xs:px-12 pt-8 pb-10 rounded-[8px]">
            <div>
              <div className="text-left">
                <Formik
                  initialValues={{
                    role: "",
                    team: "",
                    source: "",
                    source_value: "",
                  }}
                  enableReinitialize
                  validationSchema={ValidationSchema}
                  onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true);
                    // TODO: make request
                    dispatch(
                      postRefrenceData(values, setSubmitting, setIsOpen)
                    );
                  }}
                >
                  {({
                    touched,
                    errors,
                    values,
                    isSubmitting,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit} className="flex flex-col">
                      <div className="text-Neutral900 font-extrabold	text-xl	 pb-2 border-b border-Neutral200">
                        TELL US ABOUT YOUR BUSINESS
                      </div>
                      <Menu
                        as="div"
                        className="relative inline-block text-left w-80"
                      >
                        <div className="">
                          <Menu.Button
                            ref={roleDropDownref}
                            className={
                              "focus-visible:outline-none popup-btn relative w-full"
                            }
                          >
                            <div className="mt-5">
                              <label
                                htmlFor="countries"
                                className="text-Neutral900 font-bold text-xs mb-2 inline-block w-full text-left"
                              >
                                What is your role in the company?
                              </label>
                              <div className="relative">
                                <span className="absolute pointer-events-none z-[99] top-[50%] translate-y-[-50%] right-[16px]">
                                  <SelectDownArrow />
                                </span>
                                <input
                                  id="countries"
                                  className="relative text-Neutral800 placeholder:font-medium font-medium placeholder:text-Neutral800 text-Neutral800 px-4 placeholder:text-sm text-sm 5xl:placeholder:text-lg  w-full h-[48px] focus:outline-none border-[1.5px] rounded hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow cursor-pointer appearance-none duration-300 transition border-Neutral300 leading-[48px]"
                                  placeholder="Select one..."
                                  value={values?.role}
                                  onChange={(e) => {
                                    setNewRole(e.target.value);
                                  }}
                                />
                                <span
                                  className={
                                    "dot-box rounded-full absolute pointer-events-none top-[50%] translate-y-[-50%] left-[16px] h-2 w-2 hidden z-[1]"
                                  }
                                ></span>
                              </div>
                            </div>
                          </Menu.Button>
                          {errors?.role && touched?.role && (
                            <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                              {errors?.role}
                            </p>
                          )}
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right z-[88] absolute top-[40px] -right-[3px] mt-2 w-[323px] rounded-lg dropdowns-boxshadow bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none px-2 pt-3 pb-2">
                            <div className="mx-2">
                              <input
                                className="placeholder:font-medium text-xs placeholder:text-Neutral900 text-Neutral700 pr-4 focus:outline-none w-full mb-2 placeholder:text-sm	"
                                placeholder="Select one..."
                                value={newRole}
                                onChange={(e) => {
                                  setNewRole(e.target.value);
                                }}
                              />
                            </div>
                            <div className="project-title-divide ">
                              <div className="border-0   currancy-scrollbar scrollbar-w-4 relative ">
                                <h4 className="text-Neutral600 font-semibold text-[10px] pt-3 ml-2 mr-5 border-t-[1px] border-Neutral200">
                                  Choose One
                                </h4>
                                {roles.map((item, index) => {
                                  return (
                                    <div
                                      key={`${item}+${index}`}
                                      className="mt-2 flex items-center hover:bg-Neutral200 p-2 rounded cursor-pointer group duration-300 "
                                      onClick={(e) => {
                                        setFieldValue("role", item);

                                        setTimeout(() => {
                                          roleDropDownref?.current?.click();
                                          e.stopPropagation();
                                        }, 0);
                                      }}
                                    >
                                      <p className="text-Neutral800 group-hover:text-Neutral900 font-normal text-sm	 w-full rounded">
                                        {item}
                                      </p>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>

                      <Menu
                        as="div"
                        className="relative inline-block text-left w-80"
                      >
                        <div className="">
                          <Menu.Button
                            ref={teamDropDownref}
                            className={
                              "focus-visible:outline-none popup-btn relative w-full"
                            }
                          >
                            <div className="mt-5">
                              <label
                                htmlFor="countries"
                                className="text-Neutral900 font-bold text-xs mb-2 inline-block w-full text-left"
                              >
                                How many are in your team?
                              </label>
                              <div className="relative">
                                <span className="absolute pointer-events-none z-[77] top-[50%] translate-y-[-50%] right-[16px]">
                                  <SelectDownArrow />
                                </span>
                                <input
                                  id="countries"
                                  className="relative text-Neutral800 placeholder:font-medium font-medium placeholder:text-Neutral800 text-Neutral800 px-4 placeholder:text-sm text-sm 5xl:placeholder:text-lg  w-full h-[48px] focus:outline-none border-[1.5px] rounded hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow cursor-pointer appearance-none duration-300 transition border-Neutral300 leading-[48px]"
                                  placeholder="Select one..."
                                  value={values?.team}
                                  onChange={(e) => {
                                    setNewRole(e.target.value);
                                  }}
                                />
                                <span
                                  className={
                                    "dot-box rounded-full absolute pointer-events-none top-[50%] translate-y-[-50%] left-[16px] h-2 w-2 hidden z-[1]"
                                  }
                                ></span>
                              </div>
                            </div>
                          </Menu.Button>
                          {errors?.team && touched?.team && (
                            <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                              {errors?.team}
                            </p>
                          )}
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right z-[66] absolute top-[40px] -right-[3px] mt-2 w-[323px] rounded-lg dropdowns-boxshadow bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none px-2 pt-3 pb-2">
                            <div className="mx-2">
                              <input
                                className="placeholder:font-medium text-xs placeholder:text-Neutral900 text-Neutral700 pr-4 focus:outline-none w-full mb-2 placeholder:text-sm	"
                                placeholder="Select one..."
                                value={newTeam}
                                onChange={(e) => {
                                  setNewTeam(e.target.value);
                                }}
                              />
                            </div>
                            <div className="project-title-divide ">
                              <div className="border-0   currancy-scrollbar scrollbar-w-4 relative ">
                                <h4 className="text-Neutral600 font-semibold text-[10px] pt-3 ml-2 mr-5 border-t-[1px] border-Neutral200">
                                  Choose One
                                </h4>
                                {peoplesInTeam.map((item, index) => {
                                  return (
                                    <div
                                      key={`${item}+${index}`}
                                      className="mt-2 flex items-center hover:bg-Neutral200 p-2 rounded cursor-pointer group duration-300 "
                                      onClick={(e) => {
                                        setFieldValue("team", item);

                                        setTimeout(() => {
                                          teamDropDownref?.current?.click();
                                          e.stopPropagation();
                                        }, 0);
                                      }}
                                    >
                                      <p className="text-Neutral800 group-hover:text-Neutral900 font-normal text-sm	 w-full rounded">
                                        {item}
                                      </p>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>

                      <Menu
                        as="div"
                        className="relative inline-block text-left w-80"
                      >
                        <div className="">
                          <Menu.Button
                            ref={sourceDropDownref}
                            className={
                              "focus-visible:outline-none popup-btn relative w-full"
                            }
                          >
                            <div className="mt-5">
                              <label
                                htmlFor="countries"
                                className="text-Neutral900 font-bold text-xs mb-2 inline-block w-full text-left"
                              >
                                How did you hear about us?
                              </label>
                              <div className="relative">
                                <span className="absolute pointer-events-none z-[55] top-[50%] translate-y-[-50%] right-[16px]">
                                  <SelectDownArrow />
                                </span>
                                <input
                                  id="countries"
                                  className="relative text-Neutral800 placeholder:font-medium font-medium placeholder:text-Neutral800 text-Neutral800 px-4 placeholder:text-sm text-sm 5xl:placeholder:text-lg  w-full h-[48px] focus:outline-none border-[1.5px] rounded hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow cursor-pointer appearance-none duration-300 transition border-Neutral300 leading-[48px]"
                                  placeholder="Select one..."
                                  value={values?.source}
                                  onChange={(e) => {
                                    setNewRole(e.target.value);
                                  }}
                                />
                                <span
                                  className={
                                    "dot-box rounded-full absolute pointer-events-none top-[50%] translate-y-[-50%] left-[16px] h-2 w-2 hidden z-[1]"
                                  }
                                ></span>
                              </div>
                            </div>
                          </Menu.Button>
                          {errors?.source && touched?.source && (
                            <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                              {errors?.source}
                            </p>
                          )}
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right z-[44] absolute top-[40px] -right-[3px] mt-2 w-[323px] rounded-lg dropdowns-boxshadow bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none px-2 pt-3 pb-2">
                            <div className="mx-2">
                              <input
                                className="placeholder:font-medium text-xs placeholder:text-Neutral900 text-Neutral700 pr-4 focus:outline-none w-full mb-2 placeholder:text-sm	"
                                placeholder="Select one..."
                                value={newSource}
                                onChange={(e) => {
                                  setNewSource(e.target.value);
                                }}
                              />
                            </div>
                            <div className="project-title-divide ">
                              <div className="border-0   currancy-scrollbar scrollbar-w-4 relative ">
                                <h4 className="text-Neutral600 font-semibold text-[10px] pt-3 ml-2 mr-5 border-t-[1px] border-Neutral200">
                                  Choose One
                                </h4>
                                {sources.map((item, index) => {
                                  return (
                                    <div
                                      key={`${item}+${index}`}
                                      className="mt-2 flex items-center hover:bg-Neutral200 p-2 rounded cursor-pointer group duration-300 "
                                      onClick={(e) => {
                                        setFieldValue("source", item);

                                        setTimeout(() => {
                                          sourceDropDownref?.current?.click();
                                          e.stopPropagation();
                                        }, 0);
                                      }}
                                    >
                                      <p className="text-Neutral800 group-hover:text-Neutral900 font-normal text-sm	 w-full rounded">
                                        {item}
                                      </p>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </Menu.Items>
                        </Transition>

                        {values.source === "Other (please let us know)" && (
                          <div>
                            <Field
                              placeholder="Tell us here..."
                              as="textarea"
                              id="source_value"
                              name="source_value"
                              onChange={(e) => {
                                handleTextChange(e);
                                setFieldValue("source_value", e.target.value);
                              }}
                              maxLength="120"
                              className="mt-2 resize-none relative text-Neutral800 placeholder:font-medium font-medium placeholder:text-Neutral500 text-Neutral800 px-4 py-3.5 placeholder:text-sm text-sm 5xl:placeholder:text-lg  w-full h-[94px] focus:outline-none border-[1.5px] rounded hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow cursor-pointer appearance-none duration-300 transition border-Neutral300 "
                            />
                            <div className="flex justify-end ">
                              <span className="color-Neutral700 text-xs	font-medium	">
                                {numberOfLetters}
                              </span>
                            </div>
                            {errors?.source_value && touched?.source_value && (
                              <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                                {errors?.source_value}
                              </p>
                            )}
                          </div>
                        )}
                      </Menu>

                      <div className="mt-[60px]">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="btn button-hover form-primary-btn relative text-sm text-center block text-Neutral100 bg-AccentRegular font-bold w-full py-3 rounded drop-shadow-Texts overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out"
                        >
                          {isSubmitting ? (
                            <span className="relative z-10 drop-shadow-Texts">
                              <svg
                                className="animate-spin m-auto h-5 w-5 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                            </span>
                          ) : (
                            <span className="relative drop-shadow-Texts z-10">
                              Get Started
                            </span>
                          )}
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>

          {/* <div
            className="absolute right-[48px] top-[-6px] z-30 cursor-pointer"
            onClick={() => setIsOpen(false)}
          >
            <img src={CloseBtn} alt="" className="w-6	h-6" />
          </div> */}
        </div>
      </Modal>
    </>
  );
}

export default PreWelcomeModal;
