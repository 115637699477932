const selectStyles = {
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      width: "100%",
      backgroundColor: isFocused ? "#EBF4FF" : isSelected ? "#6D6DF2" : "white",
      color: isFocused ? "#518BEC" : isSelected ? "white" : "#4B5563",
      fontSize: 14,
      cursor: "pointer",
      "&:active": {
        backgroundColor: !isSelected ? "#EBF4FF" : "#DBEAFE",
      },
      ":hover": {
        backgroundColor: "#f3f4f6",
      },
    };
  },
  control: (styles) => ({
    ...styles,
    width: "100%",
    backgroundColor: "white",
    padding: 4,
    paddingBottom: 2,
    boxShadow: "none",
    borderWidth: "1.5px",
    borderColor: "#EDEDF2",
    borderRadius: "0.25rem",
    fontFamily: "var(--inter-font)",
    "&:hover": {
      borderColor: "#6D6DF2",
    },
  }),
  menu: (styles) => ({
    ...styles,
    width: "100%",
    boxShadow:
      "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
    borderRadius: "0.375rem",
  }),
  menuList: (styles) => ({
    ...styles,
    width: "100%",
  }),
  singleValue: (styles) => ({
    ...styles,
    width: "100%",
    color: "#1a202c",
    fontWeight: 500,
    fontSize: 14,
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "#a0aec0",
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "var(--inter-font)",
  }),
  input: (styles) => ({
    ...styles,
    width: "100%",
    color: "rgb(226, 232, 240)",
  }),
  noOptionsMessage: (styles) => ({
    ...styles,
    fontSize: 14,
    fontWeight: "medium",
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    paddingLeft: 12,
    paddingRight: 12,
  }),
  menuPortal: (styles) => ({
    ...styles,
    zIndex: 9999,
  }),
};

export default selectStyles;
