import React, { Fragment, useState, useRef } from "react";
import * as Yup from "yup";
import Modal from "react-modal";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { Menu, Transition } from "@headlessui/react";
import { useSelector, useDispatch } from "react-redux";
import "react-time-picker-input/dist/components/TimeInput.css";
import TimeTrackingBadge from "../common/badges/TimeTrackingBadge";
import { UpdateTimerDetails } from "../../store/actions/timerAction";
import moment from "moment";
import { AddTimeEntryActions } from "../../store/actions/timeTrackingAction";
import CloseBtn from "../../assets/Images/close-btn.svg";
import { CreateTaskActions } from "../../store/actions/tasksAction";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
    overflow: ''
  },
};

const TaskNameModal = ({
  isStartTimerModalOpen,
  setIsStartTimerModalOpen,
  selectedProject,
  selectedClient,
  selectedTask,
}) => {
  const dispatch = useDispatch();
  const [newTitle, setNewTitle] = useState("");
  const { time, project } = useSelector((state) => state.timer);
  const user = useSelector((state) => state.auth.user);
  const projectDetail = useSelector((state) => state.project.projectDetail);
  const assignTimeValidation = Yup.object().shape({
    title: Yup.string().required("Choose client title"),
    category: Yup.string().required("Choose category"),
  });

  

  if (!isStartTimerModalOpen) {
    return null;
  }

  return (
    <>
      <Modal
        isOpen={isStartTimerModalOpen}
        onRequestClose={() => setIsStartTimerModalOpen(false)}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="Example Modal"
      >
        <div className="relative bg-Neutral000 rounded-lg text-left transform transition-all sm:my-8 sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="bg-Neutral000 px-12 pt-8 pb-10  rounded-[8px]">
            <div>
              <div className="border-b border-Neutral200 flex items-center justify-between pb-2 leading-[110%]">
                <h4 className="text-Neutral700 text-xs font-semibold  uppercase">
                  Timer Details
                </h4>
                <TimeTrackingBadge time={time.value} />
              </div>
              <Formik
                initialValues={{
                  title: user?.title
                    ? user?.title
                    : selectedTask?.title
                    ? selectedTask?.title
                    : "",
                  category: user?.timer_category ? user?.timer_category : "",
                  project: user?.project
                    ? user?.project
                    : selectedProject
                    ? selectedProject
                    : project?._id,
                  memberId: 1,
                  color: "",
                  client: user?.client
                    ? user?.client
                    : selectedClient
                    ? selectedClient
                    : project?.client?._id,
                  task: user?.task
                    ? user?.task
                    : selectedTask?._id
                    ? selectedTask?._id
                    : null,
                }}
                enableReinitialize
                validationSchema={assignTimeValidation}
                onSubmit={async (values, { setSubmitting, setErrors }) => {
                  setSubmitting(true);
                  if (user?.project && user?.start_time) {
                    const data = {
                      ...values,
                      date: moment().format("YYYY-MM-DD"),
                    };
                    await dispatch(
                      CreateTaskActions({
                        client_id: values.client,
                        title: values.title,
                        project: values.project,
                        tag: "not_started",
                        status: "to_do",
                        column: projectDetail?.data?.task_column[0]?.name,
                        type: "GENERAL",
                      })
                    );
                    await dispatch(
                      AddTimeEntryActions(
                        { ...data, type: "timer" },
                        setSubmitting,
                        setErrors,
                        setIsStartTimerModalOpen
                      )
                    );
                  } else {
                    dispatch(UpdateTimerDetails({ data: values }));
                    setSubmitting(false);
                    setIsStartTimerModalOpen(false);
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="">
                      <div className="relative mt-5">
                        <label
                          htmlFor="title"
                          className="text-Neutral900 font-bold text-xs mb-2 inline-block w-full text-left"
                        >
                          Task
                        </label>
                        <div className="relative">
                          <input
                            value={values.title}
                            id="title"
                            type="text"
                            name="title"
                            className="relative text-Neutral800 placeholder:font-medium px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full h-[48px] focus:outline-none border-[1.5px] rounded hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow appearance-none duration-300 transition border-Neutral300"
                            placeholder="Eg. Wireframing"
                            onChange={handleChange}
                            disabled={user?.title ? true : false}
                          />
                        </div>
                        {errors?.title && touched?.title && (
                          <p className="mt-1 text-[14px] text-Red400 font-medium focus-visible:outline-none absolute">
                            {errors?.title}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="flex items-center justify-between relative flex-wrap flex-row w-full mt-8 mb-3">
                      {user?.project && user?.start_time ? (
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="h-[48px] w-full button-hover uppercase relative text-sm text-center block text-Neutral100 bg-AccentRegular font-bold py-3 rounded overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out uppercase leading-[110%] max-w-[154px]"
                        >
                          {isSubmitting ? (
                            <span className="relative z-10 drop-shadow-Texts">
                              <svg
                                className="animate-spin m-auto h-5 w-5 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                            </span>
                          ) : (
                            "Save"
                          )}
                        </button>
                      ) : (
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="h-[48px] button-hover w-full uppercase relative text-sm text-center block text-Neutral100 bg-AccentRegular font-bold py-3 rounded overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out uppercase leading-[110%]"
                        >
                          Start Timer
                        </button>
                      )}
                      {/* <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsStartTimerModalOpen(false);
                        }}
                        disabled={isSubmitting}
                        className="h-[48px] uppercase text-sm text-center block text-AccentRegular bg-Neutral200 font-bold w-full py-3 rounded overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out uppercase hover:bg-Neutral400 leading-[110%]"
                      >
                        Cancel
                      </button> */}
                      {user?.project && user?.start_time ? (
                        <Menu>
                          <Menu.Button className="h-[48px] relative uppercase text-sm text-center block text-Red600 bg-Neutral200 font-bold w-full py-3 rounded overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out uppercase hover:bg-Neutral400 leading-[110%] max-w-[154px]">
                            Delete
                          </Menu.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="origin-top-right absolute top-[48px] right-[0px] pt-4 pb-6 w-[238px] px-6 rounded-lg Timetrackingdropdown bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none z-10">
                              <p className="text-Neutral800 text-left px-4 py-2 text-xs whitespace-nowrap flex justify-start items-center cursor-pointer font-semibold hover:bg-Neutral200 transition duration-300">
                                Are you sure?
                              </p>

                              <button
                                className="border-0 overflow-hidden w-[100%] mt-5"
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  dispatch(UpdateTimerDetails({ data: null }));
                                  setIsStartTimerModalOpen(false);
                                }}
                                disabled={isSubmitting}
                              >
                                <h3 className="text-white text-xs  w-full bg-Red400 font-semibold py-4 rounded text-center cursor-pointer hover:bg-Red600 duration-300">
                                  Yes, Delete it
                                </h3>
                              </button>
                              <Menu.Button className="overflow-hidden border-0 !border-transparent divide-0 w-[100%]">
                                <h3 className="text-Neutral800 bg-Neutral200 font-semibold text-xs mt-3 w-full py-4 rounded text-center cursor-pointer hover:bg-Neutral500 duration-300 hover:text-Neutral900">
                                  No, Keep it
                                </h3>
                              </Menu.Button>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      ) : null}
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => {
              setIsStartTimerModalOpen(false);
            }}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TaskNameModal;
