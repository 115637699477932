import React from 'react';

function Skeleton() {
  return (
    <div className='w-[100%] mt-6'>
      <div className='flex flex-col items-stretch justify-between'>
        <div className='flex items-center justify-center mb-6'>
          <div className='h-[28px] w-[28px] min-w-[28px] 5xl:h-16 5xl:w-16 rounded-[50%] bg-[#f6f7f8] block mr-4 bg-no-repeat skeleton-animation relative'></div>
          <div className='w-[100px] h-[14px] bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative mr-auto'></div>
          <div className='w-[90px] h-[28px] bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative ml-auto'></div>
        </div>
        <div className='flex items-center justify-between'>
          <div className='flex flex-col border-b-[0.5px] border-Neutral300 pb-6 w-full'>
            <div className='w-[140px] h-[14px] bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative'></div>
            <div className='w-[140px] h-[14px] bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative mt-3'></div>
            <div className='w-[140px] h-[14px] bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative mt-3'></div>
          </div>
          <div className='flex flex-col border-b-[0.5px] border-Neutral300 pb-6 w-full'>
            <div className='w-[170px] h-[14px] bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative'></div>
            <div className='w-[170px] h-[14px] bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative mt-3'></div>
            <div className='w-[170px] h-[14px] bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative mt-3'></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Skeleton;
