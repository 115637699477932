import { useEffect, useState } from "react";

import ScribbleArrow from "../../Icons/ScribbleArrow";
import CloudLoaderIcon from "../../../assets/Images/Cloud-loader.svg";
import CloundSuccess from "../../../assets/Images/Cloud-success-green.svg";

function SaveAsTemplateSection({
  clientDetail = {},
  handleSavingTemplate = () => {},
  handleUpdatingTemplate = () => {},
  isLoading = false,
}) {
  const [templateName, setTemplateName] = useState("");

  useEffect(() => {
    setTemplateName(clientDetail?.data?.template_to?.title);
  }, [clientDetail]);

  return (
    <div className="mb-[64px] rounded w-full 6xl:max-w-[1060px] 5xl:max-w-[900px] 2xl:max-w-[800px] max-w-[782px]">
      <h5 className="text-[12px] text-Neutral700 mb-[20px] font-semibold tracking-[0.04em] uppercase">
        {clientDetail?.data?.template_to?._id
          ? `Update Template`
          : `Save As New Template`}
      </h5>

      <div className="flex flex-col justify-between mb-5 md:flex-row">
        <div className="flex flex-col justify-between  mr-[46px]">
          <div className="mb-3 w-[320px]">
            <input
              type="text"
              placeholder="Eg. My Custom Project Template"
              className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
            />
          </div>
          <button
            className="w-[320px] h-[48px] mb-5 rounded flex justify-center items-center tracking-[0.04em] text-[12px] text-Neutral000 bg-AccentRegular disabled:bg-AccentLight uppercase font-bold hover:bg-AccentMediumDark"
            onClick={() =>
              clientDetail?.data?.template_to
                ? handleUpdatingTemplate(
                    templateName,
                    clientDetail?.data?.template_to?._id
                  )
                : handleSavingTemplate(templateName)
            }
            disabled={
              !templateName ||
              templateName === clientDetail?.data?.template_to?.title
            }
          >
            {isLoading ? (
              <span className="relative z-10 drop-shadow-Texts">
                <svg
                  className="w-5 h-5 m-auto text-white animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            ) : clientDetail?.data?.template_to?._id ? (
              `UPDATE TEMPLATE`
            ) : (
              `SAVE AS TEMPLATE`
            )}
          </button>
          <div className="w-full">
            {isLoading ? (
              <span className="text-[#757580] text-xs font-semibold leading-4 ml-2.5 flex items-center">
                <img src={CloudLoaderIcon} alt="" className="mr-1 w-[35px]" />
                Saving...
              </span>
            ) : (
              <div>
                {clientDetail?.data?.template_to?._id ? (
                  <span className="text-Green500 text-xs font-semibold leading-4 ml-2.5 flex items-center">
                    <img src={CloundSuccess} alt="" className="mr-1 w-[35px]" />
                    Saved
                  </span>
                ) : null}
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center gap-5 px-5 rounded-sm bg-Neutral200">
          <ScribbleArrow size={132} />

          <p className="text-[12px] text-neutral-800">
            This will update existing or create a new reusable template using
            essential data from this project. Find your template on the{" "}
            <a className="font-bold text-AccentRegular" href="/admin/templates">
              Templates{" "}
            </a>
            screen...
          </p>
        </div>
      </div>
    </div>
  );
}

export default SaveAsTemplateSection;
