import axios from "axios";
import { toast } from "react-toastify";

export const CreateEventAction = (
  value,
  setSubmitting,
  setErrors,
  openModal,
  execute = () => {}
) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post("api/v1/event/create-event", value);
      setSubmitting(false);
      toast.success("Event created successfully");
      openModal(false);
      await execute(data.data);
      return dispatch({
        type: "CREATE_EVENT_SUCCESS",
        payload: {
          data: data.data,
        },
      });
    } catch (error) {
      if (error?.response?.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setSubmitting(false, true);
        setErrors(err);
        return dispatch({
          type: "CREATE_EVENT_ERROR",
          payload: error,
        });
      } else {
        openModal(false);
        toast.error(error?.response?.data?.message);
        setSubmitting(false);
        return dispatch({
          type: "CREATE_EVENT_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const UpdateEventAction = (
  value,
  setSubmitting,
  setErrors,
  openModal,
  updateEvent
) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.put("api/v1/event/update-event", value);
      setSubmitting(false);
      toast.success("Event Updated successfully");
      openModal(false);
      await updateEvent(data.data);
      return dispatch({
        type: "UPDATE_EVENT_SUCCESS",
        payload: {
          data: data.data,
        },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setSubmitting(false, true);
        setErrors(err);
        return dispatch({
          type: "UPDATE_EVENT_ERROR",
          payload: error,
        });
      } else {
        openModal(false);
        toast.error(error?.response?.data?.message);
        setSubmitting(false);
        return dispatch({
          type: "UPDATE_EVENT_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const DeleteEventAction = (
  id,
  setDeleting,
  openModal,
  deleteGoogleCalendarEvent
) => {
  return async (dispatch) => {
    try {
      await axios.delete(`api/v1/event/delete-event?id=${id}`);
      toast.success("Event Deleted successfully");
      openModal(false);
      setDeleting(false);
      await deleteGoogleCalendarEvent(id);
      return dispatch({
        type: "DELETE_EVENT_SUCCESS",
        payload: {
          data: id,
        },
      });
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        setDeleting(false);
        return dispatch({
          type: "DELETE_EVENT_ERROR",
          payload: error,
        });
      } else {
        openModal(false);
        toast.error(error?.response?.data?.message);
        setDeleting(false);
        return dispatch({
          type: "DELETE_EVENT_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const GetEventsAction = (start_date, end_date) => {
  return async (dispatch) => {
    try {
      // dispatch({
      //   type: "SET_CLIENTS_LIST",
      //   payload: { isLoading: true },
      // });
      let { data } = await axios.get(
        `api/v1/event/get-events?start_date=${start_date}&end_date=${end_date}`
      );
      // setModal(false);
      // setSubmitting(false);
      // toast.success("Client created successfully");
      return dispatch({
        type: "SET_EVENTS",
        payload: {
          isLoading: false,
          start_date: start_date,
          end_date: end_date,
          data: data.data,
        },
      });
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "EVENT_LIST_GET_ERROR",
          payload: error,
        });
      } else {
        return dispatch({
          type: "EVENT_LIST_GET_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const GetEventsForReminderAction = (start_date, end_date) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.get(
        `api/v1/event/get-reminders-events?start_date=${start_date}&end_date=${end_date}`
      );
      return dispatch({
        type: "SET_EVENTS_FOR_REMINDER",
        payload: {
          isLoading: false,
          start_date: start_date,
          end_date: end_date,
          data: data.data,
        },
      });
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "EVENT_LIST_GET_ERROR",
          payload: error,
        });
      } else {
        return dispatch({
          type: "EVENT_LIST_GET_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const MarkDoneAllReminderAction = (start_date, end_date) => {
  return async (dispatch) => {
    try {
      await axios.post(`api/v1/event/mark-read-all-events`);
      return dispatch({
        type: "SET_EVENTS_FOR_REMINDER",
        payload: {
          isLoading: false,
          start_date: null,
          end_date: null,
          data: [],
        },
      });
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "EVENT_LIST_GET_ERROR",
          payload: error,
        });
      } else {
        return dispatch({
          type: "EVENT_LIST_GET_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const RemoveEventsForReminderAction = (id) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post(`api/v1/event/remove-reminders-events`, {
        id,
      });
      return dispatch({
        type: "REMOVE_EVENTS_FOR_REMINDER",
        payload: {
          isLoading: false,
          data: data.data,
        },
      });
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "EVENT_LIST_GET_ERROR",
          payload: error,
        });
      } else {
        return dispatch({
          type: "EVENT_LIST_GET_ERROR",
          payload: error,
        });
      }
    }
  };
};
