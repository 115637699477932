import React from "react";

function AssetsListLoader() {
  return (
    <>
      <div className="w-[388px] h-[264px] border border-Neutral300 p-6 rounded relative mb-[60px]">
        <div className="5xl:max-w-[25rem] lg:max-w-[22rem] lg:w-full md:w-[48%] w-full">
          <div className="bg-Neutral000 rounded-lg mb-2 max-w-full pb-3 flex justify-between items-center">
            <div className="flex items-center">
              <div className="rounded-full w-8 bg-[#f6f7f8] block h-8 bg-no-repeat skeleton-animation relative"></div>
              <div className="w-[7.125rem] ml-2 bg-[#f6f7f8] inline-block h-4 bg-no-repeat skeleton-animation relative"></div>
            </div>
            <div className="rounded-full w-8 bg-[#f6f7f8] block h-8 bg-no-repeat skeleton-animation relative"></div>
          </div>
          {Array.from({ length: 4 }).map((index) => {
            return (
              <div
                key={index}
                className="bg-Neutral000 rounded-lg mb-2 max-w-full py-2 px-1 pt-[0.9rem] flex justify-between"
              >
                <div className="rounded w-2/6 bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative"></div>
                <div className="rounded w-[3.125rem] bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative"></div>
              </div>
            );
          })}
        </div>
      </div> <div className="w-[388px] h-[264px] border border-Neutral300 p-6 rounded relative mb-[60px]">
      <div className="5xl:max-w-[25rem] lg:max-w-[22rem] lg:w-full md:w-[48%] w-full">
        <div className="bg-Neutral000 rounded-lg mb-2 max-w-full pb-3 flex justify-between items-center">
          <div className="flex items-center">
            <div className="rounded-full w-8 bg-[#f6f7f8] block h-8 bg-no-repeat skeleton-animation relative"></div>
            <div className="w-[7.125rem] ml-2 bg-[#f6f7f8] inline-block h-4 bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="rounded-full w-8 bg-[#f6f7f8] block h-8 bg-no-repeat skeleton-animation relative"></div>
        </div>
        {Array.from({ length: 2 }).map((index) => {
          return (
            <div
              key={index}
              className="bg-Neutral000 rounded-lg mb-2 max-w-full py-2 px-1 pt-[0.9rem] flex justify-between"
            >
              <div className="rounded w-2/6 bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative"></div>
              <div className="rounded w-[3.125rem] bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative"></div>
            </div>
          );
        })}
      </div>
    </div>
    <div className="w-[388px] h-[264px] border border-Neutral300 p-6 rounded relative mb-[60px]">
      <div className="5xl:max-w-[25rem] lg:max-w-[22rem] lg:w-full md:w-[48%] w-full">
        <div className="bg-Neutral000 rounded-lg mb-2 max-w-full pb-3 flex justify-between items-center">
          <div className="flex items-center">
            <div className="rounded-full w-8 bg-[#f6f7f8] block h-8 bg-no-repeat skeleton-animation relative"></div>
            <div className="w-[7.125rem] ml-2 bg-[#f6f7f8] inline-block h-4 bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="rounded-full w-8 bg-[#f6f7f8] block h-8 bg-no-repeat skeleton-animation relative"></div>
        </div>
        {Array.from({ length: 1 }).map((index) => {
          return (
            <div
              key={index}
              className="bg-Neutral000 rounded-lg mb-2 max-w-full py-2 px-1 pt-[0.9rem] flex justify-between"
            >
              <div className="rounded w-2/6 bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative"></div>
              <div className="rounded w-[3.125rem] bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative"></div>
            </div>
          );
        })}
      </div>
    </div>
    </>
  );
}

export default AssetsListLoader;
