const initialState = {
  isLoading: true,
  data: null,
  cname_data: null,
  certificate_details: null,
  isvarifyDomain: false,
  isDomainWorking: false,
  isReqForAcmCerti: false,
};

const customDoaminReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "HANDLE_LOADING":
      return {
        isLoading: payload.isLoading,
      };
    case "UPDATE_CUSTOM_DOMAIN":
      return {
        ...state,
        data: payload.data,
      };
    case "GET_CUSTOM_DOMAIN":
      return {
        ...state,
        data: payload.all_data,
        cname_data: payload.cname_data,
        certificate_details: payload.certificate_details,
      };
    case "IS_VERIFY_DOMAIN":
      return {
        ...state,
        isvarifyDomain: payload.data,
      };
    case "IS_DOMAIN_WORKING":
      return {
        ...state,
        isDomainWorking: payload.data,
      };
    case "IS_REQ_FOR_ACM_CERTI":
      return {
        ...state,
        isReqForAcmCerti: payload.data,
      };
    default:
      return state;
  }
};

export default customDoaminReducer;
