import React from "react";

function ProposalsIcon({
  color = "Neutral800",
  width = 24,
  className = "text-[#5A5A66]",
}) {
  return (
    <svg
      width={width}
      height={width}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.75 3H6.75C6.28587 3 5.84075 3.18437 5.51256 3.51256C5.18437 3.84075 5 4.28587 5 4.75V18.75C5 19.2141 5.18437 19.6592 5.51256 19.9874C5.84075 20.3156 6.28587 20.5 6.75 20.5H17.25C17.7141 20.5 18.1592 20.3156 18.4874 19.9874C18.8156 19.6592 19 19.2141 19 18.75V8.25L13.75 3Z"
        stroke="#5A5A66"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.75 3V8.25H19"
        stroke="#5A5A66"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.25 10.125H9.375H8.5"
        stroke="#5A5A66"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.25 13H9.375H8.5"
        stroke="#5A5A66"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.75 13H13.875H13"
        stroke="#5A5A66"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.75 16H13.875H13"
        stroke="#5A5A66"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.25 15.875H9.375H8.5"
        stroke="#5A5A66"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ProposalsIcon;
