import React, { useState } from "react";
import { useDispatch } from "react-redux";
import TrashDark from "../../assets/Images/Trash-dark.svg";
import { DeleteQustions } from "../../store/actions/questionnaireAction";
import { DeleteTemplateQuestionnaireQuestion } from "../../store/actions/templateAction";

const DeleteQuestionsButton = ({
  questionId,
  questionnaireID,
  is_template = false,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const handleDeleteQuestion = () => {
    setLoading(true);
    if (is_template) {
      dispatch(
        DeleteTemplateQuestionnaireQuestion(
          questionnaireID,
          questionId,
          setLoading
        )
      );
    } else {
      dispatch(DeleteQustions(questionId, questionnaireID, setLoading));
    }
  };

  return (
    <>
      <button
        className="text-AccentDark bg-AccentLight flex items-center justify-center text-xs rounded h-8 5xl:w-[100px] w-[80px] border border-Neutral300 focus-visible:outline-none focus:outline focus:outline-4 focus:outline-AccentMediumLight font-semibold hover:bg-AccentMediumLight transition duration-300"
        type="button"
        disabled={loading}
        onClick={() => handleDeleteQuestion(questionId)}
      >
        {loading ? (
          <div className="flex justify-center">
            <div className="pr-2 white dot-falling"></div>
          </div>
        ) : (
          <>
            Delete
            <img className="ml-1" src={TrashDark} alt="" />{" "}
          </>
        )}
      </button>
    </>
  );
};

export default DeleteQuestionsButton;
