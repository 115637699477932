import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import CloseBtn from "../../assets/Images/close-btn.svg";
import {
  DeleteProjectAction,
  RestoreArchivedProjectAction,
  GetArchivedProjectAction,
} from "../../store/actions/projectAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import prepareTasksAndTimeCounter from "../../utils/prepareTasksAndTimeCounter";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const ProjectItem = ({ item }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [archiving, setArchiving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const hours = prepareTasksAndTimeCounter(
    item?.total_time
      ? item?.total_time?.split(":")[0].charAt(0) === "0"
        ? item?.total_time?.split(":")[0].charAt(1)
        : item?.total_time?.split(":")[0]
      : 0,
    true
  );
  const minutes = prepareTasksAndTimeCounter(
    item?.total_time
      ? item?.total_time?.split(":")[1].charAt(0) === "0"
        ? item?.total_time?.split(":")[1].charAt(1)
        : item?.total_time?.split(":")[1]
      : 0,
    true
  );

  const totalUniqueContributors = new Set(
    [...item?.time_contributors, ...item?.task_contributors].map((c) => c._id)
  ).size;

  const handleArchiving = async (id) => {
    setArchiving(true);
    await dispatch(RestoreArchivedProjectAction(id, navigate));
    setArchiving(false);
  };

  const handleDeleting = async (id) => {
    setDeleting(true);
    await dispatch(DeleteProjectAction(id, navigate));
    setDeleting(false);
  };
  return (
    <div className="mt-6 pb-2 flex flex-col items-start border-b-[0.5px] border-Neutral300">
      <div className="flex">
        <div className="img mr-[20px]">
          {!item?.profile && !item?.client?.profile_path ? (
            <div className="border-Neutral300 w-[40px] h-[40px] flex items-center justify-center rounded-[4px] bg-Neutral400 text-Neutral700 focus-visible:outline-none">
              <span className=" text-[30px] 5xl:text-[24px] w-full h-full uppercase flex items-center justify-center rounded-[4px] font-bold">
                {item?.client?.client_name[0]}
              </span>
            </div>
          ) : (
            <img
              src={item?.client?.profile_path || item?.profile_path}
              alt="client logo"
              className="w-[40px] h-[40px] rounded-[4px]"
            />
          )}
        </div>
        <div className="meta cursor-pointer ">
          <a
            className="text-indigo-500 rounded w-full flex flex-col"
            href={`/admin/clients/manage/${item?.client?._id}`}
          >
            <span className="text-neutral-800 text-[10px] bg-Neutral300 rounded-[1.375rem] px-2.5 py-0.5 font-bold uppercase max-w-fit">
              {item?.client?.client_name}
            </span>
            <div className="text-[16px] font-bold py-[1px] w-max">
              <span className="text-neutral-800">{item?.title}</span>
            </div>
          </a>
        </div>
      </div>
      <div className="flex my-[24px]  justify-between w-full gap-2">
        <div className="flex justify-between flex-col w-1/2">
          <div className="flex justify-between">
            <p className="text-Neutral500 text-[10px] 5xl:text-sm leading-[14px]">
              ARCHIVE DATE
            </p>
            <p className="text-Neutral800 text-[12px] text-left 5xl:text-sm leading-[14px]">
              {moment(item.archived_at).format("DD/MM/YYYY")}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="text-Neutral500 text-[10px] 5xl:text-sm leading-[14px]">
              TASKS
            </p>
            <p className="text-Neutral800 text-[12px] text-left 5xl:text-sm leading-[14px]">
              {item.total_tasks}
            </p>
          </div>
        </div>
        <div className="flex justify-between flex-col w-1/2">
          <div className="flex justify-between">
            <p className="text-Neutral500 text-[10px] 5xl:text-sm leading-[14px]">
              CONTRIBUTORS
            </p>
            <p className="text-Neutral800 text-[12px] text-left 5xl:text-sm leading-[14px]">
              {totalUniqueContributors}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="text-Neutral500 text-[10px] 5xl:text-sm leading-[14px]">
              TRACKED TIME
            </p>
            <p className="text-Neutral800 text-[12px] text-left 5xl:text-sm leading-[14px]">
              {hours}h{minutes}m
            </p>
          </div>
        </div>
      </div>
      <div className="flex w-full gap-2">
        <button
          className="text-[12px] 5xl:text-sm text-zinc-600 bg-Neutral300 flex items-center w-1/2 justify-center  h-[28px]  rounded font-semibold focus-visible:outline-none hover:bg-Neutral500 transition-all duration-300s"
          onClick={() => handleArchiving(item._id)}
          disabled={archiving}
        >
          {archiving ? "Restoring" : "Restore"}
        </button>
        <button
          className="text-[12px] 5xl:text-sm text-white bg-rose-400 flex items-center justify-center w-1/2 h-[28px]  rounded font-semibold focus-visible:outline-none hover:bg-rose-500 transition-all duration-300s"
          onClick={() => handleDeleting(item._id)}
          disabled={deleting}
        >
          {deleting ? "Deleting" : "Permanently Delete"}
        </button>
      </div>
    </div>
  );
};

function ArchivedProjectsListModal({ isOpen, setModal }) {
  const dispatch = useDispatch();
  const archivedProjectList = useSelector(
    (state) => state.project.archivedProjectList
  );

  useEffect(() => {
    dispatch(GetArchivedProjectAction());
  }, [dispatch]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setModal(false)}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="Archived project list Modal"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[560px] sm:min-w-[480px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="bg-white px-12 py-8 rounded-[8px]">
          <div className="h-[506px] block">
            <div className="text-left">
              <h3
                className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-2"
                id="modal-title"
              >
                ARCHIVED PROJECTS
              </h3>
              <p className="text-Orange900 bg-Orange100 px-3 py-2 text-[12px] 5xl:text-sm leading-[1.4] font-normal rounded">
                <span className="font-semibold">Warning:</span> Deleting a
                project is an irreversible action.
              </p>
              {!archivedProjectList.isLoading && archivedProjectList.data.length
                ? archivedProjectList.data.map((item, index) => {
                    return <ProjectItem item={item} key={index} />;
                  })
                : null}
              {!archivedProjectList.isLoading &&
              !archivedProjectList.data.length ? (
                <h5 className="text-Neutral700 text-sm font-normal mb-3 text-center pt-10">
                  There are currently no archived projects.
                </h5>
              ) : null}
            </div>
          </div>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => setModal(false)}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
}

export default ArchivedProjectsListModal;
