/** @param {import("react").SVGProps} props - SVG Icon Props */
function TrashIcon({ props }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.23047 5.04395H4.33132H13.1382"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.98136 5.04444V4.02222C5.98136 3.75111 6.09734 3.49111 6.30379 3.2994C6.51024 3.1077 6.79025 3 7.08222 3H9.28393C9.57589 3 9.8559 3.1077 10.0623 3.2994C10.2688 3.49111 10.3848 3.75111 10.3848 4.02222V5.04444M12.0361 5.04444V12.2C12.0361 12.4711 11.9201 12.7311 11.7136 12.9228C11.5072 13.1145 11.2272 13.2222 10.9352 13.2222H5.43093C5.13897 13.2222 4.85896 13.1145 4.65251 12.9228C4.44606 12.7311 4.33008 12.4711 4.33008 12.2V5.04444H12.0361Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default TrashIcon;
