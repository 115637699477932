import React from "react";
import clx from "classnames";
import { useSelector } from "react-redux";

function MoneyTimeTrackingBadge({
  amount = null,
  className = "",
  asMoney = false,
}) {
  const clientDetail = useSelector((state) => state.client.clientDetail);
  if (amount === null || amount === "") {
    return null;
  }
  return (
    <span
      className={clx(
        "rounded-[16px] px-3 py-[6px] text-center bg-Neutral300 flex items-start justify-center text-Neutral900 w-fit font-semibold text-xs leading-[16px] min-w-[80px]",
        className
      )}
    >
      {/* {asMoney ? "$" : ""} */}
      {asMoney ? (
        <span className="text-[8px] leading-[14px] inline-block pr-[2px]">
          {clientDetail?.data?.currency?.symbol}
        </span>
      ) : (
        ""
      )}
      {asMoney ? amount.toFixed(2) : amount}
    </span>
  );
}

export default MoneyTimeTrackingBadge;
