import React from "react";

function ChevronDownIcon({
  width = 12,
  className = "",
  color = "currentColor",
}) {
  return (
    <svg
      width={width}
      height={width}
      className={className}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 4.5L6 7.5L3 4.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ChevronDownIcon;
