import React from "react";
import clx from "classnames";

import UserIcon from "../../Icons/UserIcon";
import TeamMemberCard from "../cards/TeamMemberCard";

function ContributorsList({ members = [], className = "" }) {
  if (!members.length) {
    return (
      <div className={clx("flex justify-start items-center", className)}>
        <div className="bg-Neutral200 rounded-[50%] w-[28px] h-[28px] flex justify-center items-center mr-2">
          <UserIcon />
        </div>
        <p className="text-[10px] italic mb-0 text-Neutral700">
          No one has contributed to this project...
        </p>
      </div>
    );
  }

  if (!Array.isArray(members)) {
    return null;
  }

  const membersCards = members.map((member, idx) => (
    <TeamMemberCard key={member?.id ?? idx} member={member} />
  ));

  return (
    <ul className={clx("flex flex-wrap list-none gap-3", className)}>
      {membersCards}
    </ul>
  );
}

export default ContributorsList;
