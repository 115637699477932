function normalizeNames(names) {
  if (!Array.isArray(names) || names.length === 0) {
    return "";
  }

  if (names.length === 1) {
    return names[0];
  }

  const allButLast = names.slice(0, -1).join(", ");
  const last = names[names.length - 1];

  return `${allButLast} and ${last}`;
}

export default normalizeNames;
