import React from "react";
import { Formik } from "formik";

const NameInput = ({ setSignInDetail, signInDetail }) => {
  return (
    <Formik
      initialValues={{ name: signInDetail?.name }}
      enableReinitialize
      validateOnBlur={false}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        /* and other goodies */
      }) => (
        <div>
          <div className="mb-[24px]">
            <div className="mt-2">
              <div className="relative passwordshow mt-2">
                <input
                  className={`text-Neutral800 font-medium placeholder:text-Neutral500 p-3 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded-[8px] border-Neutral300 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition max-w-[434px] ${
                    errors.name && touched.name
                      ? "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow pr-12"
                      : ""
                  } focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow  transition duration-300 ease-in-out`}
                  type="text"
                  placeholder="Eg. Luke"
                  name="name"
                  autocomplete="off"
                  onChange={(e) => {
                    handleChange(e);
                    setSignInDetail({
                      ...signInDetail,
                      name: e.target.value,
                    });
                  }}
                  onBlur={handleBlur}
                  value={values.name}
                />
              </div>
            </div>
            {errors.name && touched.name && (
              <p className="mt-2 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                {errors.name}
              </p>
            )}
          </div>
        </div>
      )}
    </Formik>
  );
};

export default NameInput;
