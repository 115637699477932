import React from "react";

function InvoiceDashboardLoader() {
  return (
    <div className="5xl:px-[96px] px-6 md:px-12 w-full pt-0">
      <div className="mb-[68px]">
        {/* <div className="md:mt-[32px] mt-[16px] text-right">
          <div className="flex items-center justify-between">
            <div className="w-[171px] rounded bg-Neutral000 skeleton-animation relative h-[44px]"></div>
            <div className="w-[600px] rounded bg-Neutral000 skeleton-animation relative h-[44px]"></div>
          </div>
        </div>
        <div className="mt-[42px] flex items-center justify-between mb-8 bg-Neutral000 skeleton-animation relative h-[16px] w-full"></div> */}
        <div className="flex flex-col items-start justify-start w-full gap-4">
          {Array.from({ length: 7 }).map((ele, index) => {
            return (
              <div
                className="flex flex-col p-4 rounded bg-Neutral000 w-full skeleton-animation relative h-[86px]"
                key={index}
              ></div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default InvoiceDashboardLoader;
