import axios from "axios";

const getContentHeightOfPage = (page, ignoreElementId) => {
    let totalContentHeight = 0
    for (const child of page.children) {
        if (!ignoreElementId.includes(child.id)) {
          // Add individual element height to the total
          totalContentHeight += child.offsetHeight;
        }
      }
      return totalContentHeight;
}

const getIpAddress = async () => {
    const res = await fetch('https://api.ipify.org?format=json');
    const json = await res.json()
    return json
  };

export {
    getContentHeightOfPage,
    getIpAddress
}