const initialState = {
  hasNewContent: true,
};

export default function contentReducer(state = initialState, action) {
  if (action.type === "FLIP_CONTENT_SWITCH") {
    return { ...state, hasNewContent: action.payload };
  }
  return state;
}
