import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import update from 'immutability-helper';

import Filter from '../../../components/filter/filter';
import leadsColors from '../../../assets/json/leads-colors.json';
import LeadsCard from './LeadsCard';
import LeadDetailsModal from '../../Modals/LeadDetailsModal';
import {
	CloseModalAction,
	OpenModalAction,
} from '../../../store/actions/modalsAction';
import EditColumnColorsModal from '../../Modals/EditColumnColorModal';
import EditColorMenu from './EditColorMenu';
import {
	createLeadColumnAction,
	deleteLeadAction,
	removeLeadColumnAction,
	updateLeadColumnAction,
	updateLeadColumnPositionAction,
} from '../../../store/actions/leadAction';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';

const LeadsList = ({
	setIsOpenColumn = () => {},
	isOpenColumn,
	leads,
	setLeads = () => {},
	setChannel = () => {},
	renderColor = () => {},
}) => {
	const { name, isOpen } = useSelector((state) => state?.modals?.modal);
	const user = useSelector((state) => state?.auth?.user);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [editColumn, setEditColumn] = useState(null);
	const [isEditColorOpen, setIsEditColorOpen] = useState(false);
	const [filterBtn, setFilterBtn] = useState(false);
	const [isAnimated, setIsAnimated] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [columns, setColumns] = useState(user?.lead_column);
	const [column, setColumn] = useState('');
	const [addfilter, setAddFilter] = useState({
		urgentonly: [],
		status: [],
		assignedto: [],
		date: [],
		categories: [],
	});

	useEffect(() => {
		setColumns(user?.lead_column);
	}, [user?.lead_column]);

	const handleAddColumn = async () => {
		if (column && column !== '') {
			await dispatch(
				createLeadColumnAction(
					{
						column: { name: column, color: renderColor() },
					},
					navigate,
				),
			);
		}
		setIsOpenColumn(false);
		setColumn(null);
	};

	const handleOpen = (data) => {
		navigate({ search: `?cardId=${data?._id}` });
	};

	const onLeadCardClick = (lead) => {
		handleOpen(lead);
		dispatch(OpenModalAction('lead_details'));
	};

	const onDeleteLead = async (id) => {
		const deleteIdx = leads.findIndex((item) => item._id === id);
		if (deleteIdx > -1) {
			await dispatch(deleteLeadAction(id));
			navigate({ search: '' });
		}
	};

	const onApplyColor = (
		updatedColumn,
		type = 'Default',
		color = '',
		columnName,
		setIsOpen,
	) => {
		setIsLoaded(true);
		const colIdx = columns.findIndex(
			(item) => item?._id === updatedColumn?._id,
		);
		if (colIdx > -1) {
			setColumns((prev) => {
				if (type === 'Default') {
					prev[colIdx].color = color;
				} else {
					prev[colIdx].color = {
						type,
						text: color,
						badge: color + '0D',
						background: 'Neutral200',
						backgroundOpacity: color + '08',
					};
				}
				return prev;
			});
			dispatch(
				createLeadColumnAction(
					{
						column: {
							...updatedColumn,
							name: columnName,
							color: color,
						},
						old_column: updatedColumn,
					},
					navigate,
					setIsLoaded,
					setIsOpen,
				),
			);
		}
	};

	const handelSet = () => {
		setIsAnimated(true);
		setTimeout(() => {
			setIsAnimated(false);
			setFilterBtn(!filterBtn);
		}, 350);
	};

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	const changeLeadStatus = useCallback(
		(id, column, sourceIndex, destinationIndex, dropContext) => {
			let lead = leads.find((lead) => lead._id === id);
			const leadIndex = leads.findIndex((item) => item._id === lead._id);
			lead = { ...lead, column: column };
			let newLeads = update(leads, {
				[leadIndex]: { $set: lead },
			});
			return reorder(newLeads, sourceIndex, destinationIndex);
		},
		[leads],
	);

	function handleOnDragEnd(result) {
		const old_data = leads;
		if (!result.destination) return;

		const { source, destination } = result;
		let content;
		if (source.droppableId === destination.droppableId) {
			content = reorder(
				leads,
				result.source.index,
				result.destination.index,
			);
		} else {
			content = changeLeadStatus(
				result.draggableId,
				result.destination.droppableId,
				result.source.index,
				result.destination.index,
				result,
			);
		}
		const getSpecificField = content.map((item, index) => {
			return {
				...item,
				position: index,
			};
		});
		const updatePosition = content.map((item, index) => {
			return {
				_id: item._id,
				position: index,
				column: item.column,
			};
		});
		setLeads(getSpecificField);
		dispatch(
			updateLeadColumnAction(updatePosition, getSpecificField, old_data),
		);
	}

	const onDragEnd = (result) => {
		const { destination, source, draggableId, type } = result;

		if (
			!destination ||
			(destination.droppableId === source.droppableId &&
				destination.index === source.index)
		) {
			return;
		}

		if (type === 'column') {
			const dragColumn = columns.find((item) => item?._id === draggableId);
			const newColumnOrder = Array.from(columns);
			newColumnOrder.splice(source.index, 1);
			newColumnOrder.splice(destination.index, 0, dragColumn);
			setColumns(newColumnOrder);
			dispatch(
				updateLeadColumnPositionAction(
					{
						columns: newColumnOrder,
					},
					navigate,
				),
			);
			return;
		}

		handleOnDragEnd(result);
	};

	const handleDelete = async (column) => {
		dispatch(removeLeadColumnAction({ remove_column: column }));
	};

	return (
		<div className="relative w-full pb-10 overflow-scroll h-[calc(75vh-100px)] md:h-[75vh] scrollbar-h-4 scrollbar-w-4">
			<div className="5xl:px-[96px] w-[100%] pb-10  pt-0 scrollbar-h-4">
				<Filter
					filterBtn={filterBtn}
					setFilterBtn={setFilterBtn}
					handelSet={handelSet}
					isAnimated={isAnimated}
					setIsAnimated={setIsAnimated}
					addfilter={addfilter}
					setAddFilter={setAddFilter}
				/>
				<div>
					<div className="flex w-full lg:max-w-[100%] scrollbar-hide">
						<DragDropContext onDragEnd={onDragEnd}>
							<Droppable
								droppableId="all-columns"
								direction="horizontal"
								type="column"
							>
								{(provided) => (
									<div
										className="flex"
										{...provided.droppableProps}
										ref={provided.innerRef}
									>
										{columns?.map((channel, index) => {
											const this_col_color =
												leadsColors[channel?.color];
											const columnLeads = leads
												?.sort((a, b) => a?.position - b?.position)
												?.filter(
													(item) => item?.column === channel?.name,
												);

												const totalBudget = columnLeads.reduce((acc, lead) => {
													// Ensure budget is a number and add it to the accumulator
													const budget = parseFloat(lead.budget) || 0;
													return acc + budget;
												  }, 0);
												
												  
											return (
												<Draggable
													draggableId={channel?._id?.toString()}
													index={index}
													key={channel?._id}
												>
													{(provided) => (
														<div
															{...provided.draggableProps}
															ref={provided.innerRef}
															className="flex flex-col"
														>
															<div
																{...provided.dragHandleProps}
																className={`lg:max-w-[312px] lg:w-full w-[95%] min-w-[260px] md:min-w-[312px] rounded-[6px] border h-fit px-[9px] py-[12px] mr-5 bg-${this_col_color?.background}`}
																style={{
																	backgroundColor:
																		this_col_color
																			? this_col_color?.backgroundOpacity
																			: channel?.color +
																			  '08',
																}}
															>
																<div className="flex justify-between mb-4">
																	<div className="flex items-center justify-center pl-[9px]">
																		<span
																			className={`mr-2 w-[8px] h-[8px] rounded-[50%] inline-block ${
																				this_col_color
																					? 'bg-' +
																					  this_col_color?.text
																					: ''
																			}`}
																			aria-label="Column colored marker"
																			style={
																				!this_col_color
																					? {
																							backgroundColor:
																								channel?.color,
																					  }
																					: {}
																			}
																		></span>
																		<h3
																			className={`mr-2 lex justify-center items-center ${
																				this_col_color
																					? 'text-' +
																					  this_col_color?.text
																					: ''
																			} text-xs font-bold uppercase align-center`}
																			style={
																				!this_col_color
																					? {
																							color: channel?.color,
																					  }
																					: {}
																			}
																		>
																			{channel?.name}
																		</h3>
																		<span
																			className={`flex items-center justify-center w-[18px] h-[18px] rounded-[50%] ${
																				this_col_color
																					? `text-${this_col_color?.text} bg-${this_col_color?.badge}`
																					: ''
																			} font-bold text-[10px] tracking-[0.08em]`}
																			style={
																				!this_col_color
																					? {
																							color: channel?.color,
																							backgroundColor:
																								channel?.color +
																								'0D',
																					  }
																					: {}
																			}
																		>
																			{columnLeads?.length}
																		</span>
																	</div>
																	<div className='flex items-center justify-center'>
																	{totalBudget > 0 && <div className="flex flex-col items-center justify-center ">
																		<span className={`flex items-center justify-center font-semibold text-[10px] ${
																				this_col_color
																					? `text-${this_col_color?.text}`
																					: ''
																			}`} style={
																				!this_col_color
																					? {
																							color: channel?.color,
																					  }
																					: {}
																			}>
																			Total Value
																		</span>
																		<span className={`flex items-center justify-center font-bold text-[12px] ${
																				this_col_color
																					? `text-${this_col_color?.text}`
																					: ''
																			}`} style={
																				!this_col_color
																					? {
																							color: channel?.color,
																					  }
																					: {}
																			}>
																			{user?.currency?.symbol}{totalBudget}
																		</span>
																	</div>}
																	<EditColorMenu
																		onModalOpen={() => {
																			setEditColumn(channel);
																			setIsEditColorOpen(
																				true,
																			);
																		}}
																		handleDelete={() => {
																			handleDelete(channel);
																		}}
																	/>
																	</div>
																</div>
																<Droppable
																	droppableId={channel?.name}
																>
																	{(provided) => (
																		<div
																			className="dropped-container min-h-[1px]"
																			ref={provided.innerRef}
																			{...provided.draggableProps}
																		>
																			{columnLeads?.map(
																				(item, i) => {
																					const isLastInColumn =
																						i !==
																						columnLeads?.length -
																							1;
																					return (
																						<Draggable
																							key={
																								item?._id
																							}
																							draggableId={item?._id?.toString()}
																							index={
																								item?.position ??
																								i
																							}
																						>
																							{(
																								provided,
																							) => (
																								<div
																									className="list-none drop-list-item"
																									ref={
																										provided.innerRef
																									}
																									{...provided.draggableProps}
																									{...provided.dragHandleProps}
																								>
																									<LeadsCard
																										lead={
																											item
																										}
																										openLeadModal={
																											onLeadCardClick
																										}
																										onDeleteLead={
																											onDeleteLead
																										}
																										className={`${
																											isLastInColumn &&
																											'mb-3'
																										}`}
																									/>
																								</div>
																							)}
																						</Draggable>
																					);
																				},
																			)}
																			{provided.placeholder}
																		</div>
																	)}
																</Droppable>
																<button
																	className={`flex justify-center items-center w-full py-[14px] pl-4 pr-1 border-[1px] ${
																		this_col_color
																			? `border-${this_col_color?.text}`
																			: ''
																	} border-dashed rounded-lg cursor-pointer 5xl:max-w-[400px] w-full mt-4`}
																	style={
																		!this_col_color
																			? {
																					borderColor:
																						channel?.color,
																			  }
																			: {}
																	}
																	onClick={() => {
																		setChannel(channel?.name);
																		dispatch(
																			OpenModalAction(
																				'add_lead',
																			),
																		);
																	}}
																>
																	<svg
																		width="16"
																		height="16"
																		viewBox="0 0 16 16"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M13.6569 8H2.34315"
																			className={`
                                        ${
															this_col_color
																? `stroke-${this_col_color?.text}`
																: `stroke-${channel?.color}`
														}
                                      `}
																			strokeLinecap="round"
																			strokeLinejoin="round"
																			style={
																				!this_col_color
																					? {
																							stroke:
																								channel?.color,
																					  }
																					: {}
																			}
																		/>
																		<path
																			d="M8 2.34315V13.6569"
																			className={` ${
																				this_col_color
																					? `stroke-${this_col_color?.text}`
																					: `stroke-${channel?.color}`
																			}`}
																			strokeLinecap="round"
																			strokeLinejoin="round"
																			style={
																				!this_col_color
																					? {
																							stroke:
																								channel?.color,
																					  }
																					: {}
																			}
																		/>
																	</svg>
																</button>
															</div>
														</div>
													)}
												</Draggable>
											);
										})}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>
						<div className="5xl:max-w-[400px] lg:max-w-[288px] lg:w-full md:w-[48%] min-w-[288px] w-full min-h-[300px] mr-10">
							{isOpenColumn ? (
								<div
									className={`5xl:max-w-[400px] lg:max-w-[312px] lg:w-full md:w-[48%] min-w-[312px] rounded-[6px] w-full bg-${
										leadsColors[renderColor()]?.background
									}/[0.1] border border-${
										leadsColors[renderColor()]?.border
									} h-fit p-[12px] mr-[20px]`}
								>
									<div className="flex justify-between mb-4">
										<input
											name="text"
											placeholder="Add column"
											onChange={(event) => {
												setColumn(event.target.value);
											}}
											className={`text-${
												leadsColors[renderColor()]?.text
											} text-xs font-bold focus:outline-none w-auto bg-${
												leadsColors[renderColor()]?.background
											}/[0.1]`}
											onBlur={() => {
												handleAddColumn();
											}}
											onKeyDown={(e) => {
												if (e.key === 'Enter') {
													handleAddColumn();
												}
											}}
											autoFocus
										/>
									</div>
								</div>
							) : (
								user?.lead_column?.length < 12 && (
									<button
										className={`flex justify-start items-baseline w-full min-w-[288px] py-[14px] pl-4 pr-1 border-[1px] border-dashed border-${
											leadsColors[renderColor()]?.border
										} rounded-lg cursor-pointer`}
										onClick={() => {
											setIsOpenColumn(true);
										}}
									>
										<div
											className={`font-semibold text-sm max-w-[100%] w-full text-center text-${
												leadsColors[renderColor()]?.text
											} cursor-pointer`}
										>
											+
										</div>
									</button>
								)
							)}
						</div>
					</div>
				</div>
				<LeadDetailsModal
					isOpen={['lead_details', 'lead_note'].includes(name) && isOpen}
					option={name === 'lead_note'}
					setIsOpen={() => {
						dispatch(CloseModalAction(name));
						navigate({ search: '' });
					}}
					onDeleteLead={onDeleteLead}
				/>
				<EditColumnColorsModal
					isOpen={isEditColorOpen}
					setIsOpen={setIsEditColorOpen}
					editColumn={editColumn}
					onApplyColor={onApplyColor}
					isLoaded={isLoaded}
				/>
			</div>
		</div>
	);
};

export default LeadsList;
