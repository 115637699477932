import { useFormikContext } from "formik";
import get from "lodash/get";

function CustomErrorMessage({ name }) {
  const { errors, submitCount } = useFormikContext();

  const errorMessage = get(errors, name);

  // if (submitCount > 0 && errorMessage) {
  //   return <div className="pt-1 pl-1 text-xs text-red-600">{errorMessage}</div>;
  // }

  if (errorMessage) {
    return <div className="pt-1 pl-1 text-xs text-red-600">{errorMessage}</div>;
  }

  return null;
}

export default CustomErrorMessage;
