import React from "react";
import NotData from "../../assets/Images/NotData.svg";

const NotDataFound = () => {
  return (
    <div className="pt-[80px] flex justify-center">
      <div>
        <div className="flex justify-center">
          <img src={NotData} alt="no-proposal" />
        </div>
        <div className="text-center">
          <div className="flex justify-center mt-[12px]">
            <p className="text-[14px] w-[350px] text-Neutral800">
              We could not find that client.
              <br /> Please try again using the an existing client’s name.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotDataFound;
