const initialState = {
  isLoading: false,
  contact: [],
  error: null,
};

const contactsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_CONTACT_LIST":
      return {
        ...state,
        contact: payload.data,
        isLoading: true,
      };
    case "GET_CONTACT_ERROR":
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case "CREATE_CONTACT_SUCCESS":
      return {
        ...state,
        contact: [...state.contact, payload],
      };
    case "DELETE_CONTACT_SUCCESS":
      return {
        ...state,
        contact: state.contact.filter((item) => item._id !== payload),
      };
    default:
      return state;
  }
};

export default contactsReducer;
