import React from "react";
import clx from "classnames";

function EntryBadge({ number = null, className = "" }) {
  if (!number) {
    return null;
  }
  return (
    <span
      className={clx(
        "flex items-center justify-center w-[18px] h-[14px] rounded-sm bg-AccentRegular text-white text-[8px] font-bold py-[3px] px-[4px] leading-[100%] tracking-[0.04em]",
        className
      )}
      aria-label="New entries"
      title="New entries"
    >
      +{number}
    </span>
  );
}

export default EntryBadge;
