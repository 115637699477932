import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";

import CMLogo from "../assets/Images/Logo.svg";

/// ⚡ PLEASE DO NOT REMOVE ANY COMMENTS FROM THIS FILE, ITS THERE FOR A REASON!!!

/**
 * @typedef {Map<string, string>} WhiteLabelSettings
 */

/**
 * @typedef {Object} WhiteLabelContextValue
 * @property {WhiteLabelSettings} settings - The white label settings.
 * @property {function(string, string|boolean): void} updateSetting - Function to update a specific setting.
 * @property {function(Object): void} updateSettings - Function to update multiple settings.
 */

const WhiteLabelContext = createContext(
  /** @type {WhiteLabelContextValue} */ (null)
);

function WhiteLabelProvider({ children }) {
  const auth = useSelector((state) => state.auth);

  /** @type {[Readonly<WhiteLabelSettings>, React.Dispatch<React.SetStateAction<WhiteLabelSettings>>]} */
  const [settings, applyWhiteLabelSettings] = useState(
    new Map([
      ["backgroundColor", "#F0F0FF"],
      ["accentColor", "#732AFF"],
      ["brandLogo", CMLogo],
      ["isWhiteLabelSubscriber", false],
    ])
  );

  /**
   * Updates a single setting in the settings map.
   * @param {string} key - The key of the setting to update.
   * @param {string} value - The new value for the setting.
   */
  const updateSetting = useCallback((key, value) => {
    applyWhiteLabelSettings((prevSettings) => {
      const newSettings = new Map(prevSettings);
      newSettings.set(key, value);
      return newSettings;
    });
  }, []);

  /**
   * Updates multiple settings in the settings map.
   * @param {Object} newSettingsObject - An object containing key-value pairs to update.
   */
  const updateSettings = useCallback((newSettingsObj) => {
    applyWhiteLabelSettings((prevSettings) => {
      const newSettings = new Map(prevSettings);
      Object.entries(newSettingsObj).forEach(([key, value]) => {
        newSettings.set(key, value);
      });
      return newSettings;
    });
  }, []);

  useEffect(() => {
    if (auth?.whiteLabelAccountDetail?.data?.data) {
      updateSettings({
        isWhiteLabelSubscriber:
          auth?.whiteLabelAccountDetail?.data?.data &&
          auth?.whiteLabelAccountDetail?.data?.data?.attributes?.status !==
            "cancelled"
            ? true
            : false,
      });
    }
  }, [auth?.whiteLabelAccountDetail?.data?.data, updateSettings]);

  /** @type {WhiteLabelContextValue} */
  const value = { settings, updateSetting, updateSettings };

  return (
    <WhiteLabelContext.Provider value={value}>
      {children}
    </WhiteLabelContext.Provider>
  );
}

export default WhiteLabelProvider;

export const useWhiteLabelSettings = () => {
  const context = useContext(WhiteLabelContext);
  if (!context) {
    throw new Error(
      "Make sure to wrap your component with a <WhiteLabelProvider>"
    );
  }
  return context;
};
