import ClientCollabProjects from "../components/ClientCollab/ClientCollabProjects";
import TopMenu from "../components/ClientCollab/TopMenu";

function ClientCollab() {
  return (
    <>
      <div className="relative flex">
        <TopMenu />
        <ClientCollabProjects />
      </div>
    </>
  );
}

export default ClientCollab;
