import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../components/Sidebar/Sidebar';
import { useSelector } from 'react-redux';

function Admin() {
  const [issliderHover, setIssliderHover] = useState(false);

  const user = useSelector((state) => state.auth.user);

  const topPadding =
    user?.payment?.status === 'trialing' ? 'pt-[44px]' : 'pt-0';
  return (
    <>
      <div className={`relative flex ${issliderHover ? 'medium-screen' : ''}`}>
        <Sidebar
          onMouseEnter={setIssliderHover}
          onMouseLeave={setIssliderHover}
        />
        <div
          className={`main-content relative ml-[72px] lg:ml-60 5xl:ml-[300px] bg-Neutral000 transition duration-300 pt-0 md:pb-0 w-[calc(100vw - 240px)] md:w-[calc(100vw - 240px)] max-w-[calc(100vw - 240px)] ${topPadding}`}
        >
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default Admin;
