import React, { useEffect, useState } from "react";
import CloseBtn from "../../assets/Images/close-btn.svg";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import Dollar from "../../assets/Images/dollar.svg";
import BigShoesAvatar from "../../assets/Images/big-shoes-avatar.png";
import CancelModelWarningAvatar from "../../assets/Images/cancel-model-warning-avatar.svg";
import { useDispatch } from "react-redux";
import { CancelLemonsqueezySubscription } from "../../store/actions/authAction";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const DiscountOfferModal = (props) => {
  const {
    modalDiscount,
    setmodalDiscount,
    cancle_url,
    payment_gateway,
    logedinUser,
    isWarning,
    setIsWarning,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [warning, setWarning] = useState(false);

  useEffect(() => {
    if (isWarning) setWarning(isWarning);
  }, [isWarning, modalDiscount]);

  return (
    <Modal
      isOpen={modalDiscount}
      onRequestClose={() => {
        setmodalDiscount(false);
        setWarning(false);
      }}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="Archived client list Modal"
    >
      {!warning ? (
        <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[384px] sm:min-w-[384px]  max-w-[384px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="bg-white p-[24px_32px_32px_32px] rounded-[8px]">
            <div className="flex pb-2 border-b-[0.56px] border-Neutral200">
              <h6 className="uppercase font-semibold text-xs text-Neutral700 tracking-[0.04em]  w-full">
                BEFORE WE SAY GOODBYE
              </h6>
              <img src={Dollar} alt="" />
            </div>
            <div className="flex justify-between pt-[37px]">
              <div className="">
                <div className="text-center pb-8">
                  <img
                    src={BigShoesAvatar}
                    alt=""
                    className="mx-auto pb-[27px]"
                  />
                  <p className="text-Neutral800 font-normal">
                    Will a{" "}
                    <Link to="#" className="font-bold">
                      30% discount
                    </Link>{" "}
                    on our annual plan change your mind while we work on making
                    ClientManager better?
                  </p>
                </div>
              </div>
            </div>
            <div className="">
              <button
                id="upgrade-submit-btn"
                className={`button-hover large-primary-btn text-sm rounded h-12 w-full text-Neutral000 large-primary-btn bg-AccentRegular focus:outline-AccentLight drop-shadow-Texts focus:outline focus:outline-4  focus-visible:outline-0 transition duration-300 ease-in-out font-bold`}
                onClick={() =>
                  payment_gateway === "lemonsqueezy"
                    ? window.open(
                        `${process.env.REACT_APP_LEMONSQUEEZY_URL_30_DISCOUNT}?checkout[email]=${logedinUser?.email}&checkout[custom][user_id]=${logedinUser?.id}`,
                        "_blank"
                      )
                    : navigate("/upgrade-to-discounted-plan")
                }
              >
                <span
                  className={`relative drop-shadow-Texts z-10 flex items-center justify-center font-[700]`}
                >
                  30% Discount?! Yes Please!
                </span>
              </button>
              <button
                onClick={() => {
                  setWarning(true);
                }}
                className="inlinr-block text-sm font-bold w-full h-12 flex items-center justify-center rounded-[4px] leading-[1.4] focus:outline-AccentMediumLight bg-Neutral300 text-Neutral800 hover:text-Neutral800 transition duration-300 ease-in-out hover:bg-Neutral400 mt-[15px]"
              >
                No, Cancel my Account
              </button>
            </div>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => setmodalDiscount(false)}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      ) : (
        <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[384px] sm:min-w-[384px]  max-w-[384px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="bg-white p-[24px_32px_32px_32px] rounded-[8px]">
            <div className="flex pb-2 border-b-[0.56px] border-Neutral200">
              <h6 className="uppercase font-semibold text-xs text-Neutral700 tracking-[0.04em]  w-full">
                IMPORTANT NOTICE
              </h6>
            </div>
            <div className="flex justify-between pt-[37px]">
              <div className="">
                <div className="text-center pb-8">
                  <img
                    src={CancelModelWarningAvatar}
                    alt=""
                    className="mx-auto pb-[27px]"
                  />
                  <p className="text-Neutral800 font-normal">
                    Please note that you will lose all information in your
                    account should you decide to cancel.
                  </p>
                </div>
              </div>
            </div>
            <div className="">
              <button
                id="upgrade-submit-btn"
                className={`button-hover large-primary-btn text-sm rounded h-12 w-full text-Neutral000 large-primary-btn bg-AccentRegular focus:outline-AccentLight drop-shadow-Texts focus:outline focus:outline-4  focus-visible:outline-0 transition duration-300 ease-in-out font-bold`}
                onClick={() => {
                  setmodalDiscount(false);
                  setWarning(false);
                  setIsWarning(false);
                }}
              >
                <span
                  className={`relative drop-shadow-Texts z-10 flex items-center justify-center font-[700]`}
                >
                  Take Me Back
                </span>
              </button>
              <button
                onClick={() => {
                  if (payment_gateway === "lemonsqueezy") {
                    dispatch(
                      CancelLemonsqueezySubscription(
                        setWarning,
                        setIsWarning,
                        setmodalDiscount,
                        navigate
                      )
                    );
                  } else {
                    window.open(cancle_url, "_blank");
                  }
                }}
                className="inlinr-block text-sm font-bold w-full h-12 flex items-center justify-center rounded-[4px] leading-[1.4] focus:outline-AccentMediumLight bg-Neutral300 text-Neutral800 hover:text-Neutral800 transition duration-300 ease-in-out hover:bg-Neutral400 mt-[15px]"
              >
                Understood, Cancel My Account
              </button>
            </div>
            <p className="text-Neutral700 text-[11px] font-semibold mt-2">
              If you click “Understood, Cancel My Account” a new page will open.
              Please click “Cancel subscription” then you’ll get confirmation
              your account has been cancelled.
            </p>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => setmodalDiscount(false)}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default DiscountOfferModal;
