import React from "react";

const FolderOutlineIcon = ({
  width = 20,
  color = "var(--accent-color)",
  className = "",
}) => {
  return (
    <svg
      width={width}
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.2699 15.2222C19.2699 15.6937 19.0746 16.1459 18.7269 16.4793C18.3792 16.8127 17.9076 17 17.4159 17H2.58419C2.09249 17 1.62093 16.8127 1.27324 16.4793C0.925553 16.1459 0.730225 15.6937 0.730225 15.2222V2.77778C0.730225 2.30628 0.925553 1.8541 1.27324 1.5207C1.62093 1.1873 2.09249 1 2.58419 1H7.21911L9.07308 3.66667H17.4159C17.9076 3.66667 18.3792 3.85397 18.7269 4.18737C19.0746 4.52076 19.2699 4.97295 19.2699 5.44444V15.2222Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FolderOutlineIcon;
