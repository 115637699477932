import React, { useEffect, useState } from "react";
import AdminNavbar from "../Navbars/AdminNavbar";
import { useLocation, useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { GetClientDetailAction } from "../../store/actions/clientAction";
import { GetContractContentByClientIdAction } from "../../store/actions/documentsAction";
import FroalaBuilderComp from "../TextEditor/FroalaBuilderComp";

const ContractBuilder = () => {
  const { clientId } = useParams();
  const { contractId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const clientDetail = useSelector((state) => state.client.clientDetail);
  const [navBarText, setNavbarText] = useState("Back");
  const [textEditor, setTextEditor] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getServerProposalConnent = async () => {
    setIsLoading(true);
    const serverContent = await dispatch(
      GetContractContentByClientIdAction(clientId, contractId)
    );
    if (serverContent?.data) {
      let proposalContent = serverContent?.data;
      setTextEditor({
        images: {
          url: proposalContent?.content?.cover?.url,
          key: proposalContent?.content?.cover?.key,
        },
        text: proposalContent?.content,
        ...proposalContent,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getServerProposalConnent();
  }, [clientId, dispatch, contractId]);

  useEffect(() => {
    if (state) {
      dispatch({
        type: "SET_CLIENT_DETAIL",
        payload: { isLoading: false, isUpdating: false, data: state },
      });
    } else {
      if (clientId !== clientDetail.data._id) {
        dispatch(GetClientDetailAction(clientId, navigate));
      }
    }
  }, [clientId, dispatch, navigate]);

  useEffect(() => {
    if (clientId !== clientDetail.data._id) {
      setNavbarText("Back");
    } else {
      setNavbarText(
        `Back to ${
          clientDetail?.data?.business_name || clientDetail?.data?.contact_name
        } Contracts Dashboard`
      );
    }
  }, [clientDetail]);

  return (
    <>
      <AdminNavbar
        link={`/admin/clients/manage/${clientId}/documents`}
        title="Contract Builder"
        backLink={navBarText}
      />
      <div className="mt-[100px] pb-[1px]" />
      <FroalaBuilderComp
        clientDetail={clientDetail}
        setTextEditor={setTextEditor}
        textEditor={textEditor}
        type="contract"
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        handleReload={getServerProposalConnent}
      />
    </>
  );
};

export default ContractBuilder;
