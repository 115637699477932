import React from "react";

function TrashIcon({
  width = 18,
  height = 20,
  className = "",
  color = "currentColor",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1 4.5544H2.77769H16.9992"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.4429 4.55581V2.77812C5.4429 2.30665 5.6302 1.85449 5.96358 1.5211C6.29696 1.18772 6.74912 1.00043 7.22059 1.00043H10.776C11.2474 1.00043 11.6996 1.18772 12.033 1.5211C12.3664 1.85449 12.5537 2.30665 12.5537 2.77812V4.55581M15.2202 4.55581V16.9997C15.2202 17.4711 15.0329 17.9233 14.6995 18.2567C14.3661 18.5901 13.914 18.7773 13.4425 18.7773H4.55406C4.08259 18.7773 3.63042 18.5901 3.29704 18.2567C2.96366 17.9233 2.77637 17.4711 2.77637 16.9997V4.55581H15.2202Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default TrashIcon;
