import React from "react";

export default function ProposalsIcon({
  width = 24,
  className = "",
  color = "#5A5A66",
}) {
  return (
    <svg
      width={width}
      height={width}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2 7.5H4.8C3.80589 7.5 3 8.30589 3 9.3V18.3C3 19.2941 3.80589 20.1 4.8 20.1H19.2C20.1941 20.1 21 19.2941 21 18.3V9.3C21 8.30589 20.1941 7.5 19.2 7.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6 20.1V5.69999C15.6 5.2226 15.4104 4.76477 15.0728 4.4272C14.7352 4.08964 14.2774 3.89999 13.8 3.89999H10.2C9.7226 3.89999 9.26477 4.08964 8.9272 4.4272C8.58964 4.76477 8.39999 5.2226 8.39999 5.69999V20.1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
