import axios from "axios";
import { toast } from "react-toastify";

export const LoginAction = (
  { email, password },
  setSubmitting,
  setErrors,
  setReactiveAccount,
  setLoginReactiveResponce,
  setIsOpenAppsumoWarningModal,
  setIsReactiveAccount
) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post("api/v1/user/login", { email, password });
      if (data?.data?.payment_gateway === "paddle") {
        if (data?.data?.type === "reactive") {
          setReactiveAccount(true);
          setLoginReactiveResponce(data.data);
          setSubmitting(false);
          return;
        }
        if (data?.data?.type === "complete") {
          setReactiveAccount(true);
          setLoginReactiveResponce(data.data);
          setSubmitting(false);
          return;
        }
      }
      if (data?.data?.payment_gateway === "appsumo") {
        if (data?.data?.type === "reactive") {
          setIsReactiveAccount(true);
          setIsOpenAppsumoWarningModal(true);
          setSubmitting(false);
          return;
        }
        if (data?.data?.type === "complete") {
          setIsReactiveAccount(false);
          setIsOpenAppsumoWarningModal(true);
          setSubmitting(false);
          return;
        }
      }

      if (data?.data?.payment_gateway === "lemonsqueezy") {
        if (data?.data?.type === "reactive") {
          setReactiveAccount(true);
          setLoginReactiveResponce(data.data);
          setSubmitting(false);
        }
        if (data?.data?.type === "complete") {
          setReactiveAccount(true);
          setLoginReactiveResponce(data.data);
          setSubmitting(false);
        }
      }

      if (data?.token) {
        await localStorage.setItem("jwt_access_token", data?.token);
        axios.defaults.headers["Authorization"] = `${data?.token}`;
      }
      if (data?.workspace?.length > 0) {
        axios.defaults.headers["workspace_id"] = `${data?.workspace[0]?._id}`;
      }

      setSubmitting(false);
      return dispatch({
        type: "LOGIN",
        payload: {
          isLogin: true,
          token: data?.token,
          user: data?.data,
          userLoading: false,
        },
      });
    } catch (error) {
      if (error?.response?.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setErrors(err);
        setSubmitting(false);
      } else if (error.response.status === 401) {
        toast.error(error?.response?.data?.message);
        setSubmitting(false);
        return dispatch({
          type: "LOGIN_ERROR",
          payload: error,
        });
      } else {
        setSubmitting(false);
        return dispatch({
          type: "LOGIN_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const ForgetPasswordAction = (
  { email },
  setSubmitting,
  sethaveResponse,
  setResponseText
) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.get(
        `api/v1/user/forgot-password?email=${email}`
      );
      // toast.success(data?.data);
      // navigate("/signin")
      if (data.success) {
        sethaveResponse(true);
        setResponseText(data.message);
      } else {
        sethaveResponse(false);
        setResponseText("Oops. Something went wrong. Please try again.");
      }
      setSubmitting(false);
      return dispatch({
        type: "FORGET_PASSWORD_SUCCESS",
        payload: {},
      });
    } catch (error) {
      if (error.response.status === 422) {
        sethaveResponse(false);
        setResponseText("Looks like email is not registered with us.");
        setSubmitting(false);
      } else {
        sethaveResponse(false);
        setResponseText("Oops. Something went wrong. Please try again.");
        setSubmitting(false);
      }
    }
  };
};

export const ResetPasswordAction = (
  { token, password, confirm_password },
  setSubmitting,
  setErrors,
  navigate
) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post("api/v1/user/forgot-reset-password", {
        token,
        password,
        confirm_password,
      });
      toast.success(data?.message);
      setTimeout(() => {
        setSubmitting(false);
        navigate("/signin");
      }, 1000);
      return dispatch({
        type: "FORGET_PASSWORD_SUCCESS",
        payload: {},
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setErrors(err);
        setSubmitting(false);
      } else if (error.response.status === 401) {
        toast.error(error?.response?.data?.message);
        setSubmitting(false);
        return dispatch({
          type: "SIGNUP_ERROR",
          payload: error,
        });
      } else {
        setSubmitting(false);
        return dispatch({
          type: "SIGNUP_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const VerifyUserProfileAction = (token, navigate) => {
  return async () => {
    try {
      let { data } = await axios.get(`api/v1/user/verify-email?token=${token}`);
      if (data.success) {
        toast.success(data?.message);
      } else {
        toast.error(data?.message);
      }
      navigate("/signin");
      return;
    } catch (error) {
      navigate("/signin");
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return;
      } else {
        return;
      }
    }
  };
};

export const GetEmailExistanceAction = async (email) => {
  try {
    let { data } = await axios.get(
      `api/v1/user/check-email-existance?email=${email}`
    );
    return data.data;
  } catch (error) {
    if (error.response.status === 422) {
      // toast.error(error?.response?.data?.message);
      return;
    } else {
      toast.error("Something went wrong while validating email");
      return;
    }
  }
};

export const GetEmailNotExistanceAction = async (email) => {
  try {
    let { data } = await axios.get(
      `api/v1/user/check-signup-email-existance?email=${email}`
    );
    return data;
  } catch (error) {
    if (error.response.status === 422) {
      // toast.error(error?.response?.data?.message);
      return;
    } else {
      toast.error("Something went wrong while validating email");
      return;
    }
  }
};

export const GetUserAction = () => {
  return async (dispatch) => {
    try {
      let { data } = await axios.get("api/v1/user/get-profile");
      if (data?.data?.workspace.length > 0) {
        axios.defaults.headers[
          "workspace_id"
        ] = `${data?.data?.workspace[0]?._id}`;
      }

      if (
        data?.data?.loginUser?.time_tracker?.project &&
        data?.data?.loginUser?.time_tracker?.start_time
      ) {
        dispatch({
          type: "RELOAD_TIMER",
          payload: {
            project: data?.data?.loginUser?.time_tracker?.project,
            title: data?.data?.loginUser?.time_tracker?.title,
            category: data?.data?.loginUser?.time_tracker?.timer_category,
            client: data?.data?.loginUser?.time_tracker?.client,
            start_time: data?.data?.loginUser?.time_tracker?.start_time,
            is_timer_running:
              data?.data?.loginUser?.time_tracker?.is_timer_running,
            pause_time: data?.data?.loginUser?.time_tracker?.pause_time,
            restart_time: data?.data?.loginUser?.time_tracker?.restart_time,
            is_reload: data?.data?.loginUser?.time_tracker?.is_timer_running
              ? true
              : false,
          },
        });
      }
      return dispatch({
        type: "SET_USER_DATA",
        payload: {
          data: {
            ...data.data?.loginUser,
            stripeInfo: data.data.stripeInfo,
            payPalInfo: data.data.payPalInfo,
            subDomain: data.data.subDomain,
            brandIdentity: data.data.brandIdentity,
            customEmail: data.data.customEmail,
            customDomain: data.data.customDomain,
            googleAuth: data.data.googleAuth,
          },
        },
      });
    } catch (error) {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        toast.error(error?.response?.data?.Data);
        dispatch(LogoutAction());
        return dispatch({
          type: "GET_USER_ERROR",
          payload: error,
        });
      } else {
        return dispatch({
          type: "GET_USER_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const UpdateProfileAction = (
  values,
  setSubmitting,
  setErrors,
  onlyProfile = false
) => {
  return async (dispatch) => {
    if (setSubmitting) setSubmitting(true);
    try {
      let { data } = await axios.post("api/v1/user/update-profile", values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success(
        !onlyProfile ? data?.message : "Profile updated successfully"
      );
      if (setSubmitting) setSubmitting(false);
      dispatch({
        type: "SET_USER_DATA",
        payload: {
          data: data.data,
        },
      });
      return dispatch({
        type: "PROFILE_UPDATE",
        payload: {},
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setErrors(err);
        if (setSubmitting) setSubmitting(false);
      } else {
        toast.error(error?.response?.data?.message);
        if (setSubmitting) setSubmitting(false);
        return dispatch({
          type: "PROFILE_UPDATE_ERROR",
          payload: error,
        });
      }
    } finally {
      if (setSubmitting) setSubmitting(false);
    }
  };
};

export const RemoverUserCategoryAction = (CategoryData) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post("api/v1/user/remove-app-wide-category", {
        category: CategoryData,
      });
      toast.success(data?.message);
      dispatch({
        type: "SET_USER_DATA",
        payload: {
          data: data.data,
        },
      });
      return { data: data?.data };
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        toast.error(err);
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "PROFILE_UPDATE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const AddUserCategoryAction = (CategoryData) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post("api/v1/user/add-app-wide-category", {
        category: CategoryData,
      });
      toast.success(data?.message);
      dispatch({
        type: "SET_USER_DATA",
        payload: {
          data: data.data,
        },
      });
      return { data: data?.data };
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        toast.error(err);
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "PROFILE_UPDATE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const UpdateIsNotificationReceiveAction = () => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post(
        "api/v1/user/update-is-notification-receive"
      );
      toast.success(data?.message);
      dispatch({
        type: "SET_USER_DATA",
        payload: {
          data: data.data,
        },
      });
      return dispatch({
        type: "PROFILE_UPDATE",
        payload: {},
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        toast.error(err);
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "PROFILE_UPDATE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const UpdateCurrencyAction = (currency) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post("api/v1/user/update-currency", {
        currency,
      });
      toast.success(data?.message);
      dispatch({
        type: "SET_USER_DATA",
        payload: {
          data: data.data,
        },
      });
      return dispatch({
        type: "PROFILE_UPDATE",
        payload: {},
      });
    } catch (error) {
      if (error.response.status === 422) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "PROFILE_UPDATE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const UpdateDateFormatAction = (date_format) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post("api/v1/user/update-date-format", {
        date_format,
      });
      toast.success(data?.message);
      dispatch({
        type: "SET_USER_DATA",
        payload: {
          data: data.data,
        },
      });
    } catch (error) {
      if (error.response.status === 422) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };
};

export const UpdatePasswordAction = (
  values,
  setSubmitting,
  setErrors,
  resetForm
) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post("api/v1/user/update-password", values);
      toast.success(data?.message);
      setSubmitting(false);
      resetForm({
        old_password: "",
        new_password: "",
        confirm_new_password: "",
      });
      return dispatch({
        type: "PASSWORD_UPDATE_SUCCESS",
        payload: {},
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setErrors(err);
        setSubmitting(false);
      } else {
        toast.error(error?.response?.data?.message);
        setSubmitting(false);
        return dispatch({
          type: "PASSWORD_UPDATE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const LogoutAction = () => {
  localStorage.removeItem("jwt_access_token");
  return {
    type: "LOGOUT",
  };
};

export const CreateInvitedAccountAction = (
  { user_name, email, password, ip_address, profile, token },
  setSubmitting,
  setErrors,
  navigate
) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post(
        `api/v1/team/create-invited-account?token=${token}`,
        {
          user_name,
          email,
          password,
          ip_address,
          profile,
          token,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success(data?.message);
      setSubmitting(false);
      navigate("/signin");
      return dispatch({
        type: "CREATE_INVITED_ACCOUNT",
        payload: {
          isLogin: true,
          token: "",
        },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setErrors(err);
        setSubmitting(false);
      } else if (error.response.status === 401) {
        toast.error(error?.response?.data?.message);
        setSubmitting(false);
        return dispatch({
          type: "CREATE_INVITED_ACCOUNT_ERROR",
          payload: error,
        });
      } else {
        setSubmitting(false);
        return dispatch({
          type: "CREATE_INVITED_ACCOUNT_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const AcceptInvitationAction = (token, navigate) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.get(
        `api/v1/team/accept-invitation?token=${token}`
      );
      toast.success(data?.message);
      navigate("/");
      return dispatch({
        type: "ACCEPT_INVITATION",
        payload: {
          isLogin: true,
          token: "",
        },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        toast.error(err);
      } else if (error.response.status === 401) {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "ACCEPT_INVITATION_ERROR",
          payload: error,
        });
      } else {
        toast.error(error);
        return dispatch({
          type: "ACCEPT_INVITATION_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const SendOtpAction = async (value) => {
  try {
    let { data } = await axios.post("api/v1/otp/send-otp", value);
    toast.success("OTP sent to your email");
    return { data: data.data, success: true };
  } catch (error) {
    if (error.response.status === 422) {
      let err = {};
      error.response.data.errors.forEach((element) => {
        err[element.param] = element.msg;
      });
      toast.error(err);
    } else if (error.response.status === 401) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error(error?.response?.data?.message);
    }
    return { success: false };
  }
};

export const verifyOtpAction = async (
  value,
  SetIsVerify,
  setIsContinueEnable,
  setErrors
) => {
  try {
    let { data } = await axios.post("api/v1/otp/verify-otp", value);
    SetIsVerify(data?.data?.is_verify);
    setErrors("");
    setIsContinueEnable(false);
    return data.data;
  } catch (error) {
    if (error.response.status === 422) {
      let err = {};
      error.response.data.errors.forEach((element) => {
        err[element.param] = element.msg;
      });
      setErrors(err);
      setIsContinueEnable(true);
      toast.error(err);
    } else if (error.response.status === 401) {
      setErrors(error?.response?.data?.message);
      setIsContinueEnable(true);
      toast.error(error?.response?.data?.message);
    } else {
      setErrors(error);
      setIsContinueEnable(true);
      toast.error(error);
    }
  }
};

export const signUpWithPlanAction = async (value, navigate) => {
  try {
    let { data } = await axios.post("api/v1/user/signup-with-plan", value);
    return data;
    // navigate(`/complete/${data?.data?._id}?type=complete`);
    // return {data: data.data, error: null};
  } catch (error) {
    if (error.response.status === 422) {
      let err = [];
      error.response.data.errors.forEach((element) => {
        err.push(element.msg);
      });
      // toast.error(err);
      return { data: null, error: err };
    } else if (error.response.status === 401) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error(error);
    }
  }
};

export const GetUserPlanDetailAction = () => {
  return async (dispatch) => {
    try {
      let { data } = await axios.get(`/api/v1/payment/plan-detail`);
      if (data?.success) {
        return dispatch({
          type: "ACCOUNT_DETAILS",
          payload: {
            response: data?.response,
            planData: data?.planData,
            isLoading: false,
          },
        });
      } else {
        toast.error("Something went wrong while fetching plan detail");
      }
    } catch (error) {
      if (error?.response?.status === 422) {
        // toast.error(error?.response?.data?.message);
        return;
      } else {
        toast.error("Something went wrong");
        return;
      }
    }
  };
};

export const GetWhiteLabelPlanDetailAction = () => {
  return async (dispatch) => {
    try {
      let { data } = await axios.get(
        `/api/v1/lemon-squeezy/white-label-plan-detail`
      );
      if (data?.success) {
        return dispatch({
          type: "WHITE_LABEL_ACCOUNT_DETAILS",
          payload: {
            data: data?.data,
            isLoading: false,
          },
        });
      } else {
        toast.error("Something went wrong while fetching plan detail");
      }
    } catch (error) {
      if (error?.response?.status === 422) {
        toast.error(error?.response?.data?.message);
        return;
      } else if (
        error?.response?.status === 400 &&
        error?.response?.data?.message === "Payment not found"
      ) {
        return;
      } else {
        toast.error("Something went wrong");
        return;
      }
    }
  };
};

export const UpdateToDiscountedPrice = (
  payload,
  setIsPlanChangingLoading,
  navigate
) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post(
        `/api/v1/payment/get-discounted-plan`,
        payload
      );
      if (data?.success) {
        dispatch({
          type: "ACCOUNT_DETAILS",
          payload: {
            response: data?.response,
            planData: data?.planData,
            isLoading: false,
          },
        });
        setIsPlanChangingLoading(false);
        navigate("/admin/settings");
      } else {
        toast.error("Something went wrong while fetching plan detail");
        toast.error(data?.message);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        return;
      }
      if (error.response.status === 422) {
        toast.error(error?.response?.data?.message);
        return;
      } else {
        toast.error("Something went wrong");
        return;
      }
    }
  };
};

export const UserPlanChange = (payload, setIsPlanChangingLoading, navigate) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post(`api/v1/payment/change-plan`, payload);
      dispatch(GetUserAction());
      setIsPlanChangingLoading(false);
      if (data?.success) {
        navigate("/admin/settings");
        return;
      }
      if (!data?.success) {
        toast.error(data?.message);
        return;
      }
    } catch (error) {
      setIsPlanChangingLoading(false);
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return;
      } else {
        toast.error("Something went wrong");
        return;
      }
    }
  };
};

export const UserPlanChangeTrialToPaid = (
  setIsLoading,
  setIsTrialConvertSuccess
) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post(`api/v1/payment/change-trial-to-paid`);
      dispatch(GetUserAction());
      if (data?.success) {
        setIsLoading(false);
        setIsTrialConvertSuccess(true);
        return;
      }
      if (!data?.success) {
        setIsLoading(false);
        toast.error(data?.message);
        return;
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return;
      } else {
        toast.error("Something went wrong");
        return;
      }
    }
  };
};

export const UserPlanCancle = (
  plandata,
  setSubmitting,
  setcancelPlan,
  setErrors,
  cancle_url,
  setmodalDiscount,
  planData,
  setIsWarning
) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post(`api/v1/payment/cancle-plan`, plandata);
      if (planData?.selected_payment_type === "yearly") {
        setSubmitting(false);
        setcancelPlan(false);
        setmodalDiscount(true);
      } else {
        setSubmitting(false);
        setcancelPlan(false);
        if (planData?.payment_gateway === "lemonsqueezy") {
          setIsWarning(true);
          setmodalDiscount(true);
        } else {
          window.open(cancle_url, "_blank");
        }
      }
      return dispatch({
        type: "CANCLE_PLAN",
        payload: {
          isLoading: false,
          data: data.data,
        },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setErrors(err);
        return dispatch({
          type: "CANCLE_PLAN_GET_ERROR",
          payload: error,
        });
      } else {
        return dispatch({
          type: "CANCLE_PLAN_GET_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const CancelLemonsqueezySubscription = (
  setWarning,
  setIsWarning,
  setmodalDiscount
) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post(`api/v1/lemon-squeezy/cancel-plan`);
      setWarning(false);
      setIsWarning(false);
      setmodalDiscount(false);

      // call logout action
      dispatch(LogoutAction());

      toast.success("Your plan has been cancelled successfully");
      return data;
    } catch (error) {
      setWarning(false);
      setIsWarning(false);
      setmodalDiscount(false);
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return;
      } else {
        toast.error("Something went wrong");
        return;
      }
    }
  };
};

export const CancelLemonsqueezyWhiteLabelSubscription = (setIsOpen) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post(
        `api/v1/lemon-squeezy/cancel-white-label-plan`
      );
      setIsOpen(false);
      toast.success("Your plan has been cancelled successfully");
      return data;
    } catch (error) {
      setIsOpen(false);
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return;
      } else {
        toast.error("Something went wrong");
        return;
      }
    }
  };
};

export const disableTrialSubscription = (setIsLoading) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post(`api/v1/lemon-squeezy/disable-trial`);
      setIsLoading(false);
      toast.success("Your plan has been upgraded successfully");
      return data;
    } catch (error) {
      setIsLoading(false);
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return;
      } else {
        toast.error("Something went wrong");
        return;
      }
    }
  };
};

export const welcomeModalCloseAction = () => {
  return async (dispatch) => {
    try {
      await axios.get("api/v1/user/close-welcome-modal");
      return dispatch({
        type: "CLOSE_WELCOME_MODAL",
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        toast.error(err);
      } else if (error.response.status === 401) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error(error);
      }
    }
  };
};

export const getBasicInfoUserAction = (id, setLoading, navigate) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`api/v1/user/basic-user-info/${id}`);
      setLoading(false);
      return dispatch({
        type: "GET_BASIC_USER_INFO",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
          toast.error(element.msg);
        });
        navigate("/signin");
        return dispatch({
          type: "GET_BASIC_USER_INFO_ERROR",
          payload: err,
        });
      } else if (error.response.status === 401) {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "GET_BASIC_USER_INFO_ERROR",
          payload: error?.response?.data?.message,
        });
      } else {
        toast.error(error);
        return dispatch({
          type: "GET_BASIC_USER_INFO_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const AppsumoVerifyLicense = (value) => {
  return async () => {
    try {
      const { data } = await axios.post("api/v1/appsumo/verify-license", value);
      return data?.data;
    } catch (error) {
      if (error?.response?.status === 422) {
        let err = [];
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
          toast.error(element.msg);
        });
        return;
      } else if (error?.response?.status === 401) {
        return toast.error(error?.response?.data?.message);
      } else {
        return toast.error(error);
      }
    }
  };
};

export const SignupWithAppsumo = async (
  value,
  setLoading,
  setErrors,
  setIsOpenPaymentModal
) => {
  try {
    let response = await axios.post("api/v1/user/signup-with-appsumo", value);
    if (response.status === 200) {
      setLoading(false);
      setIsOpenPaymentModal(true);
    }
    return response;
  } catch (error) {
    if (error.response.status === 422) {
      let err = [];
      error.response.data.errors.forEach((element) => {
        err[element.param] = element.msg;
      });
      setErrors(err);
      setLoading(false);
      toast.error(err);
      return { data: null, error: err };
    } else if (error.response.status === 401) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    } else {
      setLoading(false);
      toast.error(error);
    }
  }
};

export const AddUpdateGoogleCalendarAction = (
  value,
  setIsOpen,
  setSubmitting,
  setErrors
) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post(
        "api/v1/user/add-update-google-calendar",
        value
      );
      setSubmitting(false);
      setIsOpen(false);
      toast.success(data?.message);
      return dispatch({
        type: "ADD_UPDATE_GOOGLE_CALENDAR",
        payload: {
          data: data.data,
        },
      });
    } catch (error) {
      if (error.response.status === 422) {
        setSubmitting(false);
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setErrors(err);
      } else {
        setSubmitting(false);
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "ADD_UPDATE_GOOGLE_CALENDAR_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const updateQuestionnaireDefaultTextAction = (
  values,
  setSubmitting,
  setErrors
) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post(
        "api/v1/user/update-default-questionnaire-text",
        values
      );
      setSubmitting(false);
      toast.success(data?.message);
      return dispatch({
        type: "UPDATE_QUESTIONNAIRE_DEFAULT_TEXT",
        payload: {
          data: data.data,
        },
      });
    } catch (error) {
      if (error.response.status === 422) {
        setSubmitting(false);
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setErrors(err);
      } else {
        setSubmitting(false);
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "UPDATE_QUESTIONNAIRE_DEFAULT_TEXT_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const googleAuthCallbackAction = (code, navigate) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.get(
        `api/v1/user/google-auth-callback?code=${code}`
      );
      navigate("/admin/dashboard");
      dispatch(GetUserAction());
      return data.data;
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return;
      } else {
        toast.error("Something went wrong");
        return;
      }
    }
  };
};

export const googleAuthAction = (setIsLoading) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.get(`api/v1/user/google-auth`);
      window.open(data.data, "_blank");
      setIsLoading(false);
      return data.data;
    } catch (error) {
      setIsLoading(false);
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return;
      } else {
        toast.error("Something went wrong");
        return;
      }
    }
  };
};
