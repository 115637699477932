import React from "react";
import CommentIcon from "../../Icons/CommentIcon";

function CommentBadge({ count = null, color = "#DFDFE6" }) {
  if (count === null) {
    return null;
  }

  return (
    <span
      className="flex items-center justify-center w-[24px] h-[22px] relative"
      aria-label="Comments"
      title="Comments"
    >
      <CommentIcon width={24} height={22} color={color} />
      <span className="absolute top-[-2px] left-0 w-full flex items-center justify-center h-full text-[10px] text-AccentRegular font-bold leadimg-[100%] source-code-pro">
        {count}
      </span>
    </span>
  );
}

export default CommentBadge;
