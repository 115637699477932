/**
 * Tick Icon
 *
 * @param {import("react").SVGProps} props - SVG Icon Props
 */
function TickIcon(props) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0835 7.5L9.06787 13.125L6.3335 10.5682"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default TickIcon;
