export default () => {
  const colors = ["Green400", "Orange400", "Red400", "Blue400", "Pink400"];

  const usedColors = [];

  const randomColor = colors[Math.floor(Math.random() * colors.length)];
  colors.splice(colors.indexOf(randomColor), 1);
  usedColors.push(randomColor);

  if (colors.length === 0) {
    colors.push(...usedColors);
    usedColors.splice(0, usedColors.length);
  }

  return randomColor;
};
