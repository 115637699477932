/** @param {import("react").SVGProps} props - SVG Icon Props */
function ArrowFilter({ props }) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.66602 3.1665L6.99935 6.49984L10.3327 3.1665"
        stroke="#6D6DF2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.66602 7.5L6.99935 10.8333L10.3327 7.5"
        stroke="#6D6DF2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ArrowFilter;
