import React from "react";
import clx from "classnames";

function SectionTitle({ text = "", className = "" }) {
  return (
    <h5
      className={clx(
        `text-Neutral700 text-xs font-semibold tracking-[0.04em] mr-5 uppercase`,
        className
      )}
    >
      {text}
    </h5>
  );
}

export default SectionTitle;
