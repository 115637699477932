const preparePhoneNumber = (phone = "", countryCode = null) => {
  if (typeof phone !== "string") {
    return null;
  }
  let temp = phone;
  while (temp.includes("(") || temp.includes(")")) {
    temp = temp.replace("(", "").replace(")", "");
  }
  // if (countryCode) {
  //   temp = temp.replace(countryCode, "");
  // }
  return temp;
};

export default preparePhoneNumber;
