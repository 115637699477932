import { Field, FieldArray } from "formik";

import ButtonLoader from "../Loaders/ButtonLoader";
import CustomErrorMessage from "./CustomErrorMessage";

function ServiceStep({ values, errors, isSubmitting, isValid }) {
  return (
    <FieldArray name="services">
      {({ remove, push }) => {
        return (
          <div className="flex flex-col gap-5">
            {values.services?.map((service, index) => (
              <div key={index} className="flex flex-col gap-4">
                <div className="flex items-center justify-between">
                  <h2 className="self-start px-3 py-1.5 text-xs font-bold text-AccentRegular bg-Neutral300 rounded-2xl">
                    Service #{index + 1}
                  </h2>
                  <RemoveFieldButton index={index} remove={remove} />
                </div>

                {/* SERVICE TYPE */}
                <div className="space-y-2">
                  <label
                    htmlFor={`services[${index}].serviceType`}
                    className="block text-xs font-bold text-zinc-800"
                  >
                    Service Type
                  </label>
                  <div className="relative">
                    <Field
                      name={`services[${index}].serviceType`}
                      as="select"
                      className="w-full p-3 text-sm font-medium bg-white border border-gray-100 rounded-lg outline-none text-neutral-500"
                      value={service?.serviceType || ""}
                    >
                      <option className="text-zinc-800" value="" disabled>
                        Select Service Type
                      </option>
                      <option className="text-zinc-800" value="Hourly">
                        Hourly
                      </option>
                      <option className="text-zinc-800" value="Fixed">
                        Fixed
                      </option>
                    </Field>

                    <span
                      style={{
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                      className="absolute pointer-events-none"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7 10L12 15L17 10"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </div>

                  <CustomErrorMessage name={`services[${index}].serviceType`} />
                </div>

                {/* SERVICE Name */}
                {service?.serviceType && (
                  <div className="flex flex-col gap-5">
                    {/* SERVICE NAME */}
                    <div className="space-y-2">
                      <label
                        htmlFor={`services[${index}].serviceName`}
                        className="block text-xs font-bold text-zinc-800"
                      >
                        Service Name
                      </label>
                      <Field
                        name={`services[${index}].serviceName`}
                        type="text"
                        className="w-full p-3 text-sm font-medium bg-white border border-gray-100 rounded outline-none text-neutral-500"
                        placeholder="Eg. Creating Design / Marketing"
                      />
                      <CustomErrorMessage
                        name={`services[${index}].serviceName`}
                      />
                    </div>

                    {/* SERVICE PRICE */}
                    <div className="space-y-2">
                      <label
                        htmlFor={`services[${index}].servicePrice`}
                        className="block text-xs font-bold text-zinc-800"
                      >
                        Price {service.serviceType !== "Fixed" && "Per Hour"}
                      </label>
                      <Field
                        name={`services[${index}].servicePrice`}
                        placeholder={0}
                        type="number"
                        id={`services[${index}].servicePrice`}
                        className="bg-white border border-gray-100 rounded-lg p-2.5 text-neutral-500 text-sm font-medium outline-none w-full"
                      />
                      <CustomErrorMessage
                        name={`services[${index}].servicePrice`}
                      />
                    </div>

                    {/* HOURS SPENT */}
                    {service?.serviceType === "Hourly" && (
                      <div className="space-y-2">
                        <label
                          htmlFor={`services[${index}].quantity`}
                          className="block text-xs font-bold text-zinc-800"
                        >
                          Hours Spent
                        </label>
                        <Field
                          name={`services[${index}].quantity`}
                          placeholder={0}
                          type="number"
                          id={`services[${index}].quantity`}
                          className="bg-white border border-gray-100 rounded-lg p-2.5 text-neutral-500 text-sm font-medium outline-none w-full"
                        />
                        <CustomErrorMessage
                          name={`services[${index}].quantity`}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
            {/* {typeof errors?.services === "string" && (
            <CustomErrorMessage name="services" />
          )} */}
            {typeof errors?.services === "string" && (
              <div className="pt-1 pl-1 text-xs text-red-600">
                {errors?.services}
              </div>
            )}

            <div className="flex gap-3">
              <button
                type="button"
                className="py-2.5 px-3 text-xs font-semibold rounded text-AccentRegular bg-AccentLight hover:bg-AccentMediumLight flex-grow uppercase"
                onClick={() =>
                  push({
                    serviceType: "",
                    serviceName: "",
                    servicePrice: "",
                    quantity: "",
                  })
                }
              >
                {values.services?.length >= 1
                  ? "Add Another Service"
                  : "Add Service"}
              </button>
              <button
                type="submit"
                className="flex-grow p-2 py-3 text-xs font-bold tracking-wide uppercase rounded bg-Neutral300 text-AccentRegular hover:text-white not-disabled:hover:bg-AccentRegular disabled:text-Neutral600 disabled:cursor-not-allowed"
                disabled={
                  isSubmitting || !isValid || !(values?.services?.length > 0)
                }
              >
                {isSubmitting ? <ButtonLoader /> : "Save"}
              </button>
            </div>
          </div>
        );
      }}
    </FieldArray>
  );
}

export default ServiceStep;

const RemoveFieldButton = ({ index, remove }) => (
  <button
    className="text-xs font-semibold tracking-wide text-Red400 hover:text-Red600"
    onClick={() => remove(index)}
    type="button"
  >
    Remove
  </button>
);
