import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const GuestGuard = ({ children }) => {
    const auth = useSelector((state) => state.auth);
    if (auth.authToken || (!auth.userLoading && auth.user)) {
        return <Navigate to="/" replace />;
    }
    return children;
};

export default GuestGuard;