import { Field } from "formik";
import moment from "moment";
import { forwardRef } from "react";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";

import { currencies } from "../../assets/json/currencies";
import CalendarIcon from "../Icons/CalendarIcon";
import ButtonLoader from "../Loaders/ButtonLoader";
import CustomErrorMessage from "./CustomErrorMessage";

function Step4({ values, setFieldValue, isSubmitting, setSelectedDueDate }) {
  const loggedInUser = useSelector((state) => state.auth.user);

  const isTemplate = window.location.pathname.includes("invoice-creator");
  const userDateFormat = loggedInUser?.date_format;

  const DateInput = forwardRef(({ value, onClick }, ref) => {
    return (
      <button
        className="flex items-center justify-between p-3 text-sm font-medium outline-none text-neutral-500"
        onClick={onClick}
        ref={ref}
        type="button"
      >
        <span>{moment(value).format(userDateFormat)}</span>
      </button>
    );
  });

  return (
    <>
      <div className="mb-4">
        <div className="flex flex-col gap-5">
          {/* INVOICE NUMBER */}
          <div className="space-y-2">
            <label
              htmlFor="invoiceNumber"
              className="block text-xs font-bold text-zinc-800"
            >
              Invoice Number
            </label>
            <Field
              name="invoiceNumber"
              type="number"
              className="w-full p-3 text-sm font-medium bg-white border border-gray-100 rounded outline-none text-neutral-500"
              placeholder="Enter the Invoice Number"
            />
            <CustomErrorMessage name="invoiceNumber" />
          </div>

          {/* CHOOSE CURRENCY */}
          <div className="space-y-2">
            <label
              htmlFor="currency"
              className="block text-xs font-bold text-zinc-800"
            >
              Choose Currency
            </label>
            <div className="relative">
              <Field
                name="currency"
                as="select"
                id="currency"
                className="w-full p-3 text-sm font-medium bg-white border border-gray-100 rounded outline-none text-neutral-500"
                value={values.currency || currencies[0].code}
              >
                {currencies
                  ?.sort((a, b) => a.code.localeCompare(b.code))
                  .map((currency) => (
                    <option
                      className="text-zinc-800"
                      value={currency.code}
                      key={currency.code}
                    >
                      {currency?.code} ({currency?.symbol})
                    </option>
                  ))}
              </Field>
              <span
                style={{
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
                className="absolute pointer-events-none text-AccentRegular"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 10L12 15L17 10"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>

            <CustomErrorMessage name="currency" />
          </div>

          {/* INVOICE DATE */}
          <div className="space-y-2">
            <label
              htmlFor="invoiceDate"
              className="block text-xs font-bold text-zinc-800"
            >
              Invoice Date
            </label>
            <div className="relative border rounded-md border-neutral-100">
              <DatePicker
                selected={
                  isTemplate
                    ? moment(values?.invoiceDate, "DD/MM/YYYY").toDate()
                    : moment(values?.invoiceDate).toDate()
                }
                onChange={(date) =>
                  setFieldValue(
                    "invoiceDate",
                    isTemplate
                      ? moment(date).format("DD/MM/YYYY")
                      : moment(date).toDate()
                  )
                }
                customInput={<DateInput value={values?.invoiceDate} />}
              />
              <span
                style={{
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
                className="absolute pointer-events-none"
              >
                <CalendarIcon className="w-6 h-6 text-AccentRegular" />
              </span>
            </div>
            <CustomErrorMessage name="invoiceDate" />
          </div>

          {/* DUE DATE */}
          <div className="space-y-2">
            <label
              htmlFor="dueDate"
              className="block text-xs font-bold text-zinc-800"
            >
              Due Date
            </label>
            <div className="relative border rounded-md border-neutral-100">
              <DatePicker
                selected={
                  isTemplate
                    ? moment(values?.dueDate, "DD/MM/YYYY").toDate()
                    : moment(values?.dueDate).toDate()
                }
                onChange={(date) => {
                  setSelectedDueDate(
                    isTemplate
                      ? moment(date, "DD/MM/YYYY").toDate()
                      : moment(date).toDate()
                  );
                  setFieldValue(
                    "dueDate",
                    isTemplate
                      ? moment(date).format("DD/MM/YYYY")
                      : moment(date).toDate()
                  );
                }}
                customInput={<DateInput value={values?.dueDate} />}
              />
              <span
                style={{
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
                className="absolute pointer-events-none"
              >
                <CalendarIcon className="w-6 h-6 text-AccentRegular" />
              </span>
            </div>
            <CustomErrorMessage name="dueDate" />
          </div>

          <div className="flex items-center gap-2">
            <h5
              className={`text-Neutral700 text-xs font-bold tracking-[0.1em] uppercase ${
                values.is_VAT && "text-text-AccentRegular"
              }`}
            >
              VAT
            </h5>
            <label className="flex items-center cursor-pointer select-none toggle-switch">
              <div className="relative">
                <input
                  type="checkbox"
                  className="sr-only"
                  checked={!values.is_VAT}
                  onChange={() => {
                    setFieldValue("is_VAT", !values.is_VAT);
                  }}
                />
                <div className="block h-[20px] switch w-[40px] rounded-full border-Neutral400 border-2 bg-Neutral100"></div>
                <div className="dot absolute left-0 top-0 h-5 w-5 rounded-full bg-Neutral800 transition shadow-[0px_1.5px_3px_1px_rgba(0,0,0,0.2)]"></div>
              </div>
            </label>
            <h5
              className={`text-Neutral700 text-xs font-bold tracking-[0.1em] uppercase ${
                !values.is_VAT && "text-AccentRegular"
              }`}
            >
              Custom Tax
            </h5>
          </div>

          {/* VAT PERCENT */}
          {values.is_VAT && (
            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <label
                  htmlFor="vatPercentage"
                  className="block text-xs font-bold text-zinc-800"
                >
                  {values.is_VAT ? "VAT" : "Custom Tax"} %
                </label>
                <Field
                  name="vatPercentage"
                  type="number"
                  step={"any"}
                  className={`bg-white border border-gray-100 rounded p-3 text-neutral-500 text-sm font-medium outline-none w-full md:w-80 ${
                    !values.is_VAT && "cursor-not-allowed opacity-50"
                  }`}
                  placeholder="VAT Percentage"
                  min="0"
                  max="100"
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      setFieldValue("vatPercentage", 0);
                    }
                    if (e.target.value > 100) {
                      setFieldValue("vatPercentage", 100);
                    }
                    if (e.target.value < 0) {
                      setFieldValue("vatPercentage", 0);
                    }
                  }}
                />
                <CustomErrorMessage name="vatPercentage" />
              </div>
            </div>
          )}
        </div>

        {/* CUSTOM TAX */}
        {!values.is_VAT && (
          <div className="flex flex-col gap-5 mt-5">
            <h2 className="pb-2 text-sm font-bold border-b text-zinc-800 border-neutral-100">
              Custom Tax
            </h2>

            {values.taxes?.map((tax, index) => (
              <div className="flex flex-col gap-4" key={`tax-${index}`}>
                <div className="flex items-center gap-2">
                  {/* TAX NAME */}
                  <div className="flex-1 space-y-2">
                    <label
                      htmlFor={`taxes[${index.toString()}].taxName`}
                      className="block text-xs font-bold text-zinc-800"
                    >
                      Tax Name
                    </label>
                    <Field
                      name={`taxes[${index.toString()}].taxName`}
                      type="text"
                      value={values.taxes?.[index]?.taxName || ""}
                      className="bg-white border border-gray-100 rounded-lg p-2.5 text-neutral-500 text-sm font-medium outline-none w-full"
                      placeholder="Eg. VAT/GST"
                    />
                    <CustomErrorMessage
                      name={`taxes[${index.toString()}].taxName`}
                    />
                  </div>

                  {/* TAX VALUE */}
                  <div>
                    <label
                      htmlFor={`taxes[${index.toString()}].taxValue`}
                      className="block mb-2 text-xs font-bold text-zinc-800"
                    >
                      Value %
                    </label>
                    <Field
                      name={`taxes[${index.toString()}].taxValue`}
                      type="number"
                      step={"any"}
                      placeholder={0}
                      min={0}
                      value={values.taxes?.[index]?.taxValue || ""}
                      id={`taxes[${index.toString()}].taxValue`}
                      className="bg-white border border-gray-100 rounded-lg p-2.5 text-neutral-500 text-sm font-medium outline-none w-80"
                    />
                    <CustomErrorMessage
                      name={`taxes[${index.toString()}].taxValue`}
                    />
                  </div>

                  {values.taxes?.length > 1 && (
                    <button
                      className="flex flex-col items-center justify-center w-10 h-10 gap-2 font-semibold text-indigo-500 border border-gray-200 rounded-full hover:bg-red-400 hover:text-white"
                      onClick={() =>
                        setFieldValue(
                          "taxes",
                          values.taxes.filter((tax, i) => i !== index)
                        )
                      }
                      type="button"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18 6L6 18"
                          stroke="url(#paint0_linear_7548_90924)"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6 6L18 18"
                          stroke="url(#paint1_linear_7548_90924)"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_7548_90924"
                            x1="6"
                            y1="6"
                            x2="17.9868"
                            y2="18.6395"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#6D6DF2" stopOpacity="0.8" />
                            <stop offset="1" stopColor="#6D6DF2" />
                          </linearGradient>
                          <linearGradient
                            id="paint1_linear_7548_90924"
                            x1="6"
                            y1="6"
                            x2="17.9868"
                            y2="18.6395"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#6D6DF2" stopOpacity="0.8" />
                            <stop offset="1" stopColor="#6D6DF2" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </button>
                  )}

                  {/* TAX VALUE */}
                </div>
              </div>
            ))}
            <button
              className={`py-3 text-sm rounded hover:bg-gray-200 font-semibold text-AccentRegular bg-AccentLight hover:bg-AccentMediumLight ${
                values.taxes?.length >= 3 && "cursor-not-allowed opacity-50"
              }`}
              onClick={() => {
                setFieldValue("taxes", [
                  ...values.taxes,

                  {
                    taxName: "",
                    taxValue: null,
                  },
                ]);
              }}
              type="button"
              disabled={values.taxes?.length >= 3}
            >
              Add Tax
            </button>
          </div>
        )}
      </div>

      <button
        type="submit"
        className="w-full p-2 py-3 text-xs font-bold tracking-wide uppercase rounded bg-Neutral300 text-AccentRegular hover:text-white not-disabled:hover:bg-AccentRegular disabled:text-Neutral600 disabled:cursor-not-allowed"
        disabled={isSubmitting}
      >
        {isSubmitting ? <ButtonLoader /> : "Save"}
      </button>
    </>
  );
}

export default Step4;
