import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';

import CloseBtn from '../../../assets/Images/close-btn.svg';
import { customModalStyles } from '../modalsData';
import Skeleton from './Skeleton';
import LeadCard from './LeadCard';
import { useDispatch } from 'react-redux';
import { deleteArchivedLeadAction } from '../../../store/actions/leadAction';

function ArchivedLeadsModal({
  isOpen = false,
  setIsOpen = () => {},
  isArchivedLoading = false,
}) {
  const dispatch = useDispatch();
  const archivedLeadsList = useSelector((state) => state?.lead?.leads);
  const [isLoading, setIsLoading] = useState(null);

  const handleDeleteLead = (id) => {
    setIsLoading(id);
    dispatch(deleteArchivedLeadAction(id, setIsLoading));
  };

  const leadsItemsList = (
    <ul className='flex flex-col gap-y-10 pt-3 pb-5'>
      {archivedLeadsList?.data
        ?.filter((item) => item?.is_archived)
        ?.map((item, index) => (
          <LeadCard
            lead={item}
            key={index}
            onDeleteLead={(id) => {
              handleDeleteLead(id);
            }}
            isLoading={isLoading}
          />
        ))}
    </ul>
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={customModalStyles}
      closeTimeoutMS={300}
      contentLabel='Archived client list Modal'
    >
      <div className='relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[480px] sm:min-w-[480px] sm:w-[480px] shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp'>
        <div className='bg-white px-12 pt-8 pb-8 rounded-[8px] overflow-hidden'>
          <div>
            <h3
              className='uppercase text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-2'
              id='modal-title'
            >
              Archived Leads
            </h3>
            <p className='text-Red900 bg-Red100 px-3 py-2 text-[12px] 5xl:text-sm leading-[1.4] font-normal rounded mb-3'>
              <span className='font-semibold'>Warning:</span> Deleting a lead is
              an irreversible action.
            </p>
          </div>
          <div className='h-[425px] block overflow-y-scroll scroll-p-3 scrollbar-w-4'>
            <div className='text-left'>
              <div className='overflow-y-scroll scroll-p-3 '>
                {isArchivedLoading
                  ? Array.from({ length: 3 }).map((i, index) => (
                      <Skeleton key={index} />
                    ))
                  : null}
                {!isArchivedLoading && archivedLeadsList?.data?.length
                  ? leadsItemsList
                  : null}
                {!isArchivedLoading && !archivedLeadsList?.data?.length ? (
                  <h5 className='text-Neutral700 text-sm font-normal mb-3 text-center pt-10'>
                    There are currently no archived leads.
                  </h5>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div
          className='absolute right-0 top-[-50px] z-30 cursor-pointer'
          onClick={() => setIsOpen(false)}
        >
          <img src={CloseBtn} alt='' />
        </div>
      </div>
    </Modal>
  );
}

export default ArchivedLeadsModal;
