import axios from "axios";
import { toast } from "react-toastify";

export const postRefrenceData = (value, setSubmitting, setIsOpen) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post("api/v1/user/pre-welcome-modal", value);
      toast.success("Refrence data added successfully");

      // Close after request
      setSubmitting(false);
      setIsOpen(false);

      return dispatch({
        type: "CREATE_PREWELCOME_DATA_SUCCESS",
        payload: { refrence: data.data, user: data.user },
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "CREATE_PREWELCOME_DATA_ERROR",
        payload: error,
      });
    }
  };
};
