import React from "react";

const TemplateLoader = () => {
  return Array.from({ length: 4 }).map((ele, index) => (
    <div
      className="w-full px-7 py-7 mt-10 bg-Neutral100 rounded-lg"
      key={index}
    >
      <div className="flex items-center">
        <div className="w-[146px] h-[24px] bg-[#f6f7f8] inline-block rounded-lg bg-no-repeat skeleton-animation relative mr-6"></div>
        <div className="w-[90px] h-[32px] bg-[#f6f7f8] inline-block rounded-lg bg-no-repeat skeleton-animation relative"></div>
      </div>
      <div className="gap-8 mt-8 flex flex-wrap">
        {Array.from({ length: 4 }).map((ele, index) => (
          <div
            className="w-[318px] h-[174px] border p-5 border-Neutral300 rounded-lg"
            key={`11${index}`}
          >
            <div className="flex items-center justify-between">
              <div className="w-[240px] h-[20px] bg-[#f6f7f8] inline-block rounded-lg bg-no-repeat skeleton-animation relative"></div>
              <div className="w-[8px] ml-4 h-[20px] bg-[#f6f7f8] inline-block rounded-lg bg-no-repeat skeleton-animation relative"></div>
            </div>
            <div className="inline-flex mt-[8px] relative gap-x-1">
              <div className="items-center gap-x-1 mt-2 inline-flex flex-wrap relative overflow-y-hidden">
                <div className="py-1 px-2 bg-[#f6f7f8] w-[75px] h-[23px] inline-block rounded-lg bg-no-repeat skeleton-animation relative"></div>
                <div className="py-1 px-2 bg-[#f6f7f8] w-[75px] h-[23px] inline-block rounded-lg bg-no-repeat skeleton-animation relative"></div>
                <div className="py-1 px-2 bg-[#f6f7f8] w-[75px] h-[23px] inline-block rounded-lg bg-no-repeat skeleton-animation relative"></div>
                <div className="py-1 px-2 bg-[#f6f7f8] w-[25px] h-[23px] inline-block rounded-lg bg-no-repeat skeleton-animation relative"></div>
              </div>
            </div>
            <div className="mt-[26px] flex gap-x-2">
              <div className="w-[134px] h-[43px] bg-[#f6f7f8] inline-block rounded-lg bg-no-repeat skeleton-animation relative"></div>
              <div className="w-[134px] h-[43px] bg-[#f6f7f8] inline-block rounded-lg bg-no-repeat skeleton-animation relative"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  ));
};

export default TemplateLoader;
