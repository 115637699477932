import React, { useState, useEffect, useCallback } from "react";
import OtpBox from "./component/OtpBox";
import { SendOtpAction } from "../../../../../store/actions/authAction";

const OtpInput = ({ setSignInDetail, signInDetail, errors }) => {
  const [timer, setTimer] = useState(30);

  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );

  useEffect(() => {
    if (signInDetail?.step === 3) {
      timer > 0 && setTimeout(timeOutCallback, 1000);
    }
  }, [timer, timeOutCallback]);
  const onOTPChange = (value) => {
    setSignInDetail({ ...signInDetail, verify: value });
  };

  const resetTimer = async function () {
    if (!timer) {
      const value = {
        email: signInDetail?.email,
        name: signInDetail?.name,
      };
      await SendOtpAction(value);
      setTimer(30);
    }
  };

  return (
    <div>
      <OtpBox
        value={signInDetail.verify.trim()}
        valueLength={4}
        onChange={onOTPChange}
      />
      <div>
        <span className="text-xs text-Red400">
          {errors?.otp || errors?.email || errors}
        </span>
      </div>
      <p className="text-Neutral800 font-normal text-sm mt-3">
        Didn’t receive code?{" "}
        {timer ? (
          <span className="italic">Please wait {timer}s</span>
        ) : (
          <span
            className="text-AccentRegular text-sm font-bold cursor-pointer"
            onClick={resetTimer}
          >
            Click to resend
          </span>
        )}
      </p>
    </div>
  );
};

export default OtpInput;
