import React from "react";

function CommentIcon({
  width = 18,
  height = 14,
  color = "#6D6DF2",
  className = "",
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 19.0618C0.5 19.2518 0.607712 19.4254 0.777969 19.5098C0.948227 19.5942 1.1516 19.5747 1.30281 19.4597L5.16863 16.5175H17C17.6648 16.5175 18.3013 16.2505 18.7697 15.777C19.2379 15.3037 19.5 14.6628 19.5 13.9957V3.8868C19.5 3.21972 19.2379 2.57888 18.7697 2.10555C18.3013 1.63203 17.6648 1.36501 17 1.36501H3C2.3352 1.36501 1.69874 1.63203 1.23032 2.10555C0.76209 2.57888 0.5 3.21972 0.5 3.8868V19.0618Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CommentIcon;
