import { Menu, Transition } from "@headlessui/react";
import React from "react";
import MoreIcon from "../../Icons/MoreIcon";
import { Fragment } from "react";
import PenIcon from "../../Icons/PenIcon";
import DeleteIcon from "../../Icons/DeleteIcon";

function EditColorMenu({ onModalOpen = () => {}, handleDelete = () => {} }) {
  return (
    <Menu as="div" className="relative inline-block">
      <div className="flex items-center justify-center">
        <Menu.Button className="inline-flex items-center justify-center w-full focus-visible:outline-none relative text-Neutral600 hover:text-Neutral800 duration-250 transition-all">
          <span className="focus-visible:outline-none ">
            <MoreIcon className="mt-[-4px]" />
          </span>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute top-[100%] right-[0] w-[130px] border-[0.5px] border-Neutral200 rounded z-10 shadow-[0_3px_6px_rgba(0,0,0,0.1)] bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none">
          <button
            className="w-[100%] flex items-center justify-center px-3 py-2 text-Neutral800 fill-Neutral800 bg-Neutral000 z-100 hover:text-AccentRegular hover:fill-AccentRegular transition-all duration-250 cursor-pointer rounded"
            onClick={onModalOpen}
          >
            <PenIcon
              width={16}
              height={16}
              className="mr-2 flex items-center justify-center"
              color="currentColor"
            />
            <span className="text-xs leading-[16px] tracking-[0.04em] font-medium">
              Edit Column
            </span>
          </button>
          <Menu.Item>
            <Menu
              as="div"
              className="relative z-100"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="">
                <Menu.Button className="focus-visible:outline-none w-full z-100">
                  <span className="focus-visible:outline-none">
                    <div className="w-[100%] flex items-center px-4 py-2 text-Neutral800 fill-Neutral800 bg-Neutral000 z-100 hover:text-Red600 hover:fill-Red600 transition-all duration-250 cursor-pointer rounded">
                      <DeleteIcon
                        className="mr-2"
                        color="currentColor"
                        width={16}
                        height={16}
                      />
                      <span className="text-xs leading-[16px] tracking-[0.04em] font-medium">
                        Delete
                      </span>
                    </div>
                  </span>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className="origin-top-right absolute -top-[80px] z-99 right-[-20px] pt-4 pb-6 w-[238px] px-6 rounded-lg Timetrackingdropdown bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none"
                  onClick={(e) => {
                    if (e.target === e.currentTarget) {
                      e.stopPropagation();
                    }
                  }}
                >
                  <div className="">
                    <p className="font-semibold text-xs text-Neutral700 pb-2">
                      Are you sure?
                    </p>
                  </div>
                  <div
                    className="border-0 overflow-hidden"
                    onClick={() => {
                      handleDelete();
                    }}
                  >
                    <h3 className="text-white text-xs mt-5 w-full bg-Red400 font-semibold py-4 rounded text-center cursor-pointer hover:bg-Red600 duration-300">
                      Yes, Delete
                    </h3>
                  </div>
                  <div className="overflow-hidden border-0 !border-transparent divide-0">
                    <h3 className="text-Neutral800 bg-Neutral200 font-semibold text-xs mt-3 w-full py-4 rounded text-center cursor-pointer hover:bg-Neutral500 duration-300 hover:text-Neutral900">
                      No, Please Keep It
                    </h3>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default EditColorMenu;
