import { Tab } from "@headlessui/react";

import Fullcalander from "../DashboardCalender/Fullcalander";

function CalenderViewContent() {
  return (
    <Tab.Panel>
      <Fullcalander />
    </Tab.Panel>
  );
}

export default CalenderViewContent;
