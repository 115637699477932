import React from "react";

function ShevronLeftIcon({
  width = 16,
  className = "",
  color = "#6D6DF2",
  rotated = false,
}) {
  return (
    <svg
      className={className}
      width={width}
      height={width}
      style={rotated ? { transform: "rotate(180deg)" } : {}}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 12L6 8L10 4"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ShevronLeftIcon;
