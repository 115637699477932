import React, { useEffect, useRef, useState } from "react";
import { getTagColor } from "../../../../utils/getTagColor";

const TagList = ({ tags, color }) => {
  const containerRef = useRef(null);
  const [hiddenCount, setHiddenCount] = useState(0);

  useEffect(() => {
    const container = containerRef.current;
    const children = container.children;
    const containerWidth = container.offsetWidth;

    let hiddenElements = 0;
    let totalWidth = 0;

    const childrenLength = Number(children?.length);

    for (let i = 2; i < childrenLength; i++) {
      const child = children[i];
      totalWidth = children[i].offsetWidth + totalWidth;
      if (i + 1 === children.length) {
        totalWidth = totalWidth - 28;
      }
      if (
        children.length > 2 &&
        child.style.position !== 'absolute'
        // &&
        // containerWidth < totalWidth + 28
      ) {
        child.style.display = 'none';
        hiddenElements++;
      }
    }

    setHiddenCount(hiddenElements);
  }, [tags]);
  return (
    <div className="inline-flex relative gap-x-1">
      <div
        className="items-center gap-x-1 mt-2 inline-flex flex-wrap relative max-h-[23px] overflow-y-hidden"
        ref={containerRef}
      >
        {tags?.length ? (
          tags?.map((tag, i) => {
            const { bgColor, text } = getTagColor(color, i);
            return (
              <div
                className={`bg-${bgColor} py-1 px-2 text-${text} rounded-lg text-[12px] uppercase font-medium tracking-[0.04em] leading-3`}
                key={i}
              >
                {tag}
              </div>
            );
          })
        ) : (
          <div
            className={`py-1 px-2 bg-${color}200 text-${color}800 rounded-lg text-[12px] uppercase  font-medium tracking-[0.04em] leading-3`}
          >
            NONE
          </div>
        )}
      </div>
      {hiddenCount > 0 ? (
        <div
          className={`text-${color}800 text-[10px] rounded-md border-[0.25px] max-h-[30px] border-${color}800 px-2 py-1 mt-2 top-0 right-0 font-medium`}
        >
          +{hiddenCount}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TagList;
