/** @param {import("react").SVGProps} props - SVG Icon Props */
function CubeIcon(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6939 12.9773V7.02649C16.6936 6.7656 16.6248 6.50937 16.4942 6.28351C16.3636 6.05764 16.176 5.87008 15.9501 5.73964L10.7431 2.76425C10.517 2.63368 10.2604 2.56494 9.99929 2.56494C9.73815 2.56494 9.48161 2.63368 9.25545 2.76425L4.04853 5.73964C3.8226 5.87008 3.63494 6.05764 3.50438 6.28351C3.37382 6.50937 3.30496 6.7656 3.30469 7.02649V12.9773C3.30496 13.2381 3.37382 13.4944 3.50438 13.7202C3.63494 13.9461 3.8226 14.1337 4.04853 14.2641L9.25545 17.2395C9.48161 17.3701 9.73815 17.4388 9.99929 17.4388C10.2604 17.4388 10.517 17.3701 10.7431 17.2395L15.9501 14.2641C16.176 14.1337 16.3636 13.9461 16.4942 13.7202C16.6248 13.4944 16.6936 13.2381 16.6939 12.9773Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.50586 6.25293L9.99963 10.0093L16.4934 6.25293"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 17.4999V10.002"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CubeIcon;
