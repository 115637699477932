import axios from "axios";

export const getDashboardCountAction = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("api/v1/user/get-dashboad-count");
      dispatch({
        type: "GET_DASHBOARD_COUNT",
        payload: { data: data.data, isLoading: false },
      });
    } catch (err) {
      console.log(err);
    }
  };
};

export const getUpcomingTaskCountAction = (filter = "month") => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `api/v1/task/upcoming-tasks-count?filter=${filter}`
      );
      dispatch({
        type: "GET_UPCOMING_TASK_COUNT",
        payload: { data: data.data, isLoading: false },
      });
    } catch (err) {
      console.log(err);
    }
  };
};

export const getTasksCountAction = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`api/v1/task/get-tasks-count`);
      dispatch({
        type: "GET_TASK_COUNT",
        payload: { data: data.data, isLoading: false },
      });
    } catch (err) {
      console.log(err);
    }
  };
};

export const getTimeTrackingAndDashboardCountAction = (
  filter = "month",
  { setLoading }
) => {
  return async (dispatch) => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `api/v1/time-tracking/get-time-tracking-count?filter=${filter}`
      );
      dispatch({
        type: "GET_TIME_TRACKING_&_DASHBOARD_COUNT",
        payload: { data: data.data, isLoading: false },
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
};

export const getYearlyTimeTrackingCountAction = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `api/v1/time-tracking/get-time-tracking-count-yearly`
      );
      dispatch({
        type: "GET_YEARLY_TIME_TRACKING_COUNT",
        payload: { data: data.data, isLoading: false },
      });
    } catch (err) {
      console.log(err);
    }
  };
};

export const getYearlyInvoiceCountAction = (filter = "month", setLoading) => {
  return async (dispatch) => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `api/v1/invoice/get-invoice-count?filter=${filter}`
      );
      dispatch({
        type: "GET_YEARLY_INVOICE_COUNT",
        payload: { data: data.data, isLoading: false },
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
};

export const getMyTasksAction = (
  filter = "all",
  monthFilter = "month",
  setLoading
) => {
  if (setLoading) setLoading(true);
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `api/v1/task/get-your-tasks?filter=${filter}&monthFilter=${monthFilter}`
      );
      dispatch({
        type: "GET_MY_TASKS",
        payload: { data: data.data, isLoading: false },
      });
    } catch (err) {
      console.log(err);
    } finally {
      if (setLoading) setLoading(false);
    }
  };
};

export const getMyTasksByPriorityAction = (
  filter = "all",
  monthFilter = "month"
) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `api/v1/task/get-your-tasks?filter=${filter}&monthFilter=${monthFilter}`
      );
      dispatch({
        type: "GET_MY_TASKS_BY_PRIORITY",
        payload: { data: data.data, isLoading: false },
      });
    } catch (err) {
      console.log(err);
    }
  };
};

export const getTasksCountByMemberAction = (filter = "all") => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `api/v1/task/get-tasks-count-by-member?filter=${filter}`
      );
      dispatch({
        type: "GET_TASKS_COUNT_BY_MEMBER",
        payload: { data: data.data, isLoading: false },
      });
    } catch (err) {
      console.log(err);
    }
  };
};
