import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useState, useRef } from "react";
import MenuIcon from "../../Icons/MenuIcon";
import DeleteIcon from "../../Icons/DeleteIcon";
import RedirectIcon from "../../Icons/RedirectIcon";
import moment from "moment";

const CustomVideoPlayer = ({
  src,
  isHover,
  file,
  onClose,
  onDelete,
  handleOpenInBrouser,
  handleDownloadClick,
}) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;
    setCurrentTime(currentTime);
    setDuration(duration);
  };

  const handleProgressBarClick = (e) => {
    const progressBar = e.target;
    const offsetX = e.nativeEvent.offsetX;
    const clickedTime = (offsetX / progressBar.offsetWidth) * duration;
    videoRef.current.currentTime = clickedTime;
  };

  const handlePlayPauseClick = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  return (
    <div className="w-[791px] relative">
      <video
        ref={videoRef}
        src={src}
        onTimeUpdate={handleTimeUpdate}
        className="video rounded-lg"
        style={{
          width: "100%",
          maxHeight: "100%",
        }}
      />

      {isHover && (
        <>
          <div
            className="absolute top-0 w-full h-[17%] z-90 rounded-t-lg	"
            style={{
              background:
                "linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(41, 41, 51, 0) 100%)",
            }}
          >
            <div className="absolute top-4 left-5 flex gap-2">
              <div className="flex  items-center  justify-center w-10 h-10  rounded	 overflow-hidden">
                {file?.created_by?.profile ? (
                  <img
                    src={file?.created_by?.profile_path}
                    alt="avatar"
                    className="object-cover w-10 h-10"
                  />
                ) : (
                  <div className="w-10 h-10 bg-Neutral300 rounded-full flex items-center justify-center">
                    <p className="text-Neutral700 text-sm font-semibold">
                      {file?.created_by?.user_name?.charAt(0)}
                    </p>
                  </div>
                )}
              </div>
              <div className="flex flex-col justify-center  gap-1.5">
                <p className="text-Neutral000 font-bold text-base">
                  {file?.created_by?.user_name}
                </p>
                <p className="text-Neutral200 font-medium text-[10px] leading-[8px]">
                  {moment(file?.created_at).fromNow()} -{" "}
                  {file?.file?.originalname}
                </p>
              </div>
            </div>

            <button onClick={onClose} className="absolute top-4 right-5">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.5 7.5L7.5 16.5"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.5 7.5L16.5 16.5"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>

          <div
            className="absolute bottom-0 w-full h-[17%] flex rounded-b-lg	"
            style={{
              background:
                "linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(41, 41, 51, 0) 100%)",
            }}
          >
            <div className="absolute bottom-5 px-5 flex justify-between items-center w-full">
              <div className="flex justify-between ">
                {isPlaying ? (
                  <button onClick={handlePlayPauseClick}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 4H6V20H10V4Z"
                        fill="white"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18 4H14V20H18V4Z"
                        fill="white"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                ) : (
                  <button onClick={handlePlayPauseClick}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 4L20 12L8 20V4Z"
                        fill="white"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                )}
              </div>

              <div
                className="w-[590px] h-1.5 rounded-[10px] relative"
                onClick={handleProgressBarClick}
              >
                <div className="absolute w-full h-1.5 rounded-[10px] bg-Neutral000 opacity-20"></div>
                <div
                  className="h-1.5 rounded-[10px] bg-Neutral200 "
                  style={{ width: `${(currentTime / duration) * 100}%` }}
                ></div>
              </div>

              <div className="flex justify-between w-[81px]">
                <button onClick={handleDownloadClick}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.4999 15.168V18.7235C20.4999 19.195 20.3126 19.6472 19.9792 19.9806C19.6458 20.314 19.1936 20.5013 18.7221 20.5013H6.27766C5.80616 20.5013 5.35397 20.314 5.02058 19.9806C4.68718 19.6472 4.49988 19.195 4.49988 18.7235V15.168"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.05505 10.7227L12.4995 15.1671L16.9439 10.7227"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.4999 15.1667V4.5"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>

                <Menu as="div" className="  flex items-center text-left">
                  <div className="flex items-center">
                    <Menu.Button className="inline-flex justify-center w-full">
                      <MenuIcon color="#FFFFFF" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="edit-menu-servies edit-delete-menu absolute z-[999] top-0 right-0 rounded-lg bg-Neutral000 border-[0.5px] border-Neutral200 divide-y divide-Neutral300 focus:outline-none z-90 overflow-hidden">
                      <form method="POST" action="#">
                        <Menu.Item>
                          {({ active }) => (
                            <div>
                              <div
                                className="flex gap-2  text-left text-Neutral800 px-4 py-2 text-xs whitespace-nowrap flex justify-start items-center cursor-pointer font-semibold hover:bg-Neutral200 transition duration-300 "
                                onClick={handleOpenInBrouser}
                              >
                                <RedirectIcon />
                                <span>Open in Browser</span>
                              </div>
                              <div
                                className="flex gap-2 text-Neutral800 text-left px-4 py-2 text-xs whitespace-nowrap flex justify-start items-center border-t-[0.5px] border-Neutral200 cursor-pointer font-semibold hover:bg-Neutral200  transition duration-300"
                                onClick={() => onDelete(file)}
                              >
                                <DeleteIcon />
                                <span>Delete</span>
                              </div>
                            </div>
                          )}
                        </Menu.Item>
                      </form>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CustomVideoPlayer;
