import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const GuestGuard = ({ children }) => {
    const auth = useSelector((state) => state.collab_auth);
    if (auth?.authToken || (!auth?.userLoading && auth?.user)) {
        return <Navigate to="/client-collab/all-projects" replace />;
    }
    return children;
};

export default GuestGuard;