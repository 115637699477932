import React from "react";

function MoreIcon({ width = 24, className = "", color = "currentColor" }) {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.9285 12.499C12.2046 12.499 12.4285 12.2752 12.4285 11.999C12.4285 11.7229 12.2046 11.499 11.9285 11.499C11.6523 11.499 11.4285 11.7229 11.4285 11.999C11.4285 12.2752 11.6523 12.499 11.9285 12.499Z"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9285 8.99902C12.2046 8.99902 12.4285 8.77517 12.4285 8.49902C12.4285 8.22288 12.2046 7.99902 11.9285 7.99902C11.6523 7.99902 11.4285 8.22288 11.4285 8.49902C11.4285 8.77517 11.6523 8.99902 11.9285 8.99902Z"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9285 15.999C12.2046 15.999 12.4285 15.7752 12.4285 15.499C12.4285 15.2229 12.2046 14.999 11.9285 14.999C11.6523 14.999 11.4285 15.2229 11.4285 15.499C11.4285 15.7752 11.6523 15.999 11.9285 15.999Z"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default MoreIcon;
