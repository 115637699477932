import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { useNavigate } from "react-router";
import { convertLeadToClientAction } from "../../store/actions/leadAction";
import {
  CloseModalAction,
  OpenModalAction,
} from "../../store/actions/modalsAction";
import DeleteIcon from "../Icons/DeleteIcon";
import LightningIcon from "../Icons/LightningIcon";
import PenIcon from "../Icons/PenIcon";

const actionsItems = [
  {
    icon: <LightningIcon width={16} height={16} color="currentColor" />,
    title: "Convert to Client",
    modal: "lead_details",
  },
  // {
  //   icon: <PenIcon width={16} height={16} color='currentColor' />,
  //   title: 'Add a New Note',
  //   modal: 'lead_note',
  //   option: 'note',
  // },
  {
    icon: <DeleteIcon width={16} color="currentColor" />,
    title: "Delete",
    modal: "delete_lead",
  },
];

const editDetailsItems = [
  {
    icon: <PenIcon width={16} height={16} color="currentColor" />,
    title: "Edit details",
    modal: "edit_lead",
  },
  {
    icon: <DeleteIcon width={16} color="currentColor" />,
    title: "Delete",
    modal: "delete_lead",
  },
];

const LeadsActionMenu = ({
  noConvertToClient = false,
  onDeleteLead = () => {},
  leadId = "",
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const titleDropDownref = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const menuItems = noConvertToClient ? editDetailsItems : actionsItems;

  const onEditDetailsClick = (modalName = "") => {
    dispatch(OpenModalAction(modalName));
  };

  const onConvertClick = (e) => {
    setTimeout(() => {
      titleDropDownref?.current?.click();
      e.stopPropagation();
    }, 0);
    setIsLoading(true);
    dispatch(convertLeadToClientAction({ id: leadId }, "", setIsLoading));
  };
  const actionsMenuItems = menuItems.map((item, idx) => {
    if (item?.modal === "delete_lead" || item?.modal === "lead_details") {
      return (
        <Menu
          as="li"
          key={idx}
          className="relative"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Menu.Button
            ref={item?.modal === "lead_details" ? titleDropDownref : null}
            className="w-[100%] flex items-center px-3 py-2 text-Neutral800 hover:text-AccentRegular hover:fill-AccentRegular transition-all duration-250 cursor-pointer rounded"
          >
            {item?.modal === "lead_details" && isLoading ? (
              <div className="flex">
                <div className="w-4 h-4 mr-2">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-Neutral900"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="green"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="green"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
                <span className="text-xs text-green-500">Converting...</span>
              </div>
            ) : (
              <>
                <span className="flex items-center justify-center">
                  {item.icon}
                </span>
                <span className="ml-2 text-xs leading-[16px] tracking-[0.04em] font-medium">
                  {item.title}
                </span>
              </>
            )}
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute -top-[60px] right-[-10px] pt-4 pb-6 w-[238px] px-6 rounded-lg Timetrackingdropdown bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none z-10">
              <p className="font-semibold text-xs text-Neutral700 pb-2 border-b border-Neutral200 mb-3">
                Are you sure?
              </p>
              <p className="text-Red600 bg-Red100 px-3 py-2 text-[12px] 5xl:text-sm leading-[1.4] font-normal rounded mb-1">
                <span className="font-semibold">Warning:</span> Converting a
                lead is an irreversible action.
              </p>
              <button
                className="border-0 overflow-hidden w-[100%] mt-5"
                onClick={(e) => {
                  if (item?.modal === "lead_details") {
                    onConvertClick(e);
                  }
                  if (item?.modal === "delete_lead") {
                    onDeleteLead(leadId);
                  }
                  if (noConvertToClient) {
                    dispatch(CloseModalAction("lead_details"));
                  }
                }}
              >
                <h3 className="text-white text-xs  w-full bg-AccentRegular font-semibold py-4 rounded text-center cursor-pointer hover:bg-AccentMediumDark duration-300">
                  Yes,{" "}
                  {item?.modal === "lead_details"
                    ? "convert to client"
                    : "Delete It"}{" "}
                </h3>
              </button>
              <Menu.Button className="overflow-hidden border-0 !border-transparent divide-0 w-[100%]">
                <h3 className="text-Neutral800 bg-Neutral200 font-semibold text-xs mt-3 w-full py-4 rounded text-center cursor-pointer hover:bg-Neutral500 duration-300 hover:text-Neutral900">
                  No,{" "}
                  {item?.modal === "lead_details" ? "keep as lead" : "Keep It"}
                </h3>
              </Menu.Button>
            </Menu.Items>
          </Transition>
        </Menu>
      );
    }
    return (
      <Menu.Button
        as="li"
        key={idx}
        onClick={(e) => {
          e.stopPropagation();
          if (item?.modal === "lead_note") {
            navigate({
              search: `?cardId=${leadId}`,
            });
            document.getElementById(`add-note-${leadId}`)?.click();
          }
          onEditDetailsClick(item?.modal);
        }}
        className="w-[100%] flex items-center px-3 py-2 text-Neutral800 hover:text-AccentRegular hover:fill-AccentRegular transition-all duration-250 cursor-pointer rounded"
      >
        <span className="flex items-center justify-center">{item.icon}</span>
        <span className="ml-2 text-xs leading-[16px] tracking-[0.04em] font-medium">
          {item.title}
        </span>
      </Menu.Button>
    );
  });

  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="origin-top-right absolute top-[100%] right-[0] w-[156px] border-[0.5px] border-Neutral200 rounded shadow-[0_3px_6px_rgba(0,0,0,0.1)] bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none">
        <ul className="list-none divide-y divide-Neutral200">
          {actionsMenuItems}
        </ul>
      </Menu.Items>
    </Transition>
  );
};

export default LeadsActionMenu;
