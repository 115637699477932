export const currencies = [
  { name: "United States Dollar", code: "USD", symbol: "$" },
  { name: "Euro", code: "EUR", symbol: "€" },
  { name: "British Pound", code: "GBP", symbol: "£" },
  { name: "Canadian Dollar", code: "CAD", symbol: "C$" },
  { name: "Australian Dollar", code: "AUD", symbol: "A$" },
  { name: "Japanese Yen", code: "JPY", symbol: "¥" },
  { name: "Chinese Yuan", code: "CNY", symbol: "¥" },
  { name: "Swiss Franc", code: "CHF", symbol: "₣" },
  { name: "Russian Ruble", code: "RUB", symbol: "₽" },
  { name: "Indian Rupee", code: "INR", symbol: "₹" },
  { name: "Brazilian Real", code: "BRL", symbol: "R$" },
  { name: "South African Rand", code: "ZAR", symbol: "R" },
  { name: "Mexican Peso", code: "MXN", symbol: "$" },
  { name: "New Zealand Dollar", code: "NZD", symbol: "NZ$" },
  { name: "South Korean Won", code: "KRW", symbol: "₩" },
  {
    name: "MALAYSIAN RINGGIT",
    code: "MYR",
    symbol: "RM",
  },
  {
    name: "SINGAPORE DOLLAR",
    code: "SGD",
    symbol: "S$",
  },
  {
    name: "DANISH KRONE",
    code: "DKK",
    symbol: "kr",
  },
  {
    name: "BULGARIAN LEV",
    code: "BGN",
    symbol: "лв",
  },
  {
    name: "EGYPTIAN POUND",
    code: "EGP",
    symbol: "E£",
  },
  {
    name: "INDONESIAN RUPIAH",
    code: "IDR",
    symbol: "Rp",
  },
  {
    name: "PHILIPPINE PESO",
    code: "PHP",
    symbol: "₱",
  },
  {
    name: "UNITED ARAB EMIRATES DIRHAM",
    code: "AED",
    symbol: "د.إ",
  },
  {
    name: "THAI BAHT",
    code: "THB",
    symbol: "฿",
  },
  {
    name: "KENYAN SHILLING",
    code: "KES",
    symbol: "KES",
  },
  {
    name: "KRONE",
    code: "ISK",
    symbol: "Íkr",
  },
  {
    name: "SRI LANKAN RUPEE",
    code: "LKR",
    symbol: "Rs",
  },
  {
    name: "TANZANIAN SHILLING",
    code: "TZS",
    symbol: "TSh",
  },
  {
    name: "ISRAELI SHEKEL",
    code: "ILS",
    symbol: "₪",
  },
  {
    name: "COLOMBIAN PESO",
    code: "COP",
    symbol: "COL$",
  },
  {
    name: "CZECH KORUNA",
    code: "CZK",
    symbol: "Kč",
  },
  {
    name: "NORWEGIAN KRONE",
    code: "NOK",
    symbol: "kr",
  },
];
