import React from "react";
import clx from "classnames";
import { convertSecondsToTime } from "../../../utils/convertTime";

function TimeTrackingBadge({ time = null, className = "" }) {
  return (
    <span
      className={clx(
        "rounded-[40px] px-3 py-[6px] text-center bg-Green100 inline-block text-Green800 w-fit font-semibold text-xs leading-[16px]",
        className
      )}
    >
      {convertSecondsToTime(time)}
    </span>
  );
}

export default TimeTrackingBadge;
