import React from "react";
import clx from "classnames";

function ArrowLeftIcon({
  width = 25,
  height = 24,
  className = "",
  color = "#D3D3F5",
  rotated = false,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clx(rotated ? "rotate-180" : "", className)}
    >
      <path
        d="M0.469669 11.4697C0.176777 11.7626 0.176777 12.2374 0.469669 12.5303L5.24264 17.3033C5.53553 17.5962 6.01041 17.5962 6.3033 17.3033C6.59619 17.0104 6.59619 16.5355 6.3033 16.2426L2.06066 12L6.3033 7.75736C6.59619 7.46446 6.59619 6.98959 6.3033 6.6967C6.01041 6.4038 5.53553 6.4038 5.24264 6.6967L0.469669 11.4697ZM25 11.25L1 11.25L1 12.75L25 12.75L25 11.25Z"
        fill={color}
      />
    </svg>
  );
}

export default ArrowLeftIcon;
