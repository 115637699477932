import copy from "copy-to-clipboard";
import { Formik } from "formik";
import { useEffect, useMemo, useState } from "react";

import ServiceStep from "../../components/CreateInvoiceForm/ServicesStep";
import Step1 from "../../components/CreateInvoiceForm/Step1";
import Step2 from "../../components/CreateInvoiceForm/Step2";
import Step3 from "../../components/CreateInvoiceForm/Step3";
import Step4 from "../../components/CreateInvoiceForm/Step4";
import Step5 from "../../components/CreateInvoiceForm/Step5";
import A4Page from "../../components/Invoice-v2/A4Page";
import FormDropdown from "../../components/Invoice-v2/FormDropdown";
import useBaseDomainURL from "../../hooks/useBaseDomainURL";
import downloadInvoice from "../../utils/downloadInvoice";
import DownloadIcon from "../Icons/DownloadIcon";
import Spinner from "../Spinner";

function InvoiceForm({
  clientId,
  invoiceId,
  initialValues,
  validationSchema,
  handleSubmitFunction,
  currentStep,
  setCurrentStep,
  setIsCreateInvoice,
  loadingPaymentLink,
  selectedDueDate,
  setSelectedDueDate,
  invoice,
  user,
  activeFocusedTab,
  setActiveFocusedTab,
  handlePaypal,
  handleStripe,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [copyStatus, setCopyStatus] = useState("Copy Link");
  const [linkIsOpen, setLinkIsOpen] = useState(false);

  const baseDomainUrl = useBaseDomainURL();

  const stepValidation = useMemo(
    () => ({
      1: (values) =>
        values.invoiceName !== "" && values.categoryTags?.length > 0,
      2: (values) => values.clientName !== "",
      3: (values) => values.email !== "" && values.fullName !== "",
      4: (values) =>
        values.invoiceNumber !== "" &&
        values.currency !== "" &&
        values.invoiceDate !== "" &&
        values.dueDate !== "",
      5: (values) => {
        if (["Paypal", "Stripe"].includes(values.paymentMethod)) {
          return values.paymentLink !== "" && values.paymentLink;
        } else if (values.paymentMethod === "Bank") {
          return values.bankDetails !== "" && values.bankDetails;
        } else {
          // If paymentMethod is not one of the expected values, return false
          return false;
        }
      },
    }),
    []
  );

  const isStepComplete = (step, values) => {
    const validateStep = stepValidation[step];
    return validateStep ? validateStep(values) : false;
  };

  const isServicesComplete = (values) => {
    if (values?.services?.length) {
      const service = values?.services[0];
      return !!service?.serviceName;
    }
    return false;
  };

  const handleCopyLink = () => {
    const link = `${baseDomainUrl}/public-invoice/${clientId}/invoice/${invoiceId}`;

    copy(link);

    setLinkIsOpen(true);
    setCopyStatus("Link Copied");
    setTimeout(() => {
      setCopyStatus("Copy Link");
    }, 1500);
  };

  useEffect(() => {
    if (activeFocusedTab) {
      const section = document.getElementById(activeFocusedTab);
      if (section) {
        // Scroll to the section smoothly
        section.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [activeFocusedTab]);

  const checkAllStepsComplete = async (
    values,
    setTouched,
    validateForm,
    setFieldValue
  ) => {
    const stepOrder = [
      {
        id: "templateName",
        step: 1,
        errorKeys: ["invoiceName", "categoryTags"],
      },
      {
        id: "clientInformation",
        step: 2,
        errorKeys: [
          "clientName",
          "clientEmail",
          "clientPhoneNumber",
          "clientAddress",
        ],
      },
      {
        id: "myInformation",
        step: 3,
        errorKeys: [
          "email",
          "fullName",
          "companyName",
          "address",
          "phoneNumber",
        ],
      },
      {
        id: "invoiceDetails",
        step: 4,
        errorKeys: [
          "invoiceNumber",
          "currency",
          "invoiceDate",
          "dueDate",
          "vatPercentage",
        ],
      },
      { id: "serviceStep", step: 5, errorKeys: ["services"] },
      {
        id: "paymentInformation",
        step: 6,
        errorKeys: ["paymentMethod", "bankDetails", "paymentLink"],
      },
    ];

    for (const { step, id, errorKeys } of stepOrder) {
      const errorSet = errorKeys.reduce((acc, curr) => {
        acc[curr] = true;
        return acc;
      }, {});

      if (step > 0 && step !== 5) {
        if (!isStepComplete(step, values)) {
          setFieldValue("forceValidate", true, false);
          setActiveFocusedTab(id);
          setCurrentStep(step);

          await validateForm();
          setTouched(errorSet);
          setFieldValue("forceValidate", false, false);
          return;
        } else {
          setFieldValue("forceValidate", false, false);
        }
      } else if (step === 5) {
        if (!isServicesComplete(values)) {
          setFieldValue("forceValidate", true, false);
          setActiveFocusedTab(id);
          setCurrentStep(step);

          await validateForm();
          setTouched(errorSet, true);
          setFieldValue("forceValidate", false, false);
          return;
        } else {
          setFieldValue("forceValidate", false, false);
        }
      } else {
        setFieldValue("forceValidate", false, false);
        return;
      }
    }

    setFieldValue("forceValidate", false, false);
  };

  return (
    <Formik
      initialValues={{ ...initialValues, step: currentStep }}
      validationSchema={validationSchema}
      onSubmit={handleSubmitFunction}
      enableReinitialize
    >
      {({
        values,
        errors,
        isSubmitting,
        isValid,
        setFieldValue,
        handleSubmit,
        submitForm,
        setTouched,
        validateForm,
      }) => {
        return (
          <div className="flex flex-col items-start gap-10 px-3 py-5 mb-20 lg:px-10 lg:flex-row bg-gray-50 lg:mb-0">
            <form
              className="flex w-full lg:w-auto flex-col flex-grow gap-5 max-h-[calc(100dvh-130px)] 2xl:max-w-[45%] overflow-y-auto scroll-smooth"
              onSubmit={handleSubmit}
            >
              <FormDropdown
                stepCount={1}
                sectionId="templateName"
                title="Template Name"
                focused={activeFocusedTab === "templateName"}
                setActiveFocusedTab={setActiveFocusedTab}
                complete={isStepComplete(1, values)}
                updateStep={(step) => setCurrentStep(step)}
                isSubmitting={isSubmitting}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
              >
                {(props) => (
                  <Step1
                    {...props}
                    values={values}
                    setFieldValue={setFieldValue}
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                    errors={errors}
                  />
                )}
              </FormDropdown>

              <FormDropdown
                stepCount={2}
                sectionId="clientInformation"
                title="Client Information"
                focused={activeFocusedTab === "clientInformation"}
                setActiveFocusedTab={setActiveFocusedTab}
                complete={isStepComplete(2, values)}
                updateStep={(step) => setCurrentStep(step)}
                isSubmitting={isSubmitting}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
              >
                {(props) => <Step2 isSubmitting={isSubmitting} {...props} />}
              </FormDropdown>

              <FormDropdown
                stepCount={3}
                sectionId="myInformation"
                title="My Information"
                focused={activeFocusedTab === "myInformation"}
                setActiveFocusedTab={setActiveFocusedTab}
                complete={isStepComplete(3, values)}
                updateStep={(step) => setCurrentStep(step)}
                isSubmitting={isSubmitting}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
              >
                {/* <Step3 isSubmitting={isSubmitting} /> */}
                {(props) => <Step3 isSubmitting={isSubmitting} {...props} />}
              </FormDropdown>

              <FormDropdown
                stepCount={4}
                sectionId="invoiceDetails"
                title="Invoice Details"
                focused={activeFocusedTab === "invoiceDetails"}
                setActiveFocusedTab={setActiveFocusedTab}
                complete={isStepComplete(4, values)}
                updateStep={(step) => setCurrentStep(step)}
                isSubmitting={isSubmitting}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
              >
                {(props) => (
                  <Step4
                    {...props}
                    setFieldValue={setFieldValue}
                    values={values}
                    isSubmitting={isSubmitting}
                    setSelectedDueDate={setSelectedDueDate}
                  />
                )}
              </FormDropdown>

              <FormDropdown
                stepCount={5}
                sectionId="serviceStep"
                title="Services"
                setActiveFocusedTab={setActiveFocusedTab}
                focused={activeFocusedTab === "serviceStep"}
                complete={isServicesComplete(values)}
                updateStep={(step) => setCurrentStep(step)}
                isSubmitting={isSubmitting}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
              >
                {(props) => (
                  <ServiceStep
                    {...props}
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    submitForm={submitForm}
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                    currentStep={currentStep}
                  />
                )}
              </FormDropdown>

              <FormDropdown
                stepCount={6}
                sectionId="paymentInformation"
                title="Payment Information"
                focused={activeFocusedTab === "paymentInformation"}
                setActiveFocusedTab={setActiveFocusedTab}
                updateStep={(step) => setCurrentStep(step)}
                complete={isStepComplete(5, values)}
                isSubmitting={isSubmitting}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
              >
                {(props) => (
                  <Step5
                    {...props}
                    values={values}
                    isSubmitting={isSubmitting}
                    handlePaypal={handlePaypal}
                    handleStripe={handleStripe}
                    loadingPaymentLink={loadingPaymentLink}
                  />
                )}
              </FormDropdown>

              {/* Additional form elements (e.g., download and copy buttons) */}
              {isValid &&
              values.services.length > 0 &&
              isStepComplete(5, values) ? (
                <div className="p-5 bg-white rounded-lg">
                  <h4 className="mb-4 text-xs font-bold uppercase text-AccentRegular">
                    Send to client
                  </h4>
                  <div className="flex gap-2">
                    <button
                      type="button"
                      className="flex items-center justify-center flex-1 gap-1.5 px-2 py-3 text-sm font-medium rounded-full shadow-lg bg-AccentLight text-AccentRegular hover:bg-AccentMediumLight shadow-AccentMediumLight"
                      onClick={() => downloadInvoice(values, setIsLoading)}
                    >
                      {isLoading ? (
                        <Spinner
                          size={18}
                          thumbColor="var(--bg-color)"
                          spinnerBg="var(--accent-color)"
                        />
                      ) : (
                        <DownloadIcon size={22} />
                      )}
                      Download
                    </button>

                    <button
                      type="button"
                      className="flex items-center justify-center flex-1 gap-1.5 px-2 py-3 text-sm font-medium rounded-full shadow-lg bg-AccentLight text-AccentRegular shadow-AccentMediumLight hover:bg-AccentMediumLight"
                      onClick={handleCopyLink}
                    >
                      <LinkIcon size={20} />
                      {copyStatus}
                    </button>
                  </div>
                </div>
              ) : (
                <button
                  type="button"
                  className="flex items-center justify-center flex-1 gap-1.5 px-2 py-4 text-sm font-semibold rounded-md shadow-lg bg-AccentLight text-AccentRegular shadow-AccentMediumLight hover:bg-AccentMediumLight uppercase tracking-wider"
                  onClick={() =>
                    checkAllStepsComplete(
                      values,
                      setTouched,
                      validateForm,
                      setFieldValue
                    )
                  }
                >
                  Create Invoice
                </button>
              )}
            </form>

            <div className="flex-grow w-full lg:max-w-[620px] 2xl:max-w-[55%] max-h-[calc(100dvh-130px)]">
              <A4Page
                values={values}
                handleFocused={setActiveFocusedTab}
                setActiveFocusedTab={setActiveFocusedTab}
                user={user}
              />
            </div>
          </div>
        );
      }}
    </Formik>
  );
}

export default InvoiceForm;

const LinkIcon = ({ size = 24, ...props }) => (
  <svg
    width={size + 1}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.2273 7.4541H17.9545C18.5515 7.4541 19.1425 7.57167 19.694 7.8001C20.2455 8.02853 20.7466 8.36335 21.1687 8.78543C21.5908 9.20752 21.9256 9.70861 22.154 10.2601C22.3824 10.8116 22.5 11.4026 22.5 11.9996C22.5 12.5965 22.3824 13.1875 22.154 13.739C21.9256 14.2905 21.5908 14.7916 21.1687 15.2137C20.7466 15.6358 20.2455 15.9706 19.694 16.199C19.1425 16.4274 18.5515 16.545 17.9545 16.545H15.2273M9.77273 16.545H7.04545C6.44854 16.545 5.85746 16.4274 5.30598 16.199C4.7545 15.9706 4.25342 15.6358 3.83133 15.2137C2.97889 14.3612 2.5 13.2051 2.5 11.9996C2.5 10.794 2.97889 9.63787 3.83133 8.78543C4.68377 7.933 5.83993 7.4541 7.04545 7.4541H9.77273"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.86328 12H16.136"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
