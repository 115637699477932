import React from "react";

function EmailIcon({ className = "", width = 24, color = "currentColor" }) {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.75 5H18.75C19.7125 5 20.5 5.7875 20.5 6.75V17.25C20.5 18.2125 19.7125 19 18.75 19H4.75C3.7875 19 3 18.2125 3 17.25V6.75C3 5.7875 3.7875 5 4.75 5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5 6.75L11.75 12.875L3 6.75"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default EmailIcon;
