import React, { useEffect } from "react";
import Modal from "react-modal";
import CloseBtn from "../../assets/Images/close-btn.svg";
import { useDispatch, useSelector } from "react-redux";

import {
  getMemberList,
  removeteammemberAction,
  resendInviteAction,
} from "../../store/actions/memberAction";
import { useState } from "react";
import { XIcon } from "@heroicons/react/solid";

const dark = "rgba(41, 41, 51)";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

function ExpandTeamsModal({ isOpen, setModal, focusOnMember }) {
  const dispatch = useDispatch();
  const logedinUser = useSelector((state) => state.auth.user);

  const members = useSelector((state) => state.member.members);
  const [focusedMembers, setFocusedMembers] = useState([]);
  const [isloding, setisloding] = useState(false);
  const [isResending, setIsResending] = useState(false);

  let handleMemberFocus = (id) => {
    if (focusedMembers.includes(id)) {
      setFocusedMembers(focusedMembers.filter((m) => m !== id));
    } else {
      setFocusedMembers([].concat(focusedMembers, id));
    }
  };

  useEffect(() => {
    if (!members?.data?.length && isOpen) {
      dispatch(getMemberList());
    }
  }, [dispatch, isOpen]);

  let handleMemberDelete = async (item) => {
    setisloding(true);
    dispatch(removeteammemberAction({ member_id: item?._id }, setisloding));
  };

  let handleResendInvite = async (item) => {
    setIsResending(true);
    dispatch(resendInviteAction({email: item?.email}, setIsResending));
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setModal(false)}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="Test Modal"
      >
        <div className="relative sm:my-8 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div
            className={`bg-white px-6 md:px-12 pt-8 rounded-[8px] md:max-w-[60vw] ${
              members?.data.length <= 9 ? "pb-10" : ""
            }`}
          >
            <div>
              <h3
                className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-8"
                id="modal-title"
              >
                {members?.data.length || "0"} Members
              </h3>

              <div className="flex flex-wrap max-h-[60vh] overflow-y-auto m-0 p-0 gap-4">
                {members.data.map((item, index) => {
                  return (
                    <>
                      <div
                        onClick={() =>
                          focusOnMember(item?.member?._id || item?._id)
                        }
                        key={`member-p-${index}`}
                        className="h-[120px] w-[120px]  md:h-[146px] md:w-[146px] cursor-pointer grid grid-rows-5 group overflow-hidden rounded-lg"
                      >
                        {/* <div
                          className={`border border-Neutral300 md:w-[48px] md:h-[48px] h-[48px] w-[48px] flex items-center justify-center rounded-full bg-Neutral400 text-Neutral700 focus-visible:outline-none border-4 border-white
                                          `}
                        >
                          <span className="text-[20px] 5xl:text-[24px] w-full h-full flex items-center justify-center rounded-full font-bold border border-Miscgrey">
                            {item?.user_name[0]}
                          </span>
                        </div> */}
                        {!item?.profile && !item?.member?.profile_path ? (
                          <span className="w-full bg-Neutral400 row-start-1 row-end-6 col-start-1 text-[40px] 5xl:text-[24px] h-full flex items-center justify-center font-bold border-Miscgrey text-Neutral700">
                            {item?.user_name[0]}
                          </span>
                        ) : (
                          <img
                            className={`w-full row-start-1 row-end-6 col-start-1 rounded-lg h-full focus-visible:outline-none border-Neutral200 border`}
                            src={
                              item?.member?.profile_path || item?.profile_path
                            }
                            alt="UserButtons"
                          />
                        )}

                        {focusedMembers.includes(item?._id) ? (
                          <>
                            <span
                              style={{
                                ...customStyles.overlay,
                                backdropFilter: "blur(2px)",
                              }}
                              className="row-start-1 z-0 row-end-6 col-start-1 flex flex-col"
                            ></span>
                            <div className="row-start-1 z-10 row-end-6 col-start-1 flex flex-col">
                              <span className="flex align-top justify-end px-4 py-3">
                                <XIcon
                                  className="transition duration-300 text-Neutral200 hover:text-Neutral000"
                                  height={16}
                                  width={16}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleMemberFocus(item?._id);
                                  }}
                                  src={CloseBtn}
                                  alt=""
                                />
                              </span>
                              <span className="flex-1 flex justify-center flex-col">
                                <span
                                  style={{ fontSize: "10px" }}
                                  className=" text-Neutral200 text-center font-normal"
                                >
                                  Permanently remove
                                </span>
                                <span
                                  style={{ fontSize: "12px" }}
                                  className=" text-Neutral100 text-center uppercase font-semibold"
                                >
                                  {item?.member?.user_name}
                                </span>
                              </span>
                              <span className="flex justify-center align-middle p-4">
                                <button
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleMemberDelete(item);
                                  }}
                                  style={{ fontSize: "10px" }}
                                  className="bg-Red600 hover:bg-Red400 transition rounded-md text-Neutral200 tracking-wide py-2 px-3 font-semibold"
                                >
                                  {isloding ? (
                                    <div className="animate-spin rounded-full h-3 w-3 border-b-2 border-Neutral900"></div>
                                  ) : (
                                    "Confirm Removal"
                                  )}
                                </button>
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <span
                              style={customStyles.overlay}
                              className="text-center flex justify-center items-center col-start-1 row-start-5 row-end-6 font-semibold uppercase text-Neutral300 tracking-wider text-[10px] transition duration-300 group-hover:opacity-0"
                            >
                              {item?.member?.user_name
                                ? item?.member?.user_name
                                : item?.user_name}
                            </span>
                            <span
                              style={{ backgroundColor: "rgba(0, 0, 0, 0.15)" }}
                              className="col-start-1 bg-Neutral000 row-start-1 row-end-6 opacity-0 group-hover:opacity-100 transition duration-300"
                            ></span>
                            {!item?.verified_at && (
                              
                                <button
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleResendInvite(item);
                                  }}
                                  style={{
                                    fontSize: "8px",
                                    paddingTop: "2px",
                                    paddingBottom: "2px",
                                  }}
                                  className="font-semibold transition z-10 row-start-5 row-end-6 col-start-1 px-2 cursor-pointer text-xs  text-Neutral100 bg-AccentRegular opacity-0 w-full tracking-wide uppercase group-hover:opacity-100 duration-300"
                                >
                                  {isResending ? 'Resending' : 'Resend Email Invite'}
                                </button>
                            )}
                            {!item?.verified_at && (
                              <span className="row-start-1 row-end-3 col-start-1 justify-start py-2 px-2 flex transition duration-300 group-hover:opacity-0">
                                <button
                                  className="text-Neutral100 bg-AccentRegular flex items-center justify-center text-xs rounded h-6 w-max px-3 focus-visible:outline-purple font-semibold"
                                  style={{
                                    fontSize: "8px",
                                    paddingTop: "2px",
                                    paddingBottom: "2px",
                                    maxHeight: "20px",
                                  }}
                                >
                                  INVITE PENDING
                                </button>
                              </span>
                            )}
                            {logedinUser?._id !== item?.member?._id && (
                              <span className="row-start-1 row-end-3 col-start-1 justify-end py-2 px-2 flex transition duration-300 opacity-0 group-hover:opacity-100">
                                <button
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleMemberFocus(item?._id);
                                  }}
                                  style={{
                                    fontSize: "8px",
                                    paddingTop: "2px",
                                    paddingBottom: "2px",
                                    maxHeight: "20px",
                                    backgroundColor: dark,
                                  }}
                                  className="font-semibold transition z-10 opacity-70 col-start-1 px-2 cursor-pointer text-xs rounded-md text-Neutral200 tracking-wide uppercase hover:opacity-100 duration-300"
                                >
                                  Remove
                                </button>
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => setModal(false)}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ExpandTeamsModal;
