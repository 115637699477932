import React from "react";
import clx from "classnames";

import UserIcon from "../../Icons/UserIcon";
import TeamMemberAvatar from "../avatars/TeamMemberAvatar";

const TEAM_AVATARS_SHOW_LIMIT = 9;

function Counter({ number = null }) {
  if (!number) {
    return null;
  }

  return (
    <li className="min-w-[28px] h-[28px] flex justify-center items-center text-[8px] leading-[120%] text-Neutral700 uppercase border-[0.5px] border-Neutral400 rounded-[50%] ml-1">
      +{number}
    </li>
  );
}

function TeamAvatarsList({
  users = [],
  className = "",
  limit = TEAM_AVATARS_SHOW_LIMIT,
}) {
  if (!users.length) {
    return (
      <div className={clx("flex justify-start items-center", className)}>
        <div className="bg-Neutral200 rounded-[50%] w-[28px] h-[28px] flex justify-center items-center mr-2">
          <UserIcon />
        </div>
        <p className="text-[10px] italic mb-0 text-Neutral700">
          No one has contributed to this project...
        </p>
      </div>
    );
  }

  if (!Array.isArray(users)) {
    return null;
  }

  const usersAvatars =
    users.length <= limit
      ? users.map((user, idx, arr) => (
          <TeamMemberAvatar
            user={user}
            key={user?._id ?? idx}
            size={28}
            cropped={idx !== arr.length - 1}
          />
        ))
      : users
          .slice(0, limit)
          .map((user, idx, arr) => (
            <TeamMemberAvatar
              user={user}
              key={user?._id ?? idx}
              size={28}
              cropped={idx !== arr.length - 1}
            />
          ))
          .concat([<Counter key="counter" number={users.length - limit} />]);

  return (
    <ul className={clx("flex flex-wrap list-none", className)}>
      {usersAvatars}
    </ul>
  );
}

export default TeamAvatarsList;
