import moment from "moment";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import SummaryPerson from "../../../assets/Images/meeting-schedule.svg";
import CheckMarkIcon from "../../Icons/CheckMarkIcon";
import { ReminderMeetingAction } from "../../../store/actions/meetingAction";
import { useState } from "react";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

function BookMeetingSummary({ bookingInfo, isModalOpen, setIsOpen }) {
  const dispatch = useDispatch();
  const [reminderButton, setReminderButton] = useState(
    "Send reminder 1 day before"
  );
  const closeModal = () => {
    setIsOpen(false);
  };

  const handleReminder = () => {
    dispatch(ReminderMeetingAction(bookingInfo?.id, setReminderButton));
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={customStyles}
      closeTimeoutMS={300}
    >
      <div className="p-8 bg-white rounded-lg shadow-lg">
        <h3 className="mb-5 text-xs font-semibold uppercase  text-Neutral700 ">
          Meeting Scheduled
        </h3>

        <div className="flex flex-col items-center gap-7 border-t border-Neutral200 pt-8">
          <img
            src={SummaryPerson}
            alt="Confirm meeting booking"
            className="object-cover"
          />
          <p className="inline-flex items-center px-2 py-1 space-x-1.5 text-sm text-center font-medium border border-AccentMediumLight rounded-2xl text-AccentDark">
            <span className="rounded-full bg-AccentRegular">
              <CheckMarkIcon width={16} color="white" />
            </span>
            <span>
              {moment(bookingInfo.time).format("HH:mm")},{" "}
              {moment(bookingInfo.date).format("DD MMM")},{" "}
              {bookingInfo.timeSlot?.replace(" Minutes", "min")}
            </span>
          </p>
          <button
            onClick={handleReminder}
            className={`px-10 py-4 text-sm font-bold transition duration-300 rounded text-Neutral100 ${
              reminderButton === "Reminder will be sent 1 day before"
                ? "bg-neutral-200 text-neutral-500 cursor-not-allowed opacity-50"
                : "bg-AccentRegular hover:bg-AccentMediumDark"
            }`}
            disabled={reminderButton === "Reminder will be sent 1 day before"}
          >
            {reminderButton}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default BookMeetingSummary;
