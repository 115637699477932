import React, { useEffect, useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import moment from "moment";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function DayGridView({ changeCalenderViewDate, calenderViewDate }) {
  const [days, setDays] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(calenderViewDate);

  function getDaysForCalendarMonth(date) {
    var firstDayOfMonth = moment(date).startOf("month");
    var firstDayOfCal = firstDayOfMonth.clone().startOf("week");
    var lastDayOfMonth = firstDayOfMonth.clone().endOf("month");
    var lastDayOfCal = lastDayOfMonth.clone().endOf("week");
    var temp = firstDayOfCal.clone();
    var days = [
      {
        date: temp.format("YYYY-MM-DD"),
        day: temp.format("DD"),
        isCurrentMonth: moment(firstDayOfCal).isSame(date, "month"),
        events: [],
      },
    ];
    while (temp.isBefore(lastDayOfCal) && days.length < 42) {
      temp.add(1, "day");
      days.push({
        date: temp.format("YYYY-MM-DD"),
        day: temp.format("DD"),
        events: [],
        isCurrentMonth: moment(temp).isSame(date, "month"),
      });
    }
    while (days.length < 42) {
      temp.add(1, "day");
      days.push({
        date: temp.format("YYYY-MM-DD"),
        day: temp.format("DD"),
        events: [],
        isCurrentMonth: moment(temp).isSame(date, "month"),
      });
    }
    return days;
  }

  useEffect(() => {
    setDays(getDaysForCalendarMonth(selectedMonth));
  }, [selectedMonth]);

  useEffect(() => {
    setSelectedMonth(calenderViewDate);
  }, [calenderViewDate]);

  const changeMiniCalanderMonth = (val) => {
    if (val === "next") {
      setSelectedMonth(
        moment(selectedMonth).add(1, "M").startOf("month").format()
      );
    } else {
      setSelectedMonth(
        moment(selectedMonth).subtract(1, "M").startOf("month").format()
      );
    }
  };

  return (
    <div className="flex flex-auto overflow-hidden bg-white">
      <div className="flex flex-auto flex-col overflow-auto">
        <div className="sticky top-0 z-10 grid flex-none grid-cols-7 bg-white text-xs text-Neutral900 shadow ring-1 ring-black ring-opacity-5 md:hidden">
          <button
            type="button"
            className="flex flex-col items-center pt-3 pb-1.5"
          >
            <span>W</span>
            {/* Default: "text-Neutral900", Selected: "bg-AccentRegular  text-white", Today (Not Selected): "text-AccentRegular ", Today (Selected): "bg-AccentRegular text-white" */}
            <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-xs font-semibold text-Neutral900">
              19
            </span>
          </button>
          <button
            type="button"
            className="flex flex-col items-center pt-3 pb-1.5"
          >
            <span>T</span>
            <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-xs font-semibold text-AccentRegular ">
              20
            </span>
          </button>
          <button
            type="button"
            className="flex flex-col items-center pt-3 pb-1.5"
          >
            <span>F</span>
            <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-xs font-semibold text-Neutral900">
              21
            </span>
          </button>
          <button
            type="button"
            className="flex flex-col items-center pt-3 pb-1.5"
          >
            <span>S</span>
            <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full bg-AccentRegular  text-xs font-semibold text-white">
              22
            </span>
          </button>
          <button
            type="button"
            className="flex flex-col items-center pt-3 pb-1.5"
          >
            <span>S</span>
            <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-xs font-semibold text-Neutral900">
              23
            </span>
          </button>
          <button
            type="button"
            className="flex flex-col items-center pt-3 pb-1.5"
          >
            <span>M</span>
            <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-xs font-semibold text-Neutral900">
              24
            </span>
          </button>
          <button
            type="button"
            className="flex flex-col items-center pt-3 pb-1.5"
          >
            <span>T</span>
            <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-xs font-semibold text-Neutral900">
              25
            </span>
          </button>
        </div>
        <div className="flex w-full flex-auto">
          <div className="w-14 flex-none bg-white ring-1 ring-Neutral400" />
          <div className="grid flex-auto grid-cols-1 grid-rows-1">
            {/* Horizontal lines */}
            <div
              className="col-start-1 col-end-2 row-start-1 grid divide-y divide-Neutral200"
              style={{
                gridTemplateRows: "repeat(48, minmax(3.5rem, 1fr))",
              }}
            >
              <div className="row-end-1 h-7"></div>
              <div className="cursor-pointer">
                <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                  12AM
                </div>
              </div>
              <div />
              <div className="cursor-pointer">
                <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                  1AM
                </div>
              </div>
              <div />
              <div className="cursor-pointer">
                <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                  2AM
                </div>
              </div>
              <div />
              <div className="cursor-pointer">
                <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                  3AM
                </div>
              </div>
              <div />
              <div className="cursor-pointer">
                <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                  4AM
                </div>
              </div>
              <div />
              <div className="cursor-pointer">
                <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                  5AM
                </div>
              </div>
              <div />
              <div className="cursor-pointer">
                <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                  6AM
                </div>
              </div>
              <div />
              <div className="cursor-pointer">
                <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                  7AM
                </div>
              </div>
              <div />
              <div className="cursor-pointer">
                <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                  8AM
                </div>
              </div>
              <div />
              <div className="cursor-pointer">
                <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                  9AM
                </div>
              </div>
              <div />
              <div className="cursor-pointer">
                <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                  10AM
                </div>
              </div>
              <div />
              <div className="cursor-pointer">
                <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                  11AM
                </div>
              </div>
              <div />
              <div className="cursor-pointer">
                <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                  12PM
                </div>
              </div>
              <div />
              <div className="cursor-pointer">
                <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                  1PM
                </div>
              </div>
              <div />
              <div className="cursor-pointer">
                <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                  2PM
                </div>
              </div>
              <div />
              <div className="cursor-pointer">
                <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                  3PM
                </div>
              </div>
              <div />
              <div className="cursor-pointer">
                <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                  4PM
                </div>
              </div>
              <div />
              <div className="cursor-pointer">
                <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                  5PM
                </div>
              </div>
              <div />
              <div className="cursor-pointer">
                <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                  6PM
                </div>
              </div>
              <div />
              <div className="cursor-pointer">
                <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                  7PM
                </div>
              </div>
              <div />
              <div className="cursor-pointer">
                <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                  8PM
                </div>
              </div>
              <div />
              <div className="cursor-pointer">
                <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                  9PM
                </div>
              </div>
              <div />
              <div className="cursor-pointer">
                <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                  10PM
                </div>
              </div>
              <div />
              <div className="cursor-pointer">
                <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                  11PM
                </div>
              </div>
              <div />
            </div>

            {/* Events */}
            <ol
              className="col-start-1 col-end-2 row-start-1 grid grid-cols-1"
              style={{
                gridTemplateRows: "1.75rem repeat(288, minmax(0, 1fr)) auto",
              }}
            >
              <li
                className="relative mt-px flex"
                style={{ gridRow: "74 / span 12" }}
              >
                <div className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-AccentLight p-2 text-xs leading-5 hover:bg-AccentMediumLight">
                  <p className="order-1 font-semibold text-Blue900">
                    Breakfast
                  </p>
                  <p className="text-AccentRegular group-hover:text-AccentDark">
                    <time dateTime="2022-01-22T06:00">6:00 AM</time>
                  </p>
                </div>
              </li>
              <li
                className="relative mt-px flex"
                style={{ gridRow: "92 / span 30" }}
              >
                <div className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-AccentLight p-2 text-xs leading-5 hover:bg-AccentMediumLight">
                  <p className="order-1 font-semibold text-Pink900 ">
                    Flight to Paris
                  </p>
                  <p className="order-1 text-AccentMediumDark group-hover:text-AccentMediumDark">
                    John F. Kennedy International Airport
                  </p>
                  <p className="text-AccentRegular group-hover:text-AccentDark">
                    <time dateTime="2022-01-22T07:30">7:30 AM</time>
                  </p>
                </div>
              </li>
              <li
                className="relative mt-px flex"
                style={{ gridRow: "134 / span 18" }}
              >
                <div className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-AccentLight p-2 text-xs leading-5 hover:bg-AccentMediumLight">
                  <p className="order-1 font-semibold text-Blue900">
                    Sightseeing
                  </p>
                  <p className="order-1 text-AccentMediumDark group-hover:text-AccentMediumDark">
                    Eiffel Tower
                  </p>
                  <p className="text-AccentRegular group-hover:text-AccentDark">
                    <time dateTime="2022-01-22T11:00">11:00 AM</time>
                  </p>
                </div>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div className="hidden w-1/2 max-w-md flex-none border-l border-Neutral200 py-10 px-8 md:block">
        <div className="flex items-center text-center text-Neutral900">
          <button
            type="button"
            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-Neutral700 hover:text-Neutral900"
            onClick={() => changeMiniCalanderMonth("prev")}
          >
            <span className="sr-only">Previous month</span>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <div className="flex-auto font-semibold">
            {moment(selectedMonth).format("MMMM YYYY")}
          </div>
          <button
            type="button"
            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-Neutral700 hover:text-Neutral900"
            onClick={() => changeMiniCalanderMonth("next")}
          >
            <span className="sr-only">Next month</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div className="mt-6 grid grid-cols-7 text-center text-xs leading-6 text-Neutral900">
          <div>S</div>
          <div>M</div>
          <div>T</div>
          <div>W</div>
          <div>T</div>
          <div>F</div>
          <div>S</div>
        </div>
        <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-Neutral300 text-xs shadow ring-1 ring-Neutral300">
          {days.map((day, dayIdx) => (
            <button
              key={day.date}
              type="button"
              className={classNames(
                "py-1 hover:bg-Neutral200 focus:z-10",
                day.isCurrentMonth ? "bg-white" : "bg-Neutral200",
                (moment(day.date).isSame(calenderViewDate, "day") ||
                  moment(day.date).isSame(new Date(), "day")) &&
                  "font-semibold",
                moment(day.date).isSame(calenderViewDate, "day") &&
                  "text-white",
                !moment(day.date).isSame(calenderViewDate, "day") &&
                  day.isCurrentMonth &&
                  !moment(day.date).isSame(new Date(), "day") &&
                  "text-Neutral900 cursor-pointer",
                !moment(day.date).isSame(calenderViewDate, "day") &&
                  !day.isCurrentMonth &&
                  !moment(day.date).isSame(new Date(), "day") &&
                  "text-Neutral700 cursor-not-allowed",
                moment(day.date).isSame(new Date(), "day") &&
                  !moment(day.date).isSame(calenderViewDate, "day") &&
                  "text-white",
                dayIdx === 0 && "rounded-tl-lg",
                dayIdx === 6 && "rounded-tr-lg",
                dayIdx === days.length - 7 && "rounded-bl-lg",
                dayIdx === days.length - 1 && "rounded-br-lg"
              )}
              onClick={() => {
                day.isCurrentMonth && changeCalenderViewDate(moment(day.date));
              }}
            >
              <time
                dateTime={day.date}
                className={classNames(
                  "mx-auto flex h-7 w-7 items-center justify-center rounded-full",
                  moment(day.date).isSame(calenderViewDate, "day") &&
                    moment(day.date).isSame(new Date(), "day") &&
                    "border-AccentRegular bg-AccentRegular text-white border",
                    moment(day.date).isSame(new Date(), "day") &&
                  !moment(day.date).isSame(calenderViewDate, "day") &&
                  "bg-AccentRegular leading-[28px]",
                  moment(day.date).isSame(calenderViewDate, "day") &&
                    !moment(day.date).isSame(new Date(), "day") &&
                    "border-AccentRegular border text-AccentRegular"
                )}
              >
                {day.date.split("-").pop().replace(/^0/, "")}
              </time>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DayGridView;
