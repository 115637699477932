import React from "react";
import MiscellaneousShapeArrow from "../../assets/Images/Miscellaneous-shape-arrow.svg";
import MiscellaneousShape1 from "../../assets/Images/Miscellaneous-shape1.svg";

function MiscNoDataContent() {
  return (
    <div className="absolute top-[50%] -translate-y-[50%] left-[50%] -translate-x-[50%] w-full max-w-[208px]">
      <div className="relative">
        <img
          className="absolute top-[11px] -left-[12px]"
          src={MiscellaneousShape1}
          alt=""
        />
        <img
          className="absolute -top-[110px] left-[35%]"
          src={MiscellaneousShapeArrow}
          alt=""
        />
        <h5 className="text-sm font-normal font-kalam text-center pt-4">
          Drop it here when you don’t know where else to put it...
        </h5>
      </div>
    </div>
  );
}

export default MiscNoDataContent;
