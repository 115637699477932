import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetUserAction } from "../store/actions/authAction";
import { Navigate } from "react-router-dom";
import PageLoader from "../components/Loaders/PageLoader";

const ProtectedRoute = ({ children }) => {
    const dispatch = useDispatch();
    const have_token = localStorage.getItem("jwt_access_token");
    const auth = useSelector((state) => state.auth);

    useEffect(() => {
        if (have_token && !auth.user) {
            dispatch(GetUserAction());
        }
    }, [dispatch]);

    if (!have_token) {
        return <Navigate to="/signin" replace />;
    }

    if (auth.userLoading && !auth.user) {
        return <PageLoader />;
    }

    return !auth.userLoading || have_token ? children : <PageLoader />;
};

export default ProtectedRoute;