import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useRef } from "react";
import DuplicateIcon from "../../../assets/Images/Duplicate.svg";
import EditeIcon from "../../../assets/Images/Edit-gray.svg";
import TrashIcon from "../../../assets/Images/trash-gray.svg";
import TagList from "./components/TagList";

const TemplateInfo = ({
  title,
  tags,
  color,
  compact,
  handleDelete,
  handleDuplicate,
  handleEditeTemp,
  handleEdit,
  handleUse,
  setIsLoaded,
  isLoaded,
  id,
  type,
}) => {
  const templateRef = useRef(null);
  return (
    <div className="bg-Neutral00 border-[1px] border-Neutral300 p-5 rounded-lg w-[318px] bg-Neutral000">
      <div className="flex items-center justify-between">
        <h6 className="uppercase text-sm truncate font-medium tracking-[0.04em]">
          {title}
        </h6>

        {!compact && (
          <div className="ml-4">
            <Menu as="div" className="relative inline-block text-left">
              <Menu.Button
                ref={templateRef}
                className="focus-visible:outline-none popup-btn relative w-full"
              >
                <div className="">
                  <button
                    type="button"
                    className="w-4 h-4 text-center client-btn-hover cursor-pointer"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.9287 12.4985C12.2049 12.4985 12.4287 12.2747 12.4287 11.9985C12.4287 11.7224 12.2049 11.4985 11.9287 11.4985C11.6526 11.4985 11.4287 11.7224 11.4287 11.9985C11.4287 12.2747 11.6526 12.4985 11.9287 12.4985Z"
                        fill="#9C9CA6"
                        stroke="#9C9CA6"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.9287 8.99878C12.2049 8.99878 12.4287 8.77492 12.4287 8.49878C12.4287 8.22264 12.2049 7.99878 11.9287 7.99878C11.6526 7.99878 11.4287 8.22264 11.4287 8.49878C11.4287 8.77492 11.6526 8.99878 11.9287 8.99878Z"
                        fill="#9C9CA6"
                        stroke="#9C9CA6"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.9287 15.9985C12.2049 15.9985 12.4287 15.7747 12.4287 15.4985C12.4287 15.2224 12.2049 14.9985 11.9287 14.9985C11.6526 14.9985 11.4287 15.2224 11.4287 15.4985C11.4287 15.7747 11.6526 15.9985 11.9287 15.9985Z"
                        fill="#9C9CA6"
                        stroke="#9C9CA6"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right z-20 absolute left-[10px] w-[108px] rounded-lg client-edite-view-shadow bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none top-0 cursor-pointer transition-all duration-300 ease-in-out flex flex-col">
                  <p
                    className="flex px-4 hover:bg-Neutral200 py-2"
                    onClick={(e) => {
                      handleDuplicate();
                      setTimeout(() => {
                        templateRef?.current?.click();
                        e.stopPropagation();
                      }, 1000);
                    }}
                  >
                    <img src={DuplicateIcon} alt="Duplicate" />

                    <span className="px-2 text-xs text-Neutral900">
                      Duplicate
                    </span>
                  </p>
                  <p
                    className="flex px-4 hover:bg-Neutral200 py-2"
                    onClick={() => handleEdit()}
                  >
                    <img src={EditeIcon} alt="Duplicate" />

                    <span className="px-2 text-xs text-Neutral900">Edit</span>
                  </p>
                  {isLoaded?.isLoading &&
                    isLoaded?.id === id &&
                    isLoaded?.type === type ? (
                    <div className="flex px-4 hover:bg-Neutral200 py-2">
                      <div className="w-4 h-4 mr-2">
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-Neutral900"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="red"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="red"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </div>
                      <span className="text-xs text-red-500">Deleting</span>
                    </div>
                  ) : (
                    <Menu as="div" className="relative">
                      <Menu.Button className="flex px-4 hover:bg-Neutral200 py-2 w-[100%]">
                        <img src={TrashIcon} alt="Delete" />
                        <span className="px-2 text-xs text-Neutral900">
                          Delete
                        </span>
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="focus:outline-none min-w-[384px] absolute right-[-80px] top-[-60px] w-36 origin-top-right divide-y divide-Neutral200 overflow-hidden bg-white ring-1 ring-black ring-opacity-5 drop-shadow-Timetracking">
                          <div className="pt-6 pb-8 px-8">
                            <Menu.Item>
                              {({ close }) => (
                                <div>
                                  <h5 className="text-Neutral700 text-xs font-semibold pb-2 border-b border-Neutral200">
                                    Are you sure?
                                  </h5>
                                  <button
                                    onClick={(e) => {
                                      setIsLoaded({
                                        isLoading: true,
                                        id,
                                        type,
                                      });
                                      handleDelete();
                                      setTimeout(() => {
                                        templateRef?.current?.click();
                                        e.stopPropagation();
                                      }, 1000);
                                    }}
                                    className="text-Neutral000 w-full pl-2 pr-4 py-4 text-center text-sm cursor-pointer font-bold transition duration-300 bg-Red400 hover:bg-Red600 rounded-[4px] mt-8"
                                  >
                                    Yes, Delete Template
                                  </button>

                                  <button
                                    onClick={close}
                                    className="text-Neutral800 hover:text-Neutral900 w-full pl-2 pr-4 py-4 text-center text-sm cursor-pointer font-bold transition duration-300 bg-Neutral300 hover:bg-Neutral400 rounded-[4px] mt-5"
                                  >
                                    No, I Still Want This Template
                                  </button>
                                </div>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  )}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        )}
      </div>

      <TagList {...{ color, tags }} />

      {!compact && (
        <div className="mt-[26px] flex gap-x-2">
          <button
            onClick={() => handleUse()}
            className={`w-1/2 border-[1px] border-${color}300 text-${color}500 uppercase font-bold flex items-center rounded-md py-3 text-[10px] justify-center tracking-[0.04em]`}
          >
            <svg
              width="15"
              height="17"
              viewBox="0 0 15 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.15741 0.999512L0.75 9.57094H7.41667L6.67593 15.2852L14.0833 6.7138H7.41667L8.15741 0.999512Z"
                // stroke="#5281E0"
                className={`stroke-${color}500`}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span className="ml-2.5">Use Template</span>
          </button>

          {type !== 'Project' && <button
            className="w-1/2 border-[1px] border-Neutral300 text-Neutral700 uppercase font-bold flex items-center rounded-md py-2 text-[10px] justify-center tracking-[0.04em]"
            onClick={() => handleEditeTemp()}
          >
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.2144 5.6543H5.57541C5.22389 5.6543 4.88677 5.79394 4.6382 6.0425C4.38964 6.29107 4.25 6.62819 4.25 6.97971V16.2576C4.25 16.6091 4.38964 16.9463 4.6382 17.1948C4.88677 17.4434 5.22389 17.583 5.57541 17.583H14.8533C15.2048 17.583 15.542 17.4434 15.7905 17.1948C16.0391 16.9463 16.1787 16.6091 16.1787 16.2576V11.6187"
                stroke="#757580"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15.1835 4.66127C15.4472 4.39762 15.8047 4.24951 16.1776 4.24951C16.5504 4.24951 16.908 4.39762 17.1716 4.66127C17.4353 4.92491 17.5834 5.28248 17.5834 5.65533C17.5834 6.02817 17.4353 6.38575 17.1716 6.64939L10.8759 12.9451L8.2251 13.6078L8.8878 10.957L15.1835 4.66127Z"
                stroke="#757580"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span className="ml-[10.9px]">Edit</span>
          </button>}
        </div>
      )}
    </div>
  );
};

export default TemplateInfo;
