import { useEffect, useState } from "react";
import { cn } from "../../utils";

const CompleteIcon = ({ size = 24, className = "" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M17.5 9L10.2812 15.75L7 12.6818"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

function FormDropdown({ children, ...props }) {
  const {
    sectionId = "",
    stepCount,
    title,
    complete = false,
    focused = false,
    setActiveFocusedTab,
    updateStep,
    isSubmitting,
    currentStep,
    setCurrentStep,
  } = props;
  const [isOpen, setIsOpen] = useState(() => {
    if (stepCount === 1) {
      setActiveFocusedTab(sectionId);
      return true;
    } else {
      return false;
    }
  });

  useEffect(() => {
    if (focused) {
      setIsOpen(true);
      setCurrentStep(stepCount); // Update current step when focused
    } else {
      setIsOpen(false);
    }
  }, [focused, setCurrentStep, stepCount]);

  const handleDropdownClick = () => {
    // If already focused and open, allow toggling it closed
    if (focused && isOpen) {
      setIsOpen(false);
      // setCurrentStep((prev) => prev + 1);
      setActiveFocusedTab(""); // Remove active focus when closing
    } else {
      // Otherwise, set it as the active focused tab and open it
      setIsOpen(true);
      setActiveFocusedTab(sectionId);
      setCurrentStep(stepCount); // Update the current step to this step count
    }
  };

  return (
    <div id={sectionId} className={cn("flex flex-col gap-2")}>
      <div
        className={cn(
          "flex items-center gap-4 px-5 py-5 bg-white rounded-lg shadow-md cursor-pointer shadow-neutral-200"
        )}
        onClick={handleDropdownClick}
      >
        {!complete ? (
          <span className="grid w-6 text-xs font-bold text-center rounded-full bg-AccentMediumLight text-AccentRegular aspect-square place-items-center">
            {stepCount}
          </span>
        ) : (
          <CompleteIcon size={20} className="bg-green-500 rounded-full" />
        )}

        <h4 className="flex-grow text-sm">{title}</h4>
        <DropdownIcon
          size={20}
          className={`text-AccentRegular transition-all ease-in-out ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </div>

      {isOpen && (
        <div
          className={cn(
            "px-5 py-5 bg-white rounded-lg shadow-md shadow-neutral-200"
          )}
        >
          {children(props)}
        </div>
      )}
    </div>
  );
}

export default FormDropdown;

const DropdownIcon = ({ size = 24, className = "" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 9L12 15L6 9"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
