import axios from "axios";
import { toast } from "react-toastify";

export const GetNotifications = ({ page = 1, perPage = 10 }) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `api/v1/notification/get-notifications?page=${page}&limit=${perPage}`
      );
      dispatch({
        type: "SET_NOTIFICATION_PAGE",
        payload: { isLoading: false, is_called: true, data: data.data },
      });
      return dispatch({
        type: "SET_NOTIFICATION_LIST",
        payload: data.data.data,
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "SET_NOTIFICATION_LIST_ERROR",
        payload: error,
      });
    }
  };
};

export const MarkReadNotifications = (notificationsList) => {
  return async (dispatch) => {
    try {
      await axios.post(
        `api/v1/notification/mark-read-notifications`
      );
      const abc = notificationsList.map(e => {return {...e, is_read: true}})
      dispatch({
        type: "RESET_NOTIFICATION_LIST",
        payload: abc,
      });
      return dispatch({
        type: "MARK_READ_NOTIFICATION_LIST_SUCCESS",
        payload: [],
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "MARK_READ_NOTIFICATION_LIST_ERROR",
        payload: error,
      });
    }
  };
};

export const DeleteNotification = (id, notificationsList) => {
  return async (dispatch) => {
    try {
      await axios.post(
        `api/v1/notification/delete-notification`,
        {id}
      );
      const abc = notificationsList.filter(e => e._id !== id)
      dispatch({
        type: "RESET_NOTIFICATION_LIST",
        payload: abc,
      });
      return dispatch({
        type: "MARK_READ_NOTIFICATION_LIST_SUCCESS",
        payload: [],
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "MARK_READ_NOTIFICATION_LIST_ERROR",
        payload: error,
      });
    }
  };
};
