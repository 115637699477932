import React from "react";
import clx from "classnames";

const badgeColors = [
  { text: "Blue500", bg: "Blue100" },
  { text: "AccentRegular", bg: "AccentLight" },
  { text: "Green500", bg: "Green100" },
  { text: "Yellow800", bg: "Yellow100" },
  { text: "Orange500", bg: "Orange100" },
  { text: "Pink500", bg: "Pink100" },
  { text: "Red500", bg: "Red100" },
  { text: "Neutral700", bg: "Neutral100" },
  { text: "Neutral800", bg: "Neutral300" },
];

const defaultIdx = badgeColors.length - 1;

function ProjectCounterBadge({ number = null, className = "" }) {
  if (!number) {
    return null;
  }
  const { text, bg } =
    number > 0 && number < 10
      ? badgeColors[number - 1]
      : badgeColors[defaultIdx];

  return (
    //
    <span
      className={clx(
        "inline-block w-[24px] h-[24px] flex align-center justify-center px-[7.5px] py-[5px] text-sm leading-none rounded-[50%] font-bold",
        `text-${text} bg-${bg}`,
        className
      )}
    >
      {number}
    </span>
  );
}

export default ProjectCounterBadge;
