import moment from "moment";

const months = moment.months();

const prepareTaskDate = (str = "") => {
  try {
    if (!str) {
      return null;
    }

    const date = new Date(str);
    const month = months[date.getMonth()];
    return date.getDate() + " " + month.slice(0, 4);
  } catch (error) {
    return null;
  }
};

const prepareTrackingDate = (str = "") => {
  try {
    if (!str) {
      return null;
    }

    const date = new Date(str);
    const month = months[date.getMonth()].slice(0, 3);
    const year = date.getFullYear();

    return `${month} ${date.getDate()}, ${year}`;
  } catch (error) {
    return null;
  }
};

export { prepareTaskDate, prepareTrackingDate };
