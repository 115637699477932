const categoryStyles = {
  text: {
    background: "bg-gray-100", // Tailwind class for #F5F5F7 or the closest color
    text: "text-gray-600", // Tailwind class for #5A5A66 or the closest color
  },
  image: {
    background: "bg-gray-200", // Tailwind class for #DFDFE5 or the closest color
    text: "text-gray-900", // Tailwind class for #292933 or the closest color
  },
  video_audio: {
    background: "bg-gray-700", // Tailwind class for #9C9CA6 or the closest color
    text: "text-white", // Tailwind class for #FFFFFF
  },
  design: {
    background: "bg-gray-600", // Tailwind class for #5A5A66 or the closest color
    text: "text-white", // Tailwind class for #FFFFFF
  },
};

const getCategoryFromType = (type) => {
  switch (type) {
    case "pdf":
    case "txt":
    case "csv":
    case "md":
    case "json":
      return "text";
    case "png":
    case "jpeg":
    case "jpg":
    case "gif":
    case "raw":
    case "webp":
    case "svg":
      return "image";
    case "mp4":
    case "mp3":
    case "mov":
    case "aac":
    case "mkv":
    case "wav":
    case "avi":
    case "webm":
    case "flac":
      return "video_audio";
    case "psd":
    case "ai":
    case "sketch":
    case "xd":
    case "figma":
      return "design";
    default:
      return "text"; // Default category
  }
};

const getStyleForCategory = (type) => {
  return categoryStyles[type] || categoryStyles["text"]; // Default to text style
};

const getExtension = (filename) => {
  if (!filename) return null;
  return filename.split(".").pop().toLowerCase();
};

const isImage = (filename) => {
  if (!filename) return false;
  return ["png", "jpeg", "gif", "raw", "webp"].includes(getExtension(filename));
};

export {
  categoryStyles,
  getCategoryFromType,
  getStyleForCategory,
  getExtension,
  isImage,
};
