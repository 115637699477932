import axios from "axios";
import { toast } from "react-toastify";

export const CreateTaskCommentActions = (
  value,
  project_id,
  setLoading,
  setComment,
  EditorState,
  setAttachments,
  user_type = null
) => {
  return async (dispatch) => {
    try {
      let headers = {
        "Content-Type": "multipart/form-data",
      };

      if (user_type === 'client_user') {
        headers["user_type"] = 'client_user';
      }
      const { data } = await axios.post(
        "api/v1/task-comment/create-task-comment",
        value,
        { headers }
      );
      setLoading(false);
      setComment(EditorState.createEmpty());
      setAttachments([]);
      return dispatch({
        type: "CREATE_TASK_COMMENT_SUCCESS",
        payload: { data: data.data, project: project_id },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setLoading(false);
        return dispatch({
          type: "CREATE_TASK_COMMENT_ERROR",
          payload: error,
        });
      } else {
        setLoading(false);
        return dispatch({
          type: "CREATE_TASK_COMMENT_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const UpdateTaskCommentActions = (
  value,
  project_id,
  setIsEdit,
  setIsLoading,
  user_type = null
) => {
  return async (dispatch) => {
    try {
      let headers = {
        "Content-Type": "multipart/form-data",
      };

      if (user_type === 'client_user') {
        headers["user_type"] = 'client_user';
      }

      const { data } = await axios.put(
        "api/v1/task-comment/update-task-comment",
        value,
        { headers }
      );
      setIsEdit(false);
      setIsLoading(false);
      return dispatch({
        type: "UPDATE_TASK_COMMENT_SUCCESS",
        payload: { data: data.data, project: project_id },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setIsLoading(false);
        return dispatch({
          type: "UPDATE_TASK_COMMENT_ERROR",
          payload: error,
        });
      } else {
        setIsLoading(false);
        return dispatch({
          type: "UPDATE_TASK_COMMENT_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const DeleteTaskCommentAction = (id, task_id, project, user_type = null) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `api/v1/task-comment/delete-task-comment?id=${id}&task_id=${task_id}`,
        { user_type: user_type }
      );
      return dispatch({
        type: "DELETE_TASK_COMMENT_SUCCESS",
        payload: { id, data: data.data, project },
      });
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "DELETE_TASK_COMMENT_ERROR",
          payload: { error },
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "DELETE_TASK_COMMENT_ERROR",
          payload: { error },
        });
      }
    }
  };
};
