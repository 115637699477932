import React from "react";
import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  UpdateTaskActions,
  DeleteTaskAction,
  CreateTaskActions,
} from "../../../store/actions/tasksAction";
import ThreeDotsSmall from "../../../assets/Images/More-24.svg";
import StatusPills from "../../../assets/Images/status-pills.svg";
import { useParams } from "react-router-dom";

const TasksBox = (props) => {
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const tagList = [
    { id: 0, img: StatusPills, value: null },
    {
      id: 1,
      title: "Low",
      value: "low",
      bg: "bg-Blue100",
      text: "text-Blue900",
    },
    {
      id: 2,
      title: "Medium",
      value: "medium",
      bg: "bg-Green100",
      text: "text-Green900",
    },
    {
      id: 3,
      title: "Urgent",
      value: "urgent",
      bg: "bg-Orange100",
      text: "text-Orange900",
    },
  ];

  const { index, name, item, generalName = "general" } = props;

  const [task, setTask] = useState(item);
  const [taskTag, setTaskTag] = useState(tagList[0]);
  const [issliderHover, setIssliderHover] = useState(false);
  const [issliderFocus, setIssliderFocus] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [moreTogel, setMoreTogel] = useState(false);

  useEffect(() => {
    if (item?.tag) {
      const tempTag = tagList.filter((e) => e.value === item?.tag);
      if (tempTag && tempTag.length) {
        setTaskTag(tempTag[0]);
      }
    }
    setTask(item);
  }, [item]);

  const handleChangeTitle = (event) => {
    setTask({
      ...task,
      title: event.target.value,
    });
  };

  const handleDeleteTask = () => {
    setIsDeleting(true);
    dispatch(DeleteTaskAction(task?._id, task?.status, setIsDeleting));
  };

  const handleSubmit = () => {
    if (isDataSubmitable() && !task?.new) {
      dispatch(UpdateTaskActions({ ...task, id: task?._id }));
    }
    if (isDataSubmitable() && task?.new) {
      dispatch(CreateTaskActions({ ...task, client_id: clientId }));
    }
  };

  const handleChangeTag = async (tag) => {
    dispatch(
      UpdateTaskActions({
        ...task,
        id: task?._id,
        tag: tag?.value ? tag?.value : null,
      })
    );
    setTaskTag(tag);
  };

  const isDataSubmitable = () => {
    let result = false;
    if (item.title !== task.title || item.tag !== taskTag.value) {
      result = true;
    }
    return result;
  };
  const boxRef = useRef(null);

  function handleClickOutside(event) {
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      setIssliderFocus(false);
      setMoreTogel(false);
    }
  }
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [boxRef]);

  return (
    <>
      <div id={`main-${name}-${index}`} className="tasklists-main" ref={boxRef}>
        <div
          className={`border border-Neutral300 rounded-lg mb-2 bg-Neutral000 duration-300 transition focus:outline-none ${
            issliderHover === task._id
              ? "tasklists-hover"
              : "hover:tasklists-hover"
          } ${
            issliderFocus ? "tasklists-focus drop-shadow-Purpleboxshadow " : ""
          }`}
          onMouseEnter={() => setIssliderHover(task._id)}
          onMouseLeave={() => setIssliderHover(false)}
        >
          <div
            className="flex justify-between items-start py-3 pl-4 pr-1 relative"
            onClick={() => setIssliderFocus(true)}
          >
            <textarea
              name="text"
              id={`${generalName}-${name}-${index}`}
              placeholder="Add Task"
              onChange={(event) => handleChangeTitle(event)}
              className=" text-Neutral800 text-sm pr-[76px] content 5xl:max-w-[352px] w-full focus-visible:outline-none resize-none "
              value={task.title}
              onBlur={() => handleSubmit()}
            />
            <div className="flex items-center transition duration-300 absolute right-1">
              <span
                className={`text-[8px] 5xl:text-xs py-1 px-2 font-semibold ${taskTag?.text} ${taskTag?.bg} rounded-2xl leading-tight uppercase`}
              >
                {" "}
                {taskTag?.title}
              </span>
              <img
                className="h-fit cursor-pointer"
                src={ThreeDotsSmall}
                alt=""
                onClick={() => {
                  setMoreTogel(!moreTogel);
                  // setStatusButton(`${name}-${index}`);
                  // handleToggel(`${name}-${index}`);
                }}
              />
            </div>
          </div>
          <div
            className={`flex justify-between w-full items-center border-t border-Neutral300 bg-Neutral100 rounded-b-lg ${
              !moreTogel
                ? "opacity-0 w-0 h-0 invisible"
                : "opacity-100 w-fit pt-3 px-5 pb-4"
            }`}
          >
            <div className="5xl:max-w-[180px] max-w-[142px] w-full lg:pr-0 pr-2">
              <p className="text-Neutral700 text-[8px] 5xl:text-xs mb-1">
                Tags
              </p>
              <div className="flex flex-wrap ">
                {tagList.map((status) => (
                  <span
                    key={status.id}
                    onClick={() => {
                      handleChangeTag(status);
                    }}
                    className={`text-[8px] 5xl:text-xs 5xl:min-w-[40px] min-w-[32px] w-full max-w-fit flex items-center 5xl:min-h-[24px] min-h-[18px] mr-2 my-1 font-semibold rounded-2xl leading-tight cursor-pointer ${status.text} ${status.bg} `}
                  >
                    <img className="w-full" src={status.img} alt="" />
                    {status.title ? (
                      <button className="py-1 px-2 uppercase">
                        {status.title}
                      </button>
                    ) : (
                      ""
                    )}
                  </span>
                ))}
                {/* <span className="text-[8px] cursor-pointer 5xl:text-xs py-1 my-1 px-2 flex items-center font-semibold text-AccentRegular border-dashed border border-Neutral400 rounded-2xl leading-tight 5xl:h-[24px] h-[18px] uppercase mt-1">ADD TAG </span> */}
              </div>
            </div>
            <div className="pl-4 h-10 border-l border-Neutral300 flex items-center">
              {!task?.new && (
                <button
                  onClick={() => handleDeleteTask()}
                  disabled={isDeleting}
                  type="button"
                  className="cursor-pointer inlinr-block text-[10px] 5xl:text-sm font-semibold py-2 px-3 flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-Red400 outline-Red100 bg-transparent text-Red400 transition duration-300 ease-in-out"
                >
                  {!isDeleting ? (
                    "Delete Task"
                  ) : (
                    <span className="w-100">
                      <svg
                        className="animate-spin m-auto h-5 w-5 text-Red400"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </span>
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TasksBox;
