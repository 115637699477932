const RedirectIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3333 8.55588V11.8892C11.3333 12.1839 11.2163 12.4665 11.0079 12.6749C10.7995 12.8833 10.5169 13.0003 10.2222 13.0003H4.11111C3.81643 13.0003 3.53381 12.8833 3.32544 12.6749C3.11706 12.4665 3 12.1839 3 11.8892V5.7781C3 5.48342 3.11706 5.2008 3.32544 4.99243C3.53381 4.78406 3.81643 4.66699 4.11111 4.66699H7.44444"
        stroke="#5A5A66"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.66663 3H13V6.33333"
        stroke="#5A5A66"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.88892 9.11111L13 3"
        stroke="#5A5A66"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default RedirectIcon;
