import React from "react";

function TasksIcon({
  color = "Neutral800",
  width = 24,
  className = "text-[#5A5A66]",
}) {
  return (
    <svg
      width={width}
      height={width}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7778 4L5 13.6H12L11.2222 20L19 10.4H12L12.7778 4Z"
        stroke="#5A5A66"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default TasksIcon;
