import React, { useRef, forwardRef } from "react";
import Modal from "react-modal";
import CloseBtn from "../../assets/Images/close-btn.svg";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  useField,
  useFormikContext,
} from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { customStyles } from "../../utils/modalStyles";
import { UpdateInvoiceTemplate } from "../../store/actions/templateAction";
import { updateInvoice } from "../../store/actions/invoicesAction";
import { RecurringIcon, StandardIcon } from "./Templates/NewInvoiceModal";
import DatePicker from "react-datepicker";
import moment from "moment";

function EditInvoiceAndInvoiceTemplateModal({
  isOpen,
  setIsOpen,
  editData,
  isCreateInvoice = false,
  getInvoiceData = () => {},
}) {
  const editTemplateSchema = Yup.object().shape({
    billingType: Yup.string().required("Billing Type Required"),
    interval: Yup.string()
      .when("billingType", {
        is: (val) => val === "recurring",
        then: Yup.string().required("Interval Required"),
      })
      .nullable(),
    repeats: Yup.number()
      .when("billingType", {
        is: (val) => val === "recurring",
        then: Yup.number().required("Repeats Required"),
      })
      .nullable(),
    startDate: Yup.date()
      .when("billingType", {
        is: (val) => val === "recurring",
        then: Yup.date().required("Start Date Required"),
      })
      .nullable(),
    endDate: Yup.date()
      .when(["billingType", "interval"], {
        is: (billingType, interval) =>
          billingType === "recurring" && interval === "indefinitely",
        then: Yup.date().required("End Date Required"),
      })
      .nullable(),
  });

  const dispatch = useDispatch();
  const logedinUser = useSelector((state) => state.auth.user);
  const userDateFormat = logedinUser?.date_format;

  const DateInput = forwardRef(({ value, onClick }, ref) => (
    <div className="relative">
      <input
        type="text"
        className="border border-Neutral300 rounded-lg px-4 py-2 text-sm text-Neutral900 w-full"
        value={!value ? "Select date" : moment(value).format(userDateFormat)}
        onClick={onClick}
        readOnly
        ref={ref}
      />
    </div>
  ));

  const FormikDatePicker = ({ ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);

    return (
      <DatePicker
        {...field}
        {...props}
        customInput={
          <DateInput
            value={(field.value && new Date(field.value).toISOString()) || null}
          />
        }
        selected={(field.value && new Date(field.value)) || null}
        onChange={(val) => {
          setFieldValue(field.name, val);
        }}
      />
    );
  };

  const submitButtonRef = useRef();
  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="New Template Modal"
      >
        <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-16 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="bg-white px-6 xs:px-12 pt-8 pb-10 rounded-[8px]">
            <div>
              <div className="text-left">
                <h3
                  className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-8 uppercase"
                  id="modal-title"
                >
                  Edit Invoice {isCreateInvoice ? "" : "Template"}
                </h3>
                <Formik
                  initialValues={editData}
                  enableReinitialize
                  validationSchema={editTemplateSchema}
                  onSubmit={(values, { setSubmitting, setErrors }) => {
                    let templateData;
                    if (values.billingType === "standard") {
                      templateData = {
                        billingType: values.billingType,
                        interval: "",
                        repeats: "",
                        startDate: "",
                      };
                    }
                    if (values.billingType === "recurring") {
                      templateData = {
                        billingType: values.billingType,
                        interval: values.interval,
                        repeats: values.repeats,
                        startDate: values.startDate,
                      };
                    }
                    if (
                      values.billingType === "recurring" &&
                      values.interval === "indefinitely"
                    ) {
                      templateData = {
                        endDate: values.endDate,
                      };
                    }
                    if (isCreateInvoice) {
                      dispatch(
                        updateInvoice(
                          editData?._id,
                          {
                            ...templateData,
                            client: values?.client?._id || values?.client,
                          },
                          false,
                          setErrors,
                          setSubmitting
                        )
                      );
                    } else {
                      dispatch(
                        UpdateInvoiceTemplate(
                          {
                            content: templateData,
                            invoiceId: editData?._id,
                            type: "Invoice",
                          },
                          setSubmitting,
                          false,
                          false,
                          false,
                          getInvoiceData
                        )
                      );
                    }

                    setTimeout(() => {
                      setIsOpen(false);
                    }, 1000);
                  }}
                >
                  {({ values, handleSubmit, isSubmitting, setFieldValue }) => {
                    return (
                      <>
                        {/* {values?.billingType === "recurring" && (
                          <span
                            className="absolute top-0 right-12 text-Neutral900 font-semibold text-xs flex items-center gap-2 mt-4 ml-4 cursor-pointer"
                            onClick={() => setFieldValue("billingType", "")}
                          >
                            <br />
                            Back
                          </span>
                        )} */}
                        <Form onSubmit={handleSubmit}>
                          <div className="flex flex-col gap-4">
                            {values?.billingType !== "recurring" && (
                              <>
                                <button
                                  className="flex items-center gap-4 pb-4 rounded-lg border p-4 cursor-pointer text-left hover:bg-Neutral200"
                                  type="button"
                                  onClick={async (e) => {
                                    e.preventDefault();
                                    await setFieldValue(
                                      "billingType",
                                      "standard"
                                    );

                                    submitButtonRef.current.click();
                                  }}
                                >
                                  <div className="flex flex-col gap-1">
                                    <StandardIcon />
                                  </div>
                                  <div className="flex flex-col gap-1">
                                    <div className="text-sm text-Neutral900 uppercase font-semibold">
                                      Standard
                                    </div>
                                    <div className="text-xs font-normal text-Neutral800">
                                      Send a once-off invoice.
                                    </div>
                                  </div>
                                </button>
                                <button
                                  className="flex justify-between items-center gap-4 pb-4 rounded-lg border p-4 cursor-pointer text-left hover:bg-Neutral200"
                                  type="button"
                                  onClick={() =>
                                    setFieldValue("billingType", "recurring")
                                  }
                                >
                                  <div className="flex flex-col gap-1">
                                    <RecurringIcon />
                                  </div>
                                  <div className="flex flex-col gap-1">
                                    <div className="text-sm text-Neutral900 uppercase font-semibold">
                                      Recurring
                                    </div>
                                    <div className="text-xs font-normal text-Neutral800">
                                      Create a recurring invoice (monthly /
                                      bi-monthly / annual).
                                    </div>
                                  </div>
                                </button>
                              </>
                            )}
                            {values?.billingType === "recurring" && (
                              <div className="flex flex-wrap gap-4">
                                <div className="flex justify-between w-full gap-4 flex-col sm:flex-row">
                                  <div className="flex flex-col gap-1 w-full sm:w-1/2">
                                    <label
                                      htmlFor="interval"
                                      className="text-sm text-Neutral900 font-semibold"
                                    >
                                      Billing Interval
                                    </label>
                                    <Field
                                      as="select"
                                      name="interval"
                                      className="border border-Neutral300 rounded-lg px-4 py-2 text-sm text-Neutral900"
                                    >
                                      <option value="weekly">Weekly</option>
                                      <option value="bimonthly">
                                        Bi-Monthly
                                      </option>
                                      <option value="monthly">Monthly</option>
                                      <option value="yearly">Yearly</option>
                                      <option value="indefinitely">
                                        Indefinitely
                                      </option>
                                    </Field>
                                    <ErrorMessage name="interval">
                                      {(msg) => (
                                        <div className="text-red-500 text-xs">
                                          {msg}
                                        </div>
                                      )}
                                    </ErrorMessage>
                                  </div>
                                  <div className="flex flex-col gap-1 w-full sm:w-1/2">
                                    <label
                                      htmlFor="repeats"
                                      className="text-sm text-Neutral900 font-semibold"
                                    >
                                      Repetitions
                                    </label>
                                    <Field
                                      type="number"
                                      name="repeats"
                                      className="border border-Neutral300 rounded-lg px-4 py-2 text-sm text-Neutral900"
                                      min="1"
                                      max={
                                        values.interval !== "indefinitely" &&
                                        (values?.interval === "weekly"
                                          ? 52
                                          : values?.interval === "bimonthly"
                                          ? 6
                                          : values?.interval === "monthly"
                                          ? 12
                                          : 1)
                                      }
                                    />
                                    <ErrorMessage name="repeats">
                                      {(msg) => (
                                        <div className="text-red-500 text-xs">
                                          {msg}
                                        </div>
                                      )}
                                    </ErrorMessage>
                                  </div>
                                </div>
                                <div className="flex justify-between w-full gap-4 flex-col sm:flex-row">
                                  <div className="flex flex-col gap-1 w-full sm:w-1/2">
                                    <label
                                      className="text-sm text-Neutral900 font-semibold"
                                      htmlFor="startDate"
                                    >
                                      Start Date
                                    </label>
                                    <FormikDatePicker
                                      name="startDate"
                                      className="border border-Neutral300 rounded-lg px-4 py-2 text-sm text-Neutral900"
                                    />
                                    <ErrorMessage name="startDate">
                                      {(msg) => (
                                        <div className="text-red-500 text-xs">
                                          {msg}
                                        </div>
                                      )}
                                    </ErrorMessage>
                                  </div>
                                  {values?.interval === "indefinitely" && (
                                    <div className="flex flex-col gap-1 w-1/2">
                                      <label
                                        className="text-sm text-Neutral900 font-semibold"
                                        htmlFor="startDate"
                                      >
                                        End Date
                                      </label>
                                      <FormikDatePicker
                                        name="endDate"
                                        className="border border-Neutral300 rounded-lg px-4 py-2 text-sm text-Neutral900"
                                      />
                                      <ErrorMessage name="endDate">
                                        {(msg) => (
                                          <div className="text-red-500 text-xs">
                                            {msg}
                                          </div>
                                        )}
                                      </ErrorMessage>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="mt-8">
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className={`btn button-hover form-primary-btn relative text-sm text-center block text-Neutral100 bg-AccentRegular font-bold w-full py-3 rounded overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out ${
                                values?.billingType !== "recurring" && "hidden"
                              }`}
                              ref={submitButtonRef}
                            >
                              {isSubmitting ? (
                                <span className="relative">
                                  <svg
                                    className="animate-spin m-auto h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <circle
                                      className="opacity-25"
                                      cx="12"
                                      cy="12"
                                      r="10"
                                      stroke="currentColor"
                                      strokeWidth="4"
                                    ></circle>
                                    <path
                                      className="opacity-75"
                                      fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                  </svg>
                                </span>
                              ) : (
                                <span className="relative drop-shadow-Texts">
                                  Save Template
                                </span>
                              )}
                            </button>
                          </div>
                        </Form>
                      </>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EditInvoiceAndInvoiceTemplateModal;
