import React from "react";
import { useParams } from "react-router";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import ProposalTemplateView from "../../components/ProposalTemplate";

const ProposalTemplate = () => {
  const params = useParams();
  return (
    <div>
      <AdminNavbar
        link={`/admin/templates`}
        title={`${
          params?.type?.charAt(0)?.toUpperCase() + params?.type?.slice(1)
        } Template`}
        backLink={"Go Back to Templates Dashboard"}
      />

      <div className=" w-full md:pt-[94px] mt-2">
        <ProposalTemplateView />
      </div>
    </div>
  );
};

export default ProposalTemplate;
