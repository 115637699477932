import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";

import NamePasswordInput from "./Components/NamePasswordInput";
import SigninBg from "../../../../assets/Images/signin-bg-shap.png";
import Logo from "../../../../assets/Images/Logo.svg";
import Appsumo from "../../../../assets/Images/appsumo-logo-vector.svg";
import Heart from "../../../../assets/Images/heart.svg";

import "swiper/css";
import "swiper/css/navigation";
import { useDispatch } from "react-redux";
import { AppsumoVerifyLicense } from "../../../../store/actions/authAction";
import AppsumoSignupSuccessModal from "../../../Modals/AppsumoSignupSuccessModal";

const AppsumoSignup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state, search } = useLocation();
  const params = new URLSearchParams(search);
  const uuid = params.get("uuid");
  const [signInDetail, setSignInDetail] = useState({
    license_key: "",
    email: "",
    name: "",
    password: "",
  });
  const [isOpenPaymentModal, setIsOpenPaymentModal] = useState(false);

  useEffect(() => {
    document.body.classList.add("home-page");
    return () => {
      document.body.classList.remove("home-page");
    };
  }, []);

  useEffect(() => {
    if (state) {
      setSignInDetail(state);
    }
  }, [state]);

  useEffect(() => {
    const verification = async () => {
      if (uuid) {
        const res = await dispatch(AppsumoVerifyLicense({ license_key: uuid }));
        if (res?.email) {
          setSignInDetail({
            ...signInDetail,
            email: res.email,
            license_key: uuid,
          });
        }
      }
    };
    verification();
  }, [uuid]);

  useEffect(() => {
    if (isOpenPaymentModal) {
      setTimeout(() => {
        setIsOpenPaymentModal(false);
        navigate("/signin");
      }, 5000);
    }
  }, [isOpenPaymentModal]);

  return (
    <div className="flex">
      <div className="w-full max-w-[50%] sm:h-screen relative md:block hidden">
        <div className="absolute top-[50%] w-full 5xl:max-w-[440px] max-w-[384px] left-[50%] -translate-x-1/2 -translate-y-1/2 ml:px-0 px-3">
          <div className="flex items-center">
            <img src={Logo} alt="logo" />
            <h2 className="5xl:text-[60px] lg:text-[56px] text-[40px] font-black w-full max-w-[260px] leading-[0.85] text-Neutral000 ml-4">
              Client Manager
              <br />
              <img
                src={Heart}
                alt="logo"
                className="inline-block max-w-[50px] ml-2"
              />
              <br />
              <img src={Appsumo} alt="logo" />
            </h2>
          </div>
          <p className="text-Neutral100 5xl:text-[18px] text-[16px] mt-[12px]">
            The Simple Client Onboarding & Management Tool
          </p>
        </div>
        <img
          className="w-full object-cover h-screen"
          src={SigninBg}
          alt="background"
        />
      </div>
      <div className="w-full md:max-w-[50%] max-w-full md:h-screen h-full relative mx-auto px-3 overflow-y-auto flex justify-center md:flex-row flex-col md:items-center bg-Neutral000">
        <div className="w-full flex justify-center">
          <div className="flex-col h-full onboading-form max-w-[350px] w-full px-6 md:px-0 md:ml-[30px]">
            <div>
              <img className="w-full max-w-[32px]" src={Logo} alt="logo" />
              <h1 className="mt-3 text-[32px] 5xl:text-[36px] font-bold text-Neutral900">
                Sign Up To Continue
                <span className="text-AccentRegular">.</span>
              </h1>
            </div>
            <div className="onboading-form-content w-full">
              <NamePasswordInput
                signInDetail={signInDetail}
                setIsOpenPaymentModal={setIsOpenPaymentModal}
              />
            </div>
          </div>
        </div>
        <div className="lg:flex hidden justify-end mr-4 mb-4 absolute bottom-0 right-0">
          <p className=" text-Neutral800 text-xs font-medium">
            Have a question? Email us at
            <span className="text-AccentRegular">support@clientmanager.io</span>
          </p>
        </div>
      </div>

      <div className="pr-[32px] pt-[16px] flex justify-end items-center absolute top-0 right-0">
        <p className="Neutral700 text-xs font-medium mr-[24px]">
          Have an account?
        </p>
        <Link
          to="/signin"
          className="text-xs text-center inline-block text-AccentRegular font-[600] py-[7px] px-2 rounded-[4px] border-[1px] border-Neutral300 hover:border-AccentRegular focus-visible:outline focus-visible:outline-4 focus-visible:outline-AccentLight transition duration-300 ease-in-out"
        >
          Sign In
        </Link>
      </div>
      <AppsumoSignupSuccessModal isOpen={isOpenPaymentModal} />
    </div>
  );
};

export default AppsumoSignup;
