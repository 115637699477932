const initialState = {
  note: {
    isLoading: true,
    client: null,
    data: {},
  },
};

const noteReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_NOTE":
      return {
        ...state,
        note: {
          ...state.note,
          ...payload,
        },
      };
    case "CREATE_NOTE_SUCCESS":
      return {
        ...state,
        note: {
          ...state.note,
          client: payload.client,
          data: payload.data,
        },
      };
    default:
      return state;
  }
};

export default noteReducer;
