import update from "immutability-helper";

const initialState = {
  service: {
    isLoading: true,
    client: null,
    data: [],
  },
  all_services: {},
};
const serviceReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_SERVICE":
      let tempService = state.all_services;
      if (payload.is_archived) {
        tempService = {
          ...tempService,
          ...payload.data,
        };
      } else {
        tempService = payload.data;
      }
      return {
        ...state,
        service: {
          ...state.service,
          ...payload,
        },
        all_services: {
          ...tempService,
        },
      };
    case "UPDATE_SERVICE_COLUMN_NAME":
      let tempColumnData = state.all_services;
      if (payload?.data?.services?.length) {
        payload.data.services.forEach((e) => {
          tempColumnData[e._id] = e;
        });
      }
      return {
        ...state,
        all_services: {
          ...tempColumnData,
        },
      };
    case "UPDATE_SERVICE_SUCCESS":
      let allServices = state.all_services;
      if (payload.data.length) {
        payload.data.forEach((e) => {
          allServices[e._id] = e;
        });
      } else {
        allServices[payload.data._id] = payload.data;
      }
      return {
        ...state,
        all_services: {
          ...state.all_services,
          ...allServices,
        },
      };
    case "UPDATE_SERVICE_ABORT":
      let allServiceAbort = state.all_services;
      if (payload.data.length) {
        payload.data.forEach((e) => {
          allServiceAbort[e._id] = e;
        });
      } else {
        allServiceAbort[payload.data._id] = payload.data;
      }
      return {
        ...state,
        all_services: {
          ...state.all_services,
          ...allServiceAbort,
        },
      };
    case "DELETE_SERVICE_SUCCESS":
      let allServicesData = state.all_services;
      const newAllServicesData = update(allServicesData, {
        $unset: [payload.id],
      });
      return {
        ...state,
        all_services: {
          ...newAllServicesData,
        },
      };
    case "CREATE_SERVICE_SUCCESS":
      return {
        ...state,
        all_services: {
          ...state.all_services,
          [payload.data._id]: payload.data,
        },
      };
    case "CREATE_SERVICE_COMMENT_SUCCESS":
      let tempComment = state.all_services;
      tempComment[payload.data.service].comments = [
        ...tempComment[payload.data.service].comments,
        payload.data,
      ];
      return {
        ...state,
        all_services: {
          ...tempComment,
        },
      };
    case "UPDATE_SERVICE_COMMENT_SUCCESS":
      let tempUpdateCommentData = state.all_services;
      tempUpdateCommentData[payload.data.service].comments =
        tempUpdateCommentData[payload.data.service].comments.map((item) => {
          return {
            ...item,
            message:
              item._id === payload.data._id
                ? payload.data.message
                : item.message,
            attachments:
              item._id === payload.data._id
                ? payload.data.attachments
                : item.attachments,
            isedited: item._id === payload.data._id ? true : item.isedited,
          };
        });
      return {
        ...state,
        all_services: {
          ...tempUpdateCommentData,
        },
      };
    case "DELETE_SERVICE_COMMENT_SUCCESS":
      let tempDeleteData = state.all_services;
      tempDeleteData[payload.data.service].comments = tempDeleteData[
        payload.data.service
      ].comments.filter((e) => {
        return e._id !== payload.data._id;
      });
      return {
        ...state,
        all_services: {
          ...tempDeleteData,
        },
      };
    case "ARCHIVE_SERVICE_SUCCESS":
      let tempArchiveData = state.all_services;
      tempArchiveData[payload.id].is_archived =
        !tempArchiveData[payload.id].is_archived;
      return {
        ...state,
        all_services: {
          ...tempArchiveData,
        },
      };
    case "UPDATE_SERVICE_COLUMN_CHANGE_SUCCESS":
      let tempColumnChangeData = state.all_services;
      if (payload?.data?.length) {
        payload?.data?.forEach((e) => {
          tempColumnChangeData[e._id] = e;
        });
      }
      return {
        ...state,
        all_services: {
          ...tempColumnChangeData,
        },
      };
    case "CREATE_SERVICE_SUB_TASK_SUCCESS":
      let tempSubTask = state.all_services;
      tempSubTask[payload.data._id] = {
        ...tempSubTask[payload.data._id],
        sub_tasks: payload.data.sub_tasks,
      };
      return {
        ...state,
        all_services: {
          ...tempSubTask,
        },
      };
    case "UPDATE_SERVICE_SUB_TASK_SUCCESS":
      let tempUpdateSubTask = state.all_services;
      tempUpdateSubTask[payload.data._id] = {
        ...tempUpdateSubTask[payload.data._id],
        sub_tasks: payload.data.sub_tasks,
      };
      return {
        ...state,
        all_services: {
          ...tempUpdateSubTask,
        },
      };
    case "DELETE_SERVICE_SUB_TASK_SUCCESS":
      let tempDeleteSubTask = state.all_services;
      tempDeleteSubTask[payload.data._id] = {
        ...tempDeleteSubTask[payload.data._id],
        sub_tasks: payload.data.sub_tasks,
      };
      return {
        ...state,
        all_services: {
          ...tempDeleteSubTask,
        },
      };
    case "ADD_SERVICE_MEDIA_TO_TASK_SUCCESS":
      let tempMedia = state.all_services;
      tempMedia[payload.data._id] = {
        ...tempMedia[payload.data._id],
        media: payload.data.media,
      };
      return {
        ...state,
        all_services: {
          ...tempMedia,
        },
      };
    case "DELETE_SERVICE_MEDIA_FROM_TASK_SUCCESS":
      let tempDeleteMedia = state.all_services;
      tempDeleteMedia[payload.data._id] = {
        ...tempDeleteMedia[payload.data._id],
        media: payload.data.media,
      };
      return {
        ...state,
        all_services: {
          ...tempDeleteMedia,
        },
      };
    default:
      return state;
  }
};

export default serviceReducer;
