import moment from "moment";

const initialState = {
  events: {
    isLoading: true,
    start_date: null,
    end_date: null,
    data: [],
  },
  reminderEvents: {
    isLoading: true,
    start_date: null,
    end_date: null,
    data: [],
  },
};
const eventReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_EVENTS":
      return {
        ...state,
        events: {
          ...state.events,
          ...payload,
        },
      };
    case "SET_EVENTS_FOR_REMINDER":
      return {
        ...state,
        reminderEvents: {
          ...state.events,
          ...payload,
        },
      };
    case "CREATE_EVENT_SUCCESS":
      const isBetweenDate = moment(payload?.data?.start_date).isBetween(
        moment().format("YYYY-MM-DD"),
        moment().add(30, "days").format("YYYY-MM-DD")
      );
      let tempReminderEvents = state.reminderEvents.data;
      if (isBetweenDate) {
        tempReminderEvents = [...tempReminderEvents, payload.data];
      }
      return {
        ...state,
        events: {
          ...state.events,
          data: [...state.events.data, payload.data],
        },
        reminderEvents: {
          ...state.reminderEvents,
          data: tempReminderEvents,
        },
      };
    case "UPDATE_EVENT_SUCCESS":
      const data = state.events.data;
      const indexOfUpdateRecord = data.findIndex(
        (e) => e._id === payload.data._id
      );
      if (indexOfUpdateRecord !== -1) {
        data[indexOfUpdateRecord] = payload.data;
      }
      return {
        ...state,
        events: {
          ...state.events,
          data: [...data],
        },
      };
    case "DELETE_EVENT_SUCCESS":
      const newRecordAfterDelete = state.events.data.filter(
        (e) => e._id !== payload.data
      );
      return {
        ...state,
        events: {
          ...state.events,
          data: newRecordAfterDelete,
        },
      };
    case "REMOVE_EVENTS_FOR_REMINDER":
      const removeReminder = state.reminderEvents.data.filter(
        (e) => e._id !== payload.data._id
      );
      return {
        ...state,
        reminderEvents: {
          ...state.reminderEvents,
          data: removeReminder,
        },
      };
    default:
      return state;
  }
};

export default eventReducer;
