import React from "react";
import Modal from "react-modal";
import Bigshoesavtar1 from "../../assets/Images/Big Shoes - Avatar 1.svg";
import CloseBtn from "../../assets/Images/close-btn.svg";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const AppsumoWarningModal = (props) => {
  const {
    setIsOpenAppsumoWarningModal,
    isOpenAppsumoWarningModal,
    isCancelPlan,
    setIsCancelPlan,
    isReactiveAccount,
    setIsReactiveAccount,
  } = props;

  return (
    <Modal
      isOpen={isOpenAppsumoWarningModal}
      onRequestClose={() => {
        setIsOpenAppsumoWarningModal(false);
        if (isCancelPlan) {
          setIsCancelPlan(false);
        }
        if (isReactiveAccount) {
          setIsReactiveAccount(false);
        }
      }}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="Example Modal1"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="bg-white px-6 xs:px-12 pt-8 pb-10 rounded-[8px]">
          <div>
            <div className="text-left">
              <div className="flex items-center justify-between border-b border-Neutral200 w-full">
                <span className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2">
                  AppSumo Warning
                </span>
              </div>
              <div className="my-6 flex items-center justify-center">
                <img src={Bigshoesavtar1} alt="" />
              </div>
              <div className="flex items-center justify-center w-[354px]">
                <span className="text-center text-sm font-normal text-Neutral800">
                  <span className="text-center text-sm font-normal text-Neutral800">
                    {isCancelPlan
                      ? "Sorry to see you go! to cancel your plan go to appsomo and cancel your plan."
                      : isReactiveAccount
                      ? "Your account is archived, data will be deleted in 30 days. To active your account go to appsomo and buy a plan."
                      : "Go to your AppSumo account and change the plan there, then it will be updated on ClientManager."}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => {
            setIsOpenAppsumoWarningModal(false);
            if (isCancelPlan) {
              setIsCancelPlan(false);
            }
            if (isReactiveAccount) {
              setIsReactiveAccount(false);
            }
          }}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
};

export default AppsumoWarningModal;
