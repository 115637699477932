import axios from "axios";
import { toast } from "react-toastify";

export const AddUpdateSchedule = (value, setIsLoading) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        "api/v1/auto-email-followup/follow-up-email",
        value
      );
      toast.success("Email followUp sechedule set successfully");
      setIsLoading(false);
      return dispatch({
        type: "FOLLOWUP_SET_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      setIsLoading(false);
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          //   err[element.param] = element.msg;
          toast.error(element?.msg);
        });
        return dispatch({
          type: "FOLLOWUP_ERROR",
          payload: err,
        });
      } else {
        return dispatch({
          type: "FOLLOWUP_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const enabledFollowUp = (value) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        "api/v1/auto-email-followup/enable-disable-follow-up",
        value
      );
      return dispatch({
        type: "FOLLOWUP_SET_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
};

export const getEmailSendSchedule = () => {
  return async (dispatch) => {
    try {
      let { data } = await axios.get(
        `api/v1/auto-email-followup/get-follow-up-schedule`
      );
      return dispatch({
        type: "FOLLOWUP_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "FOLLOWUP_ERROR",
          payload: error,
        });
      } else {
        return dispatch({
          type: "FOLLOWUP_ERROR",
          payload: error,
        });
      }
    }
  };
};
