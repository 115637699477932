import React, { useState } from "react";
import { Link } from "react-router-dom";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import Bolt from "../../assets/Images/Bolt.svg";
import Assets from "../../assets/Images/Asset.svg";
import Folder from "../../assets/Images/Folder.svg";
import Clipboard from "../../assets/Images/Clipboard.svg";
import QuestionWhite from "../../assets/Images/Question-white.svg";
import BagWhite from "../../assets/Images/Bag-white.svg";
import EditRed from "../../assets/Images/Edit-red.svg";
import Edit from "../../assets/Images/Edit.svg";
import Contact from "../../assets/Images/Contact.svg";
import PhoneGray from "../../assets/Images/Phone-gray-icon.svg";
import MailGray from "../../assets/Images/Mail-gray-icon.svg";
import TrashWhite from "../../assets/Images/Trash-white.svg";

const Abc = () => {
  const [value, setValue] = useState("Bob Builderino");
  const [isEdit, setIsEdit] = useState(false);
  return (
    <div>
      {isEdit ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setIsEdit(!isEdit);
          }}
        >
          <input
            className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full h-10 focus:outline-none border-[1.5px] rounded-md border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />{" "}
        </form>
      ) : (
        <div className="flex">
          <p className="font-bold text-xl text-Neutral900">{value}</p>
          <button className="w-4 h-4" onClick={() => setIsEdit(!isEdit)}>
            <img src={Edit} alt="" />
          </button>
        </div>
      )}
    </div>
  );
};

function BobBuilderino() {
  return (
    <>
      <AdminNavbar
        link="/admin/clients"
        title=""
        backLink="Back to Clients"
        abc={<Abc />}
      />
      <div className="5xl:px-[96px] px-6 md:px-12 w-full pt-0">
        <div className="pt-8 relative bobBuilderino-main">
          {/* CLIENT & PROJECT MANAGEMENT */}
          <div className="project-management-main -mx-2 px-2 w-fit overflow-y-auto pr-[60px] pb-2">
            <div className="pb-7">
              <div className="flex justify-between items-center">
                <h5 className="text-Neutral700 text-xs font-semibold tracking-[0.04em]">
                  CLIENT & PROJECT MANAGEMENT
                </h5>
              </div>
              <div className="flex justify-between pt-6 w-full 6xl:max-w-[1000px] 5xl:max-w-[900px] max-w-[664px] flex-wrap">
                {/* TASKS */}
                <div className="w-full 6xl:max-w-[480px] 6xl:pt-[28px] 6xl:pr-[53px] 6xl:pb-[80px] 6xl:pl-[60px] 5xl:max-w-[436px] 5xl:pb-20 max-w-[320px] pl-8 pr-5 pt-6 pb-12 rounded builderinoshadow block mb-[52px] relative">
                  <div className="flex items-center justify-between mb-4 pr-3">
                    <h3 className="text-Neutral900 text-sm font-bold tracking-[0.04em]">
                      TASKS
                    </h3>
                    <div className="w-8 h-8 rounded-full bg-AccentRegular flex items-center justify-center">
                      <img className="w-6 h-6 block" src={Bolt} alt="" />
                    </div>
                  </div>
                  <p className="w-full 6xl:max-w-[400px] 5xl:max-w-[320px] max-w-[253px] text-Neutral800 text-sm font-normal mb-2">
                    Eget ut diam nam amet amet. Lacus, egestas auctor feugiat
                    non tellus nunc, dui. Scelerisque tempor nec urna.
                  </p>
                  <div className="flex justify-end absolute bottom-[16px] right-[20px] 6xl:right-[36px]">
                    <Link
                      to="tasks"
                      className="inlinr-block text-xs font-semibold 5xl:w-[120px] w-[100px] 5xl:h-10 h-8 flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-AccentRegular focus:border-0 focus:outline focus:outline-AccentMediumLight bg-Neutral000 text-AccentRegular transition duration-300 ease-in-out"
                    >
                      View
                    </Link>
                  </div>
                </div>

                {/* ASSETS */}
                <div className="w-full 6xl:max-w-[480px] 6xl:pt-[28px] 6xl:pr-[53px] 6xl:pb-[80px] 6xl:pl-[60px] 5xl:max-w-[436px] 5xl:pb-20 max-w-[320px] pl-8 pr-5 pt-6 pb-12 rounded builderinoshadow block mb-[52px] relative">
                  <div className="flex items-center justify-between mb-4 pr-3">
                    <h3 className="text-Neutral900 text-sm font-bold tracking-[0.04em]">
                      ASSETS
                    </h3>
                    <div className="w-8 h-8 rounded-full bg-AccentRegular flex items-center justify-center">
                      <img className="w-6 h-6 block" src={Assets} alt="" />
                    </div>
                  </div>
                  <p className="w-full 6xl:max-w-[400px] 5xl:max-w-[320px] max-w-[253px] text-Neutral800 text-sm font-normal mb-2">
                    Everything you need for your client’s project. This includes
                    documents like brand guides, images, design files, and
                    passwords.
                  </p>
                  <div className="flex justify-end absolute bottom-[16px] right-[20px] 6xl:right-[36px]">
                    <Link
                      to="assets"
                      className="inlinr-block text-xs font-semibold 5xl:w-[120px] w-[100px] 5xl:h-10 h-8 flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-AccentRegular focus:border-0 focus:outline focus:outline-AccentMediumLight bg-Neutral000 text-AccentRegular transition duration-300 ease-in-out"
                    >
                      View
                    </Link>
                  </div>
                </div>

                {/* DOCUMENTS */}
                <div className="w-full 6xl:max-w-[480px] 6xl:pt-[28px] 6xl:pr-[53px] 6xl:pb-[80px] 6xl:pl-[60px] 5xl:max-w-[436px] 5xl:pb-20 max-w-[320px] pl-8 pr-5 pt-6 pb-12 rounded builderinoshadow block mb-[52px] relative">
                  <div className="flex items-center justify-between mb-4 pr-3">
                    <h3 className="text-Neutral900 text-sm font-bold tracking-[0.04em]">
                      DOCUMENTS
                    </h3>
                    <div className="w-8 h-8 rounded-full bg-AccentRegular flex items-center justify-center">
                      <img className="w-6 h-6 block" src={Folder} alt="" />
                    </div>
                  </div>
                  <p className="w-full 6xl:max-w-[400px] 5xl:max-w-[320px] max-w-[253px] text-Neutral800 text-sm font-normal mb-2">
                    All the client admin files. This includes reports, invoices,
                    contracts, and miscellaneous for any extra documentation you
                    may need.
                  </p>
                  <div className="flex justify-end absolute bottom-[16px] right-[20px] 6xl:right-[36px]">
                    <Link
                      to="documents"
                      className="inlinr-block text-xs font-semibold 5xl:w-[120px] w-[100px] 5xl:h-10 h-8 flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-AccentRegular focus:border-0 focus:outline focus:outline-AccentLight bg-Neutral000 text-AccentRegular transition duration-300 ease-in-out"
                    >
                      View
                    </Link>
                  </div>
                </div>

                {/* SERVICES */}
                <div className="w-full 6xl:max-w-[480px] 6xl:pt-[28px] 6xl:pr-[53px] 6xl:pb-[80px] 6xl:pl-[60px] 5xl:max-w-[436px] 5xl:pb-20 max-w-[320px] pl-8 pr-5 pt-6 pb-12 rounded builderinoshadow block mb-[52px] relative">
                  <div className="flex items-center justify-between mb-4 pr-3">
                    <h3 className="text-Neutral900 text-sm font-bold tracking-[0.04em]">
                      SERVICES
                    </h3>
                    <div className="w-8 h-8 rounded-full bg-AccentRegular flex items-center justify-center">
                      <img className="w-6 h-6 block" src={Clipboard} alt="" />
                    </div>
                  </div>
                  <p className="w-full 6xl:max-w-[400px] 5xl:max-w-[320px] max-w-[253px] text-Neutral800 text-sm font-normal mb-2">
                    Eget ut diam nam amet amet. Lacus, egestas auctor feugiat
                    non tellus nunc, dui. Scelerisque tempor nec urna.{" "}
                  </p>
                  <div className="flex justify-end absolute bottom-[16px] right-[20px] 6xl:right-[36px]">
                    <Link
                      to="services"
                      className="inlinr-block text-xs font-semibold 5xl:w-[120px] w-[100px] 5xl:h-10 h-8 flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-AccentRegular focus:border-0 focus:outline focus:outline-AccentLight bg-Neutral000 text-AccentRegular transition duration-300 ease-in-out"
                    >
                      View
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="pb-6">
              <div className="flex justify-between items-center">
                <h5 className="text-Neutral700 text-xs font-semibold tracking-[0.04em]">
                  CLIENT ONBOARDING
                </h5>
              </div>
              <div className="flex justify-between pt-6 w-full 6xl:max-w-[1000px] 5xl:max-w-[900px] max-w-[664px] flex-wrap">
                {/* TASKS */}
                <div className="w-full 6xl:max-w-[480px] 6xl:pt-[28px] 6xl:pr-[53px] 6xl:pb-[80px] 6xl:pl-[60px] 5xl:max-w-[436px] max-w-[320px] 5xl:pb-20 pl-8 pr-5 pt-6 pb-12 mb-[56px] rounded builderinoshadow block relative">
                  <div className="flex items-center justify-between mb-4 pr-3">
                    <h3 className="text-Neutral900 text-sm font-bold tracking-[0.04em] uppercase">
                      QUESTIONNAIRE
                    </h3>
                    <div className="w-8 h-8 rounded-full bg-Red400 flex items-center justify-center">
                      <img
                        className="w-6 h-6 block"
                        src={QuestionWhite}
                        alt=""
                      />
                    </div>
                  </div>
                  <p className="w-full 6xl:max-w-[400px] 5xl:max-w-[320px] max-w-[253px] text-Neutral800 text-sm font-normal mb-2">
                    Eget ut diam nam amet amet. Lacus, egestas auctor feugiat
                    non tellus nunc, dui. Scelerisque tempor nec urna.
                  </p>
                  <div className="flex justify-end absolute bottom-[16px] right-[20px] 6xl:right-[36px]">
                    <Link
                      to="questionnaire"
                      className="inlinr-block text-xs font-semibold 5xl:w-[120px] w-[100px] 5xl:h-10 h-8 flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-Red400 focus:border-0 focus:outline focus:outline-Red100 bg-Neutral000 text-Red400 transition duration-300 ease-in-out"
                    >
                      View
                    </Link>
                  </div>
                </div>

                {/* ASSETS */}
                <div className="w-full 6xl:max-w-[480px] 6xl:pt-[28px] 6xl:pr-[53px] 6xl:pb-[80px] 6xl:pl-[60px] 5xl:max-w-[436px] max-w-[320px] 5xl:pb-20 pl-8 pr-5 pt-6 pb-12 mb-[56px] rounded builderinoshadow block relative">
                  <div className="flex items-center justify-between mb-4 pr-3">
                    <h3 className="text-Neutral900 text-sm font-bold tracking-[0.04em] uppercase">
                      Proposals
                    </h3>
                    <div className="w-8 h-8 rounded-full bg-Red400 flex items-center justify-center">
                      <img className="w-6 h-6 block" src={BagWhite} alt="" />
                    </div>
                  </div>
                  <p className="w-full 6xl:max-w-[400px] 5xl:max-w-[320px] max-w-[253px] text-Neutral800 text-sm font-normal mb-2">
                    Eget ut diam nam amet amet. Lacus, egestas auctor feugiat
                    non tellus nunc, dui. Scelerisque tempor nec urna.
                  </p>
                  <div className="flex justify-end absolute bottom-[16px] right-[20px] 6xl:right-[36px]">
                    <Link
                      to="#"
                      className="inlinr-block text-xs font-semibold 5xl:w-[120px] w-[100px] 5xl:h-10 h-8 flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-Red400 focus:border-0 focus:outline focus:outline-Red100 bg-Neutral000 text-Red400 transition duration-300 ease-in-out"
                    >
                      View
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* DANGER ZONE */}
            <div className="pb-8 mb-[30px]">
              <div className="flex justify-between items-center mb-6">
                <h5 className="text-Neutral700 text-xs font-semibold tracking-[0.04em]">
                  DANGER ZONE
                </h5>
              </div>
              <div className="px-6 pt-6 pb-8 w-full 6xl:max-w-[1060px] 5xl:max-w-[900px] max-w-[664px] border border-Red400 rounded">
                <div className="flex items-center justify-between mb-8">
                  <div>
                    <h4 className="text-Neutral900 text-sm font-bold tracking-[0.04em] mb-2">
                      Archive Client
                    </h4>
                    <p className="text-Neutral800 text-sm font-normal tracking-[0.04em] w-full 6xl:max-w-[600px] max-w-[408px]">
                      Mark this client as archived. You will still be able to
                      access their information.
                    </p>
                  </div>
                  <Link
                    to="#"
                    className="inlinr-block text-sm font-semibold w-[140px] h-12 flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-Red400 focus:border-0 focus:outline focus:outline-Red100 bg-Neutral000 text-Red400 transition duration-300 ease-in-out"
                  >
                    {" "}
                    Archive{" "}
                  </Link>
                </div>
                <div className="flex items-center justify-between">
                  <div>
                    <h4 className="text-Neutral900 text-sm font-bold tracking-[0.04em] mb-2">
                      Delete Client
                    </h4>
                    <p className="text-Neutral800 text-sm font-normal tracking-[0.04em] w-full 6xl:max-w-[600px] max-w-[408px]">
                      Permanently remove client and all their information.
                    </p>
                  </div>
                  <Link
                    to="#"
                    className="text-sm font-semibold w-[140px] h-12 flex items-center justify-center rounded leading-[1.4] hover:border-Red400 focus:border-0 focus:outline focus:outline-Red100 bg-Red400 text-Neutral100 transition duration-300 ease-in-out"
                  >
                    <span className="relative z-10">Delete</span>
                    <img className="w-6 h-6 ml-1" src={TrashWhite} alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute right-[48px] top-[32px]">
            <div className="fixed right-[48px] text-right border-b border-Neutral300 w-[240px]">
              <button
                type="button"
                className="button-hover large-primary-btn text-xs text-center inline-block w-[91px] 5xl:w-[120px] text-Neutral100 relative seenotes font-semibold py-2 px-[15px] ml-5 rounded focus:outline focus:outline-4 focus:outline-AccentMediumLight focus-visible:outline focus-visible:outline-[6px] focus-visible:drop-shadow-none focus-visible:outline-AccentMediumLight transition duration-300 ease-in-out"
              >
                <span className="relative z-10">See Notes</span>
              </button>
              <div className="mt-[50px]">
                {/* Contact Person */}
                <div className="flex justify-end items-center mb-10">
                  <div className="flex flex-col">
                    <div className="flex justify-end items-center">
                      <Link to="#">
                        <img
                          className="5xl:w-4 5xl:h-4 w-4 h-4 mr-1"
                          src={Edit}
                          alt=""
                        />
                      </Link>
                      <p className="text-Neutral700 text-xs font-medium">
                        Contact Person
                      </p>
                    </div>
                    <h4 className="text-Neutral900 text-base font-semibold">
                      Vicky Builderino
                    </h4>
                  </div>
                  <div className="bg-Neutral200 w-8 h-8 ml-4 rounded-full flex items-center justify-center ">
                    <img className="" src={Contact} alt="" />
                  </div>
                </div>

                {/* Phone Number */}
                <div className="flex justify-end items-center mb-10">
                  <div className="flex flex-col">
                    <div className="flex justify-end items-center">
                      <Link to="#">
                        <img
                          className="5xl:w-4 5xl:h-4 w-4 h-4 mr-1"
                          src={Edit}
                          alt=""
                        />
                      </Link>
                      <p className="text-Neutral700 text-xs font-medium">
                        Phone Number
                      </p>
                    </div>
                    <h4 className="text-Neutral900 text-base font-semibold">
                      +27 081 424 2960
                    </h4>
                  </div>
                  <div className="bg-Neutral200 w-8 h-8 ml-4 rounded-full flex items-center justify-center ">
                    <img className="" src={PhoneGray} alt="" />
                  </div>
                </div>

                {/* Email */}
                <div className="flex justify-end items-center mb-6">
                  <div className="flex flex-col">
                    <div className="flex justify-end items-center">
                      <Link to="#">
                        <img
                          className="5xl:w-4 5xl:h-4 w-4 h-4 mr-1"
                          src={Edit}
                          alt=""
                        />
                      </Link>
                      <p className="text-Neutral700 text-xs font-medium">
                        Email
                      </p>
                    </div>
                    <h4 className="text-Neutral900 text-base font-semibold">
                      bob@builderino.com
                    </h4>
                  </div>
                  <div className="bg-Neutral200 w-8 h-8 ml-4 rounded-full flex items-center justify-center ">
                    <img className="" src={MailGray} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BobBuilderino;
