import React from "react";

function PortalIcon({
  color = "#5A5A66",
  width = 24,
  className = "text-[currentColor]",
}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M23.0376 18.0103V9.97437C23.0372 9.62207 22.9443 9.27606 22.768 8.97105C22.5918 8.66603 22.3384 8.41275 22.0334 8.2366L15.0041 4.21862C14.6988 4.04229 14.3524 3.94946 13.9999 3.94946C13.6473 3.94946 13.301 4.04229 12.9957 4.21862L5.96635 8.2366C5.66134 8.41275 5.408 8.66603 5.23174 8.97105C5.05549 9.27606 4.96252 9.62207 4.96216 9.97437V18.0103C4.96252 18.3626 5.05549 18.7086 5.23174 19.0137C5.408 19.3187 5.66134 19.572 5.96635 19.7481L12.9957 23.7661C13.301 23.9424 13.6473 24.0352 13.9999 24.0352C14.3524 24.0352 14.6988 23.9424 15.0041 23.7661L22.0334 19.7481C22.3384 19.572 22.5918 19.3187 22.768 19.0137C22.9443 18.7086 23.0372 18.3626 23.0376 18.0103Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.2334 8.92969L14 14.0024L22.7666 8.92969"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 24.1177V13.9924"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PortalIcon;
