import axios from "axios";
import {
  addMinutes,
  format,
  isSameDay,
  isToday,
  isWithinInterval,
  parseISO,
  setHours,
  setMinutes,
} from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Logo from "../../assets/Images/Logo.svg";
import CheckMarkIcon from "../../components/Icons/CheckMarkIcon";
import ClockIcon from "../../components/Icons/ClockIcon";
import ConfirmMeetingBookingModal from "../../components/Modals/ConfirmMeetingBookingModal";
import BookMeetingSummary from "../../components/Modals/ScheduleMeetingModal/BookMeetingSummary";
import useGetBrandIdentityForMeeting from "../../hooks/useGetBrandIdentityForMeeting";
import { useQuery } from "../../hooks/useQuery";
import { cn } from "../../utils";

function ScheduleMeeting() {
  const query = useQuery();
  const { scheduleSlug, username } = useParams();
  const { logo, companyName } = useGetBrandIdentityForMeeting(
    `/${username}/${scheduleSlug}`
  );

  const [startDate, setStartDate] = useState(new Date());
  const [meeting, setMeeting] = useState({});
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState(null);
  const [disabledDates, setDisabledDates] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [meetingTitle, setMeetingTitle] = useState("");
  const [scheduledMeetingId, setScheduledMeetingId] = useState();
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);

  const schedulerTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const client = query.get("client");

  const brandIdentityData = useSelector((state) => state?.brandIdentity);

  useEffect(() => {
    const vanityPath = `/${username}/${scheduleSlug}`;

    const getScheduleMeeting = async (vanityPath) => {
      try {
        const { data } = await axios.get(
          `api/v1/meeting/get-schedule-meeting-by-vanity-path?vanity_path=${vanityPath}`
        );
        if (data?.data) {
          setMeeting(data?.data);
          // dispatch(getIdentityAction({user:data?.data?.user?._id}))
        }
      } catch (error) {
        console.log("Error getting meeting schedule:", error);
      }
    };
    getScheduleMeeting(vanityPath);
  }, [scheduleSlug, username]);

  useEffect(() => {
    const getDisabledDates = () => {
      // Dates to be disabled based on unavailability
      const disabledDates =
        meeting.unavailability?.map((unavail) => {
          const start = new Date(unavail.start_date);
          const end = new Date(unavail.end_date);
          let dateOfStart = start.getDate();
          let dateOfEnd = end.getDate();

          // both date are same then, no need to add date's in disable.
          if (dateOfStart === dateOfEnd) {
            return;
          }
          const datesBetween = [];
          for (
            let date = start;
            date <= end;
            date.setDate(date.getDate() + 1)
          ) {
            datesBetween.push(new Date(date));
          }

          let removeEndDate = datesBetween.filter(
            (item) => item.getDate() !== end.getDate()
          );

          let removedStartDate = removeEndDate.filter(
            (item) => item.getDate() !== dateOfStart
          );

          return removedStartDate;
        }) || [];

      return disabledDates.flat();
    };
    const disabledDatesRanges = getDisabledDates();
    setDisabledDates(disabledDatesRanges);
  }, [meeting.unavailability]);

  useEffect(() => {
    // Ensure that meeting data is loaded, including availability and unavailability
    if (!meeting || !meeting.availability || !meeting.unavailability) return;

    const slotDurationMinutes = parseInt(meeting.time_slot.split(" ")[0], 10);

    const matchesAvailability = (date, availabilityOption) => {
      const dayOfWeek = format(date, "EEEE");
      const isWeekendDay = [6, 0].includes(date.getDay()); // Assuming 6 is Saturday and 0 is Sunday

      switch (availabilityOption.day) {
        case "Monday - Sunday":
          return true;
        case "Every Weekday":
          return !isWeekendDay;
        case "Weekends Only":
          return isWeekendDay;
        default:
          return availabilityOption.day === dayOfWeek;
      }
    };

    const getTimeRangeForDate = (date, availabilityArray) => {
      for (const availabilityOption of availabilityArray) {
        if (matchesAvailability(date, availabilityOption)) {
          // We found a matching availability option, return its time range.
          return availabilityOption;
        }
      }
      // No matching availability was found for this date.
      return null;
    };

    const selectedDayAvailability = getTimeRangeForDate(
      startDate,
      meeting.availability
    );

    if (!selectedDayAvailability) return;

    // Extract the hours and minutes from the availability time range
    const [startTimeStr, endTimeStr] = selectedDayAvailability.time
      .match(/\((.*?)\)/)[1]
      .split("-");
    const [startHours, startMinutes] = startTimeStr.split(":").map(Number);
    const [endHours, endMinutes] = endTimeStr.split(":").map(Number);

    // Create Date objects for the start and end times
    let startTime = new Date(startDate);
    startTime = zonedTimeToUtc(
      setHours(setMinutes(new Date(startDate), startMinutes), startHours),
      meeting.timezone
    );

    let endTime = new Date(startDate);
    endTime = zonedTimeToUtc(
      setHours(setMinutes(new Date(startDate), endMinutes), endHours),
      meeting.timezone
    );

    // Generate all potential timeslots for the day
    let timeSlots = [];
    while (startTime < endTime) {
      // Set seconds and milliseconds to 0 to align with booked slots comparison
      const slotWithZeroedSeconds = new Date(startTime.setSeconds(0, 0));
      timeSlots.push(slotWithZeroedSeconds);
      startTime = addMinutes(startTime, slotDurationMinutes);
    }

    const filterBookedSlots = (slots) => {
      const bookedSlots = meeting.linked_meeting.map((meeting) => {
        const [hours, minutes] = meeting.time.split(":").map(Number);
        const meetingDate = new Date(meeting.date);
        meetingDate.setHours(hours, minutes, 0, 0);
        return meetingDate;
      });
      return slots?.filter(
        (slot) =>
          !bookedSlots?.some(
            (bookedSlot) => bookedSlot.getTime() === slot.getTime()
          )
      );
    };

    const filterUnavailability = (timeSlots) => {
      const now = new Date();
      // If 'date' is today, filter out timeslots that are in the past
      if (isToday(startDate)) {
        return timeSlots.filter((slot) => slot > now);
      }
      let selectedDate = timeSlots[0].toISOString();

      // Filter timeslots based on unavailability
      const unavailabilityForDay = meeting.unavailability.filter((unavail) => {
        const unavailStart = parseISO(unavail.start_date);
        const unavailEnd = parseISO(unavail.end_date);
        const aSelectedDate = parseISO(selectedDate);

        let selcted = new Date(aSelectedDate);
        let selectedYear = selcted.getFullYear();
        let Selctedmonth = selcted.getMonth() + 1;
        let selctedDay = selcted.getDate();
        let SelctedFormattedDate = `${Selctedmonth.toString().padStart(
          2,
          "0"
        )}-${selctedDay.toString().padStart(2, "0")}-${selectedYear}`;

        let unavailEndDay = new Date(unavailEnd);
        let endYear = unavailEndDay.getFullYear();
        let endMonth = unavailEndDay.getMonth() + 1;
        let EndDay = unavailEndDay.getDate();
        let EndformattedDate = `${endMonth
          .toString()
          .padStart(2, "0")}-${EndDay.toString().padStart(2, "0")}-${endYear}`;

        let unavailStartDay = new Date(unavailStart);
        let year = unavailStartDay.getFullYear();
        let month = unavailStartDay.getMonth() + 1;
        let day = unavailStartDay.getDate();
        let startFormattedDate = `${month.toString().padStart(2, "0")}-${day
          .toString()
          .padStart(2, "0")}-${year}`;

        return SelctedFormattedDate == EndformattedDate ||
          SelctedFormattedDate == startFormattedDate
          ? true
          : isWithinInterval(startDate, {
              start: unavailStart,
              end: unavailEnd,
            });
      });

      // If the date is within the unavailability range, filter out all timeslots
      if (unavailabilityForDay.length > 0) {
        // Special case for the end date of unavailability
        if (isSameDay(startDate, parseISO(unavailabilityForDay[0].end_date))) {
          const unavailEndTime = setHours(
            setMinutes(
              startDate,
              unavailabilityForDay[0].end_time.split(":")[1]
            ),
            unavailabilityForDay[0].end_time.split(":")[0]
          );
          // Allow timeslots after the unavailability period ends
          return timeSlots.filter((slot) => slot > unavailEndTime);
        }

        if (
          isSameDay(startDate, parseISO(unavailabilityForDay[0].start_date))
        ) {
          const unavailStartTime = setHours(
            setMinutes(
              startDate,
              unavailabilityForDay[0].start_time.split(":")[1]
            ),
            unavailabilityForDay[0].start_time.split(":")[0]
          );
          return timeSlots.filter((slot) => slot < unavailStartTime);
        }

        return []; // No timeslots if the whole day is unavailable
      }

      return timeSlots; // Return unfiltered timeslots if the day is not within the unavailability range
    };

    timeSlots = filterUnavailability(timeSlots);
    timeSlots = filterBookedSlots(timeSlots);

    timeSlots = timeSlots.map((slot) =>
      utcToZonedTime(slot, schedulerTimezone)
    );

    // Set the filtered timeslots to state
    setTimeSlots(timeSlots);
  }, [
    startDate,
    meeting.availability,
    meeting.unavailability,
    meeting.time_slot,
    meeting,
    schedulerTimezone,
  ]);

  const toggleTimeSlotSelection = (timeSlot) => {
    const isSelected =
      selectedTimeSlots && selectedTimeSlots.getTime() === timeSlot.getTime();

    setSelectedTimeSlots(isSelected ? null : timeSlot);
  };

  const handleConfirmMeetingBooking = (e) => {
    setIsModalOpen(true);
  };

  const isDateAvailable = (date) => {
    // Check availability array for the day of the week
    const dayOfWeek = format(date, "EEEE");
    const isWeekendDay = [0, 6].includes(date.getDay()); // Sunday - 0, Saturday - 6
    let isAvailable = false;

    // Check each availability option
    meeting?.availability?.forEach((avail) => {
      if (avail.day === "Monday - Sunday") {
        isAvailable = true;
      } else if (avail.day === "Every Weekday" && !isWeekendDay) {
        isAvailable = true;
      } else if (avail.day === "Weekends Only" && isWeekendDay) {
        isAvailable = true;
      } else if (avail.day === dayOfWeek) {
        isAvailable = true;
      }
      // Add more conditions if there are other custom options
    });

    return isAvailable;
  };

  return (
    <div className="flex flex-col justify-between">
      <div className="px-8 py-3">
        <div className="flex items-center gap-1">
          <img
            src={logo ? logo : Logo}
            alt="logo"
            className="object-contain w-6 h-6"
          />
          <h4 className="ml-1 text-sm font-black medium-screen-hover whitespace-nowrap focus-visible:outline-none site-title">
            {companyName ? companyName : 'ClientManager'}
          </h4>
        </div>
      </div>

      <div className="px-2 mt-5 space-y-8 sm:px-6 lg:px-8 xl:mx-auto">
        <div className="px-0">
          <h3 className="text-lg font-bold capitalize text-Neutral900">
            {meeting?.title}
          </h3>
        </div>

        <div className="flex flex-col items-start justify-between gap-4 lg:flex-row ">
          {/* Left content */}
          <div className="flex flex-col sm:flex-row lg:flex-col gap-5 min-w-[290px]">
            <div className="px-5 pt-4 pb-5 space-y-5 border border-Neutral300 rounded-xl">
              <div>
                <span className="font-semibold text-[10px] tracking-[4%] leading-[18px] text-Neutral500 uppercase">
                  Schedule Name
                </span>
                <div className="font-semibold text-sm leading-[23px] text-Neutral800">
                  {meeting?.title}
                </div>
              </div>

              <div className="flex items-center justify-between gap-4">
                <div className="flex items-center gap-2">
                  <img
                    src={
                      meeting.user?.profile_path ||
                      "https://clientmanager-documents-images.s3.amazonaws.com/profile/1672084410937.jpeg"
                    }
                    alt="person"
                    className="object-cover w-6 h-6 rounded-full"
                  />
                  <span className="text-xs text-Neutral900">
                    {meeting.user?.user_name}
                  </span>
                </div>

                <div className="flex items-center gap-1">
                  <ClockIcon width={16} />
                  <span className="text-[10px] leading-[18px] tracking-[4%] text-Neutral800 whitespace-nowrap">
                    {meeting?.time_slot}
                  </span>
                </div>
              </div>
            </div>

            <div className="px-5 py-5 space-y-5 border border-Neutral300 rounded-xl">
              <div>
                <div className="flex items-center justify-between mb-2">
                  <span className="text-xs font-medium text-Neutral900">
                    Meeting Title
                  </span>
                  <span className="text-xs italic font-medium text-Neutral700">
                    Optional
                  </span>
                </div>

                <div className="h-12 flex items-center px-4 border-[1.5px] border-Neutral300 rounded focus-within:ring-2 focus-within:ring-AccentRegular">
                  <input
                    className="text-sm font-medium text-Neutral700 focus:outline-0"
                    placeholder="Eg. Product Review"
                    value={meetingTitle}
                    onChange={(e) => setMeetingTitle(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between w-full gap-5 sm:flex-row lg:flex-grow">
            {/* Middle content */}
            <div className="flex flex-col items-center justify-between flex-grow gap-5 px-4 py-4 border lg:px-10 border-Neutral300 rounded-xl h-fit">
              {/* Calendar component */}
              <div className="relative w-full">
                <DatePicker
                  inline
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    setSelectedTimeSlots(null);
                  }}
                  excludeDates={disabledDates}
                  minDate={new Date()}
                  filterDate={isDateAvailable}
                />
              </div>

              {/* Confirm button */}
              <button
                className="self-stretch h-12 px-4 py-2 text-sm font-semibold rounded bg-AccentRegular text-Neutral000 hover:bg-AccentMediumDark disabled:cursor-not-allowed disabled:bg-Neutral400 disabled:text-Neutral600"
                onClick={(e) => handleConfirmMeetingBooking(e)}
                disabled={!startDate || !selectedTimeSlots}
              >
                Confirm meeting
              </button>
            </div>

            {/* Right content */}
            <div className="border border-Neutral300 rounded-xl px-4 py-5 space-y-5 flex-grow lg:min-w-[290px]">
              <div className="text-xs text-Neutral900 leading-[18px] font-noto">
                Select your time slot
              </div>
              <div className="text-xs text-Neutral900 font-noto">
                Time Zone: {schedulerTimezone}
              </div>

              {timeSlots.length > 0 ? (
                <ul className="w-full space-y-4 overflow-y-auto max-h-[34vh]">
                  {timeSlots.map((timeSlot, index) => {
                    const isSelected =
                      selectedTimeSlots &&
                      selectedTimeSlots.getTime() === timeSlot.getTime();

                    return (
                      <li
                        key={index}
                        className={cn(
                          "relative py-2 px-4 text-center text-sm font-medium text-AccentRegular cursor-pointer rounded flex justify-between items-center hover:bg-AccentLight bg-Neutral200 border-2 border-transparent",
                          {
                            "border-AccentRegular": isSelected,
                          }
                        )}
                        onClick={() => toggleTimeSlotSelection(timeSlot)}
                      >
                        <span
                          className={cn("flex-1", {
                            "text-AccentRegular": isSelected,
                          })}
                        >
                          {new Date(timeSlot).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: false,
                          })}
                        </span>
                        {isSelected && (
                          <span className="absolute rounded-full bg-AccentRegular right-5">
                            <CheckMarkIcon
                              width={20}
                              color="rgb(255, 255, 255)"
                            />
                          </span>
                        )}
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <p className="p-3 text-xs italic text-center border rounded-md border-Neutral300 text-Neutral600">
                  No slots available for today! Try for the next day.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <ConfirmMeetingBookingModal
        isOpen={isModalOpen}
        setIsOpen={(shouldClose) => setIsModalOpen(shouldClose)}
        bookingInfo={{
          username,
          id: meeting._id,
          meetingTitle: meetingTitle,
          date: startDate,
          timeSlot: meeting.time_slot,
          time: selectedTimeSlots,
          client: client ? client : null,
        }}
        setIsSuccess={(shouldClose) => setIsSummaryOpen(shouldClose)}
        setScheduledMeetingId={setScheduledMeetingId}
      />

      <BookMeetingSummary
        isModalOpen={isSummaryOpen}
        setIsOpen={(shouldClose) => setIsSummaryOpen(shouldClose)}
        bookingInfo={{
          date: startDate,
          time: selectedTimeSlots,
          timeSlot: meeting.time_slot,
          id: scheduledMeetingId,
        }}
      />
    </div>
  );
}

export default ScheduleMeeting;
