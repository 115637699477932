import moment from "moment";

function getCurrentWeekRange(weekNum = null) {
  if (!weekNum) {
    return null;
  }

  const firstDayOfWeek = moment().day("Monday").week(weekNum);
  const weekStart = firstDayOfWeek.clone().startOf("isoWeek").format("D");
  const weekEnd = firstDayOfWeek.clone().endOf("isoWeek").format("D");
  const monthStartShort = firstDayOfWeek.clone().endOf("isoWeek").format("MMM");
  const monthEndShort = firstDayOfWeek.clone().startOf("isoWeek").format("MMM");

  if (monthStartShort === monthEndShort) {
    return `${weekStart}-${weekEnd} ${monthEndShort}`;
  }

  return `${weekStart} ${monthStartShort}-${weekEnd} ${monthEndShort}`;
}

export default getCurrentWeekRange;
