const initialState = {
  isLoading: false,
  isCalled: false,
  data: [],
  error: null,
};

const foldersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_FOLDER_SUCCESS":
      return {
        ...state,
        isLoading: false,
        data: payload.data,
        error: null,
      };

    case "GET_ALL_FOLDERS":
      return {
        ...state,
        isLoading: true,
        isCalled: true,
      };
    case "GET_ALL_FOLDERS_SUCCESS":
      return {
        ...state,
        isLoading: false,
        data: payload,
      };
    case "GET_ALL_FOLDERS_ERROR":
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case "CREATE_FOLDER_SUCCESS":
      return {
        ...state,
        data: [...state.data, payload],
      };
    case "UPDATE_FOLDER":
      return {
        ...state,
        isLoading: true,
        isCalled: true,
      };

    case "UPDATE_FOLDER_SUCCESS":
      return {
        ...state,
        isLoading: false,
        data: state.data.map((folder) =>
          folder._id === payload._id ? { ...folder, ...payload } : folder
        ),
      };

    case "UPDATE_FOLDER_ERROR":
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case "UPDATE_MULTIPLE_FOLDERS_SUCCESS":
      const updatedFoldersMap = new Map(
        payload.map((folder) => [folder._id, folder])
      );
      return {
        ...state,
        isLoading: false,
        data: state.data.map(
          (folder) => updatedFoldersMap.get(folder._id) || folder
        ),
      };

    case "UPDATE_MULTIPLE_FOLDERS_ERROR":
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case "DELETE_FOLDER_SUCCESS":
      return {
        ...state,
        data: state.data.filter((folder) => folder._id !== payload),
      };
    default:
      return state;
  }
};

export default foldersReducer;
