import React, { Fragment, useRef, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import CloseBtn from "../../../assets/Images/close-btn.svg";
import ImgIcon from "../../../assets/Images/img-icon.svg";
import Close from "../../../assets/Images/Close-gray.svg";
import { customModalStyles } from "../modalsData";
import LoadingIcon from "../../Icons/LoadingIcon";
import PhoneInput from "../../PhoneInput";
import { updateLeadAction } from "../../../store/actions/leadAction";
import { useLocation } from "react-router";
import { Menu, Transition } from "@headlessui/react";
import { currencyList } from "../../../assets/json/currencyList";

const RE_DIGIT = new RegExp("^[0-9]*$", "");

const CreateClientSchema = Yup.object().shape({
  lead_name: Yup.string().required("Lead Name Required"),
  email: Yup.string().email("Email is not valid"),
});

function EditLeadModal({ isOpen = false, setIsOpen = () => {} }) {
  const currencyref = useRef(null);
  const dispatch = useDispatch();
  const [isNewCustomField, setIsNewCustomField] = useState(false);

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const id = query.get("cardId");
  const lead = useSelector((state) =>
    state.lead.leads?.data?.find((lead) => lead._id === id)
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          setIsOpen(false);
          setIsNewCustomField(false);
        }}
        style={customModalStyles}
        closeTimeoutMS={300}
        contentLabel="Example Modal"
      >
        <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] md:animate__fadeInUp">
          <div className="bg-white px-12 py-8 rounded-[8px] max-h-[80vh]">
            <div>
              <div className="text-left">
                <h3
                  className="text-xs text-Neutral700 leading-[18px] font-semibold pb-2 border-b border-Neutral200 w-full mb-8 uppercase"
                  id="modal-title"
                >
                  Edit Lead Details
                </h3>
                <Formik
                  initialValues={{
                    email: lead?.email,
                    lead_name: lead?.name,
                    phone_number: lead?.phone_number,
                    budget: lead?.budget,
                    business: lead?.business_name,
                    profile: lead?.profile ? lead?.profile : null,
                    profile_path: lead?.profile_path
                      ? lead?.profile_path
                      : null,
                    currency: lead?.currency ? lead?.currency : currencyList[0],
                    website: lead?.website ? lead?.website : null,
                    description: lead?.description,
                    rating: lead?.rating,
                    custom_fields: lead?.custom_fields || [],
                  }}
                  enableReinitialize
                  validationSchema={CreateClientSchema}
                  onSubmit={(values, { setSubmitting, setErrors }) => {
                    setSubmitting(true);
                    const formData = new FormData();
                    formData.append("name", values.lead_name);
                    formData.append("email", values.email);
                    formData.append("phone_number", values.phone_number);
                    formData.append("budget", values.budget);
                    formData.append("business_name", values.business);
                    formData.append("website", values.website);
                    formData.append("profile", values.profile);
                    formData.append("rating", values.rating || 0);
                    formData.append("id", lead?._id);
                    formData.append(
                      "custom_fields",
                      JSON.stringify(values.custom_fields)
                    );
                    formData.append(
                      "currency",
                      JSON.stringify(values.currency)
                    );
                    formData.append(
                      "description",
                      JSON.stringify(values?.description)
                    );
                    dispatch(
                      updateLeadAction(
                        formData,
                        setSubmitting,
                        setErrors,
                        setIsOpen
                      )
                    );
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldError,
                    setFieldValue,
                  }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        <div className="bg-white px-5 rounded-[8px] max-h-[50vh] overflow-x-hidden overflow-y-auto">
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div className="mb-6">
                              <div className="flex items-center justify-between">
                                <span className="text-Neutral900 font-bold text-xs">
                                  Name
                                </span>
                              </div>
                              <div className="mt-2">
                                <input
                                  className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                                  type="text"
                                  placeholder="Eg. Luke Skywalker"
                                  name="lead_name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.lead_name}
                                />
                                {errors.lead_name && touched.lead_name && (
                                  <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                                    {errors.lead_name}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="mb-6">
                              <div className="flex items-center justify-between">
                                <span className="text-Neutral900 font-bold text-xs">
                                  Email
                                </span>
                                <span className="text-xs font-medium italic text-Neutral700">
                                  Optional
                                </span>
                              </div>
                              <div className="mt-2">
                                <input
                                  className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                                  type="email"
                                  placeholder="Eg. lukeskywalker@gmail.com"
                                  name="email"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.email}
                                />
                                {errors.email && touched.email && (
                                  <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                                    {errors.email}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="mb-6">
                              <div className="flex items-center justify-between">
                                <span className="text-Neutral900 font-bold text-xs">
                                  Business
                                </span>
                                <span className="text-xs font-medium italic text-Neutral700">
                                  Optional
                                </span>
                              </div>
                              <div className="mt-2">
                                <input
                                  className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                                  type="text"
                                  placeholder="Microwsoft"
                                  name="business"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.business}
                                />
                                {errors.business && touched.business && (
                                  <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                                    {errors.business}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="mb-6">
                              <div className="flex items-center justify-between">
                                <span className="text-Neutral900 font-bold text-xs">
                                  Website
                                </span>
                                <span className="text-xs font-medium italic text-Neutral700">
                                  Optional
                                </span>
                              </div>
                              <div className="mt-2">
                                <input
                                  className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                                  type="text"
                                  placeholder="www.mikerowsoft.com"
                                  name="website"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.website}
                                />
                                {errors.website && touched.website && (
                                  <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                                    {errors.website}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="mb-6">
                              <div className="flex items-center justify-between">
                                <span className="text-Neutral900 font-bold text-xs">
                                  Budget
                                </span>
                                <span className="text-xs font-medium italic text-Neutral700">
                                  Optional
                                </span>
                              </div>
                              <div className="mt-2 relative z-50">
                                {/* <input
                            className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                            type="text"
                            placeholder="Infinite"
                            name="budget"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.budget}
                          /> */}
                                <input
                                  className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition pr-[100px]"
                                  type="text"
                                  inputMode="numeric"
                                  placeholder="1000"
                                  value={values?.budget}
                                  name="budget"
                                  onChange={(e) => {
                                    const checkedNumber = RE_DIGIT.test(
                                      e.target.value
                                    );
                                    if (checkedNumber) {
                                      handleChange(e);
                                    }
                                  }}
                                />
                                <div className="absolute right-0 top-[50%] -translate-y-2/4">
                                  <Menu
                                    as="div"
                                    className="relative inline-block text-left"
                                  >
                                    <Menu.Button
                                      ref={currencyref}
                                      className={`hourly-rate focus-visible:outline-none popup-btn relative w-[89px] h-[36px] p-[4px_8px] mr-2 rounded-[4px] hover:bg-Neutral300`}
                                    >
                                      <div
                                        className={`flex items-center text-Neutral700 text-sm font-medium justify-between`}
                                      >
                                        <span className="whitespace-nowrap text-ellipsis overflow-hidden">
                                          {values?.currency
                                            ? `${values?.currency?.short_name} - ${values?.currency?.symbol}`
                                            : "USD - $"}
                                        </span>
                                        <svg
                                          width="11"
                                          height="8"
                                          viewBox="0 0 11 8"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M0.324856 1.63059C-0.206125 0.977077 0.258936 0 1.10097 0H9.89903C10.7411 0 11.2061 0.977078 10.6751 1.63059L6.27611 7.04478C5.87592 7.53733 5.12408 7.53733 4.72389 7.04478L0.324856 1.63059Z"
                                            fill="#5A5A66"
                                          ></path>
                                        </svg>
                                      </div>
                                    </Menu.Button>
                                    <Transition
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <Menu.Items className="origin-top-right max-h-[241px] z-[999999] absolute top-[42px] right-[4px] rounded-[8px] dropdowns-boxshadow bg-white ring-Neutral300 ring-opacity-5 focus:outline-none overflow-auto cursor-pointer w-[180px] p-2">
                                        {currencyList
                                          ?.sort((a, b) =>
                                            a?.short_name?.localeCompare(
                                              b?.short_name
                                            )
                                          )
                                          ?.map((item) => {
                                            return (
                                              <div
                                                className="hover:bg-Neutral200 currency-hover rounded-[4px] py-2 px-2"
                                                key={item?.name}
                                                onClick={() => {
                                                  setFieldValue(
                                                    "currency",
                                                    item
                                                  );
                                                  setTimeout(() => {
                                                    currencyref?.current?.click();
                                                  }, 0);
                                                }}
                                              >
                                                <p className="text-Neutral800 font-normal text-xs group-hover:text-text-Neutral900 transition-all duration-300">
                                                  {item?.name}
                                                </p>
                                                <p className="text-Neutral600 font-normal text-xs">
                                                  {item?.short_name} -{" "}
                                                  {item?.symbol}
                                                </p>
                                              </div>
                                            );
                                          })}
                                      </Menu.Items>
                                    </Transition>
                                  </Menu>
                                </div>

                                {errors.budget && touched.budget && (
                                  <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                                    {errors.budget}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="mb-6">
                              <div className="flex items-center justify-between">
                                <span className="text-Neutral900 font-bold text-xs">
                                  Profile Image
                                </span>
                                <span className="text-xs font-medium italic text-Neutral700">
                                  Optional
                                </span>
                              </div>
                              <div className="relative mt-2">
                                <label
                                  htmlFor={
                                    values?.profile ? null : "uploadlogo"
                                  }
                                  className={`placeholder:text-Neutral500 p-2 px-3 text-sm leading-5 w-full focus-visible:outline-none border-[1px] rounded border-Neutral400 hover:border-Neutral800 bg-Neutral100 transition duration-300 border-dashed flex justify-between ${
                                    !values?.profile && "cursor-pointer"
                                  }`}
                                >
                                  {values?.profile_path ? (
                                    <span className="flex items-center">
                                      <img
                                        src={values?.profile_path}
                                        alt=""
                                        height={values?.profile && 150}
                                        width={values?.profile && 100}
                                      />
                                      <p
                                        className={`text-Neutral600 ml-2 text-xs font-medium text-ellipsis overflow-hidden ${
                                          values?.profile && "w-[160px]"
                                        }`}
                                      >
                                        {values?.profile
                                          ? values?.profile
                                          : "Upload your photo or company logo."}
                                      </p>
                                    </span>
                                  ) : (
                                    <span className="flex items-center">
                                      <img
                                        src={
                                          values?.profile
                                            ? URL.createObjectURL(
                                                values?.profile
                                              )
                                            : ImgIcon
                                        }
                                        alt=""
                                        height={values?.profile && 150}
                                        width={values?.profile && 100}
                                      />
                                      <p
                                        className={`text-Neutral600 ml-2 text-xs font-medium text-ellipsis overflow-hidden ${
                                          values?.profile && "w-[160px]"
                                        }`}
                                      >
                                        {values?.profile?.name
                                          ? values?.profile?.name
                                          : "Upload client’s photo or company logo."}
                                      </p>
                                    </span>
                                  )}

                                  {values?.profile ? (
                                    <img
                                      className="cursor-pointer"
                                      src={Close}
                                      alt=""
                                      onClick={() => {
                                        setFieldValue(`profile`, null);
                                        setFieldValue(`profile_path`, null);
                                      }}
                                    />
                                  ) : null}
                                </label>
                                <input
                                  id="uploadlogo"
                                  className="hidden"
                                  type="file"
                                  name="profile"
                                  accept="image/x-png,image/jpeg"
                                  multiple={false}
                                  onChange={(e) => {
                                    setFieldValue(`profile`, e.target.files[0]);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="mb-6">
                              <div className="flex items-center justify-between">
                                <span className="text-Neutral900 font-bold text-xs">
                                  Phone Number
                                </span>
                                <span className="text-xs font-medium italic text-Neutral700">
                                  Optional
                                </span>
                              </div>
                              <PhoneInput
                                value={values.phone_number}
                                setFieldValue={setFieldValue}
                                setFieldError={setFieldError}
                                handleBlur={handleBlur}
                                errors={errors}
                                touched={touched}
                                className="mt-2"
                              />
                            </div>

                            {/* custom fields is an array of objects and each object has a key and value */}
                            {values?.custom_fields?.map((field, index) => (
                              <div key={index}>
                                <div className="flex items-center justify-between">
                                  <span className="text-Neutral900 font-bold text-xs">
                                    {field.key}
                                  </span>
                                  <span className="text-xs font-medium italic text-Neutral700">
                                    Optional
                                  </span>
                                </div>
                                <div className="mt-2">
                                  <input
                                    className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                                    type="text"
                                    placeholder={field.key}
                                    name={`custom_fields.${index}.value`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={field.value}
                                  />
                                </div>
                              </div>
                            ))}

                            {values?.custom_fields?.length < 5 && (
                              <div className="flex items-center justify-between">
                                <button
                                  type="button"
                                  className="text-AccentRegular text-xs font-medium"
                                  onClick={() => setIsNewCustomField(true)}
                                >
                                  Add Custom Field
                                </button>
                                <span className="text-xs font-medium italic text-Neutral700">
                                  Optional
                                </span>
                              </div>
                            )}

                            {isNewCustomField && (
                              <div className="flex flex-col gap-4">
                                <div className="flex flex-col justify-end min-h-[78px]">
                                  <div className="flex items-center justify-between">
                                    <span className="text-Neutral900 font-bold text-xs">
                                      Field Title
                                    </span>
                                    <span className="text-xs font-medium italic text-Neutral700">
                                      Optional
                                    </span>
                                  </div>
                                  <div className="mt-2">
                                    <input
                                      placeholder="Eg. Address"
                                      className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-xs leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular duration-300 transition"
                                      type="text"
                                      name="key"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values?.key}
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-col justify-end min-h-[78px]">
                                  <div className="flex items-center justify-between">
                                    <span className="text-Neutral900 font-bold text-xs">
                                      Field Info
                                    </span>
                                    <span className="text-xs font-medium italic text-Neutral700">
                                      Optional
                                    </span>
                                  </div>
                                  <div className="mt-2">
                                    <input
                                      placeholder="Eg. 123, Street Name, City, State, Country"
                                      className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-xs leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular duration-300 transition"
                                      type="text"
                                      name="value"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values?.value}
                                    />
                                  </div>
                                </div>
                                <div className="mt-8">
                                  <button
                                    className="btn button-hover form-primary-btn relative text-sm text-center block text-Neutral100 bg-AccentRegular font-bold w-full py-3 rounded overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out"
                                    onClick={() => {
                                      if (values?.key && values?.value) {
                                        setFieldValue("custom_fields", [
                                          ...values?.custom_fields,
                                          {
                                            key: values?.key,
                                            value: values?.value,
                                          },
                                        ]);
                                        setIsNewCustomField(false);
                                        setFieldValue("key", "");
                                        setFieldValue("value", "");
                                      }
                                    }}
                                  >
                                    <span className="relative z-10">
                                      Add Custom Field
                                    </span>
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="mt-8">
                          <button
                            disabled={isSubmitting}
                            className="button-hover relative text-sm text-center block text-Neutral100 bg-AccentRegular font-bold w-full py-3 rounded overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out"
                          >
                            {isSubmitting ? (
                              <span className="relative z-10">
                                <LoadingIcon />
                              </span>
                            ) : (
                              <span className="relative z-10 drop-shadow-Texts uppercase">
                                Save Changes
                              </span>
                            )}
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => {
              setIsOpen(false);
              setIsNewCustomField(false);
            }}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EditLeadModal;
