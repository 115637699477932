import React, { useState } from "react";
import clx from "classnames";

import CardSubTitle from "../../../common/typography/CardSubtitle";
import NotificationBadge from "../../../common/badges/NotificationBadge";
import LightningIcon from "../../../Icons/LightningIcon";
import prepareCounter from "../../../../utils/prepareTasksAndTimeCounter";
import CommentBadge from "../../../common/badges/CommentBadge";
import { Link } from "react-router-dom";

function TasksBlock({ tasks = null, className = "", id }) {
  const [showMore, setShowMore] = useState({
    isShowMore: false,
    id: null,
  });
  if (!tasks || !Object.keys(tasks)?.length) {
    return null;
  }

  const mappedTasks = Object.keys(tasks)?.reduce((obj, task) => {
    obj[task] = prepareCounter(tasks[task]);
    return obj;
  }, {});

  return (
    <div className={clx("flex flex-col", className)}>
      <div className="flex justify-between w-full mb-[22px] border-t-[0.5px] border-Neutral300 pt-3">
        <div className="flex justify-center items-center">
          <CardSubTitle text="Tasks" className="mr-[6px]" />
          {/* <NotificationBadge number={tasks?.actual} className="mr-1" /> */}
          {tasks?.comments && <CommentBadge />}
        </div>
        <LightningIcon />
      </div>
      <div className="flex justify-between items-center w-full  relative task-divider mb-1 pr-1">
        <span className="text-[80px] text-Neutral800 leading-[60%] font-light tracking-[-0.12em]">
          {/* {mappedTasks?.total} */}
          {mappedTasks?.actual}
        </span>
        <ul>
          {Object.entries(mappedTasks)
            .filter(([key, value], index) => key !== "actual")
            .map(([key, value], index) =>
              showMore?.id === id
                ? index <
                    Object.entries(mappedTasks).filter(
                      ([key, value]) => key !== "actual"
                    ).length && (
                    <li
                      key={key}
                      className="text-base font-semibold text-Neutral800 leading-[20px] tracking-[-0.04em] flex justify-between items-baseline w-[94px] text-end"
                    >
                      <span className="block leading-[20px]">{value}</span>
                      <span className="block font-['Noto_Sans'] text-[8px] uppercase text-Neutral600 font-medium leading-[11px] tracking-[0.02em]">
                        {key}
                      </span>
                    </li>
                  )
                : index < 3 && (
                    <li
                      key={key}
                      className="text-base font-semibold text-Neutral800 leading-[20px] tracking-[-0.04em] flex justify-between items-baseline w-[94px] text-end"
                    >
                      <span className="block leading-[20px]">{value}</span>
                      <span className="block font-['Noto_Sans'] text-[8px] uppercase text-Neutral600 font-medium leading-[11px] tracking-[0.02em]">
                        {key}
                      </span>
                    </li>
                  )
            )}
        </ul>
      </div>
      {Object.keys(mappedTasks).filter((key) => key !== "actual").length >
        3 && (
        <div
          className={`flex justify-end
            ${showMore?.isShowMore && showMore?.id === id ? "hidden" : ""}
          `}
          onClick={() => {
            setShowMore({
              isShowMore: true,
              id: id,
            });
          }}
        >
          <Link
            className="block text-[8px] font-['Noto_Sans'] font-medium leading-[11px] tracking-[0.04em] text-AccentRegular uppercase p-1"
            to="#"
          >
            +
            {Object.keys(mappedTasks).filter((key) => key !== "actual").length -
              3}{" "}
            more
          </Link>
        </div>
      )}
    </div>
  );
}

export default TasksBlock;
