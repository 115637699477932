import React from "react";

function LeadListLoader() {
  return (
    <div className="5xl:px-[96px] w-full pt-0">
      <div className="flex">
        {Array.from({ length: 4 }).map((a, index) => {
          return (
            <div
              key={`1${index}`}
              className="lg:max-w-[312px] lg:w-full md:w-[48%] min-w-[312px] rounded-[6px] w-full border h-fit px-[9px] py-[12px] mr-5 mb-5 bg-Neutral000 border-Neutral300"
            >
              <div className="rounded w-3/6 bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative mb-4"></div>

              {Array.from({ length: 2 }).map((b, index) => {
                return (
                  <div
                    key={`2${index}`}
                    className="flex flex-col items-start justify-center p-4 pr-2 mx-w-[294px] border border-Neutral300 rounded-[6px] bg-Neutral000 z-1 mb-4"
                  >
                    <div className="w-[100%]">
                      <div className="flex items-center justify-start">
                        <div className="w-[36px] h-[36px] rounded-full mr-2 bg-Neutral300"></div>
                        <div className="flex flex-col">
                          <div className="w-[100px] h-[14px] bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative"></div>
                          <div className="w-[100px] h-[14px] bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative mt-2"></div>
                        </div>
                      </div>
                      <div className="mt-4">
                        {Array.from({ length: 3 }).map((c, index) => {
                          return (
                            <div
                              className="flex items-center justify-start mb-2"
                              key={index}
                            >
                              <div className="w-[14px] h-[14px] rounded-full mr-2 bg-Neutral300"></div>
                              <div className="w-[100px] h-[14px] bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative"></div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default LeadListLoader;
