const initialState = {
  members: {
    isLoading: true,
    data: [],
  },
};
const memberReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_MEMBERS_LIST":
      return {
        ...state,
        members: {
          ...state.members,
          ...payload,
        },
      };
    case "REMOVE_TEAM_MEMBER_SUCCESS":
      return {
        ...state,
        members: {
          ...state.members,
          data: payload.data,
        },
      };
    case "MEMBER_CREATE_SUCCESS":
      let tempMembers = state.members.data;
      tempMembers = [...tempMembers, payload.data];
      return {
        ...state,
        members: {
          ...state.members,
          data: tempMembers,
        },
      };
    case "UPDATE_TEAM_MEMBER_ROLE_SUCCESS":
      return {
        ...state,
        members: {
          ...state.members,
          data: payload.data,
        },
      };
    default:
      return state;
  }
};

export default memberReducer;
