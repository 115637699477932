import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { CreateNewScreenAction } from "../../store/actions/projectAction";
import { CreateNewScreenClientAction } from "../../store/actions/clientAction";

const ScrrenButton = ({
  types,
  project,
  isGeneral,
  setIsGeneral,
  clientId,
  projectId,
  clientDetail,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const titleDropDownref = useRef(null);
  const [customeTitle, setCustomeTitle] = useState("");
  const [titledata, setTitleData] = useState(
    types === "task" ? project?.task_screen : clientDetail?.service_screen
  );
  return (
    <Menu
      as="div"
      className="inline-block text-left z-20"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Menu.Button
        ref={titleDropDownref}
        className="uppercase text-Neutral700 font-medium text-xs bg-Neutral200 rounded mr-[8px] cursor-pointer flex px-3 py-2 leading-[16.3px] tracking-wider text-center items-center justify-center 5xl:h-8 5xl:text-sm border border-Neutral200"
      >
        {isGeneral}
        <svg
          width="11"
          height="8"
          viewBox="0 0 11 8"
          fill="none"
          className="ml-1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.324856 1.63059C-0.206125 0.977077 0.258936 0 1.10097 0H9.89903C10.7411 0 11.2061 0.977078 10.6751 1.63059L6.27611 7.04478C5.87592 7.53733 5.12408 7.53733 4.72389 7.04478L0.324856 1.63059Z"
            fill="#9C9CA6"
          />
        </svg>
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute z-[999] sm:right-3 top-10 w-[180px] pt-4 pb-2 px-2 rounded-lg Timetrackingdropdown bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none">
          <div className="">
            <input
              className="font-normal text-xs placeholder:text-Neutral600 text-Neutral700 px-2 focus:outline-none w-full mb-2"
              placeholder="+Add New Category"
              value={customeTitle}
              onKeyUp={(e) => {
                e.stopPropagation();
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
                if (e.key === "Enter") {
                  if (
                    titledata?.filter((t) => t === customeTitle).length === 0
                  ) {
                    setTitleData([...titledata, customeTitle]);
                    setCustomeTitle("");
                    setTimeout(() => {
                      titleDropDownref?.current?.click();
                    }, 0);
                  } else {
                    toast.error("Title already exists");
                  }
                }
              }}
              onChange={(e) => {
                setCustomeTitle(e.target.value.toUpperCase());
              }}
            />
          </div>
          <div className="project-title-divide">
            <div className="overflow-hidden border-0 h-[100%] overflow-y-auto currancy-scrollbar scrollbar-w-4 relative">
              {titledata
                ?.filter((title) =>
                  title.toLowerCase().startsWith(customeTitle.toLowerCase())
                )
                .map((items, index) => {
                  return (
                    <div
                      key={index}
                      className="mt-2 flex items-center hover:bg-Neutral200 p-2 rounded cursor-pointer group duration-300"
                      onClick={(e) => {
                        setIsGeneral(items);
                        setTimeout(() => {
                          titleDropDownref?.current?.click();
                          e.stopPropagation();
                        }, 0);
                      }}
                    >
                      <p className="text-Neutral800 group-hover:text-Neutral900 font-normal text-xs w-full rounded cursor-pointer">
                        {items}
                      </p>
                    </div>
                  );
                })}
            </div>
            {customeTitle && customeTitle !== "" && (
              <div
                className="bottom-0 flex items-center bg-AccentLight p-2 rounded duration-300 w-[200px] cursor-pointer"
                type="button"
                onClick={(e) => {
                  if (
                    titledata?.filter((t) => t === customeTitle).length === 0
                  ) {
                    setTitleData([...titledata, customeTitle]);
                    setIsGeneral(customeTitle);
                    if (types === "task") {
                      dispatch(
                        CreateNewScreenAction(
                          {
                            title: customeTitle,
                            id: clientId,
                            type: types,
                            project: projectId,
                          },
                          navigate
                        )
                      );
                    }
                    if (types === "service") {
                      dispatch(
                        CreateNewScreenClientAction(
                          {
                            title: customeTitle,
                            id: clientId,
                            type: types,
                          },
                          navigate
                        )
                      );
                    }

                    setCustomeTitle("");
                    setTimeout(() => {
                      titleDropDownref?.current?.click();
                    }, 0);
                  } else {
                    toast.error("Title already exists");
                  }
                }}
              >
                <div className="flex items-center">
                  <p className="text-AccentDark font-normal text-xs rounded cursor-pointer">
                    Create
                  </p>
                  <p className="text-white ml-[6px] py-[6px] px-2 rounded font-normal text-xs bg-AccentRegular">
                    {customeTitle}
                  </p>
                </div>
              </div>
            )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ScrrenButton;
