import { useEffect, useRef } from "react";

import { hexToRgba } from "../utils/hexToRGBA";

export const useAccentWithAlpha = (alpha = 1, fallbackColor = "#D3D3F5") => {
  const color = useRef(
    hexToRgba(
      document.documentElement.style.getPropertyValue("--accent-color"),
      alpha
    ) || fallbackColor
  );

  useEffect(() => {
    color.current =
      hexToRgba(
        document.documentElement.style.getPropertyValue("--accent-color"),
        alpha
      ) || fallbackColor;
  }, [alpha, fallbackColor]);

  return color.current;
};

export const useBackgroundWithAlpha = (
  alpha = 1,
  fallbackColor = "#D3D3F5"
) => {
  const color = useRef(
    hexToRgba(
      document.documentElement.style.getPropertyValue("--bg-color"),
      alpha
    ) || fallbackColor
  );

  useEffect(() => {
    color.current =
      hexToRgba(
        document.documentElement.style.getPropertyValue("--bg-color"),
        alpha
      ) || fallbackColor;
  }, [alpha, fallbackColor]);

  return color.current;
};
