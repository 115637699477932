import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SigninBg from "../../assets/Images/signin-bg-shap.png";
import Logo from "../../assets/Images/Logo.svg";
import PasswordIcon from "../../assets/Images/password.svg";
// import BlueRightTik from "../../assets/Images/blue-right-tik.svg";
import { ResetPasswordAction } from "../../store/actions/authAction";
import { ResetCollabPasswordAction } from "../../store/actions/collabAuthAction";
import { useDispatch } from "react-redux";
import PasswordShowIcon from "../../assets/Images/password-show.svg";
import PasswordStrengthBar from "react-password-strength-bar";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("New Password is Required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\-])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirm_password: Yup.string()
    .required("Confirm Password is Required")
    .min(8, "Min 8 character required")
    .oneOf(
      [Yup.ref("password")],
      "Confirm Password must match with new password"
    ),
  token: Yup.string().required("Token is Required"),
});

function ResetPassword() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const [token, setToken] = useState(null);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const signInPath = location.pathname.startsWith("/client-collab");
  const [confirm_passwordShown, setconfirm_passwordShown] = useState(false);
  const toggleconfirm_passwordVisiblity = () => {
    setconfirm_passwordShown(confirm_passwordShown ? false : true);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const queryToken = params.get("q");
    if (!queryToken) {
      toast.error("Token not provided");
      if (signInPath) {
        navigate("/client-collab/signin");
      } else {
        navigate("/signin");
      }
    } else {
      setToken(queryToken);
    }
  }, []);

  return (
    <section>
      <div className="flex">
        <div className="w-full max-w-[50%] sm:h-screen h-full relative md:block hidden">
          <div className="absolute top-[50%] w-full  5xl:max-w-[440px] max-w-[384px]	left-[50%] -translate-x-1/2 -translate-y-1/2 ml:px-0 px-3">
            <div className="flex items-center">
              <img src={Logo} alt="logo" />
              <h2 className="5xl:text-[60px] lg:text-[56px] text-[40px] font-black w-full max-w-[260px] leading-[0.85] text-Neutral000 ml-4">
                Client Manager
              </h2>
            </div>
            <p className="text-Neutral100 5xl:text-[18px] text-[16px] mt-3">
              The Simple Client Onboarding & Management Tool
            </p>
          </div>
          <img
            className="w-full object-cover h-screen"
            src={SigninBg}
            alt="background"
          />
        </div>
        <div className="w-full md:max-w-[50%] max-w-full md:h-screen h-full flex md:flex-row flex-col 2xl:items-center justify-center relative px-3 overflow-y-auto">
          <div className="w-full max-w-[320px] 5xl:max-w-[400px] md:mt-0 mt-12 2xl:pt-0 md:pt-24 md:mx-0 mx-auto">
            <img className="w-full max-w-[32px]" src={Logo} alt="logo" />
            <h1 className="mt-3 text-[32px] 5xl:text-[36px] font-bold mb-3 text-Neutral900 2xs:whitespace-nowrap tracking-[-0.32px]">
              Reset Password
            </h1>
            <p className="text-Neutral800 5xl:text-[16px] text-xs mb-7 ">
              Create and confirm a new, strong password. Resetting will override
              your old password.
            </p>
            <Formik
              initialValues={{
                password: "",
                confirm_password: "",
                token: token,
              }}
              enableReinitialize
              validationSchema={ResetPasswordSchema}
              onSubmit={(values, { setSubmitting, setErrors }) => {
                if (signInPath) {
                  dispatch(
                    ResetCollabPasswordAction(
                      values,
                      setSubmitting,
                      setErrors,
                      navigate
                    )
                  );
                } else {
                  dispatch(
                    ResetPasswordAction(
                      values,
                      setSubmitting,
                      setErrors,
                      navigate
                    )
                  );
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="">
                    <label className="text-Neutral900 font-bold text-xs">
                      New Password
                    </label>
                    <div className="relative passwordshow mt-2">
                      <input
                        className={`placeholder:text-Neutral500 placeholder:font-normal py-3 px-4 text-sm leading-5 w-full focus:outline-none text-Neutral800 font-medium border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow  transition duration-300 ease-in-out ${
                          errors.password && touched.password
                            ? "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow"
                            : ""
                        }`}
                        type={passwordShown ? "text" : "password"}
                        placeholder="Eg. very_good_password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                      />
                      <img
                        className="w-full absolute right-[12px] top-[8px] max-w-[32px] cursor-pointer"
                        src={passwordShown ? PasswordShowIcon : PasswordIcon}
                        alt="PasswordIcon"
                        onClick={togglePasswordVisiblity}
                      />
                      {errors.password && touched.password && (
                        <p className="mt-2 text-xs text-Red400 font-medium focus-visible:outline-none ">
                          {errors.password}
                        </p>
                      )}
                      {values.password && values.password.length && (
                        <PasswordStrengthBar
                          className="strength-bar duration-300 transition mt-2 pb-3 px-1"
                          scoreWordStyle={{ color: "#757580", display: "none" }}
                          scoreWordClassName="strength-word"
                          password={values.password}
                          barColors={[
                            "#DFDFE5",
                            "#6D6DF2",
                            "#6D6DF2",
                            "#6D6DF2",
                            "#6D6DF2",
                          ]}
                          scoreWords={[
                            "Password Strength",
                            "Password Strength",
                            "Password Strength",
                            "Password Strength",
                            "Password Strength",
                          ]}
                          shortScoreWord="Password Strength"
                        />
                      )}
                    </div>
                  </div>
                  <div className="mb-4">
                    <label className="text-Neutral900 font-bold text-xs">
                      Confirm Password{" "}
                    </label>
                    <div className="relative passwordshow mt-2">
                      <input
                        className={`placeholder:text-Neutral500 placeholder:font-normal py-3 px-4 text-sm leading-5 w-full focus:outline-none text-Neutral800 font-medium border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow  transition duration-300 ease-in-out ${
                          errors.confirm_password && touched.confirm_password
                            ? "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow"
                            : ""
                        }`}
                        type={confirm_passwordShown ? "text" : "password"}
                        placeholder="Eg. very_good_password"
                        name="confirm_password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirm_password}
                      />
                      <img
                        className="w-full absolute right-[12px] top-[8px] max-w-[32px] cursor-pointer"
                        src={
                          confirm_passwordShown
                            ? PasswordShowIcon
                            : PasswordIcon
                        }
                        alt="PasswordIcon"
                        onClick={toggleconfirm_passwordVisiblity}
                      />
                      {errors.confirm_password && touched.confirm_password && (
                        <p className="mt-2 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                          {errors.confirm_password}
                        </p>
                      )}
                    </div>
                  </div>
                  {errors.token && touched.token && (
                    <p className="mt-2 text-xs text-Red400 font-medium focus-visible:outline-none ">
                      {errors.token}
                    </p>
                  )}
                  <div className="mt-10 2xl:pb-0 md:pb-16 pb-10">
                    {/* <button className='btn button-hover form-primary-btn relative text-sm text-center block text-Neutral100 bg-AccentRegular font-bold w-full py-4 rounded drop-shadow-Texts overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out'><span className="relative z-10" >Reset Password</span></button> */}
                    {/* <button className="text-sm text-center block text-Neutral500 bg-Neutral300 font-bold w-full py-4 rounded overflow-hidden transition duration-300 ease-in-out">
                    Reset Password
                    </button> */}
                    <button
                      disabled={isSubmitting}
                      className={`relative text-sm text-center block text-Neutral100 ${
                        isSubmitting
                          ? "bg-Neutral300 focus-visible:outline-AccentLight text-Neutral500"
                          : "bg-AccentRegular text-Neutral100 form-primary-btn btn"
                      } font-bold w-full py-4 rounded drop-shadow-Texts overflow-hidden transition duration-300 ease-in-out`}
                    >
                      <span
                        className={`${
                          isSubmitting
                            ? "relative z-10 text-Neutral500"
                            : "text-Neutral100"
                        }`}
                      >
                        Reset Password
                      </span>
                    </button>
                  </div>

                  {/* Blue login continue text */}
                  {/* <Link to="#0" className="py-3 px-4 mt-2 rounded bg-AccentLight text-AccentMediumDark text-xs flex w-fit font-medium">
                  <img src={BlueRightTik} alt="" className="mr-3" /> Password reset. Please login to continue.
                </Link> */}

                  {/* red error text */}
                  {/* <div>
                <Link to="#0" className="py-3 px-4 mt-2 rounded bg-Red100 text-Red900 text-xs flex w-fit font-medium"> Oops. Something went wrong. Please try again. </Link>
              </div> */}
                </form>
              )}
            </Formik>
          </div>
          {!signInPath && (
            <div className="md:absolute md:top-2 3xl:right-[7%] ml:right-8 right-[6%] md:mb-0 mb-10 w-full mx-auto max-w-[248px] 5xl:max-w-[300px]">
              <div className="4xl:flex ml:block flex  text-right items-center md:justify-end justify-center 5xl:mt-4">
                <p className="text-xs text-Neutral700 font-medium 4xl:mr-[24px] ml:mr-0 mr-[24px] mb-0">
                  Don’t have an account?
                </p>
                <Link
                  to="/signup"
                  className="text-xs text-center inline-block text-AccentRegular font-[600] 4xl:mt-0 ml:mt-2 mt-0 py-1 px-2 rounded border-[1.5px] border-Neutral300 hover:border-AccentRegular focus-visible:outline focus-visible:outline-4 focus-visible:outline-AccentLight transition duration-300 ease-in-out"
                >
                  Sign Up
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default ResetPassword;
