import React from "react";

function ShareIcon({ width = 24, className = "" }) {
  return (
    <svg
      width={width}
      height={width}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7502 8.1C18.1585 8.1 19.3002 6.95833 19.3002 5.55C19.3002 4.14167 18.1585 3 16.7502 3C15.3419 3 14.2002 4.14167 14.2002 5.55C14.2002 6.95833 15.3419 8.1 16.7502 8.1Z"
        stroke="#5A5A66"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.55 14.0492C7.95833 14.0492 9.1 12.9075 9.1 11.4992C9.1 10.0909 7.95833 8.94922 6.55 8.94922C5.14167 8.94922 4 10.0909 4 11.4992C4 12.9075 5.14167 14.0492 6.55 14.0492Z"
        stroke="#5A5A66"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7502 20.0004C18.1585 20.0004 19.3002 18.8587 19.3002 17.4504C19.3002 16.0421 18.1585 14.9004 16.7502 14.9004C15.3419 14.9004 14.2002 16.0421 14.2002 17.4504C14.2002 18.8587 15.3419 20.0004 16.7502 20.0004Z"
        stroke="#5A5A66"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75195 12.7832L14.5575 16.1662"
        stroke="#5A5A66"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.549 6.83301L8.75195 10.216"
        stroke="#5A5A66"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ShareIcon;
