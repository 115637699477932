import { toast } from "react-toastify";

export const OpenModalAction = (name) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "OPEN_MODAL",
        payload: { name },
      });
    } catch (error) {
      toast.error(error?.message);
    }
  };
};

export const CloseModalAction = (name) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "CLOSE_MODAL",
        payload: { name },
      });
    } catch (error) {
      toast.error(error?.message);
    }
  };
};
