import { Menu, Transition } from "@headlessui/react";
import { Form, Formik } from "formik";
import React, { Fragment, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import * as Yup from "yup";

import CloseBtn from "../../assets/Images/close-btn.svg";
import { CreateQuestion } from "../../store/actions/questionnaireAction";
import { CreateTemplateQuestionnaireQuestion } from "../../store/actions/templateAction";
import DeleteIcon from "../Icons/DeleteIcon";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
    overflow: "none",
  },
};

const addQuestionnaireValidation = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  placeholder: Yup.string().when("type", {
    is: (val) => val === "text",
    then: Yup.string().required("Placeholder text is required"),
  }),
  question: Yup.string().required("Question Description is required"),
  type: Yup.string().required("Select type"),
  radio_options: Yup.array().when("type", {
    is: (val) => val === "radio",
    then: Yup.array()
      .min(2, "Minimum 2 options required")
      .of(
        Yup.object().shape({
          name: Yup.string().required("Option name is required"),
          value: Yup.string().required("Option value is required"),
        })
      ),
  }),
  checkbox_options: Yup.array().when("type", {
    is: (val) => val === "checkbox",
    then: Yup.array()
      .min(2, "Minimum 2 options required")
      .of(
        Yup.object().shape({
          name: Yup.string().required("Option name is required"),
          value: Yup.string().required("Option value is required"),
        })
      ),
  }),
});

const typeList = [
  { id: 1, name: "Text", value: "text" },
  { id: 2, name: "File", value: "file" },
  { id: 3, name: "Image", value: "image" },
  { id: 4, name: "Files", value: "files" },
  { id: 5, name: "Radio", value: "radio" },
  { id: 6, name: "Checkbox", value: "checkbox" },
  // { id: 5, name: 'Profile', value: 'profile' },
];

const AddQuestionnaireModal = ({
  isOpenQuestionnaire,
  setIsOpenQuestionnaire,
  questionnaire,
  formQuestions,
  setformQuestions,
  leadId = null,
  is_template = false,
}) => {
  const { clientId } = useParams();
  const dispatch = useDispatch();
  const roleref = useRef(null);
  const params = useParams();
  const [profileEnable, setProfileEnable] = useState(false);

  useEffect(() => {
    let getProfile;
    if (questionnaire?.data?._id) {
      getProfile = questionnaire?.data?.questions?.find(
        (item) => item?.type === "profile"
      );
    } else {
      getProfile = formQuestions?.find((item) => item?.type === "profile");
    }
    setProfileEnable(Boolean(getProfile));
  }, [formQuestions, questionnaire?.data?._id, questionnaire?.data?.questions]);

  return (
    <Modal
      isOpen={isOpenQuestionnaire}
      onRequestClose={() => setIsOpenQuestionnaire(false)}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="Add Questionnaire Modal"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[560px] sm:min-w-[480px] w-[85vw] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="bg-white px-6 md:px-12 py-8 rounded-[8px]">
          {" "}
          <div>
            <div className="text-left">
              <h3
                className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-8"
                id="modal-title"
              >
                Add Question
              </h3>
              <Formik
                initialValues={{
                  title: "",
                  placeholder: "",
                  question: "",
                  isRequired: false,
                  type: "",
                  radio_options: [
                    {
                      name: "",
                      value: "",
                      is_selected: false,
                    },
                  ],
                  checkbox_options: [
                    {
                      name: "",
                      value: "",
                      is_selected: false,
                    },
                  ],
                }}
                enableReinitialize
                validationSchema={addQuestionnaireValidation}
                onSubmit={(values, { setSubmitting, setErrors }) => {
                  setSubmitting(true);
                  if (is_template) {
                    dispatch(
                      CreateTemplateQuestionnaireQuestion(
                        {
                          id: params?.id,
                          title: values?.title,
                          question: values?.question,
                          placeholder: values?.placeholder,
                          type: values?.type,
                          isRequired: values?.isRequired,
                          radio_options:
                            values?.type === "radio"
                              ? values?.radio_options
                              : [],
                          checkbox_options:
                            values?.type === "checkbox"
                              ? values?.checkbox_options
                              : [],
                        },
                        setIsOpenQuestionnaire,
                        setSubmitting
                      )
                    );
                  } else {
                    const newQuestionnaireDetail = {
                      id: formQuestions.length,
                      title: values?.title,
                      placeholder: values?.placeholder,
                      question: values?.question,
                      type: values?.type,
                      isRequired: values?.isRequired,
                      radio_options:
                        values?.type === "radio" ? values?.radio_options : [],
                      checkbox_options:
                        values?.type === "checkbox"
                          ? values?.checkbox_options
                          : [],
                    };
                    if (questionnaire?.data?._id) {
                      const newPayload = {
                        id: questionnaire?.data?._id,
                        title: questionnaire?.data?.title,
                        client_id: clientId,
                        questions: newQuestionnaireDetail,
                        lead: leadId,
                        is_lead: leadId ? true : false,
                      };
                      dispatch(
                        CreateQuestion(
                          newPayload,
                          setSubmitting,
                          setErrors,
                          setIsOpenQuestionnaire
                        )
                      );
                    } else {
                      setformQuestions([
                        ...formQuestions,
                        newQuestionnaireDetail,
                      ]);
                      setSubmitting(false);
                      setIsOpenQuestionnaire(false);
                    }
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="mb-6">
                      <label className="text-xs font-bold text-Neutral900">
                        Title
                      </label>
                      <div className="mt-2">
                        <input
                          className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                          type="text"
                          placeholder="Enter Title"
                          name="title"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.title}
                        />
                        {errors.title && touched.title && (
                          <p className="absolute mt-1 text-xs font-medium text-Red400 focus-visible:outline-none">
                            {errors.title}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mb-6">
                      <label className="text-xs font-bold text-Neutral900">
                        Description
                      </label>
                      <div className="mt-2">
                        <input
                          className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                          type="text"
                          placeholder="Enter Question Description"
                          name="question"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.question}
                        />
                        {errors.question && touched.question && (
                          <p className="absolute mt-1 text-xs font-medium text-Red400 focus-visible:outline-none">
                            {errors.question}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mb-6">
                      <label className="text-xs font-bold text-Neutral900">
                        Placeholder Text
                      </label>
                      <div className="mt-2">
                        <input
                          className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                          type="text"
                          placeholder="Enter Placeholder Text"
                          name="placeholder"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.placeholder}
                        />
                        {errors.placeholder && touched.placeholder && (
                          <p className="absolute mt-1 text-xs font-medium text-Red400 focus-visible:outline-none">
                            {errors.placeholder}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="flex items-center">
                      <div className="w-1/2 mr-4">
                        <label className="text-xs font-bold text-Neutral900">
                          Type
                        </label>
                        <div className="mt-2 z-[99] relative">
                          <Menu
                            as="div"
                            className="relative inline-block w-full text-left"
                          >
                            <Menu.Button
                              ref={roleref}
                              className="rounded-[4px] client-name bg-Neutral200 inline-flex w-full focus-visible:outline-none popup-btn relative px-[16px] pb-[10px] pt-[10px]"
                            >
                              <span className="focus-visible:outline-none">
                                <div className="members-text flex flex-wrap text-[14px] items-center text-Neutral800 font-medium">
                                  {values?.type
                                    ? values?.type.charAt(0).toUpperCase() +
                                      values?.type.slice(1)
                                    : "Select type..."}
                                  <svg
                                    className="absolute right-[16px]"
                                    width="11"
                                    height="8"
                                    viewBox="0 0 11 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M0.324856 1.63059C-0.206125 0.977077 0.258936 0 1.10097 0H9.89903C10.7411 0 11.2061 0.977078 10.6751 1.63059L6.27611 7.04478C5.87592 7.53733 5.12408 7.53733 4.72389 7.04478L0.324856 1.63059Z"
                                      fill="#5A5A66"
                                    />
                                  </svg>
                                </div>
                              </span>
                            </Menu.Button>
                            {errors?.type && touched?.type && (
                              <p className="absolute mt-1 text-xs font-medium text-Red400 focus-visible:outline-none">
                                {errors?.type}
                              </p>
                            )}
                            <Transition as={Fragment}>
                              <Menu.Items className="box shadow-[0px_30px_40px_rgba(0,0,0,0.2)] max-h-[20vh] overflow-auto z-50 absolute top-[41px] right-0 bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none w-full pb-4 px-4 rounded-[0px_0px_4px_4px]">
                                <div className="h-full bg-white">
                                  <p className="text-Neutral600 text-[10px] font-semibold pt-[9px] pb-[12px] border-t border-Neutral200">
                                    Choose Type
                                  </p>
                                  <div>
                                    {typeList?.map((item) => {
                                      return (
                                        <div
                                          key={item?.id}
                                          className="flex items-center rounded-[2px] py-[6px] hover:bg-Neutral200 ml-[-8px] pl-[10px] cursor-pointer"
                                          onClick={() => {
                                            if (item?.value !== "profile") {
                                              setFieldValue(
                                                "type",
                                                item?.value
                                              );
                                              setTimeout(() => {
                                                roleref?.current?.click();
                                              }, 0);
                                            } else {
                                              if (!profileEnable) {
                                                setFieldValue(
                                                  "type",
                                                  item?.value
                                                );
                                                setTimeout(() => {
                                                  roleref?.current?.click();
                                                }, 0);
                                              }
                                            }
                                          }}
                                          onBlur={handleBlur}
                                        >
                                          <p className="text-sm font-normal text-Neutral800">
                                            {item?.name}
                                          </p>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>
                      </div>
                      <div className="w-1/2 mr-4">
                        <label className="text-xs font-bold text-Neutral900">
                          Required
                        </label>
                        <div className="mt-2 z-[99] relative">
                          <label className="flex items-center cursor-pointer select-none toggle-switch">
                            <div className="relative">
                              <input
                                type="checkbox"
                                name="isRequired"
                                className="sr-only"
                                checked={values.isRequired}
                                onChange={handleChange}
                              />
                              <div className="block h-[20px] switch w-[40px] rounded-full border-Neutral400 border-2 bg-Neutral100"></div>
                              <div className="dot absolute left-0 top-0 h-5 w-5 rounded-full bg-Neutral800 transition shadow-[0px_1.5px_3px_1px_rgba(0,0,0,0.2)]"></div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* Radio setion & Checkbox section */}
                    {values?.type === "radio" || values?.type === "checkbox" ? (
                      <div className="mt-6">
                        <label className="text-xs font-bold text-Neutral900">
                          {values?.type?.charAt(0).toUpperCase() +
                            values?.type?.slice(1)}{" "}
                          Options
                        </label>
                        {!Array.isArray(errors?.[`${values?.type}_options`]) &&
                          touched?.[`${values?.type}_options`] && (
                            <p className="mt-1 text-xs font-medium text-Red400 focus-visible:outline-none">
                              {errors?.[`${values?.type}_options`]}
                            </p>
                          )}

                        <div className="mt-2">
                          <div className="max-h-[15vh] overflow-y-auto scrollbar-w-4">
                            {values?.[`${values?.type}_options`]?.map(
                              (item, index) => {
                                return (
                                  <div key={index} className="flex w-full mb-2">
                                    <div className="w-full">
                                      <input
                                        className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                                        type="text"
                                        placeholder="Enter Option"
                                        name={`${values?.type}_options[${index}].name`}
                                        onChange={(e) => {
                                          // Update both name and value fields
                                          setFieldValue(
                                            `${values.type}_options[${index}].name`,
                                            e.target.value
                                          );
                                          setFieldValue(
                                            `${values.type}_options[${index}].value`,
                                            `${e.target.value}-${index}`
                                          );
                                        }}
                                        onBlur={handleBlur}
                                        value={item?.name}
                                      />
                                      {errors?.[`${values?.type}_options`]?.[
                                        index
                                      ]?.name &&
                                        touched?.[`${values?.type}_options`]?.[
                                          index
                                        ]?.name && (
                                          <p className="mt-1 text-xs font-medium text-Red400 focus-visible:outline-none">
                                            {
                                              errors?.[
                                                `${values?.type}_options`
                                              ]?.[index]?.name
                                            }
                                          </p>
                                        )}
                                    </div>

                                    {values?.[`${values?.type}_options`]
                                      ?.length > 1 ? (
                                      <div title="delete option">
                                        <button
                                          className="w-full px-2 py-3 text-sm font-medium leading-5 transition duration-300 text-Neutral800 placeholder:text-Neutral500 placeholder:text-sm 5xl:placeholder:text-lg focus:outline-none focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow"
                                          type="button"
                                          onClick={() => {
                                            const newOptions = values?.[
                                              `${values?.type}_options`
                                            ]?.filter((item, i) => i !== index);
                                            setFieldValue(
                                              `${values?.type}_options`,
                                              newOptions
                                            );
                                          }}
                                        >
                                          <span className="relative z-10">
                                            <DeleteIcon color="red" />
                                          </span>
                                        </button>
                                      </div>
                                    ) : null}
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <div className="mt-2">
                            <button
                              className="w-full text-sm font-medium leading-5 transition duration-300 text-AccentRegular placeholder:text-Neutral500 placeholder:text-sm 5xl:placeholder:text-lg focus:outline-none focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow"
                              onClick={() => {
                                setFieldValue(
                                  `${values?.type}_options`,
                                  [
                                    ...values?.[`${values?.type}_options`],
                                    {
                                      name: "",
                                      value: "",
                                      is_selected: false,
                                    },
                                  ],
                                  false
                                );
                              }}
                            >
                              <span className="relative z-10">
                                + Add Options
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div className="mt-8">
                      <button
                        disabled={isSubmitting}
                        className="relative block w-full py-3 overflow-hidden text-sm font-bold text-center transition duration-300 ease-in-out rounded btn button-hover form-primary-btn text-Neutral100 bg-AccentRegular focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight"
                      >
                        {isSubmitting ? (
                          <span className="relative z-10">
                            <svg
                              className="w-5 h-5 m-auto text-white animate-spin"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          </span>
                        ) : (
                          <span className="relative z-10 drop-shadow-Texts">
                            ADD
                          </span>
                        )}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => setIsOpenQuestionnaire(false)}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
};

export default AddQuestionnaireModal;
