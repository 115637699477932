const DEFAULT_CATEGORIES_LENGTH = 3;

const prepareCategories = (categories = []) => {
  if (!categories?.length) {
    return ["no category"];
  }

  if (categories?.length > DEFAULT_CATEGORIES_LENGTH) {
    const count = categories.length - DEFAULT_CATEGORIES_LENGTH;
    return [...categories.slice(0, DEFAULT_CATEGORIES_LENGTH), `+${count}`];
  }

  return categories;
};

export default prepareCategories;
