import React from "react";

const ClientAssetsManagementLoader = () => {
  return (
    <div className="flex justify-between overflow-visible gap-x-[22px]">
      {[...Array(4)].map((e, i) => (
        <div
          key={i}
          className="w-full min-w-[60vw] sm:min-w-[320px] px-[22px] py-6 rounded shadow-[0_4px_15px_rgba(0,0,0,0.08)] block relative bg-Neutral000 h-[219px]"
        >
          <div class="flex items-center justify-between mb-4 pr-3">
            <div class="rounded w-2/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
            <div class="w-8 h-8 rounded-full bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
          </div>
          <p class="w-full 6xl:max-w-[25rem] 5xl:max-w-[20rem] max-w-[15.8125rem] text-Neutral800 text-sm font-normal mb-2">
            <div class="w-5/6 rounded mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
            <div class="w-4/6 rounded mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
            <div class="w-3/6 rounded mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
          </p>
          <div class="flex absolute bottom-[1rem]">
            <div class="rounded w-[340px] h-[40px] bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative mr-3"></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ClientAssetsManagementLoader;
