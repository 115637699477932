const initialState = {
  notificationsList: [],
  notificationsPage: {
    isLoading: true,
    is_called: false,
    data: {
      data: [],
      totalRecord: 0,
      perPage: 10,
      totalPage: 0,
      currentPage: 0,
      fromRecord: 0,
      hasPrevPage: false,
      hasNextPage: false,
    },
  },
};
const notificationsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_NOTIFICATION_LIST":
      return {
        ...state,
        notificationsList: payload,
      };
    case "SET_NOTIFICATION_PAGE":
      return {
        ...state,
        notificationsPage: {
          ...state.notificationsPage,
          ...payload,
        },
      };
    case "RESET_NOTIFICATION_LIST":
      return {
        ...state,
        notificationsList: payload,
      };
    case "SET_NEW_NOTIFICATION_LIST":
      const tempPush = [...state.notificationsList, payload];
      const sortNotification = tempPush.sort((a, b) =>
        a?.createdAt < b?.createdAt ? 1 : -1
      );
      return {
        ...state,
        notificationsList: sortNotification,
      };
    default:
      return state;
  }
};

export default notificationsReducer;
