import { Menu, Transition } from "@headlessui/react";
import { Form, Formik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import Contract from "../../assets/Images/Contract.svg";
import InvoiceWhite from "../../assets/Images/Invoice-white.svg";
import Misc from "../../assets/Images/Misc.svg";
import Report from "../../assets/Images/Report.svg";
import Visible from "../../assets/Images/Visible.svg";
import CloseBlueIcon from "../../assets/Images/close-blue-icon.svg";
import CloseBtn from "../../assets/Images/close-btn.svg";
import DeletIcon from "../../assets/Images/delet-icon.svg";
import UploadPDF from "../../components/Buttons/UploadPDF";
import DocumentsListLoader from "../../components/ContentLoaders/DocumentsListLoader";
import ContractsNoDataContent from "../../components/Folders/ContractsNoDataContent.jsx";
import DocumentBox from "../../components/Folders/DocumentBox.jsx";
import InvoiceNoDataContent from "../../components/Folders/InvoiceNoDataContent.jsx";
import MiscNoDataContent from "../../components/Folders/MiscNoDataContent.jsx";
import NewFolderModal from "../../components/Folders/NewFolderModal.jsx";
import ReportsNoDataContent from "../../components/Folders/ReportsNoDataContent.jsx";
import DeleteIcon from "../../components/Icons/FolderIcons/DeleteIcon.js";
import DownArrowIcon from "../../components/Icons/FolderIcons/DownArrowIcon.js";
import EditIcon from "../../components/Icons/FolderIcons/EditIcon.js";
import ExternalLinkIcon from "../../components/Icons/FolderIcons/ExternalLinkIcon.js";
import FolderIcon from "../../components/Icons/FolderIcons/FolderIcon.js";
import FolderOutlineIcon from "../../components/Icons/FolderIcons/FolderOutlineIcon.js";
import ViewIcon from "../../components/Icons/FolderIcons/ViewIcon.js";
import ThreeDotsIcon from "../../components/Icons/ThreeDotsIcon";
import OpenInvoiceModal from "../../components/Modals/OpenInvoiceModal";
import { NewEntityTemplateModal } from "../../components/Modals/Templates/NewEntityTemplateModal";
import NewTemplateModal from "../../components/Modals/Templates/NewTemplateModal";
import { SelectTemplate } from "../../components/Modals/Templates/SelectTemplate";
import UploadExistingInvoiceModal from "../../components/Modals/UploadExistingInvoiceModal";
import UploadExistingInvoiceSpecificClientModal from "../../components/Modals/UploadExistingInvoiceSpecificClientModal";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import { useNewTemplateModal } from "../../hooks/useNewTemplateModal";
import {
  ClientListWithNoProposal,
  GetClientDetailAction,
} from "../../store/actions/clientAction";
import {
  DeleteDocumentsAction,
  DeleteDocumentsContratsAction,
  GetDocumentActions,
  GetDocumentContractActions,
  UploadExistingContract,
  updateExistingContract,
} from "../../store/actions/documentsAction";
import {
  createFolder,
  deleteFolder,
  getFolderByClient,
  updateFolder,
  updateMultipleFolders,
} from "../../store/actions/folderAction.js";
import {
  deleteInvoice,
  getInvoiceListByClientId,
} from "../../store/actions/invoicesAction";

import MessageIcon from "../../components/Icons/newicons/_MessageIcon.js";
import FollowUpEmailModal from "../../components/Modals/FollowUpEmailModal.jsx";
import {
  getCategoryFromType,
  getExtension,
  getStyleForCategory,
} from "../../utils/folders.js";
import { Portal } from "../../components/Portal.js";
import useMenu from "../../hooks/useMenu.js";
import ReactTooltip from "react-tooltip";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const ClientDocuments = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clientId } = useParams();

  const clientDetail = useSelector((state) => state.client.clientDetail);
  const client = useSelector((state) => state.client.clientListWithNoProposal);
  const documentDetail = useSelector((state) => state.document.documents);
  const invoices = useSelector((state) => state.invoice.invoiceList);
  const { data: allFoldersData } = useSelector((state) => state.folders);
  const contracts = documentDetail?.data?.contract;
  const reports = documentDetail?.data?.reports;
  const miscellaneous = documentDetail?.data?.miscellaneous;
  const allFolders = allFoldersData || [];

  const contractFolders = allFolders?.filter(
    (folder) => folder.type === "contracts"
  );

  const invoiceFolders = allFolders?.filter(
    (folder) => folder.type === "invoices"
  );

  const reportFolders = allFolders?.filter(
    (folder) => folder.type === "reports"
  );

  const miscFolders = allFolders?.filter(
    (folder) => folder.type === "miscellaneous"
  );

  const [isReportModalOpen, setReportModalOpen] = useState(false);
  const [isMiscModalOpen, setMiscModalOpen] = useState(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [emailModalData, setEmailModalData] = useState(null);
  const [emailModalType, setEmailModalType] = useState(null);

  useEffect(() => {
    if (
      client?.data?.length === 0 &&
      client?.data?.findIndex((e) => e._id === clientId) === -1
    ) {
      dispatch(ClientListWithNoProposal());
    }
  }, [client?.data, clientId, dispatch]);

  useEffect(() => {
    dispatch(getFolderByClient(clientId));
  }, [dispatch, clientId]);

  const [navBarText, setNavbarText] = useState("Back");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState({
    status: false,
    id: null,
  });

  const [uploadContract, setUploadContract] = useState(false);
  const [contract, setContract] = useState(null);
  const [isActive, setIsActive] = useState(false);

  const [createNewTemplate, setCreateNewTemplate] = useState(false);
  const [templateType, setTemplateType] = useState(null);

  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
  const [isOpenInvoiceModal, setIsOpenInvoiceModal] = useState(false);
  const [invoice, setInvoice] = useState("");

  const [newFolder, setNewFolder] = useState("");
  const [isNewFolderModalOpen, setIsNewFolderModalOpen] = useState(false);
  const [newFolderType, setNewFolderType] = useState("contract");
  const [isNew, setIsNew] = useState(false);
  const [openFolderId, setOpenFolderId] = useState(null);
  const [folder, setFolder] = useState(null);

  const [
    uploadExistingInvoiceModalIsOpen,
    setUploadExistingInvoiceModalIsOpen,
  ] = useState(false);

  const {
    handleOpenSelectTemplateModal,
    templateModalIsOpen,
    selectTemplateModalIsOpen,
    setTemplateModalIsOpen,
    setSelectTemplateModalIsOpen,
  } = useNewTemplateModal();

  const handleChangeEffect = (e) => {
    if (e.target.value.length >= 1) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    setUploadContract(false);
    setContract(null);
  };

  useEffect(() => {
    if (clientId !== invoices?.client) {
      dispatch(getInvoiceListByClientId(clientId));
    }
  }, [clientId, dispatch, invoices?.client]);

  useEffect(() => {
    if (clientId !== clientDetail.data._id) {
      dispatch(GetClientDetailAction(clientId, navigate));
    }
    if (clientId !== documentDetail?.client) {
      dispatch(GetDocumentActions(clientId));
    }
    if (clientId !== documentDetail?.client) {
      dispatch(GetDocumentContractActions(clientId));
    }
  }, [
    clientDetail.data._id,
    clientId,
    dispatch,
    documentDetail?.client,
    navigate,
  ]);

  useEffect(() => {
    if (clientId !== clientDetail.data._id) {
      setNavbarText("Back");
    } else {
      setNavbarText(
        `Back to ${
          clientDetail?.data?.business_name || clientDetail?.data?.contact_name
        } Dashboard`
      );
    }
  }, [clientDetail, clientId]);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Contract title required"),
    type: Yup.string(),
    is_external_contract: Yup.boolean(),
    file: Yup.string().when(["type", "is_external_contract"], {
      is: (type, is_external_contract) =>
        is_external_contract && type === "file",
      then: Yup.string().required("Select File").nullable(),
    }),
    url: Yup.string().when(["type", "is_external_contract"], {
      is: (type, is_external_contract) =>
        is_external_contract && type === "url",
      then: Yup.string()
        .matches(
          /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i,
          "Enter correct url!"
        )
        .required("Url is required")
        .nullable(),
    }),
  });

  const handleFolderClick = (folderId) => {
    setOpenFolderId(openFolderId === folderId ? null : folderId);
  };

  const [editData, setEditData] = useState(null);

  const handleDeleteFolder = (folderId) => {
    dispatch(deleteFolder(folderId));
  };

  const RenderFolder = ({
    folder,
    onDropFile,
    files,
    setaccountCencelled,
    assetId,
  }) => {
    const { setAnchorRef, setMenuElementRef, menuStyles, menuAttributes } =
      useMenu({
        placement: "bottom-end",
        modifiers: [{ name: "offset", options: { offset: [0, 4] } }],
      });

    const [, drop] = useDrop(() => ({
      accept: "file",
      drop: (item, monitor) => {
        handleDropFile(item._id, folder);
      },
    }));

    const isOpen = folder._id === openFolderId;

    let filesToRender = [];

    if (isOpen) {
      filesToRender = folder.files
        ?.map((fileId) => files.find((c) => c._id === fileId))
        .filter((file) => file !== undefined);
    }

    return (
      <div className="flex flex-col" ref={drop}>
        <div className="relative flex items-center justify-between w-full h-12 border-b border-Neutral300">
          <button
            className="flex items-center gap-2 duration-300 cursor-pointer text-Neutral800 hover:text-AccentRegular"
            onClick={() => {
              handleFolderClick(folder._id);
            }}
          >
            {isOpen ? <FolderIcon /> : <FolderOutlineIcon />}

            <h5 className="text-sm text-normal">{folder?.name}</h5>
            {isOpen && <DownArrowIcon />}
          </button>
          <Menu as="div" className="relative inline-block text-left">
            <Menu.Button
              ref={setAnchorRef}
              className="inline-flex justify-center w-full"
            >
              <ThreeDotsIcon color="#6D6DF2" />
            </Menu.Button>
            <Portal>
              <Menu.Items
                ref={setMenuElementRef}
                style={menuStyles}
                {...menuAttributes}
                className="edit-menu-servies edit-delete-menu z-[999] w-[100px] rounded-lg bg-Neutral000 border-[0.5px] border-Neutral200 divide-y divide-Neutral300 focus:outline-none"
              >
                <form method="POST" action="#">
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        className="flex items-center justify-start px-4 py-2 text-xs font-semibold text-left transition duration-300 cursor-pointer text-Neutral800 whitespace-nowrap hover:bg-Neutral200"
                        onClick={() => {
                          setIsNew(false);
                          setFolder(folder);
                          setIsNewFolderModalOpen(true);
                          setNewFolder(folder.name);
                        }}
                      >
                        <EditIcon className="mr-2" />
                        Edit
                      </div>
                    )}
                  </Menu.Item>

                  <Menu
                    as="div"
                    className="pt-[1px]"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Menu.Button className="flex items-center justify-start px-4 py-2 text-xs font-semibold text-left transition duration-300 cursor-pointer text-Neutral800 whitespace-nowrap hover:bg-Neutral200">
                      <DeleteIcon className="mr-2" />
                      Delete
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="focus:outline-none min-w-[334px] absolute right-5 top-[-115px] w-36 origin-top-right divide-y divide-Neutral200 bg-white ring-1 ring-black ring-opacity-5 drop-shadow-Timetracking">
                        <div className="px-8 pt-6 pb-8">
                          <Menu.Item>
                            {({ close }) => (
                              <div>
                                <h5 className="pb-2 text-xs font-semibold border-b text-Neutral700 border-Neutral200">
                                  Are you sure?
                                </h5>
                                <button
                                  onClick={() => {
                                    handleDeleteFolder(folder._id);
                                  }}
                                  type="button"
                                  className="text-Neutral000 w-full pl-2 pr-4 py-4 text-center text-sm cursor-pointer font-bold transition duration-300 bg-Red400 hover:bg-Red600 rounded-[4px] mt-8"
                                >
                                  Yes, Delete Folder
                                </button>

                                <button
                                  onClick={close}
                                  type="button"
                                  className="text-Neutral800 hover:text-Neutral900 w-full pl-2 pr-4 py-4 text-center text-sm cursor-pointer font-bold transition duration-300 bg-Neutral300 hover:bg-Neutral400 rounded-[4px] mt-5"
                                >
                                  No, I Still Want This Folder
                                </button>
                              </div>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </form>
              </Menu.Items>
            </Portal>
          </Menu>
        </div>

        <div className="ml-7">
          {isOpen &&
            filesToRender?.map((file) => (
              <RenderFile
                type={folder.type}
                key={file._id}
                file={file}
                handleDeleted={handleDeleted}
                setaccountCencelled={setaccountCencelled}
                assetId={assetId}
              />
            ))}
        </div>
      </div>
    );
  };

  const RenderFile = ({
    type,
    file,
    handleDeleted,
    assetId,
    setaccountCencelled,
  }) => {
    const { setAnchorRef, setMenuElementRef, menuStyles, menuAttributes } =
      useMenu({
        placement: "bottom-end",
        modifiers: [{ name: "offset", options: { offset: [0, 4] } }],
      });

    const [{ isDragging }, drag] = useDrag(() => ({
      type: "file",
      item: file,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }));

    const fileUrl =
      file?.file?.[0]?.url ||
      file?.existing_file_upload?.url ||
      file?.existing_file_url;

    const extension = getExtension(fileUrl);
    const category = getCategoryFromType(extension);
    const { background, text } = getStyleForCategory(category);

    const getFavIcon = (url) => {
      if (!url) return null;
      const tempURL = url.includes("http") ? url : `https://${url}`;
      const urlObj = new URL(tempURL);
      const domain = urlObj.hostname;
      return `https://www.google.com/s2/favicons?domain=${domain}`;
    };

    let fileData = null;
    if (!Array.isArray(file.file) && file.file) {
      fileData = Object.entries(file.file).reduce((acc, cur) => {
        acc[0] = { ...(acc[0] || {}), [cur[0]]: cur[1] };
        return acc;
      }, []);
    } else {
      fileData = file.file;
    }

    return (
      <div
        ref={drag}
        style={{ opacity: isDragging ? 0.5 : 1 }}
        className={`flex items-center justify-between h-auto border-b border-Neutral300 w-full relative ${
          isDragging && "bg-Neutral400"
        }`}
      >
        {fileData && fileData.find((item) => item.file) ? (
          <button className="flex items-center w-full gap-2 duration-300 cursor-pointer text-Neutral800 hover:text-AccentRegular">
            <div className={`my-2 w-full`}>
              {fileData.map((item) => {
                return (
                  <div className="flex items-center gap-2">
                    <img
                      src={item?.url}
                      alt=""
                      className={"w-4 h-4"}
                      onClick={() => {
                        return window.open(item?.url, "_blank");
                      }}
                    />
                    <h5
                      className="flex-grow text-sm text-left line-clamp-1 text-normal"
                      onClick={() => {
                        return window.open(item.url, "_blank");
                      }}
                      data-tip={item.file?.originalname}
                      data-for="fileTitle"
                    >
                      {item.file?.originalname}
                    </h5>
                    <span
                      className={`rounded-full py-1 px-2 text-[10px] font-semibold ${background} ${text}`}
                    >
                      {item?.file?.ContentType?.toUpperCase()}
                    </span>
                  </div>
                );
              })}
            </div>
          </button>
        ) : (
          <button className="flex items-center w-full gap-2 cursor-pointer">
            {file?.url && file?.url?.length ? (
              <div className={`my-2 w-full`}>
                {file?.url?.map((item) => {
                  return (
                    <div
                      onClick={() => {
                        window.open(item, "_blank");
                        if (type === "invoices") {
                          return navigate(
                            `/admin/clients/manage/${clientId}/invoice/${file?._id}`
                          );
                        } else if (type === "contract") {
                          return navigate(
                            `/admin/clients/manage/${clientId}/contract/${file?._id}`
                          );
                        }
                      }}
                      className="flex items-center w-full gap-2"
                    >
                      <img
                        src={getFavIcon(item)}
                        alt=""
                        className={`w-4 h-4`}
                      />
                      <h5
                        data-tip={file?.title}
                        data-for="fileTitle"
                        className="flex-grow text-sm text-left truncate duration-300 text-normal text-Neutral800 hover:text-AccentRegular"
                      >
                        {file?.title}
                      </h5>
                      <ExternalLinkIcon />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="flex items-center h-12 gap-2 duration-300 cursor-pointer text-Neutral800 hover:text-AccentRegular">
                {file?.existing_file_upload?.url ? (
                  <img
                    src={getFavIcon(file?.existing_file_upload?.url)}
                    alt=""
                    className="w-5 h-5"
                  />
                ) : null}

                <h5
                  className="text-sm text-left text-normal"
                  onClick={() => {
                    if (
                      file?.is_external_contract ||
                      file?.is_external_invoice
                    ) {
                      if (file?.existing_file_url) {
                        return window.open(file?.existing_file_url, "_blank");
                      }
                      if (file?.existing_file_upload?.url) {
                        return window.open(
                          file?.existing_file_upload?.url,
                          "_blank"
                        );
                      }
                    }
                    if (type === "invoices") {
                      return navigate(
                        `/admin/clients/manage/${clientId}/invoice/${file?._id}`
                      );
                    } else {
                      return navigate(
                        `/admin/clients/manage/${clientId}/contract/${file?._id}`
                      );
                    }
                  }}
                  data-tip={
                    file?.invoiceName ||
                    file?.title ||
                    "INVOICE #" + file.invoiceNumber
                  }
                  data-for="fileTitle"
                >
                  {file?.invoiceName ||
                    file?.title ||
                    "INVOICE #" + file.invoiceNumber}
                </h5>
                {file?.is_external_contract && file?.existing_file_url ? (
                  <ExternalLinkIcon />
                ) : null}
              </div>
            )}
          </button>
        )}

        <ReactTooltip
          id="fileTitle"
          effect="solid"
          className="!bg-slate-500 after:!bg-slate-500 !px-2 !py-1 !z-[1] before:!-z-[1] after:!-z-[1]"
        />

        {/* <button className="flex items-center gap-2 duration-300 cursor-pointer text-Neutral800 hover:text-AccentRegular">
          {isFileImage && <img src={fileUrl} alt="" className="w-5 h-5" />}
          {(file?.is_external_contract && file?.existing_file_url) ||
          (file?.is_external_invoice && file?.existing_file_url) ||
          (file?.url && file?.url[0]?.length) ? (
            <img
              src={getFavIcon(
                Array.isArray(file?.url)
                  ? file?.url[0]
                  : file?.url || file?.existing_file_url
              )}
              alt=""
              className="w-5 h-5"
            />
          ) : null}

          <h5
            className="text-sm text-left text-normal"
            onClick={() => {
              if (file?.file && file.file.length > 0 && file.file[0]?.url) {
                return window.open(file.file[0].url, "_blank");
              }

              if (file?.is_external_contract || file?.is_external_invoice) {
                if (file?.existing_file_url) {
                  return window.open(file?.existing_file_url, "_blank");
                }
                if (file?.existing_file_upload?.url) {
                  return window.open(file?.existing_file_upload?.url, "_blank");
                }
              } else {
                if (type === "invoices") {
                  return navigate(
                    `/admin/clients/manage/${clientId}/invoice/${file?._id}`
                  );
                } else {
                  return navigate(
                    `/admin/clients/manage/${clientId}/contract/${file?._id}`
                  );
                }
              }
            }}
          >
            {type === "contracts" && file.is_external_contract
              ? file.title || file.existing_file_url
              : type === "invoices"
              ? file?.invoiceName
              : file?.title}
          </h5>
          {(file?.is_external_contract && file?.existing_file_url) ||
          (file?.url && file?.url[0]?.length) ? (
            <ExternalLinkIcon />
          ) : null}
          {!["contracts", "invoices"].includes(type) && extension && (
            <span
              className={`rounded-full py-1 px-3 text-xs font-semibold ${background} ${text}`}
            >
              {extension?.toUpperCase()}
            </span>
          )}
        </button> */}
        <Menu as="div" className="pt-[1px]">
          <div className="flex items-center">
            <Menu.Button
              ref={setAnchorRef}
              className="inline-flex justify-center w-full"
            >
              <ThreeDotsIcon color="#6D6DF2" />
            </Menu.Button>
          </div>
          <Portal>
            <Menu.Items
              ref={setMenuElementRef}
              style={menuStyles}
              {...menuAttributes}
              className="edit-menu-servies edit-delete-menu z-[999] w-[100px] rounded-lg bg-Neutral000 border-[0.5px] border-Neutral200 divide-y divide-Neutral300 focus:outline-none "
            >
              <form method="POST" action="#">
                <Menu.Item>
                  {({ active }) => (
                    <div>
                      <div
                        className="flex items-center justify-start px-4 py-2 text-xs font-semibold text-left transition duration-300 cursor-pointer text-Neutral800 whitespace-nowrap hover:bg-Neutral200"
                        onClick={() => {
                          if (
                            file?.file &&
                            file.file.length > 0 &&
                            file.file[0]?.url
                          ) {
                            return window.open(file.file[0].url, "_blank");
                          }

                          if (
                            file?.is_external_contract ||
                            file?.is_external_invoice
                          ) {
                            if (file?.existing_file_url) {
                              return window.open(
                                file?.existing_file_url,
                                "_blank"
                              );
                            }
                            if (file?.existing_file_upload?.url) {
                              return window.open(
                                file?.existing_file_upload?.url,
                                "_blank"
                              );
                            }
                          } else {
                            if (type === "invoices") {
                              return navigate(
                                `/admin/clients/manage/${clientId}/invoice/${file?._id}`
                              );
                            } else {
                              return navigate(
                                `/admin/clients/manage/${clientId}/contract/${file?._id}`
                              );
                            }
                          }
                        }}
                      >
                        <ViewIcon className="mr-2" />
                        View
                      </div>
                      {!["invoices", "contracts"].includes(type) && (
                        <div
                          className="flex items-center justify-start px-4 py-2 text-xs font-semibold text-left transition duration-300 cursor-pointer text-Neutral800 whitespace-nowrap hover:bg-Neutral200"
                          onClick={() => {
                            if (
                              (file?.url && file?.url[0]?.length) ||
                              file?.file?.length
                            ) {
                              setEditData({
                                ...file,
                                document_id: assetId,
                              });
                              setaccountCencelled(true);
                              return;
                            }
                          }}
                        >
                          <EditIcon className="mr-2" />
                          Edit
                        </div>
                      )}
                      {["invoices", "contracts"].includes(type) && (
                        <div
                          className="flex items-center justify-start px-4 py-2 text-xs font-semibold text-left transition duration-300 cursor-pointer text-Neutral800 whitespace-nowrap hover:bg-Neutral200"
                          onClick={() => {
                            if (type === "invoices") {
                              if (file?.is_external_invoice) {
                                setInvoice(file);
                                setUploadExistingInvoiceModalIsOpen(true);
                              } else {
                                navigate(
                                  `/admin/create-invoice/${clientId}/${file?._id}`
                                );
                              }
                            } else {
                              setIsOpen(true);
                              setUploadContract(true);
                              setContract(file);
                            }
                          }}
                        >
                          <EditIcon className="mr-2" />
                          Edit
                        </div>
                      )}{" "}
                      {["invoices", "contracts"].includes(type) && (
                        <div
                          className="flex items-center justify-start px-4 py-2 text-xs font-semibold text-left transition duration-300 cursor-pointer text-Neutral800 whitespace-nowrap hover:bg-Neutral200"
                          onClick={() => {
                            setEmailModalData(file);
                            setEmailModalType(
                              type === "invoices" ? "Invoice" : "Contract"
                            );
                            setIsEmailModalOpen(true);
                          }}
                        >
                          <MessageIcon
                            width="16"
                            height="16"
                            className="mr-2"
                          />
                          Email
                        </div>
                      )}
                      <Menu
                        as="div"
                        className="pt-[1px]"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Menu.Button className="flex items-center justify-start px-4 py-2 text-xs font-semibold text-left transition duration-300 cursor-pointer text-Neutral800 whitespace-nowrap hover:bg-Neutral200">
                          <DeleteIcon className="mr-2" />
                          Delete
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="focus:outline-none min-w-[334px] absolute right-5 top-[-115px] w-36 origin-top-right divide-y divide-Neutral200 bg-white ring-1 ring-black ring-opacity-5 drop-shadow-Timetracking">
                            <div className="px-8 pt-6 pb-8">
                              <Menu.Item>
                                {({ close }) => (
                                  <div>
                                    <h5 className="pb-2 text-xs font-semibold border-b text-Neutral700 border-Neutral200">
                                      Are you sure?
                                    </h5>
                                    <button
                                      onClick={() => {
                                        if (type === "invoices") {
                                          handleDeleteInvoice(file?._id);
                                        } else if (type === "contracts") {
                                          handleDeleted(file?._id);
                                        } else {
                                          if (!assetId || !file?._id)
                                            return console.log(
                                              "Asset ID or File ID not found"
                                            );

                                          let formData = {
                                            item_id: file?._id,
                                            document_id: assetId,
                                          };

                                          dispatch(
                                            DeleteDocumentsAction(
                                              formData,
                                              type,
                                              setIsLoading,
                                              setIsOpen
                                            )
                                          );
                                        }
                                      }}
                                      type="button"
                                      className="text-Neutral000 w-full pl-2 pr-4 py-4 text-center text-sm cursor-pointer font-bold transition duration-300 bg-Red400 hover:bg-Red600 rounded-[4px] mt-8"
                                    >
                                      Yes, Delete File
                                    </button>

                                    <button
                                      onClick={close}
                                      type="button"
                                      className="text-Neutral800 hover:text-Neutral900 w-full pl-2 pr-4 py-4 text-center text-sm cursor-pointer font-bold transition duration-300 bg-Neutral300 hover:bg-Neutral400 rounded-[4px] mt-5"
                                    >
                                      No, I Still Want This File
                                    </button>
                                  </div>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  )}
                </Menu.Item>
              </form>
            </Menu.Items>
          </Portal>
        </Menu>
      </div>
    );
  };

  const addNewFolder = async (name, type) => {
    if (!clientId) return;
    dispatch(createFolder(name, type, clientId));
    setNewFolder("");
    setIsNewFolderModalOpen(false);
  };

  const handleDropFile = (fileId, destinationFolder) => {
    const sourceFolder = allFolders.find((folder) =>
      folder.files.includes(fileId)
    );

    if (!destinationFolder) {
      if (sourceFolder) {
        const updatedSourceFiles = sourceFolder.files.filter(
          (id) => id !== fileId
        );
        const updatedSourceFolder = {
          ...sourceFolder,
          files: updatedSourceFiles,
        };
        dispatch(updateFolder(updatedSourceFolder));
      }
      return;
    }

    const isMovingFile =
      sourceFolder && sourceFolder._id !== destinationFolder._id;

    if (isMovingFile) {
      const updatedSourceFiles = sourceFolder.files.filter(
        (id) => id !== fileId
      );
      const updatedSourceFolder = {
        ...sourceFolder,
        files: updatedSourceFiles,
      };

      const updatedDestinationFiles = [...destinationFolder.files, fileId];
      const updatedDestinationFolder = {
        ...destinationFolder,
        files: updatedDestinationFiles,
        updated_at: new Date(),
      };
      dispatch(
        updateMultipleFolders([updatedSourceFolder, updatedDestinationFolder])
      );
    } else {
      if (!destinationFolder.files.includes(fileId)) {
        const updatedDestinationFiles = [...destinationFolder.files, fileId];
        const updatedDestinationFolder = {
          ...destinationFolder,
          files: updatedDestinationFiles,
          updated_at: new Date(),
        };
        dispatch(updateFolder(updatedDestinationFolder));
      } else {
        console.log("File already in folder");
      }
    }
  };

  const handleDeleted = async (id) => {
    setLoading(true);
    dispatch(
      DeleteDocumentsContratsAction(id, setLoading, setIsOpen, setContract)
    );
  };

  const handleDeleteInvoice = (id) => {
    setIsLoading({
      status: true,
      id: id,
    });
    dispatch(deleteInvoice(id, setIsLoading));
  };

  return (
    <>
      <AdminNavbar
        link={`/admin/clients/manage/${clientId}`}
        title="Documents"
        backLink={navBarText}
      />
      <div className="5xl:px-[96px] px-12 w-full pt-0">
        <div className="w-full flex justify-between flex-wrap max-w-[836px] mx-auto pt-10 md:pt-[80px]">
          {documentDetail.isLoading && documentDetail?.client !== clientId ? (
            <DocumentsListLoader />
          ) : (
            <>
              <DndProvider backend={HTML5Backend}>
                <DocumentBox
                  type="invoices"
                  icon={InvoiceWhite}
                  title="Invoices"
                  folders={invoiceFolders}
                  files={invoices?.data}
                  setIsNew={setIsNew}
                  setNewFolderType={setNewFolderType}
                  setIsNewFolderModalOpen={setIsNewFolderModalOpen}
                  openModal={() => {
                    setIsNew(true);
                    setNewFolderType("invoices");
                    setIsNewFolderModalOpen(true);
                  }}
                  addDocument={() => {
                    setOpenInvoiceModal(true);
                    setTemplateType("Invoice");
                  }}
                  RenderFolder={RenderFolder}
                  handleDropFile={handleDropFile}
                  RenderFile={RenderFile}
                  handleDeleted={handleDeleted}
                  clientId={clientId}
                  noDataContent={<InvoiceNoDataContent />}
                />

                <DocumentBox
                  type="contracts"
                  icon={Contract}
                  title="Contracts"
                  folders={contractFolders}
                  files={contracts}
                  openModal={() => {
                    setIsNew(true);
                    setNewFolderType("contracts");
                    setIsNewFolderModalOpen(true);
                  }}
                  addDocument={() => {
                    setIsOpen(true);
                  }}
                  RenderFolder={RenderFolder}
                  handleDropFile={handleDropFile}
                  RenderFile={RenderFile}
                  handleDeleted={handleDeleted}
                  clientId={clientId}
                  noDataContent={<ContractsNoDataContent />}
                />

                <DocumentBox
                  type="reports"
                  icon={Report}
                  title="Reports"
                  folders={reportFolders}
                  files={reports?.items || []}
                  addDocument={() => {
                    setReportModalOpen(true);
                  }}
                  openModal={() => {
                    setIsNew(true);
                    setNewFolderType("reports");
                    setIsNewFolderModalOpen(true);
                  }}
                  RenderFolder={RenderFolder}
                  handleDropFile={handleDropFile}
                  RenderFile={RenderFile}
                  handleDeleted={handleDeleted}
                  isNewDocumentModalOpen={isReportModalOpen}
                  setIsNewDocumentModalOpen={setReportModalOpen}
                  editData={editData}
                  setEditData={setEditData}
                  clientId={clientId}
                  noDataContent={<ReportsNoDataContent />}
                  assetId={reports?._id}
                />

                <DocumentBox
                  type="miscellaneous"
                  icon={Misc}
                  title="Miscellaneous"
                  folders={miscFolders}
                  files={miscellaneous?.items || []}
                  addDocument={() => {
                    setMiscModalOpen(true);
                  }}
                  openModal={() => {
                    setIsNew(true);
                    setNewFolderType("miscellaneous");
                    setIsNewFolderModalOpen(true);
                  }}
                  RenderFolder={RenderFolder}
                  handleDropFile={handleDropFile}
                  RenderFile={RenderFile}
                  handleDeleted={handleDeleted}
                  isNewDocumentModalOpen={isMiscModalOpen}
                  setIsNewDocumentModalOpen={setMiscModalOpen}
                  editData={editData}
                  setEditData={setEditData}
                  clientId={clientId}
                  noDataContent={<MiscNoDataContent />}
                  assetId={miscellaneous?._id}
                />
              </DndProvider>
            </>
          )}
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="Example Modal"
      >
        <div className="relative bg-Neutral000 rounded-lg text-left transform transition-all sm:my-8 sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="bg-Neutral000 px-6 xs:px-12 pt-8 pb-12  rounded-[8px]">
            <div>
              <div className="text-left">
                {!uploadContract && (
                  <div>
                    <h3
                      className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-10"
                      id="modal-title"
                    >
                      ADD CONTRACT
                    </h3>
                    <button
                      className="flex items-center justify-center w-full h-12 py-3 text-sm font-semibold text-center transition duration-300 ease-in-out rounded btn button-hover large-primary-btn text-Neutral100 bg-AccentRegular drop-shadow-Texts focus:outline focus:outline-8 focus:outline-AccentMediumLight focus-visible:outline-0"
                      onClick={() => {
                        setTemplateModalIsOpen(true);
                        setTemplateType("Contract");
                        closeModal();
                      }}
                    >
                      <span className="relative z-10 drop-shadow-Texts">
                        Build a Contract
                      </span>
                    </button>
                    <button
                      onClick={() => {
                        setUploadContract(true);
                      }}
                      className="text-sm bg-AccentLight text-AccentDark h-12 flex items-center justify-center leading-[1.2] mt-6 rounded font-bold w-full  hover:border-AccentRegular focus:border-0 focus:outline focus:outline-4 focus:outline-AccentMediumLight transition duration-300 ease-in-out hover:bg-[#d3d3eb]"
                    >
                      Upload Existing Contract
                    </button>
                  </div>
                )}

                {uploadContract && (
                  <div>
                    <Formik
                      initialValues={{
                        file: contract?.existing_file_upload?.key
                          ? contract?.existing_file_upload?.file
                              ?.originalname ||
                            contract?.existing_file_upload?.key
                          : "",
                        title: contract?.title ? contract?.title : "",
                        is_external_contract:
                          contract?.is_external_contract || !contract
                            ? true
                            : false,
                        url: contract?.existing_file_url
                          ? contract?.existing_file_url
                          : "",
                        type: contract
                          ? contract?.existing_file_upload?.key
                            ? "file"
                            : "url"
                          : "file",
                      }}
                      enableReinitialize
                      validationSchema={validationSchema}
                      onSubmit={async (values) => {
                        setLoading(true);
                        const formData = new FormData();
                        formData.append("file", values.file);
                        formData.append("type", values.type);
                        formData.append("title", values.title);
                        formData.append("url", values.url);
                        formData.append(
                          "is_external_contract",
                          values.is_external_contract
                        );
                        formData.append("client", clientId);
                        if (contract?.client && contract?._id) {
                          formData.append("id", contract?._id);
                          dispatch(updateExistingContract(formData));
                          setLoading(false);
                          setIsOpen(false);
                          setUploadContract(false);
                        } else {
                          dispatch(UploadExistingContract(formData));
                          setLoading(false);
                          setIsOpen(false);
                          setUploadContract(false);
                          setIsActive(false);
                        }
                      }}
                    >
                      {({
                        touched,
                        errors,
                        values,
                        isSubmitting,
                        handleSubmit,
                        handleChange,
                        handleBlue,
                        handleReset,
                        setFieldValue,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <div className="text-left">
                            <h3
                              className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-8"
                              id="modal-title"
                            >
                              Existing Contract
                            </h3>
                            <div>
                              <label className="text-xs font-bold text-Neutral900">
                                Name
                              </label>
                              <div className="mb-10">
                                <input
                                  className={`duration-300 transition-all w-full py-[15px] h-12 px-4 placeholder:text-sm text-sm placeholder:text-Neutral500 text-Neutral800 resize-none font-medium placeholder:font-medium block focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow`}
                                  type="text"
                                  placeholder="Eg. Brand Guide"
                                  name="title"
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleChangeEffect(e);
                                  }}
                                  onBlur={handleBlue}
                                  value={values?.title}
                                />
                                {errors.title && touched.title && (
                                  <p className="absolute mt-1 text-xs font-medium text-Red400 focus-visible:outline-none">
                                    {errors.title}
                                  </p>
                                )}
                              </div>
                            </div>
                            {values?.is_external_contract === true &&
                            values?.type ? (
                              <div className="mb-[60px]">
                                <label className="text-xs font-bold text-Neutral900">
                                  Upload Contract
                                </label>
                                <div className="mt-2">
                                  <div
                                    className={`w-full flex ${
                                      values.type === "url"
                                        ? "opacity-0 w-0 h-0 invisible"
                                        : "opacity-100 w-full h-fit"
                                    } `}
                                  >
                                    <UploadPDF
                                      title="Upload your contract PDF."
                                      value={values}
                                      error={errors}
                                      touched={touched}
                                      handleBlue={handleBlue}
                                      setFieldValue={setFieldValue}
                                      contract={contract}
                                      handleChangeeffect={handleChangeEffect}
                                    />
                                    {contract?.client ? (
                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={
                                          contract?.existing_file_upload?.url
                                        }
                                      >
                                        <img
                                          className="cursor-pointer ml-2 mt-[10px] 5xl:w-[20px] h-[20px]"
                                          src={Visible}
                                          alt=""
                                        />
                                      </a>
                                    ) : null}
                                  </div>
                                  <div
                                    className={`w-full transition duration-300 ${
                                      values.type === "file"
                                        ? "opacity-0 w-0 h-0 invisible"
                                        : "opacity-100 w-full h-fit"
                                    } `}
                                  >
                                    <input
                                      className={`text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-base leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition ${
                                        touched?.url && errors?.url
                                          ? "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow"
                                          : ""
                                      }`}
                                      type="text"
                                      name="url"
                                      placeholder="Eg.  drive.google.com/drive..."
                                      value={values?.url}
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleChangeEffect(e);
                                      }}
                                      onBlur={handleBlue}
                                    />
                                    {errors.url && touched.url && (
                                      <p className="absolute mt-1 text-xs font-medium text-Red400 focus-visible:outline-none">
                                        {errors.url}{" "}
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <p
                                  className={`text-xs text-Neutral700 transition duration-300 font-medium pt-5 ${
                                    values?.type !== "url"
                                      ? "opacity-0 w-0 h-0 invisible"
                                      : "opacity-100 w-fit h-fit"
                                  }`}
                                >
                                  Have a PDF contract already?{" "}
                                  <button
                                    type="button"
                                    className="text-AccentRegular"
                                    name="type"
                                    onClick={() => {
                                      setFieldValue("type", "file");
                                      setFieldValue("url", "");
                                    }}
                                  >
                                    {" "}
                                    Upload it here.
                                  </button>
                                </p>
                                <p
                                  className={`text-xs text-Neutral700 transition duration-300 font-medium ${
                                    values?.type !== "file"
                                      ? "opacity-0 w-0 h-0 invisible"
                                      : "opacity-100 w-fit h-fit"
                                  }`}
                                >
                                  Have an existing contract URL?{" "}
                                  <button
                                    type="button"
                                    className="text-AccentRegular"
                                    name="type"
                                    onClick={() => {
                                      setFieldValue("type", "url");
                                      setFieldValue("file", "");
                                    }}
                                  >
                                    Upload it here.
                                  </button>
                                </p>
                              </div>
                            ) : null}
                            <div className="flex justify-between">
                              <button
                                type="submit"
                                disabled={isSubmitting}
                                className={`relative modal-discount-text inlinr-block text-sm font-bold min-w-[219px] h-12 flex items-center justify-center rounded-[4px] leading-[1.4] focus:outline-AccentMediumLight bg-Neutral300 transition duration-300 ease-in-out ${
                                  isActive
                                    ? "bg-AccentRegular text-Neutral100 invoice-changes hover:text-Neutral100 duration-300"
                                    : "text-Neutral500"
                                } `}
                              >
                                {isSubmitting ? (
                                  <span className="relative z-10">
                                    <svg
                                      className="w-5 h-5 m-auto text-white animate-spin"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                    >
                                      <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                      ></circle>
                                      <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                      ></path>
                                    </svg>
                                  </span>
                                ) : (
                                  "Save Changes"
                                )}
                              </button>
                              {contract && (
                                <button
                                  type="button"
                                  className={` modal-discount-text inlinr-block text-sm font-bold w-[88px] h-12 flex items-center justify-center rounded-[4px] leading-[1.4] focus:outline-AccentMediumLight  hover:text-Neutral800 transition duration-300 ease-in-out ${
                                    isActive
                                      ? "bg-AccentLight hover:bg-AccentMediumLight"
                                      : "bg-Red400 hover:bg-Red600"
                                  } `}
                                  onClick={() => {
                                    if (isActive) {
                                      handleReset();
                                      setIsActive(false);
                                    } else {
                                      handleDeleted(contract?._id);
                                    }
                                  }}
                                >
                                  {loading ? (
                                    <span className="relative z-10">
                                      <svg
                                        className="w-5 h-5 m-auto text-white animate-spin"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                      >
                                        <circle
                                          className="opacity-25"
                                          cx="12"
                                          cy="12"
                                          r="10"
                                          stroke="currentColor"
                                          strokeWidth="4"
                                        ></circle>
                                        <path
                                          className="opacity-75"
                                          fill="currentColor"
                                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        ></path>
                                      </svg>
                                    </span>
                                  ) : (
                                    <img
                                      className={`${
                                        isActive ? "hidden" : "block"
                                      } `}
                                      src={DeletIcon}
                                      alt=""
                                    />
                                  )}

                                  <img
                                    className={`${
                                      isActive ? "block" : "hidden"
                                    } `}
                                    src={CloseBlueIcon}
                                    alt=""
                                  />
                                </button>
                              )}
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => closeModal(false)}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>

      <OpenInvoiceModal
        isOpen={openInvoiceModal}
        setModal={setOpenInvoiceModal}
        isOpenInvoiceModal={() => {
          setOpenInvoiceModal(false);
          setIsOpenInvoiceModal(true);
          setTemplateType("Invoice");
        }}
        isOpenBuildInvoiceModal={() => {
          setOpenInvoiceModal(false);
          setTemplateModalIsOpen(true);
          setTemplateType("Invoice");
        }}
      />

      {isEmailModalOpen && (
        <FollowUpEmailModal
          isOpen={isEmailModalOpen}
          setIsOpen={setIsEmailModalOpen}
          type={emailModalType}
          data={clientDetail?.data}
          id={emailModalData?._id}
          selectedData={emailModalData}
        />
      )}

      <UploadExistingInvoiceSpecificClientModal
        modalIsOpen={isOpenInvoiceModal}
        closeModal={() => setIsOpenInvoiceModal(false)}
        client={clientId}
        is_reload={false}
      />
      {uploadExistingInvoiceModalIsOpen && (
        <UploadExistingInvoiceModal
          modalIsOpen={uploadExistingInvoiceModalIsOpen}
          closeModal={() => setUploadExistingInvoiceModalIsOpen(false)}
          client={client}
          getInvoiceList={getInvoiceListByClientId}
          invoice={invoice}
          isDisabledClient={true}
        />
      )}

      <NewEntityTemplateModal
        type={templateType}
        isOpen={templateModalIsOpen}
        clientId={clientId}
        setIsOpen={setTemplateModalIsOpen}
        onCustomTemplateClick={handleOpenSelectTemplateModal}
        openBuildInvoiceModal={() => {
          setTemplateModalIsOpen(false);
          setCreateNewTemplate(true);
        }}
      />

      <SelectTemplate
        type={templateType}
        isOpen={selectTemplateModalIsOpen}
        setIsOpen={setSelectTemplateModalIsOpen}
        clientId={clientId}
        openNewTemplateModal={() => {
          setSelectTemplateModalIsOpen(false);
          setCreateNewTemplate(true);
        }}
      />

      <NewTemplateModal
        type={templateType}
        isOpen={createNewTemplate}
        setIsOpen={setCreateNewTemplate}
        isCreateInvoice={true}
        clientId={clientId}
      />

      {isNewFolderModalOpen && (
        <NewFolderModal
          isOpen={isNewFolderModalOpen}
          onRequestClose={() => setIsNewFolderModalOpen(false)}
          customStyles={customStyles}
          isNew={isNew}
          addNewFolder={addNewFolder}
          updateFolder={updateFolder}
          dispatch={dispatch}
          folder={folder}
          newFolderType={newFolderType}
          newFolder={newFolder}
          setNewFolder={setNewFolder}
        />
      )}
    </>
  );
};

export default ClientDocuments;
