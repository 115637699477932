import React from "react";
import { useSelector } from "react-redux";
import Checkmark from "../../assets/Images/Checkmark.svg";
import DatePicker from "react-datepicker";
import Calendar from "../../assets/Images/calendar.svg";
import moment from "moment";

function InvoiceFilter(props) {
  const { filterBtn, setFilterBtn, addfilter, setAddFilter } = props;
  const [sliceClient, setSliceClient] = React.useState(5);
  const fullClientList = useSelector((state) => state.client.fullClientList);
  const invoiceStatusList = useSelector(
    (state) => state.invoice.getInvoiceStatus
  );
  const hanldleChangeFilter = (name, value) => {
    if (addfilter?.[name].includes(value)) {
      const removedFilter = addfilter[name].filter((res) => res !== value);
      setAddFilter({
        ...addfilter,
        [name]: removedFilter,
      });
    } else {
      setAddFilter({
        ...addfilter,
        [name]: [...addfilter[name], value],
      });
    }
  };

  const renderClearClassNames = () => {
    if (addfilter) {
      for (var item in addfilter) {
        if (addfilter[item].length > 0) {
          return "!text-[#6D6DF2] text-Neutral500 text-xs";
        }
      }
    }
    return "text-Neutral500 text-xs";
  };

  return (
    <div
      className={`fixed bg-[#292933B2] top-0 z-[999] left-0 w-full h-screen flex transition-all ease-in-out ${
        filterBtn ? "duration-500" : "invisible"
      }`}
    >
      <div
        className={`w-[calc(100%-352px)] ${filterBtn ? "" : ""} `}
        onClick={() => setFilterBtn(false)}
      ></div>
      <div
        className={`h-screen overflow-scroll relative bg-Neutral100 w-fit transition-all duration-500 ease-in-out ${
          filterBtn ? " right-0 " : "-right-[100%]"
        }`}
      >
        <div className="text-Neutral700 p-8 w-[344px]">
          <div className="flex justify-between items-center">
            <span className="text-Neutral800 font-bold text-sm">FILTER</span>
            <button
              className={renderClearClassNames()}
              onClick={() =>
                setAddFilter({
                  status: [],
                  client: [],
                  createdDate: [
                    {
                      from: "",
                      to: "",
                    },
                  ],
                  value: [
                    {
                      from: "",
                      to: "",
                    },
                  ],
                })
              }
            >
              CLEAR
            </button>
          </div>

          <div className="pt-9">
            <span className="text-Neutral500 text-xs font-semibold leading-[16.34px] uppercase">
              Status
            </span>
            {invoiceStatusList?.data?.map((level, idx) => (
              <div
                className="flex items-center justify-between pt-[20px]"
                key={idx}
              >
                <span className="text-Neutral700 text-xs font-medium block capitalize">
                  {level?.name}
                </span>
                <div className="flex items-center filter-checkbox">
                  <input
                    type="checkbox"
                    id="A3-yes"
                    checked={addfilter?.status.includes(level?._id)}
                    onChange={(e) => {
                      hanldleChangeFilter("status", level?._id);
                    }}
                    name="A3-confirmation"
                    value="yes"
                    className="opacity-0 absolute cursor-pointer h-[24px] w-[24px] transition duration-300"
                  />
                  <div className="bg-white border-[1.5px] cursor-pointer hover:bg-AccentLight hover:border-AccentRegular custom-chackbox rounded-md border-Neutral300 w-[24px] h-[24px] flex flex-shrink-0 justify-center items-center mr-2 transition duration-300 ">
                    <img src={Checkmark} alt="" />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="pt-9 ">
            <span className="text-Neutral500 text-xs font-semibold leading-[16.34px] uppercase">
              Clients
            </span>
            <div className="pt-5 flex flex-wrap">
              {fullClientList?.data?.length ? (
                <div className="flex items-center flex-wrap">
                  {fullClientList?.data
                    ?.slice(0, sliceClient)
                    ?.map((level, idx) => {
                      return (
                        <div key={idx}>
                          <button
                            checked={addfilter?.client?.includes(level?._id)}
                            className="flex items-center border-Neutral300 border rounded-[4px] mb-1 bg-Neutral100 py-[6px] px-2  hover:border-Neutral600 text-Neutral700 hover:text-Neutral800 cursor-pointer mr-2 transition duration-300 ease-in-out"
                            onClick={() => {
                              hanldleChangeFilter("client", level?._id);
                            }}
                            style={{
                              backgroundColor: addfilter?.client?.includes(
                                level?._id
                              )
                                ? "#5A5A66"
                                : "",
                              color: addfilter?.client?.includes(level?._id)
                                ? "white"
                                : "",
                            }}
                          >
                            {level?.profile && level?.profile_path ? (
                              <img
                                src={level?.profile_path}
                                alt=""
                                className="mr-2 outline-[0.5px] outline-[rgba(0, 0, 0, 0.01)] rounded-full outline outline-1 outline-[#0000001a] overflow-hidden min-w-9 max-w-[36px] w-5"
                              />
                            ) : (
                              <div className="mr-2 outline-[0.5px] outline-[rgba(0, 0, 0, 0.01)] rounded-full outline outline-1 outline-[#0000001a] overflow-hidden min-w-9 max-w-[36px] w-5 h-5 flex items-center justify-center">
                                <span className="text-Neutral700 text-xs font-medium capitalize">
                                  {level?.business_name?.charAt(0) ||
                                    level?.contact_name?.charAt(0)}
                                </span>
                              </div>
                            )}
                            <span className="text-xs font-medium">
                              {level?.business_name || level?.contact_name}
                            </span>
                          </button>
                        </div>
                      );
                    })}
                  {fullClientList?.data?.length > 5 && (
                    <button
                      className="flex items-center border-Neutral300 border text-xs font-medium rounded-[4px] mb-1 bg-Neutral100 py-[6px] px-2  hover:border-Neutral600 text-Neutral700 hover:text-Neutral800 cursor-pointer mr-2 transition duration-300 ease-in-out capitalize"
                      onClick={() => {
                        if (sliceClient === fullClientList?.data?.length) {
                          setSliceClient(5);
                        } else {
                          setSliceClient(fullClientList?.data?.length);
                        }
                      }}
                    >
                      {sliceClient === fullClientList?.data?.length
                        ? "less"
                        : "more"}
                    </button>
                  )}
                </div>
              ) : (
                <p className="text-Neutral700 text-xs font-medium italic">
                  No client found!
                </p>
              )}
            </div>
          </div>
          <div className="pt-9 ">
            <span className="text-Neutral500 text-xs font-semibold leading-[16.34px] uppercase">
              Date Created
            </span>
            <div className="flex items-center justify-between pt-6 relative">
              <span className="text-Neutral700 text-xs font-medium block capitalize">
                From:
              </span>
              <div className="flex items-center relative py-[6px] pl-2 w-[111px] text-Neutral900 text-xs font-medium capitalize border-Neutral300 border-2 rounded-[4px] mb-1 bg-Neutral100 hover:border-Neutral600 hover:text-Neutral800 cursor-pointer transition duration-300 ease-in-out">
                <DatePicker
                  selected={
                    addfilter?.createdDate[0]?.from
                      ? new Date(addfilter?.createdDate[0]?.from)
                      : null
                  }
                  customInput={
                    <div>
                      <input
                        className="w-full focus:outline-none"
                        placeholder={moment().format("DD/MM/YYYY")}
                        value={
                          addfilter?.createdDate[0]?.from
                            ? moment(
                                new Date(addfilter?.createdDate[0]?.from)
                              ).format("DD/MM/YYYY")
                            : ""
                        }
                      />{" "}
                      <img
                        src={Calendar}
                        alt=""
                        className="absolute right-1 top-[-2px] w-[20px] cursor-pointer"
                      />
                    </div>
                  }
                  onChange={(date) => {
                    setAddFilter({
                      ...addfilter,
                      createdDate: [
                        {
                          from: moment(date).format("YYYY-MM-DD"),
                          to: addfilter?.createdDate[0]?.to,
                        },
                      ],
                    });
                  }}
                  className="w-full focus:outline-none"
                />
              </div>
            </div>
            <div className="flex items-center justify-between pt-[20px] relative">
              <span className="text-Neutral700 text-xs font-medium block capitalize">
                To:
              </span>
              <div className="flex items-center relative py-[6px] pl-2 w-[111px] text-Neutral900 text-xs font-medium capitalize border-Neutral300 border-2 rounded-[4px] mb-1 bg-Neutral100 hover:border-Neutral600 hover:text-Neutral800 cursor-pointer transition duration-300 ease-in-out">
                <DatePicker
                  selected={
                    addfilter?.createdDate[0]?.to
                      ? new Date(addfilter?.createdDate[0]?.to)
                      : null
                  }
                  customInput={
                    <div>
                      <input
                        className="w-full focus:outline-none"
                        placeholder={moment().format("DD/MM/YYYY")}
                        value={
                          addfilter?.createdDate[0]?.to
                            ? moment(
                                new Date(addfilter?.createdDate[0]?.to)
                              ).format("DD/MM/YYYY")
                            : ""
                        }
                      />{" "}
                      <img
                        src={Calendar}
                        alt=""
                        className="absolute right-1 top-[-2px] w-[20px] cursor-pointer"
                      />
                    </div>
                  }
                  onChange={(date) => {
                    setAddFilter({
                      ...addfilter,
                      createdDate: [
                        {
                          from: addfilter?.createdDate[0]?.from,
                          to: moment(date).format("YYYY-MM-DD"),
                        },
                      ],
                    });
                  }}
                  className="w-full focus:outline-none"
                />
              </div>
            </div>
          </div>
          <div className="pt-9 ">
            <span className="text-Neutral500 text-xs font-semibold leading-[16.34px] uppercase">
              Invoice Value
            </span>
            <div className="flex items-center justify-between pt-6 relative">
              <span className="text-Neutral700 text-xs font-medium block capitalize">
                From:
              </span>
              <div className="flex items-center relative py-[6px] px-2 w-[111px] text-Neutral900 text-xs font-medium capitalize border-Neutral300 border-2 rounded-[4px] mb-1 bg-Neutral100 hover:border-Neutral600 hover:text-Neutral800 cursor-pointer transition duration-300 ease-in-out">
                <input
                  type="number"
                  value={addfilter?.value[0]?.from}
                  placeholder="1,000"
                  onChange={(e) => {
                    setAddFilter({
                      ...addfilter,
                      value: [
                        {
                          from: e.target.value,
                          to: addfilter?.value[0]?.to,
                        },
                      ],
                    });
                  }}
                  className="w-full focus:outline-none number-input text-right"
                />
              </div>
            </div>
            <div className="flex items-center justify-between pt-[20px] relative">
              <span className="text-Neutral700 text-xs font-medium block capitalize">
                To:
              </span>
              <div className="flex items-center relative py-[6px] px-2 w-[111px] text-Neutral900 text-xs font-medium capitalize border-Neutral300 border-2 rounded-[4px] mb-1 bg-Neutral100 hover:border-Neutral600 hover:text-Neutral800 cursor-pointer transition duration-300 ease-in-out">
                <input
                  type="number"
                  value={addfilter?.value[0]?.to}
                  placeholder="100,000"
                  onChange={(e) => {
                    setAddFilter({
                      ...addfilter,
                      value: [
                        {
                          from: addfilter?.value[0]?.from,
                          to: e.target.value,
                        },
                      ],
                    });
                  }}
                  className="w-full focus:outline-none number-input text-right"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default InvoiceFilter;
