import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import CloseBtn from "../../../assets/Images/close-btn.svg";
import { initContractContent } from "../../../store/actions/documentsAction";
import { initInvoice } from "../../../store/actions/invoicesAction";
import { initProject } from "../../../store/actions/projectAction";
import { InitialContent } from "../../../store/actions/proposalAction";
import { initQuestionnaire } from "../../../store/actions/questionnaireAction";
import { customStyles } from "../../../utils/modalStyles";
import TemplateInfoModal from "../../Templates/TemplateInfo/TemplateInfoModal";
import { MODAL_COLORS } from "./NewTemplateModal";
import { getTemplateList } from "../../../store/actions/templateAction";

export const SelectTemplate = ({
  isOpen,
  setIsOpen,
  type,
  clientId,
  openNewTemplateModal = null,
  id,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const templateList = useSelector((state) => state.template.template);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  useEffect(() => {
    if (isOpen && !templateList?.[type?.toLowerCase()]?.length) {
      dispatch(getTemplateList());
    }
  }, [isOpen]);
  
  const handleCustom = () => {
    if (type === "Proposal") {
      dispatch(
        InitialContent(
          {
            // client_id: clientId,
            [clientId && "client_id"]: clientId,
            [id && "lead"]: id,
            type: "custom",
            template_id: selectedTemplate?._id,
            is_lead: id ? true : false,
          },
          setIsOpen,
          navigate
        )
      );
    }
    if (type === "Contract") {
      dispatch(
        initContractContent(
          {
            client_id: clientId,
            type: "custom",
            template_id: selectedTemplate?._id,
          },
          setIsOpen,
          navigate
        )
      );
    }
    if (type === "Questionnaire") {
      dispatch(
        initQuestionnaire(
          {
            [clientId && "client_id"]: clientId,
            [id && "lead"]: id,
            type: "custom",
            template_id: selectedTemplate?._id,
            is_lead: id ? true : false,
          },
          setIsOpen,
          navigate
        )
      );
    }
    if (type === "Invoice") {
      dispatch(
        initInvoice(
          {
            client_id: clientId,
            type: "custom",
            template_id: selectedTemplate?._id,
          },
          setIsOpen,
          navigate
        )
      );
    }
    if (type === "Project") {
      dispatch(
        initProject(
          {
            client_id: clientId,
            type: "custom",
            template_id: selectedTemplate?._id,
          },
          setIsOpen,
          navigate
        )
      );
    }
    setIsOpen(false);
    setSelectedTemplate(null);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={{
        ...customStyles,
        content: {
          ...customStyles.content,
          padding: "70px 47px",
          overflowY: "hidden",
          maxHeight: "670px",
        },
      }}
      closeTimeoutMS={300}
      contentLabel="New Template Modal"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp max-w-[752px] max-h-[520px]">
        <div className="bg-white pt-8 pb-10 rounded-[8px]">
          <div className="px-6 text-left sm:px-12">
            <h3
              className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full uppercase"
              id="modal-title"
            >
              Select A Template
            </h3>
          </div>

          <div className="mt-10 overflow-y-scroll h-[318px] px-6 sm:px-12">
            {templateList?.isLoading ? (
              "Loading.."
            ) : templateList?.[type?.toLowerCase()]?.length ? (
              <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
                {templateList?.[type?.toLowerCase()]?.map((template, i) => (
                  <TemplateInfoModal
                    key={i}
                    title={template?.invoiceName || template?.title}
                    id={template?._id}
                    tags={
                      type === "Invoice"
                        ? template?.categoryTags?.map((tag) => tag?.name)
                        : template?.category?.map((tag) => tag?.name)
                    }
                    color={MODAL_COLORS[type]}
                    handleSelect={() => setSelectedTemplate(template)}
                    selectedTemplate={selectedTemplate}
                  />
                ))}
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center">
                {type === "Project" && (
                  <h3 className="text-sm font-semibold text-Neutral700">
                    No templates yet. Start by creating a reusable Project Template under a Project
                  </h3>
                )}
                {type !== "Project" && (
                  <>
                    <h3 className="text-sm font-semibold text-Neutral700">No Templates Found</h3>
                    <p className="text-sm font-normal text-Neutral700">
                      Please create a new template
                    </p>
                    <div className="mt-5">
                      <button
                        onClick={() => openNewTemplateModal()}
                        className="text-Neutral700 text-[40px] bg-Neutral300 rounded-[50%] w-[70px] h-[70px]"
                      >
                        +
                      </button>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => setIsOpen(false)}
        >
          <img src={CloseBtn} alt="" />
        </div>
        {selectedTemplate && (
          <button
            onClick={handleCustom}
            className="flex items-center justify-center absolute left-[50%] translate-[-50%] p-5 rounded-2xl bottom-[-100px] z-30 cursor-pointer uppercase bg-AccentRegular text-Neutral000 font-bold text-sm"
            style={{ transform: "translateX(-50%)" }}
          >
            {type === "Project" ? `Continue to Project Dashboard` : `Go to Templates Builder`}
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="ml-2"
            >
              <path
                d="M24.5303 12.5303C24.8232 12.2374 24.8232 11.7626 24.5303 11.4697L19.7574 6.6967C19.4645 6.40381 18.9896 6.40381 18.6967 6.6967C18.4038 6.9896 18.4038 7.46447 18.6967 7.75736L22.9393 12L18.6967 16.2426C18.4038 16.5355 18.4038 17.0104 18.6967 17.3033C18.9896 17.5962 19.4645 17.5962 19.7574 17.3033L24.5303 12.5303ZM-1.31134e-07 12.75L24 12.75L24 11.25L1.31134e-07 11.25L-1.31134e-07 12.75Z"
                fill="white"
              />
            </svg>
          </button>
        )}
      </div>
    </Modal>
  );
};
