import React, { useState } from "react";
import AdminNavbar from "../Navbars/AdminNavbar";

const Questionnaire = () => {
  const [isTab, setIsTab] = useState(true);

  return (
    <>
      <AdminNavbar
        link="/admin/clients/bobbuilderino"
        title="Questionnaire"
        backLink="Back to Bob Builderino Dashboard"
      />
      <div className="5xl:px-[96px] px-12 w-full mt-8">
        <div className="pt-[20px] max-w-[387px] 5xl:max-w-[446px] px-8 pb-8 border border-Neutral400 rounded-[4px]">
          <p className="text-AccentMediumDark text-xs flex items-center pb-[17px]">
            <span className="inline-block h-3 w-3 rounded-full bg-questionnaire border-[3px] mr-2"></span>{" "}
            Last updated <b className="ml-[3px]"> 3 days ago</b>
          </p>
          <h2 className="source-code-pro text-[28px] font-[900] text-Neutral900">
            2/12
          </h2>
          <p className="pt-2 text-Neutral800 uppercase text-xs font-[500] tracking-[0.08em]">
            Questions answered
          </p>
        </div>
        <div className="mt-16">
          <div className="bg-white border rounded-full mb-8 cursor-pointer text-Neutral500 flex 5xl:max-w-[240px] max-w-[192px] w-full mt-[34px] h-[32px] 5xl:h-12">
            <div
              onClick={() => setIsTab(true)}
              className={`transition duration-300 flex items-center justify-center w-full 5xl:max-w-[121px] max-w-[96px] min-w-[96px] 5xl:min-w-[100px] text-center rounded-full leading-4 transform text-xs ease-in-out mt-[-1px] mb-[-1px] ml-[-1px] ${
                isTab
                  ? "checkmarkicon text-white mt-[-1px] mb-[-1px] ml-[-1px] drop-shadow-Texts"
                  : ""
              }`}
            >
              ANSWERED
            </div>
            <div
              onClick={() => setIsTab(false)}
              className={`transition duration-300 flex items-center justify-center w-full 5xl:max-w-[121px] max-w-[96px] min-w-[96px] 5xl:min-w-[100px] text-center rounded-full transform text-xs ease-in-out ${
                !isTab
                  ? "checkmarkicon text-white mt-[-1px] mb-[-1px] ml-[-1px] drop-shadow-Texts"
                  : ""
              }`}
            >
              PENDING
            </div>
          </div>
          {isTab ? (
            <div className="flex max-w-[822px] 5xl:max-w-[940px] flex-wrap">
              <div className="pt-[45px] max-w-[387px] 5xl:max-w-[446px] px-8 pb-[45px] border border-Neutral400 rounded-[4px] mr-6 mb-10">
                <div className="flex items-center justify-between">
                  <p className="pr-2 font-[700] text-sm text-Neutral900">
                    What Your Business Sells
                  </p>
                  <div className="flex items-center justify-center w-[28px] font-medium h-[28px] border border-Neutral300 rounded-full font-feature-settings text-[18px] 5xl:text-[22px] text-Neutral800">
                    2
                  </div>
                </div>
                <p className="font-[400] text-sm text-Neutral900 pt-7 pb-7">
                  What does your business sell? And what are your most
                  popular/profitable products or services?
                </p>
                <p className="font-[400] italic text-sm text-Neutral700 leading-[1.7] min-h-[133px] overflow-auto before:block before:absolute relative before:bottom-0 before:left-0 before:h-[28px] before:w-full questionnaire-text">
                  Id varius adipiscing porta in tortor. Nam amet eleifend
                  suspendisse semper tellus rhoncus tellus amet tellus.
                </p>
              </div>
              <div className="pt-[45px] max-w-[387px] 5xl:max-w-[446px] px-8 pb-[45px] border border-Neutral400 rounded-[4px] ml-6 mb-10">
                <div className="flex items-center justify-between">
                  <p className="pr-2 font-[700] text-sm text-Neutral900">
                    Target Market
                  </p>
                  <div className="flex items-center justify-center w-[28px] font-medium h-[28px] border border-Neutral300 rounded-full font-feature-settings text-[18px] 5xl:text-[22px] text-Neutral800">
                    3
                  </div>
                </div>
                <p className="font-[400] text-sm text-Neutral900 pt-7 pb-7">
                  Who is your target market and ideal client/customer?
                </p>
                <p className="font-[400] italic text-sm text-Neutral700 leading-[1.7] min-h-[133px] overflow-auto before:block before:absolute relative before:bottom-0 before:left-0 before:h-[28px] before:w-full questionnaire-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl
                  nec egestas ac ultrices senectus at vel nulla sit. Id varius
                  adipiscing porta in tortor. Nam amet eleifend suspendisse
                  semper tellus rhoncus tellus amet tellus. Imperdiet at justo
                  praesent tortor vel et, adipiscing turpis.
                </p>
              </div>
            </div>
          ) : (
            <div className="flex max-w-[822px] 5xl:max-w-[940px] flex-wrap">
              <div className="pt-[45px] max-w-[387px] 5xl:max-w-[446px] px-8 pb-[45px] border border-Neutral400 rounded-[4px] mr-6 w-[100%] mb-10">
                <div className="flex items-center justify-between">
                  <p className="pr-2 font-[700] text-sm text-Neutral900">
                    What Your Business Sells
                  </p>
                  <div className="flex items-center justify-center w-[28px] font-medium h-[28px] border border-Neutral300 rounded-full font-feature-settings text-[18px] 5xl:text-[22px] text-Neutral800">
                    2
                  </div>
                </div>
                <p className="font-[400] text-sm text-Neutral800 pt-7 pb-7 min-h-[124px]">
                  What does your business sell? And what are your most
                  popular/profitable products or services?
                </p>
              </div>
              <div className="pt-[45px] max-w-[387px] 5xl:max-w-[446px] px-8 pb-[45px] border border-Neutral400 rounded-[4px] ml-6 w-[100%] mb-10">
                <div className="flex items-center justify-between">
                  <p className="pr-2 font-[700] text-sm text-Neutral900">
                    Question Title
                  </p>
                  <div className="flex items-center justify-center w-[28px] font-medium h-[28px] border border-Neutral300 rounded-full font-feature-settings text-[18px] 5xl:text-[22px] text-Neutral800">
                    3
                  </div>
                </div>
                <p className="font-[400] text-sm text-Neutral800 pt-7 pb-7 min-h-[124px]">
                  Description of the question.
                </p>
              </div>
              <div className="pt-[45px] max-w-[387px] 5xl:max-w-[446px] px-8 pb-[45px] border border-Neutral400 rounded-[4px] mr-6 w-[100%] mb-10">
                <div className="flex items-center justify-between">
                  <p className="pr-2 font-[700] text-sm text-Neutral900">
                    Question Title
                  </p>
                  <div className="flex items-center justify-center w-[28px] font-medium h-[28px] border border-Neutral300 rounded-full font-feature-settings text-[18px] 5xl:text-[22px] text-Neutral800">
                    2
                  </div>
                </div>
                <p className="font-[400] text-sm text-Neutral800 pt-7 pb-7 min-h-[124px]">
                  Description of the question.
                </p>
              </div>
              <div className="pt-[45px] max-w-[387px] 5xl:max-w-[446px] px-8 pb-[45px] border border-Neutral400 rounded-[4px] ml-6 w-[100%] mb-10">
                <div className="flex items-center justify-between">
                  <p className="pr-2 font-[700] text-sm text-Neutral900">
                    Question Title
                  </p>
                  <div className="flex items-center justify-center w-[28px] font-medium h-[28px] border border-Neutral300 rounded-full font-feature-settings text-[18px] 5xl:text-[22px] text-Neutral800">
                    3
                  </div>
                </div>
                <p className="font-[400] text-sm text-Neutral800 pt-7 pb-7 min-h-[124px]">
                  Description of the question.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Questionnaire;
