import React from "react";
import CloseBtn from "../../assets/Images/close-btn.svg";
import BigShoesAvatar from "../../assets/Images/Big Shoes - Avatar 1.svg";
import Modal from "react-modal";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const ReactiveAccountModal = ({
  reactiveAccount,
  setReactiveAccount,
  loginReactiveResponce,
}) => {
  const handleSubmit = async () => {
    if (loginReactiveResponce?.payment_gateway === "lemonsqueezy") {
      window.open(loginReactiveResponce?.url, "_blank");
    }

    if (loginReactiveResponce?.payment_gateway === "paddle") {
      window.open(
        `${loginReactiveResponce?.type}/${loginReactiveResponce?._id}?type=${loginReactiveResponce?.type}`,
        "_self"
      );
    }
  };

  return (
    <Modal
      isOpen={reactiveAccount}
      onRequestClose={() => setReactiveAccount(false)}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="ACCOUNT CANCELLED"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="bg-white px-6 xs:px-12 pt-8 pb-10 rounded-[8px]">
          <div className="flex items-center justify-between border-b border-Neutral200 w-full">
            <span className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2">
              {loginReactiveResponce?.type === "reactive"
                ? "ACCOUNT CANCELLED"
                : "PAYMENT INCOMPLETE"}
            </span>
          </div>
          <div className="my-6 flex items-center justify-center">
            <img src={BigShoesAvatar} alt="" />
          </div>
          <div className="flex items-center justify-center w-[354px]">
            {loginReactiveResponce?.type === "reactive" ? (
              <span className="text-center text-sm font-normal text-Neutral800">
                Your account data will be archived for <b>30 days</b> in case
                you decide to come back.
              </span>
            ) : (
              <span className="text-center text-sm font-normal text-Neutral800">
                Looks like you left early. Complete payment to access your
                account.
              </span>
            )}
          </div>
          <div className="mt-8">
            <button
              onClick={handleSubmit}
              className="btn button-hover form-primary-btn relative text-sm text-center block text-Neutral100 bg-AccentRegular font-bold w-full py-3 rounded drop-shadow-Texts overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out"
            >
              <span className="relative z-10 drop-shadow-Texts">
                {loginReactiveResponce?.type === "reactive"
                  ? "Reactivate account"
                  : "Complete Payment"}
              </span>
            </button>
          </div>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => setReactiveAccount(false)}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
};

export default ReactiveAccountModal;
