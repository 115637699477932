import { useEffect, useMemo, useState } from "react";
// Require Editor JS files.
import axios from "axios";
import Froala from "froala-editor";
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins.pkgd.min.js";
import moment from "moment";
import FroalaEditor from "react-froala-wysiwyg";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Tribute from "tributejs";
import "tributejs/dist/tribute.css";

import DefaultLogo from "../../assets/Images/Logo.svg";
import SignatureModal from "../../components/Modals/SignatureModal-Editor";
import useGetBrandIdentityForPublicRoute from "../../hooks/useGetBrandIdentityForPublicRoute";
import { SaveContractContent } from "../../store/actions/documentsAction";
import {
  RemoveContractSignatureAction,
  RemoveSignatureAction,
  SaveContent,
} from "../../store/actions/proposalAction";
import { UpdateTemplate } from "../../store/actions/templateAction";
import { getContentHeightOfPage } from "../../utils/editor";
import EditorLoader from "../ContentLoaders/EditorLoader";
import "./froala_editor.pkgd.min.css";
import "./froala_style.min.css";

const buttonsConfig = {
  moreText: {
    buttons: [
      "bold",
      "italic",
      "underline",
      "strikeThrough",
      "subscript",
      "superscript",
      "fontFamily",
      "fontSize",
      "textColor",
      "backgroundColor",
      "inlineClass",
      "inlineStyle",
      "clearFormatting",
    ],
  },
  moreParagraph: {
    buttons: [
      "alignLeft",
      "alignCenter",
      "alignRight",
      "paragraphFormat",
      "formatOLSimple",
      "alignJustify",
      "formatOL",
      "formatUL",
      "paragraphStyle",
      "lineHeight",
      "outdent",
      "indent",
      "quote",
    ],
    buttonsVisible: 3,
  },
  moreRich: {
    buttons: [
      "trackChanges",
      "insertLink",
      "insertImage",
      "insertTable",
      "emoticons",
      "fontAwesome",
      "specialCharacters",
      "insertHR",
    ],
    buttonsVisible: 6,
  },
  moreMisc: {
    buttons: ["help", "undo", "redo", "insert"],
    align: "right",
    buttonsVisible: 4, //lowing this number will make the buttons appear in a dropdown
  },
  trackChanges: {
    buttons: [
      "showChanges",
      "applyAll",
      "removeAll",
      "applyLast",
      "removeLast",
    ],
    buttonsVisible: 0,
  },
};

const AppEditor = (props) => {
  const {
    mode,
    type,
    editor,
    setIsLoading,
    isLoading,
    textEditor,
    setUnsavedChanges,
  } = props;
  const dispatch = useDispatch();

  const params = useParams();
  const { clientId, leadId } = useParams();
  const { proposalId, contractId } = useParams();
  const { brandIdentity } = useGetBrandIdentityForPublicRoute(clientId);
  console.log("brandIdentity from:", brandIdentity);

  const urlType = params.type;

  const client_name = props?.clientDetail?.data?.client_name;
  const business_name = props?.clientDetail?.data?.business_name;
  const address = props?.clientDetail?.data?.address;
  const vat_number = props?.clientDetail?.data?.vat_number;
  const email = props?.clientDetail?.data?.email;
  const dateForReplace = moment().format("MMMM DD, YYYY");

  const token = useSelector((state) => state.auth.authToken);
  const loggedInUser = useSelector((state) => state.auth.user);

  const isWhiteLabelUser =
    brandIdentity?.status === "active" ||
    loggedInUser?.brandIdentity?.status === "active";
  const hasCustomLogo = Boolean(
    brandIdentity?.logo || loggedInUser?.brandIdentity?.logo
  );
  const hasCompanyName = Boolean(
    brandIdentity?.companyName || loggedInUser?.brandIdentity?.companyName
  );

  const [model, setModel] = useState();
  const [buttons, setButtons] = useState(buttonsConfig);
  const [pages, setPages] = useState(1);
  const [editorRef, setEditorRef] = useState();
  const [effectcall, setEffectCall] = useState(false);
  const [isFroalaInitialized, setIsFroalaInitialized] = useState(false);

  const tribute = useMemo(() => {
    const options = {
      trigger: "{",
      values: [
        {
          key: "1",
          label: "Date",
          slug: "date",
          value: dateForReplace,
        },
        {
          key: "2",
          label: "Client Name",
          slug: "clientname",
          value: client_name,
        },
        {
          key: "3",
          label: "Client Address",
          slug: "client address",
          value: address,
        },
        {
          key: "4",
          label: "Client VAT",
          slug: "vat number",
          value: vat_number,
        },
        {
          key: "5",
          label: "Client Email",
          slug: "email",
          value: email,
        },
        {
          key: "6",
          label: "Business Name",
          slug: "business name",
          value: business_name,
        },
        {
          key: "7",
          label: "Business Address",
          slug: "business address",
          value: address,
        },
        {
          key: "8",
          label: "Business VAT",
          slug: "business vat",
          value: vat_number,
        },
        {
          key: "9",
          label: "Business Email",
          slug: "business email",
          value: email,
        },
      ],
      lookup: (user) => user.label,
      fillAttr: "value",
      selectTemplate: function (item) {
        if (urlType) {
          return `<span>{${item.original.label}}<span/>`;
        } else {
          if (item.original.value === null) {
            return "-";
          } else {
            return item.original.value;
          }
        }
      },
    };

    return new Tribute(options);
  }, [client_name, vat_number, email]);

  useEffect(() => {
    if (props?.model) {
      setModel(props?.model);
    }
  }, [props?.model]);

  useEffect(() => {
    Froala.DefineIcon("insert", {
      NAME: "plus",
      SVG_KEY: "add",
    });

    Froala.RegisterCommand("insert", {
      title: "Page Break",
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      callback: function () {
        const html = this.html.get();

        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");

        const pages = doc.querySelectorAll(".page");
        if (pages.length) {
          const lastPage = pages[pages.length - 1];

          const tempDiv = document.createElement("div");
          tempDiv.innerHTML = getHTMLContent(pages.length);

          const pageBreak = tempDiv.querySelector(".page-break");
          const newPage = tempDiv.querySelector(".page");

          lastPage.after(pageBreak);
          pageBreak.after(newPage);
        } else {
          const tempDiv = document.createElement("div");
          tempDiv.innerHTML = getHTMLContent(pages.length);

          const pageBreak = tempDiv.querySelector(".page-break");
          const newPage = tempDiv.querySelector(".page");

          doc.body.appendChild(pageBreak);
          doc.body.appendChild(newPage);
        }

        const newHtml = doc.body.innerHTML;

        this.html.set(newHtml || "<p></p>");

        setPages(doc.querySelectorAll(".page").length);
      },
    });
    Froala.RegisterShortcut(49, "insert", null, 8, true);
  }, [pages]);

  useEffect(() => {
    Froala.POPUP_TEMPLATES["customPlugin.popup"] =
      "[_BUTTONS_][_CUSTOM_LAYER_]";

    Froala.PLUGINS.customPlugin = function (editor) {
      // Create custom popup.
      function initPopup() {
        // Load popup template.
        // Popup buttons.
        var popup_buttons = "";

        // Load popup template.
        var template = {
          buttons: popup_buttons,
          custom_layer: `<div class="custom-layer" data-mouseenter-event-set="true" style="padding: 10px;width: 250px;
          "><p style="
              font-size: 12px;
              color: gray;
              margin-bottom: 5px;
          ">3 New Variables are available to make dynamic <b>Client name, Business Name and Today's Date</b>.</p>
          <p style="
              font-size: 12px;
              font-weight: 600;
              margin-top: 5px;
              margin-bottom: 5px;
          ">How to use above variables ?</p>
          <ul><li style="
              font-size: 11px;
              color: gray;
          ">1] Type "{" and popup will comes up with above options.</li>
          <li style="
          font-size: 11px;
          color: gray;
      ">2] Select a option from the lists</li>
          <li style="
          font-size: 11px;
          color: gray;
      ">3] When you create a proposal/contract using this template, This variable converts into the actual value.
          
          </li></ul> </div>`,
        };

        // Create popup.
        var $popup = editor.popups.create("customPlugin.popup", template);

        return $popup;
      }

      // Show the popup
      function showPopup() {
        // Get the popup object defined above.
        var $popup = editor.popups.get("customPlugin.popup");

        // If popup doesn't exist then create it.
        // To improve performance it is best to create the popup when it is first needed
        // and not when the editor is initialized.
        if (!$popup) $popup = initPopup();

        // Set the editor toolbar as the popup's container.
        editor.popups.setContainer("customPlugin.popup", editor.$tb);

        // If the editor is not displayed when a toolbar button is pressed, then set BODY as the popup's container.
        // editor.popups.setContainer('customPlugin.popup', $('body'));

        // Trigger refresh for the popup.
        // editor.popups.refresh('customPlugin.popup');

        // This custom popup is opened by pressing a button from the editor's toolbar.
        // Get the button's object in order to place the popup relative to it.
        var $btn = editor.$tb.find('.fr-command[data-cmd="help"]');

        // Compute the popup's position.
        var left = $btn.offset().left + $btn.outerWidth() / 2;
        var top =
          $btn.offset().top +
          (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10);

        // Show the custom popup.
        // The button's outerHeight is required in case the popup needs to be displayed above it.
        editor.popups.show("customPlugin.popup", left, top, $btn.outerHeight());
      }

      // Hide the custom popup.
      function hidePopup() {
        editor.popups.hide("customPlugin.popup");
      }

      // Methods visible outside the plugin.
      return {
        showPopup: showPopup,
        hidePopup: hidePopup,
      };
    };
    Froala.DefineIcon("buttonIcon", { NAME: "i", template: "text" });
    Froala.RegisterCommand("help", {
      title: "Info",
      icon: "buttonIcon",
      undo: false,
      focus: false,
      popup: true,
      plugin: "customPlugin",
      callback: function () {
        this.customPlugin.showPopup();
      },
    });
  }, [Froala]);

  const getHTMLContent = (page) => {
    return `<div class="page-break" contenteditable="false"><span class="page-break-label">Page ${
      page + 1
    }</span></div><div class="page"><p></p></div>`;
  };

  useEffect(() => {
    if (props.model && !props.model.includes("page")) {
      setModel(`<div class="page">${props.model}</div>`);
    }
  }, [props.model, client_name, business_name]);

  useEffect(() => {
    const htmlToElem = (html) => {
      let temp = document.createElement("template");
      html = html.trim(); // Never return a space text node as a result
      temp.innerHTML = html;
      return temp.content.firstChild;
    };

    let signatureBlock = document.getElementById("signature-block");
    let editorView = document.getElementsByClassName("fr-view");

    if (!signatureBlock && editorView && editorView.length > 0) {
      editorView[0].setAttribute("id", "froala-editor-view");
      const editorContent = document.getElementById("froala-editor-view");

      let para = htmlToElem(
        `<div id="signature-block">
          <div class="flex ${
            textEditor?.client_signature?.html || mode === "ghost"
              ? "disabled-close"
              : "enabled-close"
          }">
            ${
              textEditor?.user_signature?.html
                ? textEditor?.user_signature?.html
                : '<div class="w-1/2 drop-shadow-none" id="user-signature"></div>'
            }
            ${
              textEditor?.client_signature?.html
                ? textEditor?.client_signature?.html
                : '<div class="w-1/2 drop-shadow-none" id="client-signature"></div>'
            }
          </div>
          ${getBrandingFooterHTML()}
        </div>`
      );
      editorContent.appendChild(para);
      signatureBlock = document.getElementById("signature-block");
    }

    if (
      textEditor?.client_signature?.html ||
      textEditor?.user_signature?.html
    ) {
      const signatureBlock = document.getElementById("signature-block");
      if (signatureBlock) {
        signatureBlock.style.backgroundColor = "#fff";
        signatureBlock.style.margin = "auto";
        // if (mode === "normal") {
        //   signatureBlock.style.width = "21cm";
        // } else {
        signatureBlock.style.width = "100%";
        // }
        setTimeout(() => {
          setSignatureBlock(signatureBlock);
        }, 1000);
      }
    }
  }, [
    mode,
    textEditor?.client_signature?.html,
    textEditor?.user_signature?.html,
  ]);

  // useEffect(() => {
  //   const htmlToElem = (html) => {
  //     let temp = document.createElement("template");
  //     html = html.trim(); // Never return a space text node as a result
  //     temp.innerHTML = html;
  //     return temp.content.firstChild;
  //   };
  //   if (effectcall) {
  //     let signatureBlock = document.getElementById("signature-block");
  //     let editorview = document.getElementsByClassName("fr-view");
  //     if (!signatureBlock && editorview && editorview.length > 0) {
  //       editorview[0].setAttribute("id", "froala-editor-view");
  //       const editorContent = document.getElementById("froala-editor-view");
  //       let para = htmlToElem(
  //         `<div id="signature-block" class="flex ${
  //           textEditor?.client_signature?.html || mode === "ghost"
  //             ? "disabled-close"
  //             : "enabled-close"
  //         }">
  //           ${
  //             textEditor?.user_signature?.html
  //               ? textEditor?.user_signature?.html
  //               : '<div class="w-1/2 drop-shadow-none" id="user-signature"></div>'
  //           }
  //           ${
  //             textEditor?.client_signature?.html
  //               ? textEditor?.client_signature?.html
  //               : ' <div class="w-1/2 drop-shadow-none" id="client-signature"></div>'
  //           }
  //         </div>`
  //       );
  //       editorContent.appendChild(para);
  //       signatureBlock = document.getElementById("signature-block");
  //       signatureBlock.style.backgroundColor = "#fff";
  //       signatureBlock.style.margin = "auto";
  //       signatureBlock.style.width = "100%";
  //       setTimeout(() => {
  //         setSignatureBlock(signatureBlock);
  //       }, 1000);
  //     }
  //     // if (
  //     //   textEditor?.client_signature?.html ||
  //     //   textEditor?.user_signature?.html
  //     // ) {
  //     //   const editorContent = document.getElementById("froala-editor-view");
  //     //   const signatureBlock = document.getElementById("signature-block");
  //     //   signatureBlock.style.backgroundColor = "#fff";
  //     //   signatureBlock.style.margin = "auto";
  //     //   // if (mode === "normal") {
  //     //   //   signatureBlock.style.width = "21cm";
  //     //   // } else {
  //     //   signatureBlock.style.width = "100%";
  //     //   // }
  //     //   setTimeout(() => {
  //     //     setSignatureBlock(signatureBlock, editorContent);
  //     //   }, 1000);
  //     // }
  //   }
  // }, [effectcall]);

  useEffect(() => {
    if (
      textEditor?.client_signature?.html ||
      textEditor?.user_signature?.html
    ) {
      setEffectCall(true);
    }
  }, [textEditor, brandIdentity?.logo]);

  const callSaveContentAPI = async (data) => {
    if (data === '<div class="page"><p><br></p></div>') return;
    if (data === '<div class="page"></div>') return;
    if (data === '<div class="page"><p></p></div>') return;
    if (data === "<p><br></p><p><br></p>") return;
    const removeSpace = data.replaceAll(/&nbsp;/g, "");
    if (removeSpace === "<p></p>") return;
    if (model === "<p></p>") return;
    setUnsavedChanges(true);
    if (urlType) {
      const type = urlType[0].toUpperCase() + urlType.slice(1);
      if (urlType === "proposal") {
        const value = {
          proposalId: params?.id,
          type,
          data,
        };
        dispatch(UpdateTemplate(value, setUnsavedChanges));
      } else if (urlType === "contract") {
        const value = {
          contractId: params?.id,
          type,
          data,
        };
        dispatch(UpdateTemplate(value, setUnsavedChanges));
      }
    } else {
      if (type === "proposal") {
        dispatch(
          SaveContent(
            {
              content: data,
              client_id: clientId,
              proposal_id: proposalId,
              lead_id: leadId,
            },
            setUnsavedChanges
          )
        );
      }
      if (type === "contract") {
        dispatch(
          SaveContractContent(
            {
              content: data,
              client_id: clientId,
              contract_id: contractId,
            },
            setUnsavedChanges
          )
        );
      }
    }
    if (!data && model) {
      setModel(data);
    }
    if (!data) {
      setModel(`<div class="page"></div>`);
    }
    if (data && !data.includes("page")) {
      setModel(`<div class="page">${data}</div>`);
      editor?.current?.editor?.selection?.setAtEnd(
        editor?.current?.editor?.$el[0]
      );
      editor?.current?.editor?.selection?.restore();
    }
    // setTextEditor({
    //   ...textEditor,
    //   text: data,
    // });
  };

  useEffect(() => {
    if (editor.current.editor) {
      setEditorRef(editor.current.editor);
      editorRef && setIsFroalaInitialized(true);
    }
  }, [editor.current]);

  useEffect(() => {
    if (isFroalaInitialized) {
      tribute.attach(editorRef.el);
    }
  }, [isFroalaInitialized, tribute]);

  useEffect(() => {
    if (editor?.current) {
      var newButtons = buttons;
      if (!newButtons.moreMisc.buttons.includes("insert")) {
        newButtons.moreMisc.buttons.push("insert");
      }
      if (!newButtons.moreMisc.buttons.includes("info")) {
        newButtons.moreMisc.buttons.push("info");
      }
      setButtons(newButtons);
      setIsLoading(false);
    }
  }, [editor, isLoading]);

  useEffect(() => {
    if (editor && editor.current && editor.current.editor) {
      if (mode === "ghost" || mode === "preview") {
        editor?.current?.editor?.edit?.off();
        editor?.current?.editor?.toolbar?.hide();
      } else {
        editor?.current?.editor?.edit?.on();
        editor?.current?.editor?.toolbar?.show();
      }
    }
  }, [editor?.current?.editor, mode]);

  const setSignatureBlock = (signatureBlock) => {
    const divContainer = document.getElementById("froala-editor-view");
    const lastPage =
      divContainer?.querySelectorAll(".page")[
        divContainer?.querySelectorAll(".page").length - 1
      ];
    if (divContainer && signatureBlock) {
      // if (mode === "ghost" || mode === "preview") {
      signatureBlock.style.width = "100%";
      if (lastPage) {
        const pageHeight = getContentHeightOfPage(lastPage, [
          "signature-block",
        ]);
        if (lastPage?.clientHeight > pageHeight + 296) {
          lastPage?.appendChild(signatureBlock);
        } else {
          const tempDivPageBreak = document.createElement("div");
          tempDivPageBreak.className = "page-break";
          tempDivPageBreak.innerHTML = `<span class="page-break-label">Page ${
            divContainer?.querySelectorAll(".page")?.length
          }</span>`;
          const tempDivPage = document.createElement("div");
          tempDivPage.className = "page";
          tempDivPage.append(signatureBlock);
          lastPage.after(tempDivPageBreak);
          tempDivPageBreak.after(tempDivPage);
        }
      }
      // } else {
      //   signatureBlock.style.width = "21cm";
      //   divContainer.after(signatureBlock);
      // }
    }
  };

  const handleRemoveSignature = async (userType) => {
    if (type === "proposal") {
      const response = dispatch(
        RemoveSignatureAction(userType, params.proposalId)
      );
      if (response) {
        document.getElementById(`${userType}_signature`).remove();
      }
    } else {
      const response = dispatch(
        RemoveContractSignatureAction(userType, params.contractId)
      );
      if (response) {
        document.getElementById(`${userType}_signature`).remove();
      }
    }
  };

  const imageUploadURL = `${process.env.REACT_APP_API_URL}api/v1/${
    urlType ? "template" : type
  }/upload-images`;

  const getBrandingFooterHTML = () => {
    const companyName =
      brandIdentity.companyName || loggedInUser?.brandIdentity?.companyName;

    if (hasCustomLogo && hasCompanyName) {
      // Case 1: Custom logo and company name
      return `
      <div id="footer-default" class="p-4 pt-12 bg-Neutral000">
        <a
          href="#"
          target="_blank"
          class="text-[10px] font-normal leading-[7px] flex items-center justify-center"
          rel="noreferrer"
        >
          <img src="${brandIdentity.logo}" alt="${companyName} Logo" class="mr-[6px] h-6 w-6" id="cm_logo" />
          <span class="text-Neutral700">
            Made with
          </span>
          <span class="text-gray-500 font-bold ml-[2px]">
            ${companyName}
          </span>
        </a>
      </div>
    `;
    } else if (hasCustomLogo && !hasCompanyName) {
      // Case 2: Custom logo only
      return `
      <div id="footer-default" class="p-4 pt-12 bg-Neutral000 flex items-center justify-center">
        <img src="${brandIdentity.logo}" alt="Company Logo" class="h-6 w-6" id="cm_logo" />
      </div>
    `;
    } else if (!hasCustomLogo && !isWhiteLabelUser) {
      // Case 3: Default branding
      return `
      <div id="footer-default" class="p-4 pt-12 bg-Neutral000">
        <a
          href="https://www.clientmanager.io/"
          target="_blank"
          class="text-[10px] font-normal leading-[7px] flex items-center justify-center"
          rel="noreferrer"
        >
          <img src="${DefaultLogo}" alt="ClientManager Logo" class="mr-[6px] h-6 w-6" id="cm_logo" />
          <span class="text-Neutral700"> 
            Made with
          </span>
          <span class="text-gray-500 font-bold ml-[2px]">
            ClientManager
          </span>
        </a>
      </div>
    `;
    } else if (!hasCustomLogo && isWhiteLabelUser) {
      // Case 4: No branding
      return ""; // Return an empty string to show nothing
    } else {
      // Optional: Handle any unexpected cases
      return "";
    }
  };

  return (
    <div
      style={{
        padding: mode === "ghost" && "50px",
        maxWidth: mode === "ghost" && "1000px",
        margin: mode === "ghost" && "0 auto",
      }}
      className={`${mode !== "ghost" && "froala-editor"}`}
    >
      {isLoading && <EditorLoader mode={mode} />}
      <div className={`${isLoading ? "hidden" : ""}`} id="expand-editor">
        <FroalaEditor
          docId="froala-editor-123"
          editable={false}
          ref={editor}
          model={model}
          onModelChange={(e) => {
            let values = e;
            values = values.replaceAll(
              "<hr>",
              "<p class='horizontal-line'></p>"
            );
            if (textEditor?.client_signature?.html) {
              document.getElementById(
                "froala-editor-view"
              ).contentEditable = false;
              return true;
            }
            if (mode === "normal") {
              setModel(values);
              if (values !== model) {
                callSaveContentAPI(values);
              }
            }
          }}
          contenteditable="false"
          config={{
            toolbarStcky: true,
            documentReady: true,
            key: "re1H1qB1A2E6A5C4E5B4iAa1Tb1YZNYAh1CUKUEQOHFVANUqD1G1F4B3B1C8A4D2B4G3==",
            attribution: false,
            imageUploadURL: imageUploadURL,
            imageUploadMethod: "POST",
            imageUploadRemoteUrls: true,
            requestHeaders: {
              Authorization: `${token}`,
              workspace_id: axios.defaults.headers["workspace_id"],
            },
            imageUploadParam: "image",
            imageUploadParams: {
              type: type?.charAt(0).toUpperCase() + type?.slice(1),
              client: clientId,
              is_cover: false,
              [type === "proposal" ? "proposalId" : "contractId"]:
                type === "proposal" ? urlType ? params?.id : proposalId : urlType ? params?.id : contractId,
            },
            events: {
              "initialized": function () {
                if (mode === "ghost" || mode === "preview") {
                  this?.edit?.off();
                  this?.toolbar?.hide();
                } else {
                  this?.edit?.on();
                  this?.toolbar?.show();
                }
              },
              "commands.before": function (cmd, param1, param2) {
                if (cmd === "getPDF") {
                  // editor?.current?.editor?.commands?.exec("getPDF");
                  // setTimeout(() => {
                  // setModel(model);
                  // }, 1000);
                  // return false;
                  //this code is refreshing component over and over again due to useeffect on model
                  //css in print mode for editor is making page css go off screen
                }
              },
              "click": function (e) {
                let targetElement = e.target;
                if (targetElement.id === "remove-user-sign") {
                  handleRemoveSignature("user");
                }

                if (targetElement.classList.contains("page-break-label")) {
                  targetElement = targetElement.parentElement;
                }

                if (targetElement.classList.contains("page-break")) {
                  if (
                    window.confirm(
                      "Are you sure you want to delete this page break?"
                    )
                  ) {
                    targetElement.remove(); // Remove the page break div element
                  }
                }
              },
            },
            shortcutsEnabled: [
              "insert",
              "bold",
              "getPDF",
              "undo",
              "redo",
              "info",
            ],
            placeholderText: "Edit Your Content Here!",
            charCounterCount: true,
            colorsHEXInput: true,
            colorsBackground: [
              "#61BD6D",
              "#1ABC9C",
              "#54ACD2",
              "#2C82C9",
              "#9365B8",
              "#475577",
              "#CCCCCC",
              "#41A85F",
              "#00A885",
              "#3D8EB9",
              "#2969B0",
              "#553982",
              "#28324E",
              "#000000",
              "#F7DA64",
              "#FBA026",
              "#EB6B56",
              "#E25041",
              "#A38F84",
              "#EFEFEF",
              "#FFFFFF",
              "#FAC51C",
              "#F37934",
              "#D14841",
              "#B8312F",
              "#7C706B",
              "#D1D5D8",
            ],
            toolbarButtons: {
              ...buttons,
            },
          }}
        />
        {!urlType && (
          <div className="flex w-full max-w-[788px] relativeCSS px-16 mx-auto -mt-1 mt-3 justify-between items-center bg-white">
            <SignatureModal
              textEditor={textEditor}
              visible={false}
              isEditable={mode !== "ghost"}
              who="user"
              type={type}
            />
            <SignatureModal
              textEditor={textEditor}
              visible={true}
              isEditable={!Boolean(textEditor?.client_signature?.html)}
              who="client"
              type={type}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AppEditor;
