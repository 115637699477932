import Modal from "react-modal";

import CloseBtn from "../../assets/Images/close-btn.svg";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const ViewClientDetail = ({
  isOpen,
  setIsOpen,
  clientDetail,
  handleEditOpen,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="View Form Preview Modal"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all w-[80vw] sm:my-8 sm:min-w-[500px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="bg-white px-6 xs:px-12 pt-8 pb-12 rounded-[8px]">
          <div>
            <div className="text-left">
              <h3
                className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-8"
                id="modal-title"
              >
                CLIENT DETAILS
              </h3>
            </div>
            <div className="flex flex-col gap-y-4">
              <div className="flex items-center justify-start">
                <div className="flex items-center justify-center w-6 h-6 mr-4 rounded-full bg-Neutral200">
                  <svg
                    className="object-cover w-5 h-5"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 15.75V14.25C15 13.4544 14.6839 12.6913 14.1213 12.1287C13.5587 11.5661 12.7956 11.25 12 11.25H6C5.20435 11.25 4.44129 11.5661 3.87868 12.1287C3.31607 12.6913 3 13.4544 3 14.25V15.75"
                      stroke="var(--accent-color)" //#C2C2CC
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9 8.25C10.6569 8.25 12 6.90685 12 5.25C12 3.59315 10.6569 2.25 9 2.25C7.34315 2.25 6 3.59315 6 5.25C6 6.90685 7.34315 8.25 9 8.25Z"
                      stroke="var(--accent-color)" //#C2C2CC
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="flex flex-col">
                  <div className="flex items-center justify-start">
                    <p className="text-xs font-medium text-Neutral700">
                      Business Name
                    </p>
                  </div>
                  <h4 className="text-sm font-semibold text-Neutral900">
                    {clientDetail?.data?.business_name}
                  </h4>
                </div>
              </div>
              <div className="flex items-center justify-start">
                <div className="flex items-center justify-center w-6 h-6 mr-4 rounded-full bg-Neutral200">
                  <svg
                    className="object-cover w-5 h-5"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 15.75V14.25C15 13.4544 14.6839 12.6913 14.1213 12.1287C13.5587 11.5661 12.7956 11.25 12 11.25H6C5.20435 11.25 4.44129 11.5661 3.87868 12.1287C3.31607 12.6913 3 13.4544 3 14.25V15.75"
                      stroke="var(--accent-color)" //#C2C2CC
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9 8.25C10.6569 8.25 12 6.90685 12 5.25C12 3.59315 10.6569 2.25 9 2.25C7.34315 2.25 6 3.59315 6 5.25C6 6.90685 7.34315 8.25 9 8.25Z"
                      stroke="var(--accent-color)" //#C2C2CC
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="flex flex-col">
                  <div className="flex items-center justify-start">
                    <p className="text-xs font-medium text-Neutral700">
                      Contact Person
                    </p>
                  </div>
                  <h4 className="text-sm font-semibold text-Neutral900">
                    {clientDetail?.data?.contact_name}
                  </h4>
                </div>
              </div>
              <div className="flex items-center justify-start">
                <div className="flex items-center justify-center w-6 h-6 mr-4 rounded-full bg-Neutral200">
                  <svg
                    className="object-cover w-5 h-5"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.5625 3.75H14.0625C14.7844 3.75 15.375 4.34062 15.375 5.0625V12.9375C15.375 13.6594 14.7844 14.25 14.0625 14.25H3.5625C2.84062 14.25 2.25 13.6594 2.25 12.9375V5.0625C2.25 4.34062 2.84062 3.75 3.5625 3.75Z"
                      stroke="var(--accent-color)" //#C2C2CC
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15.375 5.0625L8.8125 9.65625L2.25 5.0625"
                      stroke="var(--accent-color)" //#C2C2CC
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="flex flex-row gap-4">
                  <div className="flex flex-col">
                    <div className="flex items-center justify-start">
                      <p className="text-xs font-medium text-Neutral700">
                        Email
                      </p>
                    </div>
                    <h4 className="text-sm font-semibold text-Neutral900">
                      {clientDetail?.data?.email}
                    </h4>
                  </div>
                </div>
              </div>
              {/* PHONE */}
              {clientDetail?.data?.phone_number && (
                <div className="flex items-center justify-start">
                  <div className="flex items-center justify-center w-6 h-6 mr-4 rounded-full bg-Neutral200">
                    <svg
                      className="object-cover w-5 h-5"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.6 2.25H5.85C5.10442 2.25 4.5 2.85442 4.5 3.6V14.4C4.5 15.1456 5.10442 15.75 5.85 15.75H12.6C13.3456 15.75 13.95 15.1456 13.95 14.4V3.6C13.95 2.85442 13.3456 2.25 12.6 2.25Z"
                        stroke="var(--accent-color)" //#C2C2CC
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.22498 13.0503H9.23248"
                        stroke="var(--accent-color)" //#C2C2CC
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-col">
                    <div className="flex items-center justify-start">
                      <p className="text-xs font-medium text-Neutral700">
                        Phone Number
                      </p>
                    </div>
                    <h4 className="text-sm font-semibold text-Neutral900">
                      {clientDetail?.data?.phone_number}
                    </h4>
                  </div>
                </div>
              )}

              {/* Contact person job title */}
              {clientDetail?.data?.contact_person_job_title && (
                <div className="flex items-center justify-start">
                  <div className="flex items-center justify-center w-6 h-6 mr-4 rounded-full bg-Neutral200">
                    <svg
                      className="object-cover w-5 h-5"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 15.75V14.25C15 13.4544 14.6839 12.6913 14.1213 12.1287C13.5587 11.5661 12.7956 11.25 12 11.25H6C5.20435 11.25 4.44129 11.5661 3.87868 12.1287C3.31607 12.6913 3 13.4544 3 14.25V15.75"
                        stroke="var(--accent-color)" //#C2C2CC
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9 8.25C10.6569 8.25 12 6.90685 12 5.25C12 3.59315 10.6569 2.25 9 2.25C7.34315 2.25 6 3.59315 6 5.25C6 6.90685 7.34315 8.25 9 8.25Z"
                        stroke="var(--accent-color)" //#C2C2CC
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-col">
                    <div className="flex items-center justify-start">
                      <p className="text-xs font-medium text-Neutral700">
                        Contact Person Job Title
                      </p>
                    </div>
                    <h4 className="text-sm font-semibold text-Neutral900">
                      {clientDetail?.data?.contact_person_job_title}
                    </h4>
                  </div>
                </div>
              )}

              {/* ADDITIONAL INFO */}
              {clientDetail?.data?.second_contact_name ||
              clientDetail?.data?.second_phone_number ||
              clientDetail?.data?.second_email ||
              clientDetail?.data?.address ||
              clientDetail?.data?.website_one ||
              clientDetail?.data?.website_two ||
              clientDetail?.data?.second_contact_person_job_title ||
              clientDetail?.data?.vat_number ? (
                <>
                  {clientDetail?.data?.second_contact_name && (
                    <div className="flex items-center justify-start">
                      <div className="flex items-center justify-center w-6 h-6 mr-4 rounded-full bg-Neutral200">
                        <svg
                          className="object-cover w-5 h-5"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15 15.75V14.25C15 13.4544 14.6839 12.6913 14.1213 12.1287C13.5587 11.5661 12.7956 11.25 12 11.25H6C5.20435 11.25 4.44129 11.5661 3.87868 12.1287C3.31607 12.6913 3 13.4544 3 14.25V15.75"
                            stroke="var(--accent-color)" //#C2C2CC
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9 8.25C10.6569 8.25 12 6.90685 12 5.25C12 3.59315 10.6569 2.25 9 2.25C7.34315 2.25 6 3.59315 6 5.25C6 6.90685 7.34315 8.25 9 8.25Z"
                            stroke="var(--accent-color)" //#C2C2CC
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex items-center justify-start">
                          <p className="text-xs font-medium text-Neutral700">
                            Contact Person 2
                          </p>
                        </div>
                        <h4 className="text-sm font-semibold text-Neutral900">
                          {clientDetail?.data?.second_contact_name}
                        </h4>
                      </div>
                    </div>
                  )}
                  {clientDetail?.data?.second_phone_number && (
                    <div className="flex items-center justify-start">
                      <div className="flex items-center justify-center w-6 h-6 mr-4 rounded-full bg-Neutral200">
                        <svg
                          className="object-cover w-5 h-5"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.6 2.25H5.85C5.10442 2.25 4.5 2.85442 4.5 3.6V14.4C4.5 15.1456 5.10442 15.75 5.85 15.75H12.6C13.3456 15.75 13.95 15.1456 13.95 14.4V3.6C13.95 2.85442 13.3456 2.25 12.6 2.25Z"
                            stroke="var(--accent-color)" //#C2C2CC
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9.22498 13.0503H9.23248"
                            stroke="var(--accent-color)" //#C2C2CC
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex items-center justify-start">
                          <p className="text-xs font-medium text-Neutral700">
                            Phone Number 2
                          </p>
                        </div>
                        <h4 className="text-sm font-semibold text-Neutral900">
                          {clientDetail?.data?.second_phone_number}
                        </h4>
                      </div>
                    </div>
                  )}
                  {clientDetail?.data?.second_email && (
                    <div className="flex items-center justify-start">
                      <div className="flex items-center justify-center w-6 h-6 mr-4 rounded-full bg-Neutral200">
                        <svg
                          className="object-cover w-5 h-5"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.5625 3.75H14.0625C14.7844 3.75 15.375 4.34062 15.375 5.0625V12.9375C15.375 13.6594 14.7844 14.25 14.0625 14.25H3.5625C2.84062 14.25 2.25 13.6594 2.25 12.9375V5.0625C2.25 4.34062 2.84062 3.75 3.5625 3.75Z"
                            stroke="var(--accent-color)" //#C2C2CC
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M15.375 5.0625L8.8125 9.65625L2.25 5.0625"
                            stroke="var(--accent-color)" //#C2C2CC
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex items-center justify-start">
                          <p className="text-xs font-medium text-Neutral700">
                            Email 2
                          </p>
                        </div>
                        <h4 className="text-sm font-semibold text-Neutral900">
                          {clientDetail?.data?.second_email}
                        </h4>
                      </div>
                    </div>
                  )}
                  {clientDetail?.data?.second_contact_person_job_title && (
                    <div className="flex items-center justify-start">
                      <div className="flex items-center justify-center w-6 h-6 mr-4 rounded-full bg-Neutral200">
                        <svg
                          className="object-cover w-5 h-5"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15 15.75V14.25C15 13.4544 14.6839 12.6913 14.1213 12.1287C13.5587 11.5661 12.7956 11.25 12 11.25H6C5.20435 11.25 4.44129 11.5661 3.87868 12.1287C3.31607 12.6913 3 13.4544 3 14.25V15.75"
                            stroke="var(--accent-color)" //#C2C2CC
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9 8.25C10.6569 8.25 12 6.90685 12 5.25C12 3.59315 10.6569 2.25 9 2.25C7.34315 2.25 6 3.59315 6 5.25C6 6.90685 7.34315 8.25 9 8.25Z"
                            stroke="var(--accent-color)" //#C2C2CC
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex items-center justify-start">
                          <p className="text-xs font-medium text-Neutral700">
                            Contact Person 2 Job Title
                          </p>
                        </div>
                        <h4 className="text-sm font-semibold text-Neutral900">
                          {clientDetail?.data?.second_contact_person_job_title}
                        </h4>
                      </div>
                    </div>
                  )}
                  {clientDetail?.data?.address && (
                    <div className="flex items-center justify-start">
                      <div className="flex items-center justify-center w-6 h-6 mr-4 rounded-full text-Neutral500 bg-Neutral200">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex items-center justify-start">
                          <p className="text-xs font-medium text-Neutral700">
                            Address
                          </p>
                        </div>
                        <h4 className="text-sm font-semibold text-Neutral900">
                          {clientDetail?.data?.address}
                        </h4>
                      </div>
                    </div>
                  )}
                  {clientDetail?.data?.website_one && (
                    <div className="flex items-center justify-start">
                      <div className="flex items-center justify-center w-6 h-6 mr-4 rounded-full text-Neutral500 bg-Neutral200">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex items-center justify-start">
                          <p className="text-xs font-medium text-Neutral700">
                            Website 1
                          </p>
                        </div>
                        <h4 className="text-sm font-semibold text-Neutral900">
                          {clientDetail?.data?.website_one}
                        </h4>
                      </div>
                    </div>
                  )}
                  {clientDetail?.data?.website_two && (
                    <div className="flex items-center justify-start">
                      <div className="flex items-center justify-center w-6 h-6 mr-4 rounded-full text-Neutral500 bg-Neutral200">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex items-center justify-start">
                          <p className="text-xs font-medium text-Neutral700">
                            Website 2
                          </p>
                        </div>
                        <h4 className="text-sm font-semibold text-Neutral900">
                          {clientDetail?.data?.website_two}
                        </h4>
                      </div>
                    </div>
                  )}
                  {clientDetail?.data?.vat_number && (
                    <div className="flex items-center justify-start">
                      <div className="flex items-center justify-center w-6 h-6 mr-4 rounded-full text-Neutral500 bg-Neutral200">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex items-center justify-start">
                          <p className="text-xs font-medium text-Neutral700">
                            VAT Number
                          </p>
                        </div>
                        <h4 className="text-sm font-semibold text-Neutral900">
                          {clientDetail?.data?.vat_number}
                        </h4>
                      </div>
                    </div>
                  )}
                </>
              ) : null}

              {/* CUSTOM FIELDS */}
              {clientDetail?.data?.custom_fields?.map((field) => (
                <div className="flex items-center justify-start">
                  <div className="flex items-center justify-center w-6 h-6 mr-4 rounded-full text-Neutral500 bg-Neutral200">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-col">
                    <div className="flex items-center justify-start">
                      <p className="text-xs font-medium text-Neutral700">
                        {field?.key}
                      </p>
                    </div>
                    <h4 className="text-sm font-semibold text-Neutral900">
                      {field?.value}
                    </h4>
                  </div>
                </div>
              ))}
            </div>

            <button
              onClick={() => handleEditOpen()}
              className="flex justify-center items-center text-sm text-center text-AccentRegular bg-Bg-Regular font-bold w-full py-[17.5px] rounded overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight hover:bg-Bg-MediumDark mt-8"
            >
              Edit Client Details
            </button>
          </div>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => setIsOpen(false)}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
};

export default ViewClientDetail;
