import React, { useState } from "react";
import Modal from "react-modal";
import CloseBtn from "../../../assets/Images/close-btn.svg";
import ButtonLoader from "../../Loaders/ButtonLoader";
import { useDispatch } from "react-redux";
import { ImportLeadsAction } from "../../../store/actions/leadAction";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const PreviewLeadModal = ({
  isPreviewModalOpen,
  setIsPreviewModalOpen,
  previewData,
  setPreviewData,
}) => {
  const dispatch = useDispatch();
  const [isSubmitting, setisSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  return (
    <Modal
      isOpen={isPreviewModalOpen}
      onRequestClose={() => {
        setIsPreviewModalOpen(false);
        setPreviewData([]);
      }}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="Example Modal1"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp  px-1 h-2/3">
        <div className="bg-white px-6 xs:px-12 py-8 rounded-[8px] w-[80vw]">
          <div className="overflow-y-hidden max-h-[900px]">
            <div className="flex items-center justify-between border-b border-Neutral200 w-full">
              <span className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2">
                Preview Lead(s)
              </span>
            </div>

            <div className="flex items-start justify-between w-full overflow-y-scroll max-h-96 scrollbar-h-4 scrollbar-w-4">
              <table
                className="w-full text-left text-[14px] leading-[20px] text-Neutral700 font-normal border-collapse border border-slate-500 mt-[20px] max-h-96"
                cellSpacing="0"
              >
                <thead>
                  <tr>
                    <th className="py-[10px] border border-slate-600 px-[5px] text-center">
                      Name
                    </th>
                    <th className="py-[10px] border border-slate-600 px-[5px] text-center">
                      Email
                    </th>
                    <th className="py-[10px] border border-slate-600 px-[5px] text-center">
                      Source
                    </th>
                    <th className="py-[10px] border border-slate-600 px-[5px] text-center">
                      Phone Number
                    </th>
                    <th className="py-[10px] border border-slate-600 px-[5px] text-center">
                      Business Name
                    </th>
                    <th className="py-[10px] border border-slate-600 px-[5px] text-center">
                      Budget
                    </th>
                    {Object.keys(errors).length > 0 && (
                      <th className="py-[10px] border border-slate-600 px-[5px] text-center">
                        Errors
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {previewData.map((item, index) => (
                    <tr key={index}>
                      <td className="py-[10px] border border-slate-600 px-[5px] text-center">
                        {item?.name}
                      </td>
                      <td className="py-[10px] border border-slate-600 px-[5px] text-center">
                        {item?.email}
                      </td>
                      <td className="py-[10px] border border-slate-600 px-[5px] text-center">
                        {item?.source}
                      </td>
                      <td className="py-[10px] border border-slate-600 px-[5px] text-center">
                        {item?.phone_number}
                      </td>
                      <td className="py-[10px] border border-slate-600 px-[5px] text-center">
                        {item?.business_name}
                      </td>
                      <td className="py-[10px] border border-slate-600 px-[5px] text-center">
                        {item?.budget}
                      </td>
                      {Object.keys(errors).length > 0 && (
                        <td className="py-[10px] border border-slate-600 px-[5px] text-center text-red-500">
                          {Object.entries(errors).map(([key, value]) => {
                            if (key.includes(index)) {
                              return value;
                            }
                          })}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {Object.keys(errors).length > 0 && (
              <div className="text-left mt-[20px]">
                <span className="text-xs text-red-500 leading-[18px] font-semibold pb-2">
                  Note: Please check the errors in the file and correct them
                  before importing.
                </span>
              </div>
            )}

            <div className="flex justify-end mt-[20px]">
              <button
                className="bg-AccentRegular button-hover text-white text-[14px] leading-[20px] font-semibold px-[10px] py-[5px] rounded-[4px] mt-[20px] transition duration-300 ease-in-out w-[120px] h-[45px]"
                onClick={() => {
                  setisSubmitting(true);

                  const cleanedPreviewData = previewData.filter((item) => {
                    return Object.values(item).every(
                      (val) => val.trim() !== ""
                    );
                  });

                  const isProperFormat = cleanedPreviewData.every((item) => {
                    return item?.name.trim() !== "";
                  });
                  if (isProperFormat) {
                    dispatch(
                      ImportLeadsAction(
                        previewData,
                        setisSubmitting,
                        setPreviewData,
                        setIsPreviewModalOpen,
                        setErrors
                      )
                    );
                  } else {
                    alert(
                      "Please check the format of the file & Name is required"
                    );
                    setisSubmitting(false);
                  }
                }}
              >
                {isSubmitting ? (
                  <ButtonLoader className="z-10 min-w-[100px]" />
                ) : (
                  "IMPORT NOW"
                )}
              </button>
            </div>
          </div>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => {
            setIsPreviewModalOpen(false);
            setPreviewData([]);
          }}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
};

export default PreviewLeadModal;
