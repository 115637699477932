import { useSelector } from "react-redux";

import Logo from "../assets/Images/Logo.svg";

const useLogo = () => {
  const userProfile = useSelector((state) => state?.auth?.user);

  const userLogo = userProfile?.brandIdentity?.logo;

  return { Logo: userLogo ? userLogo : Logo, isCustom: !!userLogo };
};

export default useLogo;
