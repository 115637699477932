import React, { useEffect, useState } from "react";
import AdminNavbar from "../Navbars/AdminNavbar";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import FroalaBuilderComp from "../TextEditor/FroalaBuilderComp";
import { getLeadProposalAction } from "../../store/actions/proposalAction";
import { GetLeadsAction } from "../../store/actions/leadAction";

const LeadBuilder = () => {
  const { leadId } = useParams();
  const { proposalId } = useParams();
  const dispatch = useDispatch();

  const clientDetail = useSelector((state) => state.client.clientDetail);
  const leadsList = useSelector((state) => state?.lead?.leads);

  const [textEditor, setTextEditor] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [lead, setLead] = useState(
    leadsList.data.find((lead) => lead._id === leadId)
  );

  useEffect(() => {
    dispatch(GetLeadsAction());
  }, [dispatch]);

  const getServerProposalConnent = async () => {
    setIsLoading(true);
    const serverContent = await dispatch(
      getLeadProposalAction(leadId, proposalId)
    );
    if (serverContent?.data) {
      let proposalContent = serverContent?.data;
      setTextEditor({
        images: {
          url: proposalContent?.content?.cover?.url,
          key: proposalContent?.content?.cover?.key,
        },
        text: proposalContent?.content,
        ...proposalContent,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getServerProposalConnent();
  }, [leadId, dispatch, proposalId]);

  useEffect(() => {
    if (leadId !== lead?._id) {
      setLead(leadsList.data.find((lead) => lead?._id === leadId));
    }
  }, [leadId, leadsList]);

  return (
    <>
      <AdminNavbar
        link={`/admin/leads`}
        title="Lead Builder"
        backLink="Back to Leads Dashboard"
      />
      <div className="mt-[100px] pb-[1px]" />
      <FroalaBuilderComp
        clientDetail={clientDetail}
        textEditor={textEditor}
        type="proposal"
        isLoading={isLoading}
        setTextEditor={setTextEditor}
        setIsLoading={setIsLoading}
        handleReload={getServerProposalConnent}
        lead={lead}
      />
    </>
  );
};

export default LeadBuilder;
