import axios from "axios";
import { toast } from "react-toastify";

export const GetAssetsActions = (id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SET_ASSETS",
        payload: {
          isLoading: true,
        },
      });
      const { data } = await axios.get(
        `api/v1/assets/get-assets-by-client?id=${id}`
      );
      return dispatch({
        type: "SET_ASSETS",
        payload: { client: id, data: data.data },
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "GET_ASSETS_ERROR",
        payload: error,
      });
    }
  };
};

export const CreateAssetsActions = (
  value,
  type,
  setSubmitting,
  setErrors,
  closeModal
) => {
  return async (dispatch) => {
    try {
      let formData = new FormData();
      formData.append("client_id", value.client_id);
      formData.append("title", value.title);
      formData.append("type", value.type);
      formData.append("file_type", value.file_type);
      value?.file_type === "url" &&
        value?.url?.map((url) => {
          formData.append("url", url);
        });
      value?.file_type === "file" &&
        value.file?.map((file) => {
          formData.append("file", file);
        });
      const { data } = await axios.post("api/v1/assets/add-assets", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      });
      setSubmitting(false);
      closeModal();
      return dispatch({
        type: "SET_ASSETS",
        payload: { data: { [type]: data.data } },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setSubmitting(false);
        setErrors(err);
        return dispatch({
          type: "CREATE_SERVICE_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "CREATE_SERVICE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const UpdateAssetsActions = (
  value,
  type,
  setSubmitting,
  setErrors,
  closeModal
) => {
  return async (dispatch) => {
    try {
      let formData = new FormData();
      formData.append("item_id", value.id);
      formData.append("assets_id", value.assets_id);
      formData.append("title", value.title);
      formData.append("type", value.type);
      formData.append("file_type", value.file_type);
      value?.file_type === "url" &&
        value?.url?.map((url) => {
          formData.append("url", url);
        });
      value?.file_type === "file" &&
        value.file?.map((file) => {
          if (file?.key) {
            formData.append("file[]", JSON.stringify(file));
          } else {
            formData.append("file", file);
          }
        });
      value?.remove_file?.length &&
        value?.remove_file?.map((file) => {
          formData.append("remove_file[]", JSON.stringify(file));
        });
      const { data } = await axios.put("api/v1/assets/update-asset", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      });
      setSubmitting(false);
      closeModal();
      return dispatch({
        type: "SET_ASSETS",
        payload: { data: { [type]: data.data } },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setSubmitting(false);
        setErrors(err);
        return dispatch({
          type: "CREATE_SERVICE_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "CREATE_SERVICE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const DeleteServiceAction = (id, type, setIsDeleting) => {
  return async (dispatch) => {
    try {
      await axios.delete(`api/v1/service/delete-service?id=${id}`);
      setIsDeleting(false);
      return dispatch({
        type: "DELETE_SERVICE_SUCCESS",
        payload: { id, type },
      });
    } catch (error) {
      setIsDeleting(false);
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "DELETE_SERVICE_ERROR",
          payload: { error },
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "DELETE_SERVICE_ERROR",
          payload: { error },
        });
      }
    }
  };
};

export const DeleteAssetsAction = (
  formData,
  type,
  setLoading,
  setaccountCencelled
) => {
  return async (dispatch) => {
    try {
      await axios.post(`api/v1/assets/delete-asset`, formData);
      setLoading(false);
      setaccountCencelled(false);
      return dispatch({
        type: "DELETE_ASSETS_SUCCESS",
        payload: { data: formData, type: type },
      });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "DELETE_ASSETS_ERROR",
          payload: { error },
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "DELETE_ASSETS_ERROR",
          payload: { error },
        });
      }
    }
  };
};
