import axios from "axios";
import { toast } from "react-toastify";

export const updateDomainDetailsAction = (
  domain,
  setIsLoading,
  setCnameDetailsOpen
) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);
      let { data } = await axios.post(
        "api/v1/custom-domain/update-domain-details",
        domain
      );
      const response = data?.data;

      if (response?.custom_domain_name) {
        // IF NOT A CERTIFICATE THEN REQ FOR CERTIFICATE
        if (!response?.acm_certificateArn?.cartificate) {
          dispatch(requestForACMCertificate(setCnameDetailsOpen, setIsLoading));
          // toast.success(data?.message);
        }

        // IF CERIFICATE ALREDY EXIST AND REQ FOR NEW CERTIFICATE
        // MEANS USER UPDATE DOMAIN THAT TIME NEED TO DELETE OLD CERTIFICATE
        if (response?.acm_certificateArn?.cartificate) {
          setIsLoading(false);
          setCnameDetailsOpen(true);

          // const isDelete = await dispatch(deleteACMCertificate());
          // if (isDelete) {
          //   dispatch(
          //     requestForACMCertificate(setCnameDetailsOpen, setIsLoading)
          //   );
          // } else {
          //   toast.error("something went wrong in ACM certificate");
          // }
          toast.success(data?.message);
        }
      }

      return dispatch({
        type: "UPDATE_CUSTOM_DOMAIN",
        payload: { data: response },
      });
    } catch (error) {
      toast.error("Failed to add domain! please try again");
      setIsLoading(false);
      return dispatch({
        type: "UPDATE_CUSTOM_DOMAIN_ERROR",
        payload: error,
      });
    }
  };
};

export const getDomainDetailsAction = (
  setIsReloadSite,
  isReloadSite,
  setTab
) => {
  return async (dispatch) => {
    dispatch({
      type: "HANDLE_LOADING",
      payload: { isLoading: true },
    });
    try {
      let { data } = await axios.get("api/v1/custom-domain/get-domain-details");

      if (isReloadSite && data?.all_data?.cloudfront?.domain_name) {
        setTab(2);
      }

      setIsReloadSite(false);
      dispatch({
        type: "HANDLE_LOADING",
        payload: { isLoading: false },
      });
      return dispatch({
        type: "GET_CUSTOM_DOMAIN",
        payload: {
          cname_data: data?.cname_data,
          all_data: data?.all_data,
          certificate_details: data?.certificate_details,
        },
      });
    } catch (error) {
      dispatch({
        type: "HANDLE_LOADING",
        payload: { isLoading: false },
      });
      return dispatch({
        type: "GET_CUSTOM_DOMAIN_ERROR",
        payload: error,
      });
    }
  };
};

export const checkDnsPropogation = (domain_name, setIsReloadSite) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.get(
        `api/v1/custom-domain/check-dns-propagation/${domain_name}`
      );
      setIsReloadSite(false);
      return dispatch({
        type: "IS_DOMAIN_WORKING",
        payload: { data: data?.isPropogate },
      });
    } catch (error) {
      return dispatch({
        type: "IS_DOMAIN_WORKING_ERROR",
        payload: error,
      });
    }
  };
};

export const regenerateDomainAction = (
  domain,
  setIsLoading,
  setCnameDetailsOpen,
  setModalOpen
) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);
      let { data } = await axios.post(
        "api/v1/custom-domain/regenerate-domain",
        domain
      );
      const response = data?.data;
      if (response?.custom_domain_name) {
        setIsLoading(false);
        setCnameDetailsOpen(true);
        toast.success(data?.message);
      }
      setModalOpen(false);
      return dispatch({
        type: "UPDATE_CUSTOM_DOMAIN",
        payload: { data: response },
      });
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
      toast.error("Failed to regenerate domain! please try again");
      return dispatch({
        type: "UPDATE_CUSTOM_DOMAIN_ERROR",
        payload: error,
      });
    }
  };
};

export const requestForACMCertificate = (setCnameDetailsOpen, setIsLoading) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post(
        `api/v1/custom-domain/request-acm-certificate`
      );
      toast.success("Request has been send for your domain's certificate");
      setIsLoading(false);
      setCnameDetailsOpen(true);
      dispatch({
        type: "UPDATE_CUSTOM_DOMAIN",
        payload: { data: data?.data },
      });
      return dispatch({
        type: "IS_REQ_FOR_ACM_CERTI",
        payload: { data: data?.data?.acm_certificateArn.isReqForCertificate },
      });
    } catch (error) {
      setIsLoading(false);
      toast.error(
        "Error while request send for ACM certificate, please try again"
      );
      return dispatch({
        type: "IS_REQ_FOR_ACM_CERTI_ERROR",
        payload: error,
      });
    }
  };
};

const deleteACMCertificate = () => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post(
        `api/v1/custom-domain/delete-acm-certificate`
      );
      return data.IsDeleteCerti;
    } catch (error) {
      console.log("Error :", error);
    }
  };
};

export const getACMCertificateAction = () => {
  return async (dispatch) => {
    try {
      let { data } = await axios.get(
        `api/v1/custom-domain/get-acm-certificate`
      );
      return data.isCertiVerified;
    } catch (error) {
      return dispatch({
        type: "GET_ACM_CERTI_ERROR",
        payload: error,
      });
    }
  };
};
