import React from 'react';

const NotificationsEmptyState = () => {
  return (
    <div className="flex flex-col items-center gap-[13px] h-[136px] pt-5">
      <div>
        <svg
          width="27"
          height="22"
          viewBox="0 0 27 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_7692_93140)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.54547 10.7586C5.50797 10.5361 5.47972 10.3161 5.46147 10.0961C5.43397 9.76614 5.45097 9.43362 5.44122 9.10362C5.43697 8.96362 5.42022 8.59862 5.42697 8.54612C5.48822 8.08862 5.85747 8.01861 5.96547 8.00611C6.01772 7.99861 6.55422 7.93864 6.66997 8.53364C6.81197 9.26114 6.85747 9.99363 6.80722 10.7111C6.92447 11.3211 7.12497 11.9286 7.38972 12.4786C8.44322 14.6661 10.4507 14.8786 12.6257 14.8586C12.9602 14.8536 13.238 15.1161 13.2555 15.4486C13.273 15.7836 13.024 16.0736 12.691 16.1036C11.4752 16.2211 9.31821 17.1961 8.57246 18.2111C8.41646 18.4236 8.32572 18.9361 8.20572 19.4386C8.03822 20.1361 7.83897 20.8261 7.61572 21.1161C7.58222 21.1611 7.17497 21.6211 7.05347 21.6886C6.76147 21.8486 6.52772 21.7561 6.39497 21.6711C6.26222 21.5861 6.11197 21.4186 6.05197 21.1411C5.98922 20.8511 6.04222 20.2561 6.03247 20.1286C5.99697 19.6661 5.90448 18.7586 5.71573 17.9786C5.61398 17.5586 5.50547 17.1711 5.32597 16.9786C4.66147 16.2661 3.59897 16.3711 2.70422 16.4886C2.60147 16.5011 2.49872 16.5161 2.39622 16.5286C2.03197 16.6361 1.64521 16.7161 1.23646 16.7636C0.619214 16.8361 0.517721 16.2686 0.512221 16.2311C0.500721 16.1486 0.457967 15.7236 0.923217 15.5536C0.973967 15.5361 1.34847 15.4536 1.49272 15.4211C1.69372 15.3761 1.89697 15.3411 2.10097 15.3086C4.20822 14.6536 5.35272 12.8136 5.54547 10.7586ZM6.26872 13.0311C7.06422 14.6761 8.27147 15.4711 9.70247 15.8311H9.70222C8.80872 16.2986 7.99597 16.8836 7.56522 17.4711C7.40172 17.6936 7.25172 18.1411 7.11647 18.6436C7.04322 18.1761 6.94423 17.6786 6.81348 17.2536C6.66473 16.7686 6.46447 16.3661 6.24097 16.1261C5.80947 15.6636 5.26796 15.4061 4.68571 15.2786C5.38271 14.6611 5.91122 13.8911 6.26872 13.0311Z"
              fill="#6D6DF2"
            />
          </g>
          <g clipPath="url(#clip1_7692_93140)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.5649 1.77375C16.5408 1.63071 16.5227 1.4893 16.5109 1.34787C16.4933 1.13573 16.5042 0.92196 16.4979 0.709817C16.4952 0.619817 16.4844 0.385178 16.4888 0.351428C16.5281 0.0573204 16.7655 0.012315 16.8349 0.00427925C16.8685 -0.000542176 17.2134 -0.0390964 17.2878 0.343404C17.3791 0.811082 17.4084 1.28197 17.3761 1.74322C17.4514 2.13536 17.5803 2.52589 17.7505 2.87946C18.4278 4.28571 19.7183 4.42232 21.1165 4.40946C21.3316 4.40625 21.5101 4.575 21.5214 4.78875C21.5326 5.0041 21.3725 5.19053 21.1585 5.20982C20.3769 5.28535 18.9903 5.91215 18.5109 6.56465C18.4106 6.70125 18.3522 7.03072 18.2751 7.35376C18.1674 7.80215 18.0393 8.24572 17.8958 8.43215C17.8743 8.46107 17.6125 8.7568 17.5344 8.80019C17.3466 8.90305 17.1964 8.84357 17.111 8.78893C17.0257 8.73428 16.9291 8.62661 16.8905 8.44821C16.8502 8.26179 16.8843 7.87928 16.878 7.79731C16.8552 7.49999 16.7957 6.9166 16.6744 6.41517C16.609 6.14517 16.5392 5.89607 16.4238 5.77232C15.9967 5.31428 15.3136 5.38179 14.7384 5.45732C14.6724 5.46536 14.6063 5.47501 14.5404 5.48304C14.3063 5.55215 14.0576 5.60357 13.7949 5.63411C13.3981 5.68071 13.3328 5.31589 13.3293 5.29178C13.3219 5.23875 13.2944 4.96554 13.5935 4.85625C13.6261 4.845 13.8669 4.79196 13.9596 4.77107C14.0888 4.74214 14.2195 4.71965 14.3506 4.69876C15.7053 4.27769 16.441 3.09482 16.5649 1.77375ZM17.0299 3.23465C17.5413 4.29215 18.3174 4.80322 19.2373 5.03464H19.2371C18.6627 5.33518 18.1403 5.71126 17.8633 6.08894C17.7582 6.23198 17.6618 6.51964 17.5749 6.84268C17.5278 6.54214 17.4641 6.22231 17.3801 5.9491C17.2845 5.63731 17.1557 5.37858 17.012 5.2243C16.7346 4.92697 16.3865 4.76144 16.0122 4.67947C16.4603 4.28251 16.8001 3.78751 17.0299 3.23465Z"
              fill="#6D6DF2"
            />
          </g>
          <g clipPath="url(#clip2_7692_93140)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M23.3766 17.1828C23.3606 17.0875 23.3485 16.9932 23.3406 16.8989C23.3289 16.7575 23.3361 16.615 23.332 16.4735C23.3301 16.4135 23.323 16.2571 23.3259 16.2346C23.3521 16.0385 23.5104 16.0085 23.5566 16.0032C23.579 16 23.809 15.9743 23.8586 16.2293C23.9194 16.541 23.9389 16.855 23.9174 17.1625C23.9676 17.4239 24.0536 17.6843 24.167 17.92C24.6185 18.8575 25.4789 18.9485 26.411 18.94C26.5544 18.9378 26.6734 19.0503 26.6809 19.1928C26.6884 19.3364 26.5817 19.4607 26.439 19.4735C25.918 19.5239 24.9935 19.9418 24.6739 20.3768C24.6071 20.4678 24.5682 20.6875 24.5167 20.9028C24.445 21.2018 24.3596 21.4975 24.2639 21.6218C24.2495 21.641 24.075 21.8382 24.0229 21.8671C23.8978 21.9357 23.7976 21.896 23.7407 21.8596C23.6838 21.8232 23.6194 21.7514 23.5937 21.6325C23.5668 21.5082 23.5895 21.2532 23.5854 21.1985C23.5701 21.0003 23.5305 20.6114 23.4496 20.2771C23.406 20.0971 23.3595 19.931 23.2826 19.8485C22.9978 19.5432 22.5424 19.5882 22.159 19.6385C22.1149 19.6439 22.0709 19.6503 22.027 19.6557C21.8709 19.7018 21.7051 19.736 21.5299 19.7564C21.2654 19.7875 21.2219 19.5443 21.2195 19.5282C21.2146 19.4928 21.1963 19.3107 21.3957 19.2378C21.4174 19.2303 21.5779 19.195 21.6398 19.181C21.7259 19.1618 21.813 19.1468 21.9004 19.1328C22.8035 18.8521 23.294 18.0635 23.3766 17.1828ZM23.6866 18.1568C24.0275 18.8618 24.5449 19.2025 25.1582 19.3568H25.1581C24.7752 19.5571 24.4269 19.8078 24.2422 20.0596C24.1722 20.155 24.1079 20.3468 24.0499 20.5621C24.0185 20.3618 23.9761 20.1485 23.9201 19.9664C23.8563 19.7585 23.7705 19.586 23.6747 19.4832C23.4898 19.285 23.2577 19.1746 23.0082 19.12C23.3069 18.8553 23.5334 18.5253 23.6866 18.1568Z"
              fill="#6D6DF2"
            />
          </g>
          <defs>
            <clipPath id="clip0_7692_93140">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(0 8)"
              />
            </clipPath>
            <clipPath id="clip1_7692_93140">
              <rect
                width="9"
                height="9"
                fill="white"
                transform="translate(13)"
              />
            </clipPath>
            <clipPath id="clip2_7692_93140">
              <rect
                width="6"
                height="6"
                fill="white"
                transform="translate(21 16)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>

      <span className="w-[211px] text-center font-kalam font-normal	text-sm	text-Neutral800 flex flex-col">
        <span>No new notifications.</span>
        <span>It’s a good time to relax... Or is it?</span>
      </span>
    </div>
  );
};

export default NotificationsEmptyState;
