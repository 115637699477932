const AddIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6569 7.99963H2.34315"
        stroke="#5A5A66"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 2.34277V13.6565"
        stroke="#5A5A66"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default AddIcon;
