const initialState = {
  dashboardCount: {
    isLoading: true,
    data: {},
  },
  upcomingTaskCount: {
    isLoading: true,
    data: {},
  },
  taskCount: {
    isLoading: true,
    data: {},
  },
  timeTrackingAndDashboardCount: {
    isLoading: true,
    data: [],
  },
  yearlyTimeTrackingCount: {
    isLoading: true,
    data: {},
  },
  yearlyInvoiceCount: {
    isLoading: true,
    data: {},
  },
  myTasks: {
    isLoading: true,
    data: [],
  },
  myTasksByPriority: {
    isLoading: true,
    data: [],
  },
  tasksCountByMember: {
    isLoading: true,
    data: [],
  },
};

const dashboardV2Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_DASHBOARD_COUNT":
      return {
        ...state,
        dashboardCount: {
          ...state.dashboardCount,
          isLoading: payload?.isLoading ?? false,
          data: payload?.data,
        },
      };
    case "GET_UPCOMING_TASK_COUNT":
      return {
        ...state,
        upcomingTaskCount: {
          ...state.upcomingTaskCount,
          isLoading: payload?.isLoading ?? false,
          data: payload?.data,
        },
      };
    case "GET_TASK_COUNT":
      return {
        ...state,
        taskCount: {
          ...state.taskCount,
          isLoading: payload?.isLoading ?? false,
          data: payload?.data,
        },
      };
    case "GET_TIME_TRACKING_&_DASHBOARD_COUNT":
      return {
        ...state,
        timeTrackingAndDashboardCount: {
          ...state.timeTrackingAndDashboardCount,
          isLoading: payload?.isLoading ?? false,
          data: payload?.data,
        },
      };
    case "GET_YEARLY_TIME_TRACKING_COUNT":
      return {
        ...state,
        yearlyTimeTrackingCount: {
          ...state.yearlyTimeTrackingCount,
          isLoading: payload?.isLoading ?? false,
          data: payload?.data,
        },
      };
    case "GET_YEARLY_INVOICE_COUNT":
      return {
        ...state,
        yearlyInvoiceCount: {
          ...state.yearlyInvoiceCount,
          isLoading: payload?.isLoading ?? false,
          data: payload?.data,
        },
      };
    case "GET_MY_TASKS":
      return {
        ...state,
        myTasks: {
          ...state.myTasks,
          isLoading: payload?.isLoading ?? false,
          data: payload?.data,
        },
      };
    case "GET_MY_TASKS_BY_PRIORITY":
      return {
        ...state,
        myTasksByPriority: {
          ...state.myTasksByPriority,
          isLoading: payload?.isLoading ?? false,
          data: payload?.data,
        },
      };
    case "GET_TASKS_COUNT_BY_MEMBER":
      return {
        ...state,
        tasksCountByMember: {
          ...state.tasksCountByMember,
          isLoading: payload?.isLoading ?? false,
          data: payload?.data,
        },
      };
    default:
      return state;
  }
};

export default dashboardV2Reducer;
