/**
 * Edit Icon
 *
 * @param {import("react").SVGProps} props - SVG Icon Props
 */
function EditIcon(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.71436 5.1543H5.07541C4.72389 5.1543 4.38677 5.29394 4.1382 5.5425C3.88964 5.79107 3.75 6.12819 3.75 6.47971V15.7576C3.75 16.1091 3.88964 16.4463 4.1382 16.6948C4.38677 16.9434 4.72389 17.083 5.07541 17.083H14.3533C14.7048 17.083 15.042 16.9434 15.2905 16.6948C15.5391 16.4463 15.6787 16.1091 15.6787 15.7576V11.1187"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6833 4.16127C14.9469 3.89762 15.3045 3.74951 15.6773 3.74951C16.0502 3.74951 16.4078 3.89762 16.6714 4.16127C16.935 4.42491 17.0832 4.78248 17.0832 5.15533C17.0832 5.52817 16.935 5.88575 16.6714 6.14939L10.3757 12.4451L7.72485 13.1078L8.38756 10.457L14.6833 4.16127Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default EditIcon;
