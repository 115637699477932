function convertMinutesToTime(minutes) {
  if (!minutes) {
    return "00:00:00";
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  const seconds = 0;
  const time = new Date();
  time.setHours(hours, remainingMinutes, seconds);
  return time.toLocaleTimeString("en-US", { hour12: false });
}

function convertSecondsToTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const timeString =
    padZero(hours) + ":" + padZero(minutes) + ":" + padZero(remainingSeconds);
  return timeString;
}

function padZero(value) {
  return value < 10 ? "0" + value : value;
}

export { convertMinutesToTime, convertSecondsToTime };
