import React from "react";

const CloseIcon = ({ width = 18, className = "", color = "currentColor" }) => {
  return (
    <>
      <svg
        className={className}
        width={width}
        height={width}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.5 4.5L4.5 13.5"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.5 4.5L13.5 13.5"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default CloseIcon;
