import React from "react";

function TaskLoader() {
  return (
    <div>
      <div className="mb-8 p-5 border rounded-lg flex shadow-sm h-[118px] w-[100%] justify-between">
        <div className="w-[70%] flex">
          <div>
            <div
              className={`h-16 w-16 min-w-16 5xl:h-16 5xl:w-16 rounded items-center justify-center text-Neutral700 bg-[#f6f7f8] bg-no-repeat skeleton-animation relative`}
            ></div>
          </div>
          <div className="w-[100px] pl-[24px]">
            <span className="w-full rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></span>
            <span className="w-full rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></span>
            <span className="w-full rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></span>
          </div>
        </div>
        <div className="w-[25%] flex justify-between">
          <span className="w-[30%] py-1 h-[74px] rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></span>
          <span className="w-[30%] py-1 h-[74px] rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></span>
          <span className="w-[30%] py-1 h-[74px] rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></span>
        </div>
      </div>
      <div className="mb-8 p-5 border rounded-lg flex shadow-sm h-[118px] w-[100%] justify-between">
        <div className="w-[70%] flex">
          <div>
            <div
              className={`h-16 w-16 min-w-16 5xl:h-16 5xl:w-16 rounded items-center justify-center text-Neutral700 bg-[#f6f7f8] bg-no-repeat skeleton-animation relative`}
            ></div>
          </div>
          <div className="w-[100px] pl-[24px]">
            <span className="w-full rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></span>
            <span className="w-full rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></span>
            <span className="w-full rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></span>
          </div>
        </div>
        <div className="w-[25%] flex justify-between">
          <span className="w-[30%] py-1 h-[74px] rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></span>
          <span className="w-[30%] py-1 h-[74px] rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></span>
          <span className="w-[30%] py-1 h-[74px] rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></span>
        </div>
      </div>
      <div className="mb-8 p-5 border rounded-lg flex shadow-sm h-[118px] w-[100%] justify-between">
        <div className="w-[70%] flex">
          <div>
            <div
              className={`h-16 w-16 min-w-16 5xl:h-16 5xl:w-16 rounded items-center justify-center text-Neutral700 bg-[#f6f7f8] bg-no-repeat skeleton-animation relative`}
            ></div>
          </div>
          <div className="w-[100px] pl-[24px]">
            <span className="w-full rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></span>
            <span className="w-full rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></span>
            <span className="w-full rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></span>
          </div>
        </div>
        <div className="w-[25%] flex justify-between">
          <span className="w-[30%] py-1 h-[74px] rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></span>
          <span className="w-[30%] py-1 h-[74px] rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></span>
          <span className="w-[30%] py-1 h-[74px] rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></span>
        </div>
      </div>
      <div className="mb-8 p-5 border rounded-lg flex shadow-sm h-[118px] w-[100%] justify-between">
        <div className="w-[70%] flex">
          <div>
            <div
              className={`h-16 w-16 min-w-16 5xl:h-16 5xl:w-16 rounded items-center justify-center text-Neutral700 bg-[#f6f7f8] bg-no-repeat skeleton-animation relative`}
            ></div>
          </div>
          <div className="w-[100px] pl-[24px]">
            <span className="w-full rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></span>
            <span className="w-full rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></span>
            <span className="w-full rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></span>
          </div>
        </div>
        <div className="w-[25%] flex justify-between">
          <span className="w-[30%] py-1 h-[74px] rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></span>
          <span className="w-[30%] py-1 h-[74px] rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></span>
          <span className="w-[30%] py-1 h-[74px] rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></span>
        </div>
      </div>
      <div className="mb-8 p-5 border rounded-lg flex shadow-sm h-[118px] w-[100%] justify-between">
        <div className="w-[70%] flex">
          <div>
            <div
              className={`h-16 w-16 min-w-16 5xl:h-16 5xl:w-16 rounded items-center justify-center text-Neutral700 bg-[#f6f7f8] bg-no-repeat skeleton-animation relative`}
            ></div>
          </div>
          <div className="w-[100px] pl-[24px]">
            <span className="w-full rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></span>
            <span className="w-full rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></span>
            <span className="w-full rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></span>
          </div>
        </div>
        <div className="w-[25%] flex justify-between">
          <span className="w-[30%] py-1 h-[74px] rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></span>
          <span className="w-[30%] py-1 h-[74px] rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></span>
          <span className="w-[30%] py-1 h-[74px] rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></span>
        </div>
      </div>
    </div>
  );
}

export default TaskLoader;
