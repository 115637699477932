const initialState = {
  invoiceList: {
    isLoading: true,
    data: {
      data: [],
      totalRecord: 0,
      perPage: 10,
      totalPage: 0,
      currentPage: 0,
      fromRecord: 0,
      hasPrevPage: false,
      hasNextPage: false,
    },
  },
  invoice: {},
  getInvoiceStatus: {
    isLoading: true,
    data: [],
  },
};

const invoiceReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_INVOICE":
      return {
        ...state,
        invoice: payload.data,
        isLoading: false,
      };
    case "GET_INVOICE_ERROR":
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case "SET_INVOICE_LIST":
      return {
        ...state,
        invoiceList: {
          ...state.invoiceList,
          ...payload,
        },
      };
    case "SET_INVOICE_LIST_BY_CLIENT":
      return {
        ...state,
        invoiceList: {
          ...state.invoiceList,
          ...payload,
        },
      };
    case "CREATE_INVOICE_SUCCESS":
    case "INIT_INVOICE_SUCCESS":
      return {
        ...state,
        invoiceList: {
          ...state.invoiceList,
          data: {
            ...state.invoiceList.data,
            docs: [...state.invoiceList.data.docs, payload.data],
          },
        },
      };
    case "NEW_PAYMENT_LINK":
      return {
        ...state,
        invoice: { ...state.invoice, ...payload },
      };
    case "FOLLOW_UP_EMAIL_DETAILS":
      return {
        ...state,
        invoice: {
          ...state.invoice,
          ...payload.data,
        },
      };
    case "UPDATE_INVOICE_SUCCESS":
      const updatedInvoice =
        state?.invoice?._id === payload._id ? payload : state.invoice;
      const updatedInvoiceList = state?.invoiceList?.data;
      updatedInvoiceList.docs = updatedInvoiceList?.docs?.map((invoice) =>
        invoice?._id === payload?._id ? payload : invoice
      );
      return {
        ...state,
        invoice: updatedInvoice,
        invoiceList: {
          ...state.invoiceList,
          data: updatedInvoiceList,
        },
      };

    case "UPDATE_FOLLOWUP_EMAIL_INVOICE":
      const remainingInvoices = state.invoiceList.data.docs.find(
        (invoice) => invoice._id === payload._id
      );
      const updatedInvoic = state.invoiceList.data.docs.map((invoice) =>
        invoice._id === remainingInvoices._id
          ? { ...invoice, auto_email_followUp: payload.auto_email_followUp }
          : invoice
      );
      return {
        ...state,
        invoice: remainingInvoices,
        invoiceList: {
          ...state.invoiceList,
          data: {
            docs: updatedInvoic,
          },
        },
      };

    case "UPDATE_INVOICE_SERIES_SUCCESS":
      const updatedInvoiceSeries = payload.find(
        (inv) => inv._id === state.invoice._id
      );

      const updatedDocs = state.invoiceList.data.docs.map((doc) => {
        if (doc._id === updatedInvoiceSeries._id) {
          return updatedInvoiceSeries;
        }
        return doc;
      });

      const updatedState = {
        ...state,
        invoice: updatedInvoiceSeries,
        invoiceList: {
          ...state.invoiceList,
          data: {
            ...state.invoiceList.data,
            docs: updatedDocs,
          },
        },
      };

      return updatedState;
    case "DELETE_INVOICE_SUCCESS":
      if (state.invoiceList.data.docs) {
        const remainingInvoices = state.invoiceList.data.docs.filter(
          (invoice) => invoice._id !== payload.id
        );
        return {
          ...state,
          invoiceList: {
            ...state.invoiceList,
            data: {
              ...state.invoiceList.data,
              docs: remainingInvoices,
            },
          },
        };
      } else {
        const remainingInvoices = state.invoiceList.data.filter(
          (invoice) => invoice._id !== payload.id
        );
        return {
          ...state,
          invoiceList: {
            ...state.invoiceList,
            data: remainingInvoices,
          },
        };
      }
    // const remainingInvoices = state.invoiceList.data;
    // const newInvoiceList = remainingInvoices.docs.filter(
    //   (invoice) => invoice._id !== payload.id
    // );
    // remainingInvoices.docs = newInvoiceList;
    // return {
    //   ...state,
    //   invoiceList: {
    //     ...state.invoiceList,
    //     data: remainingInvoices,
    //   },
    // };
    case "UPDATE_INVOICE_STATUS":
      const updatedInvoiceStatus = state.invoiceList.data.docs.map((invoice) =>
        invoice?._id === payload?.data?._id ? payload?.data : invoice
      );
      return {
        ...state,
        invoiceList: {
          ...state.invoiceList,
          data: {
            ...state.invoiceList.data,
            docs: updatedInvoiceStatus,
          },
        },
      };
    case "INIT_INVOICE_ERROR":
    case "UPDATE_INVOICE_ERROR":
    case "DELETE_INVOICE_ERROR":
    case "SET_INVOICES_LIST_ERROR":
      return {
        ...state,
        error: payload,
      };
    case "GET_INVOICE_STATUS":
      return {
        ...state,
        getInvoiceStatus: {
          ...state.getInvoiceStatus,
          data: payload.data,
        },
      };
    case "UPDATE_INVOICE_STATUS_LIST":
      return {
        ...state,
        getInvoiceStatus: {
          ...state.getInvoiceStatus,
          data: [...state.getInvoiceStatus.data, payload.data],
        },
      };
    default:
      return state;
  }
};

export default invoiceReducer;
