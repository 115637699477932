import axios from "axios";
import { toast } from "react-toastify";

export const GetGoals = (setGoal) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`api/v1/goal/get-goals`);
      setGoal(data.data);
      return dispatch({
        type: "SET_GOAL",
        payload: { isLoading: false, is_called: true, data: data.data },
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "SET_GOAL_ERROR",
        payload: error,
      });
    }
  };
};

export const CreateGoal = (
  text,
  setLoading,
  setInputEditValue,
  setInputEdit
) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`api/v1/goal/create-goal`, text);
      setLoading(false);
      setInputEditValue(null);
      setInputEdit(false);
      return dispatch({
        type: "CREATE_GOAL_SUCCESS",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "CREATE_GOAL_ERROR",
        payload: error,
      });
    }
  };
};

export const UpdateGoal = (
  value,
  setLoading,
  setInputEditValue,
  setInputEdit
) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`api/v1/goal/update-goal`, value);
      setLoading(false);
      setInputEditValue(null);
      setInputEdit(false);
      return dispatch({
        type: "UPDATE_GOAL_SUCCESS",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "UPDATE_GOAL_ERROR",
        payload: error,
      });
    }
  };
};

export const UpdateIsMarkGoal = (value) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`api/v1/goal/is-marked-done`, value);
      return dispatch({
        type: "UPDATE_ISMARK_GOAL_SUCCESS",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "UPDATE_ISMARK_GOAL_ERROR",
        payload: error,
      });
    }
  };
};

export const DeleteGoal = (id, goalList) => {
  return async (dispatch) => {
    try {
      await axios.delete(`api/v1/goal/delete-goal?id=${id}`);
      const abc = goalList?.data?.filter((e) => e._id !== id);
      dispatch({
        type: "RESET_GOAL_LIST",
        payload: abc,
      });
      return dispatch({
        type: "MARK_READ_GOAL_LIST_SUCCESS",
        payload: [],
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "MARK_READ_GOAL_LIST_ERROR",
        payload: error,
      });
    }
  };
};
