import React from "react";

const EditorLoader = ({ mode }) => {
  return (
    <div className="w-full border-[1px] border-solid border-[#CCCCCC] rounded-md">
      {mode === "normal" && (
        <div className="flex justify-between my-[10px] mx-[12px] w-full">
          <div className="flex w-[70%]">
            <div className="flex justify-between w-1/3 mr-[40px]">
              {Array.from({ length: 4 }).map((ele, index) => (
                <div
                  className="w-[20px] h-[20px] bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"
                  key={`123${index}`}
                ></div>
              ))}
            </div>{" "}
            <div className="flex justify-between w-1/3 mr-[40px]">
              {Array.from({ length: 4 }).map((ele, index) => (
                <div
                  className="w-[20px] h-[20px] bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"
                  key={`456${index}`}
                ></div>
              ))}
            </div>{" "}
            <div className="flex justify-between w-1/3 mr-[40px]">
              {Array.from({ length: 5 }).map((ele, index) => (
                <div
                  className="w-[20px] h-[20px] bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"
                  key={`789${index}`}
                ></div>
              ))}
            </div>
          </div>
          <div className="flex justify-end w-[30%]">
            <div className="flex justify-between w-1/3 mr-[17px]">
              {Array.from({ length: 3 }).map((ele, index) => (
                <div
                  className="w-[20px] h-[20px] bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"
                  key={`012${index}`}
                ></div>
              ))}
            </div>
          </div>
        </div>
      )}
      <div className="bg-[#efefef] py-[30px] px-[52px]">
        <div className="bg-[#fff] p-[30px]">
          {Array.from({ length: 50 }).map((ele, index) => (
            <div
              className="w-full h-4 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"
              key={index}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EditorLoader;
