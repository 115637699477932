import { useState } from "react";

export const useNewTemplateModal = () => {
  const [selectTemplateModalIsOpen, setSelectTemplateModalIsOpen] =
    useState(false);

  const [templateModalIsOpen, setTemplateModalIsOpen] = useState(false);

  const handleOpenSelectTemplateModal = () => {
    setTemplateModalIsOpen(false);
    setSelectTemplateModalIsOpen(true);
  };

  return {
    selectTemplateModalIsOpen,
    setSelectTemplateModalIsOpen,
    templateModalIsOpen,
    setTemplateModalIsOpen,
    handleOpenSelectTemplateModal,
  };
};
