import React, { forwardRef, useState } from "react";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import CloseBtn from "../../assets/Images/close-btn.svg";
import CloseBtns from "../../assets/Images/Close-btn-set.svg";
import {
  UpdateEventAction,
  DeleteEventAction,
} from "../../store/actions/eventAction";
// import TimeInput from "react-time-picker-input/dist/components/TimeInput";
// import "react-time-picker-input/dist/components/TimeInput.css";

import CalendarIcon from "../Icons/CalendarIcon";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const EditEventSchema = Yup.object().shape({
  id: Yup.string().required("Event ID Required"),
  title: Yup.string().required("Event Subject Required"),
  start_time: Yup.string().required("Time required"),
  start_date: Yup.date().required("Start date required"),
  // end_time: Yup.string().required("Time required"),
  // end_date: Yup.date().when("start_date", (start_date, schema) => {
  //   if (start_date) {
  //     const dayAfter = new Date(start_date.getTime());
  //     return schema.min(dayAfter, "End date has to be after than start date");
  //   }
  //   return schema;
  // }),
});

function EditeEventsModal({
  isEvent,
  setIsEvent,
  editEvent,
  setEditEvent,
  updateEvent,
  deleteGoogleCalendarEvent,
}) {
  const dispatch = useDispatch();
  const [isView, setIsView] = useState(true);
  const [isDeleting, setDeleting] = useState(false);
  const logedinUser = useSelector((state) => state.auth.user);
  const userDateFormat = logedinUser?.date_format;

  const deleteEvent = () => {
    setDeleting(true);
    dispatch(
      DeleteEventAction(
        editEvent._id,
        setDeleting,
        setIsEvent,
        deleteGoogleCalendarEvent
      )
    );
  };

  const DateInput = forwardRef(({ value, onClick }, ref) => (
    <div className="relative">
      <input
        type="text"
        className={`cursor-pointer caret-transparent example-custom-input whitespace-nowrap ${
          value ? "font-bold text-Neutral900" : "text-Neutral600"
        } text-Neutral800 h-[48px] w-full font-medium font-feature-settings pl-[17.5px] pr-[46.5px] placeholder:text-sm text-sm leading-5 5xl:max-w-[180px] w-[148px] focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition add-new-event-time-color`}
        value={!value ? "Select date" : moment(value).format(userDateFormat)}
        onClick={onClick}
        readOnly
        ref={ref}
      />
      <CalendarIcon className="absolute top-[50%] right-[12px] translate-y-[-50%] pointer-events-none" />
    </div>
  ));

  return (
    <Modal
      isOpen={isEvent}
      onRequestClose={() => {
        setIsEvent(false);
        setEditEvent(null);
        setIsView(true);
      }}
      style={customStyles}
      closeTimeoutMS={100}
      contentLabel="Edit Event Modal"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="bg-white px-6 xs:px-12 pt-8 pb-10 rounded-[8px]">
          <div>
            <div className="text-left">
              <h3
                className={`text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-8`}
                id="modal-title"
              >
                YOUR EVENT
              </h3>
              <Formik
                initialValues={{
                  id: editEvent && editEvent?._id ? editEvent?._id : "",
                  title: editEvent && editEvent?.title ? editEvent?.title : "",
                  start_time_hours:
                    editEvent && editEvent?.start_time
                      ? moment(
                          editEvent?.start_time,
                          "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"
                        ).format("HH")
                      : "",
                  start_time_minutes:
                    editEvent && editEvent?.start_time
                      ? moment(
                          editEvent?.start_time,
                          "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"
                        ).format("mm")
                      : "",
                  start_time:
                    editEvent && editEvent?.start_time
                      ? moment(
                          editEvent?.start_time,
                          "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"
                        ).format("HH:mm:ss")
                      : "",
                  start_date:
                    editEvent && editEvent?.start_date
                      ? moment(editEvent?.start_date).utc().format("YYYY-MM-DD")
                      : "",
                  end_time_hours:
                    editEvent &&
                    editEvent?.end_time &&
                    editEvent?.end_time !== ""
                      ? moment(
                          editEvent?.end_time,
                          "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"
                        ).format("HH")
                      : "00",
                  end_time_minutes:
                    editEvent &&
                    editEvent?.end_time &&
                    editEvent?.end_time !== ""
                      ? moment(
                          editEvent?.end_time,
                          "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"
                        ).format("mm")
                      : "00",
                  end_time:
                    editEvent &&
                    editEvent?.end_time &&
                    editEvent?.end_time !== ""
                      ? moment(
                          editEvent?.end_time,
                          "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"
                        ).format("HH:mm:ss")
                      : "",
                  end_date:
                    editEvent &&
                    editEvent?.end_date &&
                    editEvent?.end_date !== ""
                      ? moment(editEvent?.end_date).utc().format("YYYY-MM-DD")
                      : "",
                }}
                enableReinitialize
                validationSchema={EditEventSchema}
                onSubmit={async (values, { setSubmitting, setErrors }) => {
                  setSubmitting(true);
                  const closeModal = () => {
                    setIsEvent(false);
                    setIsView(true);
                  };
                  await dispatch(
                    UpdateEventAction(
                      values,
                      setSubmitting,
                      setErrors,
                      closeModal,
                      updateEvent
                    )
                  );
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  resetForm,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="mb-8">
                      <label
                        className={`text-Neutral900 font-bold text-xs ${
                          isView ? "opacity-60 font-[500]" : "text-Neutral900"
                        }`}
                      >
                        What
                      </label>
                      <div className="mt-2">
                        {isView ? (
                          <>
                            <p className="font-bold text-sm text-[#5A5A66]">
                              {values.title}
                            </p>
                          </>
                        ) : (
                          <>
                            <input
                              className={`text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition`}
                              type="text"
                              disabled={isView}
                              placeholder="Eg. Wireframing"
                              name="title"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.title}
                            />
                          </>
                        )}
                        {errors.title && touched.title && (
                          <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                            {errors.title}
                          </p>
                        )}
                        {errors.id && (
                          <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                            {errors.id}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mb-6">
                      <label
                        className={`text-Neutral900 font-bold text-xs ${
                          isView ? "opacity-60 font-[500]" : "text-Neutral900"
                        }`}
                      >
                        Start
                      </label>
                      <div className="mt-2 mb-6 add-event-time-date">
                        <div className="flex items-center date-times">
                          {isView ? (
                            <>
                              <p className="font-bold text-sm text-[#5A5A66]">
                                {values.start_time}
                              </p>
                              <span className="min-w-[4px] min-h-[4px] w-1 h-1 inline-block bg-[#5a5a6633] rounded-full m-[0px_4px_0px_11px]"></span>
                              <div className="ml-[8px] w-full">
                                <p className="font-bold text-sm text-[#5A5A66]">
                                  {values.start_date}
                                </p>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                className="bg-white min-w-fit max-w-fit text-Neutral800 font-medium placeholder:text-Neutral500 font-feature-settings py-4 h-[48px] placeholder:text-sm text-sm leading-5 focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular time-inputs event-time-inputs focus:drop-shadow-Purpleboxshadow duration-300 transition"
                                tabIndex="1"
                              >
                                <div
                                  className={`${
                                    values.start_time !== "" &&
                                    values.start_time !== "00:00"
                                      ? "input-text"
                                      : ""
                                  } flex`}
                                >
                                  <input
                                    className="number-input outline-none w-[22px] ml-[17px] text-Neutral800 font-medium placeholder:text-Neutral500 font-feature-settings placeholder:font-medium placeholder:text-sm text-sm leading-5 5xl:placeholder:text-lg"
                                    type="number"
                                    disabled={isView}
                                    placeholder="00"
                                    name="start_time_hours"
                                    maxLength="2"
                                    onChange={(e) => {
                                      if (Number(e.target.value) > 25) {
                                        setFieldValue("start_time_hours", 23);
                                        setFieldValue(
                                          "start_time",
                                          `${23}:${values?.start_time_minutes}`
                                        );
                                      } else if (
                                        Number(e.target.value) === 24
                                      ) {
                                        setFieldValue("start_time_hours", "24");
                                        setFieldValue(
                                          "start_time_minutes",
                                          "00"
                                        );
                                        setFieldValue("start_time", `24:00`);
                                      } else {
                                        setFieldValue(
                                          "start_time_hours",
                                          ("0" + e.target.value).slice(-2)
                                        );
                                        setFieldValue(
                                          "start_time",
                                          `${("0" + e.target.value).slice(
                                            -2
                                          )}:${values?.start_time_minutes}`
                                        );
                                      }
                                    }}
                                    onBlur={handleBlur}
                                    value={values?.start_time_hours}
                                  />
                                  :
                                  <input
                                    className="number-input outline-none w-[22px] mr-[17px] ml-[5px] text-Neutral800 font-medium placeholder:text-Neutral500 font-feature-settings placeholder:font-medium placeholder:text-sm text-sm leading-5 5xl:placeholder:text-lg"
                                    type="number"
                                    disabled={isView}
                                    min="0"
                                    max="59"
                                    placeholder="00"
                                    maxLength="2"
                                    name="start_time_minutes"
                                    onChange={(e) => {
                                      if (Number(e.target.value) > 59) {
                                        setFieldValue("start_time_minutes", 59);
                                        setFieldValue(
                                          "start_time",
                                          `${values?.start_time_hours}:${59}`
                                        );
                                      } else {
                                        setFieldValue(
                                          "start_time_minutes",
                                          ("0" + e.target.value).slice(-2)
                                        );
                                        setFieldValue(
                                          "start_time",
                                          `${values?.start_time_hours}:${(
                                            "0" + e.target.value
                                          ).slice(-2)}`
                                        );
                                      }
                                    }}
                                    onBlur={handleBlur}
                                    value={values?.start_time_minutes}
                                  />
                                  {/* <TimeInput
                                    onChange={(e) =>
                                      setFieldValue("start_time", e)
                                    }
                                    value={values.start_time}
                                    eachInputDropdown
                                  /> */}
                                </div>
                              </div>
                              <div className="ml-[8px] w-full relative">
                                <DatePicker
                                  selected={new Date(values.start_date)}
                                  customInput={
                                    <DateInput
                                      value={
                                        values.start_date
                                          ? new Date(
                                              values.start_date
                                            ).toISOString()
                                          : null
                                      }
                                    />
                                  }
                                  onChange={(date) => {
                                    setFieldValue(
                                      "start_date",
                                      moment(date).format("YYYY-MM-DD")
                                    );
                                  }}
                                />
                              </div>
                            </>
                          )}
                        </div>
                        {errors.start_time && touched.start_time && (
                          <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none">
                            {errors.start_time}
                          </p>
                        )}
                        {errors.start_date && touched.start_date && (
                          <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none">
                            {errors.start_date}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mb-6 date-times">
                      <label
                        className={`text-Neutral900 font-bold text-xs ${
                          isView ? "opacity-60 font-[500]" : "text-Neutral900"
                        }`}
                      >
                        End
                      </label>
                      <div className="mt-2 mb-6 add-event-time-date">
                        <div className="flex items-center">
                          {isView ? (
                            <>
                              <p className="font-bold text-sm text-[#5A5A66]">
                                {values?.end_time}
                              </p>
                              {values?.end_time !== "" &&
                                values?.end_date !== "" && (
                                  <span className="min-w-[4px] min-h-[4px] w-1 h-1 inline-block bg-[#5a5a6633] rounded-full m-[0px_4px_0px_11px]"></span>
                                )}
                              <div className="ml-[8px] w-full">
                                <p className="font-bold text-sm text-[#5A5A66]">
                                  {values?.end_date}
                                </p>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                className="bg-white min-w-fit max-w-fit text-Neutral800 font-medium placeholder:text-Neutral500 font-feature-settings py-4 h-[48px] placeholder:text-sm text-sm leading-5 focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular time-inputs event-time-inputs focus:drop-shadow-Purpleboxshadow duration-300 transition"
                                tabIndex="1"
                              >
                                <div
                                  className={`${
                                    values?.end_time !== "" &&
                                    values?.end_time !== "00:00"
                                      ? "input-text"
                                      : ""
                                  } flex`}
                                >
                                  <input
                                    className="number-input outline-none w-[22px] ml-[17px] text-Neutral800 font-medium placeholder:text-Neutral500 font-feature-settings placeholder:font-medium placeholder:text-sm text-sm leading-5 5xl:placeholder:text-lg"
                                    type="number"
                                    disabled={isView}
                                    placeholder="00"
                                    name="end_time_hours"
                                    maxLength="2"
                                    onChange={(e) => {
                                      if (Number(e.target.value) > 25) {
                                        setFieldValue("end_time_hours", 23);
                                        setFieldValue(
                                          "end_time",
                                          `${23}:${values?.end_time_minutes}`
                                        );
                                      } else if (
                                        Number(e.target.value) === 24
                                      ) {
                                        setFieldValue("end_time_hours", "24");
                                        setFieldValue("end_time_minutes", "00");
                                        setFieldValue("end_time", `24:00`);
                                      } else {
                                        setFieldValue(
                                          "end_time_hours",
                                          ("0" + e.target.value).slice(-2)
                                        );
                                        setFieldValue(
                                          "end_time",
                                          `${("0" + e.target.value).slice(
                                            -2
                                          )}:${values?.end_time_minutes}`
                                        );
                                      }
                                    }}
                                    onBlur={handleBlur}
                                    value={values?.end_time_hours}
                                  />
                                  :
                                  <input
                                    className="number-input outline-none w-[22px] mr-[17px] ml-[5px] text-Neutral800 font-medium placeholder:text-Neutral500 font-feature-settings placeholder:font-medium placeholder:text-sm text-sm leading-5 5xl:placeholder:text-lg"
                                    type="number"
                                    disabled={isView}
                                    min="0"
                                    max="59"
                                    placeholder="00"
                                    maxLength="2"
                                    name="end_time_minutes"
                                    onChange={(e) => {
                                      if (Number(e.target.value) > 59) {
                                        setFieldValue("end_time_minutes", 59);
                                        setFieldValue(
                                          "end_time",
                                          `${values?.end_time_hours}:${59}`
                                        );
                                      } else {
                                        setFieldValue(
                                          "end_time_minutes",
                                          ("0" + e.target.value).slice(-2)
                                        );
                                        setFieldValue(
                                          "end_time",
                                          `${values?.end_time_hours}:${(
                                            "0" + e.target.value
                                          ).slice(-2)}`
                                        );
                                      }
                                    }}
                                    onBlur={handleBlur}
                                    value={values?.end_time_minutes}
                                  />
                                  {/* <TimeInput
                                    onChange={(e) =>
                                      setFieldValue("end_time", e)
                                    }
                                    value={values.end_time}
                                    eachInputDropdown
                                  /> */}
                                </div>
                              </div>
                              <div className="ml-[8px] w-full">
                                <DatePicker
                                  selected={
                                    values.end_date && new Date(values.end_date)
                                  }
                                  customInput={
                                    <DateInput
                                      value={
                                        values.end_date
                                          ? new Date(
                                              values.end_date
                                            ).toISOString()
                                          : null
                                      }
                                    />
                                  }
                                  onChange={(date) => {
                                    setFieldValue(
                                      "end_date",
                                      moment(date).format("YYYY-MM-DD")
                                    );
                                  }}
                                />
                              </div>
                            </>
                          )}
                        </div>
                        {errors.end_date && touched.end_date && (
                          <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none">
                            {errors.end_date}
                          </p>
                        )}
                        {errors.end_time && touched.end_time && (
                          <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none">
                            {errors.end_time}
                          </p>
                        )}
                      </div>
                    </div>
                    {/* <button type="button" className="bg-indigo-500 ..." disabled>
                    </button> */}
                    <div className="mt-8 flex">
                      {isView ? (
                        <button
                          type="button"
                          onClick={() => setIsView(false)}
                          className="btn button-hover w-[219px] h-12 form-primary-btn relative text-sm text-center block text-Neutral100 bg-AccentRegular font-bold py-3 rounded drop-shadow-Texts overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out"
                        >
                          <span className="relative z-10 drop-shadow-Texts">
                            Edit Event Details
                          </span>
                        </button>
                      ) : (
                        <button
                          type="button"
                          disabled={isSubmitting}
                          onClick={() => handleSubmit()}
                          className="btn button-hover h-12 w-[219px] form-primary-btn relative text-sm text-center block text-Neutral100 bg-AccentRegular font-bold py-3 rounded drop-shadow-Texts overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out"
                        >
                          {isSubmitting ? (
                            <span className="relative z-10">
                              <svg
                                className="animate-spin m-auto h-5 w-5 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                            </span>
                          ) : (
                            <span className="relative z-1">Save Changes</span>
                          )}
                        </button>
                      )}
                      {isView ? (
                        <button
                          type="button"
                          onClick={() => deleteEvent()}
                          disabled={isDeleting}
                          className={`w-[88px] ml-3 h-12 relative text-sm text-center block text-Neutral100 font-bold py-3 rounded overflow-hidden transition duration-300 ease-in-out ${
                            isDeleting
                              ? "bg-AccentLight"
                              : "bg-Red400 hover:bg-Red600"
                          }`}
                        >
                          {isDeleting ? (
                            <span className="relative z-10">
                              <svg
                                className="animate-spin m-auto h-5 w-5 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                            </span>
                          ) : (
                            <span className="relative z-10">
                              <svg
                                width="18"
                                className="m-auto"
                                height="20"
                                viewBox="0 0 18 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1 4.55371H2.77769H16.9992"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M5.4429 4.55538V2.77769C5.4429 2.30622 5.6302 1.85406 5.96358 1.52067C6.29696 1.18729 6.74912 1 7.22059 1H10.776C11.2474 1 11.6996 1.18729 12.033 1.52067C12.3664 1.85406 12.5537 2.30622 12.5537 2.77769V4.55538M15.2202 4.55538V16.9992C15.2202 17.4707 15.0329 17.9229 14.6995 18.2562C14.3661 18.5896 13.914 18.7769 13.4425 18.7769H4.55406C4.08259 18.7769 3.63042 18.5896 3.29704 18.2562C2.96366 17.9229 2.77637 17.4707 2.77637 16.9992V4.55538H15.2202Z"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                          )}
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => {
                            setIsView(true);
                            resetForm();
                          }}
                          className="w-[88px] ml-3 relative text-sm text-center block text-Neutral100 bg-AccentLight font-bold py-3 rounded overflow-hidden transition duration-300 ease-in-out h-12 hover:bg-AccentMediumLight"
                        >
                          <span className="relative z-1">
                            <img className="m-auto" src={CloseBtns} alt="" />
                          </span>
                        </button>
                      )}
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => {
            setIsEvent(false);
            setIsView(true);
          }}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
}

export default EditeEventsModal;
