const initialState = {
  isEnabled: false,
  data: null,
  isError: null,
};

const autoEmailSendFollowupReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case "FOLLOWUP_SUCCESS":
      return {
        ...state,
        data: payload.data,
        isEnabled: payload?.data?.enable ? payload?.data?.enable : false
      };
    case "FOLLOWUP_SET_SUCCESS":
      return {
        ...state,
        data: payload.data,
        isEnabled: payload.data.enable
      };
    case "FOLLOWUP_ERROR":
      return {
        ...state,
        isError: payload,
      };

    default:
      return state;
  }
};

export default autoEmailSendFollowupReducer;
