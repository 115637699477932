import Timer from "../../utils/Timer";

const initialState = {
  title: "",
  category: "",
  project: null,
  time: {
    value: 0,
    id: null,
  },
  isStarted: false,
  isRunning: false,
};

const timerReducer = (state = initialState, { type, payload }) => {
  const { isRunning, isStarted, time, id } = Timer.getCurrent();
  switch (type) {
    case "GET_TIMER":
      return {
        ...state,
        time: {
          id,
          value: time,
        },
        isRunning,
        isStarted,
      };
    case "START_TIMER":
      Timer.flush();
      Timer.start(payload);
      return {
        ...state,
        time: {
          ...state.time,
          value: Timer.getCurrent().time,
        },
        project: payload?.project,
        title: payload?.title,
        category: payload?.category,
        isStarted: true,
        isRunning: true,
      };
    case "RESUME_TIMER":
      // Timer.start(payload);
      Timer.resume(payload);
      return {
        ...state,
        time: {
          ...state.time,
          value: Timer.getCurrent().time,
        },
        isRunning: true,
      };
    case "PAUSE_TIMER":
      Timer.pause();
      return {
        ...state,
        isRunning: false,
      };
    case "RELOAD_TIMER":
      Timer.reloadAfterRefresh(payload);
      return {
        ...state,
        time: {
          ...state.time,
          value: Timer.getCurrent().time,
        },
        isRunning: true,
        isStarted: true,
      };
    case "UPDATE_TIMER":
      return {
        ...state,
        isRunning,
        time: {
          id,
          value: time,
        },
      };
    case "STOP_TIMER":
      Timer.stop();
      Timer.flush();
      return {
        ...state,
        time: {
          value: 0,
          id: null,
        },
        isRunning: false,
        isStarted: false,
      };
    case "UPDATE_TIMER_DETAILS":
      if (!payload.data) {
        Timer.stop();
        Timer.flush();
        return {
          ...state,
          time: {
            id: null,
            value: 0,
          },
          isStarted: false,
          isRunning: false,
        };
      }

      const { title, category, project, client } = payload.data;
      return {
        ...state,
        title,
        category,
        projectId: project,
        client,
        isRunning: true,
        isStarted: true,
      };
    default:
      return state;
  }
};

export default timerReducer;
