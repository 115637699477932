import React from "react";

function MeetingsIcon({
  color = "Neutral800",
  width = 24,
  className = "text-[#5A5A66]",
}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4761 8.28577H18.3808C19.3407 8.28577 20.2612 8.66706 20.9399 9.34576C21.6186 10.0245 21.9999 10.945 21.9999 11.9048C21.9999 12.8646 21.6186 13.7852 20.9399 14.4639C20.2612 15.1426 19.3407 15.5239 18.3808 15.5239H17.4761"
        stroke="#5A5A66"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 8.28577H17.4762V16.4286C17.4762 17.3885 17.0949 18.309 16.4162 18.9877C15.7375 19.6664 14.817 20.0477 13.8571 20.0477H6.61905C5.65922 20.0477 4.7387 19.6664 4.05999 18.9877C3.38129 18.309 3 17.3885 3 16.4286V8.28577Z"
        stroke="#5A5A66"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.61914 1.95239V4.66668"
        stroke="#5A5A66"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2383 1.95239V4.66668"
        stroke="#5A5A66"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8569 1.95239V4.66668"
        stroke="#5A5A66"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default MeetingsIcon;
