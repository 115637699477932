import React from "react";

function ClientListLoader() {
  return (
    <div className="">
      {/* <p className="font-black text-lg ml-1 medium-screen-hover whitespace-nowrap mb-6">
        Client table page skeleton
      </p> */}
      <div className="w-[150px] mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
      <table className="w-full">
        {Array.from({ length: 8 }).map((ele, index) => {
          return (
            <tr key={`12${index}`}>
              <td className="w-full max-w-[1%]">
                <div
                  className={`h-12 mb-4 w-12 min-w-12 5xl:h-16 5xl:w-16 rounded items-center justify-center text-Neutral700 bg-[#f6f7f8] bg-no-repeat skeleton-animation relative`}
                ></div>
              </td>
              <td className=" w-full max-w-[10%]">
                <div className="rounded w-40 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
              </td>
              <td className="w-full max-w-[30%] text-right">
                <div className="rounded w-20 h-8 mb-4 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative mr-2"></div>
                <div className="rounded w-20 h-8 mb-4 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative mr-2"></div>
                <div className="rounded w-25 h-8 mb-4 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative mr-2"></div>
                <div className="rounded w-20 h-8 mb-4 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
              </td>
            </tr>
          );
        })}
      </table>
      {/* pagination */}
      <div className="w-full justify-between flex my-7">
        <div className="w-40">
          <div className="w-5/6 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          <div className="w-4/6 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
        </div>
        <div className="flex">
          <div className="rounded w-6 h-6 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative mr-3"></div>
          <div className="rounded w-6 h-6 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
        </div>
      </div>
    </div>
  );
}

export default ClientListLoader;
