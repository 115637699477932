import React, { useState } from "react";
import Noproposals from "../../assets/Images/Noproposals.svg";
import Modal from "react-modal";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CloseBtn from "../../assets/Images/close-btn.svg";
import CreateClientModal from "../Modals/CreateClientModal";
import { GetProposalList } from "../../store/actions/proposalAction";
import UploadExistingProposalModal from "../Modals/UploadExistingProposalModal";
import { NewEntityTemplateModal } from "../Modals/Templates/NewEntityTemplateModal";
import { SelectTemplate } from "../Modals/Templates/SelectTemplate";
import NewTemplateModal from "../Modals/Templates/NewTemplateModal";
import ClientSelect from "../comman/Client/ClientSelect";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const NoProposalComponent = () => {
  const [createClientModal, setCreateClientModal] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectProposal, setSelectProposal] = useState("");
  const authSelector = useSelector((state) => state.auth);
  const [modalClient, setIsmodalClient] = React.useState(false);
  const dispatch = useDispatch();
  const [uploadContract, setUploadContract] = useState(false);
  const allClients = useSelector((state) => state.client.fullClientList);
  const [proposalTemplateModalIsOpen, setProposalTemplateModalIsOpen] =
    useState(false);
  const [selectTemplateModalIsOpen, setSelectTemplateModalIsOpen] =
    useState(false);
  const [newTemplateModalIsOpen, setNewTemplateModalIsOpen] = useState(false);

  const handleOpenSelectTemplateModal = () => {
    setProposalTemplateModalIsOpen(false);
    setSelectTemplateModalIsOpen(true);
  };

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
    setUploadContract(false);
  }

  const getProposalList = async () => {
    await dispatch(GetProposalList({ page: 1, perPage: 50, search: "" }));
  };

  const UploadExistingProposal = () => {
    closeModal();
    setUploadContract(true);
  };
  return (
    <>
      <div className="pt-[52px] flex justify-center">
        <div>
          <img src={Noproposals} alt="no-proposal" />
          <div className="text-center">
            <div className=" flex justify-center">
              <h1 className="font-[900] text-[28px] mt-[24px] text-Neutral900">
                Build Your First Proposal
              </h1>
            </div>
            <div className="flex justify-center mt-[12px]">
              <p className="text-[14px] w-[295px] text-Neutral800 text-center">
                Getting a client starts with a compelling proposal. Build it
                here.
              </p>
            </div>
            <div className="mt-[24px] 2xl:pb-0 md:pb-16 pb-10 flex justify-center">
              {authSelector?.user?.invited === false ||
              (authSelector?.user?.owner &&
                authSelector?.user?.invited === true) ? (
                <button
                  type="submit"
                  className="w-[295px] relative text-sm text-center block py-4 rounded overflow-hidden border border-Neutral900 relativ focus-visible:outline focus-visible:outline-[6px] focus-visible:drop-shadow-none focus-visible:outline-AccentMediumLight transition duration-300 ease-in-out"
                  onClick={openModal}
                >
                  <span className="relative text-Neutral900 font-[600] z-10">
                    Build Proposal
                  </span>
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <CreateClientModal
        isOpen={createClientModal}
        setIsOpen={setCreateClientModal}
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="Example Modal"
      >
        <div className="relative bg-Neutral000 rounded-lg text-left transform transition-all sm:my-8 sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="bg-Neutral000 px-6 xs:px-12 pt-8 pb-12  rounded-[8px]">
            <div>
              <div className="text-left">
                {!uploadContract && (
                  <div>
                    <h3
                      className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-10"
                      id="modal-title"
                    >
                      ADD PROPOSAL
                    </h3>
                    <button
                      onClick={() => setIsmodalClient(true)}
                      className="btn button-hover large-primary-btn text-sm text-center flex items-center justify-center text-Neutral100 bg-AccentRegular font-semibold w-full h-12 py-3 rounded drop-shadow-Texts focus:outline focus:outline-8 focus:outline-AccentMediumLight focus-visible:outline-0 transition duration-300 ease-in-out"
                    >
                      <span className="relative drop-shadow-Texts z-10">
                        Build a Proposal
                      </span>
                    </button>
                    <button
                      onClick={UploadExistingProposal}
                      className="text-sm bg-AccentLight text-AccentDark h-12 flex items-center justify-center leading-[1.2] mt-6 rounded font-bold w-full  hover:border-AccentRegular focus:border-0 focus:outline focus:outline-4 focus:outline-AccentMediumLight transition duration-300 ease-in-out hover:bg-[#d3d3eb]"
                    >
                      Upload Existing Proposal
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => closeModal(false)}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>

      <UploadExistingProposalModal
        modalIsOpen={uploadContract}
        closeModal={() => setUploadContract(false)}
        client={allClients}
        getProposalList={getProposalList}
      />

      <Modal
        isOpen={modalClient}
        onRequestClose={() => setIsmodalClient(false)}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="View Form Preview Modal"
      >
        <div className="relative bg-white rounded-lg text-left transform transition-all w-[80vw] sm:my-8 sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="bg-white px-6 xs:px-12 pt-8 pb-12 rounded-[8px]">
            <div>
              <div className="text-left">
                <h3
                  className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-8"
                  id="modal-title"
                >
                  SELECT CLIENT
                </h3>
                <div className="mb-8">
                  <label className="text-Neutral900 font-bold text-xs mb-2 block">
                    Client
                  </label>
                  <ClientSelect
                    allClients={allClients}
                    onChange={(e) => {
                      setSelectProposal(e);
                    }}
                  />
                </div>
                <button
                  className={`btn button-hover large-primary-btn text-sm text-center inline-block text-Neutral100 bg-AccentRegular font-semibold w-full min-w-[148px]  5xl:max-w-[172px] py-3 rounded drop-shadow-Texts focus:outline focus:outline-8 focus:outline-AccentMediumLight focus-visible:outline-0 transition duration-300 ease-in-out ${
                    !selectProposal && "pointer-events-none"
                  }`}
                  onClick={() => {
                    setIsmodalClient(false);
                    setProposalTemplateModalIsOpen(true);
                  }}
                >
                  <span className="relative drop-shadow-Texts z-10">
                    Continue to Proposal Builder
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => setIsmodalClient(false)}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>

      <NewEntityTemplateModal
        type={"Proposal"}
        isOpen={proposalTemplateModalIsOpen}
        setIsOpen={setProposalTemplateModalIsOpen}
        clientId={selectProposal?._id}
        onCustomTemplateClick={handleOpenSelectTemplateModal}
      />

      <SelectTemplate
        type={"Proposal"}
        isOpen={selectTemplateModalIsOpen}
        setIsOpen={setSelectTemplateModalIsOpen}
        clientId={selectProposal?._id}
        openNewTemplateModal={() => {
          setSelectTemplateModalIsOpen(false);
          setNewTemplateModalIsOpen(true);
        }}
      />

      <NewTemplateModal
        type={"Proposal"}
        isOpen={newTemplateModalIsOpen}
        setIsOpen={setNewTemplateModalIsOpen}
      />
    </>
  );
};

export default NoProposalComponent;
