import React from "react";

import AddButton from "../../Buttons/AddButton";
import SectionTitle from "../../common/typography/SectionTitle";
import ContributorsList from "../../common/lists/ContributorsList";

function AllContributorsSection({
  title = "Contributors",
  withButton = false,
  members = [],
  isLoading = false,
}) {
  return (
    <section className="w-100% pt-6 pb-[57px]">
      <div className="flex items-center mb-5">
        <SectionTitle text={title} />
        {withButton && <AddButton title="assign" />}
      </div>
      {isLoading ? (
        <div className="flex flex-wrap list-none gap-3">
          {[...Array(4)].map((_, idx) => (
            <div
              key={idx}
              className="bg-Neutral200 rounded-[50%] w-[28px] h-[28px] flex justify-center items-center mr-2 skeleton-animation"
            />
          ))}
        </div>
      ) : (
        <ContributorsList members={members} />
      )}
    </section>
  );
}

export default AllContributorsSection;
