import { Field } from "formik";

import ButtonLoader from "../Loaders/ButtonLoader";
import AddressAutocomplete from "./AddressAutocomplete";
import CustomErrorMessage from "./CustomErrorMessage";

function Step2({ isSubmitting, focused }) {
  return (
    <>
      <div className="flex flex-col gap-4 mb-4">
        {/* CLIENT NAME */}
        <div className="space-y-2">
          <label
            htmlFor="clientName"
            className="block text-xs font-bold text-zinc-800"
          >
            Client Name
          </label>
          <Field
            name="clientName"
            type="text"
            className="w-full p-3 text-sm font-medium bg-white border border-gray-100 rounded outline-none text-neutral-500"
            placeholder="Eg. John P."
          />
          <CustomErrorMessage name="clientName" />
        </div>

        {/* CLIENT EMAIL */}
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <label
              htmlFor="clientEmail"
              className="block text-xs font-bold text-zinc-800"
            >
              Client Email
            </label>
            <span className="text-xs italic font-medium text-Neutral700">
              Optional
            </span>
          </div>
          <Field
            name="clientEmail"
            type="text"
            className="w-full p-3 text-sm font-medium bg-white border border-gray-100 rounded outline-none text-neutral-500"
            placeholder="Eg. JohnP@yopmail.com"
          />
          <CustomErrorMessage name="clientEmail" />
        </div>

        {/* COMPANY NAME */}
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <label
              htmlFor="clientCompanyName"
              className="block text-xs font-bold text-zinc-800"
            >
              Company Name
            </label>
            <span className="text-xs italic font-medium text-Neutral700">
              Optional
            </span>
          </div>
          <Field
            name="clientCompanyName"
            type="text"
            className="w-full p-3 text-sm font-medium bg-white border border-gray-100 rounded outline-none text-neutral-500"
            placeholder="Enter client company name"
          />
          <CustomErrorMessage name="clientCompanyName" />
        </div>

        {/* PHONE NUMBER */}
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <label
              htmlFor="clientPhoneNumber"
              className="block text-xs font-bold text-zinc-800"
            >
              Phone Number
            </label>
            <span className="text-xs italic font-medium text-Neutral700">
              Optional
            </span>
          </div>
          <Field
            name="clientPhoneNumber"
            type="text"
            className="w-full p-3 text-sm font-medium bg-white border border-gray-100 rounded outline-none text-neutral-500"
            placeholder="Enter client phone number"
            length="10"
          />
          <CustomErrorMessage name="clientPhoneNumber" />
        </div>

        {/* ADDRESS */}
        <AddressAutocomplete label="Address" name="clientAddress" />

        {/* VAT */}
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <label
              htmlFor="clientVAT"
              className="block text-xs font-bold text-zinc-800"
            >
              VAT Number
            </label>
            <span className="text-xs italic font-medium text-Neutral700">
              Optional
            </span>
          </div>
          <Field
            name="clientVAT"
            type="text"
            className="w-full p-3 text-sm font-medium bg-white border border-gray-100 rounded outline-none text-neutral-500"
            placeholder="Enter client VAT number"
          />
          <CustomErrorMessage name="clientVAT" />
        </div>
      </div>

      <button
        type="submit"
        className="w-full p-2 py-3 text-xs font-bold tracking-wide uppercase rounded bg-Neutral300 text-AccentRegular hover:text-white not-disabled:hover:bg-AccentRegular disabled:text-Neutral600 disabled:cursor-not-allowed"
        disabled={isSubmitting}
      >
        {isSubmitting && focused ? <ButtonLoader /> : "Save"}
      </button>
    </>
  );
}

export default Step2;
