import axios from "axios";
import { toast } from "react-toastify";

export const CreateMeetingScheduleAction = (finalData) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `api/v1/meeting/create-schedule-meeting`,
        finalData
      );

      if (data?.Message || data?.message) {
        toast.success(data.Message || data.message);
      }

      return dispatch({
        type: "CREATE_MEETING_SCHEDULE",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "CREATE_MEETING_SCHEDULE_ERROR",
          payload: error,
        });
      } else {
        return dispatch({
          type: "CREATE_MEETING_SCHEDULE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export function GetMeetingsListAction() {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `api/v1/meeting/get-schedule-meeting-list`
      );
      return dispatch({ type: "GET_MEETINGS_SUCCESS", payload: data });
    } catch (error) {
      console.error("GetMeetingsListAction Error:", error);
    }
  };
}

export function ReminderMeetingAction(meetingId, setReminderButton) {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`api/v1/meeting/have-reminder`, {
        id: meetingId,
      });

      if (data?.Message || data?.message) {
        setReminderButton("Reminder will be sent 1 day before");
        toast.success(data.Message || data.message);
      }
    } catch (error) {
      console.error("GetMeetingsListAction Error:", error);
    }
  };
}

export function DeleteMeetingAction(
  parentMeetingId,
  linkedMeetingId,
  setOpenCancelMeetingModal
) {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`api/v1/meeting/cancel-meeting`, {
        id: linkedMeetingId,
      });

      if (data?.Message || data?.message) {
        toast.success(data.Message || data.message);
      }

      setOpenCancelMeetingModal(false);

      return dispatch({
        type: "DELETE_MEETING_SUCCESS",
        payload: { parentMeetingId, linkedMeetingId },
      });
    } catch (error) {
      console.error("GetMeetingsListAction Error:", error);
    }
  };
}

export function UpdateMeetingScheduleAction(formData) {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        "api/v1/meeting/update-schedule-meeting",
        formData
      );

      if (data?.Message || data?.message) {
        toast.success(data.Message || data.message);
      }

      return dispatch({
        type: "UPDATE_MEETING_SCHEDULE_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "UPDATE_MEETING_SCHEDULE_ERROR",
          payload: error,
        });
      } else {
        return dispatch({
          type: "UPDATE_MEETING_SCHEDULE_ERROR",
          payload: error,
        });
      }
    }
  };
}

export function DeleteMeetingScheduleAction(scheduleMeetingId) {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `api/v1/meeting/delete-schedule-meeting`,
        {
          id: scheduleMeetingId,
        }
      );

      if (data?.Message || data?.message) {
        toast.success(data.Message || data.message);
      }

      return dispatch({
        type: "DELETE_SCHEDULE_MEETING_SUCCESS",
        payload: data,
      });
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
      }
      console.error("DeleteMeetingScheduleAction", error);
    }
  };
}
