import axios from "axios";
import { toast } from "react-toastify";

export const CREATE_FOLDER = "CREATE_FOLDER";
export const CREATE_FOLDER_ERROR = "CREATE_FOLDER_ERROR";
export const CREATE_FOLDER_SUCCESS = "CREATE_FOLDER_SUCCESS";
export const GET_ALL_FOLDERS = "GET_ALL_FOLDERS";
export const GET_ALL_FOLDERS_ERROR = "GET_ALL_FOLDERS_ERROR";
export const GET_ALL_FOLDERS_SUCCESS = "GET_ALL_FOLDERS_SUCCESS";
export const GET_FOLDER = "GET_FOLDER";
export const GET_FOLDER_ERROR = "GET_FOLDER_ERROR";
export const GET_FOLDER_SUCCESS = "GET_FOLDER_SUCCESS";
export const UPDATE_FOLDER = "UPDATE_FOLDER";
export const UPDATE_FOLDER_ERROR = "UPDATE_FOLDER_ERROR";
export const UPDATE_FOLDER_SUCCESS = "UPDATE_FOLDER_SUCCESS";
export const DELETE_FOLDER = "DELETE_FOLDER";
export const DELETE_FOLDER_ERROR = "DELETE_FOLDER_ERROR";
export const DELETE_FOLDER_SUCCESS = "DELETE_FOLDER_SUCCESS";

export const createFolder = (name, type, client) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `api/v1/folders/create-folder/${client}`,
        {
          name,
          type,
        }
      );

      dispatch({
        type: CREATE_FOLDER_SUCCESS,
        payload: response.data.data,
      });

      toast.success("Folder created successfully");
    } catch (error) {
      toast.error(error?.response?.data?.Error[0].msg);
      dispatch({
        type: CREATE_FOLDER_ERROR,
        payload: error.response.data,
      });
    }
  };
};

export const getFolderByClient = (client) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `api/v1/folders/get-folder-by-client/${client}`
      );

      dispatch({
        type: GET_FOLDER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch({
        type: GET_FOLDER_ERROR,
        payload: error.response.data,
      });
    }
  };
};

export const getFolderById = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`api/v1/folders/get-folder/${id}`);

      dispatch({
        type: GET_FOLDER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch({
        type: GET_FOLDER_ERROR,
        payload: error.response.data,
      });
    }
  };
};

export const updateFolder = (folder) => {
  return async (dispatch) => {
    try {
      const response = await axios.put(
        `api/v1/folders/update-folder/${folder._id}`,
        folder,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      dispatch({
        type: UPDATE_FOLDER_SUCCESS,
        payload: folder,
      });

      toast.success("Folder updated successfully");

      return dispatch(getFolderByClient(folder.client));
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch({
        type: UPDATE_FOLDER_ERROR,
        payload: error.response?.data || error.message,
      });
    }
  };
};

export const updateMultipleFolders = (folders) => {
  return async (dispatch) => {
    try {
      for (const folder of folders) {
        await axios.put(`api/v1/folders/update-folder/${folder._id}`, folder, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      }

      dispatch({
        type: "UPDATE_MULTIPLE_FOLDERS_SUCCESS",
        payload: folders,
      });

      toast.success("Folders updated successfully");
      return dispatch(getFolderByClient(folders[0].client));
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch({
        type: "UPDATE_MULTIPLE_FOLDERS_ERROR",
        payload: error.response?.data || error.message,
      });
    }
  };
};

export const deleteFolder = (id) => {
  return async (dispatch) => {
    try {
      await axios.delete(`api/v1/folders/delete-folder/${id}`);

      dispatch({
        type: DELETE_FOLDER_SUCCESS,
        payload: id,
      });

      toast.success("Folder deleted successfully");
    } catch (error) {
      toast.error(error?.response?.data?.message || "An error occurred");
      dispatch({
        type: DELETE_FOLDER_ERROR,
        payload: error.response.data,
      });
    }
  };
};
