import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import CloseBtn from "../../assets/Images/close-btn.svg";
import { useDispatch, useSelector } from "react-redux";
import { getMemberList, resendInviteAction, modifyMemberClients } from "../../store/actions/memberAction";
import { useState } from "react";
import {
  getAssignMemberClientList,
  getFullClientList,
} from "../../store/actions/clientAction";
import { MinusIcon, PlusIcon } from "@heroicons/react/solid";
import ReactTooltip from "react-tooltip";
import Checkmark from "../../assets/Images/Checkmark.svg";


const dateOptions = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};
const userNameMaxLength = 22;
const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

function ExpandTeamMemberModal({ isOpen, setModal, memberId }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const members = useSelector((state) => state.member.members);
  const clients = useSelector((state) => state.client.fullClientList);
  const [focusedMember, setFocusedMember] = useState(null);
  const [clientsExpanded, setClientsExpanded] = useState(false);
  const [selectClients, setSelectClients] = useState(false);
  const [assignClient, setAssignClient] = useState([]);
  const isInitialMount = useRef(true);
  const [shouldDispatchUpdate, setShouldDispatchUpdate] = useState(false);

  const dateString = focusedMember
    ? new Date(
        focusedMember?.member?.createdAt
          ? focusedMember?.member?.createdAt
          : focusedMember?.createdAt
      )
        .toLocaleDateString("en-US", dateOptions)
        .replace("day", "")
    : "Some time ago";

  let handleMemberFocus = (id) => {
    let member = members?.data.find(
      (mem) => mem?.member?._id === id || mem?._id === id
    );
    member && setFocusedMember(member);
  };

  useEffect(() => {
    if (!members?.data?.length && isOpen) {
      dispatch(getMemberList());
    }
    if (!clients?.data?.length && isOpen) {
      dispatch(getFullClientList());
    }
  }, [dispatch, isOpen, members?.data?.length, clients?.data?.length]);

  useEffect(() => {
    handleMemberFocus(memberId);
  }, [memberId]);

  let handleClientInteraction = (clientId) => {
    navigate(`/admin/clients/manage/${clientId}`);
  };

  useEffect(() => {
    const callGetAssignMemberClientList = async () => {
      const data = await getAssignMemberClientList(memberId);
      setAssignClient(data);
    };
    callGetAssignMemberClientList();
    if (isInitialMount.current) {
      isInitialMount.current = false;
    }
  }, []);

  let handleResendInvite = async (item) => {
    setIsLoading(true);
    dispatch(resendInviteAction({email: item?.email}, setIsLoading));
  };

  const toggleClientSelection = (clientId) => {
    setAssignClient(prev => {
      const isClientSelected = prev.some(client => client._id === clientId);
      if (isClientSelected) {
        return prev.filter(client => client._id !== clientId);
      } else {
        const newClient = clients.data.find(client => client._id === clientId);
        return [...prev, newClient];
      }
    });
    setShouldDispatchUpdate(true);
  };

  const toggleSelectAllClients = () => {
    if (assignClient.length === clients.data.length) {
      setAssignClient([]);
    } else {
      setAssignClient(clients.data);
    }
    setShouldDispatchUpdate(true);
  };

  const dispatchSelectedClients = (selectedClients) => {
    dispatch(modifyMemberClients({member: memberId, clients: selectedClients}))
  };

  useEffect(() => {
    if (!isInitialMount.current && shouldDispatchUpdate) {
      dispatchSelectedClients(assignClient.map(client => client._id));
      setShouldDispatchUpdate(false);
    }
  }, [assignClient, shouldDispatchUpdate]);

  const renderProfileName = () => {
    if (focusedMember?.member && focusedMember?.member?.user_name) {
      if (focusedMember?.member?.user_name.length > userNameMaxLength) {
        return focusedMember?.member?.user_name.substring(0, userNameMaxLength);
      } else {
        return focusedMember?.member?.user_name;
      }
    } else {
      if (focusedMember?.user_name) {
        if (focusedMember?.user_name.length > userNameMaxLength) {
          return focusedMember?.user_name.substring(0, userNameMaxLength);
        } else {
          return focusedMember?.user_name;
        }
      } else {
        return "Not Specified";
      }
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setModal(false)}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="Member"
      >
        <div className="relative rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full  animate__fadeInUp">
          <div className="bg-white p-5 rounded-[8px] w-[85vw] sm:w-[480px] h-[200px] shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)]">
            <div className="flex flex-row">
              {/* PFP */}
              {!focusedMember?.profile &&
              !focusedMember?.member?.profile_path ? (
                <span className="h-[120px] w-[120px]  md:h-[160px] md:w-[160px] bg-Neutral400 text-[40px] 5xl:text-[24px] flex rounded-lg items-center justify-center font-bold border-Miscgrey text-Neutral700">
                  {focusedMember?.user_name[0]}
                </span>
              ) : (
                <div
                  key={`member-p-`}
                  className="h-[120px] w-[120px]  md:h-[160px] md:w-[160px] cursor-pointer flex group overflow-hidden rounded-lg"
                >
                  <img
                    className={`w-full  rounded-lg h-full focus-visible:outline-none border-Neutral200 border`}
                    src={focusedMember?.member?.profile_path}
                    alt="Member Pic"
                  />
                </div>
              )}

              {/* Details */}
              <div className="flex flex-col pl-6">
                <span
                  data-for="longNameToolTip"
                  data-tip={
                    focusedMember?.member?.user_name.length > userNameMaxLength
                      ? focusedMember?.member?.user_name
                      : ""
                  }
                  style={{ fontSize: "16px", lineHeight: "100%" }}
                  className="pt-3 font-semibold text-Neutral800 max-w-[244px]"
                >
                  {renderProfileName()}
                </span>
                <ReactTooltip
                  place="bottom"
                  textColor="#F5F5F7"
                  className="tooltip-font-size"
                  backgroundColor="#292933"
                  id="longNameToolTip"
                />

                <span
                  style={{ fontSize: "10px", lineHeight: "100%" }}
                  className="pt-4 pb-0 my-0 text-Neutral600 font-normal"
                >
                  Added On
                </span>
                <span
                  style={{ fontSize: "12px", lineHeight: "100%" }}
                  className="pt-1 pb-0 my-0 text-Neutral800 font-normal"
                >
                  {dateString}
                </span>
                <span
                  style={{ fontSize: "10px", lineHeight: "100%" }}
                  className="pt-5 pb-0 my-0 text-Neutral600 font-normal"
                >
                  Email
                </span>
                <span
                  style={{ fontSize: "12px", lineHeight: "100%" }}
                  className="pt-1 pb-0 my-0 text-Neutral800 font-normal"
                >
                  {focusedMember?.member?.email
                    ? focusedMember?.member?.email
                    : focusedMember?.email}
                </span>
                {!focusedMember?.verified_at ? (
                  <button onClick={(event) => {
                    handleResendInvite(focusedMember);
                  }} className="my-6 text-Neutral100 bg-AccentRegular flex items-center justify-center text-xs rounded h-6 w-max px-3 focus-visible:outline-purple font-semibold">
                    { isLoading ? 'RESENDING' : 'RESEND PENDING INVITATION'}
                  </button>
                ) : (
                  <span
                    style={{ fontSize: "10px", lineHeight: "100%" }}
                    className="py-6"
                  >
                    <button
                      onClick={() => {setClientsExpanded(!clientsExpanded); setSelectClients(false)}} 
                      className="flex flex-row content-center items-center group transition duration-300"
                    >
                      <span className="">
                        {clientsExpanded ? (
                          <MinusIcon
                            className="transition duration-300 text-AccentRegular group-hover:text-AccentMediumDark"
                            height={10}
                            width={10}
                          />
                        ) : (
                          <PlusIcon
                            className="transition duration-300 text-AccentRegular group-hover:text-AccentMediumDark"
                            height={10}
                            width={10}
                          />
                        )}
                      </span>
                      <span className="transition duration-300 uppercase text-AccentRegular px-1 group-hover:text-AccentMediumDark">
                        Assigned Clients
                      </span>
                    </button>
                  </span>
                )}
              </div>
            </div>
          </div>

         {clientsExpanded && (
          <div className="bg-white px-2 rounded-[8px] w-[288px] relative float-right animate__fadeInUp animate__fadeOutDown bottom-[-4px]">
            <div className="max-h-[160px] overflow-y-auto">
              <ul className="w-full">
                {assignClient?.map((client, index) => {
                  return (
                    <li
                      key={`client-${index}`}
                      onClick={() => handleClientInteraction(client?._id)}
                      className="flex flex-row px-2 py-2 rounded-sm content-center items-center hover:bg-Neutral200 group transition duration-300 cursor-pointer"
                    >
                      {!client?.profile_path ? (
                        <div className="h-[24px] w-[24px] flex items-center justify-center rounded-full text-Neutral700 bg-Neutral400 focus-visible:outline-none">
                          <span className="text-[14px] 5xl:text-[24px] w-full h-full flex items-center justify-center rounded-full font-bold">
                            {client?.client_name[0] ||
                              client?.business_name[0]}
                          </span>
                        </div>
                      ) : (
                        <div className="rounded-full h-[24px] w-[24px] overflow-hidden">
                          <img
                            src={
                              client?.profile_path
                            }
                            className="object-cover"
                            alt=""
                          />
                        </div>
                      )}

                      <span
                        style={{ fontSize: "12px" }}
                        className="px-3 text-Neutral800 group-hover:text-Neutral900 group-hover:font-medium"
                      >
                        {client?.client_name}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
            <button 
              onClick={() => {setClientsExpanded(!clientsExpanded); setSelectClients(true)}} 
              className="my-2 text-AccentRegular flex items-center justify-center text-xs rounded h-8 px-3 focus-visible:outline-purple font-semibold w-full border-[0.5px]"
            >
              Assign to New Client
            </button>
          </div>
          )} 

         {selectClients && (
            <div className="bg-white px-2 overflow-y-auto overflow-x-hidden max-h-[180px] rounded-[8px] w-[288px] relative bottom-[-4px] float-right animate__fadeInUp animate__fadeOutDown">
              <div className="bg-white">
                  <p className="text-Neutral600 flex justify-between text-[10px] font-semibold pt-[9px] pb-[12px] border-t border-Neutral200">
                    <span>Clients</span>
                    <span
                      className="cursor-pointer text-AccentRegular"
                      onClick={toggleSelectAllClients}
                    >
                      {assignClient?.length ===
                      clients?.data?.length
                        ? "UNSELECT ALL"
                        : "SELECT ALL"}
                    </span>
                  </p>
                  <div>
                   {clients?.data?.map((item, index) => {
                      return (
                        <label htmlFor={item?._id}>
                          <div
                            className="flex justify-between items-center rounded-lg py-[6px] hover:bg-Neutral200 ml-[-8px] pl-[10px] cursor-pointer"
                            key={index}
                          >
                            <div className="flex">
                              {item?.profile ? (
                                <div className="rounded-full h-[24px] w-[24px] overflow-hidden">
                                  <img
                                    src={
                                      item?.profile_path
                                    }
                                    className="object-cover"
                                    alt=""
                                  />
                                </div>
                              ) : (
                                <div className="h-[24px] w-[24px] flex items-center justify-center rounded-full text-Neutral700 bg-Neutral400 focus-visible:outline-none">
                                  <span className="text-[14px] 5xl:text-[24px] w-full h-full flex items-center justify-center rounded-full font-bold">
                                    {item?.client_name[0] ||
                                      item?.business_name[0]}
                                  </span>
                                </div>
                              )}
                              <p className="text-Neutral900 pl-4 text-sm font-normal">
                                {item?.client_name ||
                                      item?.business_name}
                              </p>
                            </div>
                            <div className="flex items-center filter-checkbox">
                              <input
                                type="checkbox"
                                id={item?._id}
                                // defaultChecked
                                checked={assignClient?.some(clientObj => clientObj._id === item._id)}
                                onChange={() => toggleClientSelection(item?._id)}
                                name="A3-confirmation"
                                value="yes"
                                className="opacity-0 absolute cursor-pointer h-[24px] w-[24px] transition duration-300"
                              />
                              <div className="bg-white border-Neutral300 border-[1.5px] custom-chackbox rounded-md hover:bg-AccentLight hover:border-black w-[24px] h-[24px] flex flex-shrink-0 justify-center items-center mr-2 transition duration-300 ">
                                <img
                                  src={Checkmark}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </label>
                      );
                    })} 
                  </div>
                </div>
            </div>
          )} 

          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => setModal(false)}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ExpandTeamMemberModal;
