import { generateInitials } from "../../utils";
import BoltIcon from "./icons/BoltIcon";
import ClockIcon from "./icons/ClockIcon";
import PeopleIcon from "./icons/PeopleIcon";

function TimeTrackingRow({
  userImage,
  userName,
  hours,
  clients,
  projects,
  cost,
}) {
  return (
    <div className="flex items-center gap-4 px-4 py-4 border rounded-xl border-Neutral300">
      <div className="w-[40%] flex items-center gap-3">
        <div className="grid overflow-hidden rounded-full size-10 bg-Neutral200 place-items-center">
          {userImage ? (
            <img
              src={userImage}
              alt={userName}
              className="object-cover size-full"
            />
          ) : (
            <span className="text-xs font-semibold text-Neutral800">
              {generateInitials(userName)}
            </span>
          )}
        </div>
        <h3 className="text-base text-Neutral900">{userName}</h3>
      </div>
      <div className="w-[60%] flex items-center gap-3">
        <div className="flex items-center justify-center flex-1 gap-1 text-base text-Neutral800">
          <ClockIcon className="text-Blue500 size-4" />
          <span>{hours?.toFixed(2)}</span>
        </div>
        <div className="flex items-center justify-center flex-1 gap-1 text-base text-Neutral800">
          <PeopleIcon className="text-Blue500 size-5" />
          <span>{clients}</span>
        </div>
        <div className="flex items-center justify-center flex-1 gap-1 text-base text-Neutral800">
          <BoltIcon className="text-Blue500 size-4" />
          <span>{projects}</span>
        </div>
        <div className="flex-1 text-base text-center text-Neutral800">
          ${cost?.toFixed(2)}
        </div>
      </div>
    </div>
  );
}

export default TimeTrackingRow;
