import React from "react";

function ClientQuestionnaireLoader() {
  return (
    <div className="pt-8">
      <div className="block mb-10">
        <div className="w-[200px] mb-4 bg-[#f6f7f8] h-3 bg-no-repeat skeleton-animation relative rounded"></div>
        <div className="w-[200px] p-3 border border-Neutral300 rounded mb-5">
          <div className="w-full bg-[#f6f7f8] block h-5 bg-no-repeat skeleton-animation relative rounded"></div>
        </div>
        <div className="w-[400px] mb-4 bg-[#f6f7f8] h-2 bg-no-repeat skeleton-animation relative rounded"></div>
      </div>

      <div className="flex justify-between w-full max-w-[816px] flex-wrap">
        {Array.from({ length: 4 }).map((item) => {
          return (
            <div
              key={item}
              className="w-full max-w-[388px] pt-8 px-8 pb-5 border border-Neutral300 rounded mb-10"
            >
              <div className="w-2/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="overflow-hidden relative">
                <div className="5xl:h-[100px] h-[88px] overflow-hidden mb-4 pb-[28px]">
                  <div className="rounded w-6/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
                  <div className="rounded w-6/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
                  <div className="rounded w-3/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
                </div>
              </div>
              <div className="flex justify-between relative bottom-0">
                <div className="w-[28px] h-[28px] rounded-full bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
                <div className="flex items-center">
                  <div className="bg-[#f6f7f8] rounded block bg-no-repeat skeleton-animation relative w-20 h-8"></div>
                  <div className="bg-[#f6f7f8] rounded block bg-no-repeat skeleton-animation relative w-20 h-8 ml-3"></div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="large-primary-btn fixed bottom-[80px] right-[48px] flex h-[48px] bg-[#f6f7f8] bg-no-repeat skeleton-animation w-full 5xl:max-w-[200px] max-w-[172px] rounded transition duration-300 ease-in-out"></div>
    </div>
  );
}

export default ClientQuestionnaireLoader;
