import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { VerifyUserProfileAction } from "../../store/actions/authAction";
import SigninBg from "../../assets/Images/signin-bg-shap.png";
import Logo from "../../assets/Images/Logo.svg";

const Signin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("q");
    if(token) {
      dispatch(VerifyUserProfileAction(token, navigate))
    } else {
      toast.error("Token not provided");
    }
  }, []);

  return (
    <section>
      <div className="flex">
        <div className="w-full max-w-[50%]  sm:h-screen relative md:block hidden">
          <div className="absolute top-[50%] w-full 5xl:max-w-[440px] max-w-[384px] left-[50%] -translate-x-1/2 -translate-y-1/2 ml:px-0 px-3">
            <div className="flex items-center">
              <img src={Logo} alt="logo" />
              <h2 className="5xl:text-[60px] lg:text-[56px] text-[40px] font-black w-full max-w-[260px] leading-[0.85] text-Neutral000 ml-4">
                Client Manager
              </h2>
            </div>
            <p className="text-Neutral100 5xl:text-[18px] text-[16px] mt-[12px]">
              The Simple Client Onboarding & Management Tool
            </p>
          </div>
          <img
            className="w-full object-cover h-screen"
            src={SigninBg}
            alt="background"
          />
        </div>
        <div className="w-full md:max-w-[50%] max-w-full md:h-screen h-full flex md:flex-row flex-col md:items-center justify-center relative px-3 overflow-y-auto">
          <div className="w-full max-w-[320px] 5xl:max-w-[400px] md:mt-0 mt-12 2xl:pt-0 md:pt-24 md:mx-0 mx-auto">
            <h1 className="mt-3 text-[32px] 5xl:text-[36px] font-bold mb-[40px] text-Neutral900 text-center">
              Verifying Profile
            </h1>
            <div className="p-4 flex justify-center items-center relative overflow-hidden z-10">
              <div className="white dot-falling"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Signin;
