import copy from "copy-to-clipboard";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router";
import CheckmarkIcon from "../../assets/Images/Checkmark.svg";
import LinkCopied from "../../assets/Images/Link Copied.svg";
import Links from "../../assets/Images/Link Icon.svg";
import LinkIcon from "../../assets/Images/Link.svg";
import RoundLogo from "../../assets/Images/Round-logo-requestInfo.svg";
import CloseBtn from "../../assets/Images/close-btn.svg";
import ImgIcon from "../../assets/Images/img-icon.svg";
import boxshape1 from "../../assets/Images/requestInfo-shape-1.svg";
import BgShape1 from "../../components/Icons/BgShape1";
import BgShape2 from "../../components/Icons/BgShape2";
import BgShape3 from "../../components/Icons/BgShape3";
import useBaseDomainURL from "../../hooks/useBaseDomainURL";
import useHasWhiteLabelSubscription from "../../hooks/useHasWhiteLabelSubscription";
import { SaveFormConfig } from "../../store/actions/questionnaireAction";
import UploadFiles from "../Inputs/UploadFiles";
import UpgradeCard from "../White-Label/UpgradeCard";
import FollowUpEmailModal from "./FollowUpEmailModal";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
    overflowY: "auto",
  },
  content: {
    position: "unset",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const customStyless = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
    zIndex: "9999999999",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

function QuestionnaireFormPreview({
  modalIsOpen,
  setModal,
  preview = false,
  clientDetail,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hasWhiteLabelSubscription = useHasWhiteLabelSubscription();
  const baseDomainUrl = useBaseDomainURL();

  const loggedInUser = useSelector((state) => state.auth.user);

  const [modalIsOpens, setIsOpens] = useState(false);
  const [formData, setFormData] = useState(null);
  const [isOpenEmailModal, setIsOpenEmailModal] = useState(false);

  const questionnaireData = useSelector(
    (state) => state.questionnaire?.questionnaire?.data
  );
  const questionData = useSelector(
    (state) => state?.template?.template?.question
  );

  const colorList = [
    { id: 1, color: "bg-Pink400", box: "color-1", text: "text-color-1" },
    { id: 2, color: "bg-Blue400", box: "color-2", text: "text-color-2" },
    { id: 3, color: "bg-Green400", box: "color-3", text: "text-color-3" },
    { id: 4, color: "bg-AccentRegular", box: "color-4", text: "text-color-4" },
    { id: 5, color: "bg-Neutral800", box: "color-5", text: "text-color-5" },
  ];

  const [selectedBox, setSelectedBox] = useState("box-1");
  const [copyStatus, setCopyStatus] = useState("Copy Link");
  const [colorBox, setColorBox] = useState({
    id: 4,
    color: "bg-AccentRegular",
    box: "color-4",
    text: "text-AccentRegular",
  });

  useEffect(() => {
    if (questionnaireData && !preview) {
      setFormData(questionnaireData);
    }
    if (questionData && preview) {
      setFormData(questionData);
    }
  }, [questionnaireData, questionData, preview]);

  useEffect(() => {
    if (formData?.config) {
      const parsedConfig = formData?.config;
      if (parsedConfig && parsedConfig?.colorBox) {
        setColorBox(parsedConfig?.colorBox);
      }
      if (parsedConfig && parsedConfig?.selctedBox) {
        setSelectedBox(parsedConfig?.selctedBox);
      }
    }
  }, [formData]);

  const saveFormConfig = () => {
    setCopyStatus("Loading...");
    if (
      selectedBox !== formData?.config?.selctedBox ||
      colorBox !== formData?.config?.colorBox
    ) {
      dispatch(
        SaveFormConfig(
          {
            ...formData,
            id: formData._id,
            config: { selctedBox: selectedBox, colorBox },
          },
          setCopyStatus
        )
      );
    } else {
      copy(`${baseDomainUrl}/request-info/${formData._id}`);
      setCopyStatus("Copied");
      setTimeout(() => {
        setCopyStatus("Copy Link");
      }, 1000);
    }
  };

  const handleEmailModal = () => {
    setIsOpens(false);
    setModal(false);
    setIsOpenEmailModal(true);
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={setModal}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="View Form Preview Modal"
      >
        <div className="view-form-prev relative bg-white text-left transform transition-all sm:my-8 max-w-[604px] min-w-[604px] mx-auto sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          {preview && (
            <h6 className="italic font-medium text-xs tracking-[0.04em] py-2 px-8 bg-AccentRegular text-Neutral000">
              Please Note - This is a template preview. To use this template,
              select your client, then select the template
            </h6>
          )}

          {/* Questionnaire page */}
          <div className="bg-white px-[60px] pt-[60px] pb-[24px] rounded-[8px] relative overflow-hidden">
            <div
              className={`absolute top-0 left-0 h-full bg-shap-main ${selectedBox} ${colorBox.box}`}
            >
              <div className="absolute top-0 left-0 transition duration-300 opacity-0 bg-shape-1">
                <BgShape1 />
              </div>
              <div className="absolute top-0 left-0 transition duration-300 opacity-0 bg-shape-2">
                <BgShape2 />
              </div>
              <div className="absolute top-0 left-0 transition duration-300 opacity-0 bg-shape-3">
                <BgShape3 />
              </div>
            </div>

            <div>
              <div className="border-b border-Neutral400">
                <h2 className="text-black font-bold text-[20px] 5xl:text-[24px] mb-5">
                  {formData?.title} Information Request
                </h2>

                {preview && (
                  <p className="pb-10 text-xs text-Neutral800">
                    Please complete these steps as best as you can. Have any
                    questions? Email us at{" "}
                    <strong
                      className={`text-AccentRegular transition duration-300 ${colorBox.text}`}
                    >
                      {formData?.created_by?.email}
                    </strong>{" "}
                    so that we can help you out.
                  </p>
                )}
                {!preview && (
                  <p
                    className="pb-10 text-xs text-Neutral800"
                    dangerouslySetInnerHTML={{
                      __html:
                        formData?.created_by?.text_for_questionnaire?.replace(
                          formData?.created_by?.email,
                          `<strong class="text-AccentRegular transition duration-300 ${colorBox.text}">${formData?.created_by?.email}</strong>`
                        ),
                    }}
                  ></p>
                )}
              </div>
              {formData?.questions?.map((item, index) => (
                <div className="pt-[48px]" key={item._id}>
                  <div className="flex items-center justify-between mb-4">
                    <h3 className="text-base font-bold text-black">
                      {item.title}
                    </h3>
                    <div
                      className={`flex items-center justify-center w-[28px] font-medium h-[28px] border border-Neutral300 rounded-full font-feature-settings text-[18px] 5xl:text-[22px] text-AccentRegular transition duration-300 shrink-0 ${colorBox.text}`}
                    >
                      {index + 1}
                    </div>
                  </div>
                  <p className="mb-4 text-xs text-Neutral800">
                    {item.question}{" "}
                    {item?.is_required && (
                      <strong
                        className={`text-AccentRegular transition duration-300 ${colorBox.text}`}
                      >
                        *
                      </strong>
                    )}
                    <strong
                      className={`text-AccentRegular transition duration-300 ${colorBox.text}`}
                    >
                      {/* {item.email} */}
                    </strong>{" "}
                    {/* {item.extdetails} */}
                  </p>
                  {(item.type === "files" || item?.type === "file") && (
                    <div className="flex justify-between">
                      <div className="w-full max-w-[50%] pr-2">
                        <UploadFiles
                          label={
                            (item?.type === "files" && "Upload Files") ||
                            (item?.type === "file" && "Upload File")
                          }
                          id={
                            (item?.type === "files" && "files") ||
                            (item?.type === "file" && "file")
                          }
                          name={
                            (item?.type === "files" && "files") ||
                            (item?.type === "file" && "file")
                          }
                          icon={ImgIcon}
                        />
                      </div>
                    </div>
                  )}
                  {(item.type === "profile" || item.type === "image") && (
                    <div className="flex justify-between">
                      <div className="w-full max-w-[50%] pr-2">
                        <UploadFiles
                          label={
                            item?.type === "profile"
                              ? "Upload Profile"
                              : "Upload Image"
                          }
                          id="profile"
                          name="profile"
                          icon={ImgIcon}
                        />
                      </div>
                      <div className="w-full max-w-[50%] pr-2"></div>
                    </div>
                  )}
                  {item.type === "text" && (
                    <textarea
                      className="mt-4 border border-Neutral400 w-full resize-none p-6 h-[168px] placeholder:text-sm 5xl:placeholder:text-lg placeholder:font-medium font-medium focus:outline-none rounded hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                      placeholder={item.placeholder}
                    />
                  )}
                  {(item.type === "radio" || item.type === "checkbox") && (
                    <div className="flex flex-wrap">
                      {item?.[`${item.type}_options`]?.map((option, index) => (
                        <div
                          key={index}
                          className="w-full max-w-[50%] pr-2 flex items-center"
                        >
                          <input
                            type={item.type}
                            id={option?.value}
                            name={item.type}
                            className="mr-2"
                          />
                          <label
                            htmlFor={option?.value}
                            className="text-xs text-Neutral800"
                          >
                            {option?.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
              {loggedInUser?.brandIdentity.status === "deactive" ? (
                <div className="pt-[52px] flex items-center justify-center">
                  {/* <img src={RoundLogo} alt="" /> */}
                  <img
                    src={
                      loggedInUser?.brandIdentity?.logo
                        ? loggedInUser?.brandIdentity?.logo
                        : RoundLogo
                    }
                    alt="Logo"
                    id="cm_logo"
                    className="mr-[6px] h-7 w-7"
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Modal>

      {/* Show upgrade card only when modal is open else it will show all the time regardless of the modal */}
      {modalIsOpen && !loggedInUser.white_label_payment && (
        <UpgradeCard
          title="Customise what your clients see..."
          features={[
            'Remove "Made with ClientManager"',
            "Use your company logo",
            "Modify background and button colors",
            "And much more...",
          ]}
          className="z-[99999] left-5"
          onViewPlanClick={() => {
            navigate("/white-label/plans");
          }}
        />
      )}

      <div className={`${modalIsOpen ? "block" : "hidden"}`}>
        <div
          className="fixed right-12 cursor-pointer top-[62px] z-[999999]"
          onClick={() => setModal(false)}
        >
          <img src={CloseBtn} alt="" />
        </div>

        {/* Page Design Selector */}
        <div className="w-full max-w-[176px] fixed right-[48px] bottom-[80px] z-[999999]">
          {!preview && (
            <div className="w-full max-w-[176px] rounded p-2 flex flex-wrap drop-shadow-Raisedmodal bg-Neutral000">
              <div
                className="p-1 w-full max-w-[50%] cursor-pointer"
                onClick={() => setSelectedBox("box-0")}
              >
                <img
                  className={`border border-Neutral100 rounded-sm transition duration-300 ${
                    selectedBox === "box-0"
                      ? "border-4 border-AccentRegular rounded-sm"
                      : ""
                  }`}
                  src={boxshape1}
                  alt=""
                />
              </div>
              <div
                className="p-1 w-full max-w-[50%] cursor-pointer"
                onClick={() => setSelectedBox("box-1")}
              >
                <div
                  className={`selctedbox box-1 ${
                    selectedBox === "box-1"
                      ? "border-4 border-AccentRegular rounded-sm"
                      : ""
                  } ${colorBox.box}`}
                ></div>
              </div>
              <div
                className="p-1 w-full max-w-[50%] cursor-pointer"
                onClick={() => setSelectedBox("box-2")}
              >
                <div
                  className={`selctedbox box-2 ${
                    selectedBox === "box-2"
                      ? "border-4 border-AccentRegular rounded-sm"
                      : ""
                  } ${colorBox.box}`}
                ></div>
              </div>
              <div
                className="p-1 w-full max-w-[50%] cursor-pointer"
                onClick={() => setSelectedBox("box-3")}
              >
                <div
                  className={`selctedbox box-3 ${
                    selectedBox === "box-3"
                      ? "border-4 border-AccentRegular rounded-sm"
                      : ""
                  } ${colorBox.box}`}
                ></div>
              </div>
            </div>
          )}
          {!preview && !hasWhiteLabelSubscription && (
            <div className="w-full max-w-[176px] rounded p-2 flex flex-wrap mt-6 drop-shadow-Raisedmodal bg-Neutral000">
              {colorList?.map((item) => (
                <div
                  key={item.id}
                  className="p-1 w-full max-w-[20%] cursor-pointer"
                  onClick={() => setColorBox(item)}
                >
                  <span
                    className={`w-6 h-6 rounded-full block transition duration-300 ${item.color}`}
                  >
                    {/* {colorBox == item.id ? <img src={CheckmarkIcon} alt="" /> : "" } */}
                    <img
                      className={`transition duration-300 ${
                        colorBox.box === item.box ? "opacity-100" : "opacity-0"
                      }`}
                      src={CheckmarkIcon}
                      alt=""
                    />
                  </span>
                </div>
              ))}
            </div>
          )}
          {!preview && (
            <button
              className="button-hover large-primary-btn text-sm text-center flex items-center justify-center h-[48px] text-Neutral100 bg-AccentRegular font-semibold w-full rounded focus:outline focus:outline-8 focus:outline-AccentMediumLight focus-visible:outline-0 transition duration-300 ease-in-out drop-shadow-Raisedmodal mt-12"
              type="button"
              onClick={() => {
                setIsOpens(true);
                saveFormConfig();
              }}
            >
              <span className="relative z-10 flex items-center drop-shadow-Texts">
                {copyStatus === "Loading..." ? (
                  <div className="flex justify-center">
                    <div className="pr-2 white dot-falling"></div>
                  </div>
                ) : (
                  <>
                    {copyStatus} <img className="ml-1" src={LinkIcon} alt="" />
                  </>
                )}
              </span>
            </button>
          )}
          <Modal
            isOpen={modalIsOpens}
            onRequestClose={() => setIsOpens(false)}
            style={customStyless}
            closeTimeoutMS={300}
            contentLabel="Archived client list Modal"
          >
            <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[416px] sm:min-w-[416px]  max-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
              <div className="bg-white p-[24px_32px_30px_32px] rounded-[8px]">
                <div className="flex items-center justify-between pb-2 border-b border-Neutral200">
                  <p className="text-xs font-semibold text-Neutral700">
                    LINK COPIED
                  </p>
                  <img
                    className="cursor-pointer"
                    onClick={() => saveFormConfig()}
                    src={Links}
                    alt=""
                  />
                </div>
                <div className="text-center pt-7 pb-7">
                  {/* <img src={CopyMassage} className="m-auto" alt="" /> */}
                  <img src={LinkCopied} className="m-auto" alt="" />
                </div>
                <p className="pb-8 text-sm font-normal text-Neutral800">
                  Send this copied link to your client. Answers will appear in
                  <span className="text-sm font-semibold text-Neutral800">
                    &nbsp;Client Details/Questionnaire
                  </span>
                </p>
                <button
                  onClick={() => {
                    saveFormConfig();
                    setIsOpens(false);
                  }}
                  class="text-Neutral100 bg-AccentRegular flex items-center justify-center text-sm rounded focus-visible:outline-purple font-semibold hover:bg-AccentMediumDark transition duration-300 w-full py-4 copy-btn"
                  type="button"
                >
                  Got it! I'll send the link
                </button>

                <button
                  onClick={(e) => {
                    handleEmailModal();
                  }}
                  className="relative block w-full py-3 mt-4 overflow-hidden text-sm font-bold text-center transition duration-300 ease-in-out border rounded text-AccentRegular bg-Neutral100 focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight border-AccentRegular hover:bg-Neutral300"
                >
                  SEND AS EMAIL
                </button>
              </div>
              <div
                className="absolute right-0 top-[-50px] z-30 cursor-pointer"
                onClick={() => setIsOpens(false)}
              >
                <img src={CloseBtn} alt="" />
              </div>
            </div>
          </Modal>
        </div>
      </div>

      <FollowUpEmailModal
        isOpen={isOpenEmailModal}
        setIsOpen={setIsOpenEmailModal}
        type="Questionnaire"
        data={clientDetail}
        id={formData?._id}
        selectedData={formData}
      />
    </>
  );
}

export default QuestionnaireFormPreview;
