import CreateProjectIllustration from "../assets/Images/Project-Illustration.png";

function EmptyProjectCard({ onClick = () => {} }) {
  return (
    <div className="flex items-center justify-center max-w-xs mx-auto bg-Neutral100">
      <div className="p-6 text-center rounded-lg bg-Neutral000">
        <div className="flex justify-center mb-6">
          <img
            src={CreateProjectIllustration}
            alt="Illustration"
            className="object-cover h-40"
          />
        </div>
        <h2 className="mb-2 text-2xl font-black text-Neutral900">
          Create a Project
        </h2>
        <p className="mb-5 text-sm text-Neutral700">
          Manage tasks, tracked time, and other project info in one place
        </p>
        <button
          className="w-full px-4 py-3 text-sm font-semibold border rounded-md text-Neutral700 border-Neutral400 hover:bg-AccentRegular hover:text-Neutral100"
          onClick={onClick}
        >
          Add Project
        </button>
      </div>
    </div>
  );
}

export default EmptyProjectCard;
