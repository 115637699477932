import React, { useState, Fragment, useEffect, useRef } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import googleCalendarPlugin from "@fullcalendar/google-calendar";
import moment from "moment";
import { debounce } from "lodash";
import { Menu, Transition } from "@headlessui/react";
import { StyleWrapper } from "./components/StyleWrapper";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DotsHorizontalIcon,
} from "@heroicons/react/solid";
import { useDispatch, useSelector } from "react-redux";
import CreateEventsModal from "../../components/Modals/CreateEventsModal";
import EditeEventsModal from "../Modals/EditeEventsModal";
import { GetEventsAction } from "../../store/actions/eventAction";
import { GetTaskByDueDate } from "../../store/actions/tasksAction";
import { useLocation, useNavigate } from "react-router";
// gapi
import { gapi } from "gapi-script";
import CalendarIcon from "../Icons/CalendarIcon";
import GoogleCalendarModal from "../Modals/GoogleCalendarModal";
import GoogleCalendarDetailsModal from "../Modals/GoogleCalendarDetailsModal";
import { toast } from "react-toastify";
import SchedulerModal from "../Modals/ScheduleMeetingModal/SchedulerModal";
import axios from "axios";
import { googleAuthCallbackAction } from "../../store/actions/authAction";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Fullcalander() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get query params
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const code = query.get("code");

  const [modalIsOpen, setIsOpen] = useState(false);
  const [meetingModalIsOpen, setMeetingModalIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isEvent, setIsEvent] = useState(false);
  const [editEvent, setEditEvent] = useState(null);
  const [calenderTitle, setCalenderTitle] = useState("");
  const [calendarView, setCalenderView] = useState("monthGrid");
  const [events, setEvents] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [googleCalendarModal, setGoogleCalendarModal] = useState(false);
  const [googleCalendarDetailsModal, setGoogleCalendarDetailsModal] =
    useState(false);
  const calendarRef = useRef(null);
  const eventsData = useSelector((state) => state?.event?.events);
  const tasksData = useSelector((state) => state?.tasks?.events);
  const user = useSelector((state) => state?.auth?.user);
  // const [calenderViewDate, setCalenderViewDate] = useState(
  //   moment(new Date()).format()
  // );
  function openModal() {
    setIsOpen(true);
  }

  function openMeetingModal() {
    setMeetingModalIsOpen(true);
  }

  function setModal(value) {
    setIsOpen(value);
  }

  const changeCalenderView = (val) => {
    let calendarApi = calendarRef.current.getApi();
    setCalenderView(val);
    if (val === "monthGrid") {
      calendarApi.changeView("dayGridMonth");
      setCalenderTitle(calendarApi.getCurrentData().viewTitle);
      return;
    }
    if (val === "weekGrid") {
      calendarApi.changeView("timeGridWeek", new Date());
      setCalenderTitle(calendarApi.getCurrentData().viewTitle);
      return;
    }
    if (val === "dayGrid") {
      calendarApi.changeView("timeGridDay");
      setCalenderTitle(calendarApi.getCurrentData().viewTitle);
      return;
    }
  };

  const onCalanderNavigate = (val) => {
    let calendarApi = calendarRef.current.getApi();
    if (val === "next") {
      calendarApi.next();
    }
    if (val === "prev") {
      calendarApi.prev();
    }
    if (val === "today") {
      calendarApi.today();
    }
    setCalenderTitle(calendarApi.getCurrentData().viewTitle);
  };

  const debouncedGetEvents = useRef(
    debounce(async (startDate, endDate) => {
      const abcd = await dispatch(
        GetEventsAction(
          moment(startDate).format("YYYY-MM-DD"),
          moment(endDate).format("YYYY-MM-DD")
        )
      );
      return abcd;
    }, 600)
  ).current;

  const debouncedGetTasks = useRef(
    debounce(async (startDate, endDate) => {
      const abcd = await dispatch(
        GetTaskByDueDate(
          moment(startDate).format("YYYY-MM-DD"),
          moment(endDate).format("YYYY-MM-DD")
        )
      );
      return abcd;
    }, 600)
  ).current;

  useEffect(() => {
    return () => {
      debouncedGetEvents.cancel();
      debouncedGetTasks.cancel();
    };
  }, [debouncedGetEvents, debouncedGetTasks]);

  useEffect(() => {
    const getAuthCallback = async () => {
      dispatch(googleAuthCallbackAction(code, navigate));
    };

    if (code) {
      getAuthCallback();
    }
  }, [code]);

  useEffect(() => {
    const abcd2 = eventsData.data.map((item) => {
      return {
        ...item,
        start: !item?.lead
          ? moment(item?.start_time, "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]").format()
          : moment(item?.start_time, "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]")
              .add(1, "days")
              .format(),
        end: moment(item.end_time, "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]").format(),
      };
    });
    setEvents(abcd2);
  }, [eventsData]);

  useEffect(() => {
    const abcd2 = tasksData.data.map((item) => {
      return {
        ...item,
        start: moment(item?.date).format(),
        end: moment(item?.date).format(),
        event_type: "task",
      };
    });
    setTasks(abcd2);
  }, [tasksData]);

  const arrangeEvents = (payload) => {
    // setEvents([
    //   { title: "event 1", date: "2022-10-12" },
    //   { title: "event 2", date: "2022-10-12" },
    // ]);
  };
  const fetchEvents = (payload) => {
    debouncedGetEvents(payload.start, payload.end);
    debouncedGetTasks(payload.start, payload.end);
    arrangeEvents();
  };

  useEffect(() => {
    gapi.load("client:auth2", () => {
      gapi.client
        .init({
          // apiKey: "AIzaSyCXZImC7eODJSRZriLUNQSKdjpT2A5O_pg", //don't init with an api key it messes with the creation of events
          // apiKey: "AIzaSyBPwFUG5Jq94GGrx1xPktFN_G6wRZTm9M8", //alexs
          clientId:
            "860737785330-69p10ng9sdf6ed7r82kaet42ulrdp7bb.apps.googleusercontent.com", //alexs
          // "844530265625-1r5vhr2mclflg4vsjpee9invsb71o59k.apps.googleusercontent.com", //chriags - needs to be reviewed in google console, not set up correctly
          discoveryDocs: [
            "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
          ],
          scope: "https://www.googleapis.com/auth/calendar",
        })
        .then(() => {
          // // can do some stuff here if we want
          // const authInstance = gapi.auth2.getAuthInstance();
          // if (authInstance.isSignedIn.get()) {
          //   // fetchGoogleCalendars();
          // }
          // authInstance.isSignedIn.listen((signinStatus) => {
          //   if (signinStatus) {
          //     // fetchGoogleCalendars();
          //   }
          // });
        });

      gapi.client.load("calendar", "v3", () =>
        console.log("Calendar API loaded")
      );

      // gapi.auth2.getAuthInstance().signIn(); - can auto sign in if we want
    });
  }, []);

  const execute = async (data) => {
    if (user?.google_calendar_ids?.length === 0) {
      return;
    }

    // const response = await gapi.client.calendar.calendarList.list();

    // const calendars = response.result.items;

    gapi.client.calendar.events
      .insert({
        calendarId: user?.google_calendar_ids?.[0],
        // calendarId: calendars?.[0]?.id, //once verified we can update this for the user to select which calendar to add to
        resource: {
          summary: data.title,
          id: data._id,
          start: {
            dateTime: data.start_time,
          },
          end: {
            dateTime: data.end_time,
          },
        },
      })
      .then((response) => {
        // Handle the results here (response.result has the parsed body).
        console.log("Response", response);
      })
      .catch((err) => {
        toast.error(err?.result?.error?.message);
      });
  };

  const updateEvent = (data) => {
    if (user?.google_calendar_ids?.length === 0) {
      return;
    }
    gapi.client.calendar.events
      .update({
        calendarId: user?.google_calendar_ids?.[0],
        // calendarId: userCalendars?.[0]?.id, //once verified we can update this for the user to select which calendar to add to
        eventId: data._id,
        resource: {
          summary: data.title,
          start: {
            dateTime: data.start_time,
          },
          end: {
            dateTime: data.end_time,
          },
        },
      })
      .then((response) => {
        // Handle the results here (response.result has the parsed body).
        console.log("Response", response);
      })
      .catch((err) => {
        if (err?.result?.error?.message === "Not Found") {
          return;
        }
        toast.error(err?.result?.error?.message);
      });
  };

  const deleteEvent = (data) => {
    if (user?.google_calendar_ids?.length === 0) {
      return;
    }
    gapi.client.calendar.events
      .delete({
        calendarId: user?.google_calendar_ids?.[0],
        // calendarId: userCalendars?.[0]?.id, //once verified we can update this for the user to select which calendar to add to
        eventId: data,
      })
      .then((response) => {
        // Handle the results here (response.result has the parsed body).
        console.log("Response", response);
      })
      .catch((err) => {
        toast.error(err?.result?.error?.message);
      });
  };

  return (
    <>
      {/* Add google calendar button */}
      <div className="flex items-center justify-between py-4 lg:flex-none">
        <div className="flex items-center gap-2">
          {!user?.google_calendar_ids?.length > 0 &&
            !user?.googleAuth &&
            !user?.googleAuth?._id && <CalendarIcon />}
          <button
            type="button"
            onClick={() => {
              setGoogleCalendarModal(true);
            }}
            className="text-xs text-center inline-block text-AccentRegular relative font-semibold ml-1 rounded focus-visible:outline focus-visible:outline-[6px] focus-visible:drop-shadow-none focus-visible:outline-AccentMediumLight transition duration-300 ease-in-out"
          >
            <span className="relative z-10">
              {user?.google_calendar_ids?.length > 0 ||
              (user?.googleAuth && user?.googleAuth?._id) ? (
                <>
                  ✅{" "}
                  <span className="underline">
                    Google Calendar Is Now Integrated
                  </span>
                </>
              ) : (
                <span className="underline">Integrate Google Calendar</span>
              )}
            </span>
          </button>
        </div>
      </div>
      <div className="border border-Neutral400 md:mb-[36px] rounded-lg overflow-hidden fullcelender">
        <div className="lg:flex lg:h-full lg:flex-col">
          {/* Calender Header */}
          <header className="relative z-20 flex items-center justify-between px-6 py-4 border-b border-Neutral300 lg:flex-none bg-Neutral200">
            <h1 className="text-bs md:text-[16px] md:font-semibold tracking-[0.08em] md:tracking-normal font-bold text-Neutral900">
              <time dateTime="2022-01">{calenderTitle}</time>
            </h1>
            <div className="flex items-center">
              <div className="flex items-center justify-between rounded-md shadow-sm md:items-stretch h-8 w-full max-w-[120px]">
                <button
                  type="button"
                  className="flex items-center justify-center rounded-l-md border-[0.5px] border-r-0 border-Neutral500 bg-white text-Neutral400 hover:text-Neutral900 focus:relative md:w-9 duration-300 transition focus-visible:outline-none"
                  onClick={() => onCalanderNavigate("prev")}
                >
                  <span className="sr-only">Previous month</span>
                  <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
                </button>
                <button
                  type="button"
                  onClick={() => onCalanderNavigate("today")}
                  className="hidden border-[0.5px] border-x-0 border-Neutral500 bg-white px-2 text-xs font-semibold text-Neutral700 hover:text-Neutral900 focus:relative md:block duration-300 transition focus-visible:outline-none"
                >
                  Today
                </button>
                <span className="relative w-px h-5 -mx-px bg-Neutral500 md:hidden" />
                <button
                  type="button"
                  className="flex items-center justify-center rounded-r-md border-[0.5px] border-l-0 border-Neutral500 bg-white text-Neutral400 hover:text-Neutral900 focus:relative md:w-9 md duration-300 transition focus-visible:outline-none"
                  onClick={() => onCalanderNavigate("next")}
                >
                  <span className="sr-only">Next month</span>
                  <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
                </button>
              </div>
              <div className="hidden md:ml-4 md:flex md:items-center">
                <Menu as="div" className="relative">
                  <Menu.Button
                    type="button"
                    className="flex items-center rounded-md border-[0.5px] border-Neutral500 bg-white h-8 py-1 pl-2 pr-2 text-xs font-semibold text-Neutral700 hover:text-Neutral900 shadow-sm focuse:border-0 focus-visible:outline-none"
                  >
                    {calendarView === "dayGrid"
                      ? "Day View"
                      : calendarView === "weekGrid"
                      ? "Week View"
                      : calendarView === "monthGrid"
                      ? "Month view"
                      : "Year View"}
                    <ChevronDownIcon
                      className="w-5 h-5 ml-1 text-Neutral400"
                      aria-hidden="true"
                    />
                  </Menu.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 mt-3 overflow-hidden origin-top-right bg-white rounded-md shadow-lg focus:outline-none w-36 ring-1 ring-black ring-opacity-5">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              type="button"
                              className={classNames(
                                active
                                  ? "bg-Neutral200 text-Neutral900"
                                  : "text-Neutral700",
                                "block px-4 py-2 text-xs w-full text-left"
                              )}
                              onClick={() => changeCalenderView("dayGrid")}
                            >
                              Day view
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              type="button"
                              className={classNames(
                                active
                                  ? "bg-Neutral200 text-Neutral900"
                                  : "text-Neutral700",
                                "block px-4 py-2 text-xs w-full text-left"
                              )}
                              onClick={() => changeCalenderView("weekGrid")}
                            >
                              Week view
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              type="button"
                              className={classNames(
                                active
                                  ? "bg-Neutral200 text-Neutral900"
                                  : "text-Neutral700",
                                "block px-4 py-2 text-xs w-full text-left"
                              )}
                              onClick={() => changeCalenderView("monthGrid")}
                            >
                              Month view
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
                <div className="w-px h-6 ml-5 bg-Neutral500" />
                <button
                  type="button"
                  onClick={openModal}
                  className="button-hover large-primary-btn text-xs text-center inline-block text-Neutral000 relative bg-AccentRegular font-semibold py-2 px-[15px] ml-5 rounded focus-visible:outline focus-visible:outline-[6px] focus-visible:drop-shadow-none focus-visible:outline-AccentMediumLight transition duration-300 ease-in-out"
                >
                  <span className="relative z-10">Add event</span>
                </button>

                <button
                  type="button"
                  onClick={openMeetingModal}
                  className="button-hover large-primary-btn text-xs text-center inline-block text-Neutral100 relative bg-AccentRegular font-semibold py-2 px-[15px] ml-5 rounded drop-shadow-Texts focus-visible:outline focus-visible:outline-[6px] focus-visible:drop-shadow-none focus-visible:outline-AccentMediumLight transition duration-300 ease-in-out"
                >
                  <span className="relative z-10">Schedule meeting</span>
                </button>
              </div>
              <Menu as="div" className="relative ml-6 md:hidden">
                <Menu.Button className="flex items-center p-2 -mx-2 border border-transparent rounded-full text-Neutral700 hover:text-Neutral900">
                  <span className="sr-only">Open menu</span>
                  <DotsHorizontalIcon className="w-5 h-5" aria-hidden="true" />
                </Menu.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 mt-3 overflow-hidden origin-top-right bg-white divide-y rounded-md shadow-lg focus:outline-none w-36 divide-Neutral200 ring-1 ring-black ring-opacity-5">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            // href="#"
                            className={classNames(
                              active
                                ? "bg-Neutral200 text-Neutral900"
                                : "text-Neutral700",
                              "block px-4 py-2 text-xs"
                            )}
                          >
                            Create event
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            // href="#"
                            className={classNames(
                              active
                                ? "bg-Neutral200 text-Neutral900"
                                : "text-Neutral700",
                              "block px-4 py-2 text-xs"
                            )}
                          >
                            Go to today
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            // href="#"
                            className={classNames(
                              active
                                ? "bg-Neutral200 text-Neutral900"
                                : "text-Neutral700",
                              "block px-4 py-2 text-xs"
                            )}
                            onClick={() => changeCalenderView("dayGrid")}
                          >
                            Day view
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            // href="#"
                            className={classNames(
                              active
                                ? "bg-Neutral200 text-Neutral900"
                                : "text-Neutral700",
                              "block px-4 py-2 text-xs"
                            )}
                            onClick={() => changeCalenderView("weekGrid")}
                          >
                            Week view
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            // href="#"
                            className={classNames(
                              active
                                ? "bg-Neutral200 text-Neutral900"
                                : "text-Neutral700",
                              "block px-4 py-2 text-xs"
                            )}
                            onClick={() => changeCalenderView("monthGrid")}
                          >
                            Month view
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </header>

          <StyleWrapper>
            <FullCalendar
              ref={calendarRef}
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                googleCalendarPlugin,
              ]}
              // googleCalendarApiKey="AIzaSyCXZImC7eODJSRZriLUNQSKdjpT2A5O_pg"
              googleCalendarApiKey="AIzaSyBPwFUG5Jq94GGrx1xPktFN_G6wRZTm9M8"
              eventSources={[
                {
                  // googleCalendarId: userCalendars?.[0]?.id,
                  googleCalendarId: user?.google_calendar_ids?.[0],
                  className: "gcal-event",
                },
                {
                  events: [...events, ...tasks],
                },
              ]}
              initialView="dayGridMonth"
              eventsSet={(arg) => {
                for (const event of arg) {
                  let sameTitleEvents = arg.filter(
                    (item) =>
                      item.title === event.title &&
                      moment(item.start).format("YYYY-MM-DD") ===
                        moment(event.start).format("YYYY-MM-DD")
                  );

                  if (sameTitleEvents.length > 1) {
                    let sameTitleEventIds = sameTitleEvents.map(
                      (item) => item._def.publicId
                    );
                    let sameTitleEventIdsExceptFirst =
                      sameTitleEventIds.slice(1);
                    calendarRef.current
                      .getApi()
                      .getEventById(sameTitleEventIdsExceptFirst)
                      ?.remove();
                  }
                }
              }}
              headerToolbar={false}
              // events={[...events, ...tasks]}
              datesSet={fetchEvents}
              timeFormat="H(:mm)"
              displayEventTime={false}
              contentHeight={"auto"}
              dateClick={(e) => {
                let date = new Date(e.date);
                date = date.toDateString();
                date = moment(date).format("YYYY-MM-DD");
                setSelectedDate(date);
                openModal();
              }}
              eventClick={(e) => {
                const event = e.event.extendedProps;
                if (event?.event_type === "task") {
                  return navigate(
                    `/admin/clients/manage/${event?.client}/project/${event?.project}?cardId=${event?._id}`
                  );
                }
                setIsEvent(true);
                setEditEvent({
                  ...e.event.extendedProps,
                  title: e.event.title,
                });
              }}
              dayMaxEvents={3}
              eventMaxStack={3}
              eventContent={function (e) {
                if (
                  e?.event?.extendedProps?.event_type === "task" &&
                  e?.event?.extendedProps?.tag === "completed"
                ) {
                  return (
                    <>
                      <div
                        className="fc-daygrid-event-dot"
                        style={{
                          borderColor: "#42D6A6",
                        }}
                      ></div>
                      <span className="fc-event-title">{e.event.title}</span>
                    </>
                  );
                }
              }}
              // eventTextColor={'#000000'}
              // backgroundColor={'transparent'}
              // headerToolbar={{
              //   start: "title",
              //   center: "dayGridMonth,timeGridWeek,timeGridDay",
              //   end: "today prev,next",
              // }}
              dayCellContent={function (e) {
                return <CustomDay date={e.date} />;
              }}
            />
          </StyleWrapper>
        </div>
        <CreateEventsModal
          isOpen={modalIsOpen}
          setModal={setModal}
          selectedDate={selectedDate}
          execute={execute}
        />
        <EditeEventsModal
          isEvent={isEvent}
          setIsEvent={setIsEvent}
          setEditEvent={setEditEvent}
          editEvent={editEvent}
          updateEvent={updateEvent}
          deleteGoogleCalendarEvent={deleteEvent}
        />
        <GoogleCalendarModal
          isOpen={googleCalendarModal}
          setIsOpen={setGoogleCalendarModal}
          setGoogleCalendarDetailsModal={setGoogleCalendarDetailsModal}
        />
        <GoogleCalendarDetailsModal
          modalIsOpen={googleCalendarDetailsModal}
          setIsOpen={setGoogleCalendarDetailsModal}
        />
        <SchedulerModal
          meetingModalIsOpen={meetingModalIsOpen}
          setMeetingModalIsOpen={setMeetingModalIsOpen}
        />
      </div>
    </>
  );
}

export default Fullcalander;

const CustomDay = ({ date }) => {
  return (
    <div className="custom-day">
      <div className="invisible add-event-icon">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.6569 7.99963H2.34315"
            stroke="#9C9CA6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 2.34277V13.6565"
            stroke="#9C9CA6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      <span>{date.getDate()}</span>
    </div>
  );
};
