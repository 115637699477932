/**
 * Search Icon
 *
 * @param {import("react").SVGProps} props - SVG Icon Props
 */
function SearchIcon(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M9.04201 14.375C11.9694 14.375 14.3425 11.9965 14.3425 9.0625C14.3425 6.12849 11.9694 3.75 9.04201 3.75C6.11463 3.75 3.74152 6.12849 3.74152 9.0625C3.74152 11.9965 6.11463 14.375 9.04201 14.375Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.8369 16.875L13.0953 13.125"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default SearchIcon;
