import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { updateLeadAction } from "../../../store/actions/leadAction";
import { debounce } from "lodash";
import DraftEditor from "../../comman/DraftEditor/DraftEditor";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

const DescriptionSection = ({ item, user, clientDetail = {} }) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [content, setContent] = useState(EditorState.createEmpty());

  const computeCharacterCount = (editorState) => {
    const plainText = editorState.getCurrentContent().getPlainText();
    return plainText.length;
  };

  const charCount = computeCharacterCount(content);

  useEffect(() => {
    if (item?.description && item) {
      setContent(
        EditorState.createWithContent(
          convertFromRaw(
            item?.description?.entityMap
              ? item?.description
              : { ...item?.description, entityMap: {} }
          )
        )
      );
    }
  }, [item]);

  const debouncedChangeHandler = useRef(
    debounce((editData) => {
      if (computeCharacterCount(content) <= 250) {
        const formData = new FormData();
        formData.append("name", item.name);
        formData.append("email", item.email);
        formData.append("phone_number", item.phone_number);
        formData.append("budget", item.budget);
        formData.append("business_name", item.business_name);
        formData.append("website", item.website);
        formData.append("profile", item.profile);
        formData.append("id", item?._id);
        formData.append("rating", item?.rating || 0);
        formData.append("currency", JSON.stringify(item?.currency));
        formData.append("description", JSON.stringify(editData?.description));
        formData.append("custom_fields", JSON.stringify(item?.custom_fields));
        dispatch(updateLeadAction(formData));
        setIsEdit(false);
      }
    }, 400)
  ).current;

  const handleChange = () => {
    const currentLength = computeCharacterCount(content);
    if (currentLength <= 250) {
      debouncedChangeHandler({
        ...item,
        description: convertToRaw(content.getCurrentContent()),
      });
    }
  };

  return (
    <div className="relative w-full h-auto flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <h3 className="text-base font-semibold leading-[20px] text-Neutral800">
            Description
          </h3>
        </div>
      </div>
      <div className="">
        {!isEdit ? (
          <div
            className={`h-auto max-h-[10vh] lg:max-h-[15vh] overflow-y-auto resize-none border-[0.5px] px-[16px] border-Neutral400 rounded-[4px] text-sm w-full 
              m-0 break-all ${!item?.description && ""}`}
            onClick={() => {
              setIsEdit(true);
            }}
          >
            {/* {item?.description} */}

            {/* convert from contentBlock to text */}

            <Editor
              className="placeholder:text-Neutral600 text-sm w-full h-full  text-[red]"
              placeholder="Add additional information about the lead here..."
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              editorState={content}
              onEditorStateChange={handleChange}
              toolbarHidden
              editorStyle={{
                zIndex: 9999999,
                overflow: "hidden!important",
                color: "#5A5A66",
                fontSize: "14px",
                fontFamily: "Noto Sans",
                lineHeight: "21px",
              }}
              readOnly
              hashtag={{}}
            />
          </div>
        ) : (
          <DraftEditor
            comment={content}
            setComment={setContent}
            clientDetail={clientDetail}
            isDescription={true}
            handleChange={handleChange}
            placeholder="Add additional information about the lead here..."
            isLead={true}
          />
        )}
      </div>
      <div className="flex justify-end">
        <p
          className={`text-Neutral700 text-[12px] ${
            charCount > 250 ? "text-red-500" : ""
          }`}
        >
          {charCount}/250
        </p>
        {charCount > 250 && (
          <p className="text-red-500 text-[12px]">
            &nbsp; Description must be less than 250 characters.
          </p>
        )}
      </div>
    </div>
  );
};

export default DescriptionSection;
