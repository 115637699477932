import React, { Fragment, useState } from "react";
import clx from "classnames";
import DateTrackingBadge from "../../../common/badges/DateTrackingBadge";
import { prepareTrackingDate } from "../../../../utils/prepareTaskDate";
import MoneyTimeTrackingBadge from "../../../common/badges/MoneyTimeTrackingBadge";
import TagsTrackingList from "../../../common/lists/TagsTrackingList";
import TeamMemberAvatar from "../../../common/avatars/TeamMemberAvatar";
import moment from "moment";
import Pagination from "../../../Pagination";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { Menu, Transition } from "@headlessui/react";
import ThreeDots from "../../../../assets/Images/three-dots.svg";
import loader from "../../../../assets/Images/loader-icon.svg";
import TrackingEditTimeEntryModal from "../../../Modals/TrackingEditTimeEntryModal";
import { DeleteTimeEntry } from "../../../../store/actions/timeTrackingAction";

const minutes_to_hhmm = (numberOfMinutes) => {
  //create duration object from moment.duration
  var duration = moment.duration(numberOfMinutes, "minutes");

  //calculate hours
  var hh =
    duration.years() * (365 * 24) +
    duration.months() * (30 * 24) +
    duration.days() * 24 +
    duration.hours();

  hh = hh <= 9 ? `0${hh}` : hh;

  //get minutes
  var mm =
    duration.minutes() <= 9 ? `0${duration.minutes()}` : duration.minutes();

  //return total time in hh:mm format
  return hh + ":" + mm + ":" + "00";
};

function TimeTrackingTable({
  events = [],
  setEvents,
  className = "",
  timeEntryList,
  setPage,
}) {
  if (!events) {
    return null;
  }

  return (
    <div
      className={clx(
        "w-full rounded-lg border-[0.25px] border-Neutral300 bg-Neutral100 flex flex-col py-6 px-4 pr-[109px] mb-10",
        className
      )}
    >
      {Object.entries(events)
        .sort((a, b) =>
          moment(b[0], "YYYY-MM-DD").diff(moment(a[0], "YYYY-MM-DD"))
        )
        .map(([key, value]) => {
          // Calculate the total time for the date
          let time_in_min = value.reduce((total, current) => {
            const timeParts = current.time.split(":");
            return (
              total +
              moment
                .duration({
                  hours: timeParts[0],
                  minutes: timeParts[1],
                })
                .asMinutes()
            );
          }, 0);
          const displayable = minutes_to_hhmm(time_in_min);
          const totalAmount = value?.reduce(
            (acc, el) => (acc += el?.amount),
            0
          );
          return (
            <div className="mb-7" key={key}>
              <div className="flex items-center justify-between mb-5">
                <DateTrackingBadge
                  date={prepareTrackingDate(key)}
                  className="self-start"
                />
                <div className="w-[210px] flex items-center justify-between">
                  <MoneyTimeTrackingBadge amount={totalAmount} asMoney />
                  <MoneyTimeTrackingBadge amount={displayable} />
                </div>
              </div>
              <ul className="flex flex-col w-full gap-y-10 pl-[7px]">
                {value?.map((item, idx) => {
                  return (
                    <div key={idx}>
                      <ListItem
                        idx={idx}
                        item={item}
                        key={item?._id ?? idx}
                        setEvents={setEvents}
                      />
                    </div>
                  );
                })}
              </ul>
            </div>
          );
        })}
      {timeEntryList?.data?.length ? (
        <Pagination
          currantPage={timeEntryList?.currentPage}
          totalData={timeEntryList?.totalRecord}
          hasPrevPage={timeEntryList?.hasPrevPage}
          hasNextPage={timeEntryList?.hasNextPage}
          fromRecord={timeEntryList?.fromRecord}
          outOfCounter={timeEntryList?.data?.length}
          setPage={setPage}
        />
      ) : null}
    </div>
  );
}

// members.find(({ id }) => id === item?.member_id)

function ListItem({ item = null, idx = null, setEvents }) {
  const clientDetail = useSelector((state) => state.client.clientDetail);
  const dispatch = useDispatch();
  const params = useParams();
  const loginUser = useSelector((state) => state.auth.user);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editTimeEntry, setEditTimeEntry] = useState("");

  if (!item) {
    return null;
  }
  const handleRemoveTimeEnrty = async (id) => {
    setIsLoading(true);
    dispatch(DeleteTimeEntry(id, setEvents, params.projectId, setIsLoading));
  };
  return (
    <li className="flex justify-between items-center">
      <div className="flex justify-start items-center">
        <span className="flex items-center justify-center mr-[27px] bg-Neutral200 border-[0.25px] border-Neutral300 rounded-full min-w-[24px] h-[24px] source-code-pro font-semibold text-Neutral700 leading-[15px] text-xs">
          {idx + 1}
        </span>

        <div className="w-[200px]">
          <p className="w-full mb-2 font-medium text-base leading-[22px] text-Neutral900">
            {item?.title}
          </p>
          <TagsTrackingList tags={item?.category} />
        </div>
      </div>

      <div className="flex justify-start items-center w-[150px]">
        <TeamMemberAvatar user={item?.user} className="mr-2" size={36} />
        <span className="text-xs font-medium leading-[16px]">
          {item?.user?.user_name}
        </span>
      </div>

      <div className="w-[210px] flex items-center justify-between">
        <span className="leading-[22px] text-Neutral800 font-medium min-w-[80px] text-center flex items-start justify-center">
          {item?.amount ? (
            <span className="text-[9px] leading-[18px] inline-block pr-[2px]">
              {item?.currency?.symbol || clientDetail?.data?.currency?.symbol}
            </span>
          ) : null}
          {item?.amount?.toFixed(2)}
        </span>
        <span className="leading-[22px] text-Neutral800 font-medium text-center">
          {item?.time}
        </span>
        <Menu as="div" className="relative inline-block">
          <div className="flex items-center">
            {(clientDetail?.data?.is_admin === true &&
              clientDetail?.data?.is_member === true) ||
            (clientDetail?.data?.is_admin === false &&
              clientDetail?.data?.is_member === true &&
              loginUser?._id === item?.user?._id) ||
            clientDetail?.data?.is_manager === true ? (
              <Menu.Button className="inline-flex justify-center w-full focus-visible:outline-none popup-btn relative">
                <span className="focus-visible:outline-none">
                  {isLoading ? (
                    <div className="flex justify-center mt-1 mb-1">
                      <img
                        src={loader}
                        alt="loader"
                        className="animate-spin relative drop-shadow-Texts z-10 h-[20px]"
                      />
                    </div>
                  ) : (
                    <img className="cursor-pointer" src={ThreeDots} alt="" />
                  )}
                </span>
              </Menu.Button>
            ) : null}
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute bottom-0 right-[-60px] w-[109px] rounded-[4px] Timetrackingdropdown bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none innermenu-main">
              <div className="">
                <div
                  className="focus-visible:outline-none popup-btn relative w-full cursor-pointer"
                  onClick={() => {
                    setEditTimeEntry(item);
                    setIsOpen(true);
                  }}
                >
                  <span className="focus-visible:outline-none">
                    <div className="py-[11px] px-[15px] flex justify-between edit-icon group duration-300 download hover:bg-Neutral200 overflow-hidden rounded-b-[4px] w-full">
                      <h3 className="text-Neutral800 text-xs flex group-hover:text-AccentRegular">
                        <svg
                          className="mr-2"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.77149 4.12354H4.06033C3.77911 4.12354 3.50941 4.23525 3.31056 4.4341C3.11171 4.63295 3 4.90265 3 5.18387V12.6062C3 12.8874 3.11171 13.1571 3.31056 13.356C3.50941 13.5548 3.77911 13.6665 4.06033 13.6665H11.4827C11.7639 13.6665 12.0336 13.5548 12.2324 13.356C12.4313 13.1571 12.543 12.8874 12.543 12.6062V8.89503"
                            stroke="#292933"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.7469 3.32916C11.9578 3.11825 12.2439 2.99976 12.5422 2.99976C12.8404 2.99976 13.1265 3.11825 13.3374 3.32916C13.5483 3.54007 13.6668 3.82613 13.6668 4.12441C13.6668 4.42268 13.5483 4.70874 13.3374 4.91966L8.30084 9.95623L6.18018 10.4864L6.71034 8.36574L11.7469 3.32916Z"
                            stroke="#292933"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Edit
                      </h3>
                    </div>
                  </span>
                </div>
              </div>
              <Menu.Item>
                <div className="">
                  <div
                    className="focus-visible:outline-none popup-btn relative w-full cursor-pointer"
                    onClick={async () => {
                      await handleRemoveTimeEnrty(item?._id);
                    }}
                  >
                    <span className="focus-visible:outline-none">
                      <div className="py-[11px] px-[15px] flex justify-between delet-icon group duration-300 download hover:bg-Neutral200 overflow-hidden rounded-b-[4px] w-full">
                        <h3 className="text-Neutral800 text-xs flex group-hover:text-Red400">
                          <svg
                            className="mr-2"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.23096 5.04419H4.33181H13.1386"
                              stroke="#5A5A66"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M5.98234 5.04469V4.02247C5.98234 3.75136 6.09832 3.49135 6.30477 3.29965C6.51122 3.10794 6.79123 3.00024 7.08319 3.00024H9.2849C9.57687 3.00024 9.85687 3.10794 10.0633 3.29965C10.2698 3.49135 10.3858 3.75136 10.3858 4.02247V5.04469M12.037 5.04469V12.2002C12.037 12.4714 11.9211 12.7314 11.7146 12.9231C11.5082 13.1148 11.2281 13.2225 10.9362 13.2225H5.43191C5.13994 13.2225 4.85994 13.1148 4.65349 12.9231C4.44704 12.7314 4.33105 12.4714 4.33105 12.2002V5.04469H12.037Z"
                              stroke="#5A5A66"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          Delete
                        </h3>
                      </div>
                    </span>
                  </div>
                </div>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
      {isOpen && (
        <TrackingEditTimeEntryModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          editTimeEntry={editTimeEntry}
          setTimeEntryList={setEvents}
        />
      )}
    </li>
  );
}

export default TimeTrackingTable;
