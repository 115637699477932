const customModalStyles = {
  overlay: {
    backgroundColor: 'rgba(41, 41, 51, 0.7)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: '50px',
    background: 'none',
    borderRadius: '10px',
  },
};

export { customModalStyles };
