const EditIcon = ({ width = 16, color = "#5A5A66", className = "" }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.77149 4.12352H4.06033C3.77911 4.12352 3.50941 4.23523 3.31056 4.43408C3.11171 4.63293 3 4.90263 3 5.18385V12.6062C3 12.8874 3.11171 13.1571 3.31056 13.3559C3.50941 13.5548 3.77911 13.6665 4.06033 13.6665H11.4827C11.7639 13.6665 12.0336 13.5548 12.2324 13.3559C12.4313 13.1571 12.543 12.8874 12.543 12.6062V8.89501"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7467 3.32908C11.9576 3.11817 12.2436 2.99968 12.5419 2.99968C12.8402 2.99968 13.1263 3.11817 13.3372 3.32908C13.5481 3.54 13.6666 3.82606 13.6666 4.12433C13.6666 4.42261 13.5481 4.70867 13.3372 4.91958L8.3006 9.95616L6.17993 10.4863L6.7101 8.36566L11.7467 3.32908Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditIcon;
