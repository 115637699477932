import React, { useState } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

import CloseBtn from "../../assets/Images/close-btn.svg";
import { removeteammemberAction } from "../../store/actions/memberAction";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

function RemoveMemberModal({ setModal, isOpen, member }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const handleRemoveMember = () => {
    setLoading(true);
    dispatch(
      removeteammemberAction({ member_id: member?._id }, setModal, setLoading)
    );
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setModal(false)}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="Remove member Modal"
      >
        <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="bg-white px-6 xs:px-12 pt-8 pb-10 rounded-[8px]">
            <div>
              <div className="text-left">
                <h3
                  className="flex text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-8"
                  id="modal-title"
                >
                  Removal of &nbsp;
                  <span className="font-bold text-Neutral900">
                    {" "}
                    {member?.user_name}
                  </span>
                </h3>
                <button
                  type="button"
                  className={`text-Neutral000 w-[100%] mb-[24px] h-12 flex items-center justify-center rounded-[4px] leading-[1.4] focus:outline-AccentMediumLight transition duration-300 ease-in-out bg-Red400 hover:bg-Red600`}
                  onClick={handleRemoveMember}
                >
                  {loading ? (
                    <span className="relative z-10">
                      <svg
                        className="animate-spin m-auto h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </span>
                  ) : (
                    <span className="text-sm font-bold">
                      Yes, Remove from Account Plan
                    </span>
                  )}
                </button>
                <div className="flex justify-end w-[100%] 6xl:right-[36px]">
                  <span
                    className="inlinr-block text-xs font-bold 5xl:w-[120px] w-full 5xl:h-10 h-10 flex items-center justify-center rounded-[4px] leading-[1.4] focus:outline-AccentMediumLight bg-Neutral300 text-Neutral800 hover:text-Neutral900 transition duration-300 ease-in-out hover:bg-Neutral400"
                    onClick={() => setModal(false)}
                  >
                    No, Keep Team Member
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => setModal(false)}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default RemoveMemberModal;
