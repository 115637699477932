import React from "react";
import Contracts1 from "../../assets/Images/Contracts-1.svg";
import ContractsArrow from "../../assets/Images/Contracts-Arrow.svg";
import ContractsLine from "../../assets/Images/Contracts-line.svg";

function ReportsNoDataContent() {
  return (
    <div className="absolute top-[50%] -translate-y-[50%] left-[50%] -translate-x-[50%] w-full max-w-[144px]">
    <div className="relative">
      <img
        className="absolute -top-[8px] -left-[12px]"
        src={Contracts1}
        alt=""
      />
      <img
        className="absolute -top-[86px] left-[45%]"
        src={ContractsArrow}
        alt=""
      />
      <img className="absolute -bottom-[38px]" src={ContractsLine} alt="" />
      <h5 className="text-sm font-normal font-kalam text-center">
      Put all the analytical stuff here :)
      </h5>
    </div>
  </div>
  );
}

export default ReportsNoDataContent;
