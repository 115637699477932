import React, { Fragment, useRef, useState } from "react";
import Modal from "react-modal";
import CloseBtn from "../../assets/Images/close-btn.svg";
import { Menu, Transition } from "@headlessui/react";
import SelectDownArrow from "../Icons/SelectDownArrow";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { CreateNewProjectActions } from "../../store/actions/timeTrackingAction";
import getRandomColor from "../../utils/getRandomColor";
import { toast } from "react-toastify";

const newProjectValidation = Yup.object().shape({
  client_id: Yup.string().required("Choose client name"),
  title: Yup.string().required("Choose project title"),
});

const TrackingAddProjectModal = (props) => {
  const {
    projectModel,
    closeProjectModal,
    customStyles,
    submitHandle,
    client,
  } = props;
  const dispatch = useDispatch();
  const titleDropDownref = useRef(null);
  const [newTitle, setNewTitle] = useState("");
  const [projectTitleList, setProjectTitleList] = useState([
    { tag: "Database Development", color: "Green400" },
    { tag: "Retainer Contract", color: "Orange400" },
    { tag: "E-Commerce Platform", color: "Red400" },
    { tag: "Blog Site", color: "Blue400" },
    { tag: "SEO Strategy", color: "Pink400" },
  ]);
  return (
    <>
      <Modal
        isOpen={projectModel}
        onRequestClose={closeProjectModal}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="Example Modal"
        className={"project-model"}
      >
        <div className="relative bg-Neutral000 rounded-lg text-left transform transition-all sm:my-8 sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="bg-Neutral000 px-6 xs:px-12 pt-8 pb-10 rounded-[8px]">
            <Formik
              initialValues={{ title: "", client_id: "", color: "" }}
              validationSchema={newProjectValidation}
              onSubmit={(values, { setSubmitting, setErrors }) => {
                setSubmitting(true);
                dispatch(
                  CreateNewProjectActions(
                    values,
                    setSubmitting,
                    setErrors,
                    closeProjectModal
                  )
                );
              }}
            >
              {({
                touched,
                errors,
                values,
                isSubmitting,
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="text-Neutral700 text-xs font-semibold pb-2 border-b border-Neutral200">
                    NEW PROJECT
                  </div>
                  <div className="mt-7">
                    <label
                      for="countries"
                      className="text-Neutral900 font-bold text-xs mb-2 inline-block"
                    >
                      Client
                    </label>
                    <div className="relative">
                      <span className="absolute pointer-events-none z-[999] top-[50%] translate-y-[-50%] right-[16px]">
                        <SelectDownArrow />
                      </span>
                      <select
                        id="countries"
                        name="client_id"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.client_id}
                        className="text-Neutral800 bg-Neutral200 font-medium placeholder:text-Neutral500 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full h-[48px] focus:outline-none border-[1.5px] rounded border-transparent hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow cursor-pointer appearance-none duration-300 transition"
                      >
                        <option value="" disabled selected>
                          Select Client...
                        </option>
                        {client?.data?.map((item, i) => {
                          return (
                            <option value={item?._id} key={`12${i}`}>
                              {item?.client_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {errors?.client_id && touched?.client_id && (
                      <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                        {errors?.client_id}
                      </p>
                    )}
                  </div>
                  <Menu
                    as="div"
                    className="relative inline-block text-left w-full"
                  >
                    <div className="">
                      <Menu.Button
                        ref={titleDropDownref}
                        className={`focus-visible:outline-none popup-btn relative w-full ${
                          values?.title !== null && values?.title !== ""
                            ? "project-title"
                            : ""
                        }`}
                      >
                        <div className="mt-5">
                          <label
                            for="countries"
                            className="text-Neutral900 font-bold text-xs mb-2 inline-block w-full text-left"
                          >
                            Project Category
                          </label>
                          <div className="relative">
                            <span className="absolute pointer-events-none z-[999] top-[50%] translate-y-[-50%] right-[16px]">
                              <SelectDownArrow />
                            </span>
                            <input
                              id="countries"
                              className="relative text-Neutral500 placeholder:font-medium placeholder:text-Neutral500 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm w-full h-[48px] focus:outline-none border-[1.5px] rounded hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow cursor-pointer appearance-none duration-300 transition border-Neutral300 leading-[48px]"
                              placeholder="+Add New Category"
                              value={values?.title}
                            />
                            <span
                              className={`dot-box rounded-full absolute pointer-events-none top-[50%] translate-y-[-50%] left-[16px] ${
                                values?.color ? `bg-${values?.color}` : ""
                              } h-2 w-2 hidden z-[1]`}
                            ></span>
                          </div>
                        </div>
                      </Menu.Button>
                      {errors?.title && touched?.title && (
                        <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                          {errors?.title}
                        </p>
                      )}
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right z-20 absolute top-[40px] -right-[3px] mt-2 w-[323px] rounded-lg dropdowns-boxshadow bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none px-2 pt-3 pb-2">
                        <div className="mx-2">
                          <input
                            className="placeholder:font-medium text-xs placeholder:text-Neutral500 text-Neutral700 pr-4 focus:outline-none w-full mb-2 placeholder:text-xs"
                            placeholder="+Add New Category"
                            value={newTitle}
                            onChange={(e) => {
                              setNewTitle(e.target.value);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                if (
                                  projectTitleList.filter(
                                    (item) => item.tag === newTitle
                                  ).length === 0
                                ) {
                                  setFieldValue("title", newTitle);
                                  setFieldValue("color", "Neutral400");
                                  setNewTitle("");
                                  setTimeout(() => {
                                    const newColour = getRandomColor();
                                    setProjectTitleList([
                                      ...projectTitleList,
                                      {
                                        tag: newTitle,
                                        color: newColour,
                                      },
                                    ]);
                                    titleDropDownref?.current?.click();
                                    e.stopPropagation();
                                  }, 0);
                                } else {
                                  toast.error("Title already exists");
                                }
                              }
                            }}
                          />
                        </div>
                        <div className="project-title-divide">
                          <div className="overflow-hidden border-0 mb-2 overflow-y-auto currancy-scrollbar scrollbar-w-4 relative ">
                            <h4 className="text-Neutral600 font-semibold text-[10px] pt-3 ml-2 mr-5 border-t-[1px] border-Neutral200">
                              Categories
                            </h4>
                            {projectTitleList
                              .filter((title) =>
                                title.tag
                                  .toLowerCase()
                                  .startsWith(newTitle.toLowerCase())
                              )
                              .map((item, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="mt-2 flex items-center hover:bg-Neutral200 p-2 rounded cursor-pointer group duration-300"
                                    onClick={(e) => {
                                      setFieldValue("title", item.tag);
                                      setFieldValue("color", item.color);
                                      setTimeout(() => {
                                        titleDropDownref?.current?.click();
                                        e.stopPropagation();
                                      }, 0);
                                    }}
                                  >
                                    <div className="w-4 flex items-center justify-left">
                                      <span
                                        className={`w-2 h-2 rounded ${
                                          item?.color ? `bg-${item?.color}` : ""
                                        }`}
                                      ></span>
                                    </div>
                                    <p className="text-Neutral800 group-hover:text-Neutral900 font-normal text-xs w-full rounded">
                                      {item.tag}
                                    </p>
                                  </div>
                                );
                              })}
                          </div>
                          {newTitle && newTitle !== "" && (
                            <div
                              className="bottom-0 flex items-center bg-AccentLight p-2 rounded duration-300 w-[303px] cursor-pointer"
                              type="button"
                              onClick={(e) => {
                                if (
                                  projectTitleList.filter(
                                    (item) => item.tag === newTitle
                                  ).length === 0
                                ) {
                                  setFieldValue("title", newTitle);
                                  setFieldValue("color", "Neutral400");
                                  setNewTitle("");
                                  setTimeout(() => {
                                    const newColour = getRandomColor();
                                    setProjectTitleList([
                                      ...projectTitleList,
                                      {
                                        tag: newTitle,
                                        color: newColour,
                                      },
                                    ]);
                                    titleDropDownref?.current?.click();
                                    e.stopPropagation();
                                  }, 0);
                                } else {
                                  toast.error("Title already exists");
                                }
                              }}
                            >
                              <div className="flex items-center w">
                                <p className="text-AccentDark font-normal text-xs rounded">
                                  Create
                                </p>
                                <p className="text-white ml-[6px] py-[6px] px-2 rounded font-normal text-xs bg-AccentRegular">
                                  {newTitle}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>

                  <div className="mt-8">
                    <button
                      onClick={submitHandle}
                      disabled={isSubmitting}
                      className="btn button-hover form-primary-btn relative text-sm text-center block text-Neutral100 bg-AccentRegular font-bold w-full py-3 rounded drop-shadow-Texts overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out"
                    >
                      {isSubmitting ? (
                        <span className="relative z-10 drop-shadow-Texts">
                          <svg
                            className="animate-spin m-auto h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        </span>
                      ) : (
                        <span className="relative drop-shadow-Texts z-10">
                          Save
                        </span>
                      )}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => closeProjectModal()}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TrackingAddProjectModal;
