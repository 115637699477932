import React from "react";
import AddIcon from "../Icons/AddIcon";

function AddIconButton({ onClick = () => {}, disabled = false }) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className="text-AccentRegular flex items-center justify-center text-xs rounded-[30px] px-3 py-2 h-14 w-[224px] tracking-[0.04em] border border-AccentMediumLight focus-visible:outline-none focus:outline focus:outline-4 focus:outline-AccentMediumLight font-bold hover:bg-AccentMediumLight transition duration-300 uppercase"
    >
      <AddIcon width={24} />
    </button>
  );
}

export default AddIconButton;
