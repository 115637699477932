import React, { useState } from "react";
import { EditorState, convertToRaw } from "draft-js";
import { useDispatch } from "react-redux";
import loader from "../../../../../assets/Images/loader-icon.svg";
import { CreateLeadCommentActions } from "../../../../../store/actions/leadAction";
import SendIcon from "./SendIcon";
import DraftEditor from "../../../../comman/DraftEditor/DraftEditor";

const AddComment = ({ lead_id }) => {
  const dispatch = useDispatch();
  const [comment, setComment] = useState(EditorState.createEmpty());
  const [loading, setLoading] = useState(false);
  const [attachments, setAttachments] = useState([]);

  const handleSubmit = async () => {
    if (comment) {
      setLoading(true);
      const formData = new FormData();
      formData.append(
        "message",
        JSON.stringify(convertToRaw(comment.getCurrentContent()))
      );
      formData.append("lead_id", lead_id);
      attachments.forEach((file) => {
        formData.append("files", file);
      });
      await dispatch(
        CreateLeadCommentActions(
          formData,
          setLoading,
          setComment,
          EditorState,
          setAttachments
        )
      );
    }
  };

  return (
    <div
      className={`bottom-[8px] w-full bg-white border-Neutral300 p-3 border-t overflow-auto z-10 task-add-comment`}
    >
      <DraftEditor
        comment={comment}
        setComment={setComment}
        attachments={attachments}
        setAttachments={setAttachments}
        clientDetail={{}}
        isLead={true}
      />

      <div className="flex justify-end mt-[-20px]">
        <div className="flex items-center pl-3.5 border-l border-Neutral300">
          {loading ? (
            <div className="flex justify-center">
              <img
                src={loader}
                alt="loader"
                className="animate-spin relative drop-shadow-Texts z-10"
              />
            </div>
          ) : (
            <SendIcon onClick={handleSubmit} />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddComment;
