/** @param {import("react").SVGProps} props - SVG Icon Props */
function PeopleIcon(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0307 15.2317V14.0196C13.0307 13.3767 12.7753 12.7601 12.3207 12.3055C11.866 11.8509 11.2494 11.5955 10.6065 11.5955H5.75816C5.11523 11.5955 4.49863 11.8509 4.04401 12.3055C3.58939 12.7601 3.33398 13.3767 3.33398 14.0196V15.2317"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.18199 9.1711C9.52082 9.1711 10.6062 8.08576 10.6062 6.74693C10.6062 5.40809 9.52082 4.32275 8.18199 4.32275C6.84315 4.32275 5.75781 5.40809 5.75781 6.74693C5.75781 8.08576 6.84315 9.1711 8.18199 9.1711Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6677 15.2311V14.019C16.6673 13.4819 16.4886 12.9601 16.1595 12.5356C15.8304 12.1111 15.3697 11.8079 14.8496 11.6736"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4238 4.40161C12.9453 4.53512 13.4075 4.83839 13.7375 5.26359C14.0676 5.6888 14.2467 6.21176 14.2467 6.75003C14.2467 7.2883 14.0676 7.81126 13.7375 8.23646C13.4075 8.66167 12.9453 8.96493 12.4238 9.09845"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PeopleIcon;
