import React from "react";
import clx from "classnames";

function TagTrackingBadge({ tag = null, className = "" }) {
  return (
    <li
      className={clx(
        "uppercase rounded-[6px] border border-Neutral300 py-1 px-2 text-[10px] leading-[120%] tracking-[0.04em] font-medium text-Neutral700 flex justify-center items-center",
        className
      )}
    >
      {tag}
    </li>
  );
}

export default TagTrackingBadge;
