import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CreaditCard from "../../assets/Images/creadit-card.svg";
import masterCard from "../../assets/Images/master-card.svg";
import visa from "../../assets/Images/visa.svg";
import Paypal from "../../assets/Images/paypal.svg";
import PaymentDetailLoader from "../ContentLoaders/PaymentDetailLoader";
import moment from "moment";
import CopyLinkModal from "../Modals/CopyLinkModal";
import AppsumoWarningModal from "../Modals/AppsumoWarningModal";

const PaymentDetail = ({
  data,
  isLoading,
  planData,
  setcancelPlan,
  lemonsqueezy,
}) => {
  const authSelector = useSelector((state) => state.auth.user);
  const [isUpgrading, setIsUpgrading] = useState(false);
  const [isOpenAppsumoWarningModal, setIsOpenAppsumoWarningModal] =
    useState(false);
  const [isCancelPlan, setIsCancelPlan] = useState(false);

  return (
    <>
      {isLoading && !data ? (
        <PaymentDetailLoader />
      ) : (
        <div className="w-full 5xl:max-w-[500px] mt-10 md:mt-0 md:max-w-[408px] rounded border border-Neutral300 pt-8 px-10 pb-10">
          <div className="w-full flex justify-between items-center mb-12">
            <h5 className="text-Neutral700 text-xs font-bold tracking-[0.1em] uppercase">
              account
            </h5>
            {planData?.payment_gateway === "appsumo" && (
              <button
                onClick={() => setIsOpenAppsumoWarningModal(true)}
                type="button"
                className="text-AccentRegular text-[10px] font-semibold tracking-[0.04em] uppercase hover:text-AccentMediumDark"
              >
                Change Plan
              </button>
            )}
            {planData?.payment_gateway === "paddle" &&
              (planData?.status === "active" ? (
                <Link
                  to="/upgrade-plan"
                  className="text-AccentRegular text-[10px] font-semibold tracking-[0.04em] uppercase hover:text-AccentMediumDark"
                >
                  Change Plan
                </Link>
              ) : (
                <button
                  onClick={() => setIsUpgrading(true)}
                  type="button"
                  className="text-AccentRegular text-[10px] font-semibold tracking-[0.04em] uppercase hover:text-AccentMediumDark"
                >
                  Upgrade Plan
                </button>
              ))}

            {planData?.payment_gateway === "lemonsqueezy" &&
              planData?.selected_payment_type !== "life_time" && (
                <Link
                  to={
                    lemonsqueezy?.data?.attributes?.urls
                      ?.customer_portal_update_subscription
                  }
                  className="text-AccentRegular text-[10px] font-semibold tracking-[0.04em] uppercase hover:text-AccentMediumDark"
                >
                  Change Plan
                </Link>
              )}
          </div>
          <div className="w-full flex justify-between items-center mb-8">
            <h5 className="text-Neutral600 font-medium text-[10px] uppercase tracking-[0.04em]">
              TYPE
            </h5>
            <span className="text-xs text-Neutral900 font-medium">
              {planData?.selected_account_plan === "individual" && "Solo"}
              {planData?.selected_account_plan === "small_team" && "Team"}
              {planData?.selected_account_plan === "large_team" && "Enterprise"}
            </span>
          </div>
          <div className="w-full flex justify-between pb-10">
            <h5 className="text-Neutral600 font-medium text-[10px] uppercase">
              Account plan
            </h5>
            <span className="text-xs text-Neutral900 font-medium ">
              {authSelector?.payment?.price?.includes("$")
                ? authSelector?.payment?.price
                : `$${Math.trunc(authSelector?.payment?.price)}`}{" "}
              -{" "}
              {planData?.selected_payment_type === "life_time"
                ? "Lifetime"
                : planData?.selected_payment_type === "monthly"
                ? "Monthly"
                : "Yearly"}
            </span>
          </div>
          {planData?.payment_gateway === "paddle" &&
            (data?.payment_information?.payment_method === "card" ? (
              <div className="pb-6">
                <div className="w-full flex justify-between items-center ">
                  <h5 className="text-Neutral600 font-medium text-[10px] uppercase tracking-[0.04em]">
                    payment method
                  </h5>
                  <div className="inline-block">
                    <div className="flex text-xs mb-1 text-Neutral900 items-center">
                      {data?.payment_information?.card_type === "visa" && (
                        <img src={visa} alt="" className="mr-2" />
                      )}

                      {data?.payment_information?.card_type === "master" && (
                        <img src={masterCard} alt="" className="mr-2" />
                      )}

                      {data?.payment_information?.card_type !== "master" &&
                        data?.payment_information?.card_type !== "visa" && (
                          <img src={CreaditCard} alt="" className="mr-2" />
                        )}

                      <span className="h-[20px] leading-[23px]">
                        **** {data?.payment_information?.last_four_digits}
                      </span>
                    </div>
                  </div>
                </div>
                {moment(new Date(), "MM/YYYY").diff(
                  moment(data?.payment_information?.expiry_date, "MM/YYYY"),
                  "months"
                ) > 0 ? (
                  <p className="text-right w-full text-[#ff2d55] text-[10px] font-semibold">
                    <a target="_blanck" href={data?.update_url}>
                      Expired. Click to update.
                    </a>
                  </p>
                ) : (
                  <p className="text-right w-full text-AccentRegular text-[10px] font-semibold hover:text-AccentMediumDark">
                    <a target="_blanck" href={data?.update_url}>
                      Edit
                    </a>
                  </p>
                )}
              </div>
            ) : (
              <div className="pb-6">
                <div className="w-full flex justify-between items-center ">
                  <h5 className="text-Neutral600 font-medium text-[10px] uppercase tracking-[0.04em]">
                    payment method
                  </h5>
                  <div className="inline-block">
                    <Link
                      to="#"
                      className="flex text-xs mb-1 text-Neutral900 items-center"
                    >
                      <img src={Paypal} alt="" className="" />
                    </Link>
                  </div>
                </div>
                {moment().format("MM/YYYY") <
                data?.payment_information?.expiry_date ? (
                  <p className="text-right w-full text-[#ff2d55] text-[10px] font-semibold">
                    <a target="_blanck" href={data?.update_url}>
                      Expired. Click to update.
                    </a>
                  </p>
                ) : (
                  <p className="text-right w-full text-AccentRegular text-[10px] font-semibold">
                    <a target="_blanck" href={data?.update_url}>
                      Edit
                    </a>
                  </p>
                )}
              </div>
            ))}

          {planData?.payment_gateway === "appsumo" && (
            <div className="pb-6">
              <div className="w-full flex justify-between items-center ">
                <h5 className="text-Neutral600 font-medium text-[10px] uppercase tracking-[0.04em]">
                  payment method
                </h5>
                <div className="inline-block">
                  <Link
                    to="#"
                    className="flex text-xs mb-1 text-Neutral900 items-center"
                  >
                    <span className="h-[20px] leading-[23px]">🌮 AppSumo</span>
                  </Link>
                </div>
              </div>
            </div>
          )}

          {planData?.payment_gateway === "lemonsqueezy" &&
            (lemonsqueezy?.data?.attributes?.card_brand ? (
              <div className="pb-6">
                <div className="w-full flex justify-between items-center ">
                  <h5 className="text-Neutral600 font-medium text-[10px] uppercase tracking-[0.04em]">
                    payment method
                  </h5>
                  <div className="inline-block">
                    <div className="flex text-xs mb-1 text-Neutral900 items-center">
                      {lemonsqueezy?.data?.attributes?.card_brand ===
                        "visa" && <img src={visa} alt="" className="mr-2" />}

                      {lemonsqueezy?.data?.attributes?.card_brand ===
                        "master" && (
                        <img src={masterCard} alt="" className="mr-2" />
                      )}

                      {lemonsqueezy?.data?.attributes?.card_brand !==
                        "master" &&
                        lemonsqueezy?.data?.attributes?.card_brand !==
                          "visa" && (
                          <img src={CreaditCard} alt="" className="mr-2" />
                        )}

                      <span className="h-[20px] leading-[23px]">
                        **** {lemonsqueezy?.data?.attributes?.card_last_four}
                      </span>
                    </div>
                  </div>
                </div>
                <p className="text-right w-full text-AccentRegular text-[10px] font-semibold hover:text-AccentMediumDark">
                  <a
                    target="_blanck"
                    href={
                      lemonsqueezy?.data?.attributes?.urls
                        ?.update_payment_method
                    }
                  >
                    Edit
                  </a>
                </p>
              </div>
            ) : planData?.selected_payment_type === "life_time" ? (
              <div className="pb-6">
                <div className="w-full flex justify-between items-center ">
                  <h5 className="text-Neutral600 font-medium text-[10px] uppercase tracking-[0.04em]">
                    payment method
                  </h5>
                  <div className="inline-block">
                    <Link
                      to="#"
                      className="flex text-xs mb-1 text-Neutral900 items-center"
                    >
                      <span className="h-[20px] leading-[23px]">
                        Lemon Squeezy
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            ) : (
              <div className="pb-6">
                <div className="w-full flex justify-between items-center ">
                  <h5 className="text-Neutral600 font-medium text-[10px] uppercase tracking-[0.04em]">
                    payment method
                  </h5>
                  <div className="inline-block">
                    <Link
                      to="#"
                      className="flex text-xs mb-1 text-Neutral900 items-center"
                    >
                      <img src={Paypal} alt="" className="" />
                    </Link>
                  </div>
                </div>
              </div>
            ))}

          {planData?.status === "trialing" && (
            <div className="w-full flex justify-between items-center mb-4">
              <h5 className="text-Neutral600 font-medium text-[10px] uppercase tracking-[0.04em]">
                Your free trial ends on{" "}
                {moment(planData?.next_bill_date).format("LL")}
              </h5>
            </div>
          )}

          {planData?.payment_gateway === "lemonsqueezy" &&
          planData?.selected_payment_type === "life_time" ? (
            ""
          ) : (
            <>
              <hr className="my-2 border-none h-[1px] bg-Neutral300" />
              <div className="w-full flex justify-between items-center pt-8">
                <h5 className="text-Neutral600 font-bold text-[10px] uppercase tracking-[0.04em]">
                  Danger Zone
                </h5>
                <button
                  className="inlinr-block text-[10px] 5xl:text-sm bg-Neutral200 font-semibold h-7 px-4 flex items-center justify-center rounded leading-[1.4] outline-Red100 text-Red400 focus-visible:outline-none focus:outline focus:outline-4 focus:outline-Red100 transition duration-300 ease-in-out hover:bg-Neutral300 hover:text-Red600 uppercase"
                  onClick={() => {
                    if (planData?.payment_gateway === "appsumo") {
                      setIsOpenAppsumoWarningModal(true);
                      setIsCancelPlan(true);
                    }
                    if (
                      planData?.payment_gateway === "paddle" ||
                      planData?.payment_gateway === "lemonsqueezy"
                    ) {
                      setcancelPlan(true);
                    }
                  }}
                >
                  Cancel Account
                </button>
              </div>
            </>
          )}
        </div>
      )}

      {isUpgrading && (
        <CopyLinkModal
          linkIsOpen={isUpgrading}
          setLinkIsOpen={setIsUpgrading}
          text="End your free trial and get full access to the client manager without any limitations."
        />
      )}

      {isOpenAppsumoWarningModal && (
        <AppsumoWarningModal
          isOpenAppsumoWarningModal={isOpenAppsumoWarningModal}
          setIsOpenAppsumoWarningModal={setIsOpenAppsumoWarningModal}
          isCancelPlan={isCancelPlan}
          setIsCancelPlan={setIsCancelPlan}
        />
      )}
    </>
  );
};

export default PaymentDetail;
