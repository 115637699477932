import axios from "axios";
import { toast } from "react-toastify";

export const GetPlanConfig = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`api/v1/configs/payment-configs`);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "GET_ASSETS_ERROR",
        payload: error,
      });
    }
  };
};

export const GetLemonSqueezyConfig = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `api/v1/configs/lemon-squeezy-payment-configs`
      );
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "GET_PAYMENT_CONFIG_ERROR",
        payload: error,
      });
    }
  };
};
