const initialState = {
  modal: {
    name: null,
    isOpen: false,
  },
};

const modalsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "OPEN_MODAL":
      return {
        ...state,
        modal: {
          name: payload.name,
          isOpen: true,
        },
      };
    case "CLOSE_MODAL":
      return {
        ...state,
        modal: {
          name: payload.name,
          isOpen: false,
        },
      };
    default:
      return state;
  }
};

export default modalsReducer;
