import React from "react";
import noClient from "../../assets/Images/no-client.svg";

const NoClientData = () => {

  return (
    <>
      <div className="pt-[40px] flex justify-center">
        <div>
          <img src={noClient} alt="no-client" />
          <div className="text-center">
            <div className=" flex justify-center">
              <h1 className="font-[900] text-[28px] mt-[32px] text-Neutral900">
              No Clients Assigned
              </h1>
            </div>
            <div className="flex justify-center mt-[12px]">
              <p className="text-[14px] w-[295px] text-Neutral800 text-center">
              Contact your team admin to see if there is a client ready for you to work on.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoClientData;
