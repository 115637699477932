export const currencyList = [
  { name: "DOLLAR", short_name: "USD", symbol: "$" },
  { name: "EURO", short_name: "EUR", symbol: "€" },
  { name: "RUPEES", short_name: "INR", symbol: "₹" },
  { name: "YEN", short_name: "JPY", symbol: "¥" },
  { name: "RAND", short_name: "ZAR", symbol: "R" },
  { name: "POUND", short_name: "GBP", symbol: "£" },
  { name: "CAN DOLLAR", short_name: "CAD", symbol: "C$" },
  { name: "AUS DOLLAR", short_name: "AUD", symbol: "A$" },
  {
    name: "MALAYSIAN RINGGIT",
    short_name: "MYR",
    symbol: "RM",
  },
  {
    name: "SINGAPORE DOLLAR",
    short_name: "SGD",
    symbol: "S$",
  },
  {
    name: "DANISH KRONE",
    short_name: "DKK",
    symbol: "kr",
  },
  {
    name: "BULGARIAN LEV",
    short_name: "BGN",
    symbol: "лв",
  },
  {
    name: "BRAZILIAN REAL",
    short_name: "BRL",
    symbol: "R$",
  },
  {
    name: "POLISH ZLOTY",
    short_name: "PLN",
    symbol: "zł",
  },
  {
    name: "EGYPTIAN POUND",
    short_name: "EGP",
    symbol: "E£",
  },
  {
    name: "SWISS FRANC",
    short_name: "CHF",
    symbol: "₣",
  },
  {
    name: "INDONESIAN RUPIAH",
    short_name: "IDR",
    symbol: "Rp",
  },
  {
    name: "NEW ZEALAND DOLLAR",
    short_name: "NZD",
    symbol: "NZ$",
  },
  {
    name: "PHILIPPINE PESO",
    short_name: "PHP",
    symbol: "₱",
  },
  {
    name: "UNITED ARAB EMIRATES DIRHAM",
    short_name: "AED",
    symbol: "د.إ",
  },
  {
    name: "THAI BAHT",
    short_name: "THB",
    symbol: "฿",
  },
  {
    name: "KENYAN SHILLING",
    short_name: "KES",
    symbol: "KES",
  },
  {
    name: "Króna",
    short_name: "ISK",
    symbol: "Íkr",
  },
  {
    name: "Sri Lankan Rupee",
    short_name: "LKR",
    symbol: "Rs",
  },
  {
    name: "Tanzanian Shilling",
    short_name: "TZS",
    symbol: "TSh",
  },
  {
    name: "Israeli Shekel",
    short_name: "ILS",
    symbol: "₪",
  },
  {
    name: "Colombian Peso",
    short_name: "COP",
    symbol: "COL$",
  },
  {
    name: "Czech Koruna",
    short_name: "CZK",
    symbol: "Kč",
  },
  {
    name: "NORWEGIAN KRONE",
    short_name: "NOK",
    symbol: "kr",
  },
];
