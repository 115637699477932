import React from "react";

function Animation() {
  return (
    <div className="p-12">
      <p className="font-black text-lg ml-1 medium-screen-hover whitespace-nowrap mb-6">
        Client table page skeleton
      </p>
      <div className="w-[9.375rem] mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
      <table className="w-full">
        <tr>
          <td className="w-full max-w-[7%]">
            <div
              className={`h-12 mb-4 w-12 min-w-12 5xl:h-16 5xl:w-16 rounded items-center justify-center text-Neutral700 bg-[#f6f7f8] bg-no-repeat skeleton-animation relative`}
            ></div>
          </td>
          <td className=" w-full max-w-[15%]">
            <div className="rounded w-5/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className="w-full max-w-[15%]">
            <div className="rounded w-5/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className=" w-full max-w-[15%]">
            <div className="w-5/6 rounded mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className=" w-full max-w-[30%] h-3">
            <div className="flex">
              <div className="ml-2 rounded w-20 h-8 mb-4 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
            </div>
          </td>
          <td className="w-full max-w-[10%] text-right">
            <div className="rounded w-20 h-8 mb-4 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
          </td>
        </tr>
        <tr>
          <td className="w-full max-w-[7%]">
            <div
              className={`h-12 mb-4 w-12 min-w-12 5xl:h-16 5xl:w-16 rounded items-center justify-center text-Neutral700 bg-[#f6f7f8] bg-no-repeat skeleton-animation relative`}
            ></div>
          </td>
          <td className=" w-full max-w-[15%]">
            <div className="rounded w-5/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className="w-full max-w-[15%]">
            <div className="rounded w-5/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className=" w-full max-w-[15%]">
            <div className="w-5/6 rounded mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className=" w-full max-w-[30%] h-3">
            <div className="flex">
              <div className="ml-2 rounded w-20 h-8 mb-4 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
            </div>
          </td>
          <td className="w-full max-w-[10%] text-right">
            <div className="rounded w-20 h-8 mb-4 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
          </td>
        </tr>
        <tr>
          <td className="w-full max-w-[7%]">
            <div
              className={`h-12 mb-4 w-12 min-w-12 5xl:h-16 5xl:w-16 rounded items-center justify-center text-Neutral700 bg-[#f6f7f8] bg-no-repeat skeleton-animation relative`}
            ></div>
          </td>
          <td className=" w-full max-w-[15%]">
            <div className="rounded w-5/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className="w-full max-w-[15%]">
            <div className="rounded w-5/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className=" w-full max-w-[15%]">
            <div className="w-5/6 rounded mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className=" w-full max-w-[30%] h-3">
            <div className="flex">
              <div className="ml-2 rounded w-20 h-8 mb-4 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
            </div>
          </td>
          <td className="w-full max-w-[10%] text-right">
            <div className="rounded w-20 h-8 mb-4 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
          </td>
        </tr>
        <tr>
          <td className="w-full max-w-[7%]">
            <div
              className={`h-12 mb-4 w-12 min-w-12 5xl:h-16 5xl:w-16 rounded items-center justify-center text-Neutral700 bg-[#f6f7f8] bg-no-repeat skeleton-animation relative`}
            ></div>
          </td>
          <td className=" w-full max-w-[15%]">
            <div className="rounded w-5/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className="w-full max-w-[15%]">
            <div className="rounded w-5/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className=" w-full max-w-[15%]">
            <div className="w-5/6 rounded mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className=" w-full max-w-[30%] h-3">
            <div className="flex">
              <div className="ml-2 rounded w-20 h-8 mb-4 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
            </div>
          </td>
          <td className="w-full max-w-[10%] text-right">
            <div className="rounded w-20 h-8 mb-4 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
          </td>
        </tr>
        <tr>
          <td className="w-full max-w-[7%]">
            <div
              className={`h-12 mb-4 w-12 min-w-12 5xl:h-16 5xl:w-16 rounded items-center justify-center text-Neutral700 bg-[#f6f7f8] bg-no-repeat skeleton-animation relative`}
            ></div>
          </td>
          <td className=" w-full max-w-[15%]">
            <div className="rounded w-5/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className="w-full max-w-[15%]">
            <div className="rounded w-5/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className=" w-full max-w-[15%]">
            <div className="w-5/6 rounded mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className=" w-full max-w-[30%] h-3">
            <div className="flex">
              <div className="ml-2 rounded w-20 h-8 mb-4 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
            </div>
          </td>
          <td className="w-full max-w-[10%] text-right">
            <div className="rounded w-20 h-8 mb-4 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
          </td>
        </tr>
        <tr>
          <td className="w-full max-w-[7%]">
            <div
              className={`h-12 mb-4 w-12 min-w-12 5xl:h-16 5xl:w-16 rounded items-center justify-center text-Neutral700 bg-[#f6f7f8] bg-no-repeat skeleton-animation relative`}
            ></div>
          </td>
          <td className=" w-full max-w-[15%]">
            <div className="rounded w-5/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className="w-full max-w-[15%]">
            <div className="rounded w-5/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className=" w-full max-w-[15%]">
            <div className="w-5/6 rounded mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className=" w-full max-w-[30%] h-3">
            <div className="flex">
              <div className="ml-2 rounded w-20 h-8 mb-4 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
            </div>
          </td>
          <td className="w-full max-w-[10%] text-right">
            <div className="rounded w-20 h-8 mb-4 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
          </td>
        </tr>
        <tr>
          <td className="w-full max-w-[7%]">
            <div
              className={`h-12 mb-4 w-12 min-w-12 5xl:h-16 5xl:w-16 rounded items-center justify-center text-Neutral700 bg-[#f6f7f8] bg-no-repeat skeleton-animation relative`}
            ></div>
          </td>
          <td className=" w-full max-w-[15%]">
            <div className="rounded w-5/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className="w-full max-w-[15%]">
            <div className="rounded w-5/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className=" w-full max-w-[15%]">
            <div className="w-5/6 rounded mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className=" w-full max-w-[30%] h-3">
            <div className="flex">
              <div className="ml-2 rounded w-20 h-8 mb-4 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
            </div>
          </td>
          <td className="w-full max-w-[10%] text-right">
            <div className="rounded w-20 h-8 mb-4 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
          </td>
        </tr>
        <tr>
          <td className="w-full max-w-[7%]">
            <div
              className={`h-12 mb-4 w-12 min-w-12 5xl:h-16 5xl:w-16 rounded items-center justify-center text-Neutral700 bg-[#f6f7f8] bg-no-repeat skeleton-animation relative`}
            ></div>
          </td>
          <td className=" w-full max-w-[15%]">
            <div className="rounded w-5/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className="w-full max-w-[15%]">
            <div className="rounded w-5/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className=" w-full max-w-[15%]">
            <div className="w-5/6 rounded mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className=" w-full max-w-[30%] h-3">
            <div className="flex">
              <div className="ml-2 rounded w-20 h-8 mb-4 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
            </div>
          </td>
          <td className="w-full max-w-[10%] text-right">
            <div className="rounded w-20 h-8 mb-4 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
          </td>
        </tr>
      </table>
      {/* pagination */}
      <div className="w-full justify-between flex my-7">
        <div className="w-40">
          <div className="w-5/6 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          <div className="w-4/6 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
        </div>
        <div className="flex">
          <div className="rounded w-6 h-6 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative mr-3"></div>
          <div className="rounded w-6 h-6 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
        </div>
      </div>
      <p className="font-black text-lg ml-1 medium-screen-hover whitespace-nowrap mb-6">
        CLIENT & PROJECT MANAGEMENT
      </p>
      <div className="relative">
        <div className="flex justify-between w-full 6xl:max-w-[62.5rem] 5xl:max-w-[56.25rem] max-w-[41.5rem] flex-wrap">
          <div className="w-full 6xl:max-w-[30rem] 6xl:pt-[1.75rem] 6xl:pr-[3.3125rem] 6xl:pb-[5rem] 6xl:pl-[3.75rem] 5xl:max-w-[27.25rem] 5xl:pb-20 max-w-[20rem] pl-8 pr-5 pt-6 pb-12 rounded builderinoshadow block mb-10 relative">
            <div className="flex items-center justify-between mb-4 pr-3">
              <div className="w-2/6 mb-4 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="w-8 h-8 rounded-full bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
            </div>
            <p className="w-full 6xl:max-w-[25rem] 5xl:max-w-[20rem] max-w-[15.8125rem] text-Neutral800 text-sm font-normal mb-2">
              <div className="w-5/6 rounded mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="w-4/6 rounded mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="w-3/6 rounded mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
            </p>
            <div className="flex justify-end absolute bottom-[1rem] right-[1.25rem] 6xl:right-[2.25rem]">
              <div className="rounded w-24 h-8 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative mr-3"></div>
            </div>
          </div>
          <div className="w-full 6xl:max-w-[30rem] 6xl:pt-[1.75rem] 6xl:pr-[3.3125rem] 6xl:pb-[5rem] 6xl:pl-[3.75rem] 5xl:max-w-[27.25rem] 5xl:pb-20 max-w-[20rem] pl-8 pr-5 pt-6 pb-12 rounded builderinoshadow block mb-10 relative">
            <div className="flex items-center justify-between mb-4 pr-3">
              <div className="rounded w-2/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="w-8 h-8 rounded-full bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
            </div>
            <p className="w-full 6xl:max-w-[25rem] 5xl:max-w-[20rem] max-w-[15.8125rem] text-Neutral800 text-sm font-normal mb-2">
              <div className="w-5/6 rounded mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="w-4/6 rounded mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="w-3/6 rounded mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
            </p>
            <div className="flex justify-end absolute bottom-[1rem] right-[1.25rem] 6xl:right-[2.25rem]">
              <div className="rounded w-24 h-8 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative mr-3"></div>
            </div>
          </div>
          <div className="w-full 6xl:max-w-[30rem] 6xl:pt-[1.75rem] 6xl:pr-[3.3125rem] 6xl:pb-[5rem] 6xl:pl-[3.75rem] 5xl:max-w-[27.25rem] 5xl:pb-20 max-w-[20rem] pl-8 pr-5 pt-6 pb-12 rounded builderinoshadow block mb-10 relative">
            <div className="flex items-center justify-between mb-4 pr-3">
              <div className="rounded w-2/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="w-8 h-8 rounded-full bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
            </div>
            <p className="w-full 6xl:max-w-[25rem] 5xl:max-w-[20rem] max-w-[15.8125rem] text-Neutral800 text-sm font-normal mb-2">
              <div className="w-5/6 rounded mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="w-4/6 rounded mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="w-3/6 rounded mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
            </p>
            <div className="flex justify-end absolute bottom-[1rem] right-[1.25rem] 6xl:right-[2.25rem]">
              <div className="rounded w-24 h-8 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative mr-3"></div>
            </div>
          </div>
          <div className="w-full 6xl:max-w-[30rem] 6xl:pt-[1.75rem] 6xl:pr-[3.3125rem] 6xl:pb-[5rem] 6xl:pl-[3.75rem] 5xl:max-w-[27.25rem] 5xl:pb-20 max-w-[20rem] pl-8 pr-5 pt-6 pb-12 rounded builderinoshadow block mb-10 relative">
            <div className="flex items-center justify-between mb-4 pr-3">
              <div className="rounded w-2/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="w-8 h-8 rounded-full bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
            </div>
            <p className="w-full 6xl:max-w-[25rem] 5xl:max-w-[20rem] max-w-[15.8125rem] text-Neutral800 text-sm font-normal mb-2">
              <div className="w-5/6 rounded mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="w-4/6 rounded mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="w-3/6 rounded mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
            </p>
            <div className="flex justify-end absolute bottom-[1rem] right-[1.25rem] 6xl:right-[2.25rem]">
              <div className="rounded w-24 h-8 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative mr-3"></div>
            </div>
          </div>
        </div>
        <div className="absolute top-0 right-0">
          <div className="w-[5.625rem] rounded ml-auto h-8 mb-4 bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
          <div className="flex mt-[3.125rem] mb-6">
            <div className="text-right">
              <div className="w-[6.25rem] rounded mb-2 ml-auto bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="w-[9.375rem] rounded bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
            </div>
            <div className="w-8 rounded-full ml-4 h-8 mb-4 bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="flex mb-6">
            <div className="text-right">
              <div className="w-[6.25rem] rounded mb-2 ml-auto bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="w-[9.375rem] rounded bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
            </div>
            <div className="w-8 rounded-full ml-4 h-8 mb-4 bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="flex mb-6">
            <div className="text-right">
              <div className="w-[6.25rem] rounded mb-2 ml-auto bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="w-[9.375rem] rounded bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
            </div>
            <div className="w-8 rounded-full ml-4 h-8 mb-4 bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
          </div>
        </div>
      </div>
      <p className="font-black text-lg ml-1 medium-screen-hover whitespace-nowrap mb-6">
        DANGER ZONE
      </p>
      <div className="pb-8 mb-[1.875rem]">
        <div className="px-6 pt-6 pb-8 w-full 6xl:max-w-[66.25rem] 5xl:max-w-[56.25rem] max-w-[41.5rem] border border-[#f6f7f8] rounded">
          <div className="flex items-center justify-between mb-8">
            <div className="block w-full">
              <div className="w-2/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="w-full 6xl:max-w-[37.5rem] max-w-[25.5rem]">
                <div className="w-6/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
                <div className="w-3/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              </div>
            </div>
            <div className="w-36 h-12 bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="flex items-center justify-between">
            <div className="block w-full">
              <div className="w-2/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="w-full 6xl:max-w-[37.5rem] max-w-[25.5rem]">
                <div className="w-6/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
                <div className="w-3/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              </div>
            </div>
            <div className="w-36 h-12 bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
          </div>
        </div>
      </div>
      <p className="font-black text-lg ml-1 medium-screen-hover whitespace-nowrap mb-6">
        FORM QUESTIONS
      </p>
      <div className="flex justify-between w-full max-w-[51rem] flex-wrap">
        <div className="w-full max-w-[24.25rem] pt-8 px-8 pb-5 border border-Neutral300 rounded mb-10">
          <div className="w-2/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
          <div className="overflow-hidden relative">
            <div className="5xl:h-[6.25rem] h-[5.5rem] overflow-hidden mb-4 pb-[1.75rem]">
              <div className="rounded w-6/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="rounded w-6/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="rounded w-3/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
            </div>
          </div>
          <div className="flex justify-between relative bottom-0">
            <div className="w-[1.75rem] h-[1.75rem] rounded-full bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
            <div className="flex items-center">
              <div className="bg-[#f6f7f8] rounded block bg-no-repeat skeleton-animation relative w-20 h-8"></div>
              <div className="bg-[#f6f7f8] rounded block bg-no-repeat skeleton-animation relative w-20 h-8 ml-3"></div>
            </div>
          </div>
        </div>
        <div className="w-full max-w-[24.25rem] pt-8 px-8 pb-5 border border-Neutral300 rounded mb-10">
          <div className="w-2/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
          <div className="overflow-hidden relative">
            <div className="5xl:h-[6.25rem] h-[5.5rem] overflow-hidden mb-4 pb-[1.75rem]">
              <div className="rounded w-6/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="rounded w-6/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="rounded w-3/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
            </div>
          </div>
          <div className="flex justify-between relative bottom-0">
            <div className="w-[1.75rem] h-[1.75rem] rounded-full bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
            <div className="flex items-center">
              <div className="bg-[#f6f7f8] rounded block bg-no-repeat skeleton-animation relative w-20 h-8"></div>
              <div className="bg-[#f6f7f8] rounded block bg-no-repeat skeleton-animation relative w-20 h-8 ml-3"></div>
            </div>
          </div>
        </div>
        <div className="w-full max-w-[24.25rem] pt-8 px-8 pb-5 border border-Neutral300 rounded mb-10">
          <div className="w-2/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
          <div className="overflow-hidden relative">
            <div className="5xl:h-[6.25rem] h-[5.5rem] overflow-hidden mb-4 pb-[1.75rem]">
              <div className="rounded w-6/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="rounded w-6/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="rounded w-3/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
            </div>
          </div>
          <div className="flex justify-between relative bottom-0">
            <div className="w-[1.75rem] h-[1.75rem] rounded-full bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
            <div className="flex items-center">
              <div className="bg-[#f6f7f8] rounded block bg-no-repeat skeleton-animation relative w-20 h-8"></div>
              <div className="bg-[#f6f7f8] rounded block bg-no-repeat skeleton-animation relative w-20 h-8 ml-3"></div>
            </div>
          </div>
        </div>
        <div className="w-full max-w-[24.25rem] pt-8 px-8 pb-5 border border-Neutral300 rounded mb-10">
          <div className="w-2/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
          <div className="overflow-hidden relative">
            <div className="5xl:h-[6.25rem] h-[5.5rem] overflow-hidden mb-4 pb-[1.75rem]">
              <div className="rounded w-6/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="rounded w-6/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="rounded w-3/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
            </div>
          </div>
          <div className="flex justify-between relative bottom-0">
            <div className="w-[1.75rem] h-[1.75rem] rounded-full bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
            <div className="flex items-center">
              <div className="bg-[#f6f7f8] rounded block bg-no-repeat skeleton-animation relative w-20 h-8"></div>
              <div className="bg-[#f6f7f8] rounded block bg-no-repeat skeleton-animation relative w-20 h-8 ml-3"></div>
            </div>
          </div>
        </div>
      </div>
      <p className="font-black text-lg ml-1 medium-screen-hover whitespace-nowrap mb-6">
        Request-info
      </p>
      <div className="bg-Neutral000 rounded border border-Neutral300 py-[1.75rem] px-[3.5rem] mb-6 max-w-[21.25rem]">
        <div className="rounded mb-3 w-3/6 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
        <div className="rounded bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
      </div>
      <div className="pt-[3rem] max-w-[29.6875rem]">
        <div className="flex justify-between items-center mb-4">
          <div className="rounded mb-3 w-2/6 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
          <div className="flex items-center justify-center w-[1.75rem] font-medium h-[1.75rem] rounded-full bg-[#f6f7f8] bg-no-repeat skeleton-animation relative"></div>
        </div>
        <div className="mb-4">
          <div className="rounded mb-3 w-4/6 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
          <div className="rounded mb-3 w-3/6 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
        </div>
        <div className="rounded bg-cover mt-4 w-full p-6 h-[10.5rem] bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
      </div>
      <div className="pt-[3rem] max-w-[29.6875rem]">
        <div className="flex justify-between items-center mb-4">
          <div className="rounded mb-3 w-2/6 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
          <div className="flex items-center justify-center w-[1.75rem] font-medium h-[1.75rem] rounded-full bg-[#f6f7f8] bg-no-repeat skeleton-animation relative"></div>
        </div>
        <div className="mb-4">
          <div className="rounded mb-3 w-4/6 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
          <div className="rounded mb-3 w-3/6 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
        </div>
        <div className="rounded bg-cover mt-4 w-full p-6 h-[10.5rem] bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
      </div>
      <div className="flex pt-10 justify-around max-w-[29.6875rem]">
        <div className="h-[3rem] w-full 5xl:max-w-[12.5rem] max-w-[10.75rem] rounded bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
        <div className="h-[3rem] w-full 5xl:max-w-[12.5rem] max-w-[10.75rem] rounded bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
      </div>
      <p className="font-black text-lg ml-1 medium-screen-hover whitespace-nowrap mt-10">
        Tasks / Services
      </p>
      <div className="flex pt-10 justify-around 5xl:max-w-[11.75rem] max-w-[9.875rem]">
        <div className="h-[2rem] w-full 5xl:max-w-[5.625rem] max-w-[4.6875rem] rounded bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
        <div className="h-[2rem] w-full 5xl:max-w-[5.625rem] max-w-[4.6875rem] rounded bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
      </div>
      <div className="flex flex-wrap mt-10 w-full 5xl:max-w-[83.75rem] lg:max-w-[59rem] max-w-[43.75rem] justify-between">
        <div className="5xl:max-w-[25rem] lg:max-w-[18rem] lg:w-full md:w-[48%] w-full">
          <div className="w-[3.125rem] mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          <div className="bg-Neutral000 rounded-lg border border-Neutral300 mb-2 max-w-full py-3 px-3 flex justify-between">
            <div className="rounded w-3/6 bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative"></div>
            <div className="rounded w-[3.125rem] bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="bg-Neutral000 rounded-lg border border-Neutral300 mb-2 max-w-full py-3 px-3 flex justify-between">
            <div className="rounded w-3/6 bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative"></div>
            <div className="rounded w-[3.125rem] bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="bg-Neutral000 rounded-lg border border-Neutral300 mb-2 max-w-full py-3 px-3 flex justify-between">
            <div className="rounded w-3/6 bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative"></div>
            <div className="rounded w-[3.125rem] bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="bg-Neutral000 rounded-lg border border-Neutral300 mb-2 max-w-full py-3 px-3 flex justify-between">
            <div className="rounded w-[3.75rem] bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative"></div>
          </div>
        </div>
        <div className="5xl:max-w-[25rem] lg:max-w-[18rem] lg:w-full md:w-[48%] w-full">
          <div className="w-[3.125rem] mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          <div className="bg-Neutral000 rounded-lg border border-Neutral300 mb-2 max-w-full py-3 px-3 flex justify-between">
            <div className="rounded w-3/6 bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative"></div>
            <div className="rounded w-[3.125rem] bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="bg-Neutral000 rounded-lg border border-Neutral300 mb-2 max-w-full py-3 px-3 flex justify-between">
            <div className="rounded w-3/6 bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative"></div>
            <div className="rounded w-[3.125rem] bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="bg-Neutral000 rounded-lg border border-Neutral300 mb-2 max-w-full py-3 px-3 flex justify-between">
            <div className="rounded w-[3.75rem] bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative"></div>
          </div>
        </div>
        <div className="5xl:max-w-[25rem] lg:max-w-[18rem] lg:w-full md:w-[48%] w-full">
          <div className="w-[3.125rem] mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          <div className="bg-Neutral000 rounded-lg border border-Neutral300 mb-2 max-w-full py-3 px-3 flex justify-between">
            <div className="rounded w-3/6 bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative"></div>
            <div className="rounded w-[3.125rem] bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="bg-Neutral000 rounded-lg border border-Neutral300 mb-2 max-w-full py-3 px-3 flex justify-between">
            <div className="rounded w-3/6 bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative"></div>
            <div className="rounded w-[3.125rem] bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="bg-Neutral000 rounded-lg border border-Neutral300 mb-2 max-w-full py-3 px-3 flex justify-between">
            <div className="rounded w-[3.75rem] bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative"></div>
          </div>
        </div>
      </div>
      <p className="font-black text-lg ml-1 medium-screen-hover whitespace-nowrap my-6">
        ASSETS
      </p>
      <div className="w-full flex justify-between flex-wrap max-w-[52.25rem] pt-[2.5rem]">
        <div className="w-[24.25rem] h-[16.5rem] p-6 rounded relative mb-[3.75rem] bg-Neutral000 border border-Neutral300 builderinoshadow ">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="w-8 h-8 rounded-full bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
              <div className="w-[5rem] ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
            </div>
            <div className="w-8 h-8 rounded-full bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="flex items-center justify-between mt-4 py-3">
            <div className="w-[5.5rem] ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
            <div className="w-[3.75rem] ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="flex items-center justify-between py-3">
            <div className="w-[5.5rem] ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
            <div className="w-[3.75rem] ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="flex items-center justify-between py-3">
            <div className="w-[5.5rem] ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
            <div className="w-[3.75rem] ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </div>
        </div>
        <div className="w-[24.25rem] h-[16.5rem] p-6 rounded relative mb-[3.75rem] bg-Neutral000 border border-Neutral300 builderinoshadow ">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="w-8 h-8 rounded-full bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
              <div className="w-[5rem] ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
            </div>
            <div className="w-8 h-8 rounded-full bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="flex items-center justify-between mt-4 py-3">
            <div className="w-[5.5rem] ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
            <div className="w-[3.75rem] ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="flex items-center justify-between py-3">
            <div className="w-[5.5rem] ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
            <div className="w-[3.75rem] ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="flex items-center justify-between py-3">
            <div className="w-[5.5rem] ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
            <div className="w-[3.75rem] ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </div>
        </div>
        <div className="w-[24.25rem] h-[16.5rem] p-6 rounded relative mb-[3.75rem] bg-Neutral000 border border-Neutral300 builderinoshadow ">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="w-8 h-8 rounded-full bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
              <div className="w-[5rem] ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
            </div>
            <div className="w-8 h-8 rounded-full bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="flex items-center justify-between mt-4 py-3">
            <div className="w-[5.5rem] ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
            <div className="w-[3.75rem] ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="flex items-center justify-between py-3">
            <div className="w-[5.5rem] ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
            <div className="w-[3.75rem] ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="flex items-center justify-between py-3">
            <div className="w-[5.5rem] ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
            <div className="w-[3.75rem] ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="flex items-center justify-between py-3">
            <div className="w-[5.5rem] ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
            <div className="w-[3.75rem] ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="flex items-center justify-between py-3">
            <div className="w-[5.5rem] ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
            <div className="w-[3.75rem] ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </div>
        </div>
      </div>
      <p className="font-black text-lg ml-1 medium-screen-hover whitespace-nowrap my-6">
        Documents
      </p>
      <div className="w-full flex justify-between flex-wrap max-w-[52.25rem] pt-[2.5rem]">
        <div className="w-[24.25rem] h-[16.5rem] p-6 rounded relative mb-[3.75rem] bg-Neutral000 border border-Neutral300 builderinoshadow ">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="w-8 h-8 rounded-full bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
              <div className="w-[5rem] ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
            </div>
            <div className="w-8 h-8 rounded-full bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="mt-8 py-3">
            <div className="w-4/6 ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="w-3/6 ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          <div className="mt-4 w-2/6 ml-2 rounded bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
        </div>
        <div className="w-[24.25rem] h-[16.5rem] p-6 rounded relative mb-[3.75rem] bg-Neutral000 border border-Neutral300 builderinoshadow ">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="w-8 h-8 rounded-full bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
              <div className="w-[5rem] ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
            </div>
            <div className="w-8 h-8 rounded-full bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="mt-8 py-3">
            <div className="w-4/6 ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="w-3/6 ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          <div className="mt-4 w-2/6 ml-2 rounded bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
        </div>
        <div className="w-[24.25rem] h-[16.5rem] p-6 rounded relative mb-[3.75rem] bg-Neutral000 border border-Neutral300 builderinoshadow ">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="w-8 h-8 rounded-full bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
              <div className="w-[5rem] ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
            </div>
            <div className="w-8 h-8 rounded-full bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="mt-8 py-3">
            <div className="w-4/6 ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="w-3/6 ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          <div className="mt-4 w-2/6 ml-2 rounded bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
        </div>
        <div className="w-[24.25rem] h-[16.5rem] p-6 rounded relative mb-[3.75rem] bg-Neutral000 border border-Neutral300 builderinoshadow ">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="w-8 h-8 rounded-full bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
              <div className="w-[5rem] ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
            </div>
            <div className="w-8 h-8 rounded-full bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="mt-8 py-3">
            <div className="w-4/6 ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="w-3/6 ml-2 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          <div className="mt-4 w-2/6 ml-2 rounded bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
        </div>
      </div>
      <p className="font-black text-lg ml-1 medium-screen-hover whitespace-nowrap my-6">
        Questionnaire
      </p>
      <div className="w-full border border-Neutral300 rounded mb-10 pt-[1.25rem] max-w-[24.1875rem] 5xl:max-w-[27.875rem] px-8 pb-8 mt-8">
        <div className="w-3/6 mb-6 bg-[#f6f7f8] block rounded h-3 bg-no-repeat skeleton-animation relative"></div>
        <div className="bg-[#f6f7f8] rounded block bg-no-repeat skeleton-animation relative w-20 h-3"></div>
        <div className="w-3/6 mb-1 mt-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
      </div>
      <div className="mt-16 mb-8">
        <div className="flex justify-around 5xl:max-w-[15rem] max-w-[12rem]">
          <div className="h-[2rem] w-full 5xl:max-w-[7.25rem] max-w-[5.75rem] rounded bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
          <div className="h-[2rem] w-full 5xl:max-w-[7.25rem] max-w-[5.75rem] rounded bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
        </div>
      </div>
      {/* 1 */}
      <div className="pt-[2.8125rem] max-w-[24.1875rem] 5xl:max-w-[27.875rem] px-8 pb-[2.8125rem] border border-Neutral300 rounded-[0.25rem] mr-6 mb-10">
        <div className="flex items-center justify-end">
          <div className="w-8 h-8 rounded-full bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
        </div>
        <div className="py-7">
          <div className="overflow-hidden relative">
            <div className="5xl:h-[6.25rem] h-[5.5rem] overflow-hidden mb-4 pb-[1.75rem]">
              <div className="rounded w-6/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="rounded w-6/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="rounded w-3/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
            </div>
          </div>
          <div className="h-[2rem] w-full 5xl:max-w-[21.25rem] max-w-[18.75rem] rounded bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
        </div>
      </div>
      {/* 2 */}
      <div className="pt-[2.8125rem] max-w-[24.1875rem] 5xl:max-w-[27.875rem] px-8 pb-[2.8125rem] border border-Neutral300 rounded-[0.25rem] mr-6 mb-10">
        <div className="flex items-center justify-end">
          <div className="w-8 h-8 rounded-full bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
        </div>
        <div className="py-7">
          <div className="overflow-hidden relative">
            <div className="5xl:h-[6.25rem] h-[5.5rem] overflow-hidden mb-4 pb-[1.75rem]">
              <div className="rounded w-6/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="rounded w-6/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="rounded w-3/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
            </div>
          </div>
          <div className="5xl:h-[6.25rem] h-[5.5rem] overflow-hidden mb-4 pb-[1.75rem]">
            <div className="rounded w-6/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
            <div className="rounded w-6/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
            <div className="rounded w-3/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
          </div>
        </div>
      </div>
      {/* 3 */}
      <div className="flex items-baseline">
        <div className="pt-[2.8125rem] w-full max-w-[24.1875rem] 5xl:max-w-[27.875rem] px-8 pb-[2.8125rem] border border-Neutral300 rounded-[0.25rem] mr-6 mb-10">
          <div className="flex items-center justify-between w-full">
            <div className="rounded w-4/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
            <div className="w-8 h-8 rounded-full bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="pt-7">
            <div className="overflow-hidden relative">
              <div className="5xl:h-[6.25rem] h-[5.5rem] overflow-hidden mb-4 pb-[1.75rem]">
                <div className="rounded w-6/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
                <div className="rounded w-4/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              </div>
            </div>
            <div className="5xl:h-[6.25rem] h-[5.5rem] overflow-hidden mb-4 pb-[1.75rem]">
              <div className="rounded w-6/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="rounded w-6/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="rounded w-3/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
            </div>
          </div>
        </div>
        <div className="pt-[2.8125rem] w-full max-w-[24.1875rem] 5xl:max-w-[27.875rem] px-8 pb-[2.8125rem] border border-Neutral300 rounded-[0.25rem] mr-6 mb-10">
          <div className="flex items-center justify-between w-full">
            <div className="rounded w-4/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
            <div className="w-8 h-8 rounded-full bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div className="pt-7">
            <div className="overflow-hidden relative">
              <div className="5xl:h-[6.25rem] overflow-hidden mb-4 pb-[1.75rem]">
                <div className="rounded w-6/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
                <div className="rounded w-4/6 mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <p className="font-black text-lg ml-1 medium-screen-hover whitespace-nowrap mb-6">
        Time Tracking
      </p>
      <div className="w-[9.375rem] mb-4 bg-[#f6f7f8] inline-block h-12 bg-no-repeat skeleton-animation relative"></div>
      <div className="w-[9.375rem] mb-4 ml-3 bg-[#f6f7f8] inline-block h-12 bg-no-repeat skeleton-animation relative"></div>
      <div className="w-full mt-5">
        <div className="w-[9.375rem] mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
      </div>
      <table className="w-full">
        <tr>
          <td className="w-full max-w-[4%]">
            <div
              className={`h-12 mb-4 w-12 min-w-12 5xl:h-16 5xl:w-16 rounded items-center justify-center text-Neutral700 bg-[#f6f7f8] bg-no-repeat skeleton-animation relative`}
            ></div>
          </td>
          <td className=" w-full max-w-[5%]">
            <div className="rounded w-5/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className="w-full max-w-[10%]">
            <div className="rounded w-5/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className=" w-full max-w-[71%] text-center">
            <div className="w-1/6 rounded mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className="w-full max-w-[10%] text-right">
            <div className="rounded w-20 h-8 mb-4 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
          </td>
        </tr>
        <tr>
          <td className="w-full max-w-[4%]">
            <div
              className={`h-12 mb-4 w-12 min-w-12 5xl:h-16 5xl:w-16 rounded items-center justify-center text-Neutral700 bg-[#f6f7f8] bg-no-repeat skeleton-animation relative`}
            ></div>
          </td>
          <td className=" w-full max-w-[5%]">
            <div className="rounded w-5/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className="w-full max-w-[10%]">
            <div className="rounded w-5/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className=" w-full max-w-[71%] text-center">
            <div className="w-1/6 rounded mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className="w-full max-w-[10%] text-right">
            <div className="rounded w-20 h-8 mb-4 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
          </td>
        </tr>
        <tr>
          <td className="w-full max-w-[4%]">
            <div
              className={`h-12 mb-4 w-12 min-w-12 5xl:h-16 5xl:w-16 rounded items-center justify-center text-Neutral700 bg-[#f6f7f8] bg-no-repeat skeleton-animation relative`}
            ></div>
          </td>
          <td className=" w-full max-w-[5%]">
            <div className="rounded w-5/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className="w-full max-w-[10%]">
            <div className="rounded w-5/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className=" w-full max-w-[71%] text-center">
            <div className="w-1/6 rounded mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className="w-full max-w-[10%] text-right">
            <div className="rounded w-20 h-8 mb-4 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
          </td>
        </tr>
        <tr>
          <td className="w-full max-w-[4%]">
            <div
              className={`h-12 mb-4 w-12 min-w-12 5xl:h-16 5xl:w-16 rounded items-center justify-center text-Neutral700 bg-[#f6f7f8] bg-no-repeat skeleton-animation relative`}
            ></div>
          </td>
          <td className=" w-full max-w-[5%]">
            <div className="rounded w-5/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className="w-full max-w-[10%]">
            <div className="rounded w-5/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className=" w-full max-w-[71%] text-center">
            <div className="w-1/6 rounded mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className="w-full max-w-[10%] text-right">
            <div className="rounded w-20 h-8 mb-4 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
          </td>
        </tr>
      </table>
      {/* pagination */}
      <div className="w-full justify-between flex my-7">
        <div className="w-40">
          <div className="w-5/6 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          <div className="w-4/6 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
        </div>
        <div className="flex">
          <div className="rounded w-6 h-6 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative mr-3"></div>
          <div className="rounded w-6 h-6 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
        </div>
      </div>
      <h1 className="text-[32px] pb-3">CLIENT DETAILS</h1>
      <div className="flex" >
        <div className="mr-10">
          <div className="pt-8 min-w-[300px] text-left w-fit">
            <div className="w-[5.625rem] rounded h-6 mb-8 bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
            <div class="flex mb-3">
              <div class="w-8 rounded-full mr-4 h-8 mb-4 bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
              <div class="text-right">
                <div class="w-[6.25rem] rounded mb-2 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
                <div class="w-[9.375rem] rounded bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              </div>
            </div>
            <div class="flex mb-3">
              <div class="w-8 rounded-full mr-4 h-8 mb-4 bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
              <div class="text-right">
                <div class="w-[6.25rem] rounded mb-2 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
                <div class="w-[9.375rem] rounded bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              </div>
            </div>
            <div class="flex mb-3">
              <div class="w-8 rounded-full mr-4 h-8 mb-4 bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
              <div class="text-right">
                <div class="w-[6.25rem] rounded mb-2 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
                <div class="w-[9.375rem] rounded bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              </div>
            </div>
          </div>
          <div className="pt-8 min-w-[300px] text-left w-fit">
            <div className="flex justify-between items-center mb-8" >
              <div class="w-[6.25rem] rounded bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
              <div className="w-[4rem] rounded h-8 bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
            </div>
            <div class="flex mb-3 justify-between">
              <div className="flex" >
                <div class="w-8 rounded-full mr-4 h-8 bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
                <div class="text-right flex items-center">
                  <div class="w-[6.375rem] rounded bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
                </div>
              </div>
              <div className="w-[3rem] rounded h-6 bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative" ></div>
            </div>
            <div class="flex mb-3 justify-between">
              <div className="flex" >
                <div class="w-8 rounded-full mr-4 h-8 bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
                <div class="text-right flex items-center">
                  <div class="w-[6.375rem] rounded bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
                </div>
              </div>
              <div className="w-[3rem] rounded h-6 bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative" ></div>
            </div>
            <div class="flex mb-3 justify-between">
              <div className="flex" >
                <div class="w-8 rounded-full mr-4 h-8 bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
                <div class="text-right flex items-center">
                  <div class="w-[6.375rem] rounded bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
                </div>
              </div>
              <div className="w-[3rem] rounded h-6 bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative" ></div>
            </div>
            <div class="flex mb-3 justify-between">
              <div className="flex" >
                <div class="w-8 rounded-full mr-4 h-8 bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
                <div class="text-right flex items-center">
                  <div class="w-[6.375rem] rounded bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
                </div>
              </div>
              <div className="w-[3rem] rounded h-6 bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative" ></div>
            </div>
            <div className="w-full mt-6 rounded h-8 bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
          </div>
        </div>
        <div className="flex justify-between w-full ml-[120px] 6xl:max-w-[62.5rem] 5xl:max-w-[56.25rem] max-w-[41.5rem] flex-wrap" >
          <div className="w-full flex justify-between items-center mb-8" >
            <div class="w-[6.25rem] rounded bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
            <div className="w-[4rem] rounded h-8 bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
          </div>
          <div class="flex justify-between w-full 6xl:max-w-[62.5rem] 5xl:max-w-[56.25rem] max-w-[41.5rem] flex-wrap">
            <div
                class="w-full 6xl:max-w-[30rem] 6xl:pt-[1.75rem] 6xl:pr-[3.3125rem] 6xl:pb-[5rem] 6xl:pl-[3.75rem] 5xl:max-w-[27.25rem] 5xl:pb-20 max-w-[20rem] pl-8 pr-5 pt-6 pb-12 rounded builderinoshadow block mb-10 relative">
                <div class="flex items-center justify-between mb-4 pr-3">
                    <div class="w-2/6 mb-4 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative">
                    </div>
                    <div class="w-8 h-8 rounded-full bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
                </div>
                <p
                    class="w-full 6xl:max-w-[25rem] 5xl:max-w-[20rem] max-w-[15.8125rem] text-Neutral800 text-sm font-normal mb-2">
                <div class="w-5/6 rounded mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
                <div class="w-4/6 rounded mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
                <div class="w-3/6 rounded mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
                </p>
                <div class="flex justify-end absolute bottom-[1rem] right-[1.25rem] 6xl:right-[2.25rem]">
                    <div class="rounded w-[260px] h-8 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative mr-3"></div>
                </div>
            </div>
            <div
                class="w-full 6xl:max-w-[30rem] 6xl:pt-[1.75rem] 6xl:pr-[3.3125rem] 6xl:pb-[5rem] 6xl:pl-[3.75rem] 5xl:max-w-[27.25rem] 5xl:pb-20 max-w-[20rem] pl-8 pr-5 pt-6 pb-12 rounded builderinoshadow block mb-10 relative">
                <div class="flex items-center justify-between mb-4 pr-3">
                    <div class="rounded w-2/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative">
                    </div>
                    <div class="w-8 h-8 rounded-full bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
                </div>
                <p
                    class="w-full 6xl:max-w-[25rem] 5xl:max-w-[20rem] max-w-[15.8125rem] text-Neutral800 text-sm font-normal mb-2">
                <div class="w-5/6 rounded mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
                <div class="w-4/6 rounded mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
                <div class="w-3/6 rounded mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
                </p>
                <div class="flex justify-end absolute bottom-[1rem] right-[1.25rem] 6xl:right-[2.25rem]">
                    <div class="rounded w-[260px] h-8 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative mr-3"></div>
                </div>
            </div>
            <div
                class="w-full 6xl:max-w-[30rem] 6xl:pt-[1.75rem] 6xl:pr-[3.3125rem] 6xl:pb-[5rem] 6xl:pl-[3.75rem] 5xl:max-w-[27.25rem] 5xl:pb-20 max-w-[20rem] pl-8 pr-5 pt-6 pb-12 rounded builderinoshadow block mb-10 relative">
                <div class="flex items-center justify-between mb-4 pr-3">
                    <div class="rounded w-2/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative">
                    </div>
                    <div class="w-8 h-8 rounded-full bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
                </div>
                <p
                    class="w-full 6xl:max-w-[25rem] 5xl:max-w-[20rem] max-w-[15.8125rem] text-Neutral800 text-sm font-normal mb-2">
                <div class="w-5/6 rounded mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
                <div class="w-4/6 rounded mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
                <div class="w-3/6 rounded mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
                </p>
                <div class="flex justify-end absolute bottom-[1rem] right-[1.25rem] 6xl:right-[2.25rem]">
                    <div class="rounded w-[260px] h-8 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative mr-3"></div>
                </div>
            </div>
            <div
                class="w-full 6xl:max-w-[30rem] 6xl:pt-[1.75rem] 6xl:pr-[3.3125rem] 6xl:pb-[5rem] 6xl:pl-[3.75rem] 5xl:max-w-[27.25rem] 5xl:pb-20 max-w-[20rem] pl-8 pr-5 pt-6 pb-12 rounded builderinoshadow block mb-10 relative">
                <div class="flex items-center justify-between mb-4 pr-3">
                    <div class="rounded w-2/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative">
                    </div>
                    <div class="w-8 h-8 rounded-full bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
                </div>
                <p
                    class="w-full 6xl:max-w-[25rem] 5xl:max-w-[20rem] max-w-[15.8125rem] text-Neutral800 text-sm font-normal mb-2">
                <div class="w-5/6 rounded mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
                <div class="w-4/6 rounded mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
                <div class="w-3/6 rounded mb-4 bg-[#f6f7f8] block h-3 bg-no-repeat skeleton-animation relative"></div>
                </p>
                <div class="flex justify-end absolute bottom-[1rem] right-[1.25rem] 6xl:right-[2.25rem]">
                    <div class="rounded w-[260px] h-8 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative mr-3"></div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Animation;
