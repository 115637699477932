import React from "react";
import clx from "classnames";

function CardSubTitle({ text = "", className = "" }) {
  if (!text) {
    return null;
  }

  return (
    <h6 className={clx("text-[10px] text-Neutral800 uppercase", className)}>
      {text}
    </h6>
  );
}

export default CardSubTitle;
