/** @param {import("react").SVGProps} props - SVG Icon Props */
function CalendarIcon({ props }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1475 2.96289H3.8512C3.19664 2.96289 2.66602 3.49352 2.66602 4.14808V12.4444C2.66602 13.0989 3.19664 13.6296 3.8512 13.6296H12.1475C12.8021 13.6296 13.3327 13.0989 13.3327 12.4444V4.14808C13.3327 3.49352 12.8021 2.96289 12.1475 2.96289Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3691 1.77783V4.1482"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.62891 1.77783V4.1482"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66602 6.51855H13.3327"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9.33268 9H8.66602" stroke="currentColor" strokeLinecap="round" />
      <path d="M11.3327 9H10.666" stroke="currentColor" strokeLinecap="round" />
      <path d="M9.33268 11H8.66602" stroke="currentColor" strokeLinecap="round" />
      <path d="M11.3327 11H10.666" stroke="currentColor" strokeLinecap="round" />
      <path d="M7.33268 9H6.66602" stroke="currentColor" strokeLinecap="round" />
      <path d="M7.33268 11H6.66602" stroke="currentColor" strokeLinecap="round" />
      <path d="M5.33268 9H4.66602" stroke="currentColor" strokeLinecap="round" />
      <path d="M5.33268 11H4.66602" stroke="currentColor" strokeLinecap="round" />
    </svg>
  );
}

export default CalendarIcon;
