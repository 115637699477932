import React from "react";
import { useSelector } from "react-redux";
import Noproposals from "../../assets/Images/Noproposals.svg";

const EmptyInvoice = ({ setSelectClientModalIsOpen }) => {
  const authSelector = useSelector((state) => state.auth);

  return (
    <>
      <div className="pt-[80px] flex justify-center">
        <div>
          <img src={Noproposals} alt="no-client" />
          <div className="text-center">
            <div className=" flex justify-center">
              <h1 className="font-[900] text-[28px] mt-[32px] text-Neutral900">
                Create an Invoice
              </h1>
            </div>
            <div className="flex justify-center mt-[12px]">
              <p className="text-[14px] w-[295px] text-Neutral800 text-center">
                Time to get paid! Use the button below to get started with your
                first invoice.
              </p>
            </div>
            <div className="mt-[24px] 2xl:pb-0 md:pb-16 pb-10 flex justify-center">
              {authSelector?.user?.invited === false ||
              (authSelector?.user?.owner &&
                authSelector?.user?.invited === true) ? (
                <button
                  type="submit"
                  className="w-[295px] relative text-sm text-center block py-4 rounded overflow-hidden border border-Neutral900 relativ focus-visible:outline focus-visible:outline-[6px] focus-visible:drop-shadow-none focus-visible:outline-AccentMediumLight transition duration-300 ease-in-out"
                  onClick={() => setSelectClientModalIsOpen(true)}
                >
                  <span className="relative text-Neutral900 font-[600] z-10 text-sm">
                    Get started with invoices
                  </span>
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmptyInvoice;
