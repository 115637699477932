import moment from "moment/moment";
import { memo, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Logo from "../../assets/Images/Logo.svg";
import { currencies } from "../../assets/json/currencies";
import useLogo from "../../hooks/useLogo";
import PaypalIcon from "../CreateInvoiceForm/PaypalIcon";
import StripeIcon from "../CreateInvoiceForm/StripeIcon";
import FeaturesModal from "../White-Label/FeaturesModal";
import WhiteLabelButton from "../White-Label/WhiteLabelButton";
import EditIcon from "../Icons/EditIcon";
import { cn } from "../../utils";

const renderOnlyWhenAvailable = (value, jsx) => {
  if (!value || value === "") return null;
  return jsx;
};

function A4Page({ values, handleFocused, user }) {
  const navigate = useNavigate();
  const { Logo: logoSrc, isCustom } = useLogo();

  const isWhiteLabelSubscriber = user?.brandIdentity?.status === "active";

  const [isWhiteLabelFeaturesModalOpen, setIsWhiteLabelFeaturesModalOpen] =
    useState(false);

  const showClickerForMyInfo = useMemo(
    () =>
      values.email === "" &&
      values.fullName === "" &&
      values.companyName === "" &&
      values.address === "",
    [values.address, values.companyName, values.email, values.fullName]
  );

  const showClickerForBillTo = useMemo(
    () =>
      values.clientName === "" &&
      values.clientEmail === "" &&
      values.clientCompanyName === "" &&
      values.clientAddress === "",
    [
      values.clientAddress,
      values.clientCompanyName,
      values.clientEmail,
      values.clientName,
    ]
  );

  const hasHourlyService = useMemo(
    () => values.services?.some((service) => service.serviceType === "Hourly"),
    [values.services]
  );

  const hasFixedService = useMemo(
    () => values.services?.some((service) => service.serviceType === "Fixed"),
    [values.services]
  );

  const showClickerForInvoiceDetails = useMemo(
    () => values.invoiceDate === "" && values.dueDate === "",
    [values.dueDate, values.invoiceDate]
  );

  const currency = useMemo(
    () => currencies.find((c) => c.code === values?.currency),
    [values?.currency]
  );

  const words = user?.customDomain?.custom_domain_name?.split(".");
  const secondLastWord = words && words[words?.length - 2];
  const domainName = secondLastWord;

  const currencyFormatter = useMemo(
    () =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: values?.currency || "USD",
      }),
    [values?.currency]
  );

  const subtotal = values?.services?.reduce((acc, service) => {
    if (service?.serviceType === "Fixed") {
      return acc + Number(service?.servicePrice);
    } else {
      return acc + Number(service?.quantity) * Number(service?.servicePrice);
    }
  }, 0);

  function roundToTwoDecimals(value) {
    if (isNaN(value)) return 0;
    return Math.round(value * 100) / 100;
  }

  const getDiscount = (subtotal, discount) => {
    const subtotalNum = Number(subtotal) || 0;
    const discountNum = Number(discount);
    if (isNaN(discountNum) || discountNum === 0 || isNaN(subtotalNum)) return 0;
    return roundToTwoDecimals((subtotalNum * discountNum) / 100);
  };

  const getVat = (subtotal, vatPercentage, discount) => {
    const subtotalNum = Number(subtotal) || 0;
    const vatPercentageNum = Number(vatPercentage);
    if (isNaN(vatPercentageNum) || vatPercentageNum === 0 || isNaN(subtotalNum))
      return 0;
    const discountedAmount = subtotalNum - getDiscount(subtotalNum, discount);
    return roundToTwoDecimals((discountedAmount * vatPercentageNum) / 100);
  };

  const getTotalDue = (subtotal, discount, vatPercentage, taxes, isVAT) => {
    const subTotalNum = Number(subtotal) || 0;
    const discountNum = Number(discount) || 0;
    const vatPercentageNum = Number(vatPercentage) || 0;

    const taxesValid = Array.isArray(taxes) && taxes.length > 0;

    if (isVAT) {
      return roundToTwoDecimals(
        subTotalNum -
          getDiscount(subTotalNum, discountNum) +
          getVat(subTotalNum, vatPercentageNum, discountNum)
      );
    } else if (taxesValid) {
      const totalTaxes = taxes.reduce((total, tax) => {
        const taxValueNum = Number(tax.taxValue) || 0;
        return total + getVat(subTotalNum, taxValueNum, discountNum);
      }, 0);
      return roundToTwoDecimals(
        subTotalNum - getDiscount(subTotalNum, discountNum) + totalTaxes
      );
    } else {
      return roundToTwoDecimals(
        subTotalNum - getDiscount(subTotalNum, discountNum)
      );
    }
  };

  return (
    <section
      id="invoice-preview"
      className="flex flex-col gap-10 px-4 py-6 bg-white border rounded-lg shadow-2xl lg:px-8 border-Neutral300"
    >
      {isWhiteLabelSubscriber && isCustom && (
        <div className="-mb-5">
          <img
            src={logoSrc}
            alt={`${user?.brandIdentity?.companyName} Logo`}
            id="cm_logo"
            className="object-contain size-7"
          />
        </div>
      )}

      {/* SECTION 1 */}
      <div className="flex justify-between">
        <h1 className="inline-flex items-center gap-4 text-2xl font-bold text-Neutral900">
          INVOICE #{values.invoiceNumber || 1}
          <button
            className="p-1.5 rounded-full bg-Neutral-200 no-print"
            onClick={() => handleFocused("templateName")}
          >
            <EditIcon className="text-Neutral700" />
          </button>
        </h1>

        <div className="relative flex flex-col items-end gap-1">
          <button
            className="p-1.5 rounded-full bg-Neutral-200 absolute -top-[100%] no-print"
            onClick={() => handleFocused("myInformation")}
          >
            <EditIcon className="text-Neutral700" />
          </button>

          {renderOnlyWhenAvailable(
            values.companyName,
            <h3 className="text-xs font-semibold text-Neutral900">
              {values.companyName}
            </h3>
          )}
          {renderOnlyWhenAvailable(
            values.fullName,
            <h2 className="text-xs text-Neutral900">{values.fullName}</h2>
          )}
          {renderOnlyWhenAvailable(
            values.email,
            <p className="text-xs text-Neutral900">{values.email}</p>
          )}
          {renderOnlyWhenAvailable(
            values.phoneNumber,
            <p className="text-xs text-Neutral900">{values.phoneNumber}</p>
          )}
          {renderOnlyWhenAvailable(
            values.address,
            <p className="text-xs text-Neutral900">{values.address}</p>
          )}
          {renderOnlyWhenAvailable(
            values.VAT,
            <p className="text-xs text-Neutral800">VAT {values.VAT}</p>
          )}

          {showClickerForMyInfo && (
            <button
              className="w-48 h-20 text-sm font-semibold rounded-lg text-Neutral700 bg-Neutral300"
              onClick={() => handleFocused("myInformation")}
            >
              + ADD
            </button>
          )}
        </div>
      </div>

      {/* SECTION 2 */}
      <div className="flex justify-between">
        <div>
          <h4 className="inline-flex items-center gap-4 mb-2 text-sm font-bold uppercase text-Neutral900">
            Bill To
            <button
              className="p-1.5 rounded-full bg-Neutral-200 no-print"
              onClick={() => handleFocused("clientInformation")}
            >
              <EditIcon className="text-Neutral700" />
            </button>
          </h4>

          <div className="relative">
            {renderOnlyWhenAvailable(
              values.clientCompanyName,
              <h3 className="text-xs font-semibold text-Neutral900">
                {values.clientCompanyName}
              </h3>
            )}
            <p className="text-xs leading-5 text-Neutral900">
              {renderOnlyWhenAvailable(
                values.clientName,
                <span>{values.clientName}</span>
              )}
              <br />
              {renderOnlyWhenAvailable(
                values.clientEmail,
                <span>{values.clientEmail}</span>
              )}
              <br />
              {renderOnlyWhenAvailable(
                values.clientAddress,
                <span>{values.clientAddress}</span>
              )}
              <br />
              {renderOnlyWhenAvailable(
                values.clientPhoneNumber,
                <span>{values.clientPhoneNumber}</span>
              )}
              <br />
              {renderOnlyWhenAvailable(
                values.clientVAT,
                <span>VAT {values.clientVAT}</span>
              )}
            </p>

            {showClickerForBillTo && (
              <button
                className="absolute inset-0 h-20 text-sm font-semibold rounded-lg w-44 text-Neutral700 bg-Neutral300"
                onClick={() => handleFocused("clientInformation")}
              >
                + ADD
              </button>
            )}
          </div>
        </div>

        <div>
          <h4 className="inline-flex items-center gap-4 mb-2 text-sm font-bold uppercase text-Neutral900">
            <button
              className="p-1.5 rounded-full bg-Neutral-200 no-print"
              onClick={() => handleFocused("invoiceDetails")}
            >
              <EditIcon className="text-Neutral700" />
            </button>
            Invoice Details
          </h4>

          <div className="relative flex flex-col gap-1">
            <div className="flex flex-col items-end">
              <h3 className="text-xs text-Neutral600">Invoice Date</h3>
              {renderOnlyWhenAvailable(
                values.invoiceDate,
                <p className="text-xs leading-6">
                  {moment(values.invoiceDate).format("DD/MM/YYYY")}
                </p>
              )}
            </div>

            <div className="flex flex-col items-end">
              <h3 className="text-xs text-Neutral600">Due Date</h3>
              {renderOnlyWhenAvailable(
                values.dueDate,
                <p className="text-xs leading-6">
                  {moment(values.dueDate).format("DD/MM/YYYY")}
                </p>
              )}
            </div>

            {showClickerForInvoiceDetails && (
              <button
                className="absolute inset-0 z-10 text-sm font-semibold rounded-lg text-Neutral700 bg-Neutral300"
                onClick={() => handleFocused("invoiceDetails")}
              >
                + ADD
              </button>
            )}
          </div>
        </div>
      </div>

      {/* SECTION 3 */}
      <div className="flex flex-col gap-3">
        <div className="flex pb-1 border-b border-Neutral300">
          <h3
            // className="text-sm font-medium text-Neutral600 w-[40%]"
            className={cn("text-sm font-medium text-Neutral600", {
              "w-[60%]": hasFixedService,
              "w-[40%]": hasHourlyService,
            })}
          >
            Description
          </h3>

          <div
            className={cn("flex gap-2 w-[60%] justify-between ml-4", {
              "w-[40%]": hasFixedService,
              "w-[60%]": hasHourlyService,
            })}
          >
            {hasHourlyService && (
              <>
                <p className="flex-1 text-sm font-medium text-center text-Neutral600">
                  Hours
                </p>
                <p className="flex-1 text-sm font-medium text-center text-Neutral600">
                  Price/HR
                </p>
              </>
            )}
            {hasFixedService && (
              <p className="flex-1 text-sm font-medium text-center text-Neutral600">
                Price
              </p>
            )}
            <p className="flex-1 text-sm font-medium text-right text-Neutral600">
              Subtotal
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-2">
          {values?.services?.length ? (
            values?.services?.map((service, idx) => {
              const isFixedService = service.serviceType === "Fixed";
              const isHourlyService = service.serviceType === "Hourly";

              return (
                <div key={`[${service.serviceName}]:-:${idx}`} className="flex">
                  {/* Service name */}
                  <h3
                    className={cn(
                      "text-xs font-medium text-Neutral900 relative",
                      {
                        "w-[60%]": hasFixedService,
                        "w-[40%]": hasHourlyService,
                      }
                    )}
                  >
                    {service?.serviceName}
                  </h3>

                  {/* Service statistics */}
                  <div
                    className={cn("flex gap-2 w-[60%] justify-between ml-4", {
                      "w-[40%]": hasFixedService,
                      "w-[60%]": hasHourlyService,
                    })}
                  >
                    {/* Service hours */}
                    {hasHourlyService && (
                      <>
                        <p className="relative flex-1 text-xs font-medium text-center text-Neutral900">
                          {isHourlyService
                            ? service?.quantity
                            : hasFixedService
                            ? "-"
                            : null}
                        </p>
                        {/* Service price/hr */}
                        <p className="relative flex-1 text-xs font-medium text-center text-Neutral900">
                          {isHourlyService
                            ? currencyFormatter.format(service?.servicePrice)
                            : hasFixedService
                            ? "-"
                            : null}
                        </p>
                      </>
                    )}
                    {/* Service price */}
                    {hasFixedService && (
                      <p className="relative flex-1 text-xs font-medium text-center text-Neutral900">
                        {isFixedService
                          ? currencyFormatter.format(service?.servicePrice)
                          : hasHourlyService
                          ? "-"
                          : null}
                      </p>
                    )}
                    {/* Service subtotal */}
                    <p className="relative flex-1 text-xs font-medium text-right text-Neutral900">
                      {currencyFormatter.format(
                        isFixedService
                          ? service?.servicePrice
                          : service?.quantity * service?.servicePrice
                      )}
                    </p>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="flex" onClick={() => handleFocused("serviceStep")}>
              <h3 className="w-[50%] relative">
                ...
                <span className="absolute inset-0 flex items-center justify-center text-xs font-medium rounded cursor-pointer bg-Neutral300 text-Neutral600">
                  + ADD
                </span>
              </h3>

              <div className="flex gap-2 w-[50%] justify-between ml-4">
                <p className="relative flex-1">
                  ...
                  <span className="absolute inset-0 flex items-center justify-center text-xs font-medium rounded cursor-pointer bg-Neutral300 text-Neutral600">
                    + ADD
                  </span>
                </p>
                <p className="relative flex-1">
                  ...
                  <span className="absolute inset-0 flex items-center justify-center text-xs font-medium rounded cursor-pointer bg-Neutral300 text-Neutral600">
                    + ADD
                  </span>
                </p>
                <p className="relative flex-1">
                  ...
                  <span className="absolute inset-0 rounded cursor-pointer bg-Neutral300" />
                  <span className="absolute inset-0 flex items-center justify-center text-xs font-medium rounded cursor-pointer bg-Neutral300 text-Neutral600">
                    + ADD
                  </span>
                </p>
                <p className="relative flex-1">
                  ...
                  <span className="absolute inset-0 flex items-center justify-center text-xs font-medium rounded cursor-pointer bg-Neutral300 text-Neutral600">
                    + ADD
                  </span>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* SECTION 4 */}
      <div className="flex flex-col items-end gap-3 px-5 py-4 border-y-2 border-Neutral300">
        <div className="flex justify-between min-w-60">
          <h4 className="w-2/4 text-sm text-right text-Neutral700">Subtotal</h4>
          <p className="text-sm">{currencyFormatter.format(subtotal)}</p>
        </div>
        {values?.discount > 0 && (
          <div className="flex justify-between min-w-60">
            <h4 className="w-2/4 text-sm text-right text-Neutral700">
              Discount ({values?.discount}%)
            </h4>
            <p className="text-sm">
              {currencyFormatter.format(
                getDiscount(subtotal, values?.discount)
              )}
            </p>
          </div>
        )}
        {values?.is_VAT && (
          <div className="flex justify-between min-w-60">
            <h4 className="w-2/4 text-sm text-right text-Neutral700">
              VAT ({values?.vatPercentage}%)
            </h4>
            <p className="text-sm">
              {currencyFormatter.format(
                getVat(subtotal, values?.vatPercentage, values?.discount)
              )}
            </p>
          </div>
        )}
        {!values?.is_VAT &&
          !!values?.taxes?.length &&
          values?.taxes?.map((tax, idx) => (
            <div
              key={`${tax.taxName}:-:${idx + 1}`}
              className="flex justify-between min-w-60"
            >
              <h4 className="w-2/4 text-sm text-right text-Neutral700">
                {tax?.taxName} ({tax?.taxValue}%)
              </h4>
              <p className="text-sm">
                {currencyFormatter.format(
                  getVat(subtotal, tax?.taxValue, values?.discount)
                )}
              </p>
            </div>
          ))}
        <div className="flex justify-between mt-2 min-w-60">
          <h4 className="w-2/4 text-sm font-bold text-right uppercase">
            Total Due
          </h4>
          <p className="font-bold">
            {currencyFormatter.format(
              getTotalDue(
                subtotal,
                values?.discount,
                values?.vatPercentage,
                values?.taxes,
                values?.is_VAT
              )
            )}
          </p>
        </div>
      </div>

      {/* SECTION 5 */}
      <div className="flex flex-col -mt-4">
        {["Paypal", "Stripe"].includes(values.paymentMethod) ? (
          <Link
            to={values.paymentLink}
            target="_blank"
            rel="noreferrer"
            className="flex items-center self-end gap-2 text-sm font-bold underline"
          >
            <span>Pay here</span>
            {values.paymentMethod === "Paypal" ? (
              <PaypalIcon />
            ) : (
              <StripeIcon />
            )}
          </Link>
        ) : values.paymentMethod === "Bank" && values?.bankDetails ? (
          <div className="flex items-center gap-4 w-full md:max-w-[65%]">
            <div className="flex-grow p-4 space-y-2 border rounded-md">
              <h3 className="text-sm font-bold uppercase text-Neutral900">
                Bank Details
              </h3>
              <p className="text-sm whitespace-pre-wrap text-Neutral700">
                {values.bankDetails}
              </p>
            </div>
            <button
              className="p-2 rounded-full bg-Neutral-200 no-print"
              onClick={() => handleFocused("paymentInformation")}
            >
              <EditIcon className="text-Neutral700" />
            </button>
          </div>
        ) : (
          <button
            className="self-end px-3 py-2 text-sm text-right rounded text-AccentRegular bg-Neutral200"
            onClick={() => handleFocused("paymentInformation")}
          >
            Pay Here&nbsp;&nbsp;
            <span className="px-1.5 py-0.5 rounded font-semibold bg-Neutral300">
              {currency?.symbol || "$"}
            </span>
          </button>
        )}
      </div>

      {/* SECTION 6 */}
      {!isWhiteLabelSubscriber && !isCustom && (
        <div className="relative flex items-center justify-center">
          <div className="flex items-center justify-center flex-grow">
            <a
              href="https://www.clientmanager.io/"
              target="_blank"
              rel="noreferrer"
              className="text-Neutral800 text-[10px] font-normal inline-flex items-center"
            >
              <img
                src={Logo}
                alt="Logo"
                id="cm_logo"
                className="mr-[6px] h-5 w-5"
              />
              <span>
                Made with{" "}
                <span className="font-bold text-AccentRegular">
                  ClientManager
                </span>
              </span>
            </a>
          </div>

          <WhiteLabelButton
            title="Add custom logo"
            className="uppercase text-[10px] absolute right-0"
            onClick={() => setIsWhiteLabelFeaturesModalOpen(true)}
          />
        </div>
      )}

      {!isWhiteLabelSubscriber && !isCustom && (
        <FeaturesModal
          isOpen={isWhiteLabelFeaturesModalOpen}
          setIsOpen={setIsWhiteLabelFeaturesModalOpen}
          data={{
            title: "Customise what your clients see...",
            features: [
              "Reward this...",
              'Remove "Made with ClientManager"',
              "Use a custom URL",
              "And more...",
            ],
            onViewPlanClick: () => {
              navigate("/white-label/plans");
            },
          }}
        />
      )}
    </section>
  );
}

export default memo(A4Page);
