import React from "react";
import ArrowLeftIcon from "../../Icons/ArrowLeftIcon";

function ProjectArrowsControls({ scrollLeft, scrollRight, atStart, atEnd }) {
  return (
    <div className="ml-auto">
      <button
        aria-label="Scroll left"
        className={`text-AccentRegular mr-4 ${
          atStart ? "text-AccentMediumLight" : ""
        }`}
        onClick={scrollLeft}
        // disabled={atStart}
      >
        <ArrowLeftIcon color="currentColor" />
      </button>
      <button
        aria-label="Scroll right"
        className={`text-AccentRegular ${
          atEnd ? "text-AccentMediumLight" : ""
        }`}
        onClick={scrollRight}
        // disabled={atEnd}
      >
        <ArrowLeftIcon color="currentColor" rotated />
      </button>
    </div>
  );
}

export default ProjectArrowsControls;
