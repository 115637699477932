import { Menu, Transition } from "@headlessui/react";
import { Field, Form, Formik } from "formik";
import React from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import * as Yup from "yup";

import PhoneInput from "../../PhoneInput";

const validationSchema = Yup.object({
  platform: Yup.string().required("Selecting a meeting type is required"),
  link: Yup.string().when("platform", {
    is: (platform) => platform === "zoom" || platform === "google_meet",
    then: Yup.string().url("Enter a valid meeting link"),
    otherwise: Yup.string().notRequired(),
  }),
  custom_url: Yup.string().when("platform", {
    is: (platform) => platform === "custom_url",
    then: Yup.string().url("Enter a valid url"),
    otherwise: Yup.string().notRequired(),
  }),
  phone_number: Yup.string().when("platform", {
    is: "phone_call",
    then: Yup.string()
      .nullable()
      .test("isValid", "Invalid phone number", (value) => {
        if (!value) return true; // allows empty values
        return typeof value === "string" && isValidPhoneNumber(value);
      }),
  }),
});

const getPlaceholderText = (platform) => {
  switch (platform) {
    case "google_meet":
      return "Enter Google Meet link";
    case "zoom":
      return "Enter Zoom link";
    case "phone_call":
      return "Enter phone number";
    case "custom_url":
      return "Enter your custom URL";
    default:
      return "Enter the meeting URL";
  }
};

// Your StepThreeForm component
const StepThreeForm = ({
  onSubmit,
  formData,
  setFormData,
  isEditMode,
  currentStep,
}) => {
  const meetingTypes = [
    { name: "In Person", value: "in_person" },
    { name: "Zoom", value: "zoom" },
    { name: "Google Meet", value: "google_meet" },
    { name: "Custom Url", value: "custom_url" },
    { name: "Phone Call", value: "phone_call" },
  ]; // Add your meeting type options here

  return (
    <Formik
      initialValues={{
        platform: formData.platform || "",
        link: formData.link || "",
        phone_number: formData.phone_number || "",
        custom_url: formData.custom_url || "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        setFormData({ ...formData, ...values });
        onSubmit();
        actions.setSubmitting(false);
      }}
    >
      {({
        values,
        setFieldValue,
        errors,
        touched,
        setFieldError,
        handleBlur,
      }) => {
        const placeholderText = getPlaceholderText(values.platform);
        const selectedPlatform = meetingTypes.find(
          (type) => type.value === values.platform
        )?.name;

        return (
          <>
            <div className="text-[12px] text-center text-Neutral200 bg-AccentRegular leading-[18px] py-1 px-2 rounded-t-lg w-full">
              Zoom & Google Meet integration Coming Soon.
            </div>
            <Form className="bg-white p-8 rounded-b-lg shadow-lg min-w-[400px]">
              <div className="flex justify-between ">
                <h3
                  className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-8"
                  id="modal-title"
                >
                  {!isEditMode ? "CREATE" : "EDIT"} MEETING SCHEDULE
                </h3>
                <h3 className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 mb-8">
                  3/3
                </h3>
              </div>

              <div className="mb-5">
                <label
                  htmlFor="platform"
                  className="block text-xs font-bold text-Neutral900 mb-2 pl-[1px]"
                >
                  Meeting Type
                </label>

                <Menu
                  as="div"
                  className="relative inline-block w-full text-left"
                >
                  <Menu.Button className="flex items-center justify-between w-full px-4 py-2 text-sm font-medium bg-white border rounded border-Neutral300 text-Neutral800">
                    {selectedPlatform || "Select one"}
                    <svg
                      className="absolute right-[20px]"
                      width="11"
                      height="8"
                      viewBox="0 0 11 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.324856 1.63059C-0.206125 0.977077 0.258936 0 1.10097 0H9.89903C10.7411 0 11.2061 0.977078 10.6751 1.63059L6.27611 7.04478C5.87592 7.53733 5.12408 7.53733 4.72389 7.04478L0.324856 1.63059Z"
                        fill="#5A5A66"
                      />
                    </svg>
                  </Menu.Button>

                  <Transition
                    as={React.Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute z-10 w-full mt-2 origin-top-right bg-white rounded-md shadow-lg focus:outline-none">
                      {meetingTypes.map((type) => (
                        <Menu.Item key={type.value}>
                          {({ active }) => {
                            return (
                              <div
                                className={`${
                                  active ? "bg-Neutral200" : ""
                                } flex items-center px-4 py-2 cursor-pointer`}
                                onClick={() => {
                                  setFieldValue("platform", type.value);
                                  setFieldValue("link", "");
                                  setFieldValue("phone_number", "");
                                  setFieldValue("custom_url", "");
                                }}
                              >
                                {type.name}
                              </div>
                            );
                          }}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>

                {errors.platform && touched.platform ? (
                  <div className="mt-1 text-xs text-red-500">
                    {errors.platform}
                  </div>
                ) : null}
              </div>

              {values.platform === "phone_call" && (
                // <div className="mb-5">
                //   <label
                //     htmlFor="phone_number"
                //     className="block mb-2 text-xs font-bold text-Neutral900"
                //   >
                //     Phone number
                //   </label>
                //   <Field
                //     type="text"
                //     name="phone_number"
                //     as="input"
                //     className="w-full px-4 py-2 text-sm font-medium bg-white border rounded border-Neutral300 text-Neutral800 focus:outline-none focus:ring-2 focus:ring-AccentRegular"
                //     placeholder="Enter phone number"
                //   />
                //   {errors.phone_number && touched.phone_number ? (
                //     <div className="mt-1 text-xs text-red-500">{errors.phone_number}</div>
                //   ) : null}
                // </div>
                <div className="mb-5">
                  <div className="flex items-center justify-between">
                    <label className="inline h-auto text-xs font-bold text-Neutral900">
                      Phone Number
                    </label>
                    <span className="text-xs italic font-medium text-Neutral700">
                      Optional
                    </span>
                  </div>

                  <PhoneInput
                    name="phone_number"
                    value={values.phone_number}
                    setFieldValue={setFieldValue}
                    setFieldError={setFieldError}
                    handleBlur={handleBlur}
                    errors={errors}
                    touched={touched}
                    className="mt-2"
                  />
                </div>
              )}

              {values.platform === "custom_url" && (
                <div className="mb-5">
                  <label
                    htmlFor="custom_url"
                    className="block mb-2 text-xs font-bold text-Neutral900"
                  >
                    Custom URL
                  </label>
                  <Field
                    type="text"
                    name="custom_url"
                    as="input"
                    className="w-full px-4 py-2 text-sm font-medium bg-white border rounded border-Neutral300 text-Neutral800 focus:outline-none focus:ring-2 focus:ring-AccentRegular"
                    placeholder={placeholderText}
                  />
                  {errors.custom_url && touched.custom_url ? (
                    <div className="mt-1 text-xs text-red-500">
                      {errors.custom_url}
                    </div>
                  ) : null}
                </div>
              )}

              {(values.platform === "zoom" ||
                values.platform === "google_meet") && (
                <div className="mb-5">
                  <label
                    htmlFor="link"
                    className="block mb-2 text-xs font-bold text-Neutral900"
                  >
                    Meeting Link
                  </label>
                  <Field
                    type="text"
                    name="link"
                    as="input"
                    className="w-full px-4 py-2 text-sm font-medium bg-white border rounded border-Neutral300 text-Neutral800 focus:outline-none focus:ring-2 focus:ring-AccentRegular"
                    placeholder={placeholderText}
                  />
                  {errors.link && touched.link ? (
                    <div className="mt-1 text-xs text-red-500">
                      {errors.link}
                    </div>
                  ) : null}
                </div>
              )}

              <button
                type="submit"
                className="w-full p-3 text-sm font-bold transition duration-300 rounded text-Neutral100 bg-AccentRegular"
              >
                {currentStep === 3
                  ? !isEditMode
                    ? "Create schedule"
                    : "Edit schedule"
                  : !isEditMode
                  ? "Creating"
                  : "Editing"}
              </button>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default StepThreeForm;
