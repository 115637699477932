import axios from "axios";
import { toast } from "react-toastify";

export const getInvoiceById = (clientId, invoiceId, setIsLoading) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "SET_INVOICE", payload: { isLoading: true } });

      const { data } = await axios.get(
        `api/v1/invoice/get-invoice-by-client/${clientId}/${invoiceId}`
      );

      if (setIsLoading) {
        setIsLoading(false);
      }

      return dispatch({
        type: "SET_INVOICE",
        payload: { data: data.data },
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);

      return dispatch({
        type: "GET_INVOICE_ERROR",
        payload: error,
      });
    }
  };
};

export const createInvoice = (values, navigate, setErrors, setSubmitting) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `api/v1/invoice/create-invoice`,
        values
      );
      setSubmitting(false);
      toast.success("Invoice created successfully");
      navigate(
        `/admin/clients/manage/${values?.client_id}/invoice/${data.data[0]?._id}`
      );
      return dispatch({
        type: "INIT_INVOICE_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error.response?.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setErrors(err);
        setSubmitting(false);
        return dispatch({
          type: "INIT_INVOICE_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        setSubmitting(false);
        return dispatch({
          type: "INIT_INVOICE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const initInvoice = (value, setIsOpen, navigate, setErrors) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post("api/v1/invoice/init-invoice", value);
      setIsOpen(false);
      if (data.data._id) {
        navigate(`/admin/create-invoice/${value?.client_id}/${data.data._id}`);
      } else if (data?.data[0]?._id) {
        navigate(
          `/admin/create-invoice/${value?.client_id}/${data.data[0]._id}`
        );
      } else {
        navigate(`/admin/create-invoice/${value?.client_id}`);
      }
      toast.success("Invoice created successfully");
      return dispatch({
        type: "INIT_INVOICE_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error?.response?.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setErrors(err);
        return dispatch({
          type: "CLIENT_CREATE_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "CREATE_QUESTIONNAIRE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const updateInvoice = (
  invoiceId,
  values,
  navigate,
  setErrors,
  setSubmitting,
  currentStep,
  setCurrentStep,
  handlePaypal,
  handleStripe
) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.put(
        `api/v1/invoice/update-invoice/${invoiceId}`,
        values
      );

      if (currentStep <= 5) {
        if (
          data?.data?.paymentLink &&
          data?.data?.paymentMethod === "Paypal" &&
          handlePaypal
        ) {
          const link = await handlePaypal();
          if (link) setCurrentStep(currentStep + 1);
        }
        if (
          data?.data?.paymentLink &&
          data?.data?.paymentMethod === "Stripe" &&
          handleStripe
        ) {
          const link = await handleStripe();
          if (link) setCurrentStep(currentStep + 1);
        } else {
          setCurrentStep(currentStep + 1);
          if (setSubmitting) setSubmitting(false);
        }
      }

      if (currentStep <= 5) {
        toast.success("Step saved successfully");
      } else {
        toast.success(data?.message);
      }

      // if (currentStep === 6) {
      //   navigate(
      //     `/admin/clients/manage/${values?.client}/invoice/${data.data?._id}`
      //   );
      // }

      if (setSubmitting) setSubmitting(false);

      return dispatch({
        type: "UPDATE_INVOICE_SUCCESS",
        payload: data.data,
      });
    } catch (error) {
      if (error.status === 422) {
        toast.error(error?.response?.data?.errors[0]?.msg);
        let err = {};
        error?.response?.data?.errors?.forEach((element) => {
          err[element.param] = element.msg;
        });
        if (setSubmitting) setSubmitting(false);
        setErrors(err);
        return dispatch({
          type: "UPDATE_TEMPLATE_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.errors?.msg);
        if (setSubmitting) setSubmitting(false);
        return dispatch({
          type: "UPDATE_TEMPLATE_ERROR",
          payload: error?.response?.errors?.msg,
        });
      }
    }
  };
};

export const updateInvoiceSeries = (
  invoiceId,
  values,
  navigate,
  setErrors,
  setSubmitting,
  currentStep,
  setCurrentStep
) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.put(
        `api/v1/invoice/update-invoice-series/${invoiceId}`,
        values
      );
      if (setSubmitting) setSubmitting(false);
      // if (currentStep < 5) {
      //   setCurrentStep(currentStep + 1);
      // }
      if (currentStep === 5) {
        navigate(
          `/admin/clients/manage/${values?.client}/invoice/${data.data[0]?._id}`
        );
      }

      return dispatch({
        type: "UPDATE_INVOICE_SERIES_SUCCESS",
        payload: data.data,
      });
    } catch (error) {
      if (error.status === 422) {
        toast.error(error?.response?.data?.errors[0]?.msg);
        let err = {};
        error?.response?.data?.errors?.forEach((element) => {
          err[element.param] = element.msg;
        });
        if (setSubmitting) setSubmitting(false);
        setErrors(err);
        return dispatch({
          type: "UPDATE_TEMPLATE_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.errors?.msg);
        if (setSubmitting) setSubmitting(false);
        return dispatch({
          type: "UPDATE_TEMPLATE_ERROR",
          payload: error?.response?.errors?.msg,
        });
      }
    }
  };
};

export const deleteInvoice = (invoiceId, setIsLoading, navigate) => {
  return async (dispatch) => {
    try {
      await axios.post(`api/v1/invoice/delete-invoice`, {
        id: invoiceId,
      });
      if (navigate) navigate(`/admin/invoices`);
      if (setIsLoading)
        setIsLoading({
          status: false,
          id: null,
        });
      toast.success("Invoice deleted successfully");
      return dispatch({
        type: "DELETE_INVOICE_SUCCESS",
        payload: { id: invoiceId },
      });
    } catch (error) {
      if (setIsLoading)
        setIsLoading({
          status: false,
          id: null,
        });
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "DELETE_INVOICE_ERROR",
        payload: error,
      });
    }
  };
};

export const deleteInvoiceSeries = (invoiceId, setIsLoading, navigate) => {
  return async (dispatch) => {
    try {
      await axios.delete(`api/v1/invoice/delete-invoice-series/${invoiceId}`);
      if (navigate) navigate(`/admin/invoices`);
      if (setIsLoading)
        setIsLoading({
          status: false,
          id: null,
        });
      toast.success("Invoice deleted successfully");
      return dispatch({
        type: "DELETE_INVOICE_SUCCESS",
        payload: { id: invoiceId },
      });
    } catch (error) {
      if (setIsLoading)
        setIsLoading({
          status: false,
          id: null,
        });
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "DELETE_INVOICE_ERROR",
        payload: error,
      });
    }
  };
};

export const getInvoiceListByClientId = (clientId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `api/v1/invoice/get-invoice-by-client/${clientId}`
      );
      return dispatch({
        type: "SET_INVOICE_LIST_BY_CLIENT",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "SET_INVOICES_LIST_ERROR",
        payload: error,
      });
    }
  };
};

export const getInvoiceList = ({
  page,
  perPage,
  search,
  sortColumn,
  sortType,
}) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `api/v1/invoice/get-invoice-list?page=${page}&limit=${perPage}&search=${search}&sortColumn=${sortColumn}&sortType=${sortType}`
      );
      return dispatch({
        type: "SET_INVOICE_LIST",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "SET_INVOICES_LIST_ERROR",
        payload: error,
      });
    }
  };
};

export const GetPublicInvoiceByClientId = (clientId, invoiceId) => {
  return async () => {
    try {
      const { data } = await axios.get(
        `api/v1/invoice/get-invoice-by-client-public/${clientId}/${invoiceId}`
      );
      return {
        data: data.data,
        brandData: data.brandIdentity,
        customDomain: data.customDomain,
      };
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return (window.location.href = "https://www.clientmanager.io/");
      } else {
        toast.error(error?.response?.data?.message);
        return (window.location.href = "https://www.clientmanager.io/");
      }
    }
  };
};

export const ChangeInvoiceStatusAction = ({ id, status }, haveError) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `api/v1/invoice/change-invoice-status`,
        {
          id,
          status,
        }
      );
      return dispatch({
        type: "UPDATE_INVOICE_STATUS",
        payload: { isLoading: false, isUpdating: false, data: data.data },
      });
    } catch (error) {
      haveError();
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "CLIENT_DETAIL_GET_ERROR",
          payload: error,
        });
      } else {
        return dispatch({
          type: "CLIENT_DETAIL_GET_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const ChangeInvoiceStatusViewedAction = ({ status, id }) => {
  return async () => {
    try {
      await axios.post(`api/v1/invoice/viewed-status`, {
        status,
        id,
      });
      return;
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return;
      } else {
        return;
      }
    }
  };
};

export const UploadExistingInvoice = (
  values,
  reloadInvoice,
  setSubmitting,
  closeModal,
  setErrors
) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `api/v1/invoice/upload-existing-invoice`,
        values,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setSubmitting(false);
      closeModal();
      if (reloadInvoice) reloadInvoice();
      return dispatch({
        type: "SET_INVOICE",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setErrors(err);
        setSubmitting(false);
        return dispatch({
          type: "SET_INVOICE_LIST_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        setSubmitting(false);
        return dispatch({
          type: "SET_INVOICES_LIST_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const updateExistingInvoice = (
  values,
  reloadInvoice,
  setSubmitting,
  setErrors,
  closeModal
) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `api/v1/invoice/update-existing-invoice`,
        values,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      closeModal();
      setSubmitting(false);
      reloadInvoice();
      return dispatch({
        type: "UPDATE_INVOICE_BY_CLIENT",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setErrors(err);
        setSubmitting(false);
        return dispatch({
          type: "SET_INVOICE_LIST_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        setSubmitting(false);
        return dispatch({
          type: "SET_INVOICE_LIST_ERROR",
          payload: error,
        });
      }
    }
  };
};

export function GetInvoiceStatus() {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "api/v1/invoice-status/get-invoice-status-list"
      );
      dispatch({ type: "GET_INVOICE_STATUS", payload: data });
    } catch (err) {
      console.log(err);
    }
  };
}

export function UpdateInvoiceStatusList(newStatus) {
  return async (dispatch) => {
    try {
      dispatch({ type: "UPDATE_INVOICE_STATUS_LIST", payload: newStatus });
    } catch (err) {
      console.log(err);
    }
  };
}

export function PaypalOrderCreate(invoiceId, setLoadingPaymentLink) {
  return async (dispatch) => {
    try {
      setLoadingPaymentLink(true);
      const res = await axios.post("api/v1/paypal/createOrder", {
        invoice_id: invoiceId,
      });
      dispatch({
        type: "NEW_PAYMENT_LINK",
        payload: {
          paymentLink: res.data.payPalPaymentLink,
          paymentMethod: "Paypal",
        },
      });
      toast.success("Created payment link successfully!");
      setLoadingPaymentLink(false);
      return res.data.payPalPaymentLink;
    } catch (error) {
      setLoadingPaymentLink(false);
      toast.error(error.response.data?.message);
      return error;
    }
  };
}

export function stripeOrderCreate(invoiceId, setLoadingPaymentLink) {
  return async (dispatch) => {
    try {
      setLoadingPaymentLink(true);
      const res = await axios.post("api/v1/stripe/createPaymentLink", {
        invoice_id: invoiceId,
      });
      dispatch({
        type: "NEW_PAYMENT_LINK",
        payload: {
          paymentLink: res.data.stripePaymentLink,
          paymentMethod: "Stripe",
        },
      });
      toast.success("Created payment link successfully!");
      setLoadingPaymentLink(false);
      return res.data.stripePaymentLink;
    } catch (error) {
      setLoadingPaymentLink(false);
      toast.error(error.response.data?.message);
      return error;
    }
  };
}

export function PaypalOrderUpdate(invoiceId, orederId) {
  return async (dispatch) => {
    try {
      const res = await axios.post("api/v1/paypal/updateOrder", {
        invoiceId: invoiceId,
        orederId: orederId,
      });
      toast.success("Updated payment link successfully!");
      return res.data.payPalPaymentLink;
    } catch (error) {
      return error;
    }
  };
}

export function LoginWithPaypal(
  code,
  searchParams,
  setSearchParams,
  user,
  setLoadingPaymentLink
) {
  return async (dispatch) => {
    try {
      setLoadingPaymentLink(true);
      const result = await axios.post("api/v1/paypal/token", {
        code: code,
      });

      searchParams.delete("code");
      searchParams.delete("scope");
      setSearchParams(searchParams);
      setLoadingPaymentLink(false);

      dispatch({
        type: "SET_USER_DATA",
        payload: {
          data: { ...user, payPalInfo: result?.data },
        },
      });

      setLoadingPaymentLink(false);
    } catch (error) {
      searchParams.delete("code");
      searchParams.delete("scope");
      setSearchParams(searchParams);
      setLoadingPaymentLink(false);
      toast.error(error?.response?.data?.message || "Something went wrong");

      return error;
    }
  };
}

export function LoginWithStripe(
  code,
  searchParams,
  setSearchParams,
  user,
  setLoadingStripeLink
) {
  return async (dispatch) => {
    try {
      setLoadingStripeLink(true);
      const result = await axios.post("api/v1/stripe/token", {
        grant_type: "authorization_code",
        code: code,
      });

      searchParams.delete("code");
      searchParams.delete("scope");
      searchParams.delete("state");
      setSearchParams(searchParams);
      setLoadingStripeLink(false);
      dispatch({
        type: "SET_USER_DATA",
        payload: {
          data: { ...user, stripeInfo: result?.data.oauth },
        },
      });

      setLoadingStripeLink(false);
    } catch (error) {
      searchParams.delete("code");
      searchParams.delete("scope");
      searchParams.delete("state");
      setSearchParams(searchParams);
      setLoadingStripeLink(false);
      toast.error(error?.response?.data?.message || "Something went wrong");

      return error;
    }
  };
}
