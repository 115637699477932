import React from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import Avatar from "../../assets/Images/Avatar.svg";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const AppsumoSignupSuccessModal = ({ isOpen }) => {
  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="Payment Modal"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="bg-white px-6 xs:px-12 pt-8 pb-10 rounded-[8px] w-full">
          <div className="flex items-center justify-between border-b border-Neutral200 w-full">
            <span className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2">
              AppSumo licence key activated
            </span>
          </div>
          <div className="my-6 flex items-center justify-center mb-8">
            <img src={Avatar} alt="" />
          </div>
          <div className="flex items-center justify-center w-[354px] mb-8">
            <span className="text-center text-sm font-normal text-Neutral800">
              You will be redirected to login page, login with your credentials
              to enjoy Client Manager.
            </span>
          </div>
          <Link
            to="/signin"
            className="btn button-hover form-primary-btn relative text-sm text-center block text-Neutral100 bg-AccentRegular font-bold w-full py-4 rounded drop-shadow-Texts overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out"
          >
            <span className="relative z-10">Sign In</span>
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default AppsumoSignupSuccessModal;
