const colorLevels = [800, 700, 600, 500, 400, 300, 200, 100];

export const getTagColor = (color, index, reverse = false) => {
  const colorLevelsFormatted = reverse ? colorLevels.reverse() : colorLevels;
  const colorLevel = index % colorLevelsFormatted.length;
  const bgColor = colorLevelsFormatted[colorLevel];

  if (colorLevel >= colorLevelsFormatted.length - 3) {
    return { bgColor: `${color}${bgColor}`, text: `${color}800` };
  }

  return { bgColor: `${color}${bgColor}`, text: "Neutral000" };
};
