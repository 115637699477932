import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import ReactTooltip from "react-tooltip";

import CloudLoaderIcon from "../../../../../assets/Images/Cloud-loader.svg";
import { UpdateServiceActions } from "../../../../../store/actions/serviceAction";
import {
  CreateTaskActions,
  UpdateTaskActions,
} from "../../../../../store/actions/tasksAction";
import AssignedNew from "../../components/AssignedNew";
import CategoryTags from "../../components/CategoryTags";
import Comment from "../../components/Comment";
import DescriptionNew from "../../components/DescriptionNew";
import DueDateNew from "../../components/DueDateNew";
import Media from "../../components/Media";
import StatusAndPriority from "../../components/StatusAndPriority";
import Subtasks from "../../components/Subtasks";
import CustomFields from "./CustomFields";

const TaskMainContent = ({
  clientDetail,
  types,
  isFullSize = false,
  item,
  project,
  isNew = false,
  cardId,
  setIsOpenTimer,
  setSelectedTask,
  setTaskId,
  setNewTask,
  setEditableTitle,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);

  const [editable, setEditable] = useState(null);
  const [callApi, setCallApi] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    if (item) {
      setEditable(item);
      setCustomFields(item.custom_fields || []);
    }
  }, [item]);

  useEffect(() => {
    if (editable && setEditableTitle) {
      setEditableTitle(editable.title);
    }
  }, [editable, setEditableTitle]);

  const debouncedChangeHandler = useRef(
    debounce((editData) => {
      if (types === "task") {
        dispatch(UpdateTaskActions(editData, setCallApi));
      }
      if (types === "service") {
        dispatch(UpdateServiceActions(editData, setCallApi));
      }
    }, 1000)
  ).current;

  const debouncedCreateHandler = useRef(
    debounce((editData) => {
      if (editData?.title?.length === 0) return;
      setUnsavedChanges(true);
      dispatch(
        CreateTaskActions(
          {
            title: editData?.title,
            client_id: clientDetail?._id,
            project: project?._id,
            type: "GENERAL",
            status: "to_do",
            tag: "not_started",
            column: project?.task_column[0]?.name,
            custom_fields: customFields,
          },
          setTaskId,
          setCallApi,
          setUnsavedChanges,
          setNewTask
        )
      );
    }, 1000)
  ).current;

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedEditable = {
      ...editable,
    };

    if (name.includes(".")) {
      const [parent, child] = name.split(".");
      updatedEditable[parent] = { ...updatedEditable[parent], [child]: value };
    } else {
      updatedEditable[name] = value;
    }

    setEditable(updatedEditable);

    if (isNew && !cardId) {
      debouncedCreateHandler(updatedEditable);
    } else {
      debouncedChangeHandler(updatedEditable);
    }
  };

  return (
    <div
      className={`bg-white rounded-lg rounded-tl-none sm:max-h-full
      ${
        isFullSize
          ? "h-full w-[1024px] mt-[114px] ml-[56px] mr-[0]"
          : "shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3) sm:w-full"
      }
      `}
    >
      <div className="flex flex-col lg:flex-row">
        <div
          className={`w-full py-3 flex flex-col gap-8 sm:py-[12px] px-[32px] px-[${
            isFullSize ? "60px" : "32px"
          }]`}
        >
          <div className="relative flex flex-col">
            <div
              className="w-full"
              data-for="task-title-Tooltip"
              data-tip={item?.title}
            >
              {!editable && !isNew ? (
                <p
                  className="text-Neutral900 w-full text-[32px] font-medium focus:outline-none truncate"
                  onClick={(e) => {
                    if (
                      clientDetail?.is_admin ||
                      clientDetail?.is_manager ||
                      user?._id === item?.created_by?._id
                    ) {
                      setEditable(item);
                    }
                  }}
                >
                  {item?.title}
                </p>
              ) : (
                <input
                  className="text-Neutral900 text-[32px] w-full truncate font-semibold focus:outline-none placeholder:text-[20px] placeholder:text-Neutral400"
                  type="text"
                  name="title"
                  placeholder="Add Task Title"
                  disabled={callApi ? true : false}
                  value={editable?.title}
                  autoFocus={isNew ? true : false}
                  onChange={handleChange}
                />
              )}

              <ReactTooltip
                id="task-title-Tooltip"
                key={item?._id}
                place="bottom"
                effect="solid"
                backgroundColor="#6D6DF2"
              />
            </div>

            <div className="flex items-center justify-between">
              <div className="flex flex-col">
                <CategoryTags
                  isNew={isNew}
                  unsavedChanges={unsavedChanges}
                  item={item}
                  clientDetail={clientDetail}
                  user={user}
                  types={types}
                  project={project}
                />

                <p className="text-Neutral600 pt-1 text-[12px] font-semibold focus:outline-none truncate max-w-[400px] cursor-default">
                  Added By: {item?.created_by?.user_name}
                </p>
              </div>

              <div className="flex items-center gap-3">
                <div className="relative">
                  <div>
                    {unsavedChanges ? (
                      <span className="text-[#757580] text-xs font-semibold leading-4 ml-2.5 flex items-center">
                        <img
                          src={CloudLoaderIcon}
                          alt=""
                          className="mr-1 w-[35px]"
                        />
                        Saving...
                      </span>
                    ) : (
                      <StatusAndPriority
                        isNew={isNew}
                        item={item}
                        types={types}
                        isVertical={true}
                        project={project}
                      />
                    )}
                  </div>
                </div>
                <div className="z-10 flex items-center">
                  <div
                    className={`cursor-pointer text-AccentRegular ${
                      user?.time_tracker?.task_id === item?._id ? "hidden" : ""
                    }`}
                    onClick={(e) => {
                      if (user?.time_tracker?.task_id) return;
                      if (isNew) return;

                      e.stopPropagation();
                      setIsOpenTimer(true);
                      setSelectedTask(item);
                      navigate({ search: "" });
                    }}
                    data-tip={
                      user?.time_tracker?.task_id
                        ? "Timer is already running. Please stop it first."
                        : "Start timer for this task."
                    }
                    data-for="timer"
                  >
                    <svg
                      className="w-[25px] h-[25px]"
                      viewBox="0 0 48 49"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="2"
                        y="2.5"
                        width="44"
                        height="44"
                        rx="22"
                        fill="currentColor"
                      />
                      <path
                        d="M20 16.5L32 24.5L20 32.5V16.5Z"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <ReactTooltip
                    id="timer"
                    place="bottom"
                    effect="solid"
                    backgroundColor="currentColor"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className={`flex flex-col gap-3 relative ${
              !isFullSize
                ? "overflow-y-auto scrollbar-hide h-[27vh] sm:h-[50vh] 2xl:h-[60vh]"
                : ""
            }`}
          >
            <div className="flex flex-col gap-3 sm:flex-row">
              <AssignedNew
                isNew={isNew}
                member={clientDetail?.members}
                item={item}
                user={user}
                types={types}
                clientDetail={clientDetail}
              />

              <DueDateNew
                isNew={isNew}
                item={item}
                user={user}
                types={types}
                clientDetail={clientDetail}
              />
            </div>

            <div className="flex flex-col gap-3 sm:flex-row">
              <div className="flex flex-col w-full sm:w-[353px] gap-3">
                <DescriptionNew
                  isNew={isNew}
                  item={editable}
                  user={user}
                  types={types}
                  clientDetail={clientDetail}
                  // description={description}
                />

                <Media
                  isNew={isNew}
                  item={item}
                  user={user}
                  types={types}
                  clientDetail={clientDetail}
                />
              </div>

              <div className="flex flex-col gap-4">
                <Subtasks
                  isNew={isNew}
                  item={item}
                  user={user}
                  types={types}
                  clientDetail={clientDetail}
                />

                <CustomFields
                  item={editable}
                  customFields={customFields}
                  setCustomFields={setCustomFields}
                  project={project}
                  isNew={isNew}
                  cardId={cardId}
                  debouncedCreateHandler={debouncedCreateHandler}
                  debouncedChangeHandler={debouncedChangeHandler}
                />
              </div>
            </div>
          </div>
        </div>

        <Comment
          isNew={isNew}
          element={item}
          task_id={item?._id}
          clientDetail={clientDetail}
          types={types}
          isFullSize={isFullSize}
        />
      </div>
    </div>
  );
};

export default TaskMainContent;
