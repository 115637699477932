import React from "react";

const ExternalLinkIcon = ({
  width = 14,
  color = "var(--accent-color)",
  className = "",
}) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10.889 7.64861V11.5375C10.889 11.8813 10.7524 12.211 10.5093 12.4541C10.2662 12.6972 9.93647 12.8338 9.59267 12.8338H2.46304C2.11925 12.8338 1.78953 12.6972 1.54642 12.4541C1.30332 12.211 1.16675 11.8813 1.16675 11.5375V4.40787C1.16675 4.06407 1.30332 3.73435 1.54642 3.49125C1.78953 3.24815 2.11925 3.11157 2.46304 3.11157H6.35193"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.94458 1.16675H12.8335V5.05564"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.70386 8.29638L12.8335 1.16675"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ExternalLinkIcon;
