import React from "react";
import { useState, useEffect, useRef } from "react";
import TasksBox from "./Tasks/TasksBox";
import AdminNavbar from "../Navbars/AdminNavbar";

const CommanTaskListing = (props) => {
  const wrapperTodoRef = useRef(null);
  const wrapperDoingRef = useRef(null);
  const wrapperDoneRef = useRef(null);

  const {
    pageTitle,
    backLine,
    switchon,
    switchhoff,
    DoneLists,
    TodoLists,
    DoingLists,
    listLable,
    clientId,
  } = props;
  const [isGeneral, setIsGeneral] = useState(true);
  const [TodoListsData, setTodoListsData] = useState(TodoLists);
  const [DoingListsData, setDoingListsData] = useState(DoingLists);
  const [DoneListsData, setDoneListsData] = useState(DoneLists);
  const [isAddDisabledForTodo, setIsAddDisabledForTodo] = useState(false);

  const AddTempTaskToList = (status) => {
    if (
      status === "to_do" &&
      !TodoListsData.find((list) => list.new || !list.title.trim().length)
    ) {
      setTodoListsData([
        ...TodoListsData,
        {
          id: TodoListsData.length + 1,
          new: true,
          status: "to_do",
          title: "",
          tag: null,
          type: isGeneral ? "general" : "monthly",
        },
      ]);
    }
    if (
      status === "doing" &&
      !DoingListsData.find((list) => list.new || !list.title.trim().length)
    ) {
      setDoingListsData([
        ...DoingListsData,
        {
          id: DoingListsData.length + 1,
          new: true,
          status: "doing",
          title: "",
          tag: null,
          type: isGeneral ? "general" : "monthly",
        },
      ]);
    }
    if (
      status === "done" &&
      !DoneListsData.find((list) => list.new || !list.title.trim().length)
    ) {
      setDoneListsData([
        ...DoneListsData,
        {
          id: DoneListsData.length + 1,
          new: true,
          status: "done",
          title: "",
          tag: null,
          type: isGeneral ? "general" : "monthly",
        },
      ]);
    }
  };

  useEffect(() => {
    setIsAddDisabledForTodo(!!TodoListsData.find((list) => list.new));
  }, [TodoListsData]);

  useEffect(() => {
    let getTodoData = TodoLists.filter(
      (list) => list.type === (isGeneral ? "general" : "monthly")
    );
    let getDoingData = DoingLists.filter(
      (list) => list.type === (isGeneral ? "general" : "monthly")
    );
    let getDoneData = DoneLists.filter(
      (list) => list.type === (isGeneral ? "general" : "monthly")
    );
    setDoingListsData([...getDoingData]);
    setTodoListsData([...getTodoData]);
    setDoneListsData([...getDoneData]);
  }, [isGeneral, TodoLists, DoingLists, DoneLists]);

  return (
    <div>
      <AdminNavbar
        link={`/admin/clients/manage/${clientId}`}
        title={pageTitle}
        backLink={backLine}
      />
      <div className="5xl:px-[96px] px-6 md:px-12 w-full pt-0">
        <div>
          <div className="bg-white border rounded-full  cursor-pointer text-Neutral500 flex 5xl:max-w-[200px] max-w-[158px] w-full mt-[34px] h-[32px]">
            <div
              onClick={() => setIsGeneral(true)}
              className={`transition duration-300 flex items-center justify-center w-full max-w-[80px] min-w-[80px] 5xl:min-w-[100px] text-center rounded-full leading-4 transform text-xs ease-in-out mt-[-1px] mb-[-1px] ml-[-1px] ${
                isGeneral
                  ? "checkmarkicon text-white mt-[-1px] mb-[-1px] ml-[-1px] drop-shadow-Texts"
                  : ""
              }`}
            >
              {switchon}
            </div>
            <div
              onClick={() => setIsGeneral(false)}
              className={`transition duration-300 flex items-center justify-center w-full max-w-[80px] min-w-[80px] 5xl:min-w-[100px] text-center rounded-full transform text-xs ease-in-out ${
                !isGeneral
                  ? "checkmarkicon text-white mt-[-1px] mb-[-1px] ml-[-1px] drop-shadow-Texts"
                  : ""
              }`}
            >
              {switchhoff}
            </div>
          </div>
          <div className="flex flex-wrap mt-10 w-full 5xl:max-w-[1340px] lg:max-w-[944px] max-w-[700px] justify-between">
            <div
              className="5xl:max-w-[400px] lg:max-w-[288px] lg:w-full md:w-[48%] w-full"
              ref={wrapperTodoRef}
            >
              <h3 className="text-black text-xs font-bold mb-5">
                {listLable[0]}
              </h3>

              {TodoListsData.map((item, index) => {
                return (
                  <TasksBox
                    key={index}
                    index={index}
                    generalName={isGeneral ? "general" : "monthly"}
                    name="to_do"
                    item={item}
                  />
                );
              })}
              <button
                className="flex justify-start items-baseline w-full py-[14px] pl-4 pr-1 border border-Neutral300 rounded-lg cursor-pointer"
                disabled={isAddDisabledForTodo}
                onClick={() => AddTempTaskToList("to_do")}
              >
                <div className="font-semibold text-sm max-w-[188px] w-full text-left text-AccentRegular cursor-pointer">
                  Add Task
                </div>
              </button>
            </div>
            <div
              className="5xl:max-w-[400px] lg:max-w-[288px] lg:w-full md:w-[48%] w-full md:mt-0 mt-4"
              ref={wrapperDoingRef}
            >
              <h3 className="text-black text-xs font-bold mb-5">
                {listLable[1]}
              </h3>
              {DoingListsData.map((item, index) => {
                return (
                  <TasksBox
                    key={index}
                    index={index}
                    generalName={isGeneral ? "general" : "monthly"}
                    name="doing"
                    item={item}
                  />
                );
              })}
              <div
                className="flex justify-between items-baseline py-[14px] pl-4 pr-1 border border-Neutral300 rounded-lg cursor-pointer"
                onClick={() => AddTempTaskToList("doing")}
              >
                <div className="font-semibold text-sm max-w-[188px] w-full text-AccentRegular cursor-pointer">
                  Add Task
                </div>
              </div>
            </div>
            <div
              className="5xl:max-w-[400px] lg:max-w-[288px] lg:w-full md:w-[48%] w-full lg:mt-0 mt-4"
              ref={wrapperDoneRef}
            >
              <h3 className="text-black text-xs font-bold mb-5">
                {listLable[2]}
              </h3>
              {DoneListsData.map((item, index) => {
                return (
                  <TasksBox
                    key={index}
                    index={index}
                    generalName={isGeneral ? "general" : "monthly"}
                    name="done"
                    item={item}
                  />
                );
              })}
              <div
                className="flex justify-between items-baseline py-[14px] pl-4 pr-1 border border-Neutral300 rounded-lg cursor-pointer "
                onClick={() => AddTempTaskToList("done")}
              >
                <div className="font-semibold text-sm max-w-[188px] w-full text-AccentRegular cursor-pointer">
                  Add Task
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommanTaskListing;
