const initialState = {
  proposalList: {
    isLoading: true,
    data: {
      data: [],
      totalRecord: 0,
      perPage: 10,
      totalPage: 0,
      currentPage: 0,
      fromRecord: 0,
      hasPrevPage: false,
      hasNextPage: false,
    },
  },
  clientProposalList: {
    isLoading: true,
    data: [],
  },
  clientProposalStatusList: {
    isLoading: true,
    data: [],
  },
};

const proposalReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_PROPOSALS_LIST":
      return {
        ...state,
        proposalList: {
          ...state.proposalList,
          ...payload,
        },
      };
    case "SET_PROPOSALS_LIST_BY_CLIENT":
      return {
        ...state,
        clientProposalList: {
          ...state.clientProposalList,
          ...payload,
        },
      };
    case "SET_PROPOSAL":
      return {
        ...state,
        clientProposalList: {
          ...state.clientProposalList,
          data: [...state.clientProposalList.data, payload.data],
        },
      };
    case "UPDATE_PROPOSAL_BY_CLIENT":
      let tempProposal = state.clientProposalList.data;
      let index = tempProposal.findIndex((e) => e._id === payload.data._id);
      tempProposal[index] = payload.data;
      return {
        ...state,
        clientProposalList: {
          ...state.clientProposalList,
          data: tempProposal,
        },
      };

      case "UPDATE_FOLLOWUP_EMAIL_PROPOSAL":
        const remainingproposalList = state.proposalList.data.docs.find(
          (invoice) => invoice._id === payload._id
        );
        const updatedInvoic = state.proposalList.data.docs.map((invoice) =>
          invoice._id === remainingproposalList._id
            ? { ...invoice, auto_email_followUp: payload.auto_email_followUp }
            : invoice
        );
        return {
          ...state,
          invoice: remainingproposalList,
          proposalList: {
            ...state.proposalList,
            data: {
              docs: updatedInvoic,
            },
          },
        };
    case "DELETE_PROPOSAL_BY_CLIENT":
      const proposalList = state.clientProposalList.data.filter(
        (proposal) => proposal._id !== payload.id
      );
      return {
        ...state,
        clientProposalList: {
          ...state.clientProposalList,
          data: proposalList,
        },
      };
    case "INIT_PROPOSAL_SUCCESS":
      return {
        ...state,
        clientProposalList: {
          ...state.clientProposalList,
          data: [...state.clientProposalList.data, payload],
        },
      };
    case "GET_PROPOSAL_STATUS_LIST":
      return {
        ...state,
        clientProposalStatusList: {
          ...state.clientProposalStatusList,
          data: payload.data,
        },
      };
    default:
      return state;
  }
};

export default proposalReducer;
