import React from "react";
import ThreeStars from "../../assets/Images/ThreeStars.svg";

function InvoiceNoDataContent() {
  return (
    <div className="absolute top-[55%] -translate-y-[50%] left-[50%] -translate-x-[50%] w-full max-w-[196px]">
      <div className="relative">
        <img
          className="absolute top-[-40px] left-[50%] -translate-x-[50%]"
          src={ThreeStars}
          alt=""
        />
        <h5 className="text-sm font-normal font-kalam text-center">
          Stay on top of your finances by keeping track of your invoices
        </h5>
      </div>
    </div>
  );
}

export default InvoiceNoDataContent;
