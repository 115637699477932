const prepareTasksAndTimeCounter = (
  number = null,
  isHoursAndMinutes = false
) => {
  if (typeof number === "string") {
    const parsedNumber =
      number.charAt(0) === "0"
        ? parseInt(number.charAt(1), 10)
        : parseInt(number, 10);
    if (!isNaN(parsedNumber)) {
      number = parsedNumber;
    } else {
      return isHoursAndMinutes ? "00" : "0";
    }
  }
  if (number === null || number === 0) {
    return isHoursAndMinutes ? "00" : "0";
  }
  return number < 10
    ? isHoursAndMinutes
      ? `0${number}`
      : `${number}`
    : `${number}`;
};

export default prepareTasksAndTimeCounter;
