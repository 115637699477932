import { useCallback, useEffect, useState } from "react";

const useGetBrandIdentityForMeeting = (id) => {
  const [brandIdentity, setBrandIdentity] = useState({
    bgColor: "",
    accentColor: "",
    companyName:null,
    logo: null,
    status: "",
  });

  const getBrandIdentity = useCallback(async () => {
    const BASE_URL = process.env.REACT_APP_API_URL + "api/v1";

    try {
      const response = await fetch(
        `${BASE_URL}/brand-identity/get-brand-identity?type=vanity_path&id=${id}`
      );

      const {
        success,
        data: { bg_color, accent_color, companyName, logo, status },
      } = await response.json();

      setBrandIdentity((prev) => ({
        ...prev,
        bgColor: bg_color,
        accentColor: accent_color,
        companyName: companyName,
        logo,
        status,
      }));

      if (success) {
        document.documentElement.style.setProperty("--bg-color", bg_color);
        document.documentElement.style.setProperty(
          "--accent-color",
          accent_color
        );
      }
    } catch (err) {
      console.error(err);
    }
  }, [id]);

  useEffect(() => {
    getBrandIdentity();
  }, [getBrandIdentity]);

  return brandIdentity;
};

export default useGetBrandIdentityForMeeting;
