import React from "react";
import CloseBtn from "../../assets/Images/close-btn.svg";
import * as Yup from "yup";
import Modal from "react-modal";
import { Formik } from "formik";
import { UserPlanCancle } from "../../store/actions/authAction";
import { useDispatch } from "react-redux";
import loader from "../../assets/Images/loader-icon.svg";
// import ButtonLoader from "../Loaders/ButtonLoader";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const canceldata = [
  { tage: "Too expensive", id: "marking_1" },
  { tage: "Bug Issues", id: "marking_2" },
  { tage: "Don't need this anymore", id: "marking_3" },
  { tage: "Unclear UI", id: "marking_4" },
  { tage: "Found a better option", id: "marking_5" },
  { tage: "Payment issues", id: "marking_6" },
  { tage: "Not enough features", id: "marking_7" },
  { tage: "Other", id: "marking_8" },
];

const CancelAccountModal = (props) => {
  const {
    cancelPlan,
    setcancelPlan,
    cancle_url,
    planData,
    setmodalDiscount,
    setIsWarning,
  } = props;
  const dispatch = useDispatch();
  const assignTimeValidation = Yup.object().shape({
    reasons: Yup.array().min(1, "add 1 reason").required("required"),
  });

  return (
    <Modal
      isOpen={cancelPlan}
      onRequestClose={() => setcancelPlan(false)}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="Archived client list Modal"
    >
      <div className="proced-with-leaving relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[567px] sm:min-w-[567px]  max-w-[567px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="bg-white p-[32px_48px_40px_48px] rounded-[8px]">
          <h6 className="uppercase font-semibold text-xs text-Neutral700 tracking-[0.04em] pb-2 border-b-[0.56px] border-Neutral200 w-full">
            REASON FOR LEAVING
          </h6>
          <Formik
            initialValues={{ reasons: [], detail_reason: "" }}
            validationSchema={assignTimeValidation}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              setSubmitting(true);
              const plandata = {
                reasons: values?.reasons,
                detail_reason: values?.detail_reason,
              };
              await dispatch(
                UserPlanCancle(
                  plandata,
                  setSubmitting,
                  setcancelPlan,
                  setErrors,
                  cancle_url,
                  setmodalDiscount,
                  planData,
                  setIsWarning
                )
              );
            }}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              isSubmitting,
              handleChange,
              handleBlur,
            }) => (
              <form onSubmit={handleSubmit}>
                <div
                  className="flex flex-wrap gap-y-4 gap-x-3 text-center reson-for-leaving mt-8"
                  data-toggle="buttons"
                >
                  {canceldata?.map((item, index) => {
                    return (
                      <div key={index}>
                        <input
                          className="cl-custom-check"
                          id={item?.tage}
                          type="checkbox"
                          onChange={handleChange}
                          name="reasons"
                          onBlur={handleBlur}
                          value={item?.tage}
                        />
                        <label
                          className="cl-custom-check-label text-sm border-Neutral400 cursor-pointer border rounded-[6px] bg-Neutral100 hover:bg-Neutral800 py-[6px] px-3 hover:border-AccentLight text-Neutral700 hover:text-Neutral000 font-medium duration-300"
                          for={item?.tage}
                          title="Click for select/unselect"
                        >
                          {item?.tage}
                        </label>
                      </div>
                    );
                  })}
                </div>
                {errors?.reasons && touched?.reasons && (
                  <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                    {errors?.reasons}
                  </p>
                )}
                <div className="flex justify-between pt-12">
                  <div className="w-full">
                    <p className="text-Neutral700 font-medium text-xs mb-2">
                      Tell us more...
                    </p>
                    <textarea
                      id="textarea"
                      className={`w-full border-[1.5px] rounded py-[15px] px-4 border-Neutral300 h-[134px] placeholder:text-sm text-sm placeholder:text-Neutral500 text-Neutral800  focus:outline-AccentRegular resize-none font-medium placeholder:font-medium block mb-2`}
                      placeholder="Expand on selection made above..."
                      onChange={handleChange}
                      name="detail_reason"
                      onBlur={handleBlur}
                      value={values?.detail_reason}
                    ></textarea>
                    <div className="text-right">
                      <p className="text-xs text-Neutral700 font-medium">
                        <span>0</span>/<span>250</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center items-end pt-8">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`modal-discount-text inlinr-block text-sm font-bold w-full h-12 flex items-center justify-center rounded-[4px] leading-[1.4] focus:outline-AccentMediumLight bg-Neutral300  hover:text-Neutral800 transition duration-300 ease-in-out hover:bg-Neutral400 ${
                      values?.detail_reason !== ""
                        ? "text-Neutral800"
                        : "text-Neutral500"
                    } `}
                  >
                    {isSubmitting ? (
                      <div className="flex justify-center mt-10 mb-10">
                        <img
                          src={loader}
                          alt="loader"
                          className="animate-spin relative drop-shadow-Texts z-10 h-[30px]"
                        />
                      </div>
                    ) : (
                      " Proceed with Leaving"
                    )}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => setcancelPlan(false)}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
};

export default CancelAccountModal;
