const initialState = {
  trackingList: {
    isLoading: true,
    data: [],
  },
  clientList: {
    isLoading: true,
    data: [],
  },
  trackingProjectList: {
    isLoading: true,
    data: [],
  },
  reload: false,
};
const trackingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_TIME_ENTRY":
      return {
        ...state,
        trackingList: {
          ...state.trackingList,
          ...payload,
        },
      };
    case "SET_TRACKING_PROJECT":
      return {
        ...state,
        trackingProjectList: {
          ...state.trackingProjectList,
          ...payload,
        },
      };
    case "CREATE_NEW_PROJECT_SUCCESS":
      let createData = state.trackingProjectList.data;
      createData = [...createData, payload.data];
      return {
        ...state,
        trackingProjectList: {
          ...state.trackingProjectList,
          data: createData,
        },
      };
    case "SET_PROJECT_CLIENT_LIST":
      return {
        ...state,
        clientList: {
          ...state.clientList,
          ...payload,
        },
      };
    case "TIME_TRACKING_CREATE_CATEGORY_SUCCESS":
      let clientData = state.clientList.data;
      const isExist = clientData?.find((e) => e._id === payload.data._id);
      if (isExist) {
        const indexOfUpdateRecord = clientData?.findIndex(
          (e) => e._id === payload.data._id
        );
        clientData[indexOfUpdateRecord] = {
          ...clientData[indexOfUpdateRecord],
          category: [
            ...new Set([
              ...clientData[indexOfUpdateRecord]?.category,
              ...payload.data.category,
            ]),
          ],
        };
      }
      return {
        ...state,
        clientList: {
          ...state.clientList,
          data: clientData,
        },
      };
    case "DELETE_PROJECT_SUCCESS":
      let data = state.trackingProjectList.data;
      const newToDO = data?.docs?.filter((e) => e._id !== payload.id);
      data.docs = newToDO;
      return {
        ...state,
        trackingProjectList: {
          ...state.trackingProjectList,
          data: data,
        },
      };
    case "UPDATE_TITLE_SUCCESS":
      let tasksData = state.trackingProjectList.data.docs;
      const indexOfUpdateRecord = tasksData?.findIndex(
        (e) => e._id === payload.data._id
      );
      const newData = tasksData?.find((item) => item?._id === payload.data._id);
      const tempData = { ...newData, title: payload.data.title };
      if (indexOfUpdateRecord !== -1) {
        tasksData[indexOfUpdateRecord] = tempData;
      }
      return {
        ...state,
        trackingProjectList: {
          ...state.trackingProjectList,
          data: { ...state.trackingProjectList.data, docs: tasksData },
        },
      };
    case "RELOAD_TIME_ENTRY_SUCCESS":
      return {
        ...state,
        reload: state.reload ? false : true,
      };
    default:
      return state;
  }
};

export default trackingReducer;
