const Spinner = ({
  size = 24,
  thumbColor = "black",
  spinnerBg = "rgba(0, 0, 0, 0.3)",
  ringWidth = 2,
  className = "",
}) => {
  return (
    <>
      <style jsx>
        {`
          #loading {
            display: inline-block;
            width: ${size}px;
            height: ${size}px;
            border: ${ringWidth}px solid ${spinnerBg};
            border-radius: 50%;
            border-top-color: ${thumbColor};
            animation: spin 1s linear infinite;
            -webkit-animation: spin 0.5s linear infinite;
          }
          @keyframes spin {
            to {
              -webkit-transform: rotate(360deg);
            }
          }
          @-webkit-keyframes spin {
            to {
              -webkit-transform: rotate(360deg);
            }
          }
        `}
      </style>
      <div id="loading" className={className} />
    </>
  );
};

export default Spinner;
