import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";
import CloseBtn from "../../assets/Images/close-btn.svg";
import ImgIcon from "../../assets/Images/img-icon.svg";
import Close from "../../assets/Images/Close-gray.svg";
import PhoneInput from "../PhoneInput";
import { UpdateClientAction } from "../../store/actions/clientAction";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const validationSchema = Yup.object().shape({
  business_name: Yup.string(),
  contact_name: Yup.string().required("Contact Name Required"),
  email: Yup.string().email("Email is not valid"),
  // phone_number: Yup.string()
  //   .nullable()
  //   .test("isValid", "Invalid phone number", (value) => {
  //     if (!value) return true; // allows empty values
  //     return typeof value === "string" && isValidPhoneNumber(value);
  //   }),
  // phone_number: Yup.string().required("Phone Number Required"),
});

const EditContactDetailModal = ({ modalIsOpen, setIsOpen, client }) => {
  const { clientId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isAddMoreContact, setIsAddMoreContact] = useState(false);
  const [isAddMoreInfo, setIsAddMoreInfo] = useState(false);
  const [isNewCustomField, setIsNewCustomField] = useState(false);

  useEffect(() => {
    if (!client) return;
    if (modalIsOpen) {
      if (
        client?.second_contact_name ||
        client?.second_email ||
        client?.second_phone_number ||
        client?.second_contact_person_job_title
      ) {
        setIsAddMoreContact(true);
      }
      if (client?.website_one || client?.website_two || client?.vat_number) {
        setIsAddMoreInfo(true);
      }
    }
  }, [client, modalIsOpen]);

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => {
        setIsOpen(false);
        setIsAddMoreContact(false);
        setIsAddMoreInfo(false);
        setIsNewCustomField(false);
      }}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="Example Modal"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="bg-white px-6 xs:px-12 pt-8 pb-10 rounded-[8px]">
          <div>
            <div className="text-left">
              <h3
                className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-4"
                id="modal-title"
              >
                EDIT CONTACT DETAILS
              </h3>
              <Formik
                initialValues={{ ...client }}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={async (value, { setSubmitting, setErrors }) => {
                  setSubmitting(true);
                  const data = {
                    ...value,
                    phone_number:
                      value?.phone_number.length == 15
                        ? value?.phone_number
                        : "",
                    second_phone_number:
                      value?.second_phone_number?.length == 15
                        ? value?.second_phone_number
                        : "",
                    custom_fields: value?.custom_fields?.length
                      ? JSON.stringify(value?.custom_fields)
                      : null,
                  };
                  await dispatch(
                    UpdateClientAction(
                      clientId,
                      data,
                      setSubmitting,
                      setErrors,
                      navigate
                    )
                  );
                  setIsOpen(false);
                  setIsAddMoreContact(false);
                }}
              >
                {({
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  setFieldError,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 overflow-y-auto px-[10px] h-[50vh]">
                      <div>
                        <div className="flex items-center justify-between">
                          <label className="text-Neutral900 font-bold text-xs">
                            Contact Person
                          </label>
                        </div>
                        <div className="mt-2">
                          <input
                            className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                            type="text"
                            placeholder="Vicky Builderino"
                            name="contact_name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.contact_name}
                          />
                          {errors.contact_name && touched.contact_name && (
                            <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                              {errors.contact_name}
                            </p>
                          )}
                        </div>
                      </div>
                      <div>
                        <div className="flex items-center justify-between">
                          <label className="text-Neutral900 font-bold text-xs">
                            Email
                          </label>
                          <span className="text-xs font-medium italic text-Neutral700">
                            Optional
                          </span>
                        </div>
                        <div className="mt-2">
                          <input
                            className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                            type="email"
                            placeholder="Eg. lukeskywalker@gmail.com"
                            name="email"
                            onChange={(e) => {
                              const emailVal = e.target.value.toLowerCase();
                              handleChange({
                                target: {
                                  name: "email",
                                  value: emailVal,
                                },
                              });
                            }}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                          {errors.email && touched.email && (
                            <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                              {errors.email}
                            </p>
                          )}
                        </div>
                      </div>
                      <div>
                        <div className="flex items-center justify-between">
                          <label className="text-Neutral900 font-bold text-xs">
                            Business Name
                          </label>
                          <span className="text-xs font-medium italic text-Neutral700">
                            Optional
                          </span>
                        </div>
                        <div className="mt-2">
                          <input
                            className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                            type="text"
                            placeholder="Bob Builderino"
                            name="business_name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.business_name}
                          />
                          {errors.business_name && touched.business_name && (
                            <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                              {errors.business_name}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="">
                        <div className="flex items-center justify-between">
                          <span className="text-Neutral900 font-bold text-xs">
                            Phone Number
                          </span>
                          <span className="text-xs font-medium italic text-Neutral700">
                            Optional
                          </span>
                        </div>
                        <PhoneInput
                          name="phone_number"
                          value={values.phone_number}
                          setFieldValue={setFieldValue}
                          setFieldError={setFieldError}
                          handleBlur={handleBlur}
                          errors={errors}
                          touched={touched}
                          className="mt-2"
                        />
                      </div>
                      <div>
                        <div className="flex items-center justify-between">
                          <label className="text-Neutral900 font-bold text-xs">
                            Contact Person Job Title
                          </label>
                          <span className="text-xs font-medium italic text-Neutral700">
                            Optional
                          </span>
                        </div>
                        <div className="mt-2">
                          <input
                            className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                            type="text"
                            placeholder="Eg. CEO"
                            name="contact_person_job_title"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.contact_person_job_title}
                          />
                          {errors.contact_person_job_title &&
                            touched.contact_person_job_title && (
                              <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                                {errors.contact_person_job_title}
                              </p>
                            )}
                        </div>
                      </div>
                      <div>
                        <div className="flex items-center justify-between">
                          <span className="text-Neutral900 font-bold text-xs">
                            Profile Image
                          </span>
                          <span className="text-xs font-medium italic text-Neutral700">
                            Optional
                          </span>
                        </div>
                        <div className="relative mt-2">
                          <label
                            htmlFor={values?.profile ? null : "uploadlogo"}
                            className={`placeholder:text-Neutral500 p-2 px-3 text-sm leading-5 w-full focus-visible:outline-none border-[1px] rounded border-Neutral400 hover:border-Neutral800 bg-Neutral100 transition duration-300 border-dashed flex justify-between ${
                              !values?.profile && "cursor-pointer"
                            }`}
                          >
                            {/* Existing image showcase */}
                            {values?.profile_path ? (
                              <span className="flex items-center">
                                <img
                                  src={values?.profile_path}
                                  alt=""
                                  height={values?.profile && 150}
                                  width={values?.profile && 100}
                                />
                                <p
                                  className={`text-Neutral600 ml-2 text-xs font-medium text-ellipsis overflow-hidden ${
                                    values?.profile && "w-[160px]"
                                  }`}
                                >
                                  {values?.profile
                                    ? values?.profile
                                    : "Upload your photo or company logo."}
                                </p>
                              </span>
                            ) : (
                              <span className="flex items-center">
                                <img
                                  src={
                                    values?.profile
                                      ? URL.createObjectURL(values?.profile)
                                      : ImgIcon
                                  }
                                  alt=""
                                  height={values?.profile && 150}
                                  width={values?.profile && 100}
                                />
                                <p
                                  className={`text-Neutral600 ml-2 text-xs font-medium text-ellipsis overflow-hidden ${
                                    values?.profile && "w-[160px]"
                                  }`}
                                >
                                  {values?.profile?.name
                                    ? values?.profile?.name
                                    : "Upload your photo or company logo."}
                                </p>
                              </span>
                            )}
                            {values?.profile ? (
                              <img
                                className="cursor-pointer"
                                src={Close}
                                alt=""
                                onClick={() => {
                                  setFieldValue(`profile`, null);
                                  setFieldValue(`profile_path`, null);
                                }}
                              />
                            ) : null}
                          </label>
                          <input
                            id="uploadlogo"
                            className="hidden"
                            type="file"
                            name="profile"
                            accept="image/x-png,image/jpeg"
                            multiple={false}
                            onChange={(e) => {
                              setFieldValue(`profile`, e.target.files[0]);
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        {isAddMoreInfo && (
                          <>
                            <div className="flex items-center justify-between">
                              <span className="text-Neutral900 font-bold text-xs">
                                Address
                              </span>
                              <span className="text-xs font-medium italic text-Neutral700">
                                Optional
                              </span>
                            </div>
                            <div className="mt-2">
                              <input
                                className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                                type="text"
                                placeholder="Miami, 67879, Hillary Beach"
                                name="address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values?.address}
                              />
                            </div>
                          </>
                        )}
                      </div>
                      {!isAddMoreContact ? (
                        <div className="flex items-center justify-between">
                          <button
                            type="button"
                            className="text-AccentRegular text-xs font-medium"
                            onClick={() => setIsAddMoreContact(true)}
                          >
                            Additional Contact
                          </button>
                          <span className="text-xs font-medium italic text-Neutral700">
                            Optional
                          </span>
                        </div>
                      ) : (
                        <div className="grid gap-4">
                          <div className="flex flex-col justify-end min-h-[78px]">
                            <div className="flex items-center justify-between">
                              <span className="text-Neutral900 font-bold text-xs">
                                Contact Person 2
                              </span>
                              <span className="text-xs font-medium italic text-Neutral700">
                                Optional
                              </span>
                            </div>
                            <div className="mt-2">
                              <input
                                className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                                type="text"
                                placeholder="Vicky Builderino"
                                name="second_contact_name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values?.second_contact_name}
                              />
                            </div>
                          </div>
                          <div className="flex flex-col justify-end min-h-[78px]">
                            <div className="flex items-center justify-between">
                              <span className="text-Neutral900 font-bold text-xs">
                                Email 2
                              </span>
                              <span className="text-xs font-medium italic text-Neutral700">
                                Optional
                              </span>
                            </div>
                            <div className="mt-2">
                              <input
                                className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                                type="email"
                                placeholder="Eg. lukeskywalker@gmail.com"
                                name="second_email"
                                onChange={(e) => {
                                  const secEmailVal =
                                    e.target.value.toLowerCase();
                                  handleChange({
                                    target: {
                                      name: "second_email",
                                      value: secEmailVal,
                                    },
                                  });
                                }}
                                onBlur={handleBlur}
                                value={values.second_email}
                              />
                            </div>
                          </div>
                          <div className="flex flex-col justify-end min-h-[78px]">
                            <div className="flex items-center justify-between">
                              <span className="text-Neutral900 font-bold text-xs">
                                Phone Number 2
                              </span>
                              <span className="text-xs font-medium italic text-Neutral700">
                                Optional
                              </span>
                            </div>
                            <PhoneInput
                              name="second_phone_number"
                              value={values.second_phone_number}
                              setFieldValue={setFieldValue}
                              setFieldError={setFieldError}
                              handleBlur={handleBlur}
                              errors={errors}
                              touched={touched}
                              className="mt-2"
                            />
                          </div>{" "}
                          <div className="flex flex-col justify-end min-h-[78px]">
                            <div className="flex items-center justify-between">
                              <span className="text-Neutral900 font-bold text-xs">
                                Contact Person 2 Job Title
                              </span>
                              <span className="text-xs font-medium italic text-Neutral700">
                                Optional
                              </span>
                            </div>
                            <div className="mt-2">
                              <input
                                className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                                type="text"
                                placeholder="Eg. CEO"
                                name="second_contact_person_job_title"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values?.second_contact_person_job_title}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {!isAddMoreInfo ? (
                        <div className="flex items-center justify-between">
                          <button
                            type="button"
                            className="text-AccentRegular text-xs font-medium"
                            onClick={() => setIsAddMoreInfo(true)}
                          >
                            Address, Websites & VAT
                          </button>
                          <span className="text-xs font-medium italic text-Neutral700">
                            Optional
                          </span>
                        </div>
                      ) : (
                        <div className="grid gap-4">
                          <div className="flex flex-col justify-end min-h-[78px]">
                            <div className="flex items-center justify-between">
                              <span className="text-Neutral900 font-bold text-xs">
                                Website 1
                              </span>
                              <span className="text-xs font-medium italic text-Neutral700">
                                Optional
                              </span>
                            </div>
                            <div className="mt-2">
                              <input
                                className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                                type="text"
                                placeholder="www.google.com"
                                name="website_one"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values?.website_one}
                              />
                            </div>
                          </div>
                          <div className="flex flex-col justify-end min-h-[78px]">
                            <div className="flex items-center justify-between">
                              <span className="text-Neutral900 font-bold text-xs">
                                Website 2
                              </span>
                              <span className="text-xs font-medium italic text-Neutral700">
                                Optional
                              </span>
                            </div>
                            <div className="mt-2">
                              <input
                                className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                                type="text"
                                placeholder="www.apple.com"
                                name="website_two"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values?.website_two}
                              />
                            </div>
                          </div>
                          <div className="flex flex-col justify-end min-h-[78px]">
                            <div className="flex items-center justify-between">
                              <span className="text-Neutral900 font-bold text-xs">
                                VAT Number
                              </span>
                              <span className="text-xs font-medium italic text-Neutral700">
                                Optional
                              </span>
                            </div>
                            <div className="mt-2">
                              <input
                                className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                                type="text"
                                placeholder="Enter client VAT number"
                                name="vat_number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values?.vat_number}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {/* custom fields is an array of objects and each object has a key and value */}
                      {values?.custom_fields?.map((field, index) => (
                        <div key={index}>
                          <div className="flex items-center justify-between">
                            <span className="text-Neutral900 font-bold text-xs">
                              {field.key}
                            </span>
                            <span className="text-xs font-medium italic text-Neutral700">
                              Optional
                            </span>
                          </div>
                          <div className="mt-2">
                            <input
                              className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                              type="text"
                              placeholder={field.key}
                              name={`custom_fields.${index}.value`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={field.value}
                            />
                          </div>
                        </div>
                      ))}

                      {values?.custom_fields?.length < 5 && (
                        <div className="flex items-center justify-between">
                          <button
                            type="button"
                            className="text-AccentRegular text-xs font-medium"
                            onClick={() => setIsNewCustomField(true)}
                          >
                            Add Custom Field
                          </button>
                          <span className="text-xs font-medium italic text-Neutral700">
                            Optional
                          </span>
                        </div>
                      )}

                      {isNewCustomField && (
                        <div className="flex flex-col gap-4">
                          <div className="flex flex-col justify-end min-h-[78px]">
                            <div className="flex items-center justify-between">
                              <span className="text-Neutral900 font-bold text-xs">
                                Field Title
                              </span>
                              <span className="text-xs font-medium italic text-Neutral700">
                                Optional
                              </span>
                            </div>
                            <div className="mt-2">
                              <input
                                placeholder="Eg. Postcode"
                                className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-xs leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular duration-300 transition"
                                type="text"
                                name="key"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values?.key}
                              />
                            </div>
                          </div>
                          <div className="flex flex-col justify-end min-h-[78px]">
                            <div className="flex items-center justify-between">
                              <span className="text-Neutral900 font-bold text-xs">
                                Field Info
                              </span>
                              <span className="text-xs font-medium italic text-Neutral700">
                                Optional
                              </span>
                            </div>
                            <div className="mt-2">
                              <input
                                placeholder="Eg. 12345"
                                className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-xs leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular duration-300 transition"
                                type="text"
                                name="value"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values?.value}
                              />
                            </div>
                          </div>
                          <div className="mt-8">
                            <button
                              className="btn button-hover form-primary-btn relative text-sm text-center block text-Neutral100 bg-AccentRegular font-bold w-full py-3 rounded overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out"
                              onClick={() => {
                                if (values?.key && values?.value) {
                                  setFieldValue("custom_fields", [
                                    ...values?.custom_fields,
                                    {
                                      key: values?.key,
                                      value: values?.value,
                                    },
                                  ]);
                                  setIsNewCustomField(false);
                                  setFieldValue("key", "");
                                  setFieldValue("value", "");
                                }
                              }}
                            >
                              <span className="relative z-10">
                                Add Custom Field
                              </span>
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="mt-6">
                      <button
                        disabled={isSubmitting}
                        className="btn button-hover form-primary-btn relative text-sm text-center block text-Neutral100 bg-AccentRegular font-bold w-full py-3 rounded drop-shadow-Texts overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out"
                      >
                        {isSubmitting ? (
                          <span className="relative z-10">
                            <svg
                              className="animate-spin m-auto h-5 w-5 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          </span>
                        ) : (
                          <span className="relative z-10 drop-shadow-Texts">
                            Save Changes
                          </span>
                        )}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => {
            setIsOpen(false);
            setIsAddMoreContact(false);
            setIsAddMoreInfo(false);
            setIsNewCustomField(false);
          }}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
};

export default EditContactDetailModal;
