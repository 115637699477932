import React, { useState, useEffect } from "react";
import ImageUploading from "react-images-uploading";

function ImageUploadings({ textEditor }) {
  const [image, setImages] = useState(textEditor.images);

  useEffect(() => {
    setImages(textEditor.images);
  }, [textEditor.images]);

  return (
    <div>
      <div>
        <ImageUploading multiple={false} maxNumber={1} dataURLKey="data_url">
          {({ dragProps }) => (
            // write your building UI
            <div
              className="w-full h-full max-w-[790px] mx-auto builder-main"
              {...dragProps}
            >
              <div className="builder-img-box bg-Neutral200 h-[1117px] w-full flex items-center justify-center relative overlay-set">
                <div className="upload__image-wrapper">
                  {image?.url ? (
                    <div className="image-item">
                      <img
                        className="absolute z-0 w-full top-0 left-0 h-[1117px] object-cover"
                        src={image?.url}
                        alt=""
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </ImageUploading>
      </div>
    </div>
  );
}

export default ImageUploadings;
