const initialState = {
  questionnaires: {
    isLoading: true,
    data: [],
  },
  questionnaire: {
    isLoading: true,
    data: {
      questionnaireUrlId: null,
      customerId: null,
      userId: null,
      questionnaire: [],
      questionnaireTitle: null,
    },
  },
  open_questionnaire: {
    isLoading: true,
    data: {
      questionnaireUrlId: null,
      customerId: null,
      userId: null,
      questionnaire: [],
      questionnaireTitle: null,
    },
    error_message: null,
    error: false,
  },
};
const questionnaireReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "CREATE_QUESTIONNAIRE_SUCCESS":
      return {
        ...state,
        questionnaires: {
          ...state.questionnaires,
          data: [...state.questionnaires.data, payload?.data],
        },
      };
    case "SET_QUESTIONNAIRE":
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          ...payload,
        },
      };
    case "SET_OPEN_QUESTIONNAIRE":
      return {
        ...state,
        open_questionnaire: {
          ...state.open_questionnaire,
          ...payload,
          error: false,
          error_message: null,
        },
      };
    case "SET_QUESTIONNAIRE_QUESTION_ANSWER":
      const data = payload;
      let questionnaire = state.open_questionnaire.data.questions.map(
        (question) => {
          if (question._id === data._id) {
            if (question.type === "checkbox" || question.type === "radio") {
              return {
                ...question,
                answer: data.answer,
                [`${question?.type}_options`]:
                  data?.[`${question?.type}_options`],
              };
            }
            return {
              ...question,
              answer: data.answer,
              selectedFiles:
                question.type === "file" ||
                question.type === "files" ||
                question.type === "profile" ||
                question.type === "image"
                  ? data.file
                  : null,
            };
          }
          return question;
        }
      );
      return {
        ...state,
        open_questionnaire: {
          ...state.open_questionnaire,
          data: {
            ...state.open_questionnaire.data,
            questions: questionnaire,
          },
        },
      };
    case "SET_OPEN_QUESTIONNAIRE_QUESTION_ANSWER":
      return {
        ...state,
        open_questionnaire: {
          ...state.open_questionnaire,
          data: {
            ...state.open_questionnaire.data,
            ...payload.data,
          },
        },
      };
    case "SET_OPEN_QUESTIONNAIRE_ERROR":
      return {
        ...state,
        open_questionnaire: {
          ...state.open_questionnaire,
          ...payload,
          data: initialState.open_questionnaire.data,
        },
      };
    case "SET_QUESTIONNAIRE_OF_CLIENT":
      return {
        ...state,
        questionnaires: {
          ...state.questionnaires,
          ...payload,
        },
      };
    case "QUESTIONNAIRE_DELETE_SUCCESS":
      const questionnaires = state.questionnaires.data.filter(
        (questionnaire) => questionnaire._id !== payload.id
      );
      return {
        ...state,
        questionnaires: {
          ...state.questionnaires,
          data: questionnaires,
        },
      };
    default:
      return state;
  }
};

export default questionnaireReducer;
