import { useCallback, useEffect, useState } from "react";
import { usePopper } from "react-popper";

/** @param {Partial<import("@popperjs/core").Options>} options */
const useMenu = (options = {}) => {
  const [anchorElement, setAnchorElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [isPopperInitialized, setIsPopperInitialized] = useState(false);

  const { styles, attributes, update } = usePopper(
    anchorElement,
    popperElement,
    options
  );

  useEffect(() => {
    if (anchorElement && popperElement && !isPopperInitialized) {
      setTimeout(() => {
        update?.(); // Trigger an update to correct the position
        setIsPopperInitialized(true);
      }, 0);
    }
  }, [popperElement, isPopperInitialized, update, anchorElement]);

  const setAnchorRef = useCallback((element) => setAnchorElement(element), []);
  const setMenuElementRef = useCallback(
    (element) => setPopperElement(element),
    []
  );

  return {
    setAnchorRef,
    setMenuElementRef,
    menuStyles: styles.popper || {},
    menuAttributes: attributes.popper || {},
  };
};

export default useMenu;
