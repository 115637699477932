const initialState = {
  isError: null,
  domain: null,
};

const SubDomainReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "CREATE_SUBDOMAIN_SUCCESS":
      return {
        ...state,
        domain: payload.data,
      };
    case "SET_SUBDOMAIN":
      return {
        ...state,
        domain: payload.data,
      };
    case "UPDATE_SUBDOMAIN_SUCCESS":
      return {
        ...state,
        domain: payload.data,
      };
    case "CREATE_SUBDOMAIN_ERROR":
    case "UPDATE_SUBDOMAIN_ERROR":
      return {
        ...state,
        isError: payload,
      };
    default:
      return state;
  }
};

export default SubDomainReducer;
