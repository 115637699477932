import React from "react";

function CheckMarkIcon({ width = 24, className = "", color = "#09842e" }) {
  return (
    <svg
      width={width}
      height={width}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 9L10.2812 15.75L7 12.6818"
        stroke={color}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CheckMarkIcon;
