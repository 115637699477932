import axios from "axios";
import { toast } from "react-toastify";

export const CreateNoteAction = (value) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post("api/v1/note/create-note", value);
      return dispatch({
        type: "CREATE_NOTE_SUCCESS",
        payload: {
          data: data.data,
          client: value?.client,
        },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        return dispatch({
          type: "CREATE_NOTE_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "CREATE_NOTE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const GetNotesActions = (id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SET_NOTE",
        payload: {
          isLoading: true,
        },
      });
      const { data } = await axios.get(`api/v1/note/get-note?id=${id}`);
      return dispatch({
        type: "SET_NOTE",
        payload: { client: id, data: data.data, isLoading: false },
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "GET_NOTE_ERROR",
        payload: error,
      });
    }
  };
};
