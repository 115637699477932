import React from "react";
import clx from "classnames";

function DateTrackingBadge({ date = "Oct 3, 2021", className = "" }) {
  if (!date) {
    return null;
  }
  return (
    <span
      className={clx(
        "rounded-[20px] px-3 py-[6px] text-center bg-Neutral900 inline-block text-Neutral000 w-fit font-semibold text-xs leading-[16px]",
        className
      )}
    >
      {date}
    </span>
  );
}

export default DateTrackingBadge;
