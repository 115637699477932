import { useSelector } from "react-redux";

function useIsAdmin(extraRoles = []) {
  const loggedInUser = useSelector((state) => state.auth.user);
  let isAdmin = false;
  if (extraRoles.length) {
    isAdmin = ["admin", "master_admin"].includes(loggedInUser?.role);
  } else {
    isAdmin = ["admin", "master_admin", ...extraRoles].includes(
      loggedInUser?.role
    );
  }
  return isAdmin;
}

export default useIsAdmin;
