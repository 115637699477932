/** @param {import("react").SVGProps} props - SVG Icon Props */
function ChevronDown(props) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0293 8.29492L10.0293 13.2949L5.0293 8.29492"
        stroke="currentColor"
        strokeWidth="1.42033"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ChevronDown;
