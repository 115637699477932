import React from "react";

function TimeTrackingLoader() {
  return (
    <div className="5xl:px-[96px] px-12 w-full md:pt-[40px] pt-0">
      {/* <div className="w-[9.375rem] mb-4 bg-[#f6f7f8] inline-block h-12 bg-no-repeat skeleton-animation relative"></div>
      <div className="w-[9.375rem] mb-4 ml-3 bg-[#f6f7f8] inline-block h-12 bg-no-repeat skeleton-animation relative"></div> */}
      <div className="w-full mt-5">
        <div className="w-[9.375rem] mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
      </div>
      <table className="w-full">
        <tr>
          <td className="w-full max-w-[4%]">
            <div
              className={`h-12 mb-4 w-12 min-w-12 5xl:h-16 5xl:w-16 rounded items-center justify-center text-Neutral700 bg-[#f6f7f8] bg-no-repeat skeleton-animation relative`}
            ></div>
          </td>
          <td className=" w-full max-w-[5%]">
            <div className="rounded w-5/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className="w-full max-w-[10%]">
            <div className="rounded w-5/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className=" w-full max-w-[71%] text-center">
            <div className="w-1/6 rounded mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className="w-full max-w-[10%] text-right">
            <div className="rounded w-20 h-8 mb-4 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
          </td>
        </tr>
        <tr>
          <td className="w-full max-w-[4%]">
            <div
              className={`h-12 mb-4 w-12 min-w-12 5xl:h-16 5xl:w-16 rounded items-center justify-center text-Neutral700 bg-[#f6f7f8] bg-no-repeat skeleton-animation relative`}
            ></div>
          </td>
          <td className=" w-full max-w-[5%]">
            <div className="rounded w-5/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className="w-full max-w-[10%]">
            <div className="rounded w-5/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className=" w-full max-w-[71%] text-center">
            <div className="w-1/6 rounded mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className="w-full max-w-[10%] text-right">
            <div className="rounded w-20 h-8 mb-4 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
          </td>
        </tr>
        <tr>
          <td className="w-full max-w-[4%]">
            <div
              className={`h-12 mb-4 w-12 min-w-12 5xl:h-16 5xl:w-16 rounded items-center justify-center text-Neutral700 bg-[#f6f7f8] bg-no-repeat skeleton-animation relative`}
            ></div>
          </td>
          <td className=" w-full max-w-[5%]">
            <div className="rounded w-5/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className="w-full max-w-[10%]">
            <div className="rounded w-5/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className=" w-full max-w-[71%] text-center">
            <div className="w-1/6 rounded mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className="w-full max-w-[10%] text-right">
            <div className="rounded w-20 h-8 mb-4 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
          </td>
        </tr>
        <tr>
          <td className="w-full max-w-[4%]">
            <div
              className={`h-12 mb-4 w-12 min-w-12 5xl:h-16 5xl:w-16 rounded items-center justify-center text-Neutral700 bg-[#f6f7f8] bg-no-repeat skeleton-animation relative`}
            ></div>
          </td>
          <td className=" w-full max-w-[5%]">
            <div className="rounded w-5/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className="w-full max-w-[10%]">
            <div className="rounded w-5/6 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className=" w-full max-w-[71%] text-center">
            <div className="w-1/6 rounded mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          </td>
          <td className="w-full max-w-[10%] text-right">
            <div className="rounded w-20 h-8 mb-4 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
          </td>
        </tr>
      </table>
      {/* pagination */}
      <div className="w-full justify-between flex my-7">
        <div className="w-40">
          <div className="w-5/6 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          <div className="w-4/6 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
        </div>
        <div className="flex">
          <div className="rounded w-6 h-6 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative mr-3"></div>
          <div className="rounded w-6 h-6 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></div>
        </div>
      </div>
    </div>
  );
}

export default TimeTrackingLoader;
