import React, { useState } from "react";
import { useSelector } from "react-redux";
import noClient from "../../assets/Images/no-client.svg";
import CreateClientModal from "../Modals/CreateClientModal";

const NoClientComponent = () => {
  const authSelector = useSelector((state) => state.auth);
  const [createClientModal, setCreateClientModal] = useState(false);

  return (
    <>
      <div className="pt-[80px] flex justify-center">
        <div>
          <img src={noClient} alt="no-client" />
          <div className="text-center">
            <div className=" flex justify-center">
              <h1 className="font-[900] text-[28px] mt-[32px] text-Neutral900">
                Add Your First Client
              </h1>
            </div>
            <div className="flex justify-center mt-[12px]">
              <p className="text-[14px] w-[295px] text-Neutral800 text-center">
                No client, no worries! Start by simply clicking this button
                below.
              </p>
            </div>
            <div className="mt-[24px] 2xl:pb-0 md:pb-16 pb-10 flex justify-center">
              {authSelector?.user?.invited === false ||
              (authSelector?.user?.owner &&
                authSelector?.user?.invited === true) ? (
                <button
                  type="submit"
                  className="w-[295px] relative text-sm text-center block py-4 rounded overflow-hidden border border-Neutral900 relativ focus-visible:outline focus-visible:outline-[6px] focus-visible:drop-shadow-none focus-visible:outline-AccentMediumLight transition duration-300 ease-in-out"
                  onClick={setCreateClientModal}
                >
                  <span className="relative text-Neutral900 font-[600] z-10 text-sm">
                    Add Client
                  </span>
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <CreateClientModal
        isOpen={createClientModal}
        setIsOpen={setCreateClientModal}
      />
    </>
  );
};

export default NoClientComponent;
