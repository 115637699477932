import Modal from "react-modal";
import { Link } from "react-router-dom";

import CloseBtn from "../../assets/Images/close-btn.svg";

const customStyles = {
	overlay: {
		backgroundColor: "rgba(41, 41, 51, 0.7)",
		overflow: "hidden",
	},
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		border: "none",
		padding: 0,
		background: "none",
		borderRadius: "10px",
		overflow: "visible",
	},
};

function PortalRedirectModal({ isOpen, setOpen }) {
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={handleClose}
			closeTimeoutMS={300}
			style={customStyles}
			contentLabel="Client Portal"
		>
			<div className="relative rounded-lg sm:w-full animate__fadeInUp shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)]">
				<div className="px-8 py-8 overflow-hidden rounded-lg bg-Neutral000">
					<h3 className="font-medium text-Neutral800">
						You'll be redirected to{" "}
						<span className="font-semibold text-AccentRegular">
							“portal.clientmanager.io”
						</span>
					</h3>
					<ol className="text-sm my-7 text-Neutral700">
						<li className="pb-2">1. Login with your account credentials.</li>
						<li className="py-2">2. Activate a portal for each client.</li>
						<li className="pt-2">3. They will receive an activation URL.</li>
					</ol>
					<Link
						to={`https://portal.clientmanager.io/`}
						className="block py-2.5 font-semibold hover:bg-AccentMediumDark text-sm text-center rounded-md text-Neutral000 bg-AccentRegular"
					>
						Login To Portal Dashboard
					</Link>
				</div>
				{/* Close modal button */}
				<button className="absolute -right-1 top-[-50px] z-30" onClick={() => handleClose()}>
					<img src={CloseBtn} alt="Close modal button" />
				</button>
			</div>
		</Modal>
	);
}

export default PortalRedirectModal;
