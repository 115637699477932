import React from "react";

function PhoneIcon({ width = 24, className = "" }) {
  return (
    <svg
      width={width}
      height={width}
      className={className}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6663 9.75803V11.2509C11.6668 11.3895 11.6401 11.5266 11.5877 11.6536C11.5353 11.7806 11.4585 11.8946 11.3622 11.9883C11.2659 12.082 11.1522 12.1533 11.0284 12.1977C10.9046 12.2421 10.7734 12.2586 10.6433 12.2461C9.19923 12.0797 7.81212 11.5565 6.59341 10.7184C5.45955 9.95442 4.49824 8.93505 3.77774 7.73272C2.98465 6.43453 2.4911 4.95649 2.33706 3.41835C2.32533 3.28075 2.34076 3.14206 2.38235 3.01112C2.42394 2.88017 2.49078 2.75985 2.57863 2.6578C2.66648 2.55576 2.7734 2.47423 2.89259 2.4184C3.01178 2.36257 3.14062 2.33367 3.27092 2.33354H4.67876C4.9065 2.33117 5.12729 2.41669 5.29997 2.57416C5.47265 2.73163 5.58544 2.95032 5.61731 3.18945C5.67673 3.6672 5.78693 4.13628 5.9458 4.58776C6.00894 4.76587 6.02261 4.95944 5.98518 5.14553C5.94775 5.33162 5.8608 5.50244 5.73463 5.63774L5.13865 6.26971C5.80669 7.51553 6.77945 8.54704 7.95431 9.25543L8.55029 8.62345C8.67789 8.48966 8.83897 8.39746 9.01447 8.35777C9.18996 8.31808 9.3725 8.33257 9.54047 8.39953C9.96623 8.56799 10.4086 8.68485 10.8591 8.74786C11.0871 8.78196 11.2953 8.90372 11.4441 9.08997C11.5929 9.27622 11.672 9.51398 11.6663 9.75803Z"
        stroke="#9C9CA6"
        strokeLinecap="round"
        strokeLinejoinround="round"
      />
    </svg>
  );
}

export default PhoneIcon;
