import React, { useEffect, useState } from "react";
import moment from "moment";

function WeekGridView({ calenderViewDate }) {
  const [days, setDays] = useState([]);

  useEffect(() => {
    function getDaysForCalendarMonth(date) {
      var firstDayOfWeek = moment(date);
      var temp = firstDayOfWeek.clone();
      var days = [
        {
          date: temp.format("YYYY-MM-DD"),
          day: temp.format("DD"),
          dayName: temp.format("ddd"),
        },
      ];
      while (days.length < 7) {
        temp.add(1, "day");
        days.push({
          date: temp.format("YYYY-MM-DD"),
          dayName: temp.format("ddd"),
          day: temp.format("DD"),
        });
      }
      return days;
    }
    setDays(getDaysForCalendarMonth(calenderViewDate));
  }, [calenderViewDate]);
  return (
    <div className="flex flex-auto flex-col overflow-auto bg-white">
      <div
        style={{ width: "165%" }}
        className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full"
      >
        <div className="sticky top-0  flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8">
          <div className="grid grid-cols-7 text-xs leading-6 text-Neutral900 sm:hidden">
            {days.map((item) => {
              return (
                <button
                  type="button"
                  className="flex flex-col items-center pt-2 pb-3"
                >
                  {item.dayName[0]}{" "}
                  <span className="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-Neutral900">
                    {item.day}
                  </span>
                </button>
              );
            })}
          </div>

          <div className="-mr-px hidden grid-cols-7 divide-x divide-Neutral200 border-r border-Neutral200 text-xs leading-5 text-Neutral900 sm:grid">
            <div className="col-end-1 w-14" />

            {days.map((item) => {
              let isToday = moment(item.date).isSame(new Date(), "day")
              return (
                <div className={`flex items-center justify-center py-3 `}>
                  <span className={`${isToday
                ? "flex h-6 p-3 items-center rounded-full bg-AccentRegular font-semibold text-white whitespace-pre"
                : 'h-6'}`}>
                    {item.dayName}{" "}
                    <span className={`items-center justify-center font-semibold ${isToday ? "text-white" : "text-Neutral900"}`}>
                      {item.day}
                    </span>
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        {
          <div className="flex flex-auto">
            <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-Neutral400" />
            <div className="grid flex-auto grid-cols-1 grid-rows-1">
              {/* Horizontal lines */}
              <div
                className="col-start-1 col-end-2 row-start-1 grid divide-y divide-Neutral200"
                style={{ gridTemplateRows: "repeat(48, minmax(3.5rem, 1fr))" }}
              >
                <div className="row-end-1 h-7"></div>
                <div className="cursor-pointer">
                  <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                    12AM
                  </div>
                </div>
                <div />
                <div className="cursor-pointer">
                  <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                    1AM
                  </div>
                </div>
                <div />
                <div className="cursor-pointer">
                  <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                    2AM
                  </div>
                </div>
                <div />
                <div className="cursor-pointer">
                  <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                    3AM
                  </div>
                </div>
                <div />
                <div className="cursor-pointer">
                  <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                    4AM
                  </div>
                </div>
                <div />
                <div className="cursor-pointer">
                  <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                    5AM
                  </div>
                </div>
                <div />
                <div className="cursor-pointer">
                  <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                    6AM
                  </div>
                </div>
                <div />
                <div className="cursor-pointer">
                  <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                    7AM
                  </div>
                </div>
                <div />
                <div className="cursor-pointer">
                  <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                    8AM
                  </div>
                </div>
                <div />
                <div className="cursor-pointer">
                  <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                    9AM
                  </div>
                </div>
                <div />
                <div className="cursor-pointer">
                  <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                    10AM
                  </div>
                </div>
                <div />
                <div className="cursor-pointer">
                  <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                    11AM
                  </div>
                </div>
                <div />
                <div className="cursor-pointer">
                  <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                    12PM
                  </div>
                </div>
                <div />
                <div className="cursor-pointer">
                  <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                    1PM
                  </div>
                </div>
                <div />
                <div className="cursor-pointer">
                  <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                    2PM
                  </div>
                </div>
                <div />
                <div className="cursor-pointer">
                  <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                    3PM
                  </div>
                </div>
                <div />
                <div className="cursor-pointer">
                  <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                    4PM
                  </div>
                </div>
                <div />
                <div className="cursor-pointer">
                  <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                    5PM
                  </div>
                </div>
                <div />
                <div className="cursor-pointer">
                  <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                    6PM
                  </div>
                </div>
                <div />
                <div className="cursor-pointer">
                  <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                    7PM
                  </div>
                </div>
                <div />
                <div className="cursor-pointer">
                  <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                    8PM
                  </div>
                </div>
                <div />
                <div className="cursor-pointer">
                  <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                    9PM
                  </div>
                </div>
                <div />
                <div className="cursor-pointer">
                  <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                    10PM
                  </div>
                </div>
                <div />
                <div className="cursor-pointer">
                  <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-Neutral700">
                    11PM
                  </div>
                </div>
                <div />
              </div>

              {/* Vertical lines */}
              <div className="col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-Neutral200 sm:grid sm:grid-cols-7">
                <div className="col-start-1 row-span-full cursor-pointer" />
                <div className="col-start-2 row-span-full cursor-pointer" />
                <div className="col-start-3 row-span-full cursor-pointer" />
                <div className="col-start-4 row-span-full cursor-pointer" />
                <div className="col-start-5 row-span-full cursor-pointer" />
                <div className="col-start-6 row-span-full cursor-pointer" />
                <div className="col-start-7 row-span-full cursor-pointer" />
                <div className="col-start-8 row-span-full w-8 cursor-pointer" />
              </div>

              {/* Events */}
              <ol
                className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
                style={{
                  gridTemplateRows: "1.75rem repeat(288, minmax(0, 1fr)) auto",
                }}
              >
                <li
                  className="relative mt-px flex sm:col-start-3"
                  style={{ gridRow: "74 / span 12" }}
                >
                  <div className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-AccentLight p-2 text-xs leading-5 hover:bg-AccentMediumLight">
                    <p className="order-1 font-semibold text-Blue900">
                      Breakfast
                    </p>
                    <p className="text-AccentRegular group-hover:text-AccentDark">
                      <time dateTime="2022-01-12T06:00">6:00 AM</time>
                    </p>
                  </div>
                </li>
                <li
                  className="relative mt-px flex sm:col-start-3"
                  style={{ gridRow: "92 / span 30" }}
                >
                  <div className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-AccentLight p-2 text-xs leading-5 hover:bg-AccentMediumLight">
                    <p className="order-1 font-semibold text-Pink900 ">
                      Flight to Paris
                    </p>
                    <p className="text-AccentRegular group-hover:text-AccentDark">
                      <time dateTime="2022-01-12T07:30">7:30 AM</time>
                    </p>
                  </div>
                </li>
                <li
                  className="relative mt-px hidden sm:col-start-6 sm:flex"
                  style={{ gridRow: "122 / span 24" }}
                >
                  <div className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-AccentLight p-2 text-xs leading-5 hover:bg-AccentLight">
                    <p className="order-1 font-semibold text-Neutral900">
                      Meeting with design team at Disney
                    </p>
                    <p className="text-AccentRegular group-hover:text-AccentDark">
                      <time dateTime="2022-01-15T10:00">10:00 AM</time>
                    </p>
                  </div>
                </li>
              </ol>
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default WeekGridView;
