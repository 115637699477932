const initialState = {
  goals: {
    isLoading: true,
    is_called: false,
    data: [],
  },
};

const goalsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_GOAL":
      return {
        ...state,
        goals: {
          ...state.goals,
          ...payload,
        },
      };
    case "CREATE_GOAL_SUCCESS":
      let tempGoal = state.goals.data;
      tempGoal = [...tempGoal, payload.data];
      return {
        ...state,
        goals: {
          ...state.goals,
          data: tempGoal,
        },
      };
    case "UPDATE_GOAL_SUCCESS":
      let goalsData = state.goals.data;
      const indexOfUpdateRecord = goalsData.findIndex(
        (e) => e._id === payload.data._id
      );
      if (indexOfUpdateRecord !== -1) {
        goalsData[indexOfUpdateRecord] = payload.data;
      }
      return {
        ...state,
        goals: {
          ...state.goals,
          data: [...goalsData],
        },
      };
    case "UPDATE_ISMARK_GOAL_SUCCESS":
      let tempGoals = state.goals.data;
      const indexOfUpdateIsMarkRecord = tempGoals.findIndex(
        (e) => e._id === payload.data._id
      );
      if (indexOfUpdateIsMarkRecord !== -1) {
        tempGoals[indexOfUpdateIsMarkRecord] = payload.data;
      }
      return {
        ...state,
        goals: {
          ...state.goals,
          data: [...tempGoals],
        },
      };
    case "RESET_GOAL_LIST":
      return {
        ...state,
        goals: {
          ...state.goals,
          data: payload,
        },
      };
    default:
      return state;
  }
};

export default goalsReducer;
