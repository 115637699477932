import React from "react";

import Modal from "react-modal";

import CloseBtn from "../../assets/Images/close-btn.svg";

const customStyles = {
    overlay: {
      backgroundColor: "rgba(41, 41, 51, 0.7)",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "none",
      padding: "47px",
      background: "none",
      borderRadius: "10px",
    },
  };

const TeamMemberDetailsModal = ({setIsOpen, modalIsOpen}) => {
  return (
    <>
        <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => setIsOpen(false)}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="Archived client list Modal"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[455px] sm:min-w-[455px]  max-w-[455px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="bg-white p-[24px_32px_30px_32px] rounded-[8px]">
          <h6 className="uppercase font-medium text-xs text-Neutral700 tracking-[0.04em] pb-2 border-b-[0.56px] border-Neutral300 w-full">
            how it works
          </h6>
          <div className="flex justify-between pt-6 pb-5 border-b-[0.5px] border-Neutral300 gap-3">
            <div className="w-full">
              <h3 className="text-Neutral900 font-bold text-xs uppercase">
                General
              </h3>
              <p className="text-Neutral600 font-medium text-[10px]">
                Ideal for <span className="font-bold">employees</span>
              </p>
            </div>
            <div className="md:min-w-[225px]">
              <p className="text-right text-xs font-normal">
                This is the most restricted role preventing members from viewing
                sensitive client & project information.
              </p>
            </div>
          </div>
          <div className="flex justify-between pt-6 pb-5 border-b-[0.5px] border-Neutral300 gap-3">
            <div className="w-full">
              <h3 className="text-Neutral900 font-bold text-xs uppercase">
                Project Manager
              </h3>
              <p className="text-Neutral600 font-medium text-[10px]">
                Ideal for <span className="font-bold">managers</span>
              </p>
            </div>
            <div className="md:min-w-[225px]">
              <p className="text-right text-xs font-normal">
                A supervisory role, a member assigned this can view and access
                most of the app but does not have full access.
              </p>
            </div>
          </div>
          <div className="flex justify-between pt-6 pb-5 border-b-[0.5px] border-Neutral300 gap-3">
            <div className="w-full">
              <h3 className="text-Neutral900 font-bold text-xs uppercase ">
                Admin
              </h3>
              <p className="text-Neutral600 font-medium text-[10px]">
                Ideal for <span className="font-bold">owners</span>
              </p>
            </div>
            <div className="md:min-w-[225px]">
              <p className="text-right text-xs font-normal">
                Has full access and can change permissions for other users.
              </p>
            </div>
          </div>
          <h6 className="italic font-medium text-xs text-Neutral700 tracking-[0.04em] pt-6">
          Only Admin + PM roles can view/edit contracts, invoices, proposals, leads pipeline, and hourly rates.
          </h6>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => setIsOpen(false)}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
    </>
  );
};

export default TeamMemberDetailsModal;