import { useState } from 'react';

export const useTemplateForm = () => {
  const [showTitleInput, setShowTitleInput] = useState(true);
  const [showCategoryTags, setShowCategoryTags] = useState(false);

  const handleShowTitleInput = () => setShowTitleInput(true);

  const handleHideTitleInput = () => setShowTitleInput(false);

  const handleShowCategoryTags = () => setShowCategoryTags(true);

  const handleHideCategoryTags = () => setShowCategoryTags(false);

  return {
    showTitleInput,
    showCategoryTags,
    handleShowTitleInput,
    handleShowCategoryTags,
    handleHideCategoryTags,
    handleHideTitleInput,
  };
};
