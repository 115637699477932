import React from "react";
import Modal from "react-modal";

import CloseBtn from "../../assets/Images/close-btn.svg";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

function OpenProposalModal({
  isOpen,
  setModal,
  isOpenProposalModal,
  isOpenBuildProposalModal,
}) {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setModal(false)}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="Example Modal"
      >
        <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="bg-white px-6 xs:px-12 pt-8 pb-10 rounded-[8px]">
            <div>
              <div className="text-left">
                <div>
                  <h3
                    className="flex justify-between text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-8"
                    id="modal-title"
                  >
                    ADD PROPOSAL
                  </h3>
                </div>
                <div className="mt-8 flex flex-col space-y-6">
                  <div className="relative z-10 drop-shadow-Texts">
                    <button
                      className="btn button-hover form-primary-btn relative text-sm text-center block text-Neutral100 bg-AccentRegular font-bold w-full py-3 rounded drop-shadow-Texts overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out"
                      onClick={isOpenBuildProposalModal}
                    >
                      <span className="relative drop-shadow-Texts z-10">
                        Build a Proposal
                      </span>
                    </button>
                  </div>
                  <div className="relative z-10 drop-shadow-Texts">
                    <button
                      className="form-primary-btn relative text-sm text-center block text-AccentDark bg-AccentLight font-bold w-full py-3 rounded drop-shadow-Texts overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out"
                      onClick={isOpenProposalModal}
                    >
                      Upload Existing Proposal
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => setModal(false)}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default OpenProposalModal;
