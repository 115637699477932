import { useState, useEffect } from "react";

const upcomingFeatures = [
  "Zoom Integration",
  "SOP Dashboard",
  "Integrations (Q4 ’24)",
];

const newFeatures = [
  "Improved Invoice Builder",
  "Improved Client Portal Activation Flow",
  "White Label Option",
  "Automated Email Reminders",
  "Stripe Integration",
  "New Dashboard",
  "Client Portal",
  "In-app Emails",
  "PayPal Integration",
];

function NewAndUpcoming() {
  const [isOpen, setIsOpen] = useState(false);
  const [isNewSelected, setIsNewSelected] = useState(true);

  const handleClickOutside = (event) => {
    const container = document.getElementById("new-upcoming-list");
    if (container && !container.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]);

  return (
    <div className="py-[1.5px] flex items-center self-start	w-full relative">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center w-full gap-3 pl-3 rounded-lg bg-AccentLight"
      >
        <div className="">
          <svg
            width="24"
            height="35"
            viewBox="0 0 24 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_8023_98597)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.92939 15.2862C6.89605 15.0884 6.87094 14.8929 6.85472 14.6973C6.83028 14.404 6.84539 14.1084 6.83672 13.8151C6.83295 13.6906 6.81806 13.3662 6.82406 13.3195C6.8785 12.9129 7.20672 12.8506 7.30272 12.8395C7.34916 12.8329 7.82605 12.7796 7.92894 13.3084C8.05516 13.9551 8.09561 14.6062 8.05094 15.244C8.15517 15.7862 8.33339 16.3262 8.56872 16.8151C9.50517 18.7595 11.2896 18.9484 13.2229 18.9306C13.5203 18.9262 13.7672 19.1595 13.7827 19.4551C13.7983 19.7529 13.577 20.0106 13.281 20.0373C12.2003 20.1418 10.2829 21.0084 9.62005 21.9107C9.48138 22.0995 9.40072 22.5551 9.29405 23.0018C9.14516 23.6218 8.96806 24.2351 8.76961 24.4929C8.73983 24.5329 8.37783 24.9418 8.26983 25.0018C8.01027 25.144 7.8025 25.0618 7.6845 24.9862C7.5665 24.9106 7.43294 24.7618 7.37961 24.5151C7.32383 24.2573 7.37094 23.7284 7.36228 23.6151C7.33072 23.204 7.24851 22.3973 7.08073 21.704C6.99028 21.3306 6.89383 20.9862 6.73428 20.8151C6.14361 20.1818 5.19917 20.2751 4.40383 20.3795C4.3125 20.3907 4.22117 20.404 4.13006 20.4151C3.80628 20.5107 3.46249 20.5818 3.09916 20.624C2.55049 20.6884 2.46028 20.184 2.45539 20.1506C2.44517 20.0773 2.40716 19.6995 2.82072 19.5484C2.86583 19.5329 3.19872 19.4595 3.32694 19.4306C3.50561 19.3906 3.68627 19.3595 3.86761 19.3307C5.74072 18.7484 6.75805 17.1129 6.92939 15.2862ZM7.57228 17.3062C8.27939 18.7684 9.3525 19.4751 10.6245 19.7951H10.6243C9.83006 20.2106 9.10761 20.7307 8.72472 21.2529C8.57938 21.4507 8.44605 21.8484 8.32583 22.2951C8.26072 21.8795 8.17273 21.4373 8.0565 21.0595C7.92428 20.6284 7.74627 20.2707 7.54761 20.0573C7.16405 19.6462 6.68272 19.4173 6.16516 19.304C6.78472 18.7551 7.2545 18.0707 7.57228 17.3062Z"
                fill="var(--accent-color)" //#4747B3
              />
            </g>
            <g clipPath="url(#clip1_8023_98597)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.7245 7.29943C13.7031 7.17228 13.687 7.04658 13.6765 6.92087C13.6608 6.7323 13.6705 6.54228 13.665 6.35371C13.6625 6.27371 13.653 6.06514 13.6568 6.03514C13.6918 5.77372 13.9028 5.73371 13.9645 5.72657C13.9944 5.72228 14.3009 5.68801 14.3671 6.02801C14.4482 6.44373 14.4742 6.86229 14.4455 7.27229C14.5125 7.62086 14.6271 7.968 14.7784 8.28228C15.3804 9.53228 16.5275 9.65372 17.7704 9.64229C17.9615 9.63943 18.1202 9.78943 18.1302 9.97943C18.1402 10.1709 17.998 10.3366 17.8077 10.3537C17.113 10.4209 15.8804 10.978 15.4542 11.558C15.3651 11.6794 15.3132 11.9723 15.2447 12.2594C15.1489 12.658 15.0351 13.0523 14.9075 13.218C14.8884 13.2437 14.6557 13.5066 14.5862 13.5452C14.4194 13.6366 14.2858 13.5837 14.21 13.5351C14.1341 13.4866 14.0482 13.3909 14.014 13.2323C13.9781 13.0666 14.0084 12.7266 14.0028 12.6537C13.9825 12.3894 13.9297 11.8709 13.8218 11.4251C13.7637 11.1851 13.7017 10.9637 13.5991 10.8537C13.2194 10.4466 12.6122 10.5066 12.101 10.5737C12.0422 10.5809 11.9835 10.5894 11.925 10.5966C11.7168 10.658 11.4958 10.7037 11.2622 10.7309C10.9095 10.7723 10.8515 10.448 10.8484 10.4266C10.8418 10.3794 10.8174 10.1366 11.0832 10.0394C11.1122 10.0294 11.3262 9.98229 11.4087 9.96372C11.5235 9.938 11.6397 9.91801 11.7562 9.89944C12.9604 9.52515 13.6144 8.47371 13.7245 7.29943ZM14.1378 8.59801C14.5924 9.53801 15.2822 9.99229 16.0999 10.198H16.0998C15.5892 10.4651 15.1248 10.7994 14.8787 11.1352C14.7852 11.2623 14.6995 11.518 14.6222 11.8051C14.5804 11.538 14.5238 11.2537 14.4491 11.0109C14.3641 10.7337 14.2497 10.5037 14.1219 10.3666C13.8754 10.1023 13.5659 9.95515 13.2332 9.8823C13.6315 9.52944 13.9335 9.08944 14.1378 8.59801Z"
                fill="var(--accent-color)" //#4747B3
              />
            </g>
            <g clipPath="url(#clip2_8023_98597)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.779 20.9968C18.7647 20.9121 18.7539 20.8283 18.747 20.7444C18.7365 20.6187 18.743 20.4921 18.7393 20.3663C18.7377 20.313 18.7313 20.174 18.7339 20.154C18.7572 19.9797 18.8979 19.953 18.939 19.9482C18.9589 19.9454 19.1633 19.9225 19.2074 20.1492C19.2615 20.4264 19.2788 20.7054 19.2597 20.9787C19.3043 21.2111 19.3807 21.4425 19.4816 21.6521C19.8829 22.4854 20.6477 22.5663 21.4762 22.5587C21.6037 22.5568 21.7095 22.6568 21.7161 22.7835C21.7228 22.9111 21.628 23.0216 21.5011 23.033C21.038 23.0778 20.2162 23.4492 19.9321 23.8359C19.8727 23.9168 19.8381 24.1121 19.7924 24.3035C19.7286 24.5692 19.6527 24.8321 19.5677 24.9425C19.5549 24.9597 19.3998 25.1349 19.3535 25.1606C19.2422 25.2216 19.1532 25.1863 19.1026 25.154C19.052 25.1216 18.9948 25.0578 18.9719 24.9521C18.948 24.8416 18.9682 24.6149 18.9645 24.5663C18.951 24.3902 18.9158 24.0444 18.8439 23.7473C18.8051 23.5873 18.7638 23.4397 18.6954 23.3663C18.4422 23.0949 18.0375 23.1349 17.6966 23.1797C17.6575 23.1844 17.6183 23.1902 17.5793 23.1949C17.4405 23.2359 17.2932 23.2663 17.1375 23.2844C16.9023 23.3121 16.8637 23.0959 16.8616 23.0816C16.8572 23.0502 16.8409 22.8883 17.0181 22.8235C17.0375 22.8168 17.1801 22.7854 17.2351 22.773C17.3117 22.7559 17.3891 22.7425 17.4668 22.7302C18.2696 22.4806 18.7056 21.7797 18.779 20.9968ZM19.0545 21.8625C19.3576 22.4892 19.8175 22.7921 20.3626 22.9292H20.3625C20.0221 23.1073 19.7125 23.3302 19.5484 23.554C19.4861 23.6387 19.429 23.8092 19.3775 24.0006C19.3496 23.8225 19.3119 23.633 19.262 23.4711C19.2054 23.2863 19.1291 23.133 19.0439 23.0416C18.8796 22.8654 18.6733 22.7673 18.4515 22.7187C18.717 22.4835 18.9183 22.1902 19.0545 21.8625Z"
                fill="var(--accent-color)" //#4747B3
              />
            </g>
            <defs>
              <clipPath id="clip0_8023_98597">
                <rect
                  width="12.4444"
                  height="12.4444"
                  fill="white"
                  transform="translate(2 12.834)"
                />
              </clipPath>
              <clipPath id="clip1_8023_98597">
                <rect
                  width="8"
                  height="8"
                  fill="white"
                  transform="translate(10.5557 5.72266)"
                />
              </clipPath>
              <clipPath id="clip2_8023_98597">
                <rect
                  width="5.33333"
                  height="5.33333"
                  fill="white"
                  transform="translate(16.6665 19.9453)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>

        <div className="text-xs font-medium text-AccentDark new-menu">
          New & Upcoming
        </div>
      </button>

      {isOpen && (
        <div
          id="new-upcoming-list"
          className="fixed bg-Neutral000 bottom-[0px] 5xl:left-[324px] md:left-[264px] w-[310px] rounded-lg	pt-[16px] pr-[24px] pb-[32px] pl-[20px] shadow-xl flex flex-col gap-6"
        >
          <div
            className="cursor-pointer reative w-[140px] h-[33px] rounded-[20px] border-Neutral300 border flex items-center justify-between"
            onClick={() => setIsNewSelected(!isNewSelected)}
          >
            <span
              className={`absolute text-xs z-10 left-[35.5px]	 ${
                isNewSelected
                  ? "text-Neutral200 font-bold"
                  : "text-Neutral500 font-normal	"
              }`}
            >
              NEW
            </span>
            <span
              className={`absolute text-xs z-10 left-[87px] ${
                isNewSelected
                  ? "text-Neutral500 font-normal"
                  : "text-Neutral200 font-bold"
              }`}
            >
              UPCOMING
            </span>
            <div
              className={` inline-block bg-AccentRegular rounded-[20px] h-[33px] transition-transform transform ${
                isNewSelected ? "" : " translate-x-[60px] "
              }`}
            >
              <div
                className={` ${isNewSelected ? "w-[60px]" : "w-[80px]"}`}
              ></div>
            </div>
          </div>

          <ul className="flex flex-col w-full gap-6">
            {isNewSelected ? (
              <>
                {newFeatures.map((item, index) => (
                  <li
                    key={item + index}
                    className="flex items-center gap-[7px]"
                  >
                    <span className="">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M6.324 3.20304C6.27694 2.94461 6.24149 2.6891 6.21859 2.43357C6.18408 2.05027 6.20542 1.66404 6.19318 1.28074C6.18785 1.11812 6.16683 0.694176 6.1753 0.633196C6.25216 0.101802 6.71551 0.0204863 6.85103 0.00596731C6.9166 -0.00274406 7.58983 -0.0724039 7.73507 0.618698C7.91326 1.4637 7.97036 2.3145 7.90731 3.14789C8.05444 3.85641 8.30604 4.56202 8.63826 5.20085C9.96025 7.74167 12.4794 7.9885 15.2087 7.96527C15.6284 7.95946 15.9769 8.26435 15.9989 8.65056C16.0209 9.03967 15.7084 9.3765 15.2905 9.41135C13.765 9.54783 11.0582 10.6803 10.1224 11.8593C9.92667 12.1061 9.8128 12.7014 9.66221 13.285C9.45203 14.0952 9.202 14.8966 8.92186 15.2335C8.87982 15.2857 8.36877 15.82 8.21631 15.8984C7.84989 16.0843 7.55658 15.9768 7.39 15.8781C7.22342 15.7794 7.03487 15.5848 6.95958 15.2625C6.88084 14.9257 6.94735 14.2345 6.93511 14.0864C6.89056 13.5492 6.7745 12.4952 6.53765 11.5892C6.40997 11.1014 6.27381 10.6513 6.04856 10.4277C5.21471 9.6001 3.88143 9.72207 2.75866 9.85855C2.62972 9.87306 2.50079 9.8905 2.37216 9.90502C1.91508 10.0299 1.42976 10.1228 0.916839 10.178C0.142282 10.2622 0.0149231 9.603 0.00802146 9.55945C-0.00640933 9.46362 -0.0600599 8.96999 0.52376 8.77253C0.587443 8.7522 1.05739 8.65637 1.2384 8.61862C1.49063 8.56635 1.74567 8.52571 2.00166 8.48796C4.64595 7.72717 6.08213 5.58996 6.324 3.20304ZM7.23157 5.84262C8.22981 7.75331 9.74472 8.6767 11.5404 9.09485H11.5401C10.4189 9.63786 9.39901 10.3174 8.85848 10.9998C8.65331 11.2582 8.46509 11.7779 8.29537 12.3616C8.20345 11.8186 8.07923 11.2407 7.91516 10.7471C7.7285 10.1838 7.4772 9.71627 7.19674 9.43751C6.65527 8.90031 5.97577 8.60121 5.24513 8.45312C6.11976 7.73588 6.78296 6.84152 7.23157 5.84262Z"
                          fill="var(--accent-color)" //6D6DF2
                        />
                      </svg>
                    </span>
                    <span className="text-sm font-light text-Neutral700 ">
                      {item}
                    </span>
                  </li>
                ))}
              </>
            ) : (
              <>
                {upcomingFeatures.map((item, index) => (
                  <li
                    key={item + index}
                    className="flex items-center gap-[7px]"
                  >
                    <span className="">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.26249 5.92635C5.7445 4.6083 4.51448 3.03865 3.59017 1.2099C3.40531 0.845648 2.97517 0.706705 2.62678 0.901972C2.28194 1.09724 2.15038 1.55162 2.33524 1.91962C3.35198 3.92111 4.6958 5.64097 6.35955 7.0867C6.66173 7.34956 7.11319 7.30449 7.36204 6.9853C7.6109 6.66236 7.56467 6.18921 7.26249 5.92635Z"
                          fill="var(--accent-color)" //6D6DF2
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M6.45309 0.793533C6.5313 2.31061 6.60242 3.82768 6.6913 5.34476C6.71618 5.75783 7.05391 6.07326 7.44496 6.04697C7.83601 6.02069 8.13463 5.66395 8.10975 5.25088C8.02087 3.73756 7.94975 2.22425 7.87154 0.707172C7.85021 0.295233 7.51249 -0.0220783 7.12144 0.00120361C6.73039 0.0244855 6.42821 0.379341 6.45309 0.793533Z"
                          fill="var(--accent-color)" //6D6DF2
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.7311 1.10115C10.821 2.55064 9.79005 3.88745 8.75199 5.2393C8.50314 5.56225 8.54932 6.03542 8.85505 6.29452C9.16079 6.55738 9.60874 6.50855 9.85404 6.18561C10.9205 4.79996 11.9799 3.42182 12.9185 1.93103C13.1353 1.58555 13.0429 1.11993 12.7158 0.890868C12.3923 0.661804 11.9479 0.755682 11.7311 1.10115Z"
                          fill="var(--accent-color)" //6D6DF2
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15.2563 6.49054C12.7891 6.60695 10.1691 6.61071 7.70189 6.44548C7.31084 6.42295 6.97311 6.73463 6.94823 7.15145C6.92334 7.56452 7.22196 7.92125 7.61301 7.94754C10.13 8.11277 12.8034 8.10901 15.3203 7.9926C15.7114 7.97382 16.0171 7.62085 15.9993 7.20779C15.9815 6.79472 15.6474 6.47177 15.2563 6.49054Z"
                          fill="var(--accent-color)" //6D6DF2
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M6.35741 6.96689C7.87896 8.93458 9.45385 10.8647 10.8652 12.9226C11.0963 13.2568 11.5407 13.3318 11.8606 13.0878C12.177 12.8437 12.2481 12.3743 12.017 12.0401C10.5879 9.95971 8.99526 8.00705 7.45594 6.01683C7.20709 5.69764 6.75913 5.64883 6.45696 5.91169C6.15123 6.17455 6.10855 6.6477 6.35741 6.96689Z"
                          fill="var(--accent-color)" //6D6DF2
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M6.81878 7.53841C7.21694 9.58871 7.32715 11.5489 7.35559 13.6255C7.35915 14.0386 7.68266 14.369 8.07371 14.3652C8.46832 14.3577 8.78115 14.0198 8.77404 13.6029C8.7456 11.4287 8.6283 9.38218 8.21236 7.23424C8.1306 6.82868 7.75732 6.56581 7.36983 6.65217C6.98588 6.73479 6.73701 7.13285 6.81878 7.53841Z"
                          fill="var(--accent-color)" //6D6DF2
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M6.88299 7.37253C5.73117 9.80586 5.13748 12.5096 3.90388 14.8903C3.71547 15.2508 3.84342 15.7089 4.18826 15.908C4.5331 16.107 4.96328 15.9718 5.1517 15.6113C6.3924 13.2155 6.99318 10.4968 8.15568 8.0447C8.32987 7.67294 8.18767 7.22232 7.83927 7.03456C7.48733 6.85056 7.06075 7.00077 6.88299 7.37253Z"
                          fill="var(--accent-color)" //6D6DF2
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.51679 6.82099C5.2238 7.24907 2.93437 7.44434 0.62716 7.73348C0.236107 7.7823 -0.0411844 8.15407 0.00503086 8.56714C0.0512461 8.9802 0.403226 9.2731 0.794278 9.22429C3.12637 8.93514 5.44424 8.73235 7.76567 8.30051C8.14961 8.22541 8.40913 7.83487 8.34158 7.42932C8.27048 7.02001 7.90429 6.74588 7.51679 6.82099Z"
                          fill="var(--accent-color)" //6D6DF2
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.11202 5.67962C8.6392 5.65709 8.19126 5.62328 7.72911 5.55193C7.33806 5.49185 6.97899 5.77724 6.92211 6.18655C6.86523 6.59586 7.13539 6.9789 7.52289 7.03898C8.03482 7.11784 8.52898 7.15539 9.04801 7.17792C9.43906 7.19669 9.77322 6.87749 9.78744 6.46067C9.80521 6.04761 9.50307 5.69839 9.11202 5.67962Z"
                          fill="var(--accent-color)" //6D6DF2
                        />
                      </svg>
                    </span>
                    <span className="text-sm font-light text-Neutral700 ">
                      {item}
                    </span>
                  </li>
                ))}
              </>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}

export default NewAndUpcoming;
