import { Menu, Transition } from "@headlessui/react";
import { Formik } from "formik";
import update from "immutability-helper";
import moment from "moment";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { TailwindThemeContext } from "../../App";
import Delete from "../../assets/Images/delete-icon-servies.svg";
import Colour from "../../assets/json/Colour.json";
import Filter from "../../components/filter/filter";
import {
  ChangeColumnPositionInClient,
  CreateNewColumnClientAction,
  RemoveClientColumnAction,
} from "../../store/actions/clientAction";
import {
  ChangeColumnPosition,
  CreateNewColumnAction,
  RemoveColumnAction,
} from "../../store/actions/projectAction";
import {
  CreateServiceActions,
  UpdateServiceStatusActions,
} from "../../store/actions/serviceAction";
import {
  CreateTaskActions,
  UpdateTaskStatusActions,
} from "../../store/actions/tasksAction";
import PenIcon from "../Icons/PenIcon";
import EditColumnColorsModal from "../Modals/EditColumnColorModal";
import StartTimerModal from "../Modals/StartTimerModal/StartTimerModal";
import TaskDetailModal from "../Modals/TaskDetailModal/index.jsx";
import ScrrenButton from "./ScrrenButton";
import TasksBoxNew from "./Tasks/TasksBoxNew";
import SlideSwitch from "../SlideSwitch.jsx";

const CommanTaskListingNew = (props) => {
  const {
    clientId,
    allTaskList = [],
    typeIsGeneral,
    isAll,
    types,
    projectId,
    project,
    checkedTask,
    clientDetail,
    frontFilter,
    openTaskList,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const cardId = params.get("cardId");
  const isNew = params.get("isNew");

  const newDragRef = useRef(null);
  const { colors } = useContext(TailwindThemeContext);

  const [isGeneral, setIsGeneral] = useState(
    types === "task" ? "GENERAL" : "ONCE"
  );
  const [tasks, setTasks] = useState(allTaskList);
  const [task, setTask] = useState(null);
  const [column, setColumn] = useState("");
  const [isOpenColumn, setIsOpenColumn] = useState(false);
  const [issliderFocus, setIssliderFocus] = useState(false);
  const [filterBtn, setFilterBtn] = useState(false);
  const [isAnimated, setIsAnimated] = useState(false);
  const isArchive = false;
  const [isDataGettingSubmitted, setIsDataGettingSubmitted] = useState(false);
  const [isOpenTimer, setIsOpenTimer] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [addfilter, setAddFilter] = useState({
    urgentonly: [],
    status: [],
    assignedto: [],
    date: [],
    categories: [],
  });
  const [columnsName, setColumnsName] = useState(
    types === "task" ? project?.task_column : clientDetail?.service_column
  );
  const [currentColumn, setCurrentColumn] = useState(null);
  const [editColumn, setEditColumn] = useState(null);
  const [isEditColorOpen, setIsEditColorOpen] = useState(false);
  const [enabled, setEnabled] = useState(false);

  const renderColor = () => {
    const newColor = Object.keys(Colour)?.find((ele) => {
      if (types === "task")
        return !project?.task_column?.some((item) => item?.color === ele);
      if (types === "service")
        return !clientDetail?.service_column?.some(
          (item) => item?.color === ele
        );
    });
    return newColor;
  };

  useEffect(() => {
    if (typeIsGeneral) {
      setIsGeneral(typeIsGeneral);
    }
  }, [typeIsGeneral]);

  useEffect(() => {
    switch (checkedTask) {
      case 0:
        setAddFilter({
          urgentonly: [],
          status: [],
          assignedto: [],
          date: [],
          categories: [],
        });
        break;
      case 1:
        setAddFilter({
          status: [],
          assignedto: [],
          date: [],
          categories: [],
          urgentonly: ["urgent"],
        });
        break;
      case 2:
        setAddFilter({
          urgentonly: [],
          assignedto: [],
          date: [],
          categories: [],
          status: ["completed"],
        });
        break;
      case 3:
        setAddFilter({
          urgentonly: [],
          assignedto: [],
          date: [],
          categories: [],
          status: ["in_progress"],
        });
        break;
      case 4:
        setAddFilter({
          urgentonly: [],
          assignedto: [],
          date: [],
          categories: [],
          status: ["not_started"],
        });
        break;
      default:
        break;
    }
  }, [checkedTask]);

  useEffect(() => {
    const getfilterdata = allTaskList?.filter((item) => {
      return (
        item?.type === isGeneral &&
        (addfilter.urgentonly?.length
          ? addfilter.urgentonly.includes(item?.priority)
          : true) &&
        (addfilter.status?.length
          ? addfilter.status.includes(item?.tag)
          : true) &&
        (addfilter.assignedto?.length
          ? addfilter?.assignedto?.some((res) =>
              item?.members?.some((rr) => rr._id === res)
            )
          : true) &&
        (addfilter.categories?.length
          ? addfilter?.categories?.some((res) =>
              item?.category?.some((rr) => rr === res)
            )
          : true) &&
        (addfilter?.date?.length
          ? (addfilter?.date.includes("Within 7 Days") &&
              moment()
                .add(7, "days")
                .startOf("day")
                .format("YYYY-MM-DD HH:mm:ss") > item?.date &&
              moment().format("YYYY-MM-DD") < item?.date) ||
            (addfilter?.date.includes("This Month") &&
              moment().startOf("Month").format("YYYY-MM-DD") <= item?.date &&
              moment().endOf("Month").format("YYYY-MM-DD") >= item?.date) ||
            (addfilter?.date.includes("After this month") &&
              moment().add(1, "M").format("YYYY-MM-DD") > item?.date &&
              moment().endOf("month").format("YYYY-MM-DD") < item?.date)
          : true)
      );
    });
    setTasks(getfilterdata);
  }, [addfilter, allTaskList]);

  const handelSet = () => {
    setIsAnimated(true);
    setTimeout(() => {
      setIsAnimated(false);
      setFilterBtn(!filterBtn);
    }, 350);
  };

  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  function generateString(length) {
    let result = " ";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const AddTempTaskToList = (column) => {
    if (!tasks.find((list) => list.new || !list.title.trim().length)) {
      setTask({
        _id: generateString(12),
        description: null,
        column: column,
        title: "",
        type: isGeneral,
      });
      setIssliderFocus(true);
    }
  };

  const handleChangeTitle = (event) => {
    setTask({
      ...task,
      title: event.target.value,
    });
  };

  const handleSubmits = async () => {
    if (task?.title !== "") {
      if (types === "task") {
        if (!isDataGettingSubmitted) {
          setIsDataGettingSubmitted((prev) => {
            return !prev;
          });
          await dispatch(
            CreateTaskActions({
              ...task,
              client_id: clientId,
              position: tasks.length === 0 ? 0 : tasks.length,
              project: projectId,
            })
          );
          setIsDataGettingSubmitted((prev) => {
            return !prev;
          });
        }
      }
      if (types === "service") {
        await dispatch(
          CreateServiceActions({
            ...task,
            client_id: clientId,
            position: tasks.length === 0 ? 0 : tasks.length,
          })
        );
      }
      setIssliderFocus(false);
      setTask(null);
    } else {
      const filterWithoutTitle = tasks?.filter((item) => item?.title !== "");
      setTasks(filterWithoutTitle);
      setTask(null);
    }
  };

  const handleAddColumn = async () => {
    if (column && column !== "") {
      if (types === "task") {
        await dispatch(
          CreateNewColumnAction(
            {
              id: clientId,
              column: { name: column, color: renderColor() },
              type: types,
              project: projectId,
            },
            navigate,
            setIsOpenColumn,
            setColumn
          )
        );
      }
      if (types === "service") {
        await dispatch(
          CreateNewColumnClientAction(
            {
              id: clientId,
              column: { name: column, color: renderColor() },
              type: types,
            },
            navigate,
            setIsOpenColumn,
            setColumn
          )
        );
      }
    }
    setIsOpenColumn(false);
    setColumn("");
  };

  useEffect(() => {
    if (frontFilter) {
      if (frontFilter.length === 0) {
        let AllTask = allTaskList?.filter((list) => list?.type === isGeneral);
        setTasks([...AllTask]);
      } else {
        const data = allTaskList?.filter(
          (list) => list?.column === frontFilter && list?.type === isGeneral
        );
        setTasks(data);
      }
    } else {
      let getAllData = allTaskList?.filter((list) => list?.type === isGeneral);
      setTasks([...getAllData]);
    }
  }, [isGeneral, allTaskList, frontFilter]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const changeTaskStatus = useCallback(
    (
      id,
      column,
      sourceIndex,
      destinationIndex,
      isCMDefaultColumn,
      dropContext
    ) => {
      let task = tasks.find((task) => task._id === id);
      const taskIndex = tasks.indexOf(task);
      // change task tag of client have default columns
      if (isCMDefaultColumn && types === "task") {
        if (column === "To Do") {
          task = { ...task, tag: "not_started" };
        } else if (column === "Doing") {
          task = { ...task, tag: "in_progress" };
        } else if (column === "Done") {
          task = { ...task, tag: "completed" };
        }
      }
      task = { ...task, column };
      let newTasks = update(tasks, {
        [taskIndex]: { $set: task },
      });
      return reorder(newTasks, sourceIndex, destinationIndex);
    },
    [tasks]
  );

  function handleOnDragEnd(result) {
    const old_data = tasks;
    // checking whether the client have CM default column or not
    const CMColumns = columnsName.filter((item) =>
      ["To Do", "Doing", "Done"].includes(item?.name)
    );
    const isCMDefaultColumn = CMColumns?.length === 3;
    if (!result.destination) return;
    const { source, destination } = result;
    let content;
    if (source.droppableId === destination.droppableId) {
      content = reorder(tasks, result.source.index, result.destination.index);
    } else {
      content = changeTaskStatus(
        result.draggableId,
        result.destination.droppableId,
        result.source.index,
        result.destination.index,
        isCMDefaultColumn,
        result
      );
    }
    const getSpecificField = content.map((item, index) => {
      return {
        ...item,
        position: index,
      };
    });
    const updatedPosition = content.map((item, index) => {
      return {
        _id: item?._id,
        column: item?.column,
        tag: item?.tag,
        position: index,
      };
    });
    setTasks(getSpecificField);
    if (types === "task") {
      dispatch(
        UpdateTaskStatusActions(
          { tasks: updatedPosition },
          getSpecificField,
          old_data,
          {
            id: clientId,
            project: projectId,
          }
        )
      );
    }
    if (types === "service") {
      dispatch(
        UpdateServiceStatusActions(updatedPosition, getSpecificField, old_data)
      );
    }
  }

  useEffect(() => {
    setColumnsName(
      types === "task" ? project?.task_column : clientDetail?.service_column
    );
  }, [project, clientDetail]);

  const onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === "column") {
      const dragColumn = columnsName.find((item) => item?._id === draggableId);
      const newColumnOrder = Array.from(columnsName);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, dragColumn);
      setColumnsName(newColumnOrder);
      if (types === "task") {
        dispatch(
          ChangeColumnPosition(
            {
              id: clientId,
              columns: newColumnOrder,
              type: types,
              project: projectId,
            },
            navigate
          )
        );
      }
      if (types === "service") {
        dispatch(
          ChangeColumnPositionInClient(
            {
              id: clientId,
              columns: newColumnOrder,
              type: types,
            },
            navigate
          )
        );
      }
      return;
    }
    handleOnDragEnd(result);
  };

  // const handleChangeArchive = async () => {
  //   const tempIsArchive = !isArchive;
  //   setIsArchive(tempIsArchive);
  //   if (isAPICalling) {
  //     setIsArchiveLoading({ status: true, id: "all" });
  //     setIsAPICalling(false);
  //     if (types === "task") {
  //       dispatch(
  //         GetTasksActions(
  //           clientId,
  //           projectId,
  //           tempIsArchive,
  //           setIsArchiveLoading
  //         )
  //       );
  //     }
  //     if (types === "service") {
  //       dispatch(
  //         GetServicesActions(clientId, tempIsArchive, setIsArchiveLoading)
  //       );
  //     }
  //   } else {
  //     const archiveData = allTaskList?.filter((list) =>
  //       tempIsArchive
  //         ? list && list?.type === isGeneral
  //         : list?.is_archived === false && list?.type === isGeneral
  //     );
  //     setTasks(archiveData);
  //   }
  // };

  const onApplyColor = async (
    column,
    type = "Default",
    color = "",
    name,
    setIsOpen
  ) => {
    const colIdx = columnsName.findIndex((item) => item?._id === column?._id);
    if (colIdx > -1) {
      setColumnsName((columnsName) => {
        if (type === "Default") {
          return update(columnsName, {
            [colIdx]: {
              color: {
                $set: color,
              },
            },
          });
        } else {
          return update(columnsName, {
            [colIdx]: {
              color: {
                $set: color,
              },
              name: {
                $set: name,
              },
            },
          });
        }
      });
    }
    if (types === "task") {
      await dispatch(
        CreateNewColumnAction(
          {
            id: clientId,
            column: {
              ...column,
              color,
              name,
            },
            old_name: column?.name,
            type: types,
            project: projectId,
          },
          navigate,
          setIsOpen
        )
      );
    }
    if (types === "service") {
      await dispatch(
        CreateNewColumnClientAction(
          {
            id: clientId,
            column: {
              ...column,
              color,
              name,
            },
            old_name: column?.name,
            type: types,
          },
          navigate,
          setIsOpen
        )
      );
    }
  };

  return (
    <div className="5xl:px-[96px] pt-0 max-h-[calc(100vh-300px)] overflow-y-auto">
      <div className="flex items-center gap-2 mt-8 mb-3 text-xs">
        <span
          className={`font-medium ${
            !enabled ? "text-AccentRegular" : "text-Neutral700"
          }`}
        >
          Simplified Tasks
        </span>
        <SlideSwitch checked={enabled} onChange={setEnabled} />
        <span
          className={`font-medium ${
            enabled ? "text-AccentRegular" : "text-Neutral700"
          }`}
        >
          Expanded Tasks
        </span>
      </div>

      {!isAll && (
        <div className="flex flex-col justify-end w-full gap-1 right-12 md:flex-row">
          {/* {!isAll && (
              <div className="w-full relative max-w-[270px] h-[34px]">
                <div className="rounded border border-Neutral300 p-2 mr-2  h-[34px] flex justify-between items-center">
                  <div className="flex items-center w-full justify-evenly">
                    <h5 className="text-Neutral700 text-xs font-bold tracking-[0.1em] uppercase mr-2">
                      {isArchive
                        ? `Hide Archived ${types}s`
                        : `View Archived ${types}s`}
                    </h5>
                    <label
                      className="flex items-center cursor-pointer select-none toggle-switch"
                      htmlFor="list-toggle"
                    >
                      <div className="relative">
                        <input
                          type="checkbox"
                          id="list-toggle"
                          className="sr-only"
                          checked={isArchive}
                          onChange={handleChangeArchive}
                        />
                        <div className="block h-[24px] switch w-[44px] rounded-full border-Neutral400 border-2 bg-Neutral100"></div>
                        <div className="dot absolute left-0 top-0 h-6 w-6 rounded-full bg-Neutral800 transition shadow-[0px_1.5px_3px_1px_rgba(0,0,0,0.2)]"></div>
                      </div>
                    </label>
                  </div>
                </div>
                {isArchiveLoading?.status && isArchiveLoading?.id === "all" && (
                  <div className="absolute top-0 right-0">
                    <span class="relative flex h-3 w-3">
                      <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-AccentMediumLight opacity-75"></span>
                      <span class="relative inline-flex rounded-full h-3 w-3 bg-AccentRegular"></span>
                    </span>
                  </div>
                )}
              </div>
            )} */}

          {types === "service" && clientDetail?.service_column?.length < 6 ? (
            <div className="relative flex">
              <div className="z-20 inline-block text-left">
                <button
                  className="uppercase text-Neutral700 font-medium text-xs bg-white border rounded mr-[8px] cursor-pointer border-Neutral400 flex px-3 py-2 leading-[16.3px] tracking-wider text-center items-center justify-center 5xl:h-8 5xl:text-sm"
                  onClick={() => {
                    setIsOpenColumn(true);
                  }}
                >
                  Add Column
                </button>
              </div>
            </div>
          ) : (
            <div />
          )}

          {!isAll && (
            <div className="relative flex">
              <ScrrenButton
                types={types}
                project={project}
                isGeneral={isGeneral}
                setIsGeneral={setIsGeneral}
                clientId={clientId}
                projectId={projectId}
                clientDetail={clientDetail}
              />
              {/* <FilterButton
                  numFilters={numFilters}
                  isFilterBtnActive={isFilterBtnActive}
                  setFilterBtn={setFilterBtn}
                /> */}
            </div>
          )}
        </div>
      )}

      <div
        className={`overflow-auto flex ${
          isAll && "bg-Neutral100 p-5"
        } pr-0 scrollbar-h-4`}
      >
        <DragDropContext onDragEnd={onDragEnd} ref={newDragRef}>
          <Droppable
            droppableId="all-columns"
            direction="horizontal"
            type="column"
          >
            {(provided) => (
              <div
                className="flex"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {columnsName?.map((channel, index) => {
                  const this_col_color = Colour[channel?.color];
                  const columnTasks = tasks
                    ?.sort((a, b) => a?.position - b?.position)
                    .filter((item) => item?.column === channel?.name)
                    .filter((item) => (isArchive ? item : !item?.is_archived));
                  return (
                    <Draggable
                      draggableId={channel?._id}
                      index={index}
                      key={channel?._id}
                    >
                      {(provided) => (
                        <div
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                          className="flex flex-col mr-5 min-w-[160px]"
                        >
                          <div
                            {...provided.dragHandleProps}
                            className={`5xl:max-w-[400px] md:min-w-[20vw] xl:w-[90%] 2xl:w-full   xl:min-w-[288px] max-w-[288px] rounded-[6px] w-full border h-fit p-[12px] mr-5`}
                            style={{
                              ...(isAll
                                ? { border: "none", padding: 0 }
                                : {
                                    borderColor: this_col_color?.border
                                      ? `${colors[this_col_color?.border]}${
                                          this_col_color?.opacity
                                        }`
                                      : `${channel?.color + "01"}`,
                                    backgroundColor: this_col_color?.background
                                      ? `${colors[this_col_color?.background]}${
                                          this_col_color?.opacity
                                        }`
                                      : `${channel?.color + "03"}`,
                                  }),
                            }}
                          >
                            <div
                              className={`flex ${
                                isAll ? "" : "justify-between"
                              } mb-4`}
                            >
                              <h3
                                className={`flex gap-1 text-${
                                  this_col_color?.color
                                } text-xs font-bold uppercase align-center ${
                                  isAll
                                    ? `bg-${this_col_color?.headingBackground} text-${this_col_color?.headingColor} flex justify-center py-[6px] px-4 rounded w-full`
                                    : ""
                                }`}
                                style={{
                                  ...(isAll
                                    ? {
                                        borderColor: `${
                                          colors[this_col_color?.border]
                                        }${this_col_color?.opacity}`,
                                        backgroundColor:
                                          this_col_color?.headingBackground
                                            ? `${
                                                colors[
                                                  this_col_color
                                                    ?.headingBackground
                                                ]
                                              }${this_col_color?.opacity}`
                                            : `${channel?.color + "08"}`,
                                        color: this_col_color?.headingColor
                                          ? `${
                                              colors[
                                                this_col_color?.headingColor
                                              ]
                                            }`
                                          : `${channel?.color}`,
                                      }
                                    : !this_col_color
                                    ? {
                                        color: channel?.color,
                                      }
                                    : {}),
                                }}
                              >
                                <span
                                  className={`truncate max-w-[190px] text-${this_col_color?.color} ${
                                    isAll
                                      ? `text-${this_col_color?.headingColor} tracking-wider`
                                      : ""
                                  }`}
                                >
                                  {channel?.name}
                                </span>{" "}
                                {!isAll && <span>{columnTasks?.length}</span>}
                              </h3>
                              {/* {!isAll && ( */}
                              <div
                                className={`flex items-center justify-end ml-[-12px]`}
                              >
                                <Menu
                                  as="div"
                                  className="relative inline-block"
                                >
                                  <div className="flex items-center">
                                    <Menu.Button className="relative inline-flex justify-center w-full focus-visible:outline-none">
                                      <span className="focus-visible:outline-none">
                                        <svg
                                          width="3"
                                          height="10"
                                          viewBox="0 0 3 10"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M1.5 5.5C1.77614 5.5 2 5.27614 2 5C2 4.72386 1.77614 4.5 1.5 4.5C1.22386 4.5 1 4.72386 1 5C1 5.27614 1.22386 5.5 1.5 5.5Z"
                                            className={`stroke-${channel?.color}600 fill-${channel?.color}600`}
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            style={
                                              !this_col_color
                                                ? {
                                                    stroke: channel?.color,
                                                    fill: channel?.color,
                                                  }
                                                : {}
                                            }
                                          />
                                          <path
                                            d="M1.5 2C1.77614 2 2 1.77614 2 1.5C2 1.22386 1.77614 1 1.5 1C1.22386 1 1 1.22386 1 1.5C1 1.77614 1.22386 2 1.5 2Z"
                                            className={`stroke-${channel?.color}600 fill-${channel?.color}600`}
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            style={
                                              !this_col_color
                                                ? {
                                                    stroke: channel?.color,
                                                    fill: channel?.color,
                                                  }
                                                : {}
                                            }
                                          />
                                          <path
                                            d="M1.5 9C1.77614 9 2 8.77614 2 8.5C2 8.22386 1.77614 8 1.5 8C1.22386 8 1 8.22386 1 8.5C1 8.77614 1.22386 9 1.5 9Z"
                                            className={`stroke-${channel?.color}600 fill-${channel?.color}600`}
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            style={
                                              !this_col_color
                                                ? {
                                                    stroke: channel?.color,
                                                    fill: channel?.color,
                                                  }
                                                : {}
                                            }
                                          />
                                        </svg>
                                      </span>
                                    </Menu.Button>
                                  </div>
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items className="z-10 absolute p-1 min-w-[129px] rounded-[4px] right-0 Timetrackingdropdown bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none innermenu-main">
                                      <Menu.Item>
                                        <div
                                          className="w-[100%] text-left pl-2 py-2 text-xs whitespace-nowrap flex justify-start items-center cursor-pointer text-Neutral800 font-semibold transition duration-300 hover:text-AccentRegular hover:fill-AccentRegular"
                                          onClick={() => {
                                            setCurrentColumn(channel);
                                            setEditColumn(channel);
                                            setIsEditColorOpen(true);
                                          }}
                                        >
                                          {/* <img
                                                src={Duplicate}
                                                className="mr-[8px]"
                                              />
                                              Duplicate */}
                                          <PenIcon
                                            width={16}
                                            height={16}
                                            className="flex items-center justify-center mr-2"
                                            color="currentColor"
                                          />
                                          <span className="text-xs leading-[16px] tracking-[0.04em] font-medium">
                                            Edit Column
                                          </span>
                                        </div>
                                      </Menu.Item>
                                      {(types === "task" &&
                                        columnsName?.length > 3) ||
                                      (types === "service" &&
                                        columnsName?.length > 2) ? (
                                        <Menu.Item>
                                          <Menu
                                            as="div"
                                            className="relative"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                            }}
                                          >
                                            <div className="">
                                              <Menu.Button className="w-full focus-visible:outline-none">
                                                <span className="focus-visible:outline-none">
                                                  <div className="w-[100%] text-left pl-2 py-2 text-xs whitespace-nowrap flex justify-start items-center cursor-pointer text-Neutral800 font-semibold hover:bg-Neutral200 transition duration-300 hover:text-Neutral900 edite-hover">
                                                    <img
                                                      src={Delete}
                                                      alt="icon"
                                                      className="mr-2"
                                                    />
                                                    Delete
                                                  </div>
                                                </span>
                                              </Menu.Button>
                                            </div>
                                            <Transition
                                              as={Fragment}
                                              enter="transition ease-out duration-100"
                                              enterFrom="transform opacity-0 scale-95"
                                              enterTo="transform opacity-100 scale-100"
                                              leave="transition ease-in duration-75"
                                              leaveFrom="transform opacity-100 scale-100"
                                              leaveTo="transform opacity-0 scale-95"
                                            >
                                              <Menu.Items
                                                className="origin-top-right absolute -top-[80px] right-[-20px] pt-4 pb-6 w-[238px] px-6 rounded-lg Timetrackingdropdown bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none"
                                                onClick={(e) => {
                                                  if (
                                                    e.target === e.currentTarget
                                                  ) {
                                                    e.stopPropagation();
                                                  }
                                                }}
                                              >
                                                <div className="">
                                                  <p className="pb-2 text-xs font-semibold text-Neutral700">
                                                    Are you sure?
                                                  </p>
                                                </div>
                                                <div
                                                  className="overflow-hidden border-0"
                                                  onClick={async () => {
                                                    if (types === "task") {
                                                      await dispatch(
                                                        RemoveColumnAction(
                                                          {
                                                            id: clientId,
                                                            remove_column:
                                                              channel,
                                                            type: types,
                                                            project: projectId,
                                                          },
                                                          navigate
                                                        )
                                                      );
                                                    }
                                                    if (types === "service") {
                                                      await dispatch(
                                                        RemoveClientColumnAction(
                                                          {
                                                            id: clientId,
                                                            remove_column:
                                                              channel,
                                                            type: types,
                                                          },
                                                          navigate
                                                        )
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <h3 className="w-full py-4 mt-5 text-xs font-semibold text-center text-white duration-300 rounded cursor-pointer bg-Red400 hover:bg-Red600">
                                                    Yes, Delete
                                                  </h3>
                                                </div>
                                                <div className="overflow-hidden border-0 !border-transparent divide-0">
                                                  <h3 className="w-full py-4 mt-3 text-xs font-semibold text-center duration-300 rounded cursor-pointer text-Neutral800 bg-Neutral200 hover:bg-Neutral500 hover:text-Neutral900">
                                                    No, Please Keep It
                                                  </h3>
                                                </div>
                                              </Menu.Items>
                                            </Transition>
                                          </Menu>
                                        </Menu.Item>
                                      ) : (
                                        <div />
                                      )}
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              </div>
                              {/* )} */}
                            </div>
                            <Droppable droppableId={channel?.name}>
                              {(provided) => (
                                <div
                                  className="dropped-container min-h-[1px]"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  {columnTasks?.map((item, i) => {
                                    return (
                                      <Draggable
                                        key={item?._id}
                                        draggableId={item?._id}
                                        index={item?.position}
                                      >
                                        {(provided) => (
                                          <div
                                            className="list-none drop-list-item"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            onClick={() =>
                                              setCurrentColumn(channel)
                                            }
                                          >
                                            <TasksBoxNew
                                              item={item}
                                              enabled={enabled}
                                            />
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  })}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                            {task && task?.column === channel?.name && (
                              <div
                                className={`border border-Neutral300 bg-white py-3 pl-4 pr-1 rounded-[6px] relative cursor-pointer mb-4 ${
                                  issliderFocus
                                    ? "tasklists-focus drop-shadow-Purpleboxshadow"
                                    : ""
                                }`}
                              >
                                <Formik
                                  initialValues={{
                                    text: "",
                                  }}
                                  onSubmit={handleSubmits}
                                >
                                  {({ handleSubmit, handleChange }) => (
                                    <form
                                      onSubmit={handleSubmit}
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          handleSubmit();
                                        }
                                      }}
                                    >
                                      <textarea
                                        name="text"
                                        placeholder={
                                          types === "task"
                                            ? "Add task"
                                            : "Add service"
                                        }
                                        onChange={(event) => {
                                          handleChangeTitle(event);
                                          handleChange(event);
                                        }}
                                        className="text-Neutral800 text-sm pr-[76px] content 5xl:max-w-[352px] w-full focus-visible:outline-none resize-none "
                                        onBlur={() => handleSubmits()}
                                        autoFocus
                                      />
                                    </form>
                                  )}
                                </Formik>
                              </div>
                            )}
                            <button
                              className={`flex justify-center items-center py-[14px] pl-4 pr-1 border-[1px] border-dashed border-${this_col_color?.dashBorder} rounded-lg cursor-pointer w-full`}
                              onClick={() => AddTempTaskToList(channel?.name)}
                              style={
                                !this_col_color
                                  ? {
                                      borderColor: channel?.color,
                                    }
                                  : {}
                              }
                            >
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M13.6569 8H2.34315"
                                  className={`stroke-${channel?.color}600`}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  style={
                                    !this_col_color
                                      ? {
                                          stroke: channel?.color,
                                        }
                                      : {}
                                  }
                                />
                                <path
                                  d="M8 2.34315V13.6569"
                                  className={`stroke-${channel?.color}600`}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  style={
                                    !this_col_color
                                      ? {
                                          stroke: channel?.color,
                                        }
                                      : {}
                                  }
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {(isOpenColumn ||
          (types === "task" && project?.task_column?.length < 6) ||
          (types === "service" &&
            clientDetail?.service_column?.length < 6 &&
            isAll)) && (
          <div className="5xl:max-w-[400px] md:min-w-[20vw] xl:w-[90%] 2xl:w-full xl:min-w-[288px] max-w-[288px] min-h-[300px] mr-5 2xl:mr-0">
            {isOpenColumn ? (
              <div
                className={`5xl:max-w-[400px] md:min-w-[20vw] xl:w-[90%] 2xl:w-full rounded-[6px] bg-${
                  Colour[renderColor()]?.background
                }/[0.1] border border-${
                  Colour[renderColor()]?.border
                } h-fit p-[12px] mr-[20px]`}
              >
                <div className="flex justify-between mb-4">
                  <input
                    name="text"
                    placeholder="Add column"
                    value={column}
                    onChange={(event) => {
                      setColumn(event.target.value);
                    }}
                    className={`text-${
                      Colour[renderColor()]?.color
                    } text-xs font-bold focus:outline-none w-auto bg-${
                      Colour[renderColor()]?.background
                    }/[0.1]`}
                    onBlur={() => {
                      handleAddColumn();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleAddColumn();
                      }
                    }}
                    autoFocus={isOpenColumn}
                  />
                </div>
                <button
                  className={`flex justify-start items-baseline w-full py-[14px] pl-4 pr-1 border-[1px] border-dashed border-${
                    Colour[renderColor()]?.dashBorder
                  } rounded-lg cursor-pointer`}
                >
                  <div
                    className={`font-semibold text-sm max-w-[100%] w-full text-center text-${
                      Colour[renderColor()]?.iconColor
                    } cursor-pointer`}
                  >
                    +
                  </div>
                </button>
              </div>
            ) : (
              <button
                className={`flex justify-start items-baseline  md:w-full max-w-[288px] min-w-[170px] py-[14px] pl-4 pr-1 border-[1px] border-dashed border-${
                  Colour[renderColor()]?.dashBorder
                } rounded-lg cursor-pointer`}
                onClick={() => {
                  setIsOpenColumn(true);
                }}
              >
                <div
                  className={`font-semibold text-sm max-w-[100%] w-full text-center text-${
                    Colour[renderColor()]?.iconColor
                  } cursor-pointer`}
                >
                  +
                </div>
              </button>
            )}
          </div>
        )}
      </div>

      {isAll && Object.keys(openTaskList).length ? (
        cardId &&
        !isNew &&
        allTaskList.filter(
          (item) =>
            item._id === cardId &&
            Object.keys(openTaskList).includes(item.project)
        ).length ? (
          <TaskDetailModal
            clientId={clientId}
            types={types}
            projectId={projectId}
            columnsName={columnsName}
            column={currentColumn}
            project={project}
            setIsOpenTimer={setIsOpenTimer}
            setSelectedTask={setSelectedTask}
          />
        ) : (
          <div />
        )
      ) : cardId && !isNew ? (
        <TaskDetailModal
          clientId={clientId}
          types={types}
          projectId={projectId}
          columnsName={columnsName}
          column={currentColumn}
          project={project}
          setIsOpenTimer={setIsOpenTimer}
          setSelectedTask={setSelectedTask}
        />
      ) : (
        <div />
      )}
      <Filter
        filterBtn={filterBtn}
        setFilterBtn={setFilterBtn}
        handelSet={handelSet}
        isAnimated={isAnimated}
        setIsAnimated={setIsAnimated}
        addfilter={addfilter}
        setAddFilter={setAddFilter}
      />
      <EditColumnColorsModal
        isOpen={isEditColorOpen}
        setIsOpen={setIsEditColorOpen}
        editColumn={editColumn}
        onApplyColor={onApplyColor}
      />
      <StartTimerModal
        isStartTimerModalOpen={isOpenTimer}
        setIsStartTimerModalOpen={setIsOpenTimer}
        selectedTask={selectedTask}
        selectedProject={projectId}
        selectedClient={clientId}
      />
    </div>
  );
};

export default CommanTaskListingNew;
