import moment from "moment";
import React, { Fragment, useRef, useState } from "react";
import clx from "classnames";
import { Menu, Transition } from "@headlessui/react";

import ChevronDownIcon from '../../Icons/ChevronDownIcon';

// TODO: add onDeleteLead function
function LeadCard({ lead = null, onDeleteLead = () => {}, isLoading = false }) {
  const deleteButtonRef = useRef();
  const [showNotes, setShowNotes] = useState(false);

  if (!lead) {
    return null;
  }

  const notesList = lead?.notes?.map((note, idx) => {
    let createdAt = moment(note?.created_at).calendar();
    if (createdAt.includes(' at ')) {
      createdAt = createdAt.replace(' at ', ', ');
    }
    return (
      <li key={note?._id ?? idx}>
        <div className='flex flex-col w-[100%] gap-4'>
          <p
            className='text-Neutral700 text-xs leading-[20px] font-normal'
            dangerouslySetInnerHTML={{ __html: note?.text }}
          />
          <div className='pb-2 border-b-[0.5px] border-Neutral300 flex items-center justify-between'>
            <div className='flex items-center justify-start'>
              {note?.last_updated_by?.profile ? (
                <img
                  src={note?.last_updated_by?.profile_path}
                  alt={`${note?.last_updated_by?.user_name}`}
                  width={20}
                  height={20}
                  className="rounded-[50%] mr-2"
                  title={`${note?.last_updated_by?.user_name}`}
                />
              ) : (
                <div className="w-[20px] h-[20px] rounded-[50%] mr-2 bg-Neutral300">
                  <p className="text-Neutral700 text-[10px] font-semibold leading-[14px] text-center pt-[2px]">
                    {note?.last_updated_by?.user_name?.charAt(0).toUpperCase()}
                  </p>
                </div>
              )}
              <span className='text-Neutral800 text-[10px] font-medium leading-[14px]'>
                {note?.last_updated_by?.user_name}
              </span>
            </div>
            <div className='flex items-center justify-start h-[14px]'>
              <span className='text-Neutral600 text-[10px] font-normal leading-[12px]'>
                {createdAt}
              </span>
            </div>
          </div>
        </div>
      </li>
    );
  });

  return (
    <li className="flex-col items-start">
      {/* Meta */}
      <div className="flex border-b-[0.5px] border-Neutral300 justify-between w-full pb-[18px]">
        <div className="flex justify-between flex-col w-full">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center justify-start gap-3">
              <div
                className={`h-[28px] w-[28px] min-w-[28px] 5xl:h-16 5xl:w-16 rounded-[50%] items-center justify-center bg-Neutral400 text-Neutral700`}
              >
                {lead.profile_path ? (
                  <img
                    src={lead.profile_path}
                    width={28}
                    height={28}
                    alt={`${lead?.name} avatar`}
                    className="rounded-[50%]"
                  />
                ) : (
                  <span className=" text-base w-full h-full flex items-center justify-center rounded-full font-semibold border border-Miscgrey capitalize">
                    {lead?.name?.charAt(0)}
                  </span>
                )}
              </div>
              <h3 className="text-base font-medium text-Neutral900 leading-[21.8px] pb-[2px]">
                {lead?.name}
              </h3>
            </div>
            <Menu
              className="relative z-1"
              as="div"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Menu.Button
                ref={deleteButtonRef}
                className="z-1 w-[90px] flex items-center justify-center  text-Red500 text-[10px] font-semibold bg-Neutral200 rounded hover:bg-Neutral300 duration-300 transition-all p-2"
              >
                {isLoading && isLoading === lead?._id ? (
                  <svg
                    className="animate-spin -ml-1 h-5 w-5 text-Red500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12z"
                    ></path>
                  </svg>
                ) : (
                  "Delete"
                )}
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute -top-[16px] right-[10px] pt-3 pb-4 w-[238px] px-6 rounded-lg Timetrackingdropdown bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none z-90">
                  <p className="font-semibold text-xs text-Neutral700 pb-2 border-b-[0.5px] border-Neutral300">
                    Are you sure?
                  </p>{" "}
                  <button
                    className="border-0 overflow-hidden w-[100%] mt-3"
                    onClick={(e) => {
                      setTimeout(() => {
                        deleteButtonRef?.current?.click();
                        e.stopPropagation();
                      }, 0);
                      onDeleteLead(lead?._id);
                    }}
                    id="convert-lead-to-client-btn"
                  >
                    <h3 className="text-white text-xs  w-full bg-Red400 font-semibold py-3 rounded text-center cursor-pointer hover:bg-Red600 duration-300">
                      Yes, delete
                    </h3>
                  </button>
                  <Menu.Button className="overflow-hidden border-0 !border-transparent divide-0 w-[100%]">
                    <h3 className="text-Neutral800 bg-Neutral200 font-semibold text-xs mt-3 w-full py-3 rounded text-center cursor-pointer hover:bg-Neutral500 duration-300 hover:text-Neutral900">
                      No, keep it
                    </h3>
                  </Menu.Button>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2">
            <div className="flex flex-col min-w-[150px] gap-y-[6px] ">
              <div className="flex items-center justify-between md:justify-start gap-5">
                <span className="min-w-[84px] uppercase text-Neutral500 text-[10px] font-semibold leading-[18px] tracking-[4%]">
                  Company
                </span>
                <span className="font-semibold text-Neutral700 text-[10px] leading-[13.6px]">
                  {lead?.business_name}
                </span>
              </div>

              <div className="flex items-center justify-between md:justify-start gap-5">
                <span className="min-w-[84px] uppercase text-Neutral500 text-[10px] font-semibold leading-[18px] tracking-[4%]">
                  Proposal sent
                </span>
                <span className="font-semibold text-Neutral700 text-[10px] leading-[13.6px] uppercase">
                  {lead?.proposal ? "yes" : "no"}
                </span>
              </div>

              <div className="flex items-center justify-between md:justify-start gap-5">
                <span className="min-w-[84px] uppercase text-Neutral500 text-[10px] font-semibold leading-[18px] tracking-[4%]">
                  Budget
                </span>
                <span className="font-semibold text-Neutral700 text-[10px] leading-[13.6px] uppercase">
                  ${lead?.budget}
                </span>
              </div>
            </div>

            <div className='flex flex-col min-w-[170px] gap-y-[6px]'>
              <div className='flex items-center justify-between md:justify-start gap-5'>
                <span className='min-w-[62px] uppercase text-Neutral500 text-[10px] font-semibold leading-[18px] tracking-[4%]'>
                  Date added
                </span>
                <span className="font-semibold text-Neutral700 text-[10px] leading-[13.6px]">
                  {moment(lead?.archived_at).format("DD MMM, YYYY")}
                </span>
              </div>

              <div className='flex items-center justify-between md:justify-start gap-5'>
                <span className='min-w-[62px] uppercase text-Neutral500 text-[10px] font-semibold leading-[18px] tracking-[4%]'>
                  Contact
                </span>
                <span className='font-semibold text-Neutral700 text-[10px] leading-[13.6px] uppercase w-fit'>
                  {lead?.phone_number}
                </span>
              </div>

              <div className='flex items-center justify-between md:justify-start gap-5'>
                <span className='min-w-[62px] uppercase text-Neutral500 text-[10px] font-semibold leading-[18px] tracking-[4%]'>
                  Source
                </span>
                <span className="font-semibold text-Neutral700 text-[10px] leading-[13.6px] capitalize">
                  {lead?.source}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Notes */}
      {!!lead?.notes?.length && (
        <Menu as={"div"} className="pt-2">
          <div className="flex justify-between">
            <Menu.Button
              as='button'
              className='text-AccentRegular font-medium text-[10px] leading-[18px] flex items-center justify-start gap-1'
              onClick={() => setShowNotes((cur) => !cur)}
            >
              <ChevronDownIcon className={`${showNotes ? "rotate-180" : ""}`} />
              Notes
            </Menu.Button>
            <span
              className={`h-[16px] w-[16px] min-w-[16px] rounded-full font-bold text-[8px] leading-[11.2px] flex items-center justify-center tracking-[-10%] ${
                showNotes
                  ? "bg-AccentRegular text-Neutral000"
                  : "bg-AccentLight text-AccentRegular"
              }`}
            >
              {lead?.notes?.length}
            </span>
          </div>
          {/* all notes */}
          <Transition
            show={showNotes}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="-translate-y-[25%] opacity-0"
            enterTo="translate-y-0 opacity-100"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-y-0 opacity-100"
            leaveTo="-translate-y-[25%] opacity-0"
          >
            <ul
              className={clx(
                'w-[100%] h-auto max-h-[200px] flex flex-col gap-6 overflow-y-scroll pr-1 mt-4 scrollbar-w-4'
              )}
            >
              {notesList}
            </ul>
          </Transition>
        </Menu>
      )}
    </li>
  );
}

export default LeadCard;
