import axios from "axios";


async function getBase64Image (url) {
    const data = await axios(url);
    return data.data.data
  }

const syncImages = async () => {
    let element = document.getElementById("froala-editor-view");
   for (const ele of element.querySelectorAll('img')) {
    if(ele.currentSrc.startsWith('http')) {
        const fileName = ele.currentSrc.split(process.env.REACT_APP_BUCKET_NAME)[1];
        ele.src = await getBase64Image(`/api/v1/proposal/get-proposals-documents?filename=${fileName}`)
      }
   }
    return true
  }

  export default syncImages