import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const getBase64Image = async (url) => {
  const data = await axios(url);
  return data.data.data;
};

const downloadInvoice = async (invoice, setIsLoading) => {
  setIsLoading(true);

  const filename = `${invoice?.invoiceName}-${invoice?.invoiceNumber}-${invoice?.clientName}-${invoice?.invoiceDate}.pdf`;
  const invoicePage = document.getElementById("invoice-preview");
  const logo = document.getElementById("cm_logo");

  // Hide elements with the "no-print" class
  const noPrintElements = document.querySelectorAll(".no-print");
  noPrintElements.forEach((el) => {
    el.style.display = "none";
  });

  try {
    if (logo && logo.currentSrc.startsWith("http")) {
      const fileName = logo.currentSrc.split(
        process.env.REACT_APP_BUCKET_NAME
      )[1];
      const base64Image = await getBase64Image(
        `/api/v1/invoice/get-invoice-base64?filename=${fileName}`
      );
      logo.src = base64Image;

      // Wait for the logo to wait before continuing else it won't be visible in the downloaded file.
      await new Promise((resolve) => (logo.onload = resolve));
    }

    const canvas = await html2canvas(invoicePage, {
      scale: 3,
      useCORS: true,
    });

    // Restore visibility of elements after capturing the canvas
    noPrintElements.forEach((el) => {
      el.style.display = "";
    });

    const imgData = canvas.toDataURL("image/jpeg");
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: "a4",
    });

    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
    pdf.save(filename);

    setIsLoading(false);
  } catch (error) {
    console.error("PDF generation error:", error);
    setIsLoading(false);
  }
};

export default downloadInvoice;
