import Vimeo from "@u-wave/react-vimeo";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import ReactTooltip from "react-tooltip";

import Avtar from "../../assets/Images/Avatar.svg";
import AddFirstClient from "../../assets/Images/add-first-client.svg";
import BuildAQuestionnare from "../../assets/Images/build-a-questionnaire.svg";
import CloseBtn from "../../assets/Images/close-btn.svg";
import InviteTeamMember from "../../assets/Images/invite-team-member.svg";
import welcomeMenager from "../../assets/Images/welcome-client-menager.svg";
import Fullcalander from "../../components/DashboardCalender/Fullcalander";
import CreateClientModal from "../../components/Modals/CreateClientModal";
import CreateMemberModal from "../../components/Modals/CreateMemberModal";
import ExpandTeamMemberModal from "../../components/Modals/ExpandTeamMember";
import ExpandTeamsModal from "../../components/Modals/ExpandTeamMembers";
import PreWelcomeModal from "../../components/Modals/PreWelcomeModal";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import {
  GetUserPlanDetailAction,
  welcomeModalCloseAction,
} from "../../store/actions/authAction";
import {
  GetClientAction,
  getFullClientList,
} from "../../store/actions/clientAction";
import { getMemberList } from "../../store/actions/memberAction";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
    // oveflow:"scroll",
  },
};
const customStylesWelcomeClient = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.4)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const client = useSelector((state) => state.client.fullClientList);
  const user = useSelector((state) => state.auth.user);
  const member = useSelector((state) => state.member.members);
  const [selctedClient, setSelectedClint] = useState();
  const [selectedTeamMemberId, setSelectedTeamMemberId] = useState(null);
  const authSelector = useSelector((state) => state.auth);
  const [createClientModal, setCreateClientModal] = useState(false);
  const [createMemberModal, setCreateMemberModal] = useState(false);
  const [editTeamMembersModal, setEditTeamMembersModal] = useState(false);
  const [welcomeClient, setwelcomeClient] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setwelcomeClient(true);
    }, 2000);
  }, []);

  useEffect(() => {
    dispatch(GetClientAction({ page: 1, perPage: 10, search: "" }));
    if (user && !user?.invited) {
      dispatch(GetUserPlanDetailAction());
    }
  }, [dispatch]);

  useEffect(() => {
    if (!client?.data?.length) {
      dispatch(getFullClientList());
    }
    if (!member?.data?.length) {
      dispatch(getMemberList());
    }
  }, [dispatch]);

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  const closeWelcomeModal = async () => {
    dispatch(welcomeModalCloseAction());
    setwelcomeClient(false);
  };

  let handleFocusMemberView = (teamMemberId = null) => {
    if (teamMemberId) {
      setEditTeamMembersModal(false);
      setSelectedTeamMemberId(teamMemberId);
    }
  };

  const handleAddClient = () => {
    navigate("/admin/clients");
  };

  const handleAddTeamMemberCTA = () => {
    closeWelcomeModal();
    setCreateMemberModal(true);
  };

  const renderIndividualPlanSlides = () => {
    return (
      <>
        <SwiperSlide>
          <div
            onClick={handleAddClient}
            className="bg-Neutral100 rounded-3xl border border-Neutral300 text-center pt-[24px] pb-10 hover:bg-Neutral200 hover:border-Neutral400 transition duration-300 cursor-pointer"
          >
            <div className="max-w-[302px] w-full mx-auto flex flex-col min-h-[271px] justify-between">
              <img className="mx-auto" src={AddFirstClient} alt="" />
              <div className="">
                <h4 className="text-Neutral900 font-bold text-xl leading-[27px] mb-[10px]">
                  Add Your First Client
                </h4>
                <p className="text-base font-medium text-Neutral900 opacity-80">
                  Straight to the point! Get started with ClientManager by
                  adding a new client.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            onClick={handleAddTeamMemberCTA}
            className="bg-Neutral100 rounded-3xl border border-Neutral300 text-center pt-[24px] pb-10 hover:bg-Neutral200 hover:border-Neutral400 transition duration-300 cursor-pointer"
          >
            <div className="max-w-[302px] w-full mx-auto flex flex-col min-h-[271px] justify-between">
              <img className="mx-auto" src={BuildAQuestionnare} alt="" />
              <div className="">
                <h4 className="text-Neutral900 font-bold text-xl leading-[27px] mb-[10px]">
                  Invite a Team Member
                </h4>
                <p className="text-base font-medium text-Neutral900 opacity-80">
                  Something about teamwork making the dreamwork or something
                  like that.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        {/* <SwiperSlide>
          <div className="bg-Neutral100 rounded-3xl border border-Neutral300 text-center pt-[24px] pb-10 hover:bg-Neutral200 hover:border-Neutral400 transition duration-300 cursor-pointer">
            <div className="w-full text-right pr-[30px]">
              <a
                target="_blank"
                href="https://www.facebook.com/groups/clientmanagerdotio"
                className="inline-block w-full max-w-[30px]"
              >
                 <img
                              className="ml-auto"
                              src={JoinCommunityIcon}
                              alt=""
                            /> 
                <svg
                  className="ml-auto"
                  width="32"
                  hestrokeWidth
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.2223 17.0376V23.2599C22.2223 23.8099 22.0038 24.3375 21.6148 24.7265C21.2259 25.1154 20.6983 25.3339 20.1482 25.3339H8.74082C8.19074 25.3339 7.66319 25.1154 7.27423 24.7265C6.88527 24.3375 6.66675 23.8099 6.66675 23.2599V11.8525C6.66675 11.3024 6.88527 10.7748 7.27423 10.3859C7.66319 9.9969 8.19074 9.77839 8.74082 9.77839H14.963"
                    strokeWidthDF2"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19.1113 6.66667H25.3335V12.8889"
                    strokeWidthDF2"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.926 18.0741L25.3334 6.66667"
                    stroke="#6D6DF2"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </a>
            </div>
             <div className="max-w-[302px] w-full mx-auto flex flex-col justify-between">
              <div className="">
                <img className="mx-auto" src={JoinCommunity} alt="" />
              </div>
              <div className="">
                <h4 className="text-Neutral900 font-bold text-xl leading-[27px] mb-[10px]">
                  Join Our Community
                </h4>
                <p className="text-base font-medium text-Neutral900 opacity-80">
                  Facebook is where it is at all of the time in all of the
                  places. Get innnnnnn.
                </p>
              </div>
            </div> 
          </div>
        </SwiperSlide> */}
      </>
    );
  };

  const renderInvitedTeamPlanSlides = () => {
    if (client?.isLoading === -false) {
      if (client?.data?.length > 0) {
        return (
          <>
            <SwiperSlide>
              <div
                onClick={handleAddClient}
                className="bg-Neutral100 rounded-3xl border border-Neutral300 text-center pt-[24px] pb-10 hover:bg-Neutral200 hover:border-Neutral400 transition duration-300 cursor-pointer"
              >
                <div className="max-w-[302px] w-full mx-auto flex flex-col min-h-[271px] justify-between">
                  <img className="mx-auto" src={BuildAQuestionnare} alt="" />
                  <div className="">
                    <h4 className="text-Neutral900 font-bold text-xl leading-[27px] mb-[10px]">
                      Add Your First Client
                    </h4>
                    <p className="text-base font-medium text-Neutral900 opacity-80">
                      Straight to the point! Get started with ClientManager by
                      adding a new client.
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                onClick={handleAddTeamMemberCTA}
                className="bg-Neutral100 rounded-3xl border border-Neutral300 text-center pt-[24px] pb-10 hover:bg-Neutral200 hover:border-Neutral400 transition duration-300 cursor-pointer"
              >
                <div className="max-w-[302px] w-full mx-auto flex flex-col min-h-[271px] justify-between">
                  <img className="mx-auto" src={AddFirstClient} alt="" />
                  <div className="">
                    <h4 className="text-Neutral900 font-bold text-xl leading-[27px] mb-[10px]">
                      Invite a Team Member
                    </h4>
                    <p className="text-base font-medium text-Neutral900 opacity-80">
                      Something about teamwork making the dreamwork or something
                      like that.
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
              <div className="bg-Neutral100 rounded-3xl border border-Neutral300 text-center pt-[24px] pb-10 hover:bg-Neutral200 hover:border-Neutral400 transition duration-300 cursor-pointer">
                <div className="w-full text-right pr-[30px]">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/groups/clientmanagerdotio"
                    className="inline-block w-full max-w-[30px]"
                  >
                     <img
                              className="ml-auto"
                              src={JoinCommunityIcon}
                              alt=""
                            /> 
                    <svg
                      clstrokeWidthauto"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <pstrokeWidth
                        d="M22.2223 17.0376V23.2599C22.2223 23.8099 22.0038 24.3375 21.6148 24.7265C21.2259 25.1154 20.6983 25.3339 20.1482 25.3339H8.74082C8.19074 25.3339 7.66319 25.1154 7.27423 24.7265C6.88527 24.3375 6.66675 23.8099 6.66675 23.2599V11.8525C6.66675 11.3024 6.88527 10.7748 7.27423 10.3859C7.66319 9.9969 8.19074 9.77839 8.74082 9.77839H14.963"
                        stroke="#6D6DF2"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <pstrokeWidth
                        d="M19.1113 6.66667H25.3335V12.8889"
                        stroke="#6D6DF2"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.926 18.0741L25.3334 6.66667"
                        stroke="#6D6DF2"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </a>
                </div>
                 <div className="max-w-[302px] w-full mx-auto flex flex-col justify-between">
                  <div className="">
                    <img className="mx-auto" src={JoinCommunity} alt="" />
                  </div>
                  <div className="">
                    <h4 className="text-Neutral900 font-bold text-xl leading-[27px] mb-[10px]">
                      Join Our Community
                    </h4>
                    <p className="text-base font-medium text-Neutral900 opacity-80">
                      Facebook is where it is at all of the time in all of the
                      places. Get innnnnnn.
                    </p>
                  </div>
                </div> 
              </div>
            </SwiperSlide> */}
          </>
        );
      } else {
        return (
          <>
            <SwiperSlide>
              <div
                onClick={closeWelcomeModal}
                className="bg-Neutral100 rounded-3xl border border-Neutral300 text-center pt-[24px] pb-10 hover:bg-Neutral200 hover:border-Neutral400 transition duration-300 cursor-pointer"
              >
                <div className="max-w-[302px] w-full mx-auto flex flex-col min-h-[271px] justify-between">
                  <img className="mx-auto" src={AddFirstClient} alt="" />
                  <div className="">
                    <h4 className="text-Neutral900 font-bold text-xl leading-[27px] mb-[10px]">
                      Browse ClientManager
                    </h4>
                    <p className="text-base font-medium text-Neutral900 opacity-80">
                      You have not been assigned a client yet. Take a look
                      around while you wait.
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
              <div className="bg-Neutral100 rounded-3xl border border-Neutral300 text-center pt-[24px] pb-10 hover:bg-Neutral200 hover:border-Neutral400 transition duration-300 cursor-pointer">
                <div className="w-full text-right pr-[30px]">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/groups/clientmanagerdotio"
                    className="inline-block w-full max-w-[30px]"
                  >
                     <img
                              className="ml-auto"
                              src={JoinCommunityIcon}
                              alt=""
                            /> 
                    <svgstrokeWidth
                      className="ml-auto"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >strokeWidth
                      <path
                        d="M22.2223 17.0376V23.2599C22.2223 23.8099 22.0038 24.3375 21.6148 24.7265C21.2259 25.1154 20.6983 25.3339 20.1482 25.3339H8.74082C8.19074 25.3339 7.66319 25.1154 7.27423 24.7265C6.88527 24.3375 6.66675 23.8099 6.66675 23.2599V11.8525C6.66675 11.3024 6.88527 10.7748 7.27423 10.3859C7.66319 9.9969 8.19074 9.77839 8.74082 9.77839H14.963"
                        stroke="#6D6DF2"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />strokeWidth
                      <path
                        d="M19.1113 6.66667H25.3335V12.8889"
                        stroke="#6D6DF2"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.926 18.0741L25.3334 6.66667"
                        stroke="#6D6DF2"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </a>
                </div>
                 <div className="max-w-[302px] w-full mx-auto flex flex-col justify-between">
                  <div className="">
                    <img className="mx-auto" src={JoinCommunity} alt="" />
                  </div>
                  <div className="">
                    <h4 className="text-Neutral900 font-bold text-xl leading-[27px] mb-[10px]">
                      Join Our Community
                    </h4>
                    <p className="text-base font-medium text-Neutral900 opacity-80">
                      Facebook is where it is at all of the time in all of the
                      places. Get innnnnnn.
                    </p>
                  </div>
                </div> 
              </div>
            </SwiperSlide> */}
          </>
        );
      }
    }
  };

  const renderTeamPlanSlides = () => {
    return (
      <>
        <SwiperSlide>
          <div
            onClick={handleAddClient}
            className="bg-Neutral100 rounded-3xl border border-Neutral300 text-center pt-[24px] pb-10 hover:bg-Neutral200 hover:border-Neutral400 transition duration-300 cursor-pointer"
          >
            <div className="max-w-[302px] w-full mx-auto flex flex-col min-h-[271px] justify-between">
              <img className="mx-auto" src={AddFirstClient} alt="" />
              <div className="">
                <h4 className="text-Neutral900 font-bold text-xl leading-[27px] mb-[10px]">
                  Add Your First Client
                </h4>
                <p className="text-base font-medium text-Neutral900 opacity-80">
                  Straight to the point! Get started with ClientManager by
                  adding a new client.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            onClick={handleAddTeamMemberCTA}
            className="bg-Neutral100 rounded-3xl border border-Neutral300 text-center pt-[24px] pb-10 hover:bg-Neutral200 hover:border-Neutral400 transition duration-300 cursor-pointer"
          >
            <div className="max-w-[302px] w-full mx-auto flex flex-col min-h-[271px] justify-between">
              <img className="mx-auto" src={InviteTeamMember} alt="" />
              <div className="">
                <h4 className="text-Neutral900 font-bold text-xl leading-[27px] mb-[10px]">
                  Invite a Team Member
                </h4>
                <p className="text-base font-medium text-Neutral900 opacity-80">
                  Something about teamwork making the dreamwork or something
                  like that.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        {/* <SwiperSlide>
          <div className="bg-Neutral100 rounded-3xl border border-Neutral300 text-center pt-[24px] pb-10 hover:bg-Neutral200 hover:border-Neutral400 transition duration-300 cursor-pointer">
            <div className="w-full text-right pr-[30px]">
              <a
                target="_blank"
                href="https://www.facebook.com/groups/clientmanagerdotio"
                className="inline-block w-full max-w-[30px]"
              >
                 <img
                              className="ml-auto"
                              src={JoinCommunityIcon}
                    strokeWidtht=""
                            /> 
                <svg
                  className="ml-auto"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fistrokeWidth
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.2223 17.0376V23.2599C22.2223 23.8099 22.0038 24.3375 21.6148 24.7265C21.2259 25.1154 20.6983 25.3339 20.1482 25.3339H8.74082C8.19074 25.3339 7.66319 25.1154 7.27423 24.7265C6.88527 24.3375 6.66675 23.8099 6.66675 23.2599V11.8525C6.66675 11.3024 6.88527 10.7748 7.27423 10.3859C7.66319 9.9969 8.19074 9.77839 8.74082 9.77839H14.963"
                    stroke="#6D6DF2"
                    strokeWidth="1.5"
                    strokeWidthap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19.1113 6.66667H25.3335V12.8889"
                    stroke="#6D6DF2"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.926 18.0741L25.3334 6.66667"
                    stroke="#6D6DF2"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </a>
            </div>
             <div className="max-w-[302px] w-full mx-auto flex flex-col justify-between">
              <div className="">
                <img className="mx-auto" src={JoinCommunity} alt="" />
              </div>
              <div className="">
                <h4 className="text-Neutral900 font-bold text-xl leading-[27px] mb-[10px]">
                  Join Our Community
                </h4>
                <p className="text-base font-medium text-Neutral900 opacity-80">
                  Facebook is where it is at all of the time in all of the
                  places. Get innnnnnn.
                </p>
              </div>
            </div> 
          </div>
        </SwiperSlide> */}
      </>
    );
  };

  const renderWelcomeScreenSlides = () => {
    if (user) {
      if (user?.payment && user?.payment !== null) {
        if (
          user?.payment?.selected_account_plan &&
          user?.payment?.selected_account_plan === "individual"
        ) {
          return renderIndividualPlanSlides();
        } else {
          return renderTeamPlanSlides();
        }
      } else {
        if (user?.invited) {
          return renderInvitedTeamPlanSlides();
        }
      }
    }
    return;
  };

  return (
    <div>
      <AdminNavbar
        title="Dashboard"
        subtitle="A bird’s eye view of what’s cooking."
      />
      <div className="5xl:px-[96px] px-6 md:px-12 w-full pt-0">
        <div className="md:pb-0 pb-[100px] mb-[68px]">
          <div className="flex flex-col gap-4 md:flex-row">
            {/* Clients Card */}
            <div className="mt-2 md:mt-8 border-[1px] pt-5 pb-7 px-6 rounded-lg w-full">
              <div className="flex items-center justify-between">
                <h5 className="mb-5 text-xs font-bold text-Neutral700">
                  CLIENTS
                </h5>
                {(authSelector?.user?.owner &&
                  authSelector?.user?.invited === true) ||
                authSelector?.user?.invited === false ? (
                  <div className="mb-5 text-xs font-bold text-Neutral700">
                    <button
                      type="button"
                      onClick={() => {
                        setCreateClientModal(true);
                      }}
                      className="button-hover large-primary-btn text-xs text-center inline-block text-Neutral000 relative bg-AccentRegular font-semibold py-2 px-[15px] ml-5 rounded focus-visible:outline focus-visible:outline-[6px] focus-visible:drop-shadow-none focus-visible:outline-AccentMediumLight transition duration-300 ease-in-out"
                    >
                      <span className="relative z-10">Add Client</span>
                    </button>
                  </div>
                ) : null}
              </div>
              <div>
                <ul className="flex items-center w-[100%]">
                  {!client?.data?.length && client.isLoading ? (
                    <>
                      <div className="rounded-full w-[48px] bg-[#f6f7f8] block h-[48px] bg-no-repeat skeleton-animation relative"></div>
                      <div className="rounded-full w-[48px] bg-[#f6f7f8] block h-[48px] bg-no-repeat skeleton-animation relative"></div>
                    </>
                  ) : client?.data?.length ? (
                    <>
                      {client?.data?.map((item, index) => {
                        if (index <= 4) {
                          return (
                            <>
                              <li
                                key={`20${index}`}
                                className={`client -mr-3 relative md:w-[48px] md:h-[48px] h-[48px] w-[48px] transition duration-300 ${
                                  index * 10
                                }`}
                                onClick={() => setSelectedClint(item._id)}
                                data-tip={`${item.client_name}`}
                                data-for={`client-${item._id}`}
                              >
                                <Link
                                  to={`/admin/clients/manage/${item._id}`}
                                  state={item}
                                  className='focus-visible:outline-none before:contents"" before:absolute relative before:rounded-full before:top-[50%] before:transition-all 
                      before:duration-300 before:left-[50%] before:bg-AccentRegular before:opacity-0 client-hover translate before:-translate-x-2/4 before:-translate-y-2/4'
                                >
                                  {!item?.profile ? (
                                    <div
                                      className={`border-Neutral300 md:w-[48px] md:h-[48px] h-[48px] w-[48px] flex items-center justify-center rounded-full bg-Neutral400 text-Neutral700  focus-visible:outline-none ${
                                        selctedClient === item.id
                                          ? "border-white border-[3px]"
                                          : "border-4 border-AccentRegular"
                                      }`}
                                    >
                                      <span className=" text-[20px] 5xl:text-[24px] w-full h-full flex items-center justify-center rounded-full font-bold border border-Miscgrey">
                                        {item.client_name[0]}
                                      </span>
                                    </div>
                                  ) : (
                                    <img
                                      className={`rounded-full w-full h-full focus-visible:outline-none ${
                                        selctedClient === item.id
                                          ? "border-white border-[3px]"
                                          : "border-4 border-AccentRegular"
                                      }`}
                                      src={item.profile_path}
                                      alt="UserButtons"
                                    />
                                  )}
                                </Link>
                              </li>
                              <ReactTooltip id={`client-${item._id}`} />
                            </>
                          );
                        }
                      })}
                      {client?.data?.length > 5 ? (
                        <li className="client ml-auto mr-0 relative md:w-[48px] md:h-[48px] h-[48px] w-[48px] transition duration-300">
                          <Link
                            to="/admin/clients"
                            className="relative focus-visible:outline-none client-hover"
                          >
                            <div className="border md:w-[48px] md:h-[48px] h-[48px] w-[48px] flex items-center justify-center rounded-full bg-white focus-visible:outline-none transition duration-300 border-Neutral400 border-1 text-[20px] text-Neutral600 hover:border-Neutral600 hover:text-Neutral800">
                              +{client?.data?.length - 5}
                            </div>
                          </Link>
                        </li>
                      ) : null}
                    </>
                  ) : (
                    <>
                      <li className="client -mr-3 relative md:w-[48px] md:h-[48px] h-[48px] w-[48px] transition duration-300 bg-white">
                        <div className="w-full h-full border-2 border-dashed rounded-full border-Neutral50"></div>
                      </li>
                      <li className="client -mr-3 relative md:w-[48px] md:h-[48px] h-[48px] w-[48px] transition duration-300 bg-white">
                        <div className="w-full h-full border-2 border-dashed rounded-full border-Neutral50"></div>
                      </li>
                      <li className="client -mr-3 relative md:w-[48px] md:h-[48px] h-[48px] w-[48px] transition duration-300 bg-white">
                        <div className="w-full h-full border-2 border-dashed rounded-full border-Neutral50"></div>
                      </li>
                      <li className="client -mr-3 relative md:w-[48px] md:h-[48px] h-[48px] w-[48px] transition duration-300 bg-white">
                        <div className="w-full h-full border-2 border-dashed rounded-full border-Neutral50"></div>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
            {/* Team members Card */}
            {authSelector?.user?.invited === false ||
            (user?.payment?.selected_account_plan &&
              user?.payment?.selected_account_plan !== "individual") ||
            (authSelector?.user?.owner &&
              authSelector?.user?.invited === true) ? (
              <div className="mt-2 md:mt-8 border-[1px] pt-5 pb-7 px-6 rounded-lg w-full cursor-pointer">
                <div className="items-center justify-between">
                  <div className="flex items-center justify-between">
                    <h5 className="mb-5 text-xs font-bold text-Neutral700">
                      TEAM MEMBERS
                    </h5>
                    <div className="mb-5 text-xs font-bold text-Neutral700">
                      <button
                        type="button"
                        onClick={() => {
                          setCreateMemberModal(true);
                        }}
                        className="button-hover large-primary-btn text-xs text-center inline-block text-Neutral000 relative bg-AccentRegular font-semibold py-2 px-[15px] ml-5 rounded focus-visible:outline focus-visible:outline-[6px] focus-visible:drop-shadow-none focus-visible:outline-AccentMediumLight transition duration-300 ease-in-out"
                      >
                        <span className="relative z-10">Add Member</span>
                      </button>
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <ul className="flex items-center w-[100%]">
                      {!member?.data?.length && member?.isLoading ? (
                        <>
                          <div className="rounded-full w-[48px] bg-[#f6f7f8] block h-[48px] bg-no-repeat skeleton-animation relative"></div>
                          <div className="rounded-full w-[48px] bg-[#f6f7f8] block h-[48px] bg-no-repeat skeleton-animation relative"></div>
                        </>
                      ) : member?.data?.length && !member?.isLoading ? (
                        <>
                          {member?.data?.map((item, index) => {
                            if (index <= 4) {
                              return (
                                <>
                                  <li
                                    key={`20${index}`}
                                    className={`client -mr-3 relative md:w-[48px] md:h-[48px] h-[48px] w-[48px] transition duration-300 ${
                                      index * 10
                                    }`}
                                    data-tip={`${item.user_name}`}
                                    data-for={`user-${item._id}`}
                                  >
                                    {!item?.member ? (
                                      !item?.profile ? (
                                        <button
                                          onClick={() =>
                                            setSelectedTeamMemberId(item?._id)
                                          }
                                          className="before:content-[''] before:absolute relative before:rounded-full before:top-[50%] before:transition-all 
                                  before:duration-300 before:left-[50%] before:bg-AccentRegular before:opacity-0 client-hover translate before:-translate-x-2/4 before:-translate-y-2/4"
                                        >
                                          <div
                                            className={`border border-Neutral300 md:w-[48px] md:h-[48px] h-[48px] w-[48px] flex items-center justify-center rounded-full bg-Neutral400 text-Neutral700 focus-visible:outline-none ${
                                              selctedClient === item?.id
                                                ? "border-white border-[3px]"
                                                : "border-4 border-white"
                                            }`}
                                          >
                                            <span className="text-[20px] 5xl:text-[24px] w-full h-full flex items-center justify-center rounded-full font-bold border border-Miscgrey">
                                              {item?.user_name[0]}
                                            </span>
                                          </div>
                                        </button>
                                      ) : (
                                        <button
                                          className="before:content-[''] before:absolute relative before:rounded-full before:top-[50%] before:transition-all 
                                  before:duration-300 before:left-[50%] before:bg-AccentRegular before:opacity-0 client-hover translate before:-translate-x-2/4 before:-translate-y-2/4"
                                          onClick={() =>
                                            setSelectedTeamMemberId(item?._id)
                                          }
                                        >
                                          <img
                                            className={`rounded-full w-full h-full focus-visible:outline-none ${
                                              selctedClient === item?.id
                                                ? "border-white border-[3px]"
                                                : "border-4 border-white"
                                            }`}
                                            src={item?.profile_path}
                                            alt="UserButtons"
                                          />
                                        </button>
                                      )
                                    ) : !item?.member?.profile ? (
                                      <button
                                        className="before:content-[''] before:absolute relative before:rounded-full before:top-[50%] before:transition-all 
                                before:duration-300 before:left-[50%] before:bg-AccentRegular before:opacity-0 client-hover translate before:-translate-x-2/4 before:-translate-y-2/4"
                                        onClick={() =>
                                          setSelectedTeamMemberId(
                                            item?.member?._id
                                          )
                                        }
                                      >
                                        <div
                                          className={`border border-Neutral300 md:w-[48px] md:h-[48px] h-[48px] w-[48px] flex items-center justify-center rounded-full bg-Neutral400 text-Neutral700 focus-visible:outline-none ${
                                            selctedClient === item?.id
                                              ? "border-white border-[3px]"
                                              : "border-4 border-AccentRegular"
                                          }`}
                                        >
                                          <span className="text-[20px] 5xl:text-[24px] w-full h-full flex items-center justify-center rounded-full font-bold border border-Miscgrey">
                                            {item?.member?.user_name[0]}
                                          </span>
                                        </div>
                                      </button>
                                    ) : (
                                      <button
                                        className="before:content-[''] before:absolute relative before:rounded-full before:top-[50%] before:transition-all 
                                      before:duration-300 before:left-[50%] before:bg-AccentRegular before:opacity-0 client-hover translate before:-translate-x-2/4 before:-translate-y-2/4"
                                        onClick={() =>
                                          setSelectedTeamMemberId(
                                            item?.member?._id
                                          )
                                        }
                                      >
                                        <img
                                          className={`rounded-full w-full h-full focus-visible:outline-none ${
                                            selctedClient === item?.id
                                              ? "border-white border-[3px]"
                                              : "border-4 border-white"
                                          }`}
                                          src={item?.member?.profile_path}
                                          alt="UserButtons"
                                        />
                                      </button>
                                    )}
                                    {/* </Link> */}
                                  </li>
                                  <ReactTooltip id={`user-${item._id}`} />
                                </>
                              );
                            }
                          })}
                          {member?.data?.length > 5 ? (
                            <li className="client ml-auto mr-0 relative md:w-[48px] md:h-[48px] h-[48px] w-[48px] transition duration-300">
                              <button
                                className="relative focus-visible:outline-none client-hover"
                                onClick={() => setEditTeamMembersModal(true)}
                              >
                                <div className="border md:w-[48px] md:h-[48px] h-[48px] w-[48px] flex items-center justify-center rounded-full bg-white focus-visible:outline-none border-Neutral400 border-1 text-[20px] text-Neutral600  transition duration-300 hover:border-Neutral600 hover:text-Neutral800">
                                  +{member?.data?.length - 5}
                                </div>
                              </button>
                            </li>
                          ) : null}
                        </>
                      ) : (
                        <>
                          <li className="client -mr-3 relative md:w-[48px] md:h-[48px] h-[48px] w-[48px] transition duration-300 bg-white">
                            <div className="w-full h-full border-2 border-dashed rounded-full border-Neutral50"></div>
                          </li>
                          <li className="client -mr-3 relative md:w-[48px] md:h-[48px] h-[48px] w-[48px] transition duration-300 bg-white">
                            <div className="w-full h-full border-2 border-dashed rounded-full border-Neutral50"></div>
                          </li>
                          <li className="client -mr-3 relative md:w-[48px] md:h-[48px] h-[48px] w-[48px] transition duration-300 bg-white">
                            <div className="w-full h-full border-2 border-dashed rounded-full border-Neutral50"></div>
                          </li>
                          <li className="client -mr-3 relative md:w-[48px] md:h-[48px] h-[48px] w-[48px] transition duration-300 bg-white">
                            <div className="w-full h-full border-2 border-dashed rounded-full border-Neutral50"></div>
                          </li>
                        </>
                      )}
                    </ul>
                    {member?.data?.length > 0 &&
                    member?.data?.length <= 5 &&
                    !member?.isLoading ? (
                      <button
                        className="text-[10px] underline text-Neutral800 font-medium hover:text-Neutral900 duration-250 transition w-fit min-w-[50px] text-right"
                        onClick={() => setEditTeamMembersModal(true)}
                      >
                        View All
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          {/* <button
            type="button"
            onClick={() => setwelcomeTeamMember(true)}
            className="button-hover large-primary-btn text-xs text-center inline-block text-Neutral000 relative bg-AccentRegular font-semibold py-2 px-[15px] ml-5 rounded focus-visible:outline focus-visible:outline-[6px] focus-visible:drop-shadow-none focus-visible:outline-AccentMediumLight transition duration-300 ease-in-out"
          >
            <span className="relative z-10">welcome team menager</span>
          </button> */}

          <div className="mt-[36px] md:mt-8">
            <div>
              {/* <DashboardCalender /> */}
              <Fullcalander />
              {/* Copyright FullCalendar.io
                Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:
                The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
                THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
              */}
              {/* <Calender /> */}
            </div>
          </div>
        </div>
      </div>
      <CreateClientModal
        isOpen={createClientModal}
        setIsOpen={setCreateClientModal}
      />
      <CreateMemberModal
        isOpen={createMemberModal}
        setModal={setCreateMemberModal}
      />
      {/* Plural Member */}
      <ExpandTeamsModal
        isOpen={editTeamMembersModal}
        setModal={setEditTeamMembersModal}
        focusOnMember={handleFocusMemberView}
      />

      {/* Singular Member */}
      {selectedTeamMemberId && (
        <ExpandTeamMemberModal
          isOpen={selectedTeamMemberId ? true : false}
          setModal={() => setSelectedTeamMemberId(null)}
          memberId={selectedTeamMemberId}
        />
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="Example Modal"
      >
        <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="bg-white px-6 xs:px-12 pt-8 pb-10 rounded-[8px]">
            <div>
              <div className="text-left">
                <div className="flex items-center justify-between w-full border-b border-Neutral200">
                  <span className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2">
                    ADD TEAM MEMBER
                  </span>
                  <span className="text-xs text-AccentRegular leading-[18px] font-bold	pb-2">
                    0
                    <span className="ml-1 font-semibold text-Neutral700">
                      Spots left
                    </span>
                  </span>
                </div>
                <div className="flex items-center justify-center my-6">
                  <img src={Avtar} alt="" />
                </div>
                <div className="flex items-center justify-center w-[354px]">
                  <span className="text-sm font-normal text-center text-Neutral800">
                    Spice up your plan in a few clicks to get back to adding
                    more members to your team!
                  </span>
                </div>
                <div className="mt-8">
                  <button className="relative block w-full py-3 overflow-hidden text-sm font-bold text-center transition duration-300 ease-in-out rounded btn button-hover form-primary-btn text-Neutral100 bg-AccentRegular drop-shadow-Texts focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight">
                    <span className="relative z-10 drop-shadow-Texts">
                      Upgrade Account
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => closeModal(false)}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>

      {/* open reference modal */}
      {user?.pre_welcome_modal && <PreWelcomeModal />}

      {user?.seen_welcome_modal && !user?.pre_welcome_modal && (
        <Modal
          isOpen={welcomeClient}
          onRequestClose={() => closeWelcomeModal()}
          style={customStylesWelcomeClient}
          closeTimeoutMS={300}
          contentLabel="Example Modal1"
          className="custome-modal"
        >
          <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
            <div>
              <div className="px-[20px] md:px-[72px] pt-[48px] pb-[33px]">
                <div className="text-left">
                  <div className="flex items-center justify-between w-full">
                    <div className="">
                      <h3 className="text-xs text-Neutral900 text-[32px] leading-[44px] font-extrabold flex mb-1">
                        Welcome to ClientManager!{" "}
                        <img
                          src={welcomeMenager}
                          alt=""
                          className="ml-[14px] w-12 h-12 mt-[-12px]"
                        />
                      </h3>
                      <span className="font-medium text-base leading-[22px] text-Neutral800">
                        Let’s get you up to speed.
                      </span>
                    </div>
                  </div>
                  <div className="relative flex items-center justify-center w-full my-10">
                    <Vimeo
                      video="https://player.vimeo.com/video/783980138"
                      autoplay={false}
                      className="w-full overflow-hidden rounded-3xl"
                      responsive
                    />
                  </div>
                  <div className="pt-8 mx-6 border-t border-Neutral300">
                    <h3 className="text-Neutral900 text-2xl leading-[33px] font-bold">
                      Get Started
                    </h3>
                  </div>
                </div>
              </div>
              <div className="px-[20px] md:px-[72px] pb-[33px] overflow-hidden">
                <Swiper
                  className="flex flex-col overflow-visible mySwiper md:flex-row"
                  breakpoints={{
                    // when window width is >= 640px
                    640: {
                      slidesPerView: 2,
                    },
                    // when window width is <= 639px
                    639: {
                      slidesPerView: 1,
                      navigation: true,
                    },
                  }}
                  spaceBetween={12}
                >
                  {renderWelcomeScreenSlides()}
                </Swiper>
              </div>
            </div>
            <div
              className="absolute right-0 top-[-50px] z-30 cursor-pointer"
              onClick={() => closeWelcomeModal()}
            >
              <img src={CloseBtn} alt="" />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default Dashboard;
