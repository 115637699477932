import axios from "axios";
import { toast } from "react-toastify";

export const GetServicesActions = (id, is_archived, setIsLoading) => {
  return async (dispatch) => {
    try {
      // dispatch({
      //   type: "SET_SERVICE",
      //   payload: {
      //     isLoading: true,
      //   },
      // });
      const { data } = await axios.get(
        `api/v1/service/get-service-by-client?id=${id}&is_archived=${is_archived}`
      );
      setIsLoading(false);
      return dispatch({
        type: "SET_SERVICE",
        payload: {
          client: id,
          data: data.data,
          isLoading: false,
          is_archived: is_archived,
        },
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
      return dispatch({
        type: "GET_SERVICES_ERROR",
        payload: error,
      });
    }
  };
};

export const CreateServiceActions = (value) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post("api/v1/service/create-service", value);
      return dispatch({
        type: "CREATE_SERVICE_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        return dispatch({
          type: "CREATE_SERVICE_ERROR",
          payload: error,
        });
      } else {
        return dispatch({
          type: "CREATE_SERVICE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const UpdateServiceActions = (value, setCallApi) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.put("api/v1/service/update-service", value);
      setCallApi && setCallApi(true);
      return dispatch({
        type: "UPDATE_SERVICE_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
          toast.error(element.msg);
        });
        return dispatch({
          type: "UPDATE_SERVICE_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "UPDATE_SERVICE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const DeleteServiceAction = (id) => {
  return async (dispatch) => {
    try {
      await axios.delete(`api/v1/service/delete-service?id=${id}`);
      return dispatch({
        type: "DELETE_SERVICE_SUCCESS",
        payload: { id: id },
      });
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "DELETE_SERVICE_ERROR",
          payload: { error },
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "DELETE_SERVICE_ERROR",
          payload: { error },
        });
      }
    }
  };
};

export const UpdateServiceStatusActions = (value, update_store, old_data) => {
  return async (dispatch) => {
    try {
      await axios.put("api/v1/service/update-service-status", value);
      return dispatch({
        type: "UPDATE_SERVICE_COLUMN_CHANGE_SUCCESS",
        payload: { data: update_store },
      });
    } catch (error) {
      dispatch({
        type: "UPDATE_SERVICE_ABORT",
        payload: { data: old_data },
      });
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        return dispatch({
          type: "UPDATE_SERVICE_ERROR",
          payload: error,
        });
      } else {
        return dispatch({
          type: "UPDATE_SERVICE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const ArchiveServiceAction = (id, handleClose, setIsArchiveLoading) => {
  return async (dispatch) => {
    try {
      await axios.post(`api/v1/service/archive-service?id=${id}`);
      setIsArchiveLoading(false);
      handleClose();
      return dispatch({
        type: "ARCHIVE_SERVICE_SUCCESS",
        payload: { id: id },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setIsArchiveLoading(false);
        return dispatch({
          type: "ARCHIVE_SERVICE_ERROR",
          payload: error,
        });
      } else {
        setIsArchiveLoading(false);
        return dispatch({
          type: "ARCHIVE_SERVICE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const addNewSubTaskInService = (
  value,
  setIsNewSubtask,
  setNewSubtask
) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        "api/v1/service/add-sub-service",
        value
      );
      setIsNewSubtask(false);
      setNewSubtask({
        _id: Date.now(),
        name: "",
        is_completed: false,
        is_new: true,
      });
      return dispatch({
        type: "CREATE_SERVICE_SUB_TASK_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        return dispatch({
          type: "CREATE_SUB_TASK_ERROR",
          payload: error,
        });
      } else {
        return dispatch({
          type: "CREATE_SUB_TASK_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const UpdateServiceSubTaskActions = (value) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        "api/v1/service/update-sub-service",
        value
      );
      return dispatch({
        type: "UPDATE_SERVICE_SUB_TASK_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
          toast.error(element.msg);
        });
        return dispatch({
          type: "UPDATE_SUB_TASK_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "UPDATE_SUB_TASK_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const DeleteServiceSubTaskAction = (value) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `api/v1/service/remove-sub-service`,
        value
      );
      return dispatch({
        type: "DELETE_SERVICE_SUB_TASK_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "DELETE_SUB_TASK_ERROR",
          payload: { error },
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "DELETE_SUB_TASK_ERROR",
          payload: { error },
        });
      }
    }
  };
};

export const AddMediaToServiceAction = (
  value,
  setIsLoading,
  setIsModalOpen
) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`api/v1/service/add-media`, value);
      setIsLoading(false);
      if (setIsModalOpen) setIsModalOpen(false);
      return dispatch({
        type: "ADD_SERVICE_MEDIA_TO_TASK_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        setIsLoading(false);
        return dispatch({
          type: "ADD_MEDIA_TO_TASK_ERROR",
          payload: { error },
        });
      } else {
        toast.error(error?.response?.data?.message);
        setIsLoading(false);
        return dispatch({
          type: "ADD_MEDIA_TO_TASK_ERROR",
          payload: { error },
        });
      }
    }
  };
};

export const DeleteMediaFromServiceAction = (value, setIsModalOpen) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`api/v1/service/remove-media`, value);
      setIsModalOpen(false);
      return dispatch({
        type: "DELETE_SERVICE_MEDIA_FROM_TASK_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "DELETE_MEDIA_FROM_TASK_ERROR",
          payload: { error },
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "DELETE_MEDIA_FROM_TASK_ERROR",
          payload: { error },
        });
      }
    }
  };
};
