import { Tab } from "@headlessui/react";
import { useSelector } from "react-redux";

import { useWhiteLabelSettings } from "../../contexts/WhiteLabelProvider";
import { useBackgroundWithAlpha } from "../../hooks/useBrandColorsWithAlpha";
import BrandIdentityCard from "./company/BrandIdentityCard";
import ClientPortalDomainCard from "./company/ClientPortalDomainCard";
import CustomEmailCard from "./company/CustomEmailCard";
import UpgradeToWhiteLabelCard from "./company/UpgradeToWhiteLabelCard";
import WhiteLabelPlanDetailCard from "./company/WhiteLabelPlanDetailCard";

function CompanyTab() {
  const { settings } = useWhiteLabelSettings();
  const userIsAWhiteLabelSubscriber = settings.get("isWhiteLabelSubscriber");
  const bgColor = useBackgroundWithAlpha(0.15);

  const loggedInUser = useSelector((state) => state.auth.user);

  const shouldShowMessage =
    loggedInUser?.brandIdentity?.status === "active" &&
    !loggedInUser?.customDomain &&
    !loggedInUser?.subDomain;

  const isWhiteLabelActive =
    loggedInUser?.white_label_payment?._id &&
    (loggedInUser?.white_label_payment?.status === "active" ||
      loggedInUser?.white_label_payment?.status === "paid");

  return (
    <Tab.Panel className="flex flex-col gap-10">
      {shouldShowMessage && (
        <div
          className="max-w-[830px] p-3 rounded-lg border border-Bg-Light"
          style={{ backgroundColor: bgColor }}
        >
          <p className="text-xs font-medium leading-5 tracking-wider text-AccentRegular">
            Woohooo! 🎉 You’ve successfully upgraded to White Label. Please
            connect your Custom Domain, update your Brand Identity, and
            Email Settings below.
          </p>
        </div>
      )}

      {/* UI-TODO: Only show this when user `DOES NOT` have white-labeling subscription */}
      {!userIsAWhiteLabelSubscriber && <UpgradeToWhiteLabelCard />}

      <ClientPortalDomainCard />
      <BrandIdentityCard />
      <CustomEmailCard />

      {isWhiteLabelActive && <WhiteLabelPlanDetailCard />}
    </Tab.Panel>
  );
}

export default CompanyTab;
