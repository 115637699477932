import { Menu, Transition } from "@headlessui/react";
import { Form, Formik } from "formik";
import { Fragment, useEffect, useState } from "react";
import Modal from "react-modal";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import Checkmark from "../../assets/Images/Checkmark.svg";
import Close from "../../assets/Images/Close-gray.svg";
import CloseBtn from "../../assets/Images/close-btn.svg";
import ImgIcon from "../../assets/Images/img-icon.svg";
import { CreateClientAction } from "../../store/actions/clientAction";
import { getMemberList } from "../../store/actions/memberAction";
import PhoneInput from "../PhoneInput";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const CreateClientSchema = Yup.object().shape({
  business_name: Yup.string(),
  contact_name: Yup.string().required("Contact Person Required"),
  email: Yup.string().email("Email is not valid"),
  members: Yup.array().when("assign_all_members", {
    is: false,
    then: Yup.array(),
  }),
  phone_number: Yup.string()
    .nullable()
    .test("isValid", "Invalid phone number", (value) => {
      if (!value) return true; // allows empty values
      return typeof value === "string" && isValidPhoneNumber(value);
    }),
});

function CreateClientModal({ isOpen = false, setIsOpen = () => {} }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const members = useSelector((state) => state.member.members);
  const [isAddMoreContact, setIsAddMoreContact] = useState(false);
  const [isAddMoreInfo, setIsAddMoreInfo] = useState(false);
  const [isNewCustomField, setIsNewCustomField] = useState(false);

  useEffect(() => {
    if (!members?.data?.length && isOpen) {
      dispatch(getMemberList());
    }
  }, [dispatch, isOpen]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          setIsOpen(false);
          setIsAddMoreContact(false);
          setIsAddMoreInfo(false);
          setIsNewCustomField(false);
        }}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="Example Modal"
      >
        <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="bg-white px-6 xs:px-12 pt-8 pb-10 rounded-[8px]">
            <div>
              <div className="text-left">
                <h3
                  className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-4"
                  id="modal-title"
                >
                  ADD CLIENT
                </h3>
                <Formik
                  initialValues={{
                    email: "",
                    contact_name: "",
                    second_contact_name: "",
                    second_email: "",
                    second_phone_number: "",
                    phone_number: "",
                    address: "",
                    website_one: "",
                    website_two: "",
                    vat_number: "",
                    profile: null,
                    members: [],
                    assign_all_members: true,
                    business_name: "",
                    contact_person_job_title: "",
                    second_contact_person_job_title: "",
                    custom_fields: [],
                  }}
                  enableReinitialize
                  validationSchema={CreateClientSchema}
                  onSubmit={(values, { setSubmitting, setErrors }) => {
                    setSubmitting(true);
                    dispatch(
                      CreateClientAction(
                        values,
                        setSubmitting,
                        setErrors,
                        setIsOpen,
                        navigate,
                        setIsAddMoreContact
                      )
                    );
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldError,
                    setFieldValue,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 overflow-y-auto px-[10px] h-[55vh]">
                        <div>
                          <div className="flex items-center justify-between">
                            <label className="text-xs font-bold text-Neutral900">
                              Contact Person
                            </label>
                          </div>
                          <div className="mt-2">
                            <input
                              className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                              type="text"
                              placeholder="Vicky Builderino"
                              name="contact_name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.contact_name}
                            />
                            {errors.contact_name && touched.contact_name && (
                              <p className="absolute mt-1 text-xs font-medium text-Red400 focus-visible:outline-none">
                                {errors.contact_name}
                              </p>
                            )}
                          </div>
                        </div>
                        <div>
                          <div className="flex items-center justify-between">
                            <label className="text-xs font-bold text-Neutral900">
                              Email
                            </label>
                            <span className="text-xs italic font-medium text-Neutral700">
                              Optional
                            </span>
                          </div>
                          <div className="mt-2">
                            <input
                              className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                              type="email"
                              placeholder="Eg. lukeskywalker@gmail.com"
                              name="email"
                              onChange={(e) => {
                                const emailVal = e.target.value.toLowerCase();
                                handleChange({
                                  target: {
                                    name: "email",
                                    value: emailVal,
                                  },
                                });
                              }}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                            {errors.email && touched.email && (
                              <p className="absolute mt-1 text-xs font-medium text-Red400 focus-visible:outline-none">
                                {errors.email}
                              </p>
                            )}
                          </div>
                        </div>
                        <div>
                          <div className="flex items-center justify-between">
                            <label className="text-xs font-bold text-Neutral900">
                              Business Name
                            </label>
                            <span className="text-xs italic font-medium text-Neutral700">
                              Optional
                            </span>
                          </div>
                          <div className="mt-2">
                            <input
                              className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                              type="text"
                              placeholder="Microwsoft"
                              name="business_name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.business_name}
                            />
                            {errors.business_name && touched.business_name && (
                              <p className="absolute mt-1 text-xs font-medium text-Red400 focus-visible:outline-none">
                                {errors.business_name}
                              </p>
                            )}
                          </div>
                        </div>
                        <div>
                          <div className="flex items-center justify-between">
                            <label className="inline h-auto text-xs font-bold text-Neutral900">
                              Phone Number
                            </label>
                            <span className="text-xs italic font-medium text-Neutral700">
                              Optional
                            </span>
                          </div>
                          <PhoneInput
                            name="phone_number"
                            value={values.phone_number}
                            setFieldValue={setFieldValue}
                            setFieldError={setFieldError}
                            handleBlur={handleBlur}
                            errors={errors}
                            touched={touched}
                            className="mt-2"
                          />
                        </div>
                        <div>
                          <div className="flex items-center justify-between">
                            <label className="text-xs font-bold text-Neutral900">
                              Contact Person Job Title
                            </label>
                            <span className="text-xs italic font-medium text-Neutral700">
                              Optional
                            </span>
                          </div>
                          <div className="mt-2">
                            <input
                              className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                              type="text"
                              placeholder="Eg. CEO"
                              name="contact_person_job_title"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.contact_person_job_title}
                            />
                            {errors?.contact_person_job_title &&
                              touched?.contact_person_job_title && (
                                <p className="absolute mt-1 text-xs font-medium text-Red400 focus-visible:outline-none">
                                  {errors?.contact_person_job_title}
                                </p>
                              )}
                          </div>
                        </div>
                        <div>
                          <div className="flex items-center justify-between">
                            <span className="text-xs font-bold text-Neutral900">
                              Profile Image
                            </span>
                            <span className="text-xs italic font-medium text-Neutral700">
                              Optional
                            </span>
                          </div>
                          <div className="relative mt-2">
                            <label
                              htmlFor={values?.profile ? null : "uploadlogo"}
                              className={`placeholder:text-Neutral500 p-2 px-3 text-sm leading-5 w-full focus-visible:outline-none border-[1px] rounded border-Neutral400 hover:border-Neutral800 bg-Neutral100 transition duration-300 border-dashed flex justify-between ${
                                !values?.profile && "cursor-pointer"
                              }`}
                            >
                              <span className="flex items-center">
                                <img
                                  src={
                                    values?.profile
                                      ? URL.createObjectURL(values?.profile)
                                      : ImgIcon
                                  }
                                  alt=""
                                  height={values?.profile && 150}
                                  width={values?.profile && 100}
                                />
                                <p
                                  className={`text-Neutral600 ml-2 text-xs font-medium text-ellipsis overflow-hidden ${
                                    values?.profile && "w-[160px]"
                                  }`}
                                >
                                  {values?.profile?.name
                                    ? values?.profile?.name
                                    : "Upload client’s photo or company logo."}
                                </p>
                              </span>
                              {values?.profile ? (
                                <img
                                  className="cursor-pointer"
                                  src={Close}
                                  alt=""
                                  onClick={() => setFieldValue(`profile`, null)}
                                />
                              ) : null}
                            </label>
                            <input
                              id="uploadlogo"
                              className="hidden"
                              type="file"
                              name="profile"
                              accept="image/x-png,image/jpeg"
                              multiple={false}
                              onChange={(e) => {
                                setFieldValue(`profile`, e.target.files[0]);
                              }}
                            />
                          </div>
                        </div>
                        <div className="flex flex-col justify-start min-h-[78px] pt-[4px]">
                          <label className="mb-2 text-xs font-bold text-Neutral900">
                            Team Member Assignment
                          </label>
                          <div className="mt-2">
                            <div className="flex items-center justify-between">
                              <div className="flex items-center filter-checkbox">
                                <input
                                  type="checkbox"
                                  id="all_clients"
                                  defaultChecked
                                  checked={values?.assign_all_members}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "assign_all_members",
                                      !values?.assign_all_members
                                    );
                                    if (values?.assign_all_members !== true) {
                                      setFieldValue("members", []);
                                    }
                                  }}
                                  name="A3-confirmation"
                                  value="yes"
                                  className="opacity-0 absolute cursor-pointer h-[24px] w-[24px] transition duration-300"
                                />
                                <div className="bg-white border-Neutral300 border-[1.5px] custom-chackbox rounded-md hover:bg-AccentLight hover:border-black w-[24px] h-[24px] flex flex-shrink-0 justify-center items-center mr-2 transition duration-300 ">
                                  <img src={Checkmark} alt="" />
                                </div>
                                <label
                                  className="cursor-pointer "
                                  htmlFor="all_clients"
                                >
                                  <span className="block text-xs font-medium text-Neutral700">
                                    Assign to all team members automatically
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                          {!values?.assign_all_members && (
                            <div className="mt-4 mb-6">
                              <div className="relative mt-2">
                                <Menu
                                  as="div"
                                  className="relative inline-block w-full text-left"
                                >
                                  <Menu.Button className="rounded-[4px] client-name bg-Neutral200 inline-flex w-full focus-visible:outline-none popup-btn relative px-[16px] pb-[10px] pt-[10px]">
                                    <span className="focus-visible:outline-none">
                                      <div className="members-text flex flex-wrap text-[14px] items-center text-Neutral800 font-medium">
                                        Select Team Members Manually
                                        <svg
                                          className="absolute right-[16px]"
                                          width="11"
                                          height="8"
                                          viewBox="0 0 11 8"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M0.324856 1.63059C-0.206125 0.977077 0.258936 0 1.10097 0H9.89903C10.7411 0 11.2061 0.977078 10.6751 1.63059L6.27611 7.04478C5.87592 7.53733 5.12408 7.53733 4.72389 7.04478L0.324856 1.63059Z"
                                            fill="#5A5A66"
                                          />
                                        </svg>
                                      </div>
                                    </span>
                                  </Menu.Button>
                                  {errors?.members && touched?.members && (
                                    <p className="absolute mt-1 text-xs font-medium text-Red400 focus-visible:outline-none">
                                      {errors?.members}
                                    </p>
                                  )}
                                  <Transition as={Fragment}>
                                    <Menu.Items className="box shadow-[0px_30px_40px_rgba(0,0,0,0.2)] z-50 absolute top-[41px] right-0 bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none w-full pb-4 px-4 rounded-[0px_0px_4px_4px]">
                                      <div className="bg-white">
                                        <p className="text-Neutral600 flex justify-between text-[10px] font-semibold pt-[9px] pb-[12px] border-t border-Neutral200">
                                          <span>Team Members</span>
                                          <span
                                            className="cursor-pointer text-AccentRegular"
                                            onClick={() => {
                                              setFieldValue(
                                                "members",
                                                values?.members?.length ===
                                                  members?.data?.length
                                                  ? []
                                                  : members?.data?.map(
                                                      (ele) => ele?._id
                                                    )
                                              );
                                            }}
                                          >
                                            {values?.members?.length ===
                                            members?.data?.length
                                              ? "UNSELECT ALL"
                                              : "SELECT ALL"}
                                          </span>
                                        </p>
                                        <div>
                                          {members?.data?.map((item, index) => {
                                            return (
                                              <label htmlFor={item?._id}>
                                                <div
                                                  className="flex justify-between items-center rounded-lg py-[6px] hover:bg-Neutral200 ml-[-8px] pl-[10px] cursor-pointer"
                                                  key={index}
                                                >
                                                  <div className="flex">
                                                    {item?.member?.profile ? (
                                                      <div className="rounded-full h-[24px] w-[24px] overflow-hidden">
                                                        <img
                                                          src={
                                                            item?.member
                                                              ?.profile_path
                                                          }
                                                          className="object-cover"
                                                          alt=""
                                                        />
                                                      </div>
                                                    ) : (
                                                      <div className="h-[24px] w-[24px] flex items-center justify-center rounded-full text-Neutral700 bg-Neutral400 focus-visible:outline-none">
                                                        <span className="text-[14px] 5xl:text-[24px] w-full h-full flex items-center justify-center rounded-full font-bold">
                                                          {item?.member
                                                            ?.user_name[0] ||
                                                            item?.user_name[0]}
                                                        </span>
                                                      </div>
                                                    )}
                                                    <p className="pl-4 text-sm font-normal text-Neutral900">
                                                      {item?.member
                                                        ?.user_name ||
                                                        item?.user_name}
                                                    </p>
                                                  </div>
                                                  <div className="flex items-center filter-checkbox">
                                                    <input
                                                      type="checkbox"
                                                      id={item?._id}
                                                      // defaultChecked
                                                      checked={values?.members?.includes(
                                                        item?._id
                                                      )}
                                                      onBlur={handleBlur}
                                                      onChange={(e) => {
                                                        setFieldValue(
                                                          "members",
                                                          values?.members?.includes(
                                                            item?._id
                                                          )
                                                            ? values?.members.filter(
                                                                (ele) =>
                                                                  ele !==
                                                                  item?._id
                                                              )
                                                            : [
                                                                ...values?.members,
                                                                item?._id,
                                                              ]
                                                        );
                                                      }}
                                                      name="A3-confirmation"
                                                      value="yes"
                                                      className="opacity-0 absolute cursor-pointer h-[24px] w-[24px] transition duration-300"
                                                    />
                                                    <div className="bg-white border-Neutral300 border-[1.5px] custom-chackbox rounded-md hover:bg-AccentLight hover:border-black w-[24px] h-[24px] flex flex-shrink-0 justify-center items-center mr-2 transition duration-300 ">
                                                      <img
                                                        src={Checkmark}
                                                        alt=""
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </label>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              </div>
                            </div>
                          )}
                        </div>
                        {!isAddMoreContact && (
                          <div className="flex items-center justify-between">
                            <button
                              type="button"
                              className="text-xs font-medium text-AccentRegular"
                              onClick={() => setIsAddMoreContact(true)}
                            >
                              Additional Contact
                            </button>
                            <span className="text-xs italic font-medium text-Neutral700">
                              Optional
                            </span>
                          </div>
                        )}

                        {isAddMoreContact && (
                          <div className="flex flex-col gap-4">
                            <div className="flex flex-col justify-end min-h-[78px]">
                              <div className="flex items-center justify-between">
                                <span className="text-xs font-bold text-Neutral900">
                                  Contact Person 2
                                </span>
                                <span className="text-xs italic font-medium text-Neutral700">
                                  Optional
                                </span>
                              </div>
                              <div className="mt-2">
                                <input
                                  className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                                  type="text"
                                  placeholder="Vicky Builderino"
                                  name="second_contact_name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values?.second_contact_name}
                                />
                              </div>
                            </div>
                            <div className="flex flex-col justify-end min-h-[78px]">
                              <div className="flex items-center justify-between">
                                <span className="text-xs font-bold text-Neutral900">
                                  Email 2
                                </span>
                                <span className="text-xs italic font-medium text-Neutral700">
                                  Optional
                                </span>
                              </div>
                              <div className="mt-2">
                                <input
                                  className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                                  type="email"
                                  placeholder="Eg. lukeskywalker@gmail.com"
                                  name="second_email"
                                  onChange={(e) => {
                                    const secEmailVal =
                                      e.target.value.toLowerCase();
                                    handleChange({
                                      target: {
                                        name: "second_email",
                                        value: secEmailVal,
                                      },
                                    });
                                  }}
                                  onBlur={handleBlur}
                                  value={values.second_email}
                                />
                              </div>
                            </div>
                            <div className="flex flex-col justify-end min-h-[78px]">
                              <div className="flex items-center justify-between">
                                <span className="text-xs font-bold text-Neutral900">
                                  Phone Number 2
                                </span>
                                <span className="text-xs italic font-medium text-Neutral700">
                                  Optional
                                </span>
                              </div>
                              <PhoneInput
                                name="second_phone_number"
                                value={values.second_phone_number}
                                setFieldValue={setFieldValue}
                                setFieldError={setFieldError}
                                handleBlur={handleBlur}
                                errors={errors}
                                touched={touched}
                                className="mt-2"
                              />
                            </div>
                            <div className="flex flex-col justify-end min-h-[78px]">
                              <div className="flex items-center justify-between">
                                <span className="text-xs font-bold text-Neutral900">
                                  Contact Person 2 Job Title
                                </span>
                                <span className="text-xs italic font-medium text-Neutral700">
                                  Optional
                                </span>
                              </div>
                              <div className="mt-2">
                                <input
                                  className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                                  type="text"
                                  placeholder="Eg. CEO"
                                  name="second_contact_person_job_title"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={
                                    values?.second_contact_person_job_title
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        {!isAddMoreInfo && (
                          <div className="flex items-center justify-between">
                            <button
                              type="button"
                              className="text-xs font-medium text-AccentRegular"
                              onClick={() => setIsAddMoreInfo(true)}
                            >
                              Address, Websites & VAT
                            </button>
                            <span className="text-xs italic font-medium text-Neutral700">
                              Optional
                            </span>
                          </div>
                        )}

                        {isAddMoreInfo && (
                          <div className="grid gap-4">
                            <div className="flex flex-col justify-end min-h-[78px]">
                              <div className="flex items-center justify-between">
                                <span className="text-xs font-bold text-Neutral900">
                                  Address
                                </span>
                                <span className="text-xs italic font-medium text-Neutral700">
                                  Optional
                                </span>
                              </div>
                              <div className="mt-2">
                                <input
                                  className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                                  type="text"
                                  placeholder="Miami, 67879, Hillary Beach"
                                  name="address"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values?.address}
                                />
                              </div>
                            </div>
                            <div className="flex flex-col justify-end min-h-[78px]">
                              <div className="flex items-center justify-between">
                                <span className="text-xs font-bold text-Neutral900">
                                  Website 1
                                </span>
                                <span className="text-xs italic font-medium text-Neutral700">
                                  Optional
                                </span>
                              </div>
                              <div className="mt-2">
                                <input
                                  className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                                  type="text"
                                  placeholder="www.google.com"
                                  name="website_one"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values?.website_one}
                                />
                              </div>
                            </div>
                            <div className="flex flex-col justify-end min-h-[78px]">
                              <div className="flex items-center justify-between">
                                <span className="text-xs font-bold text-Neutral900">
                                  Website 2
                                </span>
                                <span className="text-xs italic font-medium text-Neutral700">
                                  Optional
                                </span>
                              </div>
                              <div className="mt-2">
                                <input
                                  className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                                  type="text"
                                  placeholder="www.apple.com"
                                  name="website_two"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values?.website_two}
                                />
                              </div>
                            </div>
                            <div className="flex flex-col justify-end min-h-[78px]">
                              <div className="flex items-center justify-between">
                                <span className="text-xs font-bold text-Neutral900">
                                  VAT Number
                                </span>
                                <span className="text-xs italic font-medium text-Neutral700">
                                  Optional
                                </span>
                              </div>
                              <div className="mt-2">
                                <input
                                  className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                                  type="text"
                                  placeholder="Enter client VAT number"
                                  name="vat_number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values?.vat}
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        {/* custom fields is an array of objects and each object has a key and value */}
                        {values?.custom_fields?.map((item, index) => {
                          return (
                            <div className="flex flex-col justify-end min-h-[78px]">
                              <div className="flex items-center justify-between">
                                <span className="text-xs font-bold text-Neutral900">
                                  {item?.key}
                                </span>
                                <span className="text-xs italic font-medium text-Neutral700">
                                  Optional
                                </span>
                              </div>
                              <div className="mt-2">
                                <input
                                  className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                                  type="text"
                                  placeholder={item?.key}
                                  name={`custom_fields.${index}.value`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={item?.value}
                                />
                              </div>
                            </div>
                          );
                        })}

                        {/* maximum 5 custom field */}
                        {values?.custom_fields?.length < 5 && (
                          <div className="flex items-center justify-between">
                            <button
                              type="button"
                              className="text-xs font-medium text-AccentRegular"
                              onClick={() => setIsNewCustomField(true)}
                            >
                              Add Custom Field
                            </button>
                            <span className="text-xs italic font-medium text-Neutral700">
                              Optional
                            </span>
                          </div>
                        )}

                        {isNewCustomField && (
                          // Add Custom Field two inputs key and value
                          <div className="flex flex-col gap-4">
                            <div className="flex flex-col justify-end min-h-[78px]">
                              <div className="flex items-center justify-between">
                                <span className="text-xs font-bold text-Neutral900">
                                  Field Title
                                </span>
                                <span className="text-xs italic font-medium text-Neutral700">
                                  Optional
                                </span>
                              </div>
                              <div className="mt-2">
                                <input
                                  placeholder="Eg. Postcode"
                                  className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-xs leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular duration-300 transition"
                                  type="text"
                                  name="key"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values?.key}
                                />
                              </div>
                            </div>
                            <div className="flex flex-col justify-end min-h-[78px]">
                              <div className="flex items-center justify-between">
                                <span className="text-xs font-bold text-Neutral900">
                                  Field Info
                                </span>
                                <span className="text-xs italic font-medium text-Neutral700">
                                  Optional
                                </span>
                              </div>
                              <div className="mt-2">
                                <input
                                  placeholder="Eg. 12345"
                                  className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-xs leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular duration-300 transition"
                                  type="text"
                                  name="value"
                                  // onChange={handleChange}
                                  // onBlur={handleBlur}
                                  value={values?.value}
                                />
                              </div>
                            </div>
                            <div className="mt-8">
                              <button
                                className="relative block w-full py-3 overflow-hidden text-sm font-bold text-center transition duration-300 ease-in-out rounded btn button-hover form-primary-btn text-Neutral100 bg-AccentRegular focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight"
                                onClick={() => {
                                  if (values?.key && values?.value) {
                                    setFieldValue("custom_fields", [
                                      ...values?.custom_fields,
                                      {
                                        key: values?.key,
                                        value: values?.value,
                                      },
                                    ]);
                                    setIsNewCustomField(false);
                                    setFieldValue("key", "");
                                    setFieldValue("value", "");
                                  }
                                }}
                              >
                                <span className="relative z-10">
                                  Add Custom Field
                                </span>
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="mt-8">
                        <button
                          disabled={isSubmitting}
                          className="relative block w-full py-3 overflow-hidden text-sm font-bold text-center transition duration-300 ease-in-out rounded btn button-hover form-primary-btn text-Neutral100 bg-AccentRegular focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight"
                        >
                          {isSubmitting ? (
                            <span className="relative z-10">
                              <svg
                                className="w-5 h-5 m-auto text-white animate-spin"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                            </span>
                          ) : (
                            <span className="relative z-10 drop-shadow-Texts">
                              Continue
                            </span>
                          )}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => {
              setIsOpen(false);
              setIsAddMoreContact(false);
              setIsAddMoreInfo(false);
              setIsNewCustomField(false);
            }}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CreateClientModal;
