import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { GetWhiteLabelPlanDetailAction } from "../../../store/actions/authAction";
import ConfirmWhiteLabelCancelModal from "../../Modals/ConfirmWhiteLabelCancelModal";

function WhiteLabelPlanDetailCard() {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const whiteLabelPlanDetail = useSelector(
    (state) => state.auth.whiteLabelAccountDetail
  );

  const [openCancelModal, setOpenCancelModal] = useState(false);

  useEffect(() => {
    dispatch(GetWhiteLabelPlanDetailAction());
  }, [dispatch]);

  const isLifeTimePlan =
    user?.white_label_payment?.selected_payment_type === "life_time";

  const handlePlanChange = () => {
    window.location.href =
      whiteLabelPlanDetail?.data?.data?.attributes?.urls?.customer_portal_update_subscription;
  };

  const renderUpgradeButton = () => (
    <button
      className="inline-flex items-center gap-1 text-xs font-medium uppercase hover:text-AccentMediumDark text-AccentRegular"
      onClick={handlePlanChange}
    >
      Change Plan
    </button>
  );

  const renderCancelButton = () => (
    <button
      className="text-[10px] 5xl:text-sm bg-AccentMediumLight font-semibold h-7 px-4 flex items-center justify-center rounded leading-[1.4] outline-Red100 text-Red600 focus-visible:outline-none focus:outline focus:outline-4 focus:outline-Red100 transition duration-300 ease-in-out hover:bg-Neutral200 hover:text-Red600 uppercase"
      onClick={() => setOpenCancelModal(true)}
    >
      Cancel Plan
    </button>
  );

  return (
    <section className="w-full max-w-[830px] rounded-sm border border-Neutral300 p-4 md:p-7 bg-Neutral000">
      <div className="flex items-center justify-between w-full mb-7">
        <h5 className="text-xs font-bold tracking-widest uppercase text-Neutral600">
          Account
        </h5>

        {!isLifeTimePlan && renderUpgradeButton()}
      </div>

      {/* UI-TODO: Add plan details here */}
      <div className="flex items-center justify-between w-full mb-3">
        <h5 className="text-sm font-bold text-Neutral900">Type</h5>
        <span className="text-xs font-medium text-Neutral900">
          {user?.white_label_payment?.selected_payment_type === "monthly" &&
            "Monthly"}
          {user?.white_label_payment?.selected_payment_type === "yearly" &&
            "Yearly"}
          {user?.white_label_payment?.selected_payment_type === "life_time" &&
            "Lifetime"}
        </span>
      </div>

      <div className="flex items-center justify-between w-full">
        <h5 className="text-sm font-bold text-Neutral900">Price</h5>
        <span className="text-xs font-medium text-Neutral900">
          {user?.white_label_payment?.price}
        </span>
      </div>

      {!isLifeTimePlan && (
        <div className="flex items-center justify-between w-full mt-6">
          <h5 className="text-xs font-bold tracking-widest uppercase text-Neutral600">
            Next Payment
          </h5>
          <span className="text-xs font-medium text-Neutral900">
            {moment(user?.white_label_payment?.next_bill_date).format(
              "DD MMM YYYY"
            )}
          </span>
        </div>
      )}

      {!isLifeTimePlan && (
        <>
          <hr className="my-3 border-none h-[1px] bg-Neutral300" />
          <div className="flex items-center justify-between w-full mt-4">
            <h5 className="text-xs font-bold tracking-widest uppercase text-Neutral600">
              Danger Zone
            </h5>
            {renderCancelButton()}
          </div>
        </>
      )}

      <ConfirmWhiteLabelCancelModal
        isOpen={openCancelModal}
        setIsOpen={setOpenCancelModal}
      />
    </section>
  );
}

export default WhiteLabelPlanDetailCard;
