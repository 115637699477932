import React from "react";

const DownArrowIcon = ({ width = 15, color = "#292933", className = "" }) => {
  return (
    <svg
      width={width}
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.37667 13.3536C7.57193 13.5488 7.88852 13.5488 8.08378 13.3536L11.2658 10.1716C11.461 9.97631 11.461 9.65973 11.2658 9.46447C11.0705 9.2692 10.7539 9.2692 10.5587 9.46447L7.73022 12.2929L4.9018 9.46447C4.70653 9.2692 4.38995 9.2692 4.19469 9.46447C3.99943 9.65973 3.99943 9.97631 4.19469 10.1716L7.37667 13.3536ZM7.23022 1L7.23022 13L8.23022 13L8.23022 1L7.23022 1Z"
        fill={color}
      />
    </svg>
  );
};

export default DownArrowIcon;
