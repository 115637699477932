import React, { Fragment, useState } from "react";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import urgent from "../../assets/Images/urgent.svg";
import iconText from "../../assets/Images/icon-text.svg";
import Modal from "react-modal";
import CloseBtn from "../../assets/Images/close-btn.svg";
import { Menu, Transition } from "@headlessui/react";
import ThreeDots from "../../assets/Images/three-dots.svg";
import Headphones from "../../assets/Images/Headphones.svg";
import Isometric from "../../assets/Images/Isometric-Stickers-Alarm-Clock.svg";
import People from "../../assets/Images/People.svg";
import member from "../../assets/Images/kierra-botosh.png";
import Contract from "../../assets/Images/Contract-Mdaol.svg";
import CalendarIcon from "../../assets/Images/Calendar-icon.svg";
import Tag from "../../assets/Images/Tag.svg";
import threeDots from "../../assets/Images/three-dots-modals.svg";
import addTagClose from "../../assets/Images/modal-close-icon.svg";
import addTagMinus from "../../assets/Images/addTag-minus.svg";
import pluseIcon from "../../assets/Images/pluse-icon.svg";
import EditIcon from "../../assets/Images/Edit.svg";
import TrashIcon from "../../assets/Images/Trash.svg";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const NewTaskModal = ({ modalClient, setIsmodalClient }) => {
  const [addClass, setIsAddClass] = React.useState(true);
  const [removeClass, setIsRemoveClass] = React.useState(false);
  const [addTag, setIsAddTag] = React.useState(false);
  const [inputEdit, setInputEdit] = useState(false);

  return (
    <Modal
      isOpen={modalClient}
      onRequestClose={() => setIsmodalClient(false)}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="View Form Preview Modal"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 sm:min-w-[886px] max-h-[567px] max-w-[886px] sm:min-h-[400px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="flex">
          <div className="w-full pt-[32px] pl-[48px] pb-[48px]">
            <p
              className="text-Neutral900 text-[32px] font-bold focus:outline-none"
              contenteditable="true"
            >
              Eat a Giant Pizza
            </p>
            <div className="relative">
              <Menu
                as="div"
                className="relative inline-block text-left ml-[-16px]"
              >
                <Menu.Button className="inline-flex min-w-[312px] w-full focus-visible:outline-none popup-btn relative button-Status px-[16px] pb-[6px] pt-[10px]">
                  <span className="focus-visible:outline-none">
                    <div className="flex flex-wrap items-center">
                      <p className="bg-Neutral200 h-4 text-[10px] font-semibold flex items-center rounded-[16px] px-1 mr-3">
                        <span className="h-2 w-2 text-Neutral900 rounded-full bg-[#5CACE5] inline-block mr-[4px]"></span>
                        NOT STARTED
                      </p>
                      <p className="bg-Neutral900 font-semibold pb-[2px] flex items-center text-white text-[10px] rounded-[2px] px-1">
                        <img src={urgent} className="mr-1" alt="" />
                        URGENT
                      </p>
                      <img className="menu-open-img" src={ThreeDots} alt="" />
                    </div>
                  </span>
                </Menu.Button>
                <Transition as={Fragment}>
                  <Menu.Items className="origin-top-right z-50 absolute top-[29px] right-0 bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none w-full pb-4 px-4 ">
                    <div className="">
                      <p className="text-Neutral600 text-[10px] font-semibold pb-[12px] pt-[16px]">
                        Select Task Status
                      </p>
                      <div className="flex flex-wrap items-center">
                        <p className="h-4 text-[10px] font-semibold flex items-center rounded-[16px] px-1 mr-4">
                          <span className="h-2 w-2 text-Neutral900 rounded-full bg-[#E5675C] inline-block mr-[4px]"></span>
                          NOT STARTED
                        </p>
                        <p className="bg-Neutral200 h-4 text-[10px] font-semibold flex items-center rounded-[16px] px-1 mr-4">
                          <span className="h-2 w-2 text-Neutral900 rounded-full bg-[#5CACE5] inline-block mr-[4px]"></span>
                          In Progress
                        </p>
                        <p className="h-4 text-[10px] font-semibold flex items-center rounded-[16px] px-1">
                          <span className="h-2 w-2 text-Neutral900 rounded-full bg-[#59B347] inline-block mr-[4px]"></span>
                          COMPLETED
                        </p>
                      </div>
                      <p className="text-Neutral600 font-semibold pb-[12px] pt-[20px]">
                        Toggle Task Priority
                      </p>
                      <div className="flex flex-wrap">
                        <div
                          onClick={() => setIsAddClass(false)}
                          className={`border border-inherit transition-all duration-300 cursor-pointer w-[72px] h-[72px] flex flex-col items-center justify-around overflow-hidden relative after:content-[''] after:w-full after:h-full after:absolute after:bg-Neutral200 rounded-l-[2px] ${
                            addClass
                              ? "after:opacity-70"
                              : "after:opacity-0 bg-white border border-[#EDEDF2]"
                          }`}
                        >
                          <img src={Headphones} alt="" />
                          <p className="text-Neutral900 font-semibold text-[10px]">
                            NORMAL
                          </p>
                        </div>
                        <div
                          onClick={() => setIsAddClass(true)}
                          className={`border border-inherit transition-all duration-300 cursor-pointer w-[72px] h-[72px] flex flex-col items-center justify-around overflow-hidden relative after:content-[''] after:w-full after:h-full after:absolute after:bg-Neutral200 rounded-r-[2px] ${
                            addClass
                              ? "after:opacity-0 bg-white border border-[#EDEDF2]"
                              : "after:opacity-70"
                          }`}
                        >
                          <img src={Isometric} alt="" />
                          <p className="text-Neutral900 font-semibold text-[10px]">
                            URGENT
                          </p>
                        </div>
                      </div>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <div className="relative pt-[40px]">
              <div className="flex items-center">
                <img src={People} className="mr-[12px]" alt="" />
                <p className="text-Neutral600 text-[10px]">ASSIGNED</p>
              </div>
              <Menu
                as="div"
                className="relative inline-block text-left ml-[-16px] w-full"
              >
                <Menu.Button className="hover:rounded-[4px] inline-flex w-full assigned-btn focus-visible:outline-none popup-btn relative button-Status px-[16px] pb-[10px] pt-[10px]">
                  <span className="focus-visible:outline-none">
                    <div className="members-text flex flex-wrap text-[14px] items-center text-Neutral700">
                      Assign team members...
                    </div>
                    <div className="eam-text text-[14px] items-center text-Neutral700">
                      eam members will appear here...
                    </div>
                  </span>
                </Menu.Button>
                <Transition as={Fragment}>
                  <Menu.Items className="origin-top-right z-50 absolute top-[29px] right-0 bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none w-full pb-4 px-4 ">
                    <div className="bg-white">
                      <p className="text-Neutral600 text-[10px] font-semibold pt-[18px] pb-[12px]">
                        Add Team Members
                      </p>
                      <div>
                        <div className="flex items-center rounded-lg py-[6px] hover:bg-Neutral200 ml-[-8px] pl-[10px] cursor-pointer">
                          <div className="rounded-full h-[24px] w-[24px] overflow-hidden">
                            <img src={member} className="object-cover" alt="" />
                          </div>
                          <p className="text-Neutral900 pl-4 text-sm font-normal">
                            Lindsey Botosh
                          </p>
                        </div>
                        <div className="flex items-center rounded-lg py-[6px] hover:bg-Neutral200 ml-[-8px] pl-[10px] cursor-pointer">
                          <div className="rounded-full h-[24px] w-[24px] overflow-hidden">
                            <img src={member} className="object-cover" alt="" />
                          </div>
                          <p className="text-Neutral900 pl-4 text-sm font-normal">
                            Lindsey Botosh
                          </p>
                        </div>
                        <div className="flex items-center rounded-lg py-[6px] hover:bg-Neutral200 ml-[-8px] pl-[10px] cursor-pointer">
                          <div className="rounded-full h-[24px] w-[24px] overflow-hidden">
                            <img src={member} className="object-cover" alt="" />
                          </div>
                          <p className="text-Neutral900 pl-4 text-sm font-normal">
                            Lindsey Botosh
                          </p>
                        </div>
                        <div className="flex items-center rounded-lg py-[6px] hover:bg-Neutral200 ml-[-8px] pl-[10px] cursor-pointer">
                          <div className="rounded-full h-[24px] w-[24px] overflow-hidden">
                            <img src={member} className="object-cover" alt="" />
                          </div>
                          <p className="text-Neutral900 pl-4 text-sm font-normal">
                            Lindsey Botosh
                          </p>
                        </div>
                        <div className="flex items-center rounded-lg py-[6px] hover:bg-Neutral200 ml-[-8px] pl-[10px] cursor-pointer">
                          <div className="rounded-full h-[24px] w-[24px] overflow-hidden">
                            <img src={member} className="object-cover" alt="" />
                          </div>
                          <p className="text-Neutral900 pl-4 text-sm font-normal">
                            Lindsey Botosh
                          </p>
                        </div>
                      </div>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <div className="relative">
              <div className="flex items-center pt-9">
                <img src={Contract} className="mr-[12px]" alt="" />
                <p className="text-Neutral600 text-[10px]"></p>
              </div>
              <div
                className="hover:bg-Neutral200 rounded-[4px] ml-[-16px] text-sm test w-full max-w-[524px] focus:outline-none pt-[9px] pb-[9px] pl-[16px] pr-[16px] m-0"
                placeholder="Write a description..."
                contenteditable="true"
              ></div>
            </div>
            <div className="w-full">
              <div className="flex flex-wrap w-full">
                <div className="relative w-5/12">
                  <div className="flex items-center pt-9">
                    <img src={CalendarIcon} className="mr-[12px]" alt="" />
                    <p className="text-Neutral600 text-[10px]">DUE DATE</p>
                  </div>
                  <div className="relative">
                    <p className="text-Neutral600 text-[14px] pt-[10px]">
                      Select a date
                    </p>
                    <input
                      type="date"
                      className="task-celender absolute top-[7px] left-0 opacity-0"
                    />
                  </div>
                </div>
                <div className="relative w-7/12">
                  <div className="flex items-center pt-9">
                    <img src={Tag} className="mr-[12px]" alt="" />
                    <p className="text-Neutral600 text-[10px]">CATEGORY</p>
                  </div>
                  <Menu
                    as="div"
                    className="relative inline-block text-left ml-[-16px] w-full"
                  >
                    <Menu.Button className="hover:rounded-[4px] inline-flex w-full assigned-btn focus-visible:outline-none popup-btn relative button-Status px-[16px] pb-[10px] pt-[10px]">
                      <span className="focus-visible:outline-none">
                        <div className="members-text flex flex-wrap text-[14px] items-center text-Neutral700">
                          Assign team members...
                        </div>
                        <div className="eam-text text-[14px] items-center text-Neutral700">
                          eam members will appear here...
                        </div>
                      </span>
                    </Menu.Button>
                    <Transition as={Fragment}>
                      <Menu.Items className="origin-top-right z-50 absolute top-[29px] right-0 bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none w-full pb-4 px-4 ">
                        <div className="bg-white">
                          <div className="flex justify-between items-center">
                            <p className="text-Neutral600 font-semibold text-[10px] pt-[18px] pb-[12px]">
                              + Add Category Tags
                            </p>
                            <div className="w-2 flex items-center justify-center cursor-pointer">
                              <img
                                onClick={() => setIsRemoveClass(true)}
                                className={`${removeClass ? "hidden" : ""}`}
                                src={threeDots}
                                alt=""
                              />
                              <img
                                onClick={() => {
                                  setIsRemoveClass(false);
                                  setIsAddTag(false);
                                }}
                                className={`${removeClass ? "" : "hidden"}`}
                                src={addTagClose}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="flex flex-wrap items-center">
                            <span className="text-[12px] 5xl:text-xs 5xl:min-w-[40px] min-w-[32px] w-full max-w-fit flex items-center 5xl:min-h-[24px] min-h-[18px] mr-2 my-1 font-semibold rounded-[6px] cursor-pointer text-Neutral700 border border-Neutral400 relative">
                              <button className="py-1 px-2 uppercase tracking-wider">
                                SEO
                              </button>
                              <img
                                className={`absolute -top-2 -right-2 ${
                                  removeClass ? "" : "hidden"
                                }`}
                                src={addTagMinus}
                                alt=""
                              />
                            </span>
                            <span className="text-[12px] 5xl:text-xs 5xl:min-w-[40px] min-w-[32px] w-full max-w-fit flex items-center 5xl:min-h-[24px] min-h-[18px] mr-2 my-1 font-semibold rounded-[6px] cursor-pointer text-Neutral700 border border-Neutral400 relative">
                              <button className="py-1 px-2 uppercase tracking-wider">
                                BLOG
                              </button>
                              <img
                                className={`absolute -top-2 -right-2 ${
                                  removeClass ? "" : "hidden"
                                }`}
                                src={addTagMinus}
                                alt=""
                              />
                            </span>
                            <span className="text-[12px] 5xl:text-xs 5xl:min-w-[40px] min-w-[32px] w-full max-w-fit flex items-center 5xl:min-h-[24px] min-h-[18px] mr-2 my-1 font-semibold rounded-[6px] cursor-pointer text-Neutral700 border border-Neutral400 relative">
                              <button className="py-1 px-2 uppercase tracking-wider">
                                DESIGN
                              </button>
                              <img
                                className={`absolute -top-2 -right-2 ${
                                  removeClass ? "" : "hidden"
                                }`}
                                src={addTagMinus}
                                alt=""
                              />
                            </span>
                            <span className="text-[12px] 5xl:text-xs 5xl:min-w-[40px] min-w-[32px] w-full max-w-fit flex items-center 5xl:min-h-[24px] min-h-[18px] mr-2 my-1 font-semibold rounded-[6px] cursor-pointer text-Neutral700 border border-Neutral400 relative">
                              <button className="py-1 px-2 uppercase tracking-wider">
                                ADMIN
                              </button>
                              <img
                                className={`absolute -top-2 -right-2 ${
                                  removeClass ? "" : "hidden"
                                }`}
                                src={addTagMinus}
                                alt=""
                              />
                            </span>
                            <span className="text-[12px] 5xl:text-xs 5xl:min-w-[40px] min-w-[32px] w-full max-w-fit flex items-center 5xl:min-h-[24px] min-h-[18px] mr-2 my-1 font-semibold rounded-[6px] cursor-pointer text-Neutral700 border border-Neutral400 relative">
                              <button className="py-1 px-2 uppercase tracking-wider">
                                UX
                              </button>
                              <img
                                className={`absolute -top-2 -right-2 ${
                                  removeClass ? "" : "hidden"
                                }`}
                                src={addTagMinus}
                                alt=""
                              />
                            </span>
                            {removeClass ? (
                              <span className="cursor-pointer border border-dashed border-Neutral600 h-[25px] rounded-[6px] box-border">
                                <p
                                  onClick={() => setIsAddTag(true)}
                                  className={`py-1 px-2 flex text-AccentRegular font-medium text-xs ${
                                    addTag ? "hidden" : ""
                                  }`}
                                >
                                  <img
                                    src={pluseIcon}
                                    className="mr-1"
                                    alt=""
                                  />{" "}
                                  ADD TAG{" "}
                                </p>
                                <input
                                  type="text"
                                  className={`focus:outline-none px-1 h-[20px] ${
                                    addTag ? "" : "hidden"
                                  }`}
                                  size="5"
                                />
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
          <div className="min-w-[314px] border-l border-Neutral300 relative">
            <div className="pt-4 h-fill overflow-auto pb-[110px] max-h-[560px]">
              <div className="flex pl-6 pr-8 flex-wrap items-center relative border-b border-Neutral300 py-4">
                <div className="rounded-full h-[32px] w-[32px] overflow-hidden">
                  <img src={member} className="object-cover" alt="" />
                </div>
                <div className="pl-3">
                  <h6 className="text-Neutral900 font-semibold text-sm">
                    Rayna K
                  </h6>
                  <p className="text-Neutral700 font-normal text-sm">
                    13:45 16 Sept
                  </p>
                </div>
                <Menu
                  as="div"
                  className="absolute top-[13px] right-[17px] flex items-center text-left"
                >
                  <div className="flex items-center">
                    <Menu.Button className="inline-flex justify-center w-full">
                      <img src={ThreeDots} alt="" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right edit-delete-menu absolute z-[999] top-0 right-0 w-[100px] rounded-lg dropdowns-boxshadow bg-Neutral000 border-[0.5px] border-Neutral200 divide-y divide-Neutral300 focus:outline-none">
                      <form method="POST" action="#">
                        <Menu.Item>
                          {({ active }) => (
                            <div>
                              <div
                                className="w-[100px] text-left px-4 py-2 text-xs whitespace-nowrap flex justify-center items-center cursor-pointer text-AccentRegular font-semibold hover:bg-Neutral200 transition duration-300"
                                onClick={() => setInputEdit(true)}
                              >
                                Edit
                                <img className="ml-1" src={EditIcon} alt="" />
                              </div>
                              <div className="text-Red400 w-[100px] text-left px-4 py-2 text-xs whitespace-nowrap flex justify-center items-center border-t-[0.5px] border-Neutral200 cursor-pointer font-semibold hover:bg-Neutral200 transition duration-300">
                                Delete
                                <img className="ml-1" src={TrashIcon} alt="" />
                              </div>
                            </div>
                          )}
                        </Menu.Item>
                      </form>
                    </Menu.Items>
                  </Transition>
                </Menu>
                <p className="w-full text-Neutral800 text-sm pb-2 pt-2">
                  Hey team, here is the first comment for this giant pizza task.
                  Let’s go!
                </p>
                <span className="cursor-pointer text-[8px] text-Neutral700 border border-[#EDEDF2] font-bold p-[2px] rounded-[4px]">
                  Edited
                </span>
              </div>
              <div className="flex pl-6 pr-8 flex-wrap items-center  relative border-b border-Neutral300 py-4">
                <div className="rounded-full h-[32px] w-[32px] overflow-hidden">
                  <img src={member} className="object-cover" alt="" />
                </div>
                <div className="pl-3">
                  <h6 className="text-Neutral900 font-semibold text-sm">
                    Rayna K
                  </h6>
                  <p className="text-Neutral700 font-normal text-sm">
                    13:45 16 Sept
                  </p>
                </div>
                <Menu
                  as="div"
                  className="absolute top-[13px] right-[17px] flex items-center text-left"
                >
                  <div className="flex items-center">
                    <Menu.Button className="inline-flex justify-center w-full">
                      <img src={ThreeDots} alt="" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right edit-delete-menu absolute z-[999] top-0 right-0 w-[100px] rounded-lg dropdowns-boxshadow bg-Neutral000 border-[0.5px] border-Neutral200 divide-y divide-Neutral300 focus:outline-none">
                      <form method="POST" action="#">
                        <Menu.Item>
                          {({ active }) => (
                            <div>
                              <div
                                className="w-[100px] text-left px-4 py-2 text-xs whitespace-nowrap flex justify-center items-center cursor-pointer text-AccentRegular font-semibold hover:bg-Neutral200 transition duration-300"
                                onClick={() => setInputEdit(true)}
                              >
                                Edit
                                <img className="ml-1" src={EditIcon} alt="" />
                              </div>
                              <div className="text-Red400 w-[100px] text-left px-4 py-2 text-xs whitespace-nowrap flex justify-center items-center border-t-[0.5px] border-Neutral200 cursor-pointer font-semibold hover:bg-Neutral200 transition duration-300">
                                Delete
                                <img className="ml-1" src={TrashIcon} alt="" />
                              </div>
                            </div>
                          )}
                        </Menu.Item>
                      </form>
                    </Menu.Items>
                  </Transition>
                </Menu>
                <p className="w-full text-Neutral800 text-sm pb-2 pt-2">
                  Hey team, here is the first comment for this giant pizza task.
                  Let’s go!
                </p>
                <span className="cursor-pointer text-[8px] text-Neutral700 border border-[#EDEDF2] font-bold p-[2px] rounded-[4px]">
                  Edited
                </span>
              </div>
            </div>
            <div className="absolute bottom-0 w-full h-[110px] bg-white border-t border-Neutral300 pt-3 pr-2 pl-6 pb-3 overflow-auto">
              <div>
                <textarea
                  className="placeholder:text-Neutral600 text-sm focus:outline-none w-full h-full"
                  placeholder="Write comment..."
                ></textarea>
              </div>
              <div className="flex">
                <button
                  type="button"
                  className="overflow-hidden relative rounded-[4px] focus:outline focus:outline-8 focus:outline-AccentMediumLight focus-visible:outline-0 transition duration-300 ease-in-out button-hover mr-[5px] py-2 bg-AccentRegular text-white text-[10px] font-semibold w-full"
                >
                  <span className="relative drop-shadow-Texts z-10">Send</span>
                </button>
                <button
                  type="button"
                  className="hover:bg-AccentMediumLight rounded-[4px] ml-[5px] py-2 bg-Neutral300 text-Neutral800 text-[10px] font-semibold w-full focus-visible:outline-none focus:outline focus:outline-8 focus:outline-AccentMediumLight transition duration-300"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => setIsmodalClient(false)}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
};

export default NewTaskModal;
