/** @param {import("react").SVGProps} props - SVG Icon Props */
function BoltIcon(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.51917 2.66675L3.33398 9.06675H8.00065L7.48213 13.3334L12.6673 6.93341H8.00065L8.51917 2.66675Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default BoltIcon;
