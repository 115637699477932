import { Field } from "formik";

import ButtonLoader from "../Loaders/ButtonLoader";
import AddressAutocomplete from "./AddressAutocomplete";
import CustomErrorMessage from "./CustomErrorMessage";

function Step3({ isSubmitting }) {
  return (
    <>
      <div className="flex flex-col gap-5">
        {/* EMAIL */}
        <div className="space-y-2">
          <label
            htmlFor="email"
            className="block text-xs font-bold text-zinc-800"
          >
            Email
          </label>
          <Field
            name="email"
            type="text"
            className="w-full p-3 text-sm font-medium bg-white border border-gray-100 rounded outline-none text-neutral-500"
            placeholder="Enter E-mail"
          />
          <CustomErrorMessage name="email" />
        </div>

        {/* FULL NAME */}
        <div className="space-y-2">
          <label
            htmlFor="fullName"
            className="block text-xs font-bold text-zinc-800"
          >
            Enter Full Name
          </label>
          <Field
            name="fullName"
            type="text"
            className="w-full p-3 text-sm font-medium bg-white border border-gray-100 rounded outline-none text-neutral-500"
            placeholder="Eg. John P."
          />
          <CustomErrorMessage name="fullName" />
        </div>

        {/* COMPANY NAME */}
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <label
              htmlFor="companyName"
              className="block text-xs font-bold text-zinc-800"
            >
              Company Name
            </label>
            <span className="text-xs italic font-medium text-Neutral700">
              Optional
            </span>
          </div>
          <Field
            name="companyName"
            type="text"
            className="w-full p-3 text-sm font-medium bg-white border border-gray-100 rounded outline-none text-neutral-500"
            placeholder="Eg. John P."
          />
          <CustomErrorMessage name="companyName" />
        </div>

        {/* ADDRESS */}
        <AddressAutocomplete label="Address" name="address" />

        {/* PHONE NUMBER */}
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <label
              htmlFor="phoneNumber"
              className="block text-xs font-bold text-zinc-800"
            >
              Phone Number
            </label>
            <span className="text-xs italic font-medium text-Neutral700">
              Optional
            </span>
          </div>
          <Field
            name="phoneNumber"
            type="text"
            className="w-full p-3 text-sm font-medium bg-white border border-gray-100 rounded outline-none text-neutral-500"
            placeholder="Enter phone number"
          />
          <CustomErrorMessage name="phoneNumber" />
        </div>

        {/* VAT */}
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <label
              htmlFor="VAT"
              className="block text-xs font-bold text-zinc-800"
            >
              VAT Number
            </label>
            <span className="text-xs italic font-medium text-Neutral700">
              Optional
            </span>
          </div>
          <Field
            name="VAT"
            type="text"
            className="w-full p-3 text-sm font-medium bg-white border border-gray-100 rounded outline-none text-neutral-500"
            placeholder="Enter VAT Number"
          />
          <CustomErrorMessage name="VAT" />
        </div>
      </div>

      <button
        type="submit"
        className="w-full p-2 py-3 text-xs font-bold tracking-wide uppercase rounded bg-Neutral300 text-AccentRegular hover:text-white not-disabled:hover:bg-AccentRegular disabled:text-Neutral600 disabled:cursor-not-allowed"
        disabled={isSubmitting}
      >
        {isSubmitting ? <ButtonLoader /> : "Save"}
      </button>
    </>
  );
}

export default Step3;
