import axios from "axios";
import { toast } from "react-toastify";

export const CreateServiceCommentActions = (
  value,
  setLoading,
  setComment,
  EditorState,
  setAttachments
) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        "api/v1/service-comment/create-service-comment",
        value
      );
      setLoading(false);
      setComment(EditorState.createEmpty());
      setAttachments([]);
      return dispatch({
        type: "CREATE_SERVICE_COMMENT_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setLoading(false);
        return dispatch({
          type: "CREATE_SERVICE_COMMENT_ERROR",
          payload: error,
        });
      } else {
        setLoading(false);
        return dispatch({
          type: "CREATE_SERVICE_COMMENT_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const UpdateServiceCommentActions = (value, setIsEdit, setIsLoading) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.put(
        "api/v1/service-comment/update-service-comment",
        value
      );
      setIsEdit(false);
      setIsLoading(false);
      return dispatch({
        type: "UPDATE_SERVICE_COMMENT_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setIsEdit(false);
        return dispatch({
          type: "UPDATE_SERVICE_COMMENT_ERROR",
          payload: error,
        });
      } else {
        setIsEdit(false);
        return dispatch({
          type: "UPDATE_SERVICE_COMMENT_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const DeleteServiceCommentAction = (id, service_id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.delete(
        `api/v1/service-comment/delete-service-comment?id=${id}&service_id=${service_id}`
      );
      return dispatch({
        type: "DELETE_SERVICE_COMMENT_SUCCESS",
        payload: { id, data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "DELETE_SERVICE_COMMENT_ERROR",
          payload: { error },
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "DELETE_SERVICE_COMMENT_ERROR",
          payload: { error },
        });
      }
    }
  };
};
