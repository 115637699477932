import React, { useEffect, useState } from "react";
import clx from "classnames";

import SectionTitle from "../../common/typography/SectionTitle";
import TasksManagement from "./components/TasksManagement";
import TimeTrackingManagement from "./components/TimeTrackingManagement";
import TasksModal from "./components/TasksModal";
import TrackingAddTimeEntryModal from "../../../components/Modals/TrackingAddTimeEntryModal";
import NewTaskModal from "../../Modals/NewTaskModal";
import TimeTrackingModal from "./components/TimeTrackingModal";
import TaskDetailModal from "../../Modals/TaskDetailModal";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

function ProjectManagementSection({ project = null, className = "" }) {
  const [isTasksModalOpen, setIsTasksModalOpen] = useState(false);
  const [isTimeModalOpen, setIsTimeModalOpen] = useState(false);
  const [addNewTimeEntry, setAddNewTimeEntry] = useState(null);
  const [modalClientDetail, setModalClientDetail] = useState(null);

  const [isNewTaskModalOpen, setIsNewTaskModalOpen] = useState(false);
  const [isTrackingEditModalOpen, setIsTrackingEditModalOpen] = useState(false);
  const [isStartTimerModalOpen, setIsStartTimerModalOpen] = useState(false);
  const [checkedTask, setCheckedTask] = useState(0);
  const [frontFilter, setFrontfilter] = useState();
  const [timeEntryList, setTimeEntryList] = useState([]);

  const onSwitchModals = () => {
    setIsTasksModalOpen(!isTasksModalOpen);
    setIsTimeModalOpen(!isTimeModalOpen);
  };
  const clientDetail = useSelector((state) => state.client.clientDetail);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const isNew = params.get("isNew");
  const cardId = params.get("cardId");

  useEffect(() => {
    if (cardId) {
      setIsTasksModalOpen(true);
    }
  }, [cardId]);

  return (
    <section className={clx("w-100% overflow-visible", className)}>
      <div className="flex items-center mb-5">
        <SectionTitle text="Project Management" />
      </div>
      <div className="flex flex-col xl:flex-row gap-10 relative">
        <TasksManagement
          setFrontfilter={setFrontfilter}
          project={project}
          setIsOpen={setIsTasksModalOpen}
          setIsNewTaskModalOpen={setIsNewTaskModalOpen}
          setCheckedTask={setCheckedTask}
        />
        <TimeTrackingManagement
          timeTracking={project}
          setIsOpen={setIsTimeModalOpen}
          setIsTrackingEditModalOpen={setIsTrackingEditModalOpen}
          setModalClientDetail={setModalClientDetail}
          project={project}
          setIsStartTimerModalOpen={setIsStartTimerModalOpen}
        />
        {isTasksModalOpen && (
          <TasksModal
            frontFilter={frontFilter}
            checkedTask={checkedTask}
            setCheckedTask={setCheckedTask}
            isOpen={isTasksModalOpen}
            setIsOpen={setIsTasksModalOpen}
            project={project}
            onSwitchModals={onSwitchModals}
            setIsNewTaskModalOpen={setIsNewTaskModalOpen}
          />
        )}
        <TimeTrackingModal
          project={project}
          isOpen={isTimeModalOpen}
          setIsOpen={setIsTimeModalOpen}
          onSwitchModals={onSwitchModals}
          setIsTrackingEditModalOpen={setIsTrackingEditModalOpen}
          addNewTimeEntry={addNewTimeEntry}
          setModalClientDetail={setModalClientDetail}
          isStartTimerModalOpen={isStartTimerModalOpen}
          setIsStartTimerModalOpen={setIsStartTimerModalOpen}
          setTimeEntryList={setTimeEntryList}
          timeEntryList={timeEntryList}
        />
        {isTrackingEditModalOpen && (
          <TrackingAddTimeEntryModal
            modalIsOpen={isTrackingEditModalOpen}
            setIsOpen={setIsTrackingEditModalOpen}
            setAddNewTimeEntry={setAddNewTimeEntry}
            modalClientDetail={modalClientDetail}
            setTimeEntryList={setTimeEntryList}
          />
        )}
        <NewTaskModal
          modalClient={isNewTaskModalOpen}
          setIsmodalClient={setIsNewTaskModalOpen}
        />
        {isNew && (
          <TaskDetailModal
            project={project}
            projectId={project?._id}
            clientId={clientDetail?.data?._id}
            columnsName={project?.task_column}
            column={project?.task_column?.[0]}
            types="task"
          />
        )}
      </div>
    </section>
  );
}

export default ProjectManagementSection;
