import moment from "moment";

class Timer {
  static _time = 0;
  static _id = null;
  static _isStarted = false;
  static _isRunning = false;
  static _project = {
    id: null,
    title: "",
    description: "",
  };

  static start(data) {
    const { project } = data;
    Timer._id = setInterval(() => {
      Timer._time += 1;
    }, 1000);
    Timer._project = {
      id: project?.id,
      title: project?.title,
    };
    Timer._isRunning = true;
    Timer._isStarted = true;
  }

  static stop() {
    Timer._isRunning = false;
    clearInterval(Timer._id);
  }

  static flush() {
    clearInterval(Timer._id);
    Timer._id = null;
    Timer._time = 0;
    Timer._isRunning = false;
    Timer._isStarted = false;
    Timer._project = {};
  }

  static reloadAfterRefresh(data) {
    const {
      start_time,
      project,
      title,
      category,
      is_reload = true,
      pause_time,
      restart_time,
    } = data;

    if (pause_time && restart_time) {
      Timer._time = moment().diff(moment(restart_time), "seconds") + pause_time;
    } else if (pause_time && !restart_time) {
      Timer._time = pause_time;
    } else {
      Timer._time = moment().diff(moment(start_time), "seconds");
    }

    if (is_reload) {
      Timer._id = setInterval(() => {
        Timer._time += 1;
      }, 1000);
      Timer._isRunning = true;
    }
    Timer._isStarted = true;
    Timer._project = {
      id: project,
      title: title,
      category: category,
    };
  }

  static pause() {
    Timer._isRunning = false;
    clearInterval(Timer._id);
  }

  static resume(data) {
    const { pause_time } = data;
    Timer._time = pause_time;
    Timer._id = setInterval(() => {
      Timer._time += 1;
    }, 1000);
    Timer._isRunning = true;
  }

  static getCurrent() {
    return {
      id: Timer._id,
      time: Timer._time,
      project: Timer._project,
      isRunning: Timer._isRunning,
      isStarted: Timer._isStarted,
    };
  }
}

export default Timer;
