const initialState = {
  documents: {
    isLoading: true,
    client: null,
    data: {
      invoice: null,
      contract: [],
      reports: null,
      miscellaneous: null,
    },
  },
};
const documentsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_DOCUMENTS":
      return {
        ...state,
        documents: {
          ...state.documents,
          ...payload,
          data: { ...state.documents.data, ...payload.data },
        },
      };
    case "SET_CONTRACT":
      return {
        ...state,
        documents: {
          ...state.documents,
          ...payload,
          data: {
            ...state.documents.data,
            contract: state?.documents?.data?.contract?.length
              ? [...state?.documents?.data?.contract, payload?.data]
              : [payload?.data],
          },
        },
      };
    case "UPDATE_CONTRACT":
      let tempContract = state.documents.data.contract;
      let index = tempContract.findIndex((e) => e._id === payload.data._id);
      tempContract[index] = payload.data;
      return {
        ...state,
        documents: {
          ...state.documents,
          ...payload,
          data: {
            ...state.documents.data,
            contract: tempContract,
          },
        },
      };
    case "DELETE_DOCUMENT_CONTRATS_SUCCESS":
      let tempDataContract = state.documents.data;
      const newToDOContract = tempDataContract?.contract?.filter(
        (e) => e?._id !== payload?.id
      );
      tempDataContract.contract = newToDOContract;
      return {
        ...state,
        documents: {
          ...state.documents,
          data: tempDataContract,
        },
      };
    case "DELETE_DOCUMENT_SUCCESS":
      let tempData = state.documents.data;
      if (payload.type === "invoice") {
        const newToDO = tempData?.invoice?.items?.filter(
          (e) => e?._id !== payload?.data?.item_id
        );
        tempData.invoice = { ...tempData.invoice, items: newToDO };
      }
      if (payload.type === "reports") {
        const newToDO = tempData?.reports?.items?.filter(
          (e) => e?._id !== payload?.data?.item_id
        );
        tempData.reports = { ...tempData.reports, items: newToDO };
      }
      if (payload.type === "miscellaneous") {
        const newToDO = tempData?.miscellaneous?.items?.filter(
          (e) => e?._id !== payload?.data?.item_id
        );
        tempData.miscellaneous = { ...tempData.miscellaneous, items: newToDO };
      }
      return {
        ...state,
        documents: {
          ...state.documents,
          data: tempData,
        },
      };
    case "SET_NEW_CONTRACT":
      return {
        ...state,
        documents: {
          ...state.documents,
          data: {
            ...state.documents.data,
            contract: [...state.documents.data.contract, payload.data],
          },
        },
      };
    default:
      return state;
  }
};

export default documentsReducer;
