import React, { forwardRef, useRef, useState } from "react";
import Modal from "react-modal";
import CloseBtn from "../../assets/Images/close-btn.svg";
import { CreateEventAction } from "../../store/actions/eventAction";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import DatePicker from "react-datepicker";
import CalendarIcon from "../Icons/CalendarIcon";
// import TimeInput from "react-time-picker-input/dist/components/TimeInput";
// import "react-time-picker-input/dist/components/TimeInput.css";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
    overflow: "visible",
  },
};

const CreateEventSchema = Yup.object().shape({
  title: Yup.string().required("Event Subject Required"),
  start_time: Yup.string().required("Time required"),
  start_date: Yup.date().required("Start date required"),
});

function CreateEventsModal({
  isOpen,
  setModal,
  selectedDate,
  execute = () => {},
}) {
  const dispatch = useDispatch();
  const logedinUser = useSelector((state) => state.auth.user);
  const userDateFormat = logedinUser?.date_format;

  const DateInput = forwardRef(({ value, onClick }, ref) => (
    <div className="relative w-fit">
      <input
        type="text"
        className={`cursor-pointer caret-transparent example-custom-input whitespace-nowrap ${
          value ? "font-bold text-Neutral900" : "text-Neutral600"
        } text-Neutral800 h-[48px] w-full font-medium font-feature-settings pl-[17.5px] pr-[46.5px] placeholder:text-sm text-sm leading-5 5xl:max-w-[180px] w-[148px] focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition add-new-event-time-color`}
        value={!value ? "Select date" : moment(value).format(userDateFormat)}
        onClick={onClick}
        readOnly
        ref={ref}
      />
      <CalendarIcon className="absolute top-[50%] right-[12px] translate-y-[-50%] pointer-events-none" />
    </div>
  ));

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setModal(false)}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="Example Modal"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="bg-white px-6 xs:px-12 pt-8 pb-10 rounded-[8px]">
          <div>
            <div className="text-left">
              <h3
                className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-8"
                id="modal-title"
              >
                ADD EVENT
              </h3>
              <Formik
                initialValues={{
                  title: "",
                  start_time_hours: "09",
                  start_time_minutes: "00",
                  start_time: "09:00",
                  start_date: selectedDate ? selectedDate : "",
                  end_time_hours: "10",
                  end_time_minutes: "00",
                  end_time: "10:00",
                  end_date: selectedDate ? selectedDate : "",
                }}
                enableReinitialize
                validationSchema={CreateEventSchema}
                onSubmit={(values, { setSubmitting, setErrors }) => {
                  setSubmitting(true);
                  dispatch(
                    CreateEventAction(
                      values,
                      setSubmitting,
                      setErrors,
                      setModal,
                      execute
                    )
                  );
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="mb-6">
                      <label className="text-Neutral900 font-bold text-xs">
                        What
                      </label>
                      <div className="mt-2">
                        <input
                          className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                          type="text"
                          placeholder="Eg. Wireframing"
                          name="title"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.title}
                        />
                        {errors.title && touched.title && (
                          <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                            {errors.title}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mb-6">
                      <label className="text-Neutral900 font-bold text-xs">
                        Start
                      </label>
                      <div className="mt-2 mb-6 add-event-time-date">
                        <div className="flex">
                          <div
                            className="bg-white min-w-fit max-w-fit text-Neutral800 font-medium placeholder:text-Neutral500 font-feature-settings py-4 h-[48px] placeholder:text-sm text-sm leading-5 focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular time-inputs event-time-inputs focus:drop-shadow-Purpleboxshadow duration-300 transition add-new-event-time-color"
                            tabIndex="1"
                          >
                            <div
                              className={`${
                                values.start_time !== "" &&
                                values.start_time !== "00:00"
                                  ? "input-text"
                                  : ""
                              } flex`}
                            >
                              <input
                                className="number-input outline-none w-[22px] ml-[17px] text-Neutral800 font-medium placeholder:text-Neutral500 font-feature-settings placeholder:font-medium placeholder:text-sm text-sm leading-5 5xl:placeholder:text-lg"
                                type="number"
                                placeholder="00"
                                name="start_time_hours"
                                maxLength="2"
                                onChange={(e) => {
                                  if (Number(e.target.value) > 25) {
                                    setFieldValue("start_time_hours", 23);
                                    setFieldValue(
                                      "start_time",
                                      `${23}:${values?.start_time_minutes}`
                                    );
                                  } else if (Number(e.target.value) === 24) {
                                    setFieldValue("start_time_hours", "24");
                                    setFieldValue("start_time_minutes", "00");
                                    setFieldValue("start_time", `24:00`);
                                  } else {
                                    setFieldValue(
                                      "start_time_hours",
                                      ("0" + e.target.value).slice(-2)
                                    );
                                    setFieldValue(
                                      "start_time",
                                      `${("0" + e.target.value).slice(-2)}:${
                                        values?.start_time_minutes
                                      }`
                                    );
                                  }
                                }}
                                onBlur={handleBlur}
                                value={values.start_time_hours}
                              />
                              :
                              <input
                                className="number-input outline-none w-[22px] mr-[17px] ml-[5px] text-Neutral800 font-medium placeholder:text-Neutral500 font-feature-settings placeholder:font-medium placeholder:text-sm text-sm leading-5 5xl:placeholder:text-lg"
                                type="number"
                                min="0"
                                max="59"
                                placeholder="00"
                                maxLength="2"
                                name="start_time_minutes"
                                onChange={(e) => {
                                  if (Number(e.target.value) > 59) {
                                    setFieldValue("start_time_minutes", 59);
                                    setFieldValue(
                                      "start_time",
                                      `${values?.start_time_hours}:${59}`
                                    );
                                  } else {
                                    setFieldValue(
                                      "start_time_minutes",
                                      ("0" + e.target.value).slice(-2)
                                    );
                                    setFieldValue(
                                      "start_time",
                                      `${values?.start_time_hours}:${(
                                        "0" + e.target.value
                                      ).slice(-2)}`
                                    );
                                  }
                                }}
                                onBlur={handleBlur}
                                value={values.start_time_minutes}
                              />
                              {/* <TimeInput
                                onChange={(e) => setFieldValue("start_time", e)}
                                value={values.start_time}
                                eachInputDropdown
                              /> */}
                            </div>
                          </div>
                          {/* <input
                            className="min-w-fit max-w-fit text-Neutral800 font-medium placeholder:text-Neutral500 font-feature-settings py-4 h-[48px] px-4 placeholder:text-sm text-sm leading-5 focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                            type="time"
                            placeholder="14:00"
                            name="start_time"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.start_time}
                          /> */}
                          <div className="ml-[8px] w-full custom-date-picker">
                            <DatePicker
                              selected={
                                values.start_date
                                  ? new Date(values.start_date)
                                  : null
                              }
                              customInput={
                                <DateInput
                                  value={
                                    values.start_date
                                      ? new Date(
                                          values.start_date
                                        ).toISOString()
                                      : null
                                  }
                                />
                              }
                              onChange={(date) => {
                                setFieldValue(
                                  "start_date",
                                  moment(date).format("YYYY-MM-DD")
                                );
                              }}
                            />
                          </div>
                        </div>

                        {errors.start_time && touched.start_time && (
                          <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none">
                            {errors.start_time}
                          </p>
                        )}
                        {errors.start_date && touched.start_date && (
                          <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none">
                            {errors.start_date}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mb-6">
                      <label className="text-Neutral900 font-bold text-xs">
                        End
                      </label>
                      <div className="mt-2 mb-6 add-event-time-date">
                        <div className="flex">
                          <div
                            className="bg-white min-w-fit max-w-fit text-Neutral800 font-medium placeholder:text-Neutral500 font-feature-settings py-4 h-[48px] placeholder:text-sm text-sm leading-5 focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular time-inputs event-time-inputs focus:drop-shadow-Purpleboxshadow duration-300 transition add-new-event-time-color "
                            tabIndex="1"
                          >
                            <div
                              className={`${
                                values.end_time !== "" &&
                                values.end_time !== "00:00"
                                  ? "input-text"
                                  : ""
                              } flex`}
                            >
                              <input
                                className="number-input outline-none w-[22px] ml-[17px] text-Neutral800 font-medium placeholder:text-Neutral500 font-feature-settings placeholder:font-medium placeholder:text-sm text-sm leading-5 5xl:placeholder:text-lg"
                                type="number"
                                placeholder="00"
                                name="end_time_hours"
                                maxLength="2"
                                onChange={(e) => {
                                  if (Number(e.target.value) > 25) {
                                    setFieldValue("end_time_hours", 23);
                                    setFieldValue(
                                      "end_time",
                                      `${23}:${values?.end_time_minutes}`
                                    );
                                  } else if (Number(e.target.value) === 24) {
                                    setFieldValue("end_time_hours", "24");
                                    setFieldValue("end_time_minutes", "00");
                                    setFieldValue("end_time", `24:00`);
                                  } else {
                                    setFieldValue(
                                      "end_time_hours",
                                      ("0" + e.target.value).slice(-2)
                                    );
                                    setFieldValue(
                                      "end_time",
                                      `${("0" + e.target.value).slice(-2)}:${
                                        values?.end_time_minutes
                                      }`
                                    );
                                  }
                                }}
                                onBlur={handleBlur}
                                value={values.end_time_hours}
                              />
                              :
                              <input
                                className="number-input outline-none w-[22px] mr-[17px] ml-[5px] text-Neutral800 font-medium placeholder:text-Neutral500 font-feature-settings placeholder:font-medium placeholder:text-sm text-sm leading-5 5xl:placeholder:text-lg"
                                type="number"
                                min="0"
                                max="59"
                                placeholder="00"
                                maxLength="2"
                                name="end_time_minutes"
                                onChange={(e) => {
                                  if (Number(e.target.value) > 59) {
                                    setFieldValue("end_time_minutes", 59);
                                    setFieldValue(
                                      "end_time",
                                      `${values?.end_time_hours}:${59}`
                                    );
                                  } else {
                                    setFieldValue(
                                      "end_time_minutes",
                                      ("0" + e.target.value).slice(-2)
                                    );
                                    setFieldValue(
                                      "end_time",
                                      `${values?.end_time_hours}:${(
                                        "0" + e.target.value
                                      ).slice(-2)}`
                                    );
                                  }
                                }}
                                onBlur={handleBlur}
                                value={values.end_time_minutes}
                              />
                              {/* <TimeInput
                                onChange={(e) => setFieldValue("end_time", e)}
                                value={values.end_time}
                                eachInputDropdown
                              /> */}
                            </div>
                          </div>
                          {/* <input
                            className="min-w-fit max-w-fit text-Neutral800 font-medium placeholder:text-Neutral500 font-feature-settings py-4 h-[48px] px-4 placeholder:text-sm text-sm leading-5 focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                            type="time"
                            placeholder="14:00"
                            name="end_time"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.end_time}
                          /> */}
                          <div className="ml-[8px] w-full custom-date-picker">
                            <DatePicker
                              selected={
                                values.end_date
                                  ? new Date(values.end_date)
                                  : null
                              }
                              customInput={
                                <DateInput
                                  value={
                                    values.end_date
                                      ? new Date(values.end_date).toISOString()
                                      : null
                                  }
                                />
                              }
                              onChange={(date) => {
                                setFieldValue(
                                  "end_date",
                                  moment(date).format("YYYY-MM-DD")
                                );
                              }}
                            />
                          </div>
                        </div>
                        {errors.end_date && touched.end_date && (
                          <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none">
                            {errors.end_date}
                          </p>
                        )}
                        {errors.end_time && touched.end_time && (
                          <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none">
                            {errors.end_time}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mt-8">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn button-hover form-primary-btn relative text-sm text-center block text-Neutral100 bg-AccentRegular font-bold w-full py-3 rounded overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out"
                      >
                        {isSubmitting ? (
                          <span className="relative z-10">
                            <svg
                              className="animate-spin m-auto h-5 w-5 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          </span>
                        ) : (
                          <span className="relative z-1">Save</span>
                        )}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => setModal(false)}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
}

export default CreateEventsModal;
