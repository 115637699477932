import React, { useState, useEffect } from "react";
import CommanTaskListing from "../../components/comman/CommanTaskListing";
import { GetTasksActions } from "../../store/actions/tasksAction";
import { GetClientDetailAction } from "../../store/actions/clientAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import TaskListLoader from "../../components/ContentLoaders/TaskListLoader";

const listLable = ["To Do", "Doing", "Done"];

const ClientsTasks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clientId } = useParams();
  const taskList = useSelector((state) => state.tasks.tasks);
  const clientDetail = useSelector((state) => state.client.clientDetail);
  const [navBarText, setNavbarText] = useState("Back");

  let TodoLists = taskList?.data?.to_do || [];
  let DoingLists = taskList?.data?.doing || [];
  let DoneLists = taskList?.data?.done || [];
  useEffect(() => {
    if (clientId !== undefined && clientId !== taskList.client) {
      dispatch(GetTasksActions(clientId));
    }
  }, [clientId, dispatch]);

  useEffect(() => {
    TodoLists = taskList?.data?.to_do || [];
    DoingLists = taskList?.data?.doing || [];
    DoneLists = taskList?.data?.done || [];
  }, [taskList]);

  useEffect(() => {
    if (clientId !== clientDetail.data._id) {
      dispatch(GetClientDetailAction(clientId, navigate));
    }
  }, [clientId, dispatch, navigate]);

  useEffect(() => {
    if (clientId !== clientDetail.data._id) {
      setNavbarText("Back");
    } else {
      setNavbarText(`Back to ${clientDetail?.data?.client_name} Dashboard`);
    }
  }, [clientDetail]);

  return (
    <>
      {taskList.isLoading && taskList.client !== clientId ? (
        <TaskListLoader />
      ) : (
        <CommanTaskListing
          listLable={listLable}
          TodoLists={TodoLists}
          DoingLists={DoingLists}
          DoneLists={DoneLists}
          pageTitle="Tasks"
          backLine={navBarText}
          clientId={clientId}
          switchon="GENERAL"
          switchhoff="MONTHLY"
        />
      )}
    </>
  );
};

export default ClientsTasks;
