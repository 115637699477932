import React from "react";

function ProjectCardLoader() {
  return Array.from({ length: 3 }).map((ele, index) => {
    return (
      <div
        className="flex flex-col p-6 rounded-xl bg-Neutral000 border border-Neutral200 shadow-[0_4px_15px_rgba(0,0,0,0.08)] min-w-[320px] skeleton-animation relative h-[555px]"
        key={index}
      >
        {/* <div className="flex justify-between mb-3 pl-2 py-1">
          <h5 className="mr-3 bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative w-[70px] h-[24px]"></h5>
          <span className="bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative w-[24px] h-[24px] rounded-full"></span>
        </div>
        <div className="flex flex-wrap gap-1 mb-3 px-[6px]">
          {Array.from({ length: 3 }).map((ele, index) => (
            <span className="bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative w-[50px] h-[18px] rounded-md"></span>
          ))}
        </div>
        <div className="mb-3 px-2">
          {Array.from({ length: 10 }).map((ele, index) => (
            <span
              className={`bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative w-[28px] h-[28px] rounded-full 
                ${index === 9 ? "" : "cropped-avatar"}
            `}
            ></span>
          ))}
        </div>
        <div className="mb-4 pl-1">
          <div className="flex justify-between w-full mb-[22px] border-t-[0.5px] border-Neutral300 pt-3">
            <div className="flex justify-center items-center">
              <div className="bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative w-[28px] h-[15px] rounded-full mr-[6px]"></div>
            </div>
          </div>
        </div> */}
      </div>
    );
  });
}

export default ProjectCardLoader;
