const initialState = {
  leads: {
    isLoading: true,
    is_called: false,
    data: [],
  },
  leadDetails: {
    isLoading: true,
    is_called: false,
    data: [],
  },
};

const leadReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_LEADS":
      let tempLeadList = state.leads.data;
      if (payload.is_archived) {
        const ids = new Set(tempLeadList.map((ele) => ele._id));
        tempLeadList = [...tempLeadList, ...payload.data.filter((ele) => !ids.has(ele._id))];
      } else {
        tempLeadList = payload.data;
      }
      return {
        ...state,
        leads: {
          data: tempLeadList,
        },
      };
    case "SET_LEAD_DETAIL":
      return {
        ...state,
        leadDetails: {
          ...state.leadDetails,
          ...payload,
        },
      };
    case "CREATE_LEAD_SUCCESS":
      let tempLeads = state.leads.data;
      tempLeads = [...tempLeads, payload.data];
      return {
        ...state,
        leads: {
          ...state.leads,
          data: tempLeads,
        },
      };
    case "UPDATE_LEAD_SUCCESS":
      let tempLeads1 = state.leads.data;
      tempLeads1 = tempLeads1.map((lead) => {
        if (lead._id === payload.data._id) {
          return payload.data;
        }
        return lead;
      });
      return {
        ...state,
        leads: {
          ...state.leads,
          data: tempLeads1,
        },
      };
    case "DELETE_LEAD_SUCCESS":
      let tempLeads2 = state.leads.data;
      tempLeads2 = tempLeads2.filter((lead) => lead._id !== payload.id);
      return {
        ...state,
        leads: {
          ...state.leads,
          data: tempLeads2,
        },
      };
    case "CREATE_LEAD_COMMENT_SUCCESS":
      let tempAddComment = state.leads.data;
      tempAddComment = tempAddComment.map((lead) => {
        if (lead._id === payload.data.lead) {
          return {
            ...lead,
            comments: [...lead.comments, payload.data],
          };
        }
        return lead;
      });
      return {
        ...state,
        leads: {
          ...state.leads,
          data: tempAddComment,
        },
      };
    case "UPDATE_LEAD_COMMENT_SUCCESS":
      let tempEditComment = state.leads.data;

      tempEditComment = tempEditComment.map((lead) => {
        if (lead._id === payload.data.lead) {
          return {
            ...lead,
            comments: lead.comments.map((comment) => {
              if (comment._id === payload.data._id) {
                return {
                  ...comment,
                  message: payload.data.message,
                  attachments: payload.data.attachments,
                  isedited: true,
                };
              }
              return comment;
            }),
          };
        }
        return lead;
      });
      return {
        ...state,
        leads: {
          ...state.leads,
          data: tempEditComment,
        },
      };
    case "DELETE_LEAD_COMMENT_SUCCESS":
      let tempDelComment = state.leads.data;
      tempDelComment = tempDelComment.map((lead) => {
        if (lead._id === payload.data.lead) {
          return {
            ...lead,
            comments: lead.comments.filter((e) => {
              return e._id !== payload.data._id;
            }),
          };
        }
        return lead;
      });
      return {
        ...state,
        leads: {
          ...state.leads,
          data: tempDelComment,
        },
      };
    case "UPDATE_LEAD_COLUMN_WITH_POSITION_SUCCESS":
      return {
        ...state,
        leads: {
          ...state.leads,
          data: payload.data,
        },
      };
    case "UPDATE_LEAD_COLUMN_ABORT":
      let tempLeads4 = state.leads.data;
      if (payload.data?.length) {
        payload.data.forEach((lead) => {
          tempLeads4 = tempLeads4.map((item) => {
            if (item._id === lead._id) {
              return lead;
            }
            return item;
          });
        });
      } else {
        tempLeads4 = tempLeads4.map((lead) => {
          if (lead._id === payload.data._id) {
            return payload.data;
          }
          return lead;
        });
      }
      return {
        ...state,
        leads: {
          ...state.leads,
          data: tempLeads4,
        },
      };
    case "UPDATE_COLUMN_NAME_SUCCESS":
      let tempLeads3 = state.leads.data;
      tempLeads3 = tempLeads3.map((lead) => {
        if (lead._id === payload.data._id) {
          return payload.data;
        }
        return lead;
      });
      return {
        ...state,
        leads: {
          ...state.leads,
          data: tempLeads3,
        },
      };
    case "ADD_NOTE_TO_LEAD_SUCCESS":
      let tempLeads5 = state.leads.data;
      tempLeads5 = tempLeads5.map((lead) => {
        if (lead._id === payload.data._id) {
          return payload.data;
        }
        return lead;
      });
      return {
        ...state,
        leads: {
          ...state.leads,
          data: tempLeads5,
        },
      };
    case "UPDATE_NOTE_TO_LEAD_SUCCESS":
      let tempLeads6 = state.leads.data;
      tempLeads6 = tempLeads6.map((lead) => {
        if (lead._id === payload.data._id) {
          return payload.data;
        }
        return lead;
      });
      return {
        ...state,
        leads: {
          ...state.leads,
          data: tempLeads6,
        },
      };
    case "DELETE_NOTE_TO_LEAD_SUCCESS":
      let tempLeads7 = state.leads.data;
      tempLeads7 = tempLeads7.map((lead) => {
        if (lead._id === payload.data._id) {
          return payload.data;
        }
        return lead;
      });
      return {
        ...state,
        leads: {
          ...state.leads,
          data: tempLeads7,
        },
      };
    case "CONVERT_LEAD_TO_CLIENT_SUCCESS":
      let tempLeads8 = state.leads.data;
      tempLeads8 = tempLeads8.map((lead) => {
        if (lead._id === payload.data._id) {
          return payload.data;
        }
        return lead;
      });
      return {
        ...state,
        leads: {
          ...state.leads,
          data: tempLeads8,
        },
      };
    case "UPDATE_PROPOSAL_TITLE_SUCCESS":
      let tempLeads9 = state.leads.data;
      tempLeads9 = tempLeads9.map((lead) => {
        if (lead._id === payload.data._id) {
          return payload.data;
        }
        return lead;
      });
      return {
        ...state,
        leads: {
          ...state.leads,
          data: tempLeads9,
        },
      };
    case "DELETE_ARCHIVED_LEAD_SUCCESS":
      let tempLeads10 = state.leads.data;
      tempLeads10 = tempLeads10.filter((lead) => lead._id !== payload.id);
      return {
        ...state,
        leads: {
          ...state.leads,
          data: tempLeads10,
        },
      };
    default:
      return state;
  }
};

export default leadReducer;
