import { Menu, Transition } from "@headlessui/react";
import clx from "classnames";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import CheckMarkIcon from "../../components/Icons/CheckMarkIcon";
import { convertLeadToClientAction } from "../../store/actions/leadAction";
import { CloseModalAction, OpenModalAction } from "../../store/actions/modalsAction";

function LeadsControls({ converted = false, setConverted = () => {}, lead }) {
	let timerId = null;
	const titleDropDownref = useRef(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);

	const onConvertClick = (e) => {
		setTimeout(() => {
			titleDropDownref?.current?.click();
			e.stopPropagation();
		}, 0);
		setIsLoading(true);
		e.target.disabled = true;
		dispatch(convertLeadToClientAction({ id: lead._id }, setConverted, setIsLoading));
		timerId = setTimeout(() => {
			e.target.disabled = false;
		}, 500);
	};

	const onSendProposalClick = () => dispatch(OpenModalAction("create_proposal"));

	const onSendQuestionnaireClick = () => dispatch(OpenModalAction("create_questionnaire"));

	const onLeadsArchiveClick = () => dispatch(OpenModalAction("leads_archive"));

	const handleViewProposal = () => {
		if (lead?.proposal && lead?.proposal?.is_external_proposal) {
			return dispatch(OpenModalAction("existing_proposal"));
		}
		if (lead?.proposal && !lead?.client) {
			navigate(`/admin/leads/manage/${lead._id}/proposal/${lead.proposal._id}`);
		}
		if (lead?.proposal && lead?.client) {
			navigate(`/admin/clients/manage/${lead.client._id}/proposal/${lead.proposal._id}`);
		}
		dispatch(CloseModalAction("create_proposal"));
	};

	const handleViewQuestionnaire = () => {
		if (lead?.questionnaire && !lead?.client) {
			navigate(`/admin/leads/manage/${lead._id}/questionnaire/${lead.questionnaire._id}`);
		}
		if (lead?.questionnaire && lead?.client) {
			navigate(
				`/admin/clients/manage/${lead.client._id}/questionnaire/${lead.questionnaire._id}`,
			);
		}
		dispatch(CloseModalAction("create_questionnaire"));
	};

	useEffect(() => {
		return () => clearTimeout(timerId);
	}, [timerId]);

	return (
		<div className={clx("w-[100%]", converted ? "mb-[12px]" : "mb-[15px]")}>
			<div className="w-[100%] flex justify-stretch items-center gap-3">
				{!converted && (
					<Menu
						className="flex relative justify-center rounded text-Neutral000 bg-AccentRegular h-[33px] w-[50%] font-bold text-[10px] leading-[110%] uppercase tracking-[0.04em] transition-all duration-250 hover:bg-AccentMediumDark  "
						as="div"
						onClick={(e) => {
							e.stopPropagation();
						}}
					>
						<Menu.Button ref={titleDropDownref} className="w-[100%] uppercase">
							{isLoading ? (
								<svg
									className="w-5 h-5 m-auto animate-spin text-Neutral000"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
								>
									<circle
										className="opacity-25"
										cx="12"
										cy="12"
										r="10"
										stroke="currentColor"
										strokeWidth="4"
									></circle>
									<path
										className="opacity-75"
										fill="currentColor"
										d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
									></path>
								</svg>
							) : (
								"Convert To Client"
							)}
						</Menu.Button>
						<Transition
							as={Fragment}
							enter="transition ease-out duration-100"
							enterFrom="transform opacity-0 scale-95"
							enterTo="transform opacity-100 scale-100"
							leave="transition ease-in duration-75"
							leaveFrom="transform opacity-100 scale-100"
							leaveTo="transform opacity-0 scale-95"
						>
							<Menu.Items className="left-0 absolute pt-4 pb-6 w-[238px] px-6 rounded-lg Timetrackingdropdown bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none z-10">
								<p className="pb-2 mb-3 text-xs font-semibold border-b text-Neutral700 border-Neutral200">
									Are you sure?
								</p>
								<p className="text-Red600 normal-case bg-Red100 px-3 py-2 text-[12px] 5xl:text-sm leading-[1.4] font-normal rounded mb-1">
									<span className="font-semibold">Warning:</span> Converting a lead is an
									irreversible action.
								</p>
								<button
									className="border-0 overflow-hidden w-[100%] mt-5"
									onClick={onConvertClick}
									id="convert-lead-to-client-btn"
								>
									<h3 className="w-full py-4 text-xs font-semibold text-center text-white duration-300 rounded cursor-pointer bg-AccentRegular hover:bg-AccentMediumDark">
										Yes, convert to client
									</h3>
								</button>
								<Menu.Button className="overflow-hidden border-0 !border-transparent divide-0 w-[100%]">
									<h3 className="w-full py-4 mt-3 text-xs font-semibold text-center duration-300 rounded cursor-pointer text-Neutral800 bg-Neutral200 hover:bg-Neutral500 hover:text-Neutral900">
										No, keep as lead
									</h3>
								</Menu.Button>
							</Menu.Items>
						</Transition>
					</Menu>
				)}

				{converted && (
					<button
						onClick={() => {
							dispatch(CloseModalAction("lead_details"));
							navigate(`/admin/clients/manage/${lead.client._id}`);
						}}
						className={`flex items-center justify-center rounded h-[33px] w-[50%] font-bold text-[10px] leading-[110%] uppercase tracking-[0.04em] transition-all duration-250 bg-Green100 text-Green500`}
					>
						<CheckMarkIcon />
						Converted To Client
					</button>
				)}
				{!lead?.proposal ? (
					<>
						<button
							onClick={onSendProposalClick}
							disabled={converted}
							className="flex items-center justify-center rounded text-AccentRegular bg-Neutral200 h-[33px] w-[50%] font-bold text-[10px] leading-[110%] uppercase tracking-[0.04em] transition-all duration-250 hover:text-AccentMediumDark hover:bg-Neutral300"
						>
							Send Proposal
						</button>
						<button
							onClick={() => dispatch(OpenModalAction("existing_proposal"))}
							disabled={converted}
							className="flex items-center justify-center rounded text-AccentRegular bg-Neutral200 h-[33px] w-[50%] font-bold text-[10px] px-2 leading-[110%] uppercase tracking-[0.04em] transition-all duration-250 hover:text-AccentMediumDark hover:bg-Neutral300  "
						>
							Upload Existing Proposal
						</button>
					</>
				) : (
					<button
						onClick={handleViewProposal}
						className="flex items-center justify-center rounded text-AccentRegular bg-Neutral200 h-[33px] w-[50%] font-bold text-[10px] leading-[110%] uppercase tracking-[0.04em] transition-all duration-250 hover:text-AccentMediumDark hover:bg-Neutral300  "
					>
						View Proposal
					</button>
				)}
				{!lead?.questionnaire ? (
					<button
						onClick={onSendQuestionnaireClick}
						// disabled={converted}
						className="flex items-center justify-center rounded text-AccentRegular bg-Neutral200 h-[33px] w-[50%] font-bold text-[10px] leading-[110%] uppercase tracking-[0.04em] transition-all duration-250 hover:text-AccentMediumDark hover:bg-Neutral300  "
					>
						Send Questionnaire
					</button>
				) : (
					<button
						onClick={handleViewQuestionnaire}
						className="flex items-center justify-center rounded text-AccentRegular bg-Neutral200 h-[33px] w-[50%] font-bold text-[10px] leading-[110%] uppercase tracking-[0.04em] transition-all duration-250 hover:text-AccentMediumDark hover:bg-Neutral300"
					>
						View Questionnaire
					</button>
				)}
			</div>
			{converted && (
				<div className="w-[100%] pl-2 text-[10px] font-medium leading-[18px] mt-[6px]">
					<span className="text-Neutral600">Moved to: </span>
					<button onClick={onLeadsArchiveClick} className="text-AccentRegular">
						Leads Archive
					</button>
				</div>
			)}
		</div>
	);
}

export default LeadsControls;
