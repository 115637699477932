import copy from "copy-to-clipboard";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import LinkCopied from "../../assets/Images/CheckmarkAccent.svg";
import NoMeetingScheduler from "../../assets/Images/Images/no-meeting-scheduler.svg";
import ChevronDownIcon from "../../components/Icons/ChevronDownIcon";
import EditIcon from "../../components/Icons/EditIcon";
import LinkIcon from "../../components/Icons/Link_Icon";
import MeetingCard from "../../components/Meeting/MeetingCard";
import SchedulerModal from "../../components/Modals/ScheduleMeetingModal/SchedulerModal";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import MeetingFilter from "../../components/filter/MeetingFilter";
import { GetMeetingsListAction } from "../../store/actions/meetingAction";
import useBaseDomainURL from "../../hooks/useBaseDomainURL";

function Meeting() {
  const dispatch = useDispatch();
  const baseDomainUrl = useBaseDomainURL();

  const [meetingModalIsOpen, setMeetingModalIsOpen] = useState(false);
  const [formData, setFormData] = useState(null);
  const [copyStatus, setCopyStatus] = useState("Copy Meeting Scheduler Link");
  const [filterBtn, setFilterBtn] = useState(false);
  const [addFilter, setAddFilter] = useState({
    date: [],
    time: [],
    length: [],
  });
  const [filteredMeetings, setFilteredMeetings] = useState([]);

  useEffect(() => {
    dispatch(GetMeetingsListAction());
  }, [dispatch]);

  const meetingsList = useSelector((state) => state.meeting.meetings);

  const normalizeTimeSlot = (time) => time.replace(" Minutes", "min");

  const handleEditMeeting = (e, meeting) => {
    setFormData({
      id: meeting._id,
      stepOneData: { title: meeting.title },
      stepTwoData: {
        time_slot: meeting.time_slot,
        availability: meeting.availability,
        unavailability: meeting.unavailability,
        timezone: meeting.timezone,
      },
      stepThreeData: {
        platform: meeting.platform,
        link: meeting?.link,
        phone_number: meeting?.phone_number,
        custom_url: meeting?.custom_url,
      },
    });
    setMeetingModalIsOpen(true);
  };

  const isDateInFilter = (date, filter) => {
    const now = new Date();
    const startOfToday = new Date(
      Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
    );
    let start, end;

    switch (filter) {
      case "this_week":
        const dayOfWeek = startOfToday.getUTCDay() || 7;
        start = new Date(startOfToday);
        start.setUTCDate(start.getUTCDate() - dayOfWeek + 1); // Adjust to the first day of the week (Monday)
        end = new Date(start);
        end.setUTCDate(start.getUTCDate() + 6); // End of the week (Sunday)
        break;
      case "this_month":
        start = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), 1));
        end = new Date(
          Date.UTC(now.getUTCFullYear(), now.getUTCMonth() + 1, 0)
        );
        end = new Date(
          Date.UTC(now.getUTCFullYear(), now.getUTCMonth() + 1, 0)
        );
        break;
      case "next_month":
        start = new Date(
          Date.UTC(now.getUTCFullYear(), now.getUTCMonth() + 1, 1)
        );
        end = new Date(
          Date.UTC(now.getUTCFullYear(), now.getUTCMonth() + 2, 0)
        );
        break;
      default:
        return false;
    }

    return date >= start && date <= end;
  };

  const isTimeInPeriod = (time, period) => {
    const [hours, minutes] = time.split(":").map(Number);
    const timeInMinutes = hours * 60 + minutes;

    const morningStart = 6 * 60;
    const morningEnd = 11 * 60 + 59;

    const afternoonStart = 12 * 60;
    const afternoonEnd = 17 * 60 + 59;

    const eveningStart = 18 * 60;
    const eveningEnd = 23 * 60 + 59;

    switch (period) {
      case "morning":
        return timeInMinutes >= morningStart && timeInMinutes <= morningEnd;
      case "afternoon":
        return timeInMinutes >= afternoonStart && timeInMinutes <= afternoonEnd;
      case "evening":
        return timeInMinutes >= eveningStart && timeInMinutes <= eveningEnd;
      default:
        return false;
    }
  };

  useEffect(() => {
    const filterLinkedMeetings = () => {
      // Filtering Logic for linked meetings
      const filtered = meetingsList.data?.map((meeting) => {
        const linkedMeetingsFilter = meeting.linked_meeting?.filter(
          (linkedMeeting) => {
            // Convert the meeting date to the UTC Date
            const meetingDate = new Date(linkedMeeting.date);
            // Date Filtering
            const dateMatches =
              addFilter.date.length === 0 ||
              addFilter.date.some((dateFilter) =>
                isDateInFilter(meetingDate, dateFilter)
              );
            // Time Filtering
            const timeMatches =
              addFilter.time.length === 0 ||
              addFilter.time.some((timeFilter) =>
                isTimeInPeriod(linkedMeeting.time, timeFilter)
              );
            // Length Filtering
            const lengthMatches =
              addFilter.length.length === 0 ||
              addFilter.length.includes(
                normalizeTimeSlot(linkedMeeting.time_slot)
              );
            return dateMatches && timeMatches && lengthMatches;
          }
        );

        return { ...meeting, linked_meeting: linkedMeetingsFilter };
      });

      setFilteredMeetings(filtered);
    };

    setFilteredMeetings(filterLinkedMeetings);
  }, [addFilter, meetingsList, meetingsList.data]);

  const handleCreateMeetingSchedule = (e) => {
    setFormData(null);
    setMeetingModalIsOpen(true);
  };

  return (
    <>
      <AdminNavbar
        title="Meetings Dashboard"
        subtitle="View and manage your meetings from here."
      />

      <MeetingFilter
        filterBtn={filterBtn}
        setFilterBtn={setFilterBtn}
        addFilter={addFilter}
        setAddFilter={setAddFilter}
      />

      <div className="relative">
        {!!filteredMeetings?.length &&
          filteredMeetings?.map((meeting) => (
            <section
              key={meeting?._id}
              className="5xl:px-[96px] px-6 lg:px-12 w-full pt-0"
            >
              <div className="flex items-center justify-between py-4 bg-white">
                <div className="flex items-center space-x-1 text-xs py-[7px] rounded gap-1">
                  <ChevronDownIcon />
                  <span className="text-Neutral700">Sort By: </span>
                  <span className="text-Neutral800">Date</span>
                </div>
                <div className="flex items-center space-x-1 text-xs">
                  <span className="text-Neutral700">Schedule:</span>
                  <span className="font-bold text-Neutral800">
                    {meeting.title}
                  </span>
                  <button onClick={(e) => handleEditMeeting(e, meeting)}>
                    <EditIcon />
                  </button>
                  <button
                    className="text-AccentRegular"
                    onClick={(e) => {
                      // Construct the full URL
                      const fullUrl = `${baseDomainUrl}/schedule-meeting${meeting?.vanity_path}`;

                      // Use the copy-to-clipboard library to copy the text
                      const success = copy(fullUrl);

                      if (success) {
                        setCopyStatus("Copied");
                        setTimeout(() => {
                          setCopyStatus("Copy Meeting Scheduler Link");
                        }, 1500);
                      } else {
                        console.error(
                          "Failed to copy the meeting scheduler URL"
                        );
                      }
                    }}
                    title={copyStatus}
                  >
                    {copyStatus === "Copied" ? (
                      <img src={LinkCopied} alt="" />
                    ) : (
                      // <img src={LinkIcon} alt="" />
                      <LinkIcon className="size-5" />
                    )}
                  </button>
                </div>
                <div className="flex items-center gap-4">
                  <button
                    className="5xl:text-sm text-[12px] uppercase text-AccentRegular bg-Neutral200 5xl:h-8 h-7 5xl:w-[120px] w-[88px] flex justify-center items-center rounded hover:bg-Bg-MediumDark focus-visible:outline-none focus:outline focus:outline-4 focus:outline-Neutral200 font-medium transition duration-300 space-x-1"
                    onClick={() => setFilterBtn(true)}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.9">
                        <path
                          d="M5 15.625V11.25"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M5 8.75V4.375"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10 15.625V10"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10 7.5V4.375"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15 15.625V12.5"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15 10V4.375"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M3.125 11.25H6.875"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.125 7.5H11.875"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M13.125 12.5H16.875"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                    <span className="text-xs tracking-[8%] font-medium">
                      Filter
                    </span>
                  </button>
                </div>
              </div>

              {!!meeting?.linked_meeting?.length ? (
                <div className="grid grid-cols-1 py-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-7">
                  {meeting.linked_meeting.map((linkedMeeting) => (
                    <MeetingCard
                      key={linkedMeeting._id}
                      parentMeetingId={meeting?._id}
                      meetingDetails={linkedMeeting}
                    />
                  ))}
                </div>
              ) : (
                <div className="flex items-center justify-center">
                  <p className="max-w-md px-5 py-3 text-xs text-center bg-white border rounded text-Neutral700">
                    You don't have any scheduled meetings yet. They will show
                    here after your guest creates a meeting 🙂
                    <br />
                    <span
                      className="inline-block mt-2.5 font-medium cursor-pointer text-AccentRegular hover:text-AccentMediumDark"
                      onClick={(e) => {
                        // Construct the full URL
                        const fullUrl = `${baseDomainUrl}/schedule-meeting${meeting?.vanity_path}`;

                        // Use the copy-to-clipboard library to copy the text
                        const success = copy(fullUrl);

                        if (success) {
                          setCopyStatus("Copied");
                          setTimeout(() => {
                            setCopyStatus("Copy Meeting Scheduler Link");
                          }, 1500);
                        } else {
                          console.error(
                            "Failed to copy the meeting scheduler URL"
                          );
                        }
                      }}
                    >
                      {copyStatus === "Copied" ? (
                        <img
                          src={LinkCopied}
                          alt=""
                          className="inline-block mr-0.5 size-5"
                        />
                      ) : (
                        <LinkIcon className="inline-block mr-1 size-5" />
                      )}
                      Copy this meeting URL to get started.
                    </span>
                  </p>
                </div>
              )}
            </section>
          ))}

        {(!filteredMeetings?.length || filteredMeetings?.length === 1) && (
          <>
            <div className={`flex items-center justify-center gap-5 mt-10`}>
              <img
                src={NoMeetingScheduler}
                alt=""
                className={`object-cover ${
                  filteredMeetings?.length ? "size-52" : "size-60"
                }`}
              />
              <div className="flex flex-col max-w-xs gap-3">
                <p className="text-2xl font-black text-Neutral900">
                  Build Your Schedule
                </p>
                <p className="text-sm text-Neutral800">
                  You can create meeting schedules here and send them to
                  clients! Easy peasy.
                </p>
                <button
                  className="self-start px-4 py-3 text-sm font-semibold text-white rounded-md bg-AccentRegular hover:bg-AccentMediumDark"
                  onClick={(e) => handleCreateMeetingSchedule(e)}
                >
                  {filteredMeetings?.length === 1
                    ? "Create Another Schedule"
                    : "Create a New Meeting Schedule"}
                </button>
              </div>
            </div>
          </>
        )}
      </div>

      <SchedulerModal
        meetingModalIsOpen={meetingModalIsOpen}
        setMeetingModalIsOpen={setMeetingModalIsOpen}
        initialData={formData}
      />
    </>
  );
}

export default Meeting;
