import React, { useEffect, useRef, useState } from "react";

import AddComment from "./commponent/AddComment";
import EditComment from "./commponent/EditComment";

const Comment = ({ element, lead_id }) => {
  const [comments, setComments] = useState(element?.comments);

  const commentsEndRef = useRef(null);

  const scrollToBottom = () => {
    commentsEndRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [comments]);

  useEffect(() => {
    setComments(element?.comments);
  }, [element?.comments]);

  return (
    <div className="lg:min-w-[314px] lg:max-w-[314px] border-l bg-Neutral100 border-Neutral300 relative md:rounded-r-lg flex flex-col mx-6 lg:mx-0">
      <div
        className={`overflow-auto scrollbar-w-4 max-h-[560px] md:rounded-r-lg w-full flex-1 ${
          comments?.length !== 0 ? "pt-4" : ""
        } ${comments?.length <= 2 ? "" : "pb-[50px]"}`}
      >
        {comments?.map((item, index) => {
          return <EditComment key={index} item={item} lead_id={lead_id} />;
        })}
        <div ref={commentsEndRef} className="mb-7" />
      </div>
      {comments?.length === 0 && (
        <div className="text-Neutral500 text-[14px] flex justify-center w-full italic">
          No comments...{" "}
        </div>
      )}

      <AddComment lead_id={lead_id} />
    </div>
  );
};

export default Comment;
