import React, { Fragment, useEffect } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useState } from "react";
import MenuIcon from "../Icons/MenuIcon";
import CustomVideoPlayer from "./components/CustomVideoPlayer";
import DeleteIcon from "../Icons/DeleteIcon";
import RedirectIcon from "../Icons/RedirectIcon";
import { Menu, Transition } from "@headlessui/react";
import moment from "moment";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
    overflow: "hidden",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: 0,
    background: "none",
    borderRadius: "10px",
    overflow: "visible",
  },
};

const WatchMediaModal = ({
  file,
  onClose,
  onDelete,
  user_type,
  access_level,
}) => {
  const navigate = useNavigate();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const cardId = params.get("cardId");
  const [modalClient, setIsmodalClient] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const authSelector = useSelector((state) => state.auth);
  const authCollabSelector = useSelector((state) => state?.collab_auth);

  const handleDownloadClick = () => {
    const link = document.createElement("a");
    link.href = file?.url;
    link.download = file?.file?.originalname;
    link.click();
  };

  const handleClose = () => {
    setIsmodalClient(false);
    navigate({ search: "" });
  };

  useEffect(() => {
    if (cardId) {
      setIsmodalClient(true);
    }
  }, [cardId]);

  const handleOpenInBrouser = (e) => {
    e.preventDefault();
    const link = document.createElement("a");
    link.href = file?.url;
    link.target = "_blank";
    link.click();
  };

  return (
    <Modal
      isOpen={modalClient}
      onRequestClose={() => handleClose()}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="View Form Preview Modal"
    >
      <div
        className="relative cursor-pointer rounded-lg "
        onMouseEnter={() => {
          setIsHover(true);
        }}
        onMouseLeave={() => {
          setIsHover(false);
        }}
      >
        {isHover && !file?.file?.mimetype?.includes("video") && (
          <>
            <div
              className="absolute top-0 w-full h-[17%] z-90 rounded-t-lg "
              style={{
                background:
                  "linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(41, 41, 51, 0) 100%)",
              }}
            >
              <div className="absolute top-4 left-5 flex gap-2">
                <div className="flex  items-center  justify-center w-10 h-10  rounded	 overflow-hidden">
                  {file?.created_by?.profile ||
                  file?.created_collab_user_by?.profile ? (
                    <img
                      src={
                        file?.created_by?.profile_path ||
                        file?.created_collab_user_by?.profile_path
                      }
                      alt="avatar"
                      className="object-cover w-10 h-10"
                    />
                  ) : (
                    <div className="w-10 h-10 bg-Neutral300 rounded-full flex items-center justify-center">
                      <p className="text-Neutral700 text-sm font-semibold">
                        {file?.created_by?.user_name?.charAt(0) ||
                          file?.created_collab_user_by?.user_name?.charAt(0)}
                      </p>
                    </div>
                  )}
                </div>
                <div className="flex flex-col justify-center  gap-1.5">
                  <p className="text-Neutral000 font-bold text-base">
                    {file?.created_by?.user_name ||
                      file?.created_collab_user_by?.user_name}
                  </p>
                  <p className="text-Neutral200 font-medium text-[10px] leading-[8px]">
                    {/* {file.createdAt} - {file.name}.{file.format} */}
                    {moment(file?.created_at).fromNow()} -{" "}
                    {file?.file?.originalname}
                  </p>
                </div>
              </div>

              <button onClick={onClose} className="absolute top-4 right-5">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.5 7.5L7.5 16.5"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.5 7.5L16.5 16.5"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>

            <div
              className="absolute bottom-0 w-full h-[17%] rounded-b-lg	"
              style={{
                background:
                  "linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(41, 41, 51, 0) 100%)",
              }}
            >
              <div className="absolute bottom-5 right-5 flex justify-between w-[81px]">
                <button onClick={handleDownloadClick}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.4999 15.168V18.7235C20.4999 19.195 20.3126 19.6472 19.9792 19.9806C19.6458 20.314 19.1936 20.5013 18.7221 20.5013H6.27766C5.80616 20.5013 5.35397 20.314 5.02058 19.9806C4.68718 19.6472 4.49988 19.195 4.49988 18.7235V15.168"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.05505 10.7227L12.4995 15.1671L16.9439 10.7227"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.4999 15.1667V4.5"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>

                <Menu as="div" className="  flex items-center text-left">
                  <div className="flex items-center">
                    <Menu.Button className="inline-flex justify-center w-full">
                      <MenuIcon color="#FFFFFF" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="edit-menu-servies edit-delete-menu absolute z-[999] top-0 right-0 rounded-lg bg-Neutral000 border-[0.5px] border-Neutral200 divide-y divide-Neutral300 focus:outline-none z-90 overflow-hidden">
                      <form method="POST" action="#">
                        <Menu.Item>
                          {({ active }) => (
                            <div>
                              <div
                                className="flex gap-2  text-left text-Neutral800 px-4 py-2 text-xs whitespace-nowrap justify-start items-center cursor-pointer font-semibold hover:bg-Neutral200 transition duration-300 "
                                onClick={handleOpenInBrouser}
                              >
                                <RedirectIcon />
                                <span>Open in Browser</span>
                              </div>
                              {((authSelector?.user?._id &&
                                authSelector?.user?._id ===
                                  file?.created_by?._id) ||
                                (authCollabSelector?.user?._id ===
                                  file?.created_collab_user_by?._id &&
                                  access_level === "full_access")) && (
                                <div
                                  className="flex gap-2 text-Neutral800 text-left px-4 py-2 text-xs whitespace-nowrap justify-start items-center border-t-[0.5px] border-Neutral200 cursor-pointer font-semibold hover:bg-Neutral200  transition duration-300"
                                  onClick={() => onDelete(file)}
                                >
                                  <DeleteIcon />
                                  <span>Delete</span>
                                </div>
                              )}
                            </div>
                          )}
                        </Menu.Item>
                      </form>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </>
        )}

        {file?.file?.mimetype?.includes("video") ? (
          <CustomVideoPlayer
            src={file?.url}
            isHover={isHover}
            file={file}
            onClose={onClose}
            onDelete={onDelete}
            handleOpenInBrouser={handleOpenInBrouser}
            handleDownloadClick={handleDownloadClick}
          />
        ) : (
          <img
            src={file?.url}
            alt=""
            className="w-[791px] h-[520px] rounded-lg"
          />
        )}
      </div>
    </Modal>
  );
};

export default WatchMediaModal;
