const initialState = {
  template: {
    isLoading: true,
    questionnaire: [],
    proposal: [],
    contract: [],
    question: [],
    invoice: [],
    project: []
  },
};

const templateReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_TEMPLATE_LIST":
      return {
        ...state,
        template: {
          ...state.template,
          isLoading: payload.isLoading,
          questionnaire: payload.data.questionnaires,
          proposal: payload.data.proposals,
          contract: payload.data.contracts,
          invoice: payload.data.invoices,
          project: payload.data.projects
        },
      };
    case "GET_QUESTIONNARIES_DATA":
      return {
        ...state,
        template: {
          ...state.template,
          question: payload.data,
        },
      };
    case "SET_TEMPLATE_BY_TYPE":
      return {
        ...state,
        template: {
          ...state.template,
          isLoading: payload.isLoading,
          questionnaire: payload.data.questionnaires,
          proposal: payload.data.proposals,
          contract: payload.data.contracts,
        },
      };
    case "CREATE_TEMPLATE_SUCCESS":
      let type = payload.type;
      type = type.toLowerCase();
      let tempTemplate = state.template;
      tempTemplate[type].push(payload.data);
      return {
        ...state,
        template: {
          ...state.template,
          [type]: tempTemplate[type],
        },
      };
    case "DELETE_TEMPLATE_SUCCESS":
      let tempTemplates = state.template;
      let payloadType = payload.type;
      payloadType = payloadType.toLowerCase();
      tempTemplates[payloadType] = tempTemplates[payloadType].filter(
        (item) => item._id !== payload.id
      );
      return {
        ...state,
        template: {
          ...state.template,
          [payloadType]: tempTemplates[payloadType],
        },
      };
    case "UPDATE_TEMPLATE_SUCCESS":
      let tempTemplateList = state.template;
      let tempType = payload.type;
      tempType = tempType.toLowerCase();
      tempTemplateList[tempType] = tempTemplateList[tempType].map((item) => {
        if (item._id === payload.data._id) {
          return payload.data;
        } else {
          return item;
        }
      });
      return {
        ...state,
        template: {
          ...state.template,
          [tempType]: tempTemplateList[tempType],
        },
      };
    default:
      return state;
  }
};

export default templateReducer;
