import React from "react";

function TaskListLoader() {
  return (
    <div className="5xl:px-[96px] px-6 md:px-12 w-full pt-0">
      <div className="flex pt-10 justify-around 5xl:max-w-[11.75rem] max-w-[9.875rem]">
        <div className="h-[2rem] w-full 5xl:max-w-[5.625rem] max-w-[4.6875rem] rounded bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
        <div className="h-[2rem] w-full 5xl:max-w-[5.625rem] max-w-[4.6875rem] rounded bg-[#f6f7f8] block bg-no-repeat skeleton-animation relative"></div>
      </div>
      <div className="flex flex-wrap mt-10 w-full 5xl:max-w-[83.75rem] lg:max-w-[59rem] max-w-[43.75rem] justify-between">
        <div className="5xl:max-w-[25rem] lg:max-w-[18rem] lg:w-full md:w-[48%] w-full">
          <div className="w-[3.125rem] mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          {Array.from({ length: 4 }).map((a, index) => {
            return (
              <div
                key={`1${index}`}
                className="bg-Neutral000 rounded-lg border border-Neutral300 mb-2 max-w-full py-3 px-3 flex justify-between"
              >
                <div className="rounded w-3/6 bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative"></div>
                <div className="rounded w-[3.125rem] bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative"></div>
              </div>
            );
          })}
        </div>
        <div className="5xl:max-w-[25rem] lg:max-w-[18rem] lg:w-full md:w-[48%] w-full">
          <div className="w-[3.125rem] mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          {Array.from({ length: 3 }).map((b, index) => {
            return (
              <div
                key={`2${index}`}
                className="bg-Neutral000 rounded-lg border border-Neutral300 mb-2 max-w-full py-3 px-3 flex justify-between"
              >
                <div className="rounded w-3/6 bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative"></div>
                <div className="rounded w-[3.125rem] bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative"></div>
              </div>
            );
          })}
        </div>
        <div className="5xl:max-w-[25rem] lg:max-w-[18rem] lg:w-full md:w-[48%] w-full">
          <div className="w-[3.125rem] mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
          {Array.from({ length: 3 }).map((c, index) => {
            return (
              <div
                key={`3${index}`}
                className="bg-Neutral000 rounded-lg border border-Neutral300 mb-2 max-w-full py-3 px-3 flex justify-between"
              >
                <div className="rounded w-3/6 bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative"></div>
                <div className="rounded w-[3.125rem] bg-[#f6f7f8] block h-4 bg-no-repeat skeleton-animation relative"></div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default TaskListLoader;
