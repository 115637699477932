/** @param {import("react").SVGProps} props - SVG Icon Props */
function BoxIcon({ props }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 6.26566L5 2.80566"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 10.6655V5.33213C13.9998 5.09831 13.938 4.86867 13.821 4.66624C13.704 4.46381 13.5358 4.2957 13.3333 4.1788L8.66667 1.51213C8.46397 1.3951 8.23405 1.3335 8 1.3335C7.76595 1.3335 7.53603 1.3951 7.33333 1.51213L2.66667 4.1788C2.46418 4.2957 2.29599 4.46381 2.17897 4.66624C2.06196 4.86867 2.00024 5.09831 2 5.33213V10.6655C2.00024 10.8993 2.06196 11.1289 2.17897 11.3314C2.29599 11.5338 2.46418 11.7019 2.66667 11.8188L7.33333 14.4855C7.53603 14.6025 7.76595 14.6641 8 14.6641C8.23405 14.6641 8.46397 14.6025 8.66667 14.4855L13.3333 11.8188C13.5358 11.7019 13.704 11.5338 13.821 11.3314C13.938 11.1289 13.9998 10.8993 14 10.6655Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.17969 4.63867L7.99969 8.00534L13.8197 4.63867"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 14.719V7.99902"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default BoxIcon;
