import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import Design from "../../assets/Images/Design.svg";
import Designs from "../../assets/Images/Designs.svg";
import Documents from "../../assets/Images/Documents.svg";
import ImageIcon from "../../assets/Images/Image-small-icon.svg";
import Media from "../../assets/Images/Media.svg";
import Paperclip from "../../assets/Images/Paperclip.svg";
import AssetsListLoader from "../../components/ContentLoaders/AssetsListLoader";
import DocumentBox from "../../components/Folders/DocumentBox.jsx";
import NewFolderModal from "../../components/Folders/NewFolderModal.jsx";
import DeleteIcon from "../../components/Icons/FolderIcons/DeleteIcon.js";
import DownArrowIcon from "../../components/Icons/FolderIcons/DownArrowIcon.js";
import EditIcon from "../../components/Icons/FolderIcons/EditIcon.js";
import ExternalLinkIcon from "../../components/Icons/FolderIcons/ExternalLinkIcon.js";
import FolderIcon from "../../components/Icons/FolderIcons/FolderIcon.js";
import FolderOutlineIcon from "../../components/Icons/FolderIcons/FolderOutlineIcon.js";
import ViewIcon from "../../components/Icons/FolderIcons/ViewIcon.js";
import ThreeDotsIcon from "../../components/Icons/ThreeDotsIcon.js";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import {
  DeleteAssetsAction,
  GetAssetsActions,
} from "../../store/actions/assetsAction";
import { GetClientDetailAction } from "../../store/actions/clientAction";
import {
  createFolder,
  deleteFolder,
  getFolderByClient,
  updateFolder,
  updateMultipleFolders,
} from "../../store/actions/folderAction.js";
import {
  getCategoryFromType,
  getExtension,
  getStyleForCategory,
} from "../../utils/folders.js";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const ClientsAssets = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clientId } = useParams();
  const clientDetail = useSelector((state) => state.client.clientDetail);
  const assetsDetail = useSelector((state) => state.assets.assets);
  const [isLoading, setIsLoading] = useState(false);

  /* NEW STATE */
  const documents = assetsDetail?.data?.documents;
  const media = assetsDetail?.data?.media;
  const designs = assetsDetail?.data?.designs;

  const { data: allFoldersData } = useSelector((state) => state.folders);
  const allFolders = allFoldersData || [];
  const documentFolders = allFolders?.filter(
    (folder) => folder.type === "documents"
  );
  const mediaFolders = allFolders.filter((folder) => folder.type === "media");
  const designFolders = allFolders.filter(
    (folder) => folder.type === "designs"
  );

  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [isMediaModalOpen, setIsMediaModalOpen] = useState(false);
  const [isDesignsModalOpen, setIsDesignsModalOpen] = useState(false);

  const [newFolder, setNewFolder] = useState("");
  const [isNewFolderModalOpen, setIsNewFolderModalOpen] = useState(false);
  const [newFolderType, setNewFolderType] = useState("contract");
  const [isNew, setIsNew] = useState(false);
  const [openFolderId, setOpenFolderId] = useState(null);
  const [folder, setFolder] = useState(null);
  /* NEW STATE */

  const [navBarText, setNavbarText] = useState("Back");

  /* NEW USE EFFECTS */
  useEffect(() => {
    dispatch(getFolderByClient(clientId));
  }, [dispatch, clientId]);
  /* NEW USE EFFECTS */

  useEffect(() => {
    if (clientId !== clientDetail.data._id) {
      dispatch(GetClientDetailAction(clientId, navigate));
    }
    if (clientId !== assetsDetail?.client) {
      dispatch(GetAssetsActions(clientId));
    }
  }, [
    assetsDetail?.client,
    clientDetail.data._id,
    clientId,
    dispatch,
    navigate,
  ]);

  useEffect(() => {
    if (clientId !== clientDetail.data._id) {
      setNavbarText("Back");
    } else {
      setNavbarText(
        `Back to ${
          clientDetail?.data?.business_name || clientDetail?.data?.contact_name
        } Dashboard`
      );
    }
  }, [clientDetail, clientId]);

  /* NEW FUNCTIONS */
  const handleFolderClick = (folderId) => {
    setOpenFolderId(openFolderId === folderId ? null : folderId);
  };

  const [editData, setEditData] = useState(null);

  const handleDeleteFolder = (folderId) => {
    dispatch(deleteFolder(folderId));
  };

  const RenderFolder = ({
    folder,
    onDropFile,
    files,
    setaccountCencelled,
    assetId,
  }) => {
    const [, drop] = useDrop(() => ({
      accept: "file",
      drop: (item, monitor) => {
        handleDropFile(item._id, folder);
      },
    }));

    const isOpen = folder._id === openFolderId;

    let filesToRender = [];

    if (isOpen) {
      filesToRender = folder.files
        ?.map((fileId) => files.find((c) => c._id === fileId))
        .filter((file) => file !== undefined);
    }

    return (
      <div className="flex flex-col" ref={drop}>
        <div className="relative flex items-center justify-between w-full h-12 border-b border-Neutral300">
          <button
            className="flex items-center gap-2 duration-300 cursor-pointer text-Neutral800 hover:text-AccentRegular"
            onClick={() => {
              handleFolderClick(folder._id);
            }}
          >
            {isOpen ? <FolderIcon /> : <FolderOutlineIcon />}

            <h5 className="text-sm text-normal ">{folder?.name}</h5>
            {isOpen && <DownArrowIcon />}
          </button>
          <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="inline-flex justify-center w-full">
              <ThreeDotsIcon />
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="edit-menu-servies edit-delete-menu absolute right-0 z-[999] w-[100px] rounded-lg bg-Neutral000 border-[0.5px] border-Neutral200 divide-y divide-Neutral300 focus:outline-none">
                <form method="POST" action="#">
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        className="flex items-center justify-start px-4 py-2 text-xs font-semibold text-left transition duration-300 cursor-pointer text-Neutral800 whitespace-nowrap hover:bg-Neutral200"
                        onClick={() => {
                          setIsNew(false);
                          setFolder(folder);
                          setIsNewFolderModalOpen(true);
                          setNewFolder(folder.name);
                        }}
                      >
                        <EditIcon className="mr-2" />
                        Edit
                      </div>
                    )}
                  </Menu.Item>

                  <Menu
                    as="div"
                    className="pt-[1px]"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Menu.Button className="flex items-center justify-start px-4 py-2 text-xs font-semibold text-left transition duration-300 cursor-pointer text-Neutral800 whitespace-nowrap hover:bg-Neutral200">
                      <DeleteIcon className="mr-2" />
                      Delete
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="focus:outline-none min-w-[334px] absolute right-5 top-[-115px] w-36 origin-top-right divide-y divide-Neutral200 bg-white ring-1 ring-black ring-opacity-5 drop-shadow-Timetracking">
                        <div className="px-8 pt-6 pb-8">
                          <Menu.Item>
                            {({ close }) => (
                              <div>
                                <h5 className="pb-2 text-xs font-semibold border-b text-Neutral700 border-Neutral200">
                                  Are you sure?
                                </h5>
                                <button
                                  onClick={() => {
                                    handleDeleteFolder(folder._id);
                                  }}
                                  type="button"
                                  className="text-Neutral000 w-full pl-2 pr-4 py-4 text-center text-sm cursor-pointer font-bold transition duration-300 bg-Red400 hover:bg-Red600 rounded-[4px] mt-8"
                                >
                                  Yes, Delete Folder
                                </button>

                                <button
                                  onClick={close}
                                  type="button"
                                  className="text-Neutral800 hover:text-Neutral900 w-full pl-2 pr-4 py-4 text-center text-sm cursor-pointer font-bold transition duration-300 bg-Neutral300 hover:bg-Neutral400 rounded-[4px] mt-5"
                                >
                                  No, I Still Want This Folder
                                </button>
                              </div>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </form>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>

        <div className="ml-7">
          {isOpen &&
            filesToRender?.map((file) => (
              <RenderFile
                type={folder.type}
                key={file._id}
                file={file}
                handleDeleted={() => console.log(`File ${file._id} deleted`)}
                setaccountCencelled={setaccountCencelled}
                assetId={assetId}
              />
            ))}
        </div>
      </div>
    );
  };

  const RenderFile = ({
    type,
    file,
    assetId,
    handleDeleted,
    setaccountCencelled,
  }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: "file",
      item: file,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }));

    const fileUrl =
      file?.file?.url ||
      file?.existing_file_upload?.url ||
      file?.existing_file_url;

    const extension = getExtension(fileUrl);
    const category = getCategoryFromType(extension);
    const { background, text } = getStyleForCategory(category);

    const getFavIcon = (url) => {
      if (!url) return null;
      const tempURL = url.includes("http") ? url : `https://${url}`;
      const urlObj = new URL(tempURL);
      const domain = urlObj.hostname;
      return `https://www.google.com/s2/favicons?domain=${domain}`;
    };

    let fileData = null;
    if (!Array.isArray(file.file) && file.file) {
      fileData = Object.entries(file.file).reduce((acc, cur) => {
        acc[0] = { ...(acc[0] || {}), [cur[0]]: cur[1] };
        return acc;
      }, []);
    } else {
      fileData = file.file;
    }

    return (
      <div
        ref={drag}
        style={{ opacity: isDragging ? 0.5 : 1 }}
        className={`flex items-center justify-between h-auto border-b border-Neutral300 w-full relative ${
          isDragging && "bg-Neutral400"
        }`}
      >
        {fileData && fileData.find((item) => item.file) ? (
          <button className="flex items-center w-full gap-2 duration-300 cursor-pointer text-Neutral800 hover:text-AccentRegular">
            <div className={`my-2 w-full`}>
              {fileData.map((item) => {
                return (
                  <div className="flex items-center gap-2">
                    <img
                      src={item?.url}
                      alt=""
                      className={"w-4 h-4"}
                      onClick={() => {
                        return window.open(item?.url, "_blank");
                      }}
                    />
                    <h5
                      className="flex-grow text-sm text-left line-clamp-1 text-normal"
                      data-tip={item.file?.originalname}
                      data-for="fileTitle"
                      onClick={() => {
                        return window.open(item.url, "_blank");
                      }}
                    >
                      {item.file?.originalname}
                    </h5>
                    <span
                      className={`rounded-full py-1 px-2 text-[10px] font-semibold ${background} ${text}`}
                    >
                      {item?.file?.ContentType?.toUpperCase()}
                    </span>
                  </div>
                );
              })}
            </div>
          </button>
        ) : (
          <button className="flex items-center w-full gap-2 cursor-pointer">
            {file?.url && file?.url?.length ? (
              <div className="w-full my-2">
                {file?.url?.map((item) => {
                  return (
                    <div
                      onClick={() => {
                        return window.open(item, "_blank");
                      }}
                      className="flex items-center gap-2"
                    >
                      <img
                        src={getFavIcon(item)}
                        alt="..."
                        className={`w-4 h-4`}
                      />

                      <h5
                        data-tip={file?.title}
                        data-for="fileTitle"
                        className="flex-grow text-sm text-left truncate duration-300 text-normal text-Neutral800 hover:text-AccentRegular"
                      >
                        {file?.title}
                      </h5>
                      <ExternalLinkIcon />
                    </div>
                  );
                })}
              </div>
            ) : null}
            <ReactTooltip
              id="fileTitle"
              effect="solid"
              className="!bg-slate-500 after:!bg-slate-500 !px-2 !py-1 !z-[1] before:!-z-[1] after:!-z-[1]"
            />
          </button>
        )}
        {/* <button className="flex items-center gap-2 duration-300 cursor-pointer text-Neutral800 hover:text-AccentRegular">
          <div className={`${file?.file?.length > 1 && "my-2"}`}>
            {file?.file.length >= 1 &&
              file?.file.map((item) => {
                return (
                  <img
                    src={item?.url}
                    alt=""
                    className={"w-4 h-4 my-1"}
                    onClick={() => {
                      return window.open(item?.url, "_blank");
                    }}
                  />
                );
              })}
          </div>

          {(file?.is_external_contract && file?.existing_file_url) ||
          (file?.url && file?.url[0]?.length) ? (
            <div className={`${file?.url?.length > 1 && "my-2"}`}>
              {file?.url?.map((item) => {
                return (
                  <img
                    src={getFavIcon(item)}
                    alt=""
                    className={`${file?.url?.length > 1 && "w-4 h-4 my-1"}`}
                    onClick={() => {
                      if (
                        file?.file &&
                        file.file.length > 0 &&
                        file.file[0]?.url
                      ) {
                        return window.open(file.file[0].url, "_blank");
                      }
                      if (file?.existing_file_upload?.url) {
                        return window.open(
                          file.existing_file_upload.url,
                          "_blank"
                        );
                      }
                      if (file?.existing_file_url) {
                        return window.open(file.existing_file_url, "_blank");
                      }
                      if (item) {
                        return window.open(item, "_blank");
                      }
                    }}
                  />
                );
              })}
            </div>
          ) : null}

          <h5
            className="text-sm text-left text-normal "
            onClick={() => {
              if (file?.file && file.file.length > 0 && file.file[0]?.url) {
                return window.open(file.file[0].url, "_blank");
              }
              if (file?.existing_file_upload?.url) {
                return window.open(file.existing_file_upload.url, "_blank");
              }
              if (file?.existing_file_url) {
                return window.open(file.existing_file_url, "_blank");
              }
              if (file?.url) {
                return window.open(file?.url, "_blank");
              }
            }}
          >
            {type === "contracts" && file.is_external_contract
              ? file.title || file.existing_file_url
              : type === "invoices"
              ? file?.invoiceName
              : file?.title}
          </h5>
          {(file?.is_external_contract && file?.existing_file_url) ||
          (file?.url && file?.url[0]?.length) ? (
            <div
              className="flex items-center justify-start py-2 text-xs font-semibold text-left transition duration-300 cursor-pointer text-Neutral800 whitespace-nowrap hover:bg-Neutral200"
              onClick={() => {
                if (file?.file && file.file.length > 0 && file.file[0]?.url) {
                  return window.open(file.file[0].url, "_blank");
                }
                if (file?.existing_file_upload?.url) {
                  return window.open(file.existing_file_upload.url, "_blank");
                }
                if (file?.existing_file_url) {
                  return window.open(file.existing_file_url, "_blank");
                }
                if (file?.url) {
                  const url = file?.url?.includes("http")
                    ? file?.url
                    : `https://${file?.url}`;
                  return window.open(url, "_blank");
                }
              }}
            >
              <ExternalLinkIcon />
            </div>
          ) : null}
          {!isFileImage &&
            !["contracts", "invoices"].includes(type) &&
            extension && (
              <span
                className={`rounded-full py-1 px-3 text-xs font-semibold ${background} ${text}`}
              >
                {extension?.toUpperCase()}
              </span>
            )}
        </button> */}
        <Menu as="div" className="pt-[1px]">
          <div className="flex items-center">
            <Menu.Button className="inline-flex justify-center w-full">
              <ThreeDotsIcon />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="edit-menu-servies edit-delete-menu absolute right-0 z-[999] w-[100px] rounded-lg bg-Neutral000 border-[0.5px] border-Neutral200 divide-y divide-Neutral300 focus:outline-none ">
              <form method="POST" action="#">
                <Menu.Item>
                  {({ active }) => (
                    <div>
                      <div
                        className="flex items-center justify-start px-4 py-2 text-xs font-semibold text-left transition duration-300 cursor-pointer text-Neutral800 whitespace-nowrap hover:bg-Neutral200"
                        onClick={() => {
                          if (
                            file?.file &&
                            file.file.length > 0 &&
                            file.file[0]?.url
                          ) {
                            return window.open(file.file[0].url, "_blank");
                          }
                          if (file?.existing_file_upload?.url) {
                            return window.open(
                              file.existing_file_upload.url,
                              "_blank"
                            );
                          }
                          if (file?.existing_file_url) {
                            return window.open(
                              file.existing_file_url,
                              "_blank"
                            );
                          }
                          if (file?.file?.url) {
                            return window.open(file?.file?.url, "_blank");
                          }
                        }}
                      >
                        <ViewIcon className="mr-2" />
                        View
                      </div>
                      <div
                        className="flex items-center justify-start px-4 py-2 text-xs font-semibold text-left transition duration-300 cursor-pointer text-Neutral800 whitespace-nowrap hover:bg-Neutral200"
                        onClick={() => {
                          setEditData({
                            ...file,
                            assets_id: assetId,
                          });
                          setaccountCencelled(true);
                        }}
                      >
                        <EditIcon className="mr-2" />
                        Edit
                      </div>
                      <Menu
                        as="div"
                        className="pt-[1px]"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Menu.Button className="flex items-center justify-start px-4 py-2 text-xs font-semibold text-left transition duration-300 cursor-pointer text-Neutral800 whitespace-nowrap hover:bg-Neutral200">
                          <DeleteIcon className="mr-2" />
                          Delete
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="focus:outline-none min-w-[334px] absolute right-5 top-[-115px] w-36 origin-top-right divide-y divide-Neutral200 bg-white ring-1 ring-black ring-opacity-5 drop-shadow-Timetracking">
                            <div className="px-8 pt-6 pb-8">
                              <Menu.Item>
                                {({ close }) => (
                                  <div>
                                    <h5 className="pb-2 text-xs font-semibold border-b text-Neutral700 border-Neutral200">
                                      Are you sure?
                                    </h5>
                                    <button
                                      onClick={async () => {
                                        if (!assetId || !file?._id)
                                          return console.log(
                                            "Asset ID or File ID not found"
                                          );

                                        let formData = {
                                          item_id: file?._id,
                                          assets_id: assetId,
                                        };

                                        dispatch(
                                          DeleteAssetsAction(
                                            formData,
                                            type,
                                            setIsLoading,
                                            setaccountCencelled
                                          )
                                        );
                                        // dispatch(GetAssetsActions(clientId));
                                      }}
                                      type="button"
                                      className="text-Neutral000 w-full pl-2 pr-4 py-4 text-center text-sm cursor-pointer font-bold transition duration-300 bg-Red400 hover:bg-Red600 rounded-[4px] mt-8"
                                    >
                                      Yes, Delete File
                                    </button>

                                    <button
                                      onClick={close}
                                      type="button"
                                      className="text-Neutral800 hover:text-Neutral900 w-full pl-2 pr-4 py-4 text-center text-sm cursor-pointer font-bold transition duration-300 bg-Neutral300 hover:bg-Neutral400 rounded-[4px] mt-5"
                                    >
                                      No, I Still Want This File
                                    </button>
                                  </div>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  )}
                </Menu.Item>
              </form>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    );
  };

  const addNewFolder = (name, type) => {
    if (!clientId) return;
    dispatch(createFolder(name, type, clientId));
    setNewFolder("");
    setIsNewFolderModalOpen(false);
  };

  const handleDropFile = (fileId, destinationFolder) => {
    const sourceFolder = allFolders.find((folder) =>
      folder.files.includes(fileId)
    );

    if (!destinationFolder) {
      if (sourceFolder) {
        const updatedSourceFiles = sourceFolder.files.filter(
          (id) => id !== fileId
        );
        const updatedSourceFolder = {
          ...sourceFolder,
          files: updatedSourceFiles,
        };
        dispatch(updateFolder(updatedSourceFolder));
      }
      return;
    }

    const isMovingFile =
      sourceFolder && sourceFolder._id !== destinationFolder._id;

    if (isMovingFile) {
      const updatedSourceFiles = sourceFolder.files.filter(
        (id) => id !== fileId
      );
      const updatedSourceFolder = {
        ...sourceFolder,
        files: updatedSourceFiles,
      };

      const updatedDestinationFiles = [...destinationFolder.files, fileId];
      const updatedDestinationFolder = {
        ...destinationFolder,
        files: updatedDestinationFiles,
        updated_at: new Date(),
      };
      dispatch(
        updateMultipleFolders([updatedSourceFolder, updatedDestinationFolder])
      );
    } else {
      if (!destinationFolder.files.includes(fileId)) {
        const updatedDestinationFiles = [...destinationFolder.files, fileId];
        const updatedDestinationFolder = {
          ...destinationFolder,
          files: updatedDestinationFiles,
          updated_at: new Date(),
        };
        dispatch(updateFolder(updatedDestinationFolder));
      } else {
        console.log("File already in folder");
      }
    }
  };

  /* NEW FUNCTIONS */

  return (
    <>
      <AdminNavbar
        link={`/admin/clients/manage/${clientId}`}
        title="Assets"
        backLink={navBarText}
      />
      <div className="5xl:px-[96px] px-12 w-full pt-0">
        <div className="w-full flex justify-between flex-wrap max-w-[836px] mx-auto pt-10 md:pt-[80px]">
          {assetsDetail.isLoading && assetsDetail?.client !== clientId ? (
            <AssetsListLoader />
          ) : (
            <>
              <DndProvider backend={HTML5Backend}>
                <DocumentBox
                  type="documents"
                  icon={Paperclip}
                  title="Documents"
                  folders={documentFolders}
                  files={documents?.items || []}
                  addDocument={() => {
                    setIsDocumentModalOpen(true);
                  }}
                  openModal={() => {
                    setIsNew(true);
                    setNewFolderType("documents");
                    setIsNewFolderModalOpen(true);
                  }}
                  RenderFolder={RenderFolder}
                  handleDropFile={handleDropFile}
                  RenderFile={RenderFile}
                  handleDeleted={() => console.log("File deleted")}
                  isNewDocumentModalOpen={isDocumentModalOpen}
                  setIsNewDocumentModalOpen={setIsDocumentModalOpen}
                  editData={editData}
                  setEditData={setEditData}
                  clientId={clientId}
                  noDataContent={Documents}
                  assetId={documents?._id}
                />
                <DocumentBox
                  type="media"
                  icon={ImageIcon}
                  title="Media"
                  folders={mediaFolders}
                  files={media?.items || []}
                  addDocument={() => {
                    setIsMediaModalOpen(true);
                  }}
                  openModal={() => {
                    setIsNew(true);
                    setNewFolderType("media");
                    setIsNewFolderModalOpen(true);
                  }}
                  RenderFolder={RenderFolder}
                  handleDropFile={handleDropFile}
                  RenderFile={RenderFile}
                  handleDeleted={() => console.log("File deleted")}
                  isNewDocumentModalOpen={isMediaModalOpen}
                  setIsNewDocumentModalOpen={setIsMediaModalOpen}
                  editData={editData}
                  setEditData={setEditData}
                  clientId={clientId}
                  noDataContent={Media}
                  assetId={media?._id}
                />
                <DocumentBox
                  type="designs"
                  icon={Design}
                  title="Designs"
                  folders={designFolders}
                  files={designs?.items || []}
                  addDocument={() => {
                    setIsDesignsModalOpen(true);
                  }}
                  openModal={() => {
                    setIsNew(true);
                    setNewFolderType("designs");
                    setIsNewFolderModalOpen(true);
                  }}
                  RenderFolder={RenderFolder}
                  handleDropFile={handleDropFile}
                  RenderFile={RenderFile}
                  handleDeleted={() => console.log("File deleted")}
                  isNewDocumentModalOpen={isDesignsModalOpen}
                  setIsNewDocumentModalOpen={setIsDesignsModalOpen}
                  editData={editData}
                  setEditData={setEditData}
                  clientId={clientId}
                  noDataContent={Designs}
                  assetId={designs?._id}
                />
              </DndProvider>
            </>
          )}
        </div>
      </div>

      {isNewFolderModalOpen && (
        <NewFolderModal
          isOpen={isNewFolderModalOpen}
          onRequestClose={() => setIsNewFolderModalOpen(false)}
          customStyles={customStyles}
          isNew={isNew}
          addNewFolder={addNewFolder}
          updateFolder={updateFolder}
          dispatch={dispatch}
          folder={folder}
          newFolderType={newFolderType}
          newFolder={newFolder}
          setNewFolder={setNewFolder}
        />
      )}
    </>
  );
};

export default ClientsAssets;
