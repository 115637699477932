const initialState = {
  assets: {
    isLoading: true,
    client: null,
    data: {
      documents: null,
      media: null,
      designs: null,
    },
  },
};
const assetsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_ASSETS":
      return {
        ...state,
        assets: {
          ...state.assets,
          ...payload,
          data: { ...state.assets.data, ...payload.data },
        },
      };
    case "DELETE_ASSETS_SUCCESS":
      let tempData = state.assets.data;
      if (payload.type === "documents") {
        const newToDO = tempData?.documents?.items?.filter(
          (e) => e?._id !== payload?.data?.item_id
        );
        tempData.documents = { ...tempData.documents, items: newToDO };
      }
      if (payload.type === "media") {
        const newToDO = tempData?.media?.items?.filter(
          (e) => e?._id !== payload?.data?.item_id
        );
        tempData.media = { ...tempData.media, items: newToDO };
      }
      if (payload.type === "designs") {
        const newToDO = tempData?.designs?.items?.filter(
          (e) => e?._id !== payload?.data?.item_id
        );
        tempData.designs = { ...tempData.designs, items: newToDO };
      }
      return {
        ...state,
        assets: {
          ...state.assets,
          data: tempData,
        },
      };
    default:
      return state;
  }
};

export default assetsReducer;
