import { Field } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  AddUserCategoryAction,
  RemoverUserCategoryAction,
} from "../../store/actions/authAction";
import { getTagColor } from "../../utils/getTagColor";
import { Select } from "../Inputs/CreatableSelect";
import ButtonLoader from "../Loaders/ButtonLoader";
import CustomErrorMessage from "./CustomErrorMessage";

function Step1({ values, setFieldValue, isSubmitting, isValid, errors }) {
  const dispatch = useDispatch();

  const [categoryTags, setCategoryTags] = useState([]);

  const user = useSelector((state) => state.auth.user);
  const clientDetail = useSelector((state) => state.client.clientDetail);

  const modalColor = "Pink";

  useEffect(() => {
    let uniqueCategory = [];

    if (user?.category?.length) {
      const userCategory = user?.category?.map((item) => {
        return { value: item, label: item };
      });
      uniqueCategory = [...userCategory, ...uniqueCategory];
    }

    uniqueCategory = uniqueCategory.reduce((acc, current) => {
      const x = acc.find(
        (item) => item?.value?.toLowerCase() === current?.value?.toLowerCase()
      );
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    const optionsWithColors = uniqueCategory.map((option, i) => {
      const { bgColor: optionColor, text: optionText } = getTagColor(
        modalColor,
        i,
        true
      );

      const { bgColor: labelColor, text: labelText } = getTagColor(
        modalColor,
        i,
        true
      );

      return {
        ...option,
        color: optionColor,
        text: optionText,
        labelColor,
        labelText,
      };
    });
    setCategoryTags(optionsWithColors);
  }, [user, clientDetail]);

  const handleRemove = (data) => {
    const removedCategory = dispatch(RemoverUserCategoryAction(data));

    if (removedCategory?.data) {
      setCategoryTags(categoryTags.filter((e) => e !== data));
    }
  };

  const handleCreateNewTag = (inputValue) => {
    dispatch(AddUserCategoryAction(inputValue));
  };

  return (
    <>
      <div className="flex flex-col gap-4 mb-4">
        {/* INVOICE NAME */}
        <div className="space-y-2">
          <label
            htmlFor="invoiceName"
            className="block text-xs font-bold text-zinc-800"
          >
            Invoice Name
          </label>
          <Field
            name="invoiceName"
            type="text"
            id="invoiceName"
            className="w-full p-3 text-sm font-medium bg-white border border-gray-100 rounded-lg outline-none text-neutral-500"
            placeholder="Eg. Web Design Proposal"
          />
          <CustomErrorMessage name="invoiceName" />
        </div>

        {/* CATEGORY TAGS */}
        <div className="space-y-2">
          <label
            htmlFor="categoryTags"
            className="block text-xs font-bold text-zinc-800"
          >
            Category Tags
          </label>
          <Select
            id="categoryTags"
            name="categoryTags"
            isMulti
            isSearchable
            isClearable={true}
            options={categoryTags}
            value={values?.categoryTags}
            handleCreateNewTag={handleCreateNewTag}
            handleRemove={handleRemove}
            {...{ setFieldValue, modalColor }}
          />
          {/* <CustomErrorMessage name="categoryTags" /> */}
          {typeof errors.categoryTags === "string" && (
            <div className="pt-1 pl-1 text-xs text-red-600">
              {errors.categoryTags}
            </div>
          )}
        </div>
      </div>

      <button
        type="submit"
        className="w-full p-2 py-3 text-xs font-bold tracking-wide uppercase rounded bg-Neutral300 text-AccentRegular hover:text-white not-disabled:hover:bg-AccentRegular disabled:text-Neutral600 disabled:cursor-not-allowed"
        disabled={isSubmitting || !isValid || !(values.categoryTags.length > 0)}
      >
        {isSubmitting ? <ButtonLoader /> : "Save"}
      </button>
    </>
  );
}

export default Step1;
