import { Formik } from "formik";
import React, { useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import * as Yup from "yup";

import CloseBtn from "../../assets/Images/close-btn.svg";
import {
  FollowUpEmailAction,
  enabledFollowUpAction,
  followUpAutoEmailAction,
} from "../../store/actions/proposalAction";
import SelectDownArrow from "../Icons/SelectDownArrow";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const validationSchema = Yup.object().shape({
  to: Yup.string().required("To is required").email("Invalid email"),
  subject: Yup.string().required("Subject is required"),
  message: Yup.string().required("Message is required"),
  follow_up_email_subject: Yup.string().required(
    "Follow Up Email Subject is required"
  ),
  follow_up_email_body: Yup.string().required(
    "Follow Up Email Message is required"
  ),
});

function FollowUpEmailModal({
  isOpen = false,
  setIsOpen = () => {},
  data,
  type,
  id,
  selectedData,
  IsDashboard,
  setTextEditor,
}) {
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);

  const emailDomainURL =
    userDetail?.customDomain &&
    userDetail?.customDomain?.isDomainPropogated
      ? userDetail?.customDomain?.custom_domain_name
      : process.env.REACT_APP_PORTAL_ENDPOINT;

  const emailUserName = userDetail?.brandIdentity?.companyName || userDetail?.user_name;

  const link =
    type === "Proposal" || type === "Contract" || type === "Invoice"
      ? `https://${emailDomainURL}/public-${type.toLowerCase()}/${
          data?._id
        }/${type.toLowerCase()}/${id}`
      : `https://${emailDomainURL}/request-info/${id}`;

  const handleFollowSchedule = (value) => {
    dispatch(
      followUpAutoEmailAction(
        value,
        setIsLoading,
        setIsOpen,
        type,
        IsDashboard,
        selectedData,
        setTextEditor
      )
    );
  };

  const handleEnabled = (value) => {
    dispatch(enabledFollowUpAction(value, type, IsDashboard, setTextEditor));
  };
  const default_total_time = selectedData?.auto_email_followUp?.total_times
    ? selectedData?.auto_email_followUp?.total_times
    : 3;

  const default_total_days = selectedData?.auto_email_followUp?.total_days
    ? selectedData?.auto_email_followUp?.total_days
    : 3;

  const default_enabled = selectedData?.auto_email_followUp?.enable;

  const defaultMessage = `Hi ${data?.contact_name || data?.client_name},

Please find the ${type} in this URL: ${link}

If you have any questions, please let me know.

Regards,
${emailUserName}`;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        setIsOpen(false);
      }}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="Example Modal"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 width-[90vw] md:w-[700px] w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="bg-white px-6 xs:px-12 pt-8 pb-10 rounded-[8px]">
          <div className="overflow-hidden text-left">
            <h3
              className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-4 uppercase"
              id="modal-title"
            >
              Send An Email
            </h3>

            <div className="relative overflow-hidden">
              <Formik
                initialValues={{
                  to: data?.email || "",
                  from: userDetail?.customEmail?.reply_to_mail
                    ? userDetail?.customEmail?.reply_to_mail
                    : process.env.REACT_APP_EMAIL,
                  total_times: default_total_time,
                  total_days: default_total_days,
                  enable: default_enabled,
                  subject: `${type} for ${
                    data?.contact_name || data?.client_name
                  }`,
                  message: defaultMessage,
                  follow_up_email_subject: `${type} for ${
                    data?.contact_name || data?.client_name
                  }`,
                  follow_up_email_body: defaultMessage,
                }}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values, { setSubmitting, setErrors }) => {
                  // if (data?.is_verified_email !== "Success") {
                  //   setSubmitting(false);
                  //   setErrors({ to: "Client email should be verified" });
                  // } else
                  if (
                    !userDetail?.customEmail?.replyEmailVerified?.isVerified && values?.from !== 'noreply@clientmanager.io'
                  ) {
                    setSubmitting(false);
                    setErrors({ from: "Email should be verified" });
                  } else {
                    const val = {
                      ...values,
                      message: {
                        client_name: data?.contact_name || data?.client_name,
                        type: type,
                        link: link,
                        user_name: userDetail?.user_name,
                      },
                    };
                    dispatch(
                      FollowUpEmailAction(
                        values,
                        type,
                        setSubmitting,
                        setIsOpen
                      )
                    );
                  }
                }}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  errors,
                  touched,
                  handleBlur,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="md:max-h-[60dvh] overflow-y-auto">
                      <div className="flex flex-row mb-6 space-x-4">
                        <div className="w-full">
                          <label
                            htmlFor="to"
                            className="text-xs font-bold text-Neutral900"
                          >
                            To
                          </label>
                          <input
                            type="email"
                            name="to"
                            id="to"
                            value={values.to}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="text-Neutral800 mt-2 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                          />
                          {errors.to && touched.to && (
                            <p className="mt-1 text-xs text-red-500">
                              {errors.to}
                            </p>
                          )}
                        </div>
                        <div className="flex flex-col justify-end w-full">
                          <div className="flex gap-2">
                            <label
                              htmlFor="from"
                              className="text-xs font-bold text-Neutral900"
                            >
                              From
                            </label>
                            <div
                              data-for="email-from"
                              data-tip={`You will be able to add your own custom email when we launch the White Label feature soon (ETA: May)`}
                            >
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.3334 7.2042C7.5528 6.58051 7.98586 6.05459 8.55586 5.7196C9.12586 5.3846 9.79603 5.26215 10.4477 5.37392C11.0993 5.48569 11.6904 5.82448 12.1161 6.33028C12.5419 6.83608 12.775 7.47625 12.774 8.1374C12.774 10.0038 9.97437 10.937 9.97437 10.937V12.0003"
                                  stroke="#9C9CA6"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M10.0489 14.6702H10.0576"
                                  stroke="#9C9CA6"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <rect
                                  x="0.5"
                                  y="0.5"
                                  width="19"
                                  height="19"
                                  rx="9.5"
                                  stroke="#C2C2CC"
                                />
                              </svg>
                              <ReactTooltip
                                place="bottom"
                                textColor="#F5F5F7"
                                className="tooltip-font-size"
                                backgroundColor="#292933"
                                id="email-from"
                              />
                            </div>
                          </div>
                          <input
                            type="text"
                            name="from"
                            id="from"
                            value={values.from}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="text-Neutral800 mt-2 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                            disabled
                          />
                          {errors.from && touched.from && (
                            <p className="mt-1 text-xs text-red-500">
                              {errors.from}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="w-full mb-6">
                        <label
                          htmlFor="subject"
                          className="text-xs font-bold text-Neutral900"
                        >
                          Subject
                        </label>
                        <input
                          type="text"
                          name="subject"
                          id="subject"
                          value={values.subject}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="text-Neutral800 mt-2 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                        />
                        {errors.subject && touched.subject && (
                          <p className="mt-1 text-xs text-red-500">
                            {errors.subject}
                          </p>
                        )}
                      </div>

                      <div className="w-full mb-6">
                        <label
                          htmlFor="message"
                          className="text-xs font-bold text-Neutral900"
                        >
                          Message
                        </label>
                        <textarea
                          name="message"
                          rows="10"
                          id="message"
                          value={values.message}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="text-Neutral800 mt-2 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                        />

                        {errors.message && touched.message && (
                          <p className="mt-1 text-xs text-red-500">
                            {errors.message}
                          </p>
                        )}
                      </div>

                      <div>
                        <div className="flex justify-between">
                          <label
                            htmlFor="follow up auto email"
                            className="flex items-center gap-2 text-xs font-bold text-Neutral900"
                          >
                            Send follow-up reminder email
                            <div
                              data-for="follow_up"
                              data-tip={`This is an optional email reminder`}
                            >
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.3334 7.2042C7.5528 6.58051 7.98586 6.05459 8.55586 5.7196C9.12586 5.3846 9.79603 5.26215 10.4477 5.37392C11.0993 5.48569 11.6904 5.82448 12.1161 6.33028C12.5419 6.83608 12.775 7.47625 12.774 8.1374C12.774 10.0038 9.97437 10.937 9.97437 10.937V12.0003"
                                  stroke="#9C9CA6"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M10.0489 14.6702H10.0576"
                                  stroke="#9C9CA6"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <rect
                                  x="0.5"
                                  y="0.5"
                                  width="19"
                                  height="19"
                                  rx="9.5"
                                  stroke="#C2C2CC"
                                />
                              </svg>
                              <ReactTooltip
                                place="bottom"
                                textColor="#F5F5F7"
                                className="tooltip-font-size"
                                backgroundColor="#292933"
                                id="follow_up"
                              />
                            </div>
                          </label>
                          <div>
                            <label className="flex items-center gap-4 cursor-pointer select-none toggle-switch">
                              <h5 className="text-Neutral700 text-xs font-bold tracking-[0.1em] uppercase">
                                Enabled
                              </h5>
                              <div className="relative">
                                <input
                                  type="checkbox"
                                  className="sr-only"
                                  name="enable"
                                  value={values.enable}
                                  checked={values.enable}
                                  onChange={(event) => {
                                    setFieldValue(
                                      "enable",
                                      event.target.checked
                                    );
                                    handleEnabled({
                                      id: id,
                                      enable: event.target.checked,
                                      type: type,
                                    });
                                  }}
                                />
                                <div className="block h-[18px] switch w-[40px] rounded-full border-Neutral400 border-2 bg-Neutral100"></div>
                                <div className="dot absolute left-0 top-[-1px] h-5 w-5 rounded-full bg-Neutral800 transition shadow-[0px_1.5px_3px_1px_rgba(0,0,0,0.2)]"></div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="mt-2 mb-4 ">
                          <div className="flex items-center gap-2">
                            <div className="relative w-full">
                              <label className="text-xs font-medium uppercase text-Neutral700">
                                How many times?
                                <span className="absolute pointer-events-none z-[999] top-[65%] right-[16px]">
                                  <SelectDownArrow />
                                </span>
                                <select
                                  disabled={!values?.enable}
                                  className="text-Neutral800 mt-2 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition bg-white disabled:cursor-not-allowed"
                                  value={values.total_times}
                                  name="total_times"
                                  onChange={handleChange}
                                >
                                  <option value={3}>3 times</option>
                                  <option value={5}>5 times</option>
                                  <option value={7}>7 times</option>
                                </select>
                              </label>
                            </div>
                            <div className="relative w-full">
                              <label className="text-xs font-medium uppercase text-Neutral700">
                                Every X days
                                <span className="absolute pointer-events-none z-[999] top-[65%] right-[16px]">
                                  <SelectDownArrow />
                                </span>
                                <select
                                  disabled={!values?.enable}
                                  className="text-Neutral800 mt-2 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition bg-white disabled:cursor-not-allowed"
                                  value={values.total_days}
                                  name="total_days"
                                  onChange={handleChange}
                                >
                                  <option value={3}>3 days</option>
                                  <option value={5}>5 days</option>
                                  <option value={7}>7 days</option>
                                </select>
                              </label>
                            </div>
                          </div>

                          <div className="flex justify-end w-full gap-3 mt-4">
                            <button
                              disabled={!values.enable || isLoading}
                              className={`text-sm rounded h-8 px-3  5xl:w-fit w-fit transition duration-300 ease-in-out bg-AccentRegular drop-shadow-Texts text-Neutral000 hover:bg-AccentMediumDark button-hover disabled:bg-neutral-500 disabled:cursor-not-allowed`}
                              onClick={() => {
                                handleFollowSchedule({
                                  id: id,
                                  total_times: values.total_times,
                                  total_days: values.total_days,
                                  enable: values.enable,
                                  type: type,
                                  subject: values.follow_up_email_subject,
                                  body: values.follow_up_email_body,
                                });
                              }}
                              type="button"
                            >
                              <span className="relative z-10 flex items-center justify-center font-[600]">
                                {isLoading ? "Saving..." : "Save Reminders"}
                              </span>
                            </button>
                            {/* <button
                            type="button"
                            onClick={() => {
                              setFieldValue("total_times", default_total_time);
                              setFieldValue("total_days", default_total_days);
                              setFieldValue(
                                "disable",
                                default_enabled
                              );
                            }}
                            className={`text-sm rounded  h-8 px-3  5xl:w-fit w-fit transition duration-300 ease-in-out bg-neutral-700 drop-shadow-Texts text-Neutral000 hover:bg-neutral-500 button-hover`}
                          >
                            Cancel
                          </button> */}
                          </div>
                        </div>
                      </div>

                      {values.enable && (
                        <>
                          <div className="w-full mb-6">
                            <label
                              htmlFor="follow_up_email_subject"
                              className="text-xs font-bold text-Neutral900"
                            >
                              Follow Up Email Subject
                            </label>
                            <input
                              type="text"
                              name="follow_up_email_subject"
                              id="follow_up_email_subject"
                              value={values.follow_up_email_subject}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="text-Neutral800 mt-2 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                            />
                            {errors.follow_up_email_subject &&
                              touched.follow_up_email_subject && (
                                <p className="mt-1 text-xs text-red-500">
                                  {errors.follow_up_email_subject}
                                </p>
                              )}
                          </div>

                          <div className="w-full mb-6">
                            <label
                              htmlFor="follow_up_email_body"
                              className="text-xs font-bold text-Neutral900"
                            >
                              Follow Up Email Message
                            </label>
                            <textarea
                              name="follow_up_email_body"
                              rows="10"
                              id="follow_up_email_body"
                              value={values.follow_up_email_body}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="text-Neutral800 mt-2 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                            />

                            {errors.follow_up_email_body &&
                              touched.follow_up_email_body && (
                                <p className="mt-1 text-xs text-red-500">
                                  {errors.follow_up_email_body}
                                </p>
                              )}
                          </div>
                        </>
                      )}
                    </div>

                    <div className="flex justify-center mt-2">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="relative block w-full py-3 overflow-hidden text-sm font-bold text-center transition duration-300 ease-in-out rounded btn button-hover form-primary-btn text-Neutral100 bg-AccentRegular focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight"
                      >
                        {isSubmitting ? (
                          <span className="relative z-10">
                            <svg
                              className="w-5 h-5 m-auto text-white animate-spin"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          </span>
                        ) : (
                          <span className="relative z-10 drop-shadow-Texts">
                            Send Email
                          </span>
                        )}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div
          className="absolute right-0 -top-[40px] z-30 cursor-pointer"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
}

export default FollowUpEmailModal;
