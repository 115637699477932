import React from "react";

function InvoiceLoader() {
  return (
    <div className="flex flex-col items-start justify-start w-full px-8 py-4 gap-4">
      <div className="flex flex-col p-4 rounded-lg w-full h-[279px] bg-Neutral000 skeleton-animation relative">
        <div className="flex-col justify-center items-start gap-0.5 flex w-full"></div>
      </div>
      {Array.from({ length: 4 }).map((ele, index) => {
        return (
          <div
            className="flex flex-col p-4 rounded bg-Neutral000 w-full skeleton-animation relative h-14"
            key={index}
          ></div>
        );
      })}
    </div>
  );
}

export default InvoiceLoader;
