import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Pagination = (props) => {
  const {
    currantPage,
    totalData,
    setPage,
    hasPrevPage,
    hasNextPage,
    fromRecord,
    outOfCounter,
    outOfCounter1,
    type,
  } = props;

  const handleChangePagination = (value) => {
    const page = value ? currantPage + 1 : currantPage - 1;
    if (page >= 0) {
      setPage(page);
    }
  };

  return (
    <div className="flex items-center justify-between mt-1 mb-4 pagination-main">
      <p className="text-xs font-medium tracking-[0.04em] text-Neutral600">
        {outOfCounter ? (
          <>
            {fromRecord}-{fromRecord + outOfCounter - 1} of {totalData} {type || "Clients"}
          </>
        ) : (
          ""
        )}
        {outOfCounter1 ? (
          <>
            {fromRecord}-{fromRecord + outOfCounter1 - 1} of {totalData} Projects
          </>
        ) : (
          ""
        )}
      </p>
      <div className="pagination-buttons flex">
        <Link
          to="#"
          className={`mr-2 ${hasPrevPage ? "pagination-left-btn" : "pointer-events-none"
            }`}
          onClick={() => (hasPrevPage ? handleChangePagination(false) : null)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 26 26"
            fill="none"
          >
            <rect
              x="25.5"
              y="25.5"
              width="25"
              height="25"
              rx="4.5"
              transform="rotate(-180 25.5 25.5)"
              stroke="#DFDFE6"
            />
            <path
              d="M15 7L9 13L15 19"
              stroke="#DFDFE6"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Link>

        <Link
          to="#"
          className={`${hasNextPage ? "pagination-right-btn" : "pointer-events-none"
            }`}
          onClick={(e) => (hasNextPage ? handleChangePagination(e) : null)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 26 26"
            fill="none"
          >
            <rect
              x="0.5"
              y="0.5"
              width="25"
              height="25"
              rx="4.5"
              stroke="#DFDFE6"
            />
            <path
              d="M11 19L17 13L11 7"
              stroke="#DFDFE6"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Link>
      </div>
    </div>
  );
};

export default Pagination;
