import React, { useState } from "react";
import clx from "classnames";

import Input, {
  getCountryCallingCode,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";

import SelectDownArrow from "../Icons/SelectDownArrow";
import preparePhoneNumber from "../../utils/preparePhoneNumber";

function PhoneInput({
  value,
  name = "phone_number",
  setFieldValue,
  setFieldError,
  handleBlur,
  errors = null,
  touched = null,
  className = "",
}) {
  const [country, setCountry] = useState("");

  const onChange = (e) => {
    if (e) {
        setFieldValue(name, formatPhoneNumberIntl(e));
    }
  };

  return (
    <div className={clx("phone-editer relative", className)}>
      <Input
        placeholder="Eg. 7894561230"
        // international={false}
        name={name}
        limitMaxLength={true}
        onBlur={handleBlur}
        value={preparePhoneNumber(value)}
        onCountryChange={(value) => setCountry(value)}
        displayInitialValueAsLocalNumber
        onChange={onChange}
        className="text-Neutral800 font-medium placeholder:text-Neutral500 py-[6px] px-[8px] placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
      />
      {country && (
        <span className="absolute left-[46px] top-[50%] translate-y-[-50%] text-Neutral800 font-medium leading-[20px] text-[14px]">
          {"+" + getCountryCallingCode(country)}
        </span>
      )}
      <SelectDownArrow
        width={10}
        height={8}
        className="absolute left-[86px] top-[20px] text-Neutral800"
      />
      {errors?.phone_number && touched?.phone_number && (
        <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
          {errors?.phone_number}
        </p>
      )}
    </div>
  );
}

export default PhoneInput;
