import Modal from "react-modal";

import CloseBtn from "../../assets/Images/close-btn.svg";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

function CancelMeetingModal({
  meetingId,
  isOpen = false,
  handleClick = () => {},
  setIsOpen = () => {},
}) {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          setIsOpen(false);
        }}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="Cancel Meeting Modal"
      >
        <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="flex flex-col items-center justify-center p-5">
            <div className="w-full">
              <p className="text-xs text-Neutral700 tracking-[4%] font-semibold">
                MEETING CANCELLATION
              </p>
              <div className="h-[1px] border-b mt-2" />
            </div>

            <p className="w-[320px] text-sm text-Neutral800 text-center py-8">
              Are you sure? Your client will need to book a new meeting from
              scratch if you cancel this one.
            </p>

            <button
              className="w-full text-center bg-[#E56780] text-sm font-bold text-Neutral100 rounded p-2 py-4"
              onClick={handleClick}
            >
              Cancel Meeting
            </button>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CancelMeetingModal;
