import React from "react";

import Modal from "react-modal";

import CloseBtn from "../../assets/Images/close-btn.svg";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const GoogleCalendarDetailsModal = ({ setIsOpen, modalIsOpen }) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => setIsOpen(false)}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="Archived client list Modal"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[455px] sm:min-w-[455px]  max-w-[455px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="bg-white p-[24px_32px_30px_32px] rounded-[8px]">
          <h6 className="uppercase font-medium text-xs text-Neutral700 tracking-[0.04em] pb-2 border-b-[0.56px] border-Neutral300 w-full">
            how it works
          </h6>
          <div className="md:min-w-[225px] pt-3">
            <ol className="list-decimal list-inside">
              <li className="text-xs font-normal py-1">
              Go to <a href="https://calendar.google.com/" target="_blank" className="text-AccentRegular">Google Calendar. </a>
              </li>
              <li className="text-xs font-normal py-1">
                  On the left, find the “My calendars” section.
              </li>
              <li className="text-xs font-normal py-1">
                  Then, hover over the calendar you want to integrate and click More and then Settings and sharing.
              </li>
              <li className="text-xs font-normal py-1">
                  Under “Access permissions,” select "Make available to public".
              </li>
              <li className="text-xs font-normal py-1">
                  Next to “Make available to public,” select "See all event details" access.
              </li>
              <li className="text-xs font-normal py-1">
                  Under "Integrate Calendar", copy your calendar ID.
              </li>
            </ol>
          </div>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => setIsOpen(false)}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
};

export default GoogleCalendarDetailsModal;
